import { KatCard } from "@amzn/katal-react";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { loadModelTrainingNamespace } from "src/control/actions/modelTraining/namespace_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { namespaceSelector } from "src/control/selectors/modelTraining/model_training_selectors";
import {
  FormDropDown,
  FormInput,
} from "src/view/style/modelTraining/form_input_styles";
import Collapsible from "../../commons/collapsible";
import { isEmpty } from "src/utils/common_utils";
import { GreenSpinner } from "src/view/style/header_styles";
import { getJob, getJobLatestVersionData } from "../../../control/actions/runways/job_actions";
import {
  isJobSpinnerLoadingSelector,
  jobDataSelector,
  jobLatestVersionDataSelector,
} from "../../../control/selectors/runways/job_selectors";
import {
  TagBox,
  ViewJobExecutions,
  ViewLogsMetrics,
} from "src/view/style/runway_styles";
import JobComponent from "../job_components";
import { getViewLogsMetricsUrl } from "../constants";
import { getJobVersionDropdownOptions } from "../utils";
import {OperationType} from "src/constant";

/**
 * renders the view for view job form
 * @param props
 * @returns JSX
 */
export default function ViewJob(props: {
  isRunwayJob?: boolean;
  jobId?: any;
  liveVersion?: any;
}) {
  const dispatch = useDispatch();
  const params: any = useParams();
  const moment = require("moment");
  const DISPLAY_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm";
  const TIMESTAMP_FORMAT = "YYYYMMDDHHmmss";

  //selectors
  let fetchedNamespace = useSelector(namespaceSelector);
  let userName = useSelector(usernameSelector);
  let jobData = useSelector(jobDataSelector);
  let latestVersionJobData = useSelector(jobLatestVersionDataSelector);
  let isSpinnerLoading = useSelector(isJobSpinnerLoadingSelector);

  
  let latestVersion = latestVersionJobData.latestVersion;
  let version = jobData.version;
  
  //useEffect hooks
  useEffect(() => {
    if (props.isRunwayJob) {
      dispatch(getJobLatestVersionData(props.jobId));
      dispatch(getJob(props.jobId, props.liveVersion));
    } else {
      dispatch(getJob(params.jobId));
    }
    dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
  }, [userName]);

  useEffect(() => {
    // To adjust popUp size & zIndex of the DatePicker
    let container = document
      .querySelector<HTMLElement>(".popUpWrapper kat-modal")
      ?.shadowRoot?.querySelector<HTMLElement>(".container");
    if (container) {
      container.style.zIndex = "1200";
      let dialogContainer = container.querySelector<HTMLElement>(".dialog");
      if (dialogContainer) dialogContainer.style.minWidth = "80%";
    }
  }, []);

  /**
   * renders the available tag set into a block of tag divs
   * @returns JSX: array of tag divs
   */
  const getCustomTags = () => {
    let customTags = jobData.customTags;
    if (customTags.length > 0) {
      return customTags.map((tagValue: string) => {
        return (
          <TagBox>
            <span>{tagValue}</span>
          </TagBox>
        );
      });
    } else {
      return <p style={{ opacity: "0.6" }}>No tags provided</p>;
    }
  };

  if (
    isEmpty(fetchedNamespace.namespaceData.namespace) ||
    isSpinnerLoading
  ) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
    <>
      {!window.location.pathname.includes("create") && (
        <div style={{ display: "flex" }}>
          <ViewJobExecutions
            label="View Executions"
            onClick={() => {
              window.open(
                `${window.location.origin}/runways/${params.teamId}/namespaces/${params.namespaceId}/${params.runwayId}/jobs/${jobData.jobId}/executions?liveVersion=${props.liveVersion}`,
                "_self"
              );
            }}
          ></ViewJobExecutions>
          <ViewLogsMetrics
            label="View Logs/Metrics"
            onClick={() =>
              window.open(
                getViewLogsMetricsUrl(
                  fetchedNamespace.namespaceData.accountTypeMap[
                    jobData.domain.toLowerCase()
                  ],
                  params.runwayId
                )
              )
            }
          ></ViewLogsMetrics>
        </div>
      )}

      <KatCard>
        <div style={{ maxHeight: "auto" }}>
          <span slot="title">
            JobId: {props.isRunwayJob ? props.jobId : params.jobId}
          </span>
        </div>

        <FormInput
          label="Namespace Name"
          type="text"
          placeholder="Namespace name"
          value={fetchedNamespace.namespaceData.namespace}
          tooltipText="Namespace Name"
          disabled={true}
        />

        <FormInput
          label="Job Name"
          type="text"
          placeholder="Job name"
          value={jobData.jobName}
          tooltipText="Job Name"
          disabled={true}
          required
        />

        <FormDropDown
          label="Version"
          searchable={true}
          value={version}
          maxHeight="100px"
          placeholder={
            latestVersion === -1
              ? `${version} (Could not load other versions)`
              : "Select versions"
          }
          disabled={latestVersion === -1}
          onChange={(event: any) => {
            dispatch(getJob(jobData.jobId, event.target.value));
          }}
        >
          {getJobVersionDropdownOptions(latestVersion, props.liveVersion)}
        </FormDropDown>
        {latestVersion === -1 && (
          <div>NOTE: Please refresh to load other versions</div>
        )}
        <div>
          <span>Custom Tags: </span>
        </div>
        {getCustomTags()}
      </KatCard>

      <Collapsible label="Scheduling">
        <FormInput
          label="Job base time (UTC)"
          type="text"
          value={moment
            .utc(jobData.jobScheduleInfo.baseTimestamp, TIMESTAMP_FORMAT)
            .format(DISPLAY_TIMESTAMP_FORMAT)}
          tooltipText="Combination of Job base time and the frequency decides the job execution timestamp"
          disabled
        />

        <FormInput
          label="Scheduling frequency(in minutes)"
          type="number"
          placeholder="Scheduling frequency"
          value={jobData.jobScheduleInfo.scheduleFrequencyInMins.toString()}
          tooltipText="Volume size"
          disabled={true}
        />

        <FormInput
          label="Offset (in minutes)"
          type="number"
          value={jobData.jobScheduleInfo.offsetInfo.minutes.toString()}
          tooltipText="Timestamp offset for the scheduled executions. Ex: if offset is -60, a job executed at 5:00 hrs, will resolve the timestamp for jobData as 4:00 hrs"
          disabled={true}
        />
        <br />
      </Collapsible>

      <Collapsible label="Dependency">
        <FormInput
          label="Offset (in minutes)"
          type="text"
          value={
            isEmpty(jobData.jobDependencies)
              ? "-"
              : isEmpty(jobData.jobDependencies[0].dependencyOffsetInMins)
              ? "-"
              : jobData.jobDependencies[0].dependencyOffsetInMins.toString()
          }
          disabled={true}
        ></FormInput>

        <FormInput
          label="Instance Count"
          type="text"
          value={
            isEmpty(jobData.jobDependencies)
              ? "-"
              : isEmpty(jobData.jobDependencies[0].dependentInstancesCount)
              ? "-"
              : jobData.jobDependencies[0].dependentInstancesCount.toString()
          }
          disabled={true}
        ></FormInput>

        <FormInput
          label="Instance Frequency (in minutes)"
          type="text"
          value={
            isEmpty(jobData.jobDependencies)
              ? "-"
              : isEmpty(
                  jobData.jobDependencies[0].dependencyInstanceFrequencyInMins
                )
              ? "-"
              : jobData.jobDependencies[0].dependencyInstanceFrequencyInMins.toString()
          }
          disabled={true}
        ></FormInput>
      </Collapsible>

      <JobComponent
        operationType={OperationType.VIEW}
        serviceType={jobData.serviceType}
        service={jobData.service}
        artifactInfo={jobData.artifactInfo}
      ></JobComponent>
    </>
  );
}
