const moment = require("moment")
import DateFnsUtils from "@date-io/date-fns";

const TIMESTAMP_TILL_SECS = 'YYYYMMDDHHmmss';
const DATE_TIME_FORMAT_TILL_SEC = 'YYYY-MM-DD HH:mm:ss';

export const convertToUTC = (date: Date) => {
    return new Date(date.getTime() + date.getTimezoneOffset()*60000)
}

/*
    Converts date to YYYYMMDDhhmmss (UTC)
*/
export const convertToUTCTimestamp = (date: Date) => {
  const utcDate = convertToUTC(date)
  return DateFnsUtils.prototype.getYearText(utcDate)
            +  String(leftPad(DateFnsUtils.prototype.getMonth(utcDate) + 1)) // Month starts from 0 hence adding 1, Padding with 0 for single digit month
            +  String(leftPad(Number(DateFnsUtils.prototype.getDayText(utcDate)))) // Padding with 0 for single digit date
            +  DateFnsUtils.prototype.getHourText(utcDate, false)
            +  DateFnsUtils.prototype.getMinuteText(utcDate)
            +  DateFnsUtils.prototype.getSecondText(utcDate);
}

function leftPad(n: number): number | string {
  if (n < 10) {
    return `0${n}`;
  }
  return n;
}

export const formattedUTCTimestamp = (date: Date) => {
  const utcDate = convertToUTC(date)
  return DateFnsUtils.prototype.getYearText(utcDate) + "-"
            +  String(leftPad(DateFnsUtils.prototype.getMonth(utcDate) + 1)) + "-" // Month starts from 0 hence adding 1, Padding with 0 for single digit month
            +  String(leftPad(Number(DateFnsUtils.prototype.getDayText(utcDate)))) + "  " // Padding with 0 for single digit date
            +  DateFnsUtils.prototype.getHourText(utcDate, false) + ":"
            +  DateFnsUtils.prototype.getMinuteText(utcDate) +":"
            +  DateFnsUtils.prototype.getSecondText(utcDate) +" UTC";
}

export function getDateObjectFromTimestamp(timestamp: string) {
  return moment(timestamp, TIMESTAMP_TILL_SECS).toDate();
}

export function getDateTimeStringFromTimestamp(timestamp: string) {
  const date = getDateObjectFromTimestamp(timestamp);
  return moment(date).format(DATE_TIME_FORMAT_TILL_SEC);
}

export function getDateObjectFromTimestampInUTC(timestamp: string) {
  return moment.utc(timestamp, TIMESTAMP_TILL_SECS).toDate();
}


export function getDateTimeStringTillSecondsFromObj(obj: Date) {
  return moment(obj).format(DATE_TIME_FORMAT_TILL_SEC);
}

export function getLocalDateTimeStringFromTimestamp(timestamp: string) {
  return getDateTimeStringTillSecondsFromObj(getDateObjectFromTimestampInUTC(timestamp));
}