import React from "react"
import {Route, Switch} from "react-router-dom";
import Header from "./commons/header";
import {Provider} from "react-redux";
import store from "../model/store";
import history from "../history";
import NotFound from "./commons/not_found";
import {BodyDiv, MainDiv} from "./style/header_styles"
import ListNamespaces from "./commons/list_namespaces";
import Home from "src/view/commons/home";
import ListExperiments from "src/view/modelTraining/experiments/list_experiments";
import CreateExperiment from "src/view/modelTraining/experiments/create_experiment";
import CreateNamespace from "src/view/modelTraining/namespace/create_namespace";
import UpdateNamespace from "src/view/modelTraining/namespace/update_namespace";
import EditExperiment from "src/view/modelTraining/experiments/edit_experiment";
import NavigationBar from "./commons/navigationPanel/navigation_bar";
import {SnackBar} from "src/view/commons/snack_bar";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import ListConfigurations from "./configPanel/list_configurations";
import ViewConfiguration from "src/view/configPanel/view_configuration";
import FeatureGenerationNamespaceDetail from "./featureGeneration/namespace/feature_generation_namespace_detail";
import FeatureGenerationNamespaceForm from "./featureGeneration/namespace/feature_generation_namespace_form";
import FeatureGenerationJobsView from "./featureGeneration/jobs/feature_generation_jobs_view";
import FeatureGenerationJobForm from "./featureGeneration/jobs/feature_generation_job_form";
import EditConfiguration from "src/view/configPanel/edit_configuration";
import DatasetComponent from "src/view/dataset/dataset_component";
import ViewNamespace from "src/view/modelTraining/namespace/view_namespace";
import {ConnectedRouter} from "connected-react-router";
import ViewRunway from "./view_runway";
import Review from "./review/review";
import Merge from "./review/merge";
import CreateJob from "./runways/job/create_job";
import ViewJob from "./runways/job/view_job"
import UpdateJob from "./runways/job/update_job"
import ListJobs from "./runways/job/list_jobs"
import ListRunways from "./runways/list_runways";
import ViewJobTemplate from "./runways/jobTemplates/view_job_template"
import CreateJobTemplate from "./runways/jobTemplates/create_job_template"
import UpdateJobTemplate from "./runways/jobTemplates/update_job_template"
import ListJobTemplates from "./runways/jobTemplates/list_job_templates";
import UpdateRunway from "./update_runway"
import CreateRunway from "./create_runway"
import ViewJobExecutions from "./runways/job/job_executions"
import ViewRunwayExecutions from "./runways_executions"
import CodeRepositoryComponent from "src/view/codeRepository/code_repository_component";
import ListDataStudio from "src/view/notebooks/list_data_studio";
import ListNotebookProducts from "src/view/notebooks/list_notebook_products";
import ListDataStudioAddons from "src/view/notebooks/datastudio/list_data_studio_addons";
import ListDataStudioOnBoardings from "src/view/notebooks/datastudio/list_data_studio_onboardings";
import DataStudioManagedEndpoint from "src/view/notebooks/datastudio/data_studio_managed_endpoint";
import DataStudioOnboarding from "src/view/notebooks/datastudio/data_studio_onboarding";
import {OperationType} from "src/constant";

export const App = () => {
    //TODO: Change Router Logic With Routes.ts
    return (
        <Provider store={store}>
            <MainDiv style={{maxWidth: "1500px", margin: "auto"}}>
                <ConnectedRouter history={history}>
                    <Switch>
                        {/* Home Page */}
                        <Route path={"/"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <Home/>
                            </BodyDiv>
                        </Route>

                        {/*Admin Home Page */}
                        <Route path={"/admin"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <Home/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/admin/:teamId/notebooks"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListNotebookProducts/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/admin/:teamId/notebooks/dataStudio/onboardings"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListDataStudioOnBoardings/>
                            </BodyDiv>
                        </Route>

                        <Route path={["/admin/:teamId/notebooks/dataStudio/onboardings/:onboardingId/view", "/notebooks/:teamId/namespaces/:namespaceId/dataStudio/onboardings/:onboardingId/view"]} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <DataStudioOnboarding operationType={OperationType.VIEW}/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/admin/:teamId/notebooks/dataStudio/onboardings/:onboardingId/addons"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListDataStudioAddons/>
                            </BodyDiv>
                        </Route>
                        <Route
                            path={"/admin/:teamId/notebooks/dataStudio/onboardings/:onboardingId/addons/managed-endpoint/create"}
                            exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <DataStudioManagedEndpoint operationType={OperationType.CREATE}/>
                            </BodyDiv>
                        </Route>

                        <Route
                            path={["/admin/:teamId/notebooks/dataStudio/onboardings/:onboardingId/addons/:addonId/managed-endpoint/view", "/notebooks/:teamId/namespaces/:namespaceId/dataStudio/onboardings/:onboardingId/addons/:addonId/managed-endpoint/view"]}
                            exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <DataStudioManagedEndpoint operationType={OperationType.VIEW}/>
                            </BodyDiv>
                        </Route>

                        {/* Code Repo Pages */}
                        <Route path={"/codeRepositories"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CodeRepositoryComponent/>
                            </BodyDiv>
                        </Route>

                        {/* Datasets Pages */}
                        {/*TODO: Change Dataset Path Later If Needed*/}
                        <Route path={"/datasets"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <DatasetComponent/>
                            </BodyDiv>
                        </Route>

                        {/* Job Template Pages  */}
                        <Route path={"/jobTemplate"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListJobTemplates/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/jobTemplate/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CreateJobTemplate/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/jobTemplate/:jobTemplateId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ViewJobTemplate/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/jobTemplate/:jobTemplateId/update"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <UpdateJobTemplate/>
                            </BodyDiv>
                        </Route>

                        {/* Config Panel Pages */}
                        <Route path={"/configPanel/:teamId/namespaces"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListNamespaces/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/configPanel/:teamId/namespaces/:namespaceId/configs"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListConfigurations/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/configPanel/:teamId/namespaces/:namespaceId/configs/:configId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ViewConfiguration/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/configPanel/:teamId/namespaces/:namespaceId/configs/:configId/merge"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <Merge/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/configPanel/:teamId/namespaces/:namespaceId/configs/:configId/edit"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <EditConfiguration/>
                            </BodyDiv>
                        </Route>

                        {/* Feature Generation Pages  */}
                        <Route path={"/featureGeneration/:teamId/namespaces"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListNamespaces/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/featureGeneration/:teamId/namespaces/:namespaceId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <FeatureGenerationNamespaceDetail/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/featureGeneration/:teamId/namespaces/new"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <FeatureGenerationNamespaceForm/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/featureGeneration/:teamId/namespaces/:namespaceId/jobs"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <FeatureGenerationJobsView/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/featureGeneration/:teamId/namespaces/:namespaceId/jobs/new"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <FeatureGenerationJobForm/>
                            </BodyDiv>
                        </Route>


                        {
                            /**
                                 Model Training Pages
                                 TODO : Remove in future once its replaced from all user-guides and customer as accustomed to new urls
                             */
                        }
                        <Route path={"/modelTraining/:teamId/namespaces"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListNamespaces/>
                            </BodyDiv>
                        </Route>
                        <Route
                            path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/:runwayId/experiments/create"}
                            exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CreateExperiment/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/:runwayId/experiments"}
                               exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListExperiments/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CreateNamespace/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ViewNamespace/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/edit"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <UpdateNamespace/>
                            </BodyDiv>
                        </Route>
                        <Route
                            path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/:runwayId/:experimentName/edit"}
                            exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <EditExperiment/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/runways"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListRunways/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CreateRunway/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/:runwayId/:version/update"}
                               exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <UpdateRunway/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/:runwayId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ViewRunway/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/runways/:runwayId/executions"}
                               exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ViewRunwayExecutions/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/job"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListJobs/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/job/:jobId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ViewJob/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/job/:jobId/update"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <UpdateJob/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/modelTraining/:teamId/namespaces/:namespaceId/job/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CreateJob/>
                            </BodyDiv>
                        </Route>


                        {/**
                         Runway/Notebook Commons Pages
                         */}
                        <Route path={["/notebooks/:teamId/namespaces", "/runways/:teamId/namespaces"]} exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <ListNamespaces/>
                            </BodyDiv>
                        </Route>
                        <Route
                            path={["/notebooks/:teamId/namespaces/:namespaceId/view", "/runways/:teamId/namespaces/:namespaceId/view"]}
                            exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ViewNamespace/>
                            </BodyDiv>
                        </Route>
                        <Route path={["/notebooks/:teamId/namespaces/create", "/runways/:teamId/namespaces/create"]}
                               exact>
                            <Header/>
                            <NavigationBar/>
                            <BreadCrumb/>
                            <BodyDiv>
                                <CreateNamespace/>
                            </BodyDiv>
                        </Route>
                        <Route
                            path={["/notebooks/:teamId/namespaces/:namespaceId/edit", "/runways/:teamId/namespaces/:namespaceId/edit"]}
                            exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <UpdateNamespace/>
                            </BodyDiv>
                        </Route>

                        {/* Notebooks Pages */}
                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListNotebookProducts/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId/:runwayId/experiments"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListExperiments/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId/:runwayId/experiments/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <CreateExperiment/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId/:runwayId/:experimentName/edit"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <EditExperiment/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId/dataStudios"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListDataStudio/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId/dataStudio/onboardings"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListDataStudioOnBoardings/>
                            </BodyDiv>
                        </Route>

                        <Route path={"/notebooks/:teamId/namespaces/:namespaceId/dataStudio/onboardings/:onboardingId/addons"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListDataStudioAddons/>
                            </BodyDiv>
                        </Route>


                        {/* Runway Pages */}
                        <Route path={"/runways/:teamId/namespaces/:namespaceId"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListRunways/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <CreateRunway/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/:runwayId/:version/update"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <UpdateRunway/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/:runwayId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ViewRunway/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/:runwayId/executions"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ViewRunwayExecutions/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/job"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ListJobs/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/job/:jobId/view"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ViewJob/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/job/:jobId/update"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <UpdateJob/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/job/create"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <CreateJob/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/runways/:teamId/namespaces/:namespaceId/:runwayId/jobs/:jobId/executions"} exact>
                            <Header/>
                            <NavigationBar/>
                            <BodyDiv>
                                <ViewJobExecutions/>
                            </BodyDiv>
                        </Route>


                        {/* Review Pages */}
                        <Route path={"/review/:reviewId"} exact>
                            <Header/>
                            <BodyDiv style={{top: "50px"}}>
                                <Review/>
                            </BodyDiv>
                        </Route>
                        <Route path={"/repository/merge"} exact>
                            <Header/>
                            <BodyDiv>
                                <Merge/>
                            </BodyDiv>
                        </Route>

                        {/* Other Pages*/}
                        <Route path={"/notFound"} exact component={NotFound}/>
                        <Route component={NotFound}/>
                    </Switch>
                </ConnectedRouter>
                <SnackBar timeOut={10000}/>
            </MainDiv>
        </Provider>
    );
}

export default App;