import styled from "styled-components";

export const NavHeaderBar = styled.div`
    top: 50px;
    height: 40px;
    left: 0;
    right: 0;
    position: fixed;
    margin: 0 auto !important;
    max-width: 98vw;
    z-index: 10;
    background: #002e36;
    color: white !important;
`;

export const NavSideBar = styled.div <{sidebar: any}>`
    background: #002e36;
    width: 300px;
    height: 100vh;
    top: 50px;
    left: ${({ sidebar }) => (sidebar ? '0.5vw' : '-100%')};
    position: fixed;
    transition: 350ms;
    z-index: 15;
`;

export const NavSideBarWrapper = styled.div`
    width: 100%;
`;

export const NavIconWrapper = styled.div`
    padding: 0.5rem;
`;

export const NavPadding = styled.div`
    padding: 10px;
`;

export const NavListItem = styled.div`
    display: flex;
    align-items: center;
    padding: 20px;
    list-style: none;
    height: 40px;
    text-decoration: none;
    font-size: 15px;
    color: #fff;
    &:hover {
        background-color: #52686b !important;
        border-left: 4px solid #008295;
        cursor: pointer;
    }
`;

export const NavItemLabel = styled.span`
    margin-left: 20px;
`;

export const NavItemPadding = styled.div`
    padding-left: 25px !important;
`;