import { Job } from "./job_model";

export interface Runway {
  domain: string;
  namespaceId: string;
  runwayId: string;
  runwayName: string;
  version: number;
  latestVersion: number;
  createdBy: string;
  creationTime: string;
  lastUpdatedBy: string;
  lastUpdatedTime: string;
  runwayDAG: Job[];
  runwayStatus: RunwayStatus;
  runwayRelease: number;
}

export enum RunwayStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
  CREATING = "CREATING",
  CREATE_FAILED = "CREATE_FAILED",
  UPDATING = "UPDATING",
  UPDATE_FAILED = "UPDATE_FAILED",
}

export interface InitialRunwayState {
  fetchedLatestVersionRunway: Runway;
  fetchedRunway: Runway;
  fetchedRunwayVersionsList: Runway[];
  isSpinnerLoading: boolean;
  fetchedRunwayList: Runway[];
  fetchRunwayExecutions: {
    executionsList: any[];
    // Keeping load more enabled
    loadMore: boolean;
    runwaysExecutionsFetched: boolean;
    fetchAllExecutionsSuccess: boolean;
  };
  executionMetrics: {
    metricData: {};
    runwaysExecutionMetricsFetched: boolean;
    fetchRunwaysExecutionMetricsSuccess: boolean;
  };
}

export const initialRunwayState: InitialRunwayState = {
  fetchedLatestVersionRunway: {
    domain: "",
    namespaceId: "",
    runwayId: "",
    runwayName: "",
    version: 1,
    latestVersion: -1,
    createdBy: "",
    creationTime: "",
    lastUpdatedBy: "",
    lastUpdatedTime: "",
    runwayDAG: [],
    runwayStatus: RunwayStatus.DISABLED,
    runwayRelease: -1,
  },
  fetchedRunway: {
    domain: "",
    namespaceId: "",
    runwayId: "",
    runwayName: "",
    version: 1,
    latestVersion: 1,
    createdBy: "",
    creationTime: "",
    lastUpdatedBy: "",
    lastUpdatedTime: "",
    runwayDAG: [],
    runwayStatus: RunwayStatus.DISABLED,
    runwayRelease: -1,
  },
  fetchRunwayExecutions: {
    executionsList: <any>[],
    // Keeping load more enabled
    loadMore: true,
    runwaysExecutionsFetched: false,
    fetchAllExecutionsSuccess: false,
  },
  executionMetrics: {
    metricData: {},
    runwaysExecutionMetricsFetched: false,
    fetchRunwaysExecutionMetricsSuccess: false,
  },
  fetchedRunwayVersionsList: [],
  isSpinnerLoading: false,
  fetchedRunwayList: [],
};
