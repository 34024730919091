import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    namespaceSelector,
    runwaySelector,
    trainingImagesSelector,
    updateRunwaySelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {Dataset, DatasetDefaultConstants} from "src/view/dataset/dataset_constants";
import {allDatasetsSelector} from "src/control/selectors/dataset/dataset_selectors";
import React, {useEffect, useRef, useState} from "react";
import {
    CUSTOM_TRAINING_IMAGE,
    FETCH_RUNWAY_ERROR_MESSAGE,
    getEcrReposOptions,
    NO_DATASETS_FOUND_MESSAGE,
    NO_ONBOARDED_PACKAGES_MESSAGE,
    UPDATE_RUNWAY_ERROR_MESSAGE,
    UPDATE_RUNWAY_SUCCESS_MESSAGE
} from "src/view/modelTraining/constants";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {fetchAllDatasets} from "src/control/actions/dataset/dataset_actions";
import {extractVersionIdFromSignedUrl, isEmpty, setIfMounted} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {loadRunway, loadTrainingImages, updateRunway} from "src/control/actions/modelTraining/runway_actions";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles";
import {
    KatButton,
    KatCard,
    KatDivider,
    KatFlashbar,
    KatLabel,
    KatRadiobuttonGroup,
    KatSpinner
} from "@amzn/katal-react";
import {CouldNotLoadAlert} from "src/view/style/common_styles";
import {INSTANCE_TYPES} from "src/view/modelTraining/experiments/instance_types";
import JSONEditor from "src/view/commons/json_editor";
import {PostTrain, PreTrain, Train} from "src/view/modelTraining/runways/step_info";
import {convertToJsonWithStrings, getPrettyJsonString, isValidJSON} from "src/utils/json_utils";
import {getFeaturesAsList, isValidFeatureList} from "src/view/modelTraining/runways/commons";
import {VStatusIndicator} from "src/view/style/view_config_styles";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {DEFAULT_BRANCH, JobStep, LATEST_CODE_VERSION, ModelTrainingStep} from "src/constant";
import {
    allCodeRepositoriesSelector,
    executionCodeVersionsSelector,
    postExecutionCodeVersionsSelector,
    preExecutionCodeVersionsSelector
} from "src/control/selectors/codeRepository/code_repo_selectors";
import {getCodeVersions, loadCodeRepositories} from "src/control/actions/codeRepository/code_repository_actions";
import {RepositoryType} from "src/model/runways/job_model";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

const moment = require("moment")

const UpdateV1Runway = () => {
    const params: any = useParams();
    const dispatch = useDispatch();
    const TIMESTAMP_FORMAT = "YYYYMMDDHHmmss"

    // Selectors
    let trainingImagesInfo = useSelector(trainingImagesSelector);
    let userId = useSelector(usernameSelector);
    let datasets: Dataset[] = useSelector(allDatasetsSelector);
    let fetchedNamespace = useSelector(namespaceSelector);
    let getRunwayData = useSelector(runwaySelector);
    let updateRunwayData = useSelector(updateRunwaySelector);
    let codeRepositoriesSelector = useSelector(allCodeRepositoriesSelector);

    let preTrainCodeVersions = useSelector(preExecutionCodeVersionsSelector);
    let postTrainCodeVersions = useSelector(postExecutionCodeVersionsSelector);
    let trainCodeVersions = useSelector(executionCodeVersionsSelector)
    let _isMounted = useRef(true);

    // State hooks for general runway information
    const [runwayName, setRunwayName] = useState('');
    const [domain, setDomain] = useState('');
    const [enableCustomImageInput, setEnableCustomImageInput] = useState(false);
    const [instanceType, setInstanceType] = useState('ml.t2.medium');
    const [trainingImage, setTrainingImage] = useState('');
    const [instanceCount, setInstanceCount] = useState("1");
    const [volumeSize, setVolumeSize] = useState("50");
    const [trainingExecutionRoleArn, setTrainingExecutionRoleARN] = useState();
    const [hyperParameters, setHyperParameters] = useState('');
    const [modelOutputPath, setModelOutputPath] = useState('');
    const [inputFeatures, setInputFeatures] = useState('');
    const [responseVariables, setResponseVariables] = useState('');
    const [modelTrainingMetadata, setModelTrainingMetadata] = useState('{}');
    const [scheduleFrequencyInMins, setScheduleFrequencyInMins] = useState(60)
    const [offsetInfo, setOffsetInfo] = useState({days: 0, hours: 0, minutes: 0})
    const [customCodeRepository, setCustomCodeRepository] = useState('')
    const [codeRepoOptions, setCodeRepoOptions] = useState<any[]>([]);
    const [ecrRepo, setEcrRepo] = useState('')
    const [baseTimestamp, setBaseTimestamp] = useState(moment('20220101000000', TIMESTAMP_FORMAT).toDate());
    const DISPLAY_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss"


    // State for step specific information
    const [preTrainStepInfo, setPreTrainStepInfo] = useState<PreTrain>({
        preTrainCustomCodeURI: "",
        modelAgeThreshold: -1,
        codeVersion: "",
    })
    const [trainStepInfo, setTrainStepInfo] = useState<Train>({
        inputChannelInfoList: [],
        trainCustomCodeURI: "",
        codeVersion: "",
    })
    const [postTrainStepInfo, setPostTrainStepInfo] = useState<PostTrain>({
        deployArtifactName: "",
        deploymentBucket: "",
        deploymentKey: "",
        thresholdConditionsJson: "",
        postTrainCustomCodeURI: "",
        codeVersion: "",
        archiveSubFolder: ""
    })

    // State for callback of fetch all data sets action.
    const [dataSetLoadErrorOccurred, setDataSetLoadErrorOccurred] = useState(false);

    const showMessage = (getRunwayData.runwayFetched && !getRunwayData.showRunwaySuccess) ||
        updateRunwayData.isSuccess ||
        updateRunwayData.isError

    const isSuccessMessage = getRunwayData.showRunwaySuccess && updateRunwayData.isSuccess

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    const getMessage = () => {
        if (updateRunwayData.isError) {
            return UPDATE_RUNWAY_ERROR_MESSAGE
        } else if (getRunwayData.runwayFetched && !getRunwayData.showRunwaySuccess) {
            return FETCH_RUNWAY_ERROR_MESSAGE
        } else {
            return UPDATE_RUNWAY_SUCCESS_MESSAGE
        }
    }

    const getValueOrEmptyPlaceholder = (value: any) => {
        return !isEmpty(value) ? value : ""
    }

    const isNullModelTrainingInfo = isEmpty(getRunwayData.runway.modelTrainingInfo)
    const isNullPreTrain = isNullModelTrainingInfo || isEmpty(getRunwayData.runway.modelTrainingInfo.preTrain)
    const isNullTrain = isNullModelTrainingInfo || isEmpty(getRunwayData.runway.modelTrainingInfo.train)
    const isNullPostTrain = isNullModelTrainingInfo || isEmpty(getRunwayData.runway.modelTrainingInfo.postTrain)
    const isNullFeatures = isNullModelTrainingInfo || isEmpty(getRunwayData.runway.modelTrainingInfo.features)
    const isNullModelTrainingMetadata = isNullModelTrainingInfo || isEmpty(getRunwayData.runway.modelTrainingInfo.modelTrainingMetadata)

    const setCodeRepositories = () => {
        const codeRepositories: any[] = []
        if (codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess) {
            codeRepositoriesSelector.codeRepositories.forEach((codeRepository: any) => {
                    let repositoryName = ""
                    if (codeRepository.codeRepository.gitRepository.syncEnabled) {
                        repositoryName = codeRepository.codeRepository.gitRepository.packageName
                    }
                    if (!isEmpty(repositoryName)) {
                        codeRepositories.push({
                            name: repositoryName,
                            value: repositoryName
                        })
                    }
                }
            )
            setCodeRepoOptions(codeRepositories)
        }
    }

    useEffect(() => {
        setCodeRepositories()
    }, [codeRepositoriesSelector]);


    useEffect(() => {
        dispatch(loadCodeRepositories({
            repositoryType: RepositoryType.GIT
        }))
    }, []);

    /**
     * Initializes form with fields fetched from get Runway.
     */
    const initializeStates = () => {
        if (!isNullModelTrainingInfo) {
            setIfMounted(_isMounted.current, setBaseTimestamp, isEmpty(getRunwayData.runway.baseTimestamp) ? baseTimestamp : getValueOrEmptyPlaceholder(moment((getRunwayData.runway.baseTimestamp).toString(), TIMESTAMP_FORMAT).toDate()))
            setIfMounted(_isMounted.current, setScheduleFrequencyInMins, getValueOrEmptyPlaceholder(getRunwayData.runway.scheduleFrequencyInMins))
            setIfMounted(_isMounted.current, setOffsetInfo, isEmpty(getRunwayData.runway.offsetInfo) ? {
                days: 0,
                hours: 0,
                minutes: 0
            } : getRunwayData.runway.offsetInfo)
        }

        if (!isNullPreTrain) {
            setIfMounted(_isMounted.current, setPreTrainStepInfo, {
                ...preTrainStepInfo,
                preTrainCustomCodeURI: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.preTrain.preTrainCustomCodeURI),
                modelAgeThreshold: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.preTrain.modelAgeThreshold),
                codeVersion: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.preTrain.codeVersion)
            })
        }

        if (!isNullTrain) {
            setIfMounted(_isMounted.current, setTrainingImage, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.trainingImage))
            setIfMounted(_isMounted.current, setInstanceType, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.instanceType))
            setIfMounted(_isMounted.current, setInstanceCount, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.instanceCount))
            setIfMounted(_isMounted.current, setVolumeSize, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.volumeSizeInGB))
            setIfMounted(_isMounted.current, setTrainingExecutionRoleARN, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.trainingExecutionRole))
            setIfMounted(_isMounted.current, setHyperParameters, JSON.stringify(getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.hyperparameters), null, 2))
            setIfMounted(_isMounted.current, setModelOutputPath, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.modelOutputPath))
            setIfMounted(_isMounted.current, setCustomCodeRepository, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.customCodePackageName))
            setIfMounted(_isMounted.current, setTrainStepInfo, {
                ...trainStepInfo,
                trainCustomCodeURI: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.trainCustomCodeURI),
                inputChannelInfoList: getRunwayData.runway.modelTrainingInfo.train.inputChannelInfoList,
                codeVersion: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.codeVersion)
            })
        }
        if (!isNullPostTrain) {
            setIfMounted(_isMounted.current, setPostTrainStepInfo, {
                ...postTrainStepInfo,
                postTrainCustomCodeURI: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.postTrainCustomCodeURI),
                deployArtifactName: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.deployArtifactName),
                deploymentBucket: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.deploymentBucket),
                deploymentKey: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.deploymentKey),
                thresholdConditionsJson: getPrettyJsonString(getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.thresholdConditionsJson)),
                archiveSubFolder: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.archiveSubFolder),
                codeVersion: getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.postTrain.codeVersion)
            })
        }

        if (!isNullFeatures) {
            let _inputFeatures = getRunwayData.runway.modelTrainingInfo.features.inputFeatures
            _inputFeatures = _inputFeatures && _inputFeatures.join(",")
            let _responseVariables = getRunwayData.runway.modelTrainingInfo.features.responseVariables
            _responseVariables = _responseVariables && _responseVariables.join(",")
            setIfMounted(_isMounted.current, setInputFeatures, getValueOrEmptyPlaceholder(_inputFeatures))
            setIfMounted(_isMounted.current, setResponseVariables, getValueOrEmptyPlaceholder(_responseVariables))
        }

        if (!isNullModelTrainingMetadata) {
            let _modelTrainingMetadata = getRunwayData.runway.modelTrainingInfo.modelTrainingMetadata;
            _modelTrainingMetadata = getPrettyJsonString(_modelTrainingMetadata.toString())
            setIfMounted(_isMounted.current, setModelTrainingMetadata, getValueOrEmptyPlaceholder(_modelTrainingMetadata))
        }

        setIfMounted(_isMounted.current, setRunwayName, getValueOrEmptyPlaceholder(getRunwayData.runway.runwayName))
    }

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
            dispatch(loadRunway(params.runwayId, params.version))
            dispatch(fetchAllDatasets(() => {
                },
                (error: any) => {
                    setIfMounted(_isMounted.current, setDataSetLoadErrorOccurred, true);
                    dispatch(showSnackBar(error.message));
                }, {
                    pageSize: DatasetDefaultConstants.PAGE_SIZE,
                    nextToken: null
                }))
        }
    }, [userId, params.teamId, params.namespaceId, params.runwayId, params.version]);

    useEffect(() => {
        initializeStates()
        if (!isEmpty(getRunwayData.runway.modelTrainingInfo.customCodePackageName)) {
            dispatch(getCodeVersions({
                    repositoryType: RepositoryType.GIT,
                    scriptPath: getRunwayData.runway.modelTrainingInfo.preTrain.preTrainCustomCodeURI,
                    branch: DEFAULT_BRANCH
                },
                getRunwayData.runway.modelTrainingInfo.customCodePackageName,
                JobStep.PRE_EXECUTION
            ));

            dispatch(getCodeVersions({
                    repositoryType: RepositoryType.GIT,
                    scriptPath: getRunwayData.runway.modelTrainingInfo.train.trainCustomCodeURI,
                    branch: DEFAULT_BRANCH
                },
                getRunwayData.runway.modelTrainingInfo.customCodePackageName,
                JobStep.EXECUTION
            ));

            dispatch(getCodeVersions({
                    repositoryType: RepositoryType.GIT,
                    scriptPath: getRunwayData.runway.modelTrainingInfo.postTrain.postTrainCustomCodeURI,
                    branch: DEFAULT_BRANCH
                },
                getRunwayData.runway.modelTrainingInfo.customCodePackageName,
                JobStep.POST_EXECUTION
            ));
        }
    }, [getRunwayData])

    const getEcrRepos = () => {
        const handleEcrReposRadioChange = (event: any) => {
            if (event.target.value == CUSTOM_TRAINING_IMAGE) {
                setIfMounted(_isMounted.current, setEnableCustomImageInput, true)
            } else {
                if (!isNullTrain) {
                    setIfMounted(_isMounted.current, setTrainingImage, getValueOrEmptyPlaceholder(getRunwayData.runway.modelTrainingInfo.train.trainingImage))
                } else {
                    setIfMounted(_isMounted.current, setTrainingImage, "")
                }
                setIfMounted(_isMounted.current, setEnableCustomImageInput, false)
                dispatch(loadTrainingImages(event.target.value))

            }
            setIfMounted(_isMounted.current, setEcrRepo, event.target.value)
        }


        return (
            <>
                <KatLabel tooltipText="ECR repository types">ECR Repository
                    :&nbsp;
                </KatLabel>
                <KatRadiobuttonGroup name={"ecrRepos"}
                                     options={getEcrReposOptions()}
                                     onChange={handleEcrReposRadioChange}>
                </KatRadiobuttonGroup>
            </>
        )
    }

    /**
     * Renders the view to select dataset for each channel.
     *
     * @param handleDropDownChange: The function to be called on event of change of selected dataset.
     * @param initialDataSet: The initial data set value.
     */
    const getDataSetsView = (handleDropDownChange: any, channelIndex: number) => {
        let dataSetIndex = "-1"
        const dataSetOptions: any[] = []
        datasets.forEach((dataset: any, index: number) => {
                dataSetOptions.push({
                    "name": dataset.datasetName,
                    "value": index.toString()
                })
                if (trainStepInfo.inputChannelInfoList[channelIndex].datasetInfoName == dataset.datasetName) {
                    dataSetIndex = index.toString()
                }
            }
        )
        return (
            <>
                {
                    datasets.length > 0 ?
                        <FormDropDown
                            value={dataSetIndex === "-1" ? "" : dataSetIndex}
                            options={dataSetOptions}
                            onChange={handleDropDownChange}/>
                        : NO_DATASETS_FOUND_MESSAGE
                }
            </>
        )
    }

    /**
     * Renders the available code repositories in a radio button group field, from which the custom code package
     * can be chosen.
     *
     */
    const getCodeRepositories = () => {
        return (
            <>
                {

                    <>
                        <KatLabel
                            tooltipText={`Select package whose code you want to be made available.`}>
                            Packages :&nbsp;
                        </KatLabel>
                    </>
                }
                {
                    <>
                        {
                            <>
                                {
                                    codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess ?
                                        (codeRepoOptions.length > 0 ?
                                                <>
                                                    <FormDropDown
                                                        searchable={true}
                                                        placeholder="Select Repository"
                                                        name={"customCodeRepository"}
                                                        value={customCodeRepository}
                                                        options={
                                                            codeRepoOptions
                                                        }
                                                        onChange={(event: any) => {
                                                            setIfMounted(_isMounted.current, setCustomCodeRepository, event.target.value)
                                                        }}
                                                    >
                                                    </FormDropDown>
                                                </>
                                                :
                                                <KatFlashbar header="Note"
                                                             description={NO_ONBOARDED_PACKAGES_MESSAGE}
                                                />
                                        )
                                        :
                                        (codeRepositoriesSelector.codeRepositoriesFetched && !codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess ?
                                                <>
                                                    {dispatch(showSnackBar("Error loading Code Repositories"))}
                                                </> :
                                                (codeRepositoriesSelector.startCodeRepositoriesLoad ?
                                                        <KatSpinner/> : <></>
                                                )
                                        )
                                }
                            </>
                        }
                    </>
                }
            </>
        )
    }

    /**
     * Renders the available training images in a radio button group field, from which training
     * image can be chosen.
     */
    const getTrainingImages = () => {
        const handleTrainingImagesChange = (event: any) => {
            setIfMounted(_isMounted.current, setTrainingImage, event.target.value)
        }
        const trainingImageOptions: any[] = []
        trainingImagesInfo.trainingImages.imageList.forEach((trainingImage: any) =>
            trainingImageOptions.push({
                    "name": trainingImage.imageTag,
                    "value": trainingImage.imageTag
                }
            )
        )
        return (
            <>
                <KatLabel tooltipText="This is the training ECR docker image that will be used for the training job">Training
                    Image
                    :&nbsp;</KatLabel>
                {
                    <>
                        {
                            trainingImageOptions.length > 0 ?
                                <FormDropDown
                                    value={trainingImageOptions[0].value}
                                    options={trainingImageOptions}
                                    onChange={handleTrainingImagesChange}
                                /> :
                                trainingImagesInfo.startLoader ?
                                    <KatSpinner/> :
                                    <KatFlashbar header="Note" description="No Images found"/>
                        }
                    </>
                }
            </>
        )
    }

    /**
     * Returns the view for code script field.
     */
    const getCodeScriptPathView = (initialValue: string, handleCodePathChange: (event: any) => void) => {
        return (
            <>
                <FormInput
                    label="Code script path"
                    type='text'
                    placeholder='Code script path'
                    tooltipText="Code script path"
                    disabled={!getRunwayData.showRunwaySuccess}
                    value={initialValue}
                    onBlur={handleCodePathChange}
                />
            </>
        )
    }


    const getCodeVersionList = (step: string) => {
        switch (step) {
            case ModelTrainingStep.PRE_TRAIN :
                return preTrainCodeVersions;
            case ModelTrainingStep.POST_TRAIN :
                return postTrainCodeVersions;
            case ModelTrainingStep.TRAIN :
                return trainCodeVersions;
            default :
                return {codeVersionsList: []}
        }
    }

    const getCodeVersionsListDropDown = (initialValue: string, step: string, handleCodeVersionChange: (event: any) => void) => {
        const options: any[] = []
        let codeVersions = getCodeVersionList(step)
        let codeVersionsList = codeVersions.codeVersionsList

        options.push({
            "name": LATEST_CODE_VERSION,
            "value": LATEST_CODE_VERSION
        })
        let index = 1;
        let currentVal = "";
        codeVersionsList.forEach((codeVersion: any) => {
            const timestamp = moment.utc(new Date(codeVersion.timestamp * 1000)).format(DISPLAY_TIMESTAMP_FORMAT)
            options.push({
                "name": `[` + timestamp.toString() + `UTC] V${index++}`,
                "value": codeVersion.signedS3Url
            })
            if (extractVersionIdFromSignedUrl(codeVersion.signedS3Url) == initialValue) {
                currentVal = codeVersion.signedS3Url
            }
        });
        return (
            <>
                {
                    codeVersions.startLoader ?
                        <KatSpinner/> :
                        !isEmpty(codeVersionsList) ?
                            <FormDropDown
                                max-height="200px"
                                options={options}
                                value={!isEmpty(currentVal) ? currentVal : LATEST_CODE_VERSION}
                                label="Select Code Version"
                                placeholder='Select Code Version'
                                tooltipText='Select Code Version'
                                onChange={handleCodeVersionChange}>
                            </FormDropDown> :
                            <KatFlashbar header="Note" description="No Code Versions found"/>

                }
            </>
        )
    }

    /**
     * Adds a channel to the channels array.
     */
    const addChannel = () => {
        const channels = [...trainStepInfo.inputChannelInfoList]
        channels.push({
            channelName: "",
            datasetInfoName: "",
            s3PathTemplate: ""
        })
        setIfMounted(_isMounted.current, setTrainStepInfo, {
                ...trainStepInfo,
                inputChannelInfoList: channels
            }
        )
    }

    /**
     * Removes the the channel for training.
     *
     * @param index: Index of the channel in channels array.
     */
    const removeChannel = (index: number) => {
        const channels = [...trainStepInfo.inputChannelInfoList]
        channels.splice(index, 1)
        setIfMounted(_isMounted.current, setTrainStepInfo, {
            ...trainStepInfo,
            inputChannelInfoList: channels
        })
    }

    /**
     * Renders the input for channels required for training.
     */
    const getChannels = () => {
        const handleChannelNameChange = (event: any, index: number) => {
            const channels = [...trainStepInfo.inputChannelInfoList]
            channels[index].channelName = event.target.value
            setIfMounted(_isMounted.current, setTrainStepInfo, {
                ...trainStepInfo,
                inputChannelInfoList: channels
            })
        }
        const handleDataSetChange = (event: any, index: number) => {
            const channels = [...trainStepInfo.inputChannelInfoList]
            channels[index].datasetInfoName = datasets[parseInt(event.target.value)].datasetName
            channels[index].s3PathTemplate = datasets[parseInt(event.target.value)].s3Path
            setIfMounted(_isMounted.current, setTrainStepInfo, {
                ...trainStepInfo,
                inputChannelInfoList: channels
            })
        }
        return (
            <>
                {
                    trainStepInfo.inputChannelInfoList.map((channel: any, index) => {
                        return (
                            <>
                                <FormInput
                                    label="Channel Name"
                                    type='text'
                                    placeholder='Channel Name'
                                    value={channel.channelName}
                                    tooltipText="Name of the model training channel"
                                    onChange={(event: any) => handleChannelNameChange(event, index)}
                                />
                                <KatLabel tooltipText="Select dataset for the channel.">
                                    Dataset :&nbsp;
                                </KatLabel>
                                {
                                    getDataSetsView((event: any) => {
                                            handleDataSetChange(event, index)
                                        }, index
                                    )
                                }
                                <KatButton type="submit" label="Remove" variant="primary" size="small"
                                           onClick={(event: any) => {
                                               removeChannel(index)
                                           }}
                                />
                                <KatDivider variant={"eastern"}/>
                            </>
                        )
                    })}
            </>
        )
    }

    const enforceRequiredParams = () => {
        const isTrainingImagePresent = !isEmpty(trainingImage)
        const isCustomCodePackagePresent = isEmpty(trainStepInfo.trainCustomCodeURI) || !isEmpty(customCodeRepository)
        !isTrainingImagePresent && dispatch(showSnackBar("Image not found"))
        !isCustomCodePackagePresent && dispatch(showSnackBar("CustomCodePackage not found"))
        return isTrainingImagePresent && isCustomCodePackagePresent
    }

    const performSanityChecksForUpdate = () => {
        !isValidJSON(modelTrainingMetadata) && dispatch(showSnackBar("Invalid Model Training Metadata (JSON) found"))
        !isValidFeatureList(inputFeatures) && dispatch(showSnackBar("Invalid input feature list found"))
        !isValidFeatureList(responseVariables) && dispatch(showSnackBar("Invalid response variables found"))
        !isValidJSON(hyperParameters) && dispatch(showSnackBar("Invalid Hyperparmeters (JSON) found"))
        !isValidJSON(postTrainStepInfo.thresholdConditionsJson) && dispatch(showSnackBar("Invalid Threshold Condition (JSON) found"))
        return (
            isValidJSON(modelTrainingMetadata)
            && isValidFeatureList(inputFeatures)
            && isValidFeatureList(responseVariables)
            && isValidJSON(hyperParameters)
            && isValidJSON(postTrainStepInfo.thresholdConditionsJson)
        )
    }
    /**
     * Submits the update runway form to expresso service.
     *
     * @param event
     */
    const submitUpdateRunwayForm = (event: any) => {
        enforceRequiredParams() &&
        performSanityChecksForUpdate() &&
        dispatch(updateRunway(params.runwayId, {
            scheduleFrequencyInMins: scheduleFrequencyInMins,
            offsetInfo: offsetInfo,
            baseTimestamp: Number(moment(baseTimestamp).format(TIMESTAMP_FORMAT)),
            modelTrainingInfo: {
                customCodePackageName: customCodeRepository,
                preTrain: {
                    preTrainCustomCodeURI: preTrainStepInfo.preTrainCustomCodeURI,
                    modelAgeThreshold: preTrainStepInfo.modelAgeThreshold,
                    codeVersion: preTrainStepInfo.codeVersion
                },
                train: {
                    trainingImage: ecrRepo == CUSTOM_TRAINING_IMAGE || isEmpty(ecrRepo) ? trainingImage :
                        (!isEmpty(trainingImagesInfo.trainingImages.imageUri) && !isEmpty(trainingImage) ? trainingImagesInfo.trainingImages.imageUri + ":" + trainingImage : ""),
                    trainCustomCodeURI: trainStepInfo.trainCustomCodeURI,
                    volumeSizeInGB: volumeSize,
                    trainingExecutionRole: trainingExecutionRoleArn,
                    instanceType: instanceType,
                    instanceCount: instanceCount,
                    hyperparameters: convertToJsonWithStrings(hyperParameters),
                    inputChannelInfoList: trainStepInfo.inputChannelInfoList,
                    modelOutputPath: modelOutputPath,
                    codeVersion: trainStepInfo.codeVersion
                },
                postTrain: {
                    postTrainCustomCodeURI: postTrainStepInfo.postTrainCustomCodeURI,
                    deployArtifactName: postTrainStepInfo.deployArtifactName,
                    deploymentBucket: postTrainStepInfo.deploymentBucket,
                    deploymentKey: postTrainStepInfo.deploymentKey,
                    thresholdConditionsJson: postTrainStepInfo.thresholdConditionsJson,
                    archiveSubFolder: postTrainStepInfo.archiveSubFolder,
                    codeVersion: postTrainStepInfo.codeVersion
                },
                features: {
                    inputFeatures: getFeaturesAsList(inputFeatures),
                    responseVariables: getFeaturesAsList(responseVariables)
                },
                modelTrainingMetadata: getPrettyJsonString(modelTrainingMetadata)
            },
            requestedBy: userId
        }))
    }

    return (
        <>
            {
                (dataSetLoadErrorOccurred) ?
                    <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                        Could Not Load Datasets
                    </CouldNotLoadAlert> : null
            }
            {showMessage ?
                <KatFlashbar variant={isSuccessMessage ? "success" : "danger"}
                             header={getMessage()}
                >
                </KatFlashbar>
                : updateRunwayData.startLoader ? <KatSpinner/> : null
            }
            {
                !isEmpty(fetchedNamespace.namespaceData.namespace) && !isEmpty(runwayName) ?
                    <>
                        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                    runwayName={runwayName}></BreadCrumb>
                        <KatCard>
                            <span slot='title'> Update Runway - {runwayName}</span>
                            <FormInput
                                label="Namespace Name"
                                type='text'
                                placeholder='Namespace name'
                                value={fetchedNamespace.namespaceData.namespace}
                                tooltipText="Namespace Name"
                                disabled={true}
                            />
                            <FormInput
                                label="Created By"
                                type='text'
                                placeholder='Created By'
                                value={getRunwayData.runway.createdBy}
                                tooltipText="Creator of the runway"
                                disabled
                            />
                            <FormInput
                                label="Creation time"
                                type='text'
                                placeholder='Creation time'
                                value={getRunwayData.runway.creationTime}
                                tooltipText="Time of creation of the runway"
                                disabled
                            />
                            <FormInput
                                label="Domain"
                                type='text'
                                placeholder='Domain'
                                value={getValueOrEmptyPlaceholder(getRunwayData.runway.domain)}
                                tooltipText="Domain of the runway."
                                disabled
                            />
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'> Scheduling</span>
                            <br/>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    variant="inline"
                                    ampm={false}
                                    label="Job base time (UTC)"
                                    value={baseTimestamp}
                                    onChange={(date: MaterialUiPickersDate) => {
                                        if (date)
                                            setBaseTimestamp(date)
                                    }}
                                    format="yyyy/MM/dd HH:mm"
                                />
                            </MuiPickersUtilsProvider>
                            <FormInput
                                label="Scheduling frequency(in minutes)"
                                type='number'
                                placeholder='Scheduling frequency'
                                value={scheduleFrequencyInMins.toString()}
                                min={1}
                                tooltipText="Volume size"
                                disabled={!getRunwayData.showRunwaySuccess}
                                onChange={(event: any) => setScheduleFrequencyInMins(parseInt(event.target.value))}
                            />
                            <FormInput
                                label="Offset (in minutes)"
                                type='number'
                                value={offsetInfo.minutes.toString()}
                                tooltipText="Timestamp offset for the scheduled executions. Ex: if offset is -60, a job executed at 5:00 hrs, will resolve the timestamp for data as 4:00 hrs"
                                disabled={!getRunwayData.showRunwaySuccess}
                                onChange={(event: any) => setOffsetInfo({
                                    days: 0,
                                    hours: 0,
                                    minutes: parseInt(event.target.value)
                                })}
                            />
                            {getCodeRepositories()}
                            <br/>
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'>Pre-Train</span>
                            {getCodeScriptPathView(preTrainStepInfo.preTrainCustomCodeURI, (event: any) => {
                                    setIfMounted(
                                        _isMounted.current, setPreTrainStepInfo, {
                                            ...preTrainStepInfo,
                                            preTrainCustomCodeURI: event.target.value,
                                        })
                                    dispatch(getCodeVersions({
                                            repositoryType: RepositoryType.GIT,
                                            scriptPath: event.target.value,
                                            branch: DEFAULT_BRANCH
                                        }, customCodeRepository,
                                        JobStep.PRE_EXECUTION
                                    ));
                                }
                            )
                            }
                            {
                                !isEmpty(preTrainStepInfo.preTrainCustomCodeURI) ?
                                    getCodeVersionsListDropDown(preTrainStepInfo.codeVersion, ModelTrainingStep.PRE_TRAIN, (event: any) => {
                                        setIfMounted(
                                            _isMounted.current, setPreTrainStepInfo, {
                                                ...preTrainStepInfo,
                                                codeVersion: extractVersionIdFromSignedUrl(event.target.value),
                                            })
                                        if (event.target.value != LATEST_CODE_VERSION) {
                                            window.open(event.target.value, '_blank')?.focus();
                                        }
                                    }) : null
                            }
                            <FormInput
                                label="Model age threshold"
                                type='number'
                                placeholder='Model age threshold'
                                value={preTrainStepInfo.modelAgeThreshold.toString()}
                                tooltipText="Model age threshold"
                                disabled={!getRunwayData.showRunwaySuccess}
                                onChange={
                                    (event: any) => setIfMounted(_isMounted.current, setPreTrainStepInfo, {
                                        ...preTrainStepInfo,
                                        modelAgeThreshold: parseInt(event.target.value),
                                    })
                                }
                            />
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'>Train</span>
                            <KatLabel tooltipText="Instance type of the training instance created.">
                                Training instance Type (Default: ml.t2.medium) :&nbsp;
                            </KatLabel>
                            <FormDropDown
                                value={instanceType}
                                options={INSTANCE_TYPES}
                                onChange={(event: any) => {
                                    setIfMounted(_isMounted.current, setInstanceType, event.target.value)
                                }}>
                            </FormDropDown>
                            <FormInput
                                label="Instance Count"
                                type='number'
                                disabled={!getRunwayData.showRunwaySuccess}
                                placeholder='Instance Count'
                                value={instanceCount.toString()}
                                tooltipText="This is the instance count of the instance type specified while training."
                                onChange={(event: any) => setIfMounted(_isMounted.current, setInstanceCount, event.target.value)}
                            />
                            <FormInput
                                label="Volume Size(in Gb)"
                                type='number'
                                disabled={!getRunwayData.showRunwaySuccess}
                                placeholder='Volume Size'
                                value={volumeSize.toString()}
                                tooltipText="Volume size"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setVolumeSize, event.target.value)}
                            />
                            {getEcrRepos()}
                            {!isEmpty(ecrRepo) && ecrRepo != CUSTOM_TRAINING_IMAGE ? getTrainingImages() : null}
                            <FormInput
                                label="Training Image ARN"
                                type='text'
                                value={trainingImage}
                                placeholder='Training Image ARN'
                                tooltipText="Training Image ARN"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setTrainingImage, event.target.value)}
                                disabled={!enableCustomImageInput}
                                required
                            />
                            <FormInput
                                label="Training Role"
                                type='text'
                                placeholder='Training Role'
                                value={trainingExecutionRoleArn}
                                disabled={!getRunwayData.showRunwaySuccess}
                                tooltipText="Role used for training job. Default - arn:aws:iam::<account_id>:role/ExpressoModelTrainingRole"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setTrainingExecutionRoleARN, event.target.value)}
                            />
                            <KatLabel tooltipText="Hyper Parameters for the training job">
                                Hyper Parameters for the training job :&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidJSON(hyperParameters) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <kat-alert>"Attention: The values in this json will be available as string-to-string map in
                                custom code
                            </kat-alert>
                            <JSONEditor
                                fieldName={"Hyper Parameters"}
                                value={hyperParameters}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setHyperParameters,
                                        value
                                    )
                                }}
                                readOnly={!getRunwayData.showRunwaySuccess}
                            />
                            <FormInput
                                label="Model Output Path"
                                type='text'
                                disabled={!getRunwayData.showRunwaySuccess}
                                placeholder='Model Output Path'
                                value={modelOutputPath}
                                tooltipText="Output path for the model training job."
                                onChange={(event: any) => setIfMounted(_isMounted.current, setModelOutputPath, event.target.value)}
                            />
                            {
                                getCodeScriptPathView(trainStepInfo.trainCustomCodeURI, (event: any) => {
                                        setIfMounted(_isMounted.current, setTrainStepInfo, {
                                            ...trainStepInfo,
                                            trainCustomCodeURI: event.target.value,
                                        })
                                        dispatch(getCodeVersions({
                                                repositoryType: RepositoryType.GIT,
                                                scriptPath: event.target.value,
                                                branch: DEFAULT_BRANCH
                                            }, customCodeRepository,
                                            JobStep.EXECUTION
                                        ));
                                    }
                                )
                            }
                            {
                                !isEmpty(trainStepInfo.trainCustomCodeURI) ?
                                    getCodeVersionsListDropDown(trainStepInfo.codeVersion, ModelTrainingStep.TRAIN, (event: any) => {
                                        setIfMounted(
                                            _isMounted.current, setTrainStepInfo, {
                                                ...trainStepInfo,
                                                codeVersion: extractVersionIdFromSignedUrl(event.target.value),
                                            })
                                        if (event.target.value != LATEST_CODE_VERSION) {
                                            window.open(event.target.value, '_blank')?.focus();
                                        }
                                    }) : null
                            }
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'>Channels</span>
                            {getChannels()}
                            <KatButton type="submit" label="Add Channel" variant="primary" size="small"
                                       onClick={addChannel}
                            />
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'>Post-Train</span>
                            {
                                getCodeScriptPathView(postTrainStepInfo.postTrainCustomCodeURI, (event: any) => {
                                        setIfMounted(_isMounted.current, setPostTrainStepInfo, {
                                            ...postTrainStepInfo,
                                            postTrainCustomCodeURI: event.target.value,
                                        })
                                        dispatch(getCodeVersions({
                                                repositoryType: RepositoryType.GIT,
                                                scriptPath: event.target.value,
                                                branch: DEFAULT_BRANCH
                                            }, customCodeRepository,
                                            JobStep.POST_EXECUTION
                                        ));
                                    }
                                )
                            }
                            {
                                !isEmpty(postTrainStepInfo.postTrainCustomCodeURI) ?
                                    getCodeVersionsListDropDown(postTrainStepInfo.codeVersion, ModelTrainingStep.POST_TRAIN, (event: any) => {
                                        setIfMounted(
                                            _isMounted.current, setPostTrainStepInfo, {
                                                ...postTrainStepInfo,
                                                codeVersion: extractVersionIdFromSignedUrl(event.target.value),
                                            })
                                        if (event.target.value != LATEST_CODE_VERSION) {
                                            window.open(event.target.value, '_blank')?.focus();
                                        }
                                    }) : null
                            }
                            <FormInput
                                label="Deployment artifact name"
                                type='text'
                                placeholder='Deployment artifact name'
                                value={postTrainStepInfo.deployArtifactName}
                                tooltipText="Deployment artifact name"
                                onChange={
                                    (event: any) => setIfMounted(_isMounted.current, setPostTrainStepInfo, {
                                        ...postTrainStepInfo,
                                        deployArtifactName: event.target.value,
                                    })
                                }
                            />
                            <FormInput
                                label="Deployment Bucket"
                                type='text'
                                placeholder='Deployment Bucket'
                                value={postTrainStepInfo.deploymentBucket}
                                tooltipText="Deployment Bucket"
                                onChange={
                                    (event: any) => setIfMounted(_isMounted.current, setPostTrainStepInfo, {
                                        ...postTrainStepInfo,
                                        deploymentBucket: event.target.value,
                                    })
                                }
                            />
                            <FormInput
                                label="Deployment key"
                                type='text'
                                placeholder='Deployment key'
                                value={postTrainStepInfo.deploymentKey}
                                tooltipText="Deployment key"
                                onChange={
                                    (event: any) => setIfMounted(_isMounted.current, setPostTrainStepInfo, {
                                        ...postTrainStepInfo,
                                        deploymentKey: event.target.value,
                                    })
                                }
                            />
                            <KatLabel tooltipText="Threshold Configuration">
                                Threshold Configuration :&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidJSON(postTrainStepInfo.thresholdConditionsJson) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"Threshold Configuration"}
                                value={postTrainStepInfo.thresholdConditionsJson}
                                width="400px"
                                height="400px"
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setPostTrainStepInfo,
                                        {
                                            ...postTrainStepInfo,
                                            thresholdConditionsJson: value,
                                        }
                                    )
                                }}
                                readOnly={!getRunwayData.showRunwaySuccess}
                            />
                            <FormInput
                                label="Archive Sub Folder"
                                disabled={!getRunwayData.showRunwaySuccess}
                                type='text'
                                placeholder='Archive sub folder'
                                value={postTrainStepInfo.archiveSubFolder}
                                tooltipText="Archive sub folder"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setPostTrainStepInfo, {
                                    ...postTrainStepInfo,
                                    archiveSubFolder: event.target.value,
                                })}
                            />
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'> Features </span>
                            <KatLabel tooltipText="Input Features">
                                Input Features (comma separated):&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidFeatureList(inputFeatures) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"Input Features"}
                                width="400px"
                                height="250px"
                                value={inputFeatures.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setInputFeatures,
                                        value
                                    )
                                }}
                                readOnly={!getRunwayData.showRunwaySuccess}
                            />
                            <KatLabel tooltipText="response Variables">
                                Response Variables (comma separated):&nbsp;&nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidFeatureList(responseVariables) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"Response Variables"}
                                width="400px"
                                height="50px"
                                value={responseVariables.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setResponseVariables,
                                        value
                                    )
                                }}
                                readOnly={!getRunwayData.showRunwaySuccess}
                            />
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'> Model Training Metadata </span>
                            <KatLabel tooltipText="model training metadata">
                                Metadata (JSON):&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidJSON(modelTrainingMetadata) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"model training metadata"}
                                width="400px"
                                height="250px"
                                value={modelTrainingMetadata.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setModelTrainingMetadata,
                                        value
                                    )
                                }}
                                readOnly={!getRunwayData.showRunwaySuccess}
                            />
                        </KatCard>
                        <br/>
                        <KatButton type="submit" label="Update" variant="primary" size="base"
                                   onClick={submitUpdateRunwayForm}/>
                    </> : <KatSpinner></KatSpinner>
            }
        </>
    )
}

export default UpdateV1Runway