import './polyfills';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import katalReady from '@katal/components-ready';

import App from './view/app';
import AppInitWrapper from './view/app_init_wrapper';
import './i18n';
import './view/style/index.scss';
import 'ace-diff/dist/ace-diff.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'

katalReady(() => {
  // The Suspense component causes React to display the fallback KatSpinner until
  // the i18n translation strings have been loaded.

  ReactDOM.render(
    <AppInitWrapper>
      <Suspense fallback={<KatSpinner size='large' />}>
        <App />
      </Suspense>
    </AppInitWrapper>,
    document.getElementById('root')
  );
});
