import React, {useEffect, useRef, useState} from "react";

import AceDiff from "ace-diff";
import {setIfMounted} from "src/utils/common_utils";


type GetNumDiffsCallback = (value: number) => void;

export interface IDiffViewerProps {
    left_content: string,
    right_content: string,
    numDiffsCallbackFn?: GetNumDiffsCallback,
    syncScroll?: boolean
}

export default function JSONDiffViewer(props: IDiffViewerProps) {

    let [aceDiff, setAceDiff] = useState<AceDiff | undefined>(undefined);
    const _isMounted = useRef(true);
    const syncScrollEnabled = (props.syncScroll || false);


    const syncRightScroll = (left: any, right: any) => {
        left.session.setScrollTop(right.session.getScrollTop())
    }

    const syncScrolling = () => {
        let left = aceDiff?.getEditors().left
        let right = aceDiff?.getEditors().right

        if (left && right) {
            left.session.on("changeScrollTop", () => syncRightScroll(left, right))
            right.session.on("changeScrollTop", () => syncRightScroll(left, right))
        }
    }

    // TODO: Come up with a different strategy to show the number of diffs
    const setNumDiffs = () => {
        /*
        Reason: Here the timeout is needed so that the getNumDiffs is called after the aceDiff.diffs has been initialized.
        Reference: https://github.com/ace-diff/ace-diff/blob/6089238afaf5c8f0673f5e87a1a12bace523bafe/src/index.js#L163
        Explanation: In the above code-reference, the aceDiff.diff() is called after a timeout of 1 ms (asynchronously).
                     This would mean that the aceDiff.diffs property won't be initialized if we do not perform this call
                     inside a setTimeout. Have used a setTimeOut of 10 ms
        Failure Behaviour: If the numDiffs cannot be set, the text won't be displayed. See the EditConfigsPage.
        */
        setTimeout(() => {
            try {
                let numDiffs = aceDiff? aceDiff.getNumDiffs() : 0;
                if (numDiffs != 0 && props.numDiffsCallbackFn) {
                    props.numDiffsCallbackFn(numDiffs);
                }
            } catch (e) {
                console.log("JSONDiffViewer.SetNumDiffs failed with an exception")
                console.log(e)
            }
        }, 10)
    }


    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        };
    });

    useEffect(() => {
        if (aceDiff) {
            aceDiff.destroy();
        }
        setIfMounted(_isMounted.current, setAceDiff, new AceDiff({
            element: ".acediff",
            mode: "ace/mode/json",
            theme: "ace/theme/github",
            left: {
                content: props.left_content,
                editable: false
            },
            right: {
                content: props.right_content,
                editable: false
            },
            classes: {
                diff: 'acediff__diffLine',
                connector: 'acediff__connector',
                newCodeConnectorLinkContent: '',
                deletedCodeConnectorLinkContent: '',
            }
        }));
    }, [props]);

    useEffect(() => {
        if (aceDiff) {
            syncScrollEnabled && syncScrolling();
            setNumDiffs();
        }
    }, [aceDiff]);


    return (
        <div className={"acediff"}/>
    )
}

