import {
  START_V2_RUNWAY_LOADER,
  CREATE_V2_RUNWAY_SUCCESS,
  CREATE_V2_RUNWAY_FAILURE,
  FETCH_V2_RUNWAY_SUCCESS,
  FETCH_V2_RUNWAY_FAILURE,
  UPDATE_V2_RUNWAY_SUCCESS,
  UPDATE_V2_RUNWAY_FAILURE,
  LIST_V2_RUNWAYS_SUCCESS,
  LIST_V2_RUNWAYS_FAILURE,
  CREATE_RUNWAY_JOB_SUCCESS,
  CREATE_RUNWAY_JOB_FAILURE,
  UPDATE_JOB_VERSION_IN_DAG_SUCCESS,
  UPDATE_RUNWAY_JOB_FAILURE,
  DELETE_JOB_NODE,
  UPDATE_V2_RUNWAY_STATUS_FAILURE,
  UPDATE_V2_RUNWAY_STATUS_SUCCESS,
  START_LOAD_V2_RUNWAYS_EXECUTIONS,
  DISABLE_V2_RUNWAYS_EXECUTIONS_LOAD_MORE,
  LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE,
  LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS,
  LOAD_V2_RUNWAYS_EXECUTION_METRICS_SUCCESS,
  START_LOAD_V2_RUNWAYS_EXECUTION_METRICS,
  LOAD_V2_RUNWAYS_EXECUTION_METRICS_FAILURE,
  FETCH_RUNWAY_LATEST_VERSION_DATA_SUCCESS,
} from "src/control/actions/action_types";
import { isEmpty } from "src/utils/common_utils";
import {
  initialRunwayState,
  RunwayStatus,
} from "../../../model/runways/runway_model";

export default function runwayReducer(state = initialRunwayState, action: any) {
  let newRunwayList: any[] = [];
  let newRunwayDag: any[] = [];
  switch (action.type) {
    case START_V2_RUNWAY_LOADER:
      return {
        ...state,
        isSpinnerLoading: true,
      };

    case CREATE_V2_RUNWAY_SUCCESS:
      return {
        ...state,
        fetchedRunway: action.payload,
        isSpinnerLoading: false,
      };
    case CREATE_V2_RUNWAY_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case FETCH_V2_RUNWAY_SUCCESS:
      newRunwayDag = action.payload.runway.runwayDAG.map((dagElement: any) => {
        dagElement.prevLiveVersion = dagElement.version;
        return dagElement;
      });
      return {
        ...state,
        fetchedRunway: {
          ...action.payload.runway,
          runwayDAG: newRunwayDag,
        },
        fetchedRunwayVersionsList: action.payload.runwayVersionsList,
        isSpinnerLoading: false,
      };
    case FETCH_V2_RUNWAY_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_V2_RUNWAY_SUCCESS:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_V2_RUNWAY_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case LIST_V2_RUNWAYS_SUCCESS:
      return {
        ...state,
        fetchedRunwayList: action.payload,
        isSpinnerLoading: false,
      };
    case LIST_V2_RUNWAYS_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case CREATE_RUNWAY_JOB_SUCCESS:
      return {
        ...state,
        fetchedRunway: {
          ...state.fetchedRunway,
          runwayDAG: [...state.fetchedRunway.runwayDAG, action.payload],
        },
        isSpinnerLoading: false,
      };
    case CREATE_RUNWAY_JOB_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_JOB_VERSION_IN_DAG_SUCCESS:
      state.fetchedRunway.runwayDAG.forEach((job: any) => {
        newRunwayDag.push({
          ...job,
          version:
            job.jobId === action.payload.jobId
              ? action.payload.version
              : job.version,
        });
      });
      return {
        ...state,
        fetchedRunway: {
          ...state.fetchedRunway,
          runwayDAG: newRunwayDag,
        },
        isSpinnerLoading: false,
      };
    case UPDATE_RUNWAY_JOB_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case DELETE_JOB_NODE:
      state.fetchedRunway.runwayDAG.forEach((job: any) => {
        if (job.jobId !== action.payload) {
          newRunwayList.push(job);
        }
      });
      return {
        ...state,
        fetchedRunway: {
          ...state.fetchedRunway,
          runwayDAG: newRunwayList,
        },
      };
    case UPDATE_V2_RUNWAY_STATUS_SUCCESS:
      state.fetchedRunwayList.forEach((runway: any) => {
        if (runway.runwayId == action.payload.runwayId) {
          newRunwayList.push({
            ...runway,
            runwayStatus:
              runway.runwayStatus === RunwayStatus.DISABLED
                ? RunwayStatus.ENABLED
                : runway.runwayStatus === RunwayStatus.ENABLED
                ? RunwayStatus.DISABLED
                : runway.runwayStatus,
          });
        } else {
          newRunwayList.push(runway);
        }
      });
      return {
        ...state,
        fetchedRunwayList: newRunwayList,
        isSpinnerLoading: false,
      };
    case UPDATE_V2_RUNWAY_STATUS_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS:
      return {
        ...state,
        fetchRunwayExecutions: {
          ...state.fetchRunwayExecutions,
          executionsList: [
            ...state.fetchRunwayExecutions.executionsList,
            ...action.payload,
          ],
          runwaysExecutionsFetched: true,
          fetchAllExecutionsSuccess: true,
        },
      };
    case LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE:
      return {
        ...state,
        fetchRunwayExecutions: {
          ...state.fetchRunwayExecutions,
          runwaysExecutionsFetched: true,
          fetchAllExecutionsSuccess: false,
        },
      };
    case START_LOAD_V2_RUNWAYS_EXECUTIONS:
      return {
        ...state,
        fetchRunwayExecutions: {
          ...state.fetchRunwayExecutions,
          executionsList: [],
          loadMore: true,
          runwaysExecutionsFetched: false,
          fetchAllExecutionsSuccess: false,
        },
      };
    case DISABLE_V2_RUNWAYS_EXECUTIONS_LOAD_MORE:
      return {
        ...state,
        fetchRunwayExecutions: {
          ...state.fetchRunwayExecutions,
          loadMore: false,
        },
      };
    case START_LOAD_V2_RUNWAYS_EXECUTION_METRICS: {
      return {
        ...state,
        executionMetrics: {
          ...state.executionMetrics,
          metricData: {},
          runwaysExecutionMetricsFetched: false,
          fetchRunwaysExecutionMetricsSuccess: false,
        },
      };
    }
    case LOAD_V2_RUNWAYS_EXECUTION_METRICS_SUCCESS: {
      return {
        ...state,
        executionMetrics: {
          ...state.executionMetrics,
          metricData: action.payload,
          runwaysExecutionMetricsFetched: true,
          fetchRunwaysExecutionMetricsSuccess: true,
        },
      };
    }
    case LOAD_V2_RUNWAYS_EXECUTION_METRICS_FAILURE: {
      return {
        ...state,
        executionMetrics: {
          ...state.executionMetrics,
          runwaysExecutionMetricsFetched: true,
          fetchRunwaysExecutionMetricsSuccess: false,
        },
      };
    }
    case FETCH_RUNWAY_LATEST_VERSION_DATA_SUCCESS: {
      return {
        ...state,
        fetchedLatestVersionRunway: action.payload.runway,
      };
    }
    default:
      return state;
  }
}
