import React, {useEffect, useRef, useState} from "react";
import {getFromLocalStorageAndSetTeam, isEmpty, setIfMounted} from "src/utils/common_utils";
import RegisterCodeRepo from "src/view/codeRepository/register_codeRepo";
import ListRepositories from "src/view/codeRepository/list_repositories";
import {BannerTypes, RequestStatus} from "src/view/dataset/dataset_constants";
import {GenericAlert} from "src/view/style/view_config_styles";
import ViewCodeRepo from "src/view/codeRepository/view_code_repository";
import {useDispatch, useSelector} from "react-redux";
import {registerCodeRepositorySelector} from "src/control/selectors/codeRepository/code_repo_selectors";

export default function CodeRepositoryComponent() {
    let _isMounted = useRef(true);
    const dispatch = useDispatch();

    const registerCodeRepository = useSelector(registerCodeRepositorySelector)

    const [visibilityViewCodeRepository, setVisibilityViewCodeRepository] = useState(false);
    const [viewCodeRepositoryData, setViewCodeRepositoryData] = useState({});
    const [visibilityRegisterCodeRepository, setVisibilityRegisterCodeRepository] = useState(false);

    // Adding field for add branch addition in git repository. If we are adding branch in existing repository, repository name
    // will be static.
    const [isAlreadyExistingGitRepository, setIsAlreadyExistingGitRepository] = useState(false);
    const [existingPackageName, setExistingPackageName] = useState("");

    const [bannerMessage, setBannerMessage] = useState("");
    const [bannerHeading, setBannerHeading] = useState("");
    const [bannerType, setBannerType] = useState<BannerTypes | undefined>(undefined);
    const [showBanner, setShowBanner] = useState(false);

    const [repositoryName, setRepositoryName] = useState("");
    const [branch, setBranch] = useState("");

    const setVisibilityViewCodeRepositoryWrapper = (value: boolean) => setIfMounted(_isMounted.current, setVisibilityViewCodeRepository, value);
    const setViewCodeRepositoryDataWrapper = (value: boolean) => setIfMounted(_isMounted.current, setViewCodeRepositoryData, value);
    const setVisibilityRegisterCodeRepositoryWrapper = (value: boolean) => setIfMounted(_isMounted.current, setVisibilityRegisterCodeRepository, value);

    const setIsAlreadyExistingGitRepositoryWrapper = (value: boolean) => setIfMounted(_isMounted.current, setIsAlreadyExistingGitRepository, value);
    const setExistingPackageNameWrapper = (value: string) => setIfMounted(_isMounted.current, setExistingPackageName, value);


    const setRepositoryNameWrapper = (value: string) => {
        setIfMounted(_isMounted.current, setRepositoryName, value)
    };
    const setBranchWrapper = (value: string) => {
        setIfMounted(_isMounted.current, setBranch, value)
    }

    const handleRegisterSuccess = () => {
        if (RequestStatus.Success == registerCodeRepository.requestStatus) {
            setIfMounted(_isMounted.current, setBannerType, BannerTypes.SUCCESS)
            setIfMounted(_isMounted.current, setBannerHeading, `[Success] Registered: ${repositoryName}`)
            setIfMounted(_isMounted.current, setBannerMessage, ``)
        } else if (RequestStatus.AlreadyExists == registerCodeRepository.requestStatus) {
            setIfMounted(_isMounted.current, setBannerType, BannerTypes.WARNING)
            setIfMounted(_isMounted.current, setBannerHeading, `[Warning] Already Exists: ${repositoryName}` + (isEmpty(branch) ? "" : ` branch: ${branch}`))
            setIfMounted(_isMounted.current, setBannerMessage, ``)
        }
        setIfMounted(_isMounted.current, setShowBanner, true)
        setIfMounted(_isMounted.current, setVisibilityRegisterCodeRepository, false)
    }

    const handleRegisterFailure = () => {
        setIfMounted(_isMounted.current, setBannerType, BannerTypes.DANGER)
        setIfMounted(_isMounted.current, setBannerHeading, `[Failure] Encountered Error: ${repositoryName}`)
        setIfMounted(_isMounted.current, setBannerMessage, `Message: ${registerCodeRepository.errorMessage}`)
        setIfMounted(_isMounted.current, setShowBanner, true)
        setIfMounted(_isMounted.current, setVisibilityRegisterCodeRepository, false)
    }

    const handleLoad = () => {
        setIfMounted(_isMounted.current, setBannerHeading, `Register Request For ${repositoryName} initiated`)
        setIfMounted(_isMounted.current, setBannerType, BannerTypes.INFO)
        setIfMounted(_isMounted.current, setShowBanner, true)
        setIfMounted(_isMounted.current, setVisibilityRegisterCodeRepository, false)
    }

    const handleRegisterAction = () => {
        if (registerCodeRepository.registerRepositorySuccess && registerCodeRepository.registerRepositoryResponseFetched) {
            handleRegisterSuccess()
        } else if (!registerCodeRepository.registerRepositorySuccess && registerCodeRepository.registerRepositoryResponseFetched) {
            handleRegisterFailure()
        } else if (registerCodeRepository.startRegisterCodeRepository) {
            handleLoad()
        }
    }

    useEffect(() => {
            handleRegisterAction()
        }, [registerCodeRepository]
    )

    useEffect(() => {
        getFromLocalStorageAndSetTeam(dispatch)
    }, [dispatch])

    return (
        <>
            {
                showBanner && (
                    <GenericAlert
                        header={bannerHeading}
                        description={bannerMessage}
                        variant={bannerType}
                        onDismiss={() => setIfMounted(_isMounted.current, setShowBanner, false)}
                    />
                )
            }
            {
                (visibilityRegisterCodeRepository) && (
                    <RegisterCodeRepo
                        existingPackageName={existingPackageName}
                        isAlreadyExistingGitRepo={isAlreadyExistingGitRepository}
                        visibility={visibilityRegisterCodeRepository}
                        setVisibility={setVisibilityRegisterCodeRepositoryWrapper}
                        setRepositoryName={setRepositoryNameWrapper}
                        setBranch={setBranchWrapper}
                    />
                )
            }
            {
                visibilityViewCodeRepository && (
                    <ViewCodeRepo
                        visibility={visibilityViewCodeRepository}
                        setVisibilityForViewCodeRepo={setVisibilityViewCodeRepositoryWrapper}
                        repositoryData={viewCodeRepositoryData}
                    />
                )
            }
            <ListRepositories
                setVisibilityForRegisterCodeRepo={setVisibilityRegisterCodeRepositoryWrapper}
                setViewCodeRepositoryData={setViewCodeRepositoryDataWrapper}
                setVisibilityForViewCodeRepository={setVisibilityViewCodeRepositoryWrapper}
                setIsAlreadyExistingCodeRepository={setIsAlreadyExistingGitRepositoryWrapper}
                setExistingPackageName={setExistingPackageNameWrapper}
            />
        </>
    )
}