import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableBody,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRowWithShadow,
  TableCellItem,
} from "../../style/table_styles";
import { formatDate, isEmpty } from "../../../utils/common_utils";
import { GreenSpinner } from "../../style/header_styles";
import { Ordering } from "../../dataset/dataset_constants";
import {
  colorGrey,
  EditIconTable,
  SortAscending,
  SortDescending,
  ViewIconTable,
} from "../../style/icons";
import { useHistory, useParams } from "react-router";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { Job } from "src/model/runways/job_model";
import { isJobSpinnerLoadingSelector, jobListSelector } from "src/control/selectors/runways/job_selectors";
import { listJobs } from "src/control/actions/runways/job_actions";
import { KatButton, KatTable, KatTableCell } from "@amzn/katal-react";
import ReactTooltip from "react-tooltip";
import { getViewLogsMetricsUrl, toggledSortOrdering } from "../constants";
import { namespaceSelector } from "src/control/selectors/modelTraining/model_training_selectors";
import { loadModelTrainingNamespace } from "src/control/actions/modelTraining/namespace_actions";
import { CreateButton } from "src/view/style/runway_styles";
import { sortList } from "src/utils/list_utils";
import {
  ErrorContainer,
  ErrorHeading,
} from "src/view/style/modelTraining/errors";

enum SortBy {
  jobName = "jobName",
  createdBy = "createdBy",
  creationTime = "creationTime",
  lastUpdatedBy = "lastUpdatedBy",
  lastUpdatedTime = "lastUpdatedTime",
}

/**
 * renders the view into a list jobs table
 * @returns JSX
 */
export default function ListJobs() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  //selectors
  let userName = useSelector(usernameSelector);
  let jobListData = useSelector(jobListSelector);
  let fetchedNamespace = useSelector(namespaceSelector);
  let isSpinnerLoading = useSelector(isJobSpinnerLoadingSelector)

  //state hooks
  const [sortBy, setSortBy] = useState(SortBy.jobName);
  const [sortOrder, setSortOrder] = useState(Ordering.Ascending);

  //useEffect hooks
  useEffect(() => {
    dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
    dispatch(listJobs(params.namespaceId));
  }, [userName]);

  /**
   * sets the property on which sorting needs to happen
   * @param sortByProperty
   */
  const handleOnSortClick = (sortByProperty: SortBy) => {
    if (sortBy == sortByProperty) {
      setSortOrder(toggledSortOrdering(sortOrder));
    } else {
      setSortBy(sortByProperty);
      setSortOrder(Ordering.Ascending);
    }
  };

  /**
   * returns the sort icon based upon sorting order
   * @param property
   * @returns JSX
   */
  const getSortIcon = (property: SortBy) => {
    if (sortBy == property) {
      return sortOrder == Ordering.Ascending ? (
        <div
          className={"float-right"}
          onClick={() => handleOnSortClick(property)}
        >
          <SortAscending />
        </div>
      ) : (
        <div
          className={"float-right"}
          onClick={() => handleOnSortClick(property)}
        >
          <SortDescending />
        </div>
      );
    } else {
      return (
        <div
          className={"float-right"}
          onClick={() => handleOnSortClick(property)}
        >
          <SortAscending style={colorGrey} />
        </div>
      );
    }
  };

  /**
   * redirects to view page
   * @param jobId
   */
  const handleRedirectToView = (jobId: string) => {
    let redirectedPath = window.location.pathname + `/${jobId}/view`;
    history.push(redirectedPath);
  };

  /**
   * renders list of jobs in a table rows
   * @returns jobList<JSX>
   */
  const fillRows = () => {
    let jobList = sortList(jobListData, sortBy);
    jobList = sortOrder == Ordering.Ascending ? jobList : jobList.reverse();

    return jobList.map((job: Job, key: number) => {
      return (
        <TableRowWithShadow key={key}>
          <KatTableCell onClick={() => handleRedirectToView(job.jobId)}>
            <div>{job.jobName}</div>
          </KatTableCell>
          <KatTableCell onClick={() => handleRedirectToView(job.jobId)}>
            <div>{job.createdBy}</div>
          </KatTableCell>
          <KatTableCell onClick={() => handleRedirectToView(job.jobId)}>
            <div>{job.creationTime && formatDate(job.creationTime)}</div>
          </KatTableCell>
          <KatTableCell onClick={() => handleRedirectToView(job.jobId)}>
            <div>{job.lastUpdatedBy}</div>
          </KatTableCell>
          <KatTableCell onClick={() => handleRedirectToView(job.jobId)}>
            <div>{job.lastUpdatedTime && formatDate(job.lastUpdatedTime)}</div>
          </KatTableCell>
          <KatTableCell>
            <ReactTooltip place="bottom" type="dark" />
            <TableCellItem>
              <TableCellItem>
                <div
                  data-tip="Edit Job Details"
                  onClick={() => {
                    history.push(
                      window.location.pathname + `s/${job.jobId}/view`
                    );
                  }}
                >
                  <EditIconTable
                    style={{
                      margin: "10px",
                      width: "20px",
                      color: "#002e36",
                    }}
                  />
                </div>
                <div
                  data-tip="View Job Details"
                  onClick={() => {
                    handleRedirectToView(job.jobId);
                  }}
                >
                  <ViewIconTable
                    style={{
                      margin: "10px",
                      width: "20px",
                      color: "#002e36",
                    }}
                  />
                </div>
                <div style={{ margin: "10px" }}>
                  <KatButton
                    label="View Executions"
                    size="small"
                    variant="primary"
                    onClick={() => handleRedirectToView(job.jobId)}
                  />
                </div>
                <div style={{ margin: "10px" }}>
                  <KatButton
                    label="View Logs/Metrics"
                    size="small"
                    variant="primary"
                    onClick={() =>
                      window
                        .open(
                          getViewLogsMetricsUrl(
                            fetchedNamespace.namespaceData.accountTypeMap[
                              job.domain.toLowerCase()
                            ],
                            job.jobId
                          ),
                          "_blank"
                        )
                        ?.focus()
                    }
                  />
                </div>
              </TableCellItem>
            </TableCellItem>
          </KatTableCell>
        </TableRowWithShadow>
      );
    });
  };

  /**
   * renders data into table headers
   * @returns JSX
   */
  const display = () => {
    return (
      <>
        <KatTable>
          {jobListData.length == 0 ? (
            <ErrorContainer>
              <ErrorHeading>No Results Found</ErrorHeading>
            </ErrorContainer>
          ) : (
            <>
              <TableHead>
                <TableHeadRow>
                  <KatTableCell>
                    <TableCellItem>
                      Name&nbsp;&nbsp;&nbsp;{getSortIcon(SortBy.jobName)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <TableCellItem>
                      Created By&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.createdBy)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <TableCellItem>
                      Created At&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.creationTime)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <TableCellItem>
                      Last Updated By&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.lastUpdatedBy)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <TableCellItem>
                      Last Updated At&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.lastUpdatedTime)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <div>Actions</div>
                  </KatTableCell>
                </TableHeadRow>
              </TableHead>
              <TableBody>{fillRows()}</TableBody>
            </>
          )}
        </KatTable>
      </>
    );
  };

  if (
    isEmpty(fetchedNamespace.namespaceData.namespace) ||
    isSpinnerLoading
  ) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
    <>
      <div>
        <CreateButton
          variant="primary"
          label="Create New"
          onClick={() => {
            let redirectedPath = window.location.pathname + "/create";
            history.push(redirectedPath);
          }}
        />
      </div>
      <TableHeader>Jobs</TableHeader>
      <br />
      {display()}
    </>
  );
}
