import {colorWhite, NavDatasetIcon} from "src/view/style/icons";
import React from "react";
import {NavItem, onClickParams} from "./nav_item_commons";

const _navigateToDatasetsPage = (props: onClickParams) => {
    let history = props.history;
    history.push(`/datasets`);
}

const DatasetNavItem: NavItem = {
    displayName: "Datasets",
    icon: <NavDatasetIcon style={colorWhite}/>,
    labelOnClick: _navigateToDatasetsPage,
    hasSubNav: false
}

export default DatasetNavItem;