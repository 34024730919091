import {apiGetPromise, apiPostPromise, apiPutPromise} from "src/utils/api_handler";
import {
    getCreateRunwayEndpoint,
    getFetchRunwaysEndpoint,
    getListJobInstancesEndpoint,
    getModelTrainingRunwayEndpoint,
    getTrainingImagesEndpoint,
    getUpdateRunwayStatusEndpoint
} from "src/control/targets";
import {isEmpty} from "src/utils/common_utils";
import {Failure, Success} from "src/utils/action_utils";
import {
    CREATE_RUNWAY_FAILURE,
    CREATE_RUNWAY_SUCCESS,
    ERROR_CREATING_RUNWAY,
    ERROR_FETCHING_ALL_RUNWAYS,
    ERROR_FETCHING_RUNWAY,
    ERROR_FETCHING_TRAINING_IMAGES,
    ERROR_LOADING_EXECUTIONS,
    ERROR_UPDATE_RUNWAY,
    ERROR_UPDATE_RUNWAY_STATUS,
    FETCH_ALL_RUNWAYS_FAILURE,
    FETCH_ALL_RUNWAYS_SUCCESS,
    FETCH_RUNWAY_FAILURE,
    FETCH_RUNWAY_SUCCESS,
    FETCH_TRAINING_IMAGES_FAILURE,
    FETCH_TRAINING_IMAGES_SUCCESS,
    LOAD_EXECUTION_METRICS_SUCCESS,
    LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE,
    LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS,
    RUNWAY_NOT_FOUND,
    START_CREATE_RUNWAY_LOADER,
    START_FETCH_TRAINING_IMAGES_LOADER,
    START_LOAD_EXECUTION_METRICS,
    START_LOAD_RUNWAY_EXECUTIONS,
    START_UPDATE_RUNWAY_LOADER,
    START_UPDATE_RUNWAY_STATUS,
    UPDATE_LATEST_VERSION_SUCCESS,
    UPDATE_RUNWAY_FAILURE,
    UPDATE_RUNWAY_STATUS_FAILURE,
    UPDATE_RUNWAY_STATUS_SUCCESS,
    UPDATE_RUNWAY_SUCCESS,
} from "src/control/actions/action_types";

export const loadRunway = (runwayId: string, version?: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getModelTrainingRunwayEndpoint(runwayId), !isEmpty(version) ? {
                    version: version
                } : null
            );
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_RUNWAY_SUCCESS, data.runway));
                if(isEmpty(version)) {
                    dispatch(Success(UPDATE_LATEST_VERSION_SUCCESS, data.runway));
                }
                resolve();
                return;
            }
            const error = RUNWAY_NOT_FOUND;
            dispatch(Failure(FETCH_RUNWAY_FAILURE, error));
            reject(error);
        } catch (err) {
            const error = ERROR_FETCHING_RUNWAY;
            dispatch(Failure(FETCH_RUNWAY_FAILURE, err));
            reject(error);
        }
    });
}

export const createRunway = (postData: any) => async (dispatch: any) => {
    dispatch({type: START_CREATE_RUNWAY_LOADER})
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiPostPromise(getCreateRunwayEndpoint(), postData);
            if (!isEmpty(data)) {
                dispatch(Success(CREATE_RUNWAY_SUCCESS, data));
                resolve();
                return;
            }
            const error = ERROR_CREATING_RUNWAY;
            dispatch(Failure(CREATE_RUNWAY_FAILURE, error));
            reject(error);
        } catch (err : any) {
            const errorMessage = err.response.data.message;  
            dispatch(Failure(CREATE_RUNWAY_FAILURE, errorMessage));
            reject(errorMessage);
        }
    });
}

export const loadTrainingImages = (repositoryType: string) => async (dispatch: any) => {
    dispatch({type: START_FETCH_TRAINING_IMAGES_LOADER})
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_TRAINING_IMAGES;
        try {
            let data: any = await apiGetPromise(getTrainingImagesEndpoint(repositoryType))
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_TRAINING_IMAGES_SUCCESS, data));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_TRAINING_IMAGES_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_TRAINING_IMAGES_FAILURE, error));
            reject(error);
        }
    });
}

export const listRunways = (namespaceId: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_ALL_RUNWAYS;
        try {
            let data: any = await apiGetPromise(getFetchRunwaysEndpoint(), {
                namespaceId: namespaceId
            });
            if (!isEmpty(data)) {
                const runwayList = data.runways.map((runway: any) => {
                    return {
                        runwayName: runway.runwayName,
                        runwayId: runway.runwayId,
                        version: runway.version,
                        createdBy: runway.createdBy,
                        runwayStatus: runway.runwayStatus,
                        creationTime: runway.creationTime,
                        domain: runway.domain
                    }
                })
                dispatch(Success(FETCH_ALL_RUNWAYS_SUCCESS, runwayList));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_ALL_RUNWAYS_FAILURE, error));
            reject(error);
        } catch (err) {
            console.log(err)
            dispatch(Failure(FETCH_ALL_RUNWAYS_FAILURE, error));
            reject(error);
        }
    });
}

export const updateRunway = (runwayId: string, putData: any) => async (dispatch: any) => {
    const error = ERROR_UPDATE_RUNWAY;
    dispatch({type: START_UPDATE_RUNWAY_LOADER})
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiPutPromise(getModelTrainingRunwayEndpoint(runwayId), putData);
            if (!isEmpty(data)) {
                dispatch(Success(UPDATE_RUNWAY_SUCCESS, data));
                resolve();
                return;
            }
            dispatch(Failure(UPDATE_RUNWAY_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(UPDATE_RUNWAY_FAILURE, error));
            reject(error);
        }
    })
}

export const updateRunwayStatus = (runwayId: any, status: string) => async (dispatch: any) => {
    dispatch({type: START_UPDATE_RUNWAY_STATUS})
    return new Promise(async (resolve, reject) => {
        const error = ERROR_UPDATE_RUNWAY_STATUS;
        try {
            let data: any = await apiPutPromise(getUpdateRunwayStatusEndpoint(runwayId, status), {})
            if (!isEmpty(data)) {
                if (data.requestStatus.toLocaleLowerCase() == "success") {
                    dispatch(Success(UPDATE_RUNWAY_STATUS_SUCCESS, {runwayId: runwayId}));
                    resolve();
                    return;
                }
                dispatch(Failure(UPDATE_RUNWAY_STATUS_FAILURE, error));
                reject(error);
            }
        } catch (err) {
            dispatch(Failure(UPDATE_RUNWAY_STATUS_FAILURE, error));
            reject(error);
        }
    })
}

export const loadRunwayExecutions = (runwayId: string, refreshExecutions: boolean, timeWindowStart?: string, timeWindowEnd?: string) => async (dispatch: any) => {
    if (refreshExecutions) {
        dispatch({type: START_LOAD_RUNWAY_EXECUTIONS})
    }
    return new Promise(async (resolve, reject) => {
        const error = ERROR_LOADING_EXECUTIONS;
        try {
            let data: any = await apiGetPromise(getListJobInstancesEndpoint(runwayId), {
                timeWindowStart: timeWindowStart,
                timeWindowEnd: timeWindowEnd
            });
            if (!isEmpty(data)) {
                dispatch(Success(LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS, data.jobInstanceList));
                resolve();
                return;
            }
            dispatch(Failure(LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE, error));
            reject(error);
        }
    })
}

// TODO: Replace data with real API call and add try catch after adding the real API call.
export const loadRunwayInstanceExecutionMetrics = (runwayId: string, executionId: string) => async (dispatch: any) => {
    dispatch({type: START_LOAD_EXECUTION_METRICS})
    return new Promise(async (resolve, reject) => {
        const error = ERROR_LOADING_EXECUTIONS;
        let data: any = {
            "auc": "0.2"
        }
        if (!isEmpty(data)) {
            dispatch(Success(LOAD_EXECUTION_METRICS_SUCCESS, data));
            resolve();
            return;
        }
    })
}
