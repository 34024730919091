import { isEmpty } from "src/utils/common_utils";
import { initialJobTemplateState } from "../../../model/runways/job_template_model";
import {
  CREATE_JOB_TEMPLATE_FAILURE,
  CREATE_JOB_TEMPLATE_SUCCESS,
  FETCH_JOB_TEMPLATE_FAILURE,
  FETCH_JOB_TEMPLATE_SUCCESS,
  FETCH_JOB_TEMPLATE_LATEST_VERSION_DATA_SUCCESS,
  LIST_JOB_TEMPLATES_FAILURE,
  LIST_JOB_TEMPLATES_SUCCESS,
  START_JOB_TEMPLATE_LOADER,
  UPDATE_JOB_TEMPLATE_FAILURE,
  UPDATE_JOB_TEMPLATE_SUCCESS,
} from "../../actions/action_types";

export default function jobTemplateReducer(
  state = initialJobTemplateState,
  action: any
) {
  switch (action.type) {
    case START_JOB_TEMPLATE_LOADER:
      return {
        ...state,
        isSpinnerLoading: true,
      };
    case CREATE_JOB_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetchedJobTemplate: action.payload,
        isSpinnerLoading: false,
      };
    case CREATE_JOB_TEMPLATE_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case FETCH_JOB_TEMPLATE_SUCCESS:
      return {
        ...state,
        fetchedJobTemplate: action.payload.jobTemplate,
        fetchedJobTemplateVersionsList: action.payload.jobTemplateVersionsList,
        isSpinnerLoading: false,
      };
    case FETCH_JOB_TEMPLATE_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_JOB_TEMPLATE_SUCCESS:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_JOB_TEMPLATE_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case LIST_JOB_TEMPLATES_SUCCESS:
      return {
        ...state,
        fetchedJobTemplateList: action.payload,
        isSpinnerLoading: false,
      };
    case LIST_JOB_TEMPLATES_FAILURE:
      return { ...state, isSpinnerLoading: false };
    case FETCH_JOB_TEMPLATE_LATEST_VERSION_DATA_SUCCESS:
      return {
        ...state,
        fetchedLatestVersionJobTemplate: action.payload.jobTemplate
      }
    default:
      return state;
  }
}
