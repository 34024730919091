import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {DataStudioAddon, DataStudioAddonType} from "src/model/notebooks/data_studio_models";
import {useParams} from "react-router-dom";
import Collapsible from "src/view/commons/collapsible";
import {
    KatBadge,
    KatButton,
    KatFlashbar,
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow
} from "@amzn/katal-react";
import {isAdminWindow} from "src/utils/url_utils";
import {
    dataStudioAddonsListSelector,
    dataStudioOnboardingSelector
} from "src/control/selectors/notebooks/data_studio_selectors";
import {
    deleteDataStudioAddon,
    getDataStudioOnboarding,
    listDataStudioAddons
} from "src/control/actions/notebooks/data_studio_actions";
import {useHistory} from "react-router";
import {fetchTeam} from "src/control/actions/commons/team_actions";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {CreateNewButton, TableHeader} from "src/view/style/table_styles";
import {DeleteIconTable, ViewIconTable} from "src/view/style/icons";
import ReactTooltip from "react-tooltip";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {ClickablePaddedSpan, PopUp} from "src/view/style/common_styles";
import {getAddonStatusBadgeType} from "src/view/notebooks/datastudio_utils";
import {getTargetForDataStudioAddonType} from "src/control/targets";
import {filterList, sortList} from "src/utils/list_utils";
import {Status} from "src/constant";
import {ErrorHeading} from "src/view/style/modelTraining/errors";

export default function ListDataStudioAddons() {
    const params: any = useParams()
    const isAdminView = isAdminWindow()
    const dispatch = useDispatch()
    const history = useHistory()

    let _isMounted = useRef(false)
    let addonsList = useSelector(dataStudioAddonsListSelector);
    let fetchedNamespace = useSelector(namespaceSelector)
    let onboarding = useSelector(dataStudioOnboardingSelector)
    const [bannerVisible, setBannerVisible] = useState(false);
    const [selectedAddonId, setSelectedAddonId] = useState("");
    const [selectedAddonType, setSelectedAddonType] = useState(DataStudioAddonType.JUPYTER_MANAGED_ENDPOINT);
    const [showConfirmDeletionPopUp, setShowConfirmDeletionPopUp] = useState(false);

    useEffect(() => {
        _isMounted.current = true;
        dispatch(getDataStudioOnboarding(params.onboardingId))
        dispatch(listDataStudioAddons(params.onboardingId))
        dispatch(fetchTeam(params.teamId, null, fetchTeam(
            params.teamId,
            null,
            (error: any) => {
                dispatch(showSnackBar(error.message))
            })))
        !isAdminView && dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
        return () => {
            _isMounted.current = false;
        }
    }, []);

    const handleDelete = (addonId: string, addonType: DataStudioAddonType) => {
        if (isAdminView) {
            setIfMounted(_isMounted.current, setSelectedAddonId, addonId)
            setIfMounted(_isMounted.current, setSelectedAddonType, addonType)
            setIfMounted(_isMounted.current, setShowConfirmDeletionPopUp, true)
        }
    }

    const redirectToViewPage = (addonId: string, addonType: DataStudioAddonType) => {
        history.push(`${window.location.pathname}/${addonId}/${getTargetForDataStudioAddonType(addonType)}/view`)
    }

    const getSelectedAddonNameAndType = (addonId: string) => {
        let filteredList =  filterList(addonsList.value, "addonId", addonId)
        if(isEmpty(filteredList))
            return "";
        return `${filteredList[0].name}(${filteredList[0].type})`
    }

    const getConfirmAddonDeletionPopUp = () => {
        return ( <PopUp
            visible={showConfirmDeletionPopUp}
            onOpen={() => {
                setIfMounted(_isMounted.current, setShowConfirmDeletionPopUp, true);
            }}
            onClose={() => {
                setIfMounted(_isMounted.current, setShowConfirmDeletionPopUp, false);
            }}
        >
            <span slot={"title"}>Confirm Deletion</span>
            <p>Are you sure you want to delete addon : {`${getSelectedAddonNameAndType(selectedAddonId)}`}?</p>
            <div slot="footer" className="kat-group-horizontal">
                <KatButton
                    label="Cancel"
                    size="base"
                    variant="primary"
                    onClick={() => {
                        setIfMounted(_isMounted.current, setShowConfirmDeletionPopUp, false)
                    }}
                />
                <KatButton
                    label="Delete"
                    size="base"
                    variant="primary"
                    onClick={() => {
                        dispatch(deleteDataStudioAddon(selectedAddonId, selectedAddonType))
                        setIfMounted(_isMounted.current, setBannerVisible, true)
                        setIfMounted(_isMounted.current, setShowConfirmDeletionPopUp, false)
                    }}
                />
            </div>
        </PopUp> );
    }

    const getAddonRows = (addons: DataStudioAddon[], addonType: DataStudioAddonType) => {
        return (<>
            {
                sortList(addons, "createdTimestamp").map((currentValue: DataStudioAddon) => {
                    return (
                        <KatTableRow key={currentValue.addonId}>
                            <KatTableCell>{currentValue.name}</KatTableCell>
                            <KatTableCell>{currentValue.createdBy}</KatTableCell>
                            <KatTableCell>{currentValue.createdTimestamp}</KatTableCell>
                            <KatTableCell>
                                <KatBadge label={currentValue.status}
                                          type={getAddonStatusBadgeType(currentValue.status)}/>
                            </KatTableCell>
                            <KatTableCell>
                                <ReactTooltip place="bottom" type="dark"/>
                                <ClickablePaddedSpan data-tip="View Addon">
                                    <ViewIconTable style={{color: "#002e36"}} onClick={
                                        () => redirectToViewPage(currentValue.addonId, addonType)
                                    }/>
                                </ClickablePaddedSpan>
                                <ClickablePaddedSpan data-tip={isAdminView ? "Delete Addon": "Action Not Supported Yet"}>
                                    <DeleteIconTable style={isAdminView ? {
                                        color: "#002e36"
                                    } : {color: "grey"}} onClick={
                                        () => handleDelete(currentValue.addonId, addonType)
                                    }/>
                                </ClickablePaddedSpan>
                            </KatTableCell>
                        </KatTableRow>)
                })
            }
        </>);
    }


    const getAddonBox = (addonType: DataStudioAddonType) => {
        let filteredAddons = addonsList.value.filter((value: DataStudioAddon) => {
            return value.type == addonType
        })
        return (
            <Collapsible key={addonType} label={addonType}>
                <CreateNewButton
                    variant={"primary"}
                    onClick={() => {
                        history.push(`${window.location.pathname}/managed-endpoint/create`)
                    }}
                    disabled={!isAdminView}
                >
                    Create
                </CreateNewButton>
                <KatTable>
                    <KatTableHead>
                        <KatTableRow>
                            <KatTableCell>Name</KatTableCell>
                            <KatTableCell>Create By</KatTableCell>
                            <KatTableCell>Created At</KatTableCell>
                            <KatTableCell>Status</KatTableCell>
                            <KatTableCell>Actions</KatTableCell>
                        </KatTableRow>
                    </KatTableHead>
                    <KatTableBody>
                        {filteredAddons.length !== 0 && getAddonRows(filteredAddons, addonType)}
                    </KatTableBody>
                </KatTable>
                {
                    filteredAddons.length === 0 && <div style={{
                        textAlign: "center",
                        marginTop: "8%"
                    }}><ErrorHeading>No Results Found</ErrorHeading></div>
                }
            </Collapsible>
        )
    }

    return (
        (!isAdminView && isEmpty(fetchedNamespace.namespaceData.namespace)) ||
        addonsList.status === Status.LOADING ||
        onboarding.status === Status.LOADING ?
            <KatSpinner/>
            : <>
                {getConfirmAddonDeletionPopUp()}
                <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                            dataStudioOnboardingName={onboarding.value.name}/>
                {
                    bannerVisible && <KatFlashbar
                        variant="info"
                        header={"Info"}
                        description={"Deleting Addon, Please refresh page to check status"}
                        onDismissed={() => {
                            setIfMounted(_isMounted.current, bannerVisible, false)
                        }}
                    />
                }
                {
                    <>
                        <TableHeader>
                            Addons
                        </TableHeader>
                        {
                            Object.values(DataStudioAddonType).map((key: DataStudioAddonType) => {
                                return getAddonBox(key);
                            })
                        }
                    </>
                }
            </>
    )
}