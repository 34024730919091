import {colorWhite, NavConfigPanelIcon, NavDashIcon} from "src/view/style/icons";
import React from "react";
import {NavItem, onClickParams, CreateNamespaceItem, NamespaceItem} from "./nav_item_commons";
import {fetchAllNamespaces} from "src/control/actions/configPanel/config_panel_actions";
import {allNamespacesSelector} from "src/control/selectors/configPanel/config_panel_selectors";
import {setIfMounted} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {ERROR_OPERATION_NOT_SUPPORTED} from "src/control/errors";


const _navigateToNamespacesPage = (props: onClickParams) => {
    let history = props.history;
    let teamId = props.otherParams.teamId
    history.push(`/configPanel/${teamId}/namespaces`);
}

const _navigateToNamespaceCreatePage = (props: onClickParams) => {
    let history = props.history;
    let dispatch = props.dispatch;
    dispatch(showSnackBar(ERROR_OPERATION_NOT_SUPPORTED.message))
}

const _loadNamespaces = async (props: onClickParams) => {
    let dispatch = props.dispatch;
    let isMounted = props.isMounted;
    let setLoading = props.setLoading;
    let teamId = props.otherParams.teamId
    try {
        setIfMounted(isMounted.current, setLoading, true);
        await dispatch(fetchAllNamespaces(teamId));
        setIfMounted(isMounted.current, setLoading, false);
    } catch (error) {
        setIfMounted(isMounted.current, setLoading, false);
        dispatch(showSnackBar(error.message));
    }
}

const _constructNavItem = (namespaceItem: any) => {
    return {
        displayName: namespaceItem.namespace,
        icon: <NavDashIcon style={colorWhite}/>,
        hasSubNav: false,
        labelOnClick: (props:onClickParams) => {
            let history = props.history;
            let teamId = props.otherParams.teamId
            history.push(`/configPanel/${teamId}/namespaces/${namespaceItem.namespaceId}/configs`);
        }
    }
}


const ConfigPanelNavItem: NavItem = {
    displayName: "Configuration Panel",
    icon: <NavConfigPanelIcon style={colorWhite}/>,
    labelOnClick: _navigateToNamespacesPage,
    hasSubNav: true,
    subNavItems: [
        {
            ...NamespaceItem,
            labelOnClick: _navigateToNamespacesPage,
            hasSubNav: true,
            onClick: _loadNamespaces,
            subNavSelector: allNamespacesSelector,
            navItemConstructor: _constructNavItem
        },
        {
            ...CreateNamespaceItem,
            labelOnClick: _navigateToNamespaceCreatePage,
            hasSubNav: false
        }
    ]
}

export default ConfigPanelNavItem;