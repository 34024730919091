export const allNamespacesSelector = (state: any) => state.modelTraining.namespaces.namespaceList;
export const namespaceSelector = (state: any) => state.modelTraining.namespaces.fetchedNamespace;
export const runwaySelector = (state: any) => state.modelTraining.runways.fetchedRunway;
export const experimentationAccountInfraSelector = (state: any) => state.modelTraining.accountInfra.experimentation;
export const gammaAccountInfraSelector = (state: any) => state.modelTraining.accountInfra.gamma;
export const prodAccountInfraSelector = (state: any) => state.modelTraining.accountInfra.prod;
export const createExperimentSelector = (state: any) => state.modelTraining.experiments.createExperiment;

export const editExperimentSelector = (state: any) => state.modelTraining.experiments.editExperiment;
export const fetchedExperimentSelector = (state: any) => state.modelTraining.experiments.fetchedExperiment;
export const listExperimentsSelector = (state: any) => state.modelTraining.experiments.listExperiments;
export const createModelTrainingNamespaceSelector = (state: any) => state.modelTraining.namespaces.createModelTrainingNamespace;
export const updateModelTrainingNamespaceSelector = (state: any) => state.modelTraining.namespaces.updateModelTrainingNamespace;
export const registerCodeRepositorySelector = (state: any) => state.modelTraining.namespaces.registerCodeRepository;
export const preTrainCodeVersionsListSelector = (state: any) => state.modelTraining.namespaces.codeVersions.preTrain;
export const trainCodeVersionsListSelector = (state: any) => state.modelTraining.namespaces.codeVersions.train;
export const postTrainCodeVersionsListSelector = (state: any) => state.modelTraining.namespaces.codeVersions.postTrain;
export const createRunwaySelector = (state: any) => state.modelTraining.runways.createRunway;
export const trainingImagesSelector = (state: any) => state.modelTraining.runways.fetchedTrainingImages;
export const updateRunwaySelector = (state: any) => state.modelTraining.runways.updateRunway;
export const listRunwaysSelector = (state: any) => state.modelTraining.runways.listRunways;

export const listRunwayExecutionsSelector = (state: any) => state.modelTraining.runways.fetchRunwayExecutions;
export const listRunwayMetricsSelector = (state: any) => state.modelTraining.runways.executionMetrics;
