import React, {useEffect, useRef, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {loadRunway} from "src/control/actions/modelTraining/runway_actions";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles";
import {KatButton, KatCard, KatDivider, KatFlashbar, KatLabel, KatSpinner} from "@amzn/katal-react";
import JSONEditor from "src/view/commons/json_editor";
import {extractVersionIdFromSignedUrl, isEmpty, setIfMounted} from "src/utils/common_utils";
import {namespaceSelector, runwaySelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {FETCH_RUNWAY_ERROR_MESSAGE, Stages} from "src/view/modelTraining/constants";
import {getPrettyJsonString} from "src/utils/json_utils";
import {DEFAULT_BRANCH, JobStep, LATEST_CODE_VERSION, ModelTrainingStep} from "src/constant";
import {DownloadIcon} from "src/view/style/icons";
import {CloneButton} from "src/view/style/runway_styles";
import {useHistory} from "react-router";
import {getCodeVersions} from "src/control/actions/codeRepository/code_repository_actions";
import {RepositoryType} from "src/model/runways/job_model";
import {
    executionCodeVersionsSelector,
    postExecutionCodeVersionsSelector,
    preExecutionCodeVersionsSelector
} from "src/control/selectors/codeRepository/code_repo_selectors";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";


const moment = require("moment")

const ViewV1Runway = () => {

    const params: any = useParams();
    const dispatch = useDispatch();
    const TIMESTAMP_FORMAT = "YYYYMMDDHHmmss"
    const DISPLAY_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm"
    const defaultBaseTimestamp = (moment.utc('20220101000000', TIMESTAMP_FORMAT)).format(DISPLAY_TIMESTAMP_FORMAT);
    const DISPLAY_TIMESTAMP_FORMAT_FOR_CODE_VERSION = "YYYY-MM-DD HH:mm:ss"
    //Selectors
    let userId = useSelector(usernameSelector);
    const getRunwayData = useSelector(runwaySelector);
    let preTrainCodeVersions = useSelector(preExecutionCodeVersionsSelector);
    let postTrainCodeVersions = useSelector(postExecutionCodeVersionsSelector);
    let trainCodeVersions = useSelector(executionCodeVersionsSelector)
    let fetchedNamespace = useSelector(namespaceSelector);

    const numberOfVersions = getRunwayData.latestVersion

    const [selectedVersion, setSelectedVersion] = useState(numberOfVersions);
    const [runwayInfo, setRunwayInfo] = useState({
        domain: "",
        namespaceId: "",
        runwayId: "",
        version: 0,
        runwayName: "",
        createdBy: "",
        creationTime: "",
        scheduleFrequencyInMins: -1,
        baseTimestamp: -1,
        offsetInfo: {
            days: 0,
            hours: 0,
            minutes: 0,
        },
        modelTrainingInfo: {
            customCodePackageName: "",
            preTrain: {
                preTrainCustomCodeURI: "",
                codeVersion: "",
                modelAgeThreshold: 0
            },
            train: {
                trainingImage: "",
                trainCustomCodeURI: "",
                codeVersion: "",
                instanceType: "",
                instanceCount: "",
                volumeSizeInGB: 0,
                hyperparameters: {},
                trainingExecutionRole: "",
                inputChannelInfoList: [],
                modelOutputPath: ""
            },
            postTrain: {
                postTrainCustomCodeURI: "",
                codeVersion: "",
                deployArtifactName: "",
                deploymentBucket: "",
                deploymentKey: "",
                thresholdConditionsJson: "",
                archiveSubFolder: ""
            },
            features: {
                inputFeatures: [],
                responseVariables: []
            },
            modelTrainingMetadata: ""
        }
    })

    let isErrorMessage = getRunwayData.runwayFetched && !getRunwayData.showRunwaySuccess
    let _isMounted = useRef(true);
    const history = useHistory();

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    const initializeStates = () => {
        setIfMounted(_isMounted.current, setRunwayInfo, getRunwayData.runway)
        setIfMounted(_isMounted.current, setSelectedVersion, numberOfVersions)
    }

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, [Stages.EXPERIMENTATION]))
            dispatch(loadRunway(params.runwayId))
        }
    }, [userId, params.teamId, params.namespaceId, params.runwayId, dispatch]);

    useEffect(() => {
        initializeStates()
        if (!isEmpty(getRunwayData.runway.modelTrainingInfo.customCodePackageName)) {
            dispatch(getCodeVersions({
                    repositoryType: RepositoryType.GIT,
                    scriptPath: getRunwayData.runway.modelTrainingInfo.preTrain.preTrainCustomCodeURI,
                    branch: DEFAULT_BRANCH
                }, getRunwayData.runway.modelTrainingInfo.customCodePackageName, JobStep.PRE_EXECUTION
            ));

            dispatch(getCodeVersions({
                    repositoryType: RepositoryType.GIT,
                    scriptPath: getRunwayData.runway.modelTrainingInfo.train.trainCustomCodeURI,
                    branch: DEFAULT_BRANCH
                }, getRunwayData.runway.modelTrainingInfo.customCodePackageName, JobStep.EXECUTION
            ));

            dispatch(getCodeVersions({
                    repositoryType: RepositoryType.GIT,
                    scriptPath: getRunwayData.runway.modelTrainingInfo.postTrain.postTrainCustomCodeURI,
                    branch: DEFAULT_BRANCH
                }, getRunwayData.runway.modelTrainingInfo.customCodePackageName, JobStep.POST_EXECUTION
            ));
        }
    }, [getRunwayData])

    const getValueOrEmptyPlaceholder = (value: any) => {
        return !isEmpty(value) ? value : "-"
    }

    const isNullModelTrainingInfo = isEmpty(runwayInfo.modelTrainingInfo)
    const isNullPreTrain = isNullModelTrainingInfo || isEmpty(runwayInfo.modelTrainingInfo.preTrain)
    const isNullTrain = isNullModelTrainingInfo || isEmpty(runwayInfo.modelTrainingInfo.train)
    const isNullPostTrain = isNullModelTrainingInfo || isEmpty(runwayInfo.modelTrainingInfo.postTrain)
    const isNullFeatures = isNullModelTrainingInfo || isEmpty(runwayInfo.modelTrainingInfo.features)
    const isNullModelTrainingMetadata = isNullModelTrainingInfo || isEmpty(runwayInfo.modelTrainingInfo.modelTrainingMetadata)

    const getCodeScriptPathView = (value: string) => {
        return (
            <>
                <FormInput
                    label="Code script path"
                    type='text'
                    placeholder='Code script path'
                    tooltipText="Code script path"
                    value={getValueOrEmptyPlaceholder(value)}
                    disabled
                />
            </>
        )
    }

    const getCodeVersionList = (step: string) => {
        switch (step) {
            case ModelTrainingStep.PRE_TRAIN :
                return preTrainCodeVersions;
            case ModelTrainingStep.POST_TRAIN :
                return postTrainCodeVersions;
            case ModelTrainingStep.TRAIN :
                return trainCodeVersions;
            default :
                return {codeVersionsList: []}
        }
    }

    const getCloneRunwayButtonComponent = (runwayId: string) => {
        return (<CloneButton
            label="Clone"
            size="small"
            variant="primary"
            onClick={() => {
                let redirectedPath = window.location.pathname.replace(`${runwayId}/view`, `create`)
                history.push(redirectedPath + `?runwayId=${runwayId}`)
            }}/>)
    };

    const getCodeVersionFromVersionID = (codeVersionList: any[], versionId: string) => {
        let index = 1;
        let currVal = {
            name: versionId,
            versionId: versionId,
            signedS3Url: ""
        }
        codeVersionList.forEach((codeVersion) => {
            if (extractVersionIdFromSignedUrl(codeVersion.signedS3Url) == versionId) {
                const timestamp = moment.utc(new Date(codeVersion.timestamp * 1000)).format(DISPLAY_TIMESTAMP_FORMAT_FOR_CODE_VERSION)
                currVal = {
                    versionId: versionId,
                    signedS3Url: codeVersion.signedS3Url,
                    name: `[` + timestamp.toString() + `UTC] V${index++}`
                }
            }
            index++;
        })
        return currVal;
    }

    const getCodeVersion = (step: string, value: string) => {
        let codeVersion = getCodeVersionFromVersionID(getCodeVersionList(step).codeVersionsList, value)
        return (
            <>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <FormInput
                        label="Code Version"
                        type='text'
                        placeholder='Code Version'
                        tooltipText="Code Version"
                        value={getValueOrEmptyPlaceholder(codeVersion.name)}
                        disabled
                    />
                    {codeVersion.name != LATEST_CODE_VERSION ?
                        (<div style={{padding: "2%", height: "inherit", width: "50px"}}>
                            <DownloadIcon
                                onClick={(event: any) => {
                                    if (codeVersion.name != LATEST_CODE_VERSION && !isEmpty(codeVersion.name)) {
                                        window.open(codeVersion.signedS3Url, '_blank')?.focus();
                                    }
                                }}
                            />
                        </div>) : null}
                </div>
            </>
        )
    }

    const getSelectVersionView = () => {
        const versionOptions: any[] = []
        for (let version = numberOfVersions; version >= 1; version--) {
            versionOptions.push({
                "name": version.toString(),
                "value": version.toString()
            })
        }
        return (
            <>
                <FormDropDown
                    options={versionOptions}
                    value={selectedVersion.toString()}
                    onChange={
                        (event: any) => {
                            dispatch(loadRunway(params.runwayId, event.target.value))
                            setIfMounted(_isMounted.current, setSelectedVersion, event.target.value)
                        }
                    }
                >
                </FormDropDown>
            </>
        )
    }

    return (
        <>
            {!isEmpty(fetchedNamespace.namespaceData.namespace) && getRunwayData.runwayFetched ?
                <>
                    <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                runwayName={getRunwayData.runway.runwayName}></BreadCrumb>
                    {
                        isErrorMessage ?
                            <KatFlashbar variant={"danger"}
                                         header={FETCH_RUNWAY_ERROR_MESSAGE}
                            >
                            </KatFlashbar> :
                            null
                    }

                    <p style={{margin: "10px", float: "right"}}>
                        <p style={{float: "left"}}>
                            {getCloneRunwayButtonComponent(params.runwayId)}
                        </p>
                        <Link
                            to={`/runways/${params.teamId}/namespaces/${params.namespaceId}/${params.runwayId}/${selectedVersion}/update`}>
                            <KatButton label="Update" size="small" variant="primary"/>
                        </Link>
                    </p>

                    <KatLabel tooltipText="Select version for the runway.">
                        Version :&nbsp;
                    </KatLabel>
                    {getSelectVersionView()}
                    <br/>
                    <KatCard>
                        <span slot='title'> Runway </span>
                        <FormInput
                            label="Runway Name"
                            type='text'
                            placeholder='Runway name'
                            value={runwayInfo.runwayName}
                            tooltipText="Runway name"
                            disabled={true}
                        />
                        <FormInput
                            label="Created By"
                            type='text'
                            placeholder='Created By'
                            value={runwayInfo.createdBy}
                            tooltipText="Creator of the runway"
                            disabled
                        />
                        <FormInput
                            label="Creation time"
                            type='text'
                            placeholder='Creation time'
                            value={runwayInfo.creationTime}
                            tooltipText="Time of creation of the runway"
                            disabled
                        />
                        <FormInput
                            label="Domain"
                            type='text'
                            placeholder='Domain'
                            value={getValueOrEmptyPlaceholder(runwayInfo.domain)}
                            tooltipText="Domain of the runway."
                            disabled
                        />
                    </KatCard>
                    <KatCard>
                        <span slot='subtitle'> Scheduling</span>
                        <FormInput
                            label="Job base time (UTC)"
                            type='text'
                            value={runwayInfo.baseTimestamp ? (moment.utc((runwayInfo.baseTimestamp), TIMESTAMP_FORMAT)).format(DISPLAY_TIMESTAMP_FORMAT) : defaultBaseTimestamp}
                            tooltipText="Combination of Job base time and the frequency decides the job execution timestamp"
                            disabled
                        />
                        <FormInput
                            label="Scheduling frequency(in minutes)"
                            type='text'
                            placeholder='Scheduling frequency'
                            value={getValueOrEmptyPlaceholder(runwayInfo.scheduleFrequencyInMins).toString()}
                            tooltipText="Volume size"
                            disabled
                        />
                        <FormInput
                            label="Offset (in minutes)"
                            type='text'
                            value={runwayInfo.offsetInfo ? runwayInfo.offsetInfo.minutes.toString() : "0"}
                            tooltipText="Timestamp offset for the scheduled executions. Ex: if offset is -60, a job executed at 5:00 hrs, will resolve the timestamp for data as 4:00 hrs"
                            disabled
                        />
                        <br/>
                    </KatCard>
                    <KatCard>
                        {
                            isNullPreTrain ?
                                <>
                                    <KatFlashbar variant={"info"}
                                                 header={"No pre-train data found"}
                                    >
                                    </KatFlashbar>
                                </> :
                                <>
                                    <span slot='subtitle'>Pre-Train</span>
                                    {
                                        getCodeScriptPathView(
                                            runwayInfo.modelTrainingInfo.preTrain.preTrainCustomCodeURI
                                        )
                                    }
                                    {
                                        getCodeVersion(ModelTrainingStep.PRE_TRAIN, runwayInfo.modelTrainingInfo.preTrain.codeVersion ?
                                            runwayInfo.modelTrainingInfo.preTrain.codeVersion : LATEST_CODE_VERSION)
                                    }
                                    <FormInput
                                        label="Model age threshold"
                                        type='text'
                                        placeholder='Deployment Bucket'
                                        value={
                                            getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.preTrain.modelAgeThreshold).toString()
                                        }
                                        tooltipText="Model age threshold"
                                        disabled
                                    />
                                </>
                        }
                    </KatCard>

                    {isNullTrain ?
                        <>
                            <KatCard>
                                <KatFlashbar variant={"info"}
                                             header={"No train data found"}
                                >
                                </KatFlashbar>
                            </KatCard>
                        </> :
                        <>
                            <KatCard>
                                <span slot='subtitle'>Train</span>
                                <FormInput
                                    label="Instance Count"
                                    type='text'
                                    placeholder='Instance Count'
                                    value={
                                        getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.instanceCount).toString()
                                    }
                                    tooltipText="This is the instance count of the instance type specified while training."
                                    disabled
                                />
                                <KatLabel tooltipText="Instance type of the training instance created.">
                                    Training instance Type (Default: ml.t2.medium) :&nbsp;
                                </KatLabel>
                                <FormInput
                                    type={"text"}
                                    placeholder='Instance Type'
                                    tooltipText="Instance Type"
                                    value={
                                        getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.instanceType)
                                    }
                                    disabled
                                >
                                </FormInput>
                                <FormInput
                                    label="Volume Size(in Gb)"
                                    type='text'
                                    placeholder='Volume Size'
                                    value={
                                        getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.volumeSizeInGB).toString()
                                    }
                                    tooltipText="Volume size"
                                    disabled
                                />
                                <FormInput
                                    label="Training Image"
                                    type='text'
                                    placeholder='Training Image'
                                    value={
                                        getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.trainingImage).toString()
                                    }
                                    tooltipText="Training Image"
                                    disabled
                                />
                                <FormInput
                                    label="Training Execution Role"
                                    type='text'
                                    placeholder='Training Execution Role'
                                    value={
                                        getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.trainingExecutionRole)
                                    }
                                    tooltipText="Role used for training job."
                                    disabled
                                />
                                <KatLabel tooltipText="Hyper Parameters for the training job">
                                    Hyper Parameters for the training job :&nbsp;
                                </KatLabel>
                                <JSONEditor
                                    fieldName={"Hyper Parameters"}
                                    value={
                                        getPrettyJsonString(
                                            JSON.stringify(
                                                getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.hyperparameters)
                                            )
                                        ) as string
                                    }
                                    readOnly={true}
                                />
                                <FormInput
                                    label="Model Output Path"
                                    type='text'
                                    placeholder='Model Output Path'
                                    value={
                                        getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.train.modelOutputPath)
                                    }
                                    tooltipText="Output path for the model training job."
                                    disabled
                                />
                                <FormInput
                                    label="Code Repository"
                                    type='text'
                                    placeholder='Code Repository'
                                    value={
                                        isNullModelTrainingInfo ? "-" :
                                            getValueOrEmptyPlaceholder(runwayInfo.modelTrainingInfo.customCodePackageName)
                                    }
                                    tooltipText="Package name of the custom code repository"
                                    disabled
                                />
                                {getCodeScriptPathView(
                                    runwayInfo.modelTrainingInfo.train.trainCustomCodeURI
                                )}
                                {
                                    getCodeVersion(ModelTrainingStep.TRAIN, runwayInfo.modelTrainingInfo.train.codeVersion ?
                                        runwayInfo.modelTrainingInfo.train.codeVersion : LATEST_CODE_VERSION)
                                }
                            </KatCard>
                            <KatCard>
                                <span slot='subtitle'>Channels</span>
                                {
                                    runwayInfo.modelTrainingInfo.train.inputChannelInfoList.map((infoChannel: any) => {
                                        return (
                                            <>
                                                <FormInput
                                                    label="Channel Name"
                                                    type='text'
                                                    placeholder='Channel Name'
                                                    value={infoChannel.channelName}
                                                    tooltipText="Input Channel Name"
                                                    disabled
                                                />
                                                <FormInput
                                                    label="Data set"
                                                    type='text'
                                                    placeholder='Data Set'
                                                    value={infoChannel.datasetInfoName}
                                                    tooltipText="Data Set Name"
                                                    disabled
                                                />
                                                <KatDivider variant={"eastern"}/>
                                            </>
                                        )
                                    })
                                }
                            </KatCard>
                        </>
                    }
                    <KatCard>
                        {isNullPostTrain ?
                            <>
                                <KatFlashbar variant={"info"}
                                             header={"No post train data found"}
                                >
                                </KatFlashbar>
                            </> :
                            <>
                                <span slot='subtitle'>Post-Train</span>
                                {getCodeScriptPathView(
                                    runwayInfo.modelTrainingInfo.postTrain.postTrainCustomCodeURI
                                )}
                                {
                                    getCodeVersion(ModelTrainingStep.POST_TRAIN, runwayInfo.modelTrainingInfo.postTrain.codeVersion ?
                                        runwayInfo.modelTrainingInfo.postTrain.codeVersion : LATEST_CODE_VERSION)
                                }
                                <FormInput
                                    label="Deployment artifact name"
                                    type='text'
                                    placeholder='Deployment artifact name'
                                    value={
                                        runwayInfo.modelTrainingInfo.postTrain.deployArtifactName
                                    }
                                    tooltipText="Deployment artifact name"
                                    disabled
                                />
                                <FormInput
                                    label="Deployment Bucket"
                                    type='text'
                                    placeholder='Deployment Bucket'
                                    value={
                                        runwayInfo.modelTrainingInfo.postTrain.deploymentBucket
                                    }
                                    tooltipText="Deployment Bucket"
                                    disabled
                                />
                                <FormInput
                                    label="Deployment key"
                                    type='text'
                                    placeholder='Deployment key'
                                    value={
                                        runwayInfo.modelTrainingInfo.postTrain.deploymentKey
                                    }
                                    tooltipText="Deployment key"
                                    disabled
                                />
                                <KatLabel tooltipText="Threshold Configuration">
                                    Threshold Configuration :&nbsp;
                                </KatLabel>
                                <JSONEditor
                                    fieldName={"Threshold Configuration"}
                                    value={(
                                        getPrettyJsonString(runwayInfo.modelTrainingInfo.postTrain.thresholdConditionsJson)!
                                    )}
                                    width="400px"
                                    height="400px"
                                    readOnly={true}
                                />
                                <FormInput
                                    label="Archive Sub Folder"
                                    type='text'
                                    placeholder='Archive sub folder'
                                    value={
                                        getValueOrEmptyPlaceholder(
                                            runwayInfo.modelTrainingInfo.postTrain.archiveSubFolder
                                        )
                                    }
                                    tooltipText="Archive sub folder"
                                    disabled
                                />
                            </>
                        }
                    </KatCard>
                    <KatCard>
                        {
                            isNullFeatures ?
                                <>
                                    <KatFlashbar variant={"info"}
                                                 header={"No features data found"}
                                    >
                                    </KatFlashbar>
                                </> :
                                <>
                                    <span slot='subtitle'>Features</span>
                                    <KatLabel tooltipText="Input Features">
                                        Input Features:&nbsp;
                                    </KatLabel>
                                    <JSONEditor
                                        fieldName={"Input Features"}
                                        width="400px"
                                        height="250px"
                                        value={
                                            runwayInfo.modelTrainingInfo.features.inputFeatures
                                            && runwayInfo.modelTrainingInfo.features.inputFeatures.join(",")
                                        }
                                        readOnly={true}
                                    />
                                    <KatLabel tooltipText="response Variables">
                                        Response Variables:&nbsp;
                                    </KatLabel>
                                    <JSONEditor
                                        fieldName={"Response Variables"}
                                        width="400px"
                                        height="50px"
                                        value={
                                            runwayInfo.modelTrainingInfo.features.responseVariables
                                            && runwayInfo.modelTrainingInfo.features.responseVariables.join(",")
                                        }
                                        readOnly={true}
                                    />
                                </>
                        }
                    </KatCard>
                    <KatCard>
                        {
                            isNullModelTrainingMetadata ?
                                <>
                                    <KatFlashbar variant={"info"}
                                                 header={"No model training metadata found"}
                                    >
                                    </KatFlashbar>
                                </> :
                                <>
                                    <span slot='subtitle'>Model Training Metadata</span>
                                    <KatLabel tooltipText="Model Training Metadata">
                                        Metadata:&nbsp;
                                    </KatLabel>
                                    <JSONEditor
                                        fieldName={"Model Training Metadata"}
                                        width="400px"
                                        height="250px"
                                        value={
                                            runwayInfo.modelTrainingInfo.modelTrainingMetadata
                                        }
                                        readOnly={true}
                                    />
                                </>
                        }
                    </KatCard>
                </> :
                <KatSpinner/>
            }
        </>
    )
}

export default ViewV1Runway;