import {
    CREATE_MODEL_TRAINING_NAMESPACE_FAILURE,
    CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS,
    FETCH_ALL_MODEL_NAMESPACES_FAILURE,
    FETCH_ALL_MODEL_NAMESPACES_SUCCESS,
    FETCH_MODEL_TRAINING_NAMESPACE_FAILURE,
    FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS,
    START_CREATE_NAMESPACE_LOADER,
    START_UPDATE_NAMESPACE_LOADER,
    UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE,
    UPDATE_MODEL_TRAINING_NAMESPACE_SUCCESS,
    START_REGISTER_CODE_PACKAGE_LOADER,
    LIST_PRE_TRAIN_CODE_VERSIONS_SUCCESS,
    LIST_TRAIN_CODE_VERSIONS_SUCCESS,
    LIST_POST_TRAIN_CODE_VERSIONS_SUCCESS,
    START_FETCH_CODE_VERSIONS_PRE_TRAIN_LOADER,
    START_FETCH_CODE_VERSIONS_TRAIN_LOADER,
    START_FETCH_CODE_VERSIONS_POST_TRAIN_LOADER,
    LIST_CODE_VERSIONS_FAILURE
} from "../../actions/action_types";
import {namespaceInitialState} from "src/model/modelTraining/model_training_models"

export default function (state = namespaceInitialState, action: any) {
    switch (action.type) {
        case FETCH_ALL_MODEL_NAMESPACES_SUCCESS:
            return {
                ...state,
                namespaceList: action.payload
            }

        case FETCH_ALL_MODEL_NAMESPACES_FAILURE:
            return {
                ...state,
                namespaceList: []
            }
        case FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS:
            return {
                ...state,
                fetchedNamespace: {
                    ...state.fetchedNamespace,
                    namespaceFetched: true,
                    showNamespaceSuccess: true,
                    namespaceData: action.payload
                }
            }
        case FETCH_MODEL_TRAINING_NAMESPACE_FAILURE:
            return {
                ...state,
                fetchedNamespace: {
                    ...state.fetchedNamespace,
                    namespaceFetched: true,
                    showNamespaceSuccess: false
                }
            }
        case START_CREATE_NAMESPACE_LOADER:
            return {
                ...state,
                createModelTrainingNamespace:{
                    ...state.createModelTrainingNamespace,
                    startLoader: true,
                    isSuccess: false,
                    isError: false
                }
            }
        case CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS:
            return {
                ...state,
                createModelTrainingNamespace: {
                    ...state.createModelTrainingNamespace,
                    startLoader: false,
                    isSuccess: true,
                    isError: false
                }
            }
        case CREATE_MODEL_TRAINING_NAMESPACE_FAILURE:
            return {
                ...state,
                createModelTrainingNamespace: {
                    ...state.createModelTrainingNamespace,
                    startLoader: false,
                    isSuccess: false,
                    isError: true
                }
            }
        case START_UPDATE_NAMESPACE_LOADER:
            return {
                ...state,
                updateModelTrainingNamespace:{
                    ...state.updateModelTrainingNamespace,
                    startLoader: true,
                    isSuccess: false,
                    isError: false
                }
            }
        case UPDATE_MODEL_TRAINING_NAMESPACE_SUCCESS:
            return {
                ...state,
                updateModelTrainingNamespace: {
                    ...state.updateModelTrainingNamespace,
                    startLoader: false,
                    isSuccess: true,
                    isError: false
                }
            }
        case UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE:
            return {
                ...state,
                updateModelTrainingNamespace: {
                    ...state.updateModelTrainingNamespace,
                    startLoader: false,
                    isSuccess: false,
                    isError: true
                }
            }
        case START_REGISTER_CODE_PACKAGE_LOADER:
            return {
                ...state,
                registerCodeRepository:{
                    ...state.registerCodeRepository,
                    startLoader: true,
                    isSuccess: false,
                    isError: false,
                    showRegisterSuccess : false
                }
            }
        case LIST_PRE_TRAIN_CODE_VERSIONS_SUCCESS:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    preTrain: {
                        ...state.codeVersions.preTrain,
                        codeVersionsList: action.payload,
                        startLoader: false
                    }
                }
            }
        case LIST_POST_TRAIN_CODE_VERSIONS_SUCCESS:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    postTrain: {
                        ...state.codeVersions.postTrain,
                        codeVersionsList: action.payload,
                        startLoader: false
                    }
                }
            }
        case LIST_TRAIN_CODE_VERSIONS_SUCCESS:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    train: {
                        ...state.codeVersions.train,
                        codeVersionsList: action.payload,
                        startLoader: false
                    }
                }
            }
        case START_FETCH_CODE_VERSIONS_PRE_TRAIN_LOADER:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    preTrain : {
                        ...state.codeVersions.preTrain,
                        startLoader: true
                    }
                }
            }
        case START_FETCH_CODE_VERSIONS_TRAIN_LOADER:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    train : {
                        ...state.codeVersions.train,
                        startLoader: true
                    }
                }
            }
        case START_FETCH_CODE_VERSIONS_POST_TRAIN_LOADER:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    postTrain : {
                        ...state.codeVersions.postTrain,
                        startLoader: true
                    }
                }
            }
        case LIST_CODE_VERSIONS_FAILURE:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    preTrain: {
                        ...state.codeVersions.preTrain,
                        startLoader: false
                    },
                    train: {
                        ...state.codeVersions.train,
                        startLoader: false
                    },
                    postTrain: {
                        ...state.codeVersions.postTrain,
                        startLoader: false
                    }
                }
            }
        default:
            return state;
    }
}
