import {PopUp} from "src/view/style/view_config_styles";
import React, {useRef, useState} from "react";
import {getRepositoryTypeOptions} from "src/view/runways/constants";
import {KatRadiobuttonGroup} from "@amzn/katal-react";
import {RepositoryType} from "src/model/runways/job_model";
import GitRepoRegisterForm from "src/view/codeRepository/git_repository_form";
import S3RepoRegisterForm from "src/view/codeRepository/s3_repo_form";
import {SetFunction} from "src/view/dataset/dataset_constants";
import {isEmpty, setIfMounted} from "src/utils/common_utils";


export interface RegisterCodeRepoProps {
    isAlreadyExistingGitRepo: boolean,
    existingPackageName: string
    visibility: boolean,
    setVisibility: SetFunction<boolean>,
    setBranch: SetFunction<string>,
    setRepositoryName: SetFunction<string>
}

export default function RegisterCodeRepo(props: RegisterCodeRepoProps) {
    let _isMounted = useRef(true);

    const ADD_BRANCH = "Add Branch"
    const REGISTER_REPOSITORY = "Register Repository"

    const [repositoryType, setRepositoryType] = useState(RepositoryType.GIT)

    const setRepoNameWrapper = (value: string) => {
        props.setRepositoryName(value)
    };
    const setBranchWrapper = (value: string) => {
        props.setBranch(value)
    };

    const getRepoForm = () => {
        switch (repositoryType) {
            case RepositoryType.GIT:
                return <GitRepoRegisterForm
                    existingPackageName={props.existingPackageName}
                    isAlreadyExistingGitRepo={props.isAlreadyExistingGitRepo}
                    setVisibility={props.setVisibility}
                    setRepoNameWrapper={setRepoNameWrapper}
                    setBranchWrapper={setBranchWrapper}
                />
            case RepositoryType.S3:
                return <S3RepoRegisterForm
                    setVisibility={props.setVisibility}
                    setRepoNameWrapper={setRepoNameWrapper}
                />
        }
    }

    return (
        <>
            <PopUp visible={props.visibility}
                   onOpen={() => props.setVisibility(true)}
                   onClose={() => {props.setVisibility(false)}
                   }
            >
                <span slot={"title"}>{props.isAlreadyExistingGitRepo ? ADD_BRANCH : REGISTER_REPOSITORY}</span>
                {props.isAlreadyExistingGitRepo ?
                    <>
                    </> :
                    <KatRadiobuttonGroup
                        name="Repository Type"
                        label={"Repository Type *"}
                        options={getRepositoryTypeOptions()}
                        value={repositoryType}
                        onChange={
                            (event: any) => {
                                setIfMounted(_isMounted.current, setRepositoryType, event.target.value)
                            }
                        }
                    />
                }
                {isEmpty(repositoryType) ? <></> : getRepoForm()}
            </PopUp>
        </>
    )
}