import {isEmpty} from "src/utils/common_utils";

export const getFeaturesAsList = (featureString: string) => {
    if(isEmpty(featureString)) {
        return [];
    }
    return featureString.toString().split(",").map((feature) => feature.trim());
}

export const isValidFeatureList = (featureString: string) => {
    try {
        getFeaturesAsList(featureString);
        return true;
    } catch (error) {
        return false;
    }
}
