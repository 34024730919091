import {Failure, Request, Success} from "src/utils/action_utils";
import {
    CREATE_MODEL_TRAINING_NAMESPACE_FAILURE,
    CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS,
    ERROR_CREATING_MODEL_TRAINING_NAMESPACE,
    ERROR_FETCHING_MODEL_TRAINING_NAMESPACE,
    ERROR_IN_FETCHING_CODE_VERSION,
    ERROR_UPDATING_MODEL_TRAINING_NAMESPACE,
    FETCH_ALL_MODEL_NAMESPACES_FAILURE,
    FETCH_ALL_MODEL_NAMESPACES_REQUEST,
    FETCH_ALL_MODEL_NAMESPACES_SUCCESS,
    FETCH_MODEL_TRAINING_NAMESPACE_FAILURE,
    FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS,
    LIST_CODE_VERSIONS_FAILURE,
    LIST_POST_TRAIN_CODE_VERSIONS_SUCCESS,
    LIST_PRE_TRAIN_CODE_VERSIONS_SUCCESS,
    LIST_TRAIN_CODE_VERSIONS_SUCCESS,
    MODEL_TRAINING_NAMESPACE_NOT_FOUND,
    START_FETCH_CODE_VERSIONS_POST_TRAIN_LOADER,
    START_FETCH_CODE_VERSIONS_PRE_TRAIN_LOADER,
    START_FETCH_CODE_VERSIONS_TRAIN_LOADER,
    UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE,
    UPDATE_MODEL_TRAINING_NAMESPACE_SUCCESS
} from "src/control/actions/action_types";
import {apiGetPromise, apiPostPromise, apiPutPromise} from "src/utils/api_handler";
import {
    getCodeVersionTarget,
    getCreateModelTrainingNamespaceEndpoint,
    getFetchModelTrainingNamespaceTarget,
    getModelTrainingNamespaceEndpoint,
} from "src/control/targets";
import {ERROR_NAMESPACES_NOT_FOUND, ERROR_WHILE_FETCHING_NAMESPACE_INFO} from "src/control/errors";
import {isEmpty} from "src/utils/common_utils";
import {Stages} from "src/view/modelTraining/constants";
import {ModelTrainingStep} from "src/constant";

export const fetchAllNamespaces = (teamId: string) => async (dispatch: any) => {
    dispatch(Request(FETCH_ALL_MODEL_NAMESPACES_REQUEST));
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getFetchModelTrainingNamespaceTarget(teamId));
            if (!isEmpty(data) && !isEmpty(data.namespaceList)) {
                dispatch(Success(FETCH_ALL_MODEL_NAMESPACES_SUCCESS, data.namespaceList));
                resolve();
                return;
            }
            const error = ERROR_NAMESPACES_NOT_FOUND;
            dispatch(Failure(FETCH_ALL_MODEL_NAMESPACES_FAILURE, error));
            reject(error);
        } catch (err) {
            const error = ERROR_WHILE_FETCHING_NAMESPACE_INFO;
            dispatch(Failure(FETCH_ALL_MODEL_NAMESPACES_FAILURE, err));
            reject(error);
        }
    });
}

export const loadModelTrainingNamespace = (teamId: string, namespaceId: string, infraStagesList: Stages[]) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getModelTrainingNamespaceEndpoint(teamId, namespaceId));
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS, {
                    ...data,
                    stages: infraStagesList
                }));
                resolve();
                return;
            }
            const error = MODEL_TRAINING_NAMESPACE_NOT_FOUND;
            dispatch(Failure(FETCH_MODEL_TRAINING_NAMESPACE_FAILURE, error));
            reject(error);
        } catch (err) {
            const error = ERROR_FETCHING_MODEL_TRAINING_NAMESPACE;
            dispatch(Failure(FETCH_MODEL_TRAINING_NAMESPACE_FAILURE, err));
            reject(error);
        }
    });
}

export const createModelTrainingNamespace = (postData: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiPostPromise(getCreateModelTrainingNamespaceEndpoint(), postData);
            if (!isEmpty(data)) {
                data = {
                    ...data,
                    userId: postData.requestedBy,
                    teamId: postData.teamId,
                    accountTypeMap: postData.accountTypeMap
                }
                dispatch(Success(CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS, data));
                resolve();
                return;
            }
            const error = ERROR_CREATING_MODEL_TRAINING_NAMESPACE;
            dispatch(Failure(CREATE_MODEL_TRAINING_NAMESPACE_FAILURE, error));
            reject(error);
        } catch (err) {
            const error = ERROR_CREATING_MODEL_TRAINING_NAMESPACE;
            dispatch(Failure(CREATE_MODEL_TRAINING_NAMESPACE_FAILURE, error));
            reject(err);
        }
    });
}

export const updateModelTrainingNamespace = (teamId: string, namespaceId: string, putData: any) => async (dispatch: any) => {
    const error = ERROR_UPDATING_MODEL_TRAINING_NAMESPACE;
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiPutPromise(getModelTrainingNamespaceEndpoint(teamId, namespaceId), putData);
            if (!isEmpty(data)) {
                dispatch(Success(UPDATE_MODEL_TRAINING_NAMESPACE_SUCCESS, data));
                resolve();
                return;
            }
            dispatch(Failure(UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE, error));
            reject(err);
        }
    });
}

export const listCodeVersions = (customCodePackage: String, path: String, step: any) => {
    return async (dispatch: any) => {
        switch (step) {
            case ModelTrainingStep.PRE_TRAIN :
                dispatch({type: START_FETCH_CODE_VERSIONS_PRE_TRAIN_LOADER});
                break;
            case ModelTrainingStep.TRAIN :
                dispatch({type: START_FETCH_CODE_VERSIONS_TRAIN_LOADER});
                break;
            case ModelTrainingStep.POST_TRAIN :
                dispatch({type: START_FETCH_CODE_VERSIONS_POST_TRAIN_LOADER});
                break;
        }
        const error = ERROR_IN_FETCHING_CODE_VERSION;
        const codeVersionTargetInfo = {
            codePackage: customCodePackage,
            path: path
        }
        return new Promise(async (resolve, reject) => {
            try {
                let data: any = await apiGetPromise(getCodeVersionTarget(), codeVersionTargetInfo);
                switch (step) {
                    case ModelTrainingStep.PRE_TRAIN:
                        dispatch(Success(LIST_PRE_TRAIN_CODE_VERSIONS_SUCCESS, data.codeVersionsList));
                        break;
                    case ModelTrainingStep.POST_TRAIN:
                        dispatch(Success(LIST_POST_TRAIN_CODE_VERSIONS_SUCCESS, data.codeVersionsList));
                        break;
                    case ModelTrainingStep.TRAIN:
                        dispatch(Success(LIST_TRAIN_CODE_VERSIONS_SUCCESS, data.codeVersionsList));
                        break;
                }
                resolve();
                return;
            } catch (err) {
                dispatch(Failure(LIST_CODE_VERSIONS_FAILURE, error));
                reject(err);
            }
        });
    };
}
