import {
    KatBadge,
    KatButton,
    KatFlashbar,
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow
} from "@amzn/katal-react";
import {Link, useParams} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import React, {useEffect, useRef, useState} from "react";
import {ErrorContainer, ErrorHeading} from "src/view/style/modelTraining/errors"
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {TableHeader} from "src/view/style/modelTraining/table_styles";
import {sortList} from "src/utils/list_utils";
import {CopyIcon, DeleteIconTable, EditIconTable, InfoIcon, RestartIconTable} from "src/view/style/icons";
import {ClusterStatus, NotebookStatus, Stages, Status} from "src/view/modelTraining/constants";
import {PopUp} from "src/view/style/common_styles";
import {PaddedSpan} from "src/view/style/table_styles";
import CopyToClipboard from "react-copy-to-clipboard";
import {
    deleteNotebook,
    loadAllExperiments,
    loadClusterStatus,
    restartNotebook
} from "src/control/actions/modelTraining/experiment_actions";
import {listExperimentsSelector, namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {
    FETCH_CLUSTER_STATUS_INIT,
    LIST_EXPERIMENTS_LOADER,
    SET_EXPERIMENT_OPERATION_STARTED
} from "src/control/actions/action_types";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";


const ListExperiments = () => {
    const dispatch = useDispatch();
    let userId = useSelector(usernameSelector);
    let listExperimentsState = useSelector(listExperimentsSelector);
    let fetchedNamespace = useSelector(namespaceSelector);

    const MAX_RESULTS = 100;
    let _isMounted = useRef(true);
    const params: any = useParams()
    const date_options = {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'}
    const DateFormatter = new Intl.DateTimeFormat('en-US', date_options)
    const [restartPopUpVisible, setRestartPopUpVisible] = useState(false);
    const [deletePopUpVisible, setDeletePopUpVisible] = useState(false);
    const [experimentToRestart, setExperimentToRestart] = useState('');
    const [experimentToDelete, setExperimentToDelete] = useState('');
    const [infraInfoVisible, setInfraInfoVisible] = useState(false);
    const [experimentInfraToShow, setExperimentInfraToShow] = useState({
        kernel: "",
        clusterId: ""
    });

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    useEffect(() => {
        if (_isMounted.current) {
            loadExperimentResults()
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, [Stages.EXPERIMENTATION]))
        }
    }, [userId, params.teamId, params.namespaceId, params.runwayId, dispatch]);

    const loadExperimentResults = () => {
        dispatch(loadAllExperiments({
            namespaceId: params.namespaceId,
            runwayId: params.runwayId,
            nextToken: listExperimentsState.pageToken,
            maxResults: MAX_RESULTS
        }))
    }

    const fetchClusterInfo = (clusterId: string) => {
        dispatch({
            type: FETCH_CLUSTER_STATUS_INIT
        })
        dispatch(loadClusterStatus({
            namespaceId: params.namespaceId,
            runwayId: params.runwayId,
            teamId: params.teamId,
            clusterId: clusterId
        }))
    }

    const restartNotebookFlow = () => {
        dispatch({
            type: LIST_EXPERIMENTS_LOADER
        })
        dispatch(restartNotebook(
            {
                namespaceId: params.namespaceId,
                runwayId: params.runwayId,
                notebookInstanceName: experimentToRestart,
                teamId: params.teamId
            }
        ))
    }

    const deleteNotebookFlow = () => {
        dispatch({
            type: LIST_EXPERIMENTS_LOADER
        })
        dispatch(deleteNotebook({
            namespaceId: params.namespaceId,
            runwayId: params.runwayId,
            notebookInstanceName: experimentToDelete,
            teamId: params.teamId,
            userId: userId
        }))
    }

    const getConfirmRestartPopUp = () => {
        return (
            <>
                <PopUp visible={restartPopUpVisible}
                       onOpen={(event: any) => {
                           setRestartPopUpVisible(true)
                       }}
                       onClose={(event: any) => {
                           setRestartPopUpVisible(false)
                       }}
                >
                    <span slot={"title"}>Confirm Notebook Restart</span>

                    <p>
                        Are you sure you want to restart notebook : {experimentToRestart} ? Any running jobs will be
                        terminated. This might take a few minutes.
                    </p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton label="Restart" size="base" variant="primary" onClick={(event: any) => {
                            setOperationStarted(experimentToRestart)
                            setRestartPopUpVisible(false)
                            restartNotebookFlow()
                        }}/>
                    </div>
                </PopUp>
            </>
        );
    }

    const getConfirmDeletePopUp = () => {
        return (
            <>
                <PopUp visible={deletePopUpVisible}
                       onOpen={(event: any) => {
                           setDeletePopUpVisible(true)
                       }}
                       onClose={(event: any) => {
                           setDeletePopUpVisible(false)
                       }}
                >
                    <span slot={"title"}>Confirm Notebook Delete</span>

                    <p>
                        Are you sure you want to delete notebook : {experimentToDelete} ? Any running jobs will be
                        terminated. Uncommitted changes will be lost.
                    </p>

                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton label="Delete" size="base" variant="primary" onClick={(event: any) => {
                            setDeletePopUpVisible(false)
                            setOperationStarted(experimentToDelete)
                            deleteNotebookFlow()
                        }}/>
                    </div>
                </PopUp>
            </>
        );
    }

    const getInfrastructureInfoPopUp = () => {
        return (
            <>
                <PopUp visible={infraInfoVisible}
                       onOpen={(event: any) => {
                           setInfraInfoVisible(true)
                       }}
                       onClose={(event: any) => {
                           setInfraInfoVisible(false)
                       }}
                >
                    <span slot={"title"}>Infrastructure</span>
                    <p>
                        {
                            experimentInfraToShow.kernel.includes("EMR") ?
                                <div>
                                    Cluster Id:
                                    <PaddedSpan>
                                        {experimentInfraToShow.clusterId}
                                        <CopyToClipboard text={experimentInfraToShow.clusterId}>
                                            <CopyIcon data-tip="Copy" size="20" onClick={() => {
                                                dispatch(showSnackBar("Copied"))
                                            }}/>
                                        </CopyToClipboard>
                                    </PaddedSpan>
                                    <br/>
                                    <br/>
                                    {
                                        !isEmpty(listExperimentsState.clusterStatusForExperiment) &&
                                        <div>
                                            Cluster Status:
                                            <PaddedSpan>
                                                <KatBadge label={listExperimentsState.clusterStatusForExperiment}
                                                          type={getClusterStatusBadgeType(listExperimentsState.clusterStatusForExperiment)}/>
                                            </PaddedSpan>
                                        </div>
                                    }
                                </div>
                                : "Not applicable"
                        }
                    </p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton label="Done" size="base" variant="primary" onClick={(event: any) => {
                            setInfraInfoVisible(false)
                        }}/>
                    </div>
                </PopUp>
            </>
        );
    }

    const setOperationStarted = (experimentName: string) => {
        dispatch({
            type: SET_EXPERIMENT_OPERATION_STARTED,
            payload: {
                experimentName: experimentName
            }
        })
    }

    const handleRestartOnClick = (experimentName: any) => {
        setIfMounted(_isMounted.current, setExperimentToRestart, experimentName)
        setIfMounted(_isMounted.current, setRestartPopUpVisible, true)
    }

    const handleDeleteOnClick = (experimentName: any) => {
        setIfMounted(_isMounted.current, setExperimentToDelete, experimentName)
        setIfMounted(_isMounted.current, setDeletePopUpVisible, true)
    }

    const handleShowInfraInfoOnClick = (experiment: any) => {
        fetchClusterInfo(experiment.clusterId)
        setIfMounted(_isMounted.current, setExperimentInfraToShow, experiment)
        setIfMounted(_isMounted.current, setInfraInfoVisible, true)
    }

    const getNotebookStatusBadgeType = (status: string) => {
        switch (status) {
            case NotebookStatus.InService:
                return "success"
            case NotebookStatus.FAILED:
                return "warning"
            default:
                return "info"
        }
    }

    const getClusterStatusBadgeType = (status: string) => {
        switch (status) {
            case ClusterStatus.WAITING:
                return "success"
            case ClusterStatus.RUNNING:
                return "success"
            case ClusterStatus.TERMINATED:
                return "warning"
            default:
                return "info"
        }
    }

    const shouldEnableRestartButton = (experiment: any) => {
        return (
            experiment.notebookStatus == NotebookStatus.InService ||
            experiment.notebookStatus == NotebookStatus.UPDATE_FAILED ||
            experiment.notebookStatus == NotebookStatus.DELETION_FAILED
        ) && !experiment.operationStarted
    }

    const shouldEnableEditButton = (experiment: any) => {
        return experiment.notebookStatus == NotebookStatus.InService && !experiment.operationStarted
    }

    const shouldEnableDeleteButton = (experiment: any) => {
        return (
            experiment.notebookStatus == NotebookStatus.InService ||
            experiment.notebookStatus == NotebookStatus.UPDATE_FAILED ||
            experiment.notebookStatus == NotebookStatus.FAILED ||
            experiment.notebookStatus == NotebookStatus.DELETION_FAILED
        ) && !experiment.operationStarted
    }

    const getRow = (experiment: any) => {
        return (
            <KatTableRow key={experiment.experimentName}>
                {/*TODO: Change values in backend instead of replacing EMR with spark.*/}
                <KatTableCell>{experiment.experimentName}</KatTableCell>
                <KatTableCell>{experiment.userId}</KatTableCell>
                <KatTableCell>{DateFormatter.format(experiment.createTimestamp)}</KatTableCell>
                <KatTableCell>{DateFormatter.format(experiment.updateTimestamp)}</KatTableCell>
                <KatTableCell>{experiment.instanceType}</KatTableCell>
                <KatTableCell>{experiment.kernel.replace("EMR", "Spark")}</KatTableCell>
                <KatTableCell>
                    <KatBadge label={experiment.notebookStatus}
                              type={getNotebookStatusBadgeType(experiment.notebookStatus)}
                    />
                </KatTableCell>
                <KatTableCell>
                    <ReactTooltip place="bottom" type="info"/>
                    <PaddedSpan>
                        <InfoIcon onClick={(event: any) => handleShowInfraInfoOnClick(experiment)}/>
                    </PaddedSpan>
                    {
                        (shouldEnableDeleteButton(experiment)) &&
                        <PaddedSpan>
                            <DeleteIconTable data-tip="Delete"
                                             onClick={(event: any) => handleDeleteOnClick(experiment.experimentName)}/>
                        </PaddedSpan>
                    }
                    {
                        (shouldEnableEditButton(experiment)) &&
                        <PaddedSpan>
                            <Link
                                to={`/notebooks/${params.teamId}/namespaces/${params.namespaceId}/${params.runwayId}/${experiment.experimentName}/edit`}>
                                <EditIconTable data-tip="Edit"/>
                            </Link>
                        </PaddedSpan>

                    }
                    {
                        (shouldEnableRestartButton(experiment)) &&
                        <PaddedSpan>
                            <RestartIconTable data-tip="Restart"
                                              onClick={(event: any) => handleRestartOnClick(experiment.experimentName)}/>
                        </PaddedSpan>
                    }
                </KatTableCell>
            </KatTableRow>
        )
    }

    return (
        <>
            {fetchedNamespace.namespaceData.namespace &&
                <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}/>}
            {getInfrastructureInfoPopUp()}
            {getConfirmRestartPopUp()}
            {getConfirmDeletePopUp()}
            <div>
                {
                    listExperimentsState.clusterFetchError &&
                    <KatFlashbar variant="danger"
                                 header="Error"
                                 description="Error fetching cluster status"
                    />
                }
                {listExperimentsState.showMessage ?
                    <KatFlashbar variant={listExperimentsState.isSuccess ? "success" : "danger"}
                                 header={listExperimentsState.message}
                                 description={listExperimentsState.isSuccess ? "Refresh page to get latest status" : undefined}
                    >
                    </KatFlashbar>
                    : listExperimentsState.showLoader ? <KatSpinner/> : null
                }
            </div>
            {fetchedNamespace.namespaceData.namespace ?
                <>
                    <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}></BreadCrumb>
                    <p>
                        <Link style={{margin: "10px", float: "right"}}
                              to={`/notebooks/${params.teamId}/namespaces/${params.namespaceId}/${params.runwayId}/experiments/create`}>
                            <KatButton label="Create New" size="small" variant="primary"/>
                        </Link>
                        {listExperimentsState.pageToken != null &&
                            <KatButton label="Next" size="small" variant="primary" onClick={loadExperimentResults}/>
                        }
                    </p>
                    {
                        listExperimentsState.tableStatus == Status.ERROR ?
                            <ErrorContainer>
                                <ErrorHeading>Error loading experiments</ErrorHeading>
                            </ErrorContainer>
                            :
                            <>
                                <div>
                                    <TableHeader>
                                        Experiments
                                    </TableHeader>
                                </div>
                                <KatTable>
                                    {
                                        listExperimentsState.tableStatus == Status.InService && fetchedNamespace.namespaceData.namespace ?
                                            <>
                                                {
                                                    listExperimentsState.experiments == 0 ?
                                                        <ErrorContainer>
                                                            <ErrorHeading>No Results Found</ErrorHeading>
                                                        </ErrorContainer>
                                                        :
                                                        <>
                                                            <KatTableHead>
                                                                <KatTableRow>
                                                                    <KatTableCell>Name</KatTableCell>
                                                                    <KatTableCell>User</KatTableCell>
                                                                    <KatTableCell>Creation Date</KatTableCell>
                                                                    <KatTableCell>Last Updated</KatTableCell>
                                                                    <KatTableCell>Notebook Instance Type</KatTableCell>
                                                                    <KatTableCell>Kernel Type</KatTableCell>
                                                                    <KatTableCell>Status</KatTableCell>
                                                                    <KatTableCell>
                                                                        Actions
                                                                    </KatTableCell>
                                                                </KatTableRow>
                                                            </KatTableHead>
                                                            <KatTableBody>
                                                                {sortList(listExperimentsState.experiments, "createTime").map(getRow)}
                                                            </KatTableBody>
                                                        </>
                                                }
                                            </>
                                            : <KatSpinner/>
                                    }
                                </KatTable>
                            </>
                    }
                </> : <KatSpinner/>}
        </>
    );
}

export default ListExperiments;