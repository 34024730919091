import {combineReducers} from "redux";
import teamReducer from "./commons/team_reducer";
import userReducer from "./commons/user_reducer";
import regionReducer from "./commons/region_reducer";
import configPanelReducer from "./configPanel";
import featureGenerationReducer from "./featureGeneration";
import modelTrainingReducer from "./modelTraining";
import notebooksReducer from "./notebooks";
import snackBarReducer from "src/control/reducers/commons/snack_bar_reducer";
import datasetReducer from "src/control/reducers/dataset/dataset_reducer";
import {connectRouter} from 'connected-react-router';
import history from '../../history'
import {REFRESH_STATES} from "src/control/actions/action_types";
import reviewReducer from "./review/index";
import jobTemplateReducer from './runways/job_template_reducer'
import jobReducer from './runways/job_reducer'
import runwayReducer from './runways/runway_reducer'
import codeRepoReducer from "src/control/reducers/codeRepository/code_repository_reducers";


const allReducers = combineReducers({
    team: teamReducer,
    user: userReducer,
    region: regionReducer,
    snackBar: snackBarReducer,
    dataset: datasetReducer,
    configPanel: configPanelReducer,
    featureGeneration: featureGenerationReducer,
    modelTraining: modelTrainingReducer,
    notebooks: notebooksReducer,
    router: connectRouter(history),
    review: reviewReducer,
    job: jobReducer,
    jobTemplate: jobTemplateReducer,
    runway: runwayReducer,
    codeRepository: codeRepoReducer
});

const rootReducer = (state: any, action: any) => {
    if (action.type === REFRESH_STATES) {
        // Preserving team, user and router state as team and user are fetched everytime by header.
        const { team,  user, router ,region} = state;
        state = {team, user, router ,region}
    }
    return allReducers(state, action);
}

export default rootReducer;