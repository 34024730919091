import React, {useEffect, useRef, useState} from "react";
import {Alert} from "src/view/style/common_styles";
import {useDispatch, useSelector} from "react-redux";
import {snackBarMessageSelector} from "src/control/selectors/commons/snack_bar_selector";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

export interface SnackBarProps {
    timeOut: number
}

export function SnackBar(props: SnackBarProps) {
    let message = useSelector(snackBarMessageSelector);
    let [show, setShow] = useState(false);
    let timeOut = props.timeOut;
    const dispatch = useDispatch();

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    })

    useEffect(() => {
        if (isEmpty(message))
            return;
        setIfMounted(_isMounted.current, setShow, true)
        setTimeout(() => {
            setIfMounted(_isMounted.current, setShow, false);
            dispatch(showSnackBar(""));
        }, timeOut);

    }, [message])

    return (
        <Alert show={show} header={"Alert"} description={message}/>
    )
}