import { isEmpty } from "src/utils/common_utils";
import { initialJobState } from "../../../model/runways/job_model";
import {
  CREATE_JOB_SUCCESS,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_FAILURE,
  UPDATE_JOB_SUCCESS,
  UPDATE_JOB_FAILURE,
  LIST_JOBS_SUCCESS,
  LIST_JOBS_FAILURE,
  CREATE_JOB_FAILURE,
  START_JOB_LOADER,
  LOAD_JOB_EXECUTIONS_STATUS_FAILURE,
  LOAD_JOB_EXECUTIONS_STATUS_SUCCESS,
  START_LOAD_JOB_EXECUTIONS,
  DISABLE_JOB_EXECUTIONS_LOAD_MORE,
  FETCH_JOB_LATEST_VERSION_DATA_SUCCESS,
} from "../../actions/action_types";

export default function jobReducer(state = initialJobState, action: any) {
  switch (action.type) {
    case START_JOB_LOADER:
      return {
        ...state,
        isSpinnerLoading: true,
      };
    case CREATE_JOB_SUCCESS:
      return {
        ...state,
        fetchedJob: action.payload,
        isSpinnerLoading: false,
      };
    case CREATE_JOB_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case FETCH_JOB_SUCCESS:
      return {
        ...state,
        fetchedJob: action.payload.job,
        fetchedJobVersionsList: action.payload.jobVersionsList,
        isSpinnerLoading: false,
      };
    case FETCH_JOB_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_JOB_SUCCESS:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case UPDATE_JOB_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case LIST_JOBS_SUCCESS:
      return {
        ...state,
        fetchedJobList: action.payload,
        isSpinnerLoading: false,
      };
    case LIST_JOBS_FAILURE:
      return {
        ...state,
        isSpinnerLoading: false,
      };
    case LOAD_JOB_EXECUTIONS_STATUS_SUCCESS:
      return {
        ...state,
        fetchedJobExecutionsList: {
          ...state.fetchedJobExecutionsList,
          executionsList: [
            ...state.fetchedJobExecutionsList.executionsList,
            ...action.payload,
          ],
          jobsExecutionsFetched: true,
          fetchAllExecutionsSuccess: true,
        },
      };
    case LOAD_JOB_EXECUTIONS_STATUS_FAILURE:
      return {
        ...state,
        fetchedJobExecutionsList: {
          ...state.fetchedJobExecutionsList,
          jobsExecutionsFetched: true,
          fetchAllExecutionsSuccess: false,
        },
      };
    case START_LOAD_JOB_EXECUTIONS:
      return {
        ...state,
        fetchedJobExecutionsList: {
          ...state.fetchedJobExecutionsList,
          executionsList: [],
          loadMore: true,
          jobsExecutionsFetched: false,
          fetchAllExecutionsSuccess: false,
        },
      };
    case DISABLE_JOB_EXECUTIONS_LOAD_MORE:
      return {
        ...state,
        fetchedJobExecutionsList: {
          ...state.fetchedJobExecutionsList,
          loadMore: false,
        },
      };
    case FETCH_JOB_LATEST_VERSION_DATA_SUCCESS: {
      return {
        ...state,
        fetchedLatestVersionJob: action.payload.job
      }
    }
    default:
      return state;
  }
}
