export const jobTemplateDataSelector = (state: any) =>
  state.jobTemplate.fetchedJobTemplate;
export const jobTemplateListSelector = (state: any) =>
  state.jobTemplate.fetchedJobTemplateList;
export const isJobTemplateSpinnerLoadingSelector = (state: any) =>
  state.jobTemplate.isSpinnerLoading;
export const jobTemplateVersionsListSelector = (state: any) =>
  state.jobTemplate.fetchedJobTemplateVersionsList;
export const jobTemplateLatestVersionDataSelector = (state: any) =>
  state.jobTemplate.fetchedLatestVersionJobTemplate;
