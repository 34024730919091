import { KatCard } from "@amzn/katal-react";
import React, { useState } from "react";
import { RightArrowIcon } from "../style/icons";
/**
 * renders HTML elements into a collapsible area
 * @param props: JSX to be rendered within the collapsible area
 * @returns JSX
 */
export default function Collapsible(props: any) {
  //state hooks
  const [isOpen, setIsOpen] = useState(true);

  return (
    <>
      <KatCard>
        <span slot="subtitle" onClick={() => setIsOpen(!isOpen)}>
          {props.label}
          <RightArrowIcon
            style={{
              float: "right",
              transition: "transform 0.3s ease",
              transform: `rotate(${isOpen ? 90 : 0}deg)`,
            }}
          />
        </span>
        {isOpen && <div>{props.children}</div>}
      </KatCard>
    </>
  );
}
