import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchConfig,
    fetchConfigMetadata,
    fetchConfigValidationInfo,
    fetchMoreConfig,
    startConfigValidation,
    updateConfigValidators,
    uploadConfig
} from "../../control/actions/configPanel/config_panel_actions";
import {
    Configuration,
    Domains,
    PostStatus,
    UPDATE_CONFIG_VALIDATOR_FAILURE_MESSAGE,
    UPDATE_CONFIG_VALIDATOR_SUCCESS_MESSAGE
} from "./config_constants";
import {
    PaddedTableFooter,
    TableBody,
    TableCell,
    TableHead,
    TableHeadRow,
    TableRow,
} from "../style/table_styles";
import {
    getParamFromQueryString,
    getParamFromURL,
    resetParamsQueryString,
    setParamToQueryString
} from "../../utils/url_utils";
import {GreenSpinner, WhiteSpinner} from "../style/header_styles";
import {CouldNotLoadAlert} from "../style/common_styles";
import {
    GenericAlert,
    PopUp,
    VCancelButton,
    VCancelPopUpButton,
    VDiv,
    VDropDown,
    VInput,
    VInputHiddenDiv,
    VJSON,
    VKey,
    VLoadMore,
    VReview,
    VStatusIndicator,
    VSubmitButton,
    VSubmitPopUpButton,
    VTable,
    VTableHeader,
    VValue
} from "../style/view_config_styles";
import {
    CancelIcon,
    colorWhite,
    ContinueIcon,
    CopyIcon,
    ExternalLink,
    RefreshIcon,
    ReLoadIcon,
    smallIconSize,
    ToggleOff,
    ToggleOn,
    UploadIcon
} from "../style/icons";
import {getPrettyJsonString, isJSONFile, isValidJSON} from "src/utils/json_utils";
import {diffString} from "json-diff";
import {useHistory, useLocation} from "react-router-dom";
import {
    configBodySelector,
    configMetadataSelector,
    configValidationSelector,
    updateConfigMetadataSelector,
    validationTriggeredSelector,
    versionsSelector
} from "src/control/selectors/configPanel/config_panel_selectors";
import {isEmpty, isValidLambdaArn, isValidSemanticValidatorLambdaName, isValidMcmOrSev2Link, setIfMounted } from "src/utils/common_utils";
import {ERROR_CONFIG_NOT_FOUND, ERROR_EMPTY_TEAM_OR_NAMESPACE} from "src/control/errors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import JSONEditor from "../commons/json_editor";
import JSONDiffViewer from "src/view/commons/json_diff_viewer";
import CopyToClipboard from "react-copy-to-clipboard";
import {createConfigReview} from "src/control/actions/review/review_actions";
import {RequestStatus} from "src/model/review/review_types";
import {
    KatAlert,
    KatBadge,
    KatCard,
    KatSpinner,
    KatTabs,
    KatTab, KatLabel, KatFlashbar, KatRadiobutton, KatTableCell
} from "@amzn/katal-react";
import {ValidationStatus, ValidatorType} from "src/view/configPanel/config_panel_types";
import { DISABLE_CONFIG_VALIDATION } from "src/control/actions/action_types";
import CustomLambdaConfigValidator, { LambdaValidatorType } from "./custom_lambda_validator";
import { OperationType } from "src/constant";


export default function EditConfiguration() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const teamId = getParamFromURL("teamId");
    const namespaceId = getParamFromURL("namespaceId");
    const configId = getParamFromURL("configId");
    let versionFromQueryString = getParamFromQueryString(location.search, "version");
    let userName = useSelector(usernameSelector);

    let config = useSelector(configBodySelector);
    let configMetadata = useSelector(configMetadataSelector);
    let versions = useSelector(versionsSelector);
    let updateConfigMetadataResult = useSelector(updateConfigMetadataSelector);
    let configValidationInfo = useSelector(configValidationSelector);
    let startConfigValidationSelector = useSelector(validationTriggeredSelector);

    const [jsonSchemaValidator, setJsonSchemaValidator] = useState('{}');
    const [customValidatorLambdaArn, setCustomValidatorLambdaArn] = useState('arn:aws:lambda:');
    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [version, setVersion]: any = useState(versionFromQueryString);
    const [loading, setLoading] = useState(false);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [addValidatorsPopUpVisible, setAddValidatorsPopUpVisible] = useState(false);
    const [jsonDifference, setJsonDifference] = useState("");
    const [leftJsonValue, setLeftJsonValue] = useState("");
    const [rightJsonValue, setRightJsonValue] = useState("");
    const [currentConfig, setCurrentConfig] = useState<Configuration | null>(null);
    const [jsonConfig, setJsonConfig] = useState("");
    const [mcmOrSev2, setMcmOrSev2] = useState("");
    const [comment, setComment] = useState("");
    const [postStatus, setPostStatus] = useState(PostStatus.NONE);
    const [postStatusMessage, setPostStatusMessage] = useState("");
    const [isMCMOrSev2LinkValid, setIsMCMOrSev2LinkValid] = useState(false);
    const [numOfDiffs, setNumOfDiffs] = useState(0);
    const [errorPopupVisible, setErrorPopupVisible] = useState(false);
    const [customValidatorLambdaName, setCustomValidatorLambdaName] = useState("");

    const initialShowSplitDiff = true;
    const initialShowNewExperience = true;

    const [newExperience, setNewExperience] = useState(initialShowNewExperience);
    const [splitDiff, setSplitDiff] = useState(initialShowSplitDiff);
    const [reviewSpinner, setReviewSpinner] = useState(false)
    const [isConfigValidated, setIsConfigValidated] = useState(false);
    const [lambdaValidatorType, setLambdaValidatorType] = useState<LambdaValidatorType>(LambdaValidatorType.NONE);

    const _isMounted = useRef(true);
    const resetQueryParams = useRef(false);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    },[])

    useEffect(() => {
        if (resetQueryParams.current)
            resetParamsQueryString();
    }, [resetQueryParams])

    useEffect(() => {
        if (_isMounted.current)
            initialize();
    }, [dispatch, userName, teamId, namespaceId, configId, versionFromQueryString])

    useEffect(() => {
        if (_isMounted.current)
            initializeVersion();
    }, [dispatch, versionFromQueryString, versions, fetchSuccess])

    useEffect(() => {
        if (_isMounted.current)
            initializeStates();
    }, [config, version, fetchSuccess, configMetadata])

    useEffect(() => {
        if (!isEmpty(currentConfig)) {
            let domain = configMetadata ? configMetadata.domain : Domains.PROD;
            setIfMounted(_isMounted.current, setIsMCMOrSev2LinkValid, validateUpdatePrerequisites(mcmOrSev2, domain))
        }
    }, [currentConfig])

    const initialize = async () => {
        try {
            setIfMounted(_isMounted.current, setCurrentConfig, null);
            if (isEmpty(teamId) || isEmpty(namespaceId)) {
                throw ERROR_EMPTY_TEAM_OR_NAMESPACE;
            }

            if (isEmpty(configId)) {
                throw ERROR_CONFIG_NOT_FOUND;
            }

            await dispatch(fetchConfigMetadata(configId, {
                includeJsonSchemaBody: true,
            }));
            await dispatch(fetchConfig(configId));

            if (!isEmpty(versionFromQueryString)) {
                await dispatch(fetchMoreConfig(configId, versionFromQueryString as string))
            }

            setIfMounted(_isMounted.current, setFetchSuccess, true);
            setIfMounted(_isMounted.current, setErrorOccurred, false);
        } catch (error: any) {
            setIfMounted(_isMounted.current, setErrorOccurred, true);
            setIfMounted(_isMounted.current, setFetchSuccess, false);
            dispatch(showSnackBar(error.message));
        }
    }

    const initializeVersion = async () => {
        if (!isEmpty(versionFromQueryString)) {
            setVersionAndQueryParam(versionFromQueryString as string);
        } else if (!isEmpty(versions) && fetchSuccess) {
            setVersionAndQueryParam(versions[0])
        }
    }

    const initializeStates = () => {
        if (fetchSuccess && !isEmpty(config) && !isEmpty(version)) {
            setIfMounted(_isMounted.current, setJsonSchemaValidator, configMetadata.syntaxValidationSchemaBody);
            setIfMounted(_isMounted.current, setCustomValidatorLambdaArn, configMetadata.semanticValidationLambdaARN);
            setIfMounted(_isMounted.current, setCustomValidatorLambdaName, configMetadata.semanticValidationLambdaName);
            setIfMounted(_isMounted.current, setLambdaValidatorType, 
                (!isEmpty(customValidatorLambdaArn) && LambdaValidatorType.CUSTOM) 
                || (!isEmpty(customValidatorLambdaName) && LambdaValidatorType.ONBOARDED)
                || LambdaValidatorType.NONE
            )
            let currentConfiguration = getVersionDetails(version);
            if (!isEmpty(currentConfiguration) && configMetadata.configId == configId) {
                setIfMounted(_isMounted.current, setCurrentConfig, currentConfiguration);
                setIfMounted(_isMounted.current, setJsonConfig, getPrettyJsonString(currentConfiguration.jsonConfig) as string)
            }
        }
    }

    const setVersionAndQueryParam = (version: string) => {
        if (resetQueryParams.current)
            return;

        setParamToQueryString("version", version);
        setIfMounted(_isMounted.current, setVersion, version);
    }

    const getVersionOptions = (versions: any) => {
        return versions.map((v: any) => ({name: v, value: v.toString()}));
    }

    const getVersionDetails = (selectedVersion: string) => {
        return config.find((config: Configuration) => config.version == selectedVersion)
    }

    const getLoadMore = () => {
        let getIcon = loading ? <WhiteSpinner size={"small"}/> : <ReLoadIcon style={colorWhite} size={"25px"}/>
        return (
            <VLoadMore onClick={(e: any) => handleLoadMore()} disabled={loading}>
                {getIcon}
                &nbsp; &nbsp; Load More
            </VLoadMore>
        )
    }

    const handleVersionChange = (version: string) => {
        setVersionAndQueryParam(version);
    }

    const handleLoadMore = async (versionNeeded?: string) => {
        try {
            setIfMounted(_isMounted.current, setLoading, true);
            await dispatch(fetchMoreConfig(configId, versionNeeded));
            setIfMounted(_isMounted.current, setLoading, false);
        } catch (error: any) {
            setIfMounted(_isMounted.current, setLoading, false);
            dispatch(showSnackBar(error.message));
        }

    }

    const handleReset = () => {
        resetQueryParams.current = true;
        window.location.reload();
    }

    const showPopUp = () => {
        setIfMounted(_isMounted.current, setPopUpVisible, true);
    }

    const closePopUp = () => {
        setIfMounted(_isMounted.current, setPopUpVisible, false);
        setIfMounted(_isMounted.current, setSplitDiff, initialShowSplitDiff);
    }

    const getUpdateConfigValidatorMessage = () => {
        switch (updateConfigMetadataResult) {
            case RequestStatus.SUCCESS:
                return UPDATE_CONFIG_VALIDATOR_SUCCESS_MESSAGE
            case RequestStatus.FAILURE:
                return UPDATE_CONFIG_VALIDATOR_FAILURE_MESSAGE
            default:
                return ""
        }
    }

    const handleUpload = () => {
        try {
            closePopUp();
            setIfMounted(_isMounted.current, setPostStatus, PostStatus.LOADING);
            let dataToUpload = JSON.parse(jsonConfig);
            dataToUpload = JSON.stringify(dataToUpload);
            let stringComment = JSON.stringify({
                comment: comment,
                mcmOrSev2: mcmOrSev2,
                updatedBy: userName
            })
            dispatch(uploadConfig(
                configId,
                {
                    data: dataToUpload,
                    comment: stringComment,
                    requestedBy: userName

                },
                (data: any) => {
                    setIfMounted(_isMounted.current, setPostStatus, PostStatus.SUCCESS);
                    setIfMounted(_isMounted.current, setPostStatusMessage, data.message);
                },
                (error: any) => {
                    setIfMounted(_isMounted.current, setPostStatus, PostStatus.ERROR);
                    setIfMounted(_isMounted.current, setPostStatusMessage, error.message);
                }
            ))
        } catch (error: any) {
            dispatch(`JSON Parse error: ${error.message}`)
        }

    }

    const handleShowJSONDiff = () => {
        try {
            const latestVersion = !isEmpty(versions) ? versions[0] : null;
            if (isEmpty(latestVersion)) {
                dispatch(showSnackBar("Could not fetch latest version, something went wrong"));
                return;
            }
            let jsonOldConfig = JSON.parse(getVersionDetails(latestVersion).jsonConfig);
            let jsonNewConfig = JSON.parse(jsonConfig);
            let jsonDiff = diffString(jsonOldConfig, jsonNewConfig, {color: false});
            let leftValue = getPrettyJsonString(JSON.stringify(jsonOldConfig))
            let rightValue = getPrettyJsonString(JSON.stringify(jsonNewConfig))

            let domain = configMetadata ? configMetadata.domain : Domains.PROD;


            if (jsonDiff && comment && (mcmOrSev2 || !__isProdDomain(domain)) && isMCMOrSev2LinkValid) {
                setIfMounted(_isMounted.current, setJsonDifference, jsonDiff);
                setIfMounted(_isMounted.current, setLeftJsonValue, leftValue);
                setIfMounted(_isMounted.current, setRightJsonValue, rightValue);
                showPopUp();
            } else {
                if (!jsonDiff)
                    dispatch(showSnackBar("There is no change detected, please make a config change to proceed"))
                else if (!comment)
                    dispatch(showSnackBar("Please issue a comment, before proceeding"))
                else if (!mcmOrSev2)
                    dispatch(showSnackBar("Please provide an MCM/Sev2 link to continue"))
                else if (!isMCMOrSev2LinkValid)
                    dispatch(showSnackBar("Provided MCM/Sev2 link invalid, please provide the right link"))
            }
        } catch (error: any) {
            dispatch(showSnackBar(`JSON Parse error: ${error.message}`))
        }
    }

    const handleCreateReview = async (event: any) => {
        const latestVersion = !isEmpty(versions) ? versions[0] : null;
        if (isEmpty(latestVersion)) {
            dispatch(showSnackBar("Could not fetch latest version, something went wrong"));
            return;
        }
        let jsonOldConfig = JSON.parse(getVersionDetails(latestVersion).jsonConfig);
        let jsonNewConfig = JSON.parse(jsonConfig);
        let jsonDiff = diffString(jsonOldConfig, jsonNewConfig, {color: false});
        let rightContent = getPrettyJsonString(JSON.stringify(jsonNewConfig))
        if (!rightContent) rightContent = ""
        if (!jsonDiff) {
            dispatch(showSnackBar("There is no change detected, please make a config change to proceed"))
        } else {
            setReviewSpinner(true)
            try {
                const reviewId = await dispatch(createConfigReview(
                    configId,
                    userName,
                    rightContent,
                    configMetadata?.configName,
                    configMetadata?.domain,
                    configMetadata?.realm,
                    configMetadata?.applicationRealm,
                    configMetadata?.serviceName,
                    latestVersion,
                    window.location.href,
                ))
                setTimeout(() => {
                    setReviewSpinner(false)
                    history.push(`/review/${reviewId}`)
                }, 5000)
            } catch (err) {
                setReviewSpinner(false)
                dispatch(showSnackBar("Some error occurred while generating review. Please try again later"))
            }
        }
    }

    const handleFileUpload = (htmlInputId: string) => {
        const fileUploadElement = document.querySelector<HTMLInputElement>(htmlInputId)
        if (fileUploadElement) {
            fileUploadElement.value = "";
            fileUploadElement.click();
        }
    }

    const handleFileEvent = async (event: any, isDropEvent: boolean, setFileContent: any) => {
        let file = null;
        let fileCount = 0;
        if (isDropEvent) {
            event.preventDefault(); // preventing the default behavior of browser, which is to open any dragged file content in a new tab
            const dataTransferItems = event.dataTransfer.items;
            fileCount = dataTransferItems.length;
            if (dataTransferItems && fileCount === 1 && dataTransferItems[0].kind === 'file') {
                file = dataTransferItems[0].getAsFile();
            }
        } else {
            const fileUploadElement = event.target;
            fileCount = fileUploadElement.files.length;
            if (fileUploadElement.files && fileCount === 1) {
                file = fileUploadElement.files[0];
            }
        }
        if (file && file.name && isJSONFile(file.name)) {
            const fileContent = await file.text();
            if (fileContent) {
                setIfMounted(_isMounted.current, setFileContent, fileContent);
            }
        } else {
            dispatch(showSnackBar("Please select a SINGLE-JSON file only"));
        }
    }

    const handleFileDrag = (event: any) => {
        event.preventDefault(); // preventing the default behavior of browser, which is to open any dragged file content in a new tab
    }

    const shouldShowUploadStatus = () => {
        switch (postStatus) {
            case PostStatus.LOADING:
            case PostStatus.ERROR:
            case PostStatus.SUCCESS:
                return true;
            default:
                return false;
        }
    }

    const getPostStatusIndicator = () => {
        switch (postStatus) {
            case PostStatus.LOADING:
                return <VStatusIndicator variant={"loading"} label={"Uploading"}/>
            case PostStatus.SUCCESS:
                return <VStatusIndicator variant={"success"} label={"Successfully Uploaded"}/>
            case PostStatus.ERROR:
                return <VStatusIndicator variant={"error"} label={"Upload failed"}/>
            default:
                return <></>
        }
    }

    const getProdWarning = (configuration: Configuration) => {
        switch (configuration.domain.toUpperCase()) {
            case Domains.ONEPOD:
            case Domains.PROD:
            case Domains.PROD_REPLAY:
                return (
                    <GenericAlert
                        variant={"warning"}
                        header={"WARNING"}
                        persistent
                    >
                        Please Be Careful When Updating Production Configurations
                    </GenericAlert>)
            default:
                return <></>
        }
    }

    const __isProdDomain = (domain: string) => {
        switch (domain.toUpperCase()) {
            case Domains.PROD:
            case Domains.ONEPOD:
            case Domains.PROD_REPLAY:
                return true;
            default:
                return false;
        }
    }

    const validateUpdatePrerequisites = (mcmOrSev2: string, domain: string) => {
        return __isProdDomain(domain) ? isValidMcmOrSev2Link(mcmOrSev2) : true;
    }

    const getMCMOrSev2Row = (configuration: Configuration) => {
        return (
            <TableRow>
                <VKey>MCM/Sev2 Link *
                    &nbsp; &nbsp; &nbsp;
                    <VStatusIndicator
                        variant={isMCMOrSev2LinkValid ? "success" : "error"}
                        label={""}
                    />
                </VKey>
                <VValue>
                    <VInput
                        required
                        value={mcmOrSev2}
                        placeholder={"MCM/Sev2 for Updating"}
                        onInput={(e: any) => {
                            setIfMounted(
                                _isMounted.current,
                                setMcmOrSev2,
                                e.target.value
                            )
                            setIfMounted(
                                _isMounted.current,
                                setIsMCMOrSev2LinkValid,
                                validateUpdatePrerequisites(e.target.value, configuration.domain)
                            )
                        }}
                    />
                </VValue>
            </TableRow>
        )
    }

    const getPopUp = () => {
        if (shouldShowUploadStatus()) {
            return (
                <>
                    <PopUp visible
                           onClose={handleReset}
                    >
                        <span slot={"title"}>Upload Status</span>
                        {getPostStatusIndicator()}
                        <p>Server Response: {postStatusMessage}</p>
                        <div slot="footer" className="kat-group-horizontal">
                            <VSubmitPopUpButton onClick={(e: any) => {
                                resetQueryParams.current = true;
                                history.push(`/configPanel/${teamId}/namespaces/${namespaceId}/configs`)
                            }}>
                                Okay
                            </VSubmitPopUpButton>
                        </div>
                    </PopUp>
                </>
            );
        }
        return (
            <PopUp visible={popUpVisible}
                   onOpen={showPopUp}
                   onClose={closePopUp}
            >
                <span slot={"title"}>Confirm Changes</span>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className="col-12">
                            <div>
                                <strong>Diff</strong>
                                {/*
                                    Note: If there is a failure to set numOfDiffs by the aceDiff, the text should not be
                                    displayed.
                                */}
                                <div className={"float-right"}>
                                    {numOfDiffs > 0 && (<strong>Number of Differences: {numOfDiffs}</strong>)}
                                </div>
                                <br/>
                                <hr/>
                                <div className={"float-right"}
                                     onClick={() => {
                                         setIfMounted(_isMounted.current, setSplitDiff, !splitDiff)
                                     }}>
                                    {
                                        splitDiff ?
                                            <div className={"custom-toggle"}>
                                                <VStatusIndicator variant="information" label="Split Diff"/>
                                                &nbsp;&nbsp;
                                                <ToggleOn/>
                                            </div> :
                                            <div className={"custom-toggle"}>
                                                <VStatusIndicator variant="information" label="Inline Diff"/>
                                                &nbsp;&nbsp;
                                                <ToggleOff/>
                                            </div>
                                    }
                                </div>
                            </div>
                            <br/>
                            <hr/>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className="col-12">
                            <div style={{height: "500px"}}>
                                {splitDiff ?
                                    <JSONDiffViewer
                                        left_content={leftJsonValue}
                                        right_content={rightJsonValue}
                                        numDiffsCallbackFn={(value: number) => {
                                            setIfMounted(_isMounted.current, setNumOfDiffs, value)
                                        }}
                                        syncScroll={true}
                                    />
                                    :
                                    <VJSON
                                        value={jsonDifference}
                                        disabled
                                        className={"custom-terminal"}
                                    />
                                }


                            </div>
                        </div>
                    </div>
                </div>
                <div slot="footer" className="kat-group-horizontal">
                    <VCancelPopUpButton
                        onClick={closePopUp}
                        variant={"danger"}

                    >
                        <CancelIcon style={colorWhite} size={null}/>
                        &nbsp; &nbsp; Cancel
                    </VCancelPopUpButton>
                    <VSubmitPopUpButton onClick={handleUpload}>
                        <UploadIcon style={colorWhite} size={null}/>
                        &nbsp;&nbsp; Update
                    </VSubmitPopUpButton>
                </div>
            </PopUp>
        )
    }

    const submitValidatorsPopUp = () => {
        return (
            <>
                {!isEmpty(updateConfigMetadataResult) && updateConfigMetadataResult != RequestStatus.LOADING ?
                    <KatFlashbar variant={updateConfigMetadataResult == RequestStatus.SUCCESS ? "success" : "danger"}
                                 header={getUpdateConfigValidatorMessage()}
                    >
                    </KatFlashbar>
                    : <KatSpinner/>
                }
                <PopUp visible={addValidatorsPopUpVisible}
                       onOpen={() => {
                           setIfMounted(_isMounted.current, setAddValidatorsPopUpVisible, true)
                       }}
                       onClose={() => {
                           setIfMounted(_isMounted.current, setAddValidatorsPopUpVisible, false)
                       }}
                >
                    <span slot={"title"}>Confirm</span>
                    <p>
                        Are you sure you want to change validators?
                    </p>
                    <div slot="footer" className="kat-group-horizontal">
                        <VSubmitPopUpButton onClick={(event: any) => {
                            dispatch(updateConfigValidators(configId, 
                                jsonSchemaValidator, 
                                lambdaValidatorType === LambdaValidatorType.CUSTOM ? customValidatorLambdaArn : "", 
                                lambdaValidatorType === LambdaValidatorType.ONBOARDED ? customValidatorLambdaName : ""
                                ))
                            setIfMounted(_isMounted.current, setAddValidatorsPopUpVisible, false)
                        }}
                        >
                            <UploadIcon style={colorWhite} size={null}/>
                            &nbsp;&nbsp; Submit
                        </VSubmitPopUpButton>
                    </div>
                </PopUp>
            </>
        )
    }

    const getValidationBadgeType = (configValidity: any) => {
        switch (configValidity) {
            case ValidationStatus.VALID:
                return "success"
            case ValidationStatus.INVALID:
                return "warning"
            default:
                return "info"
        }
    }

    const isConfigValidatorPresent = () => {
        if (
            isEmpty(configMetadata.syntaxValidationSchemaS3Key) 
            &&
            (isEmpty(configMetadata.semanticValidationLambdaARN) ||  
              configMetadata.semanticValidationLambdaARN == "DDB_EMPTY" )
            &&
            (isEmpty(configMetadata.semanticValidationLambdaName) ||
              configMetadata.semanticValidationLambdaName == "DDB_EMPTY")
          ){
            if(!isConfigValidated)
                setIfMounted(
                    _isMounted.current,
                    setIsConfigValidated,
                    true
                )
            return false
        } else return true
    }

    const getConfigValidity = () => {
        if (configValidationInfo.validationInfoFetched) {
            let validationStatus = ValidationStatus.VALID
            if (isEmpty(configValidationInfo.validationData.validationResults)) {
                return ValidationStatus.STARTING
            }
            configValidationInfo.validationData.validationResults.forEach(
                (validation: any) => {
                    if (validation.validationStatus == ValidationStatus.INVALID || validation.validationStatus == ValidationStatus.INTERNAL_FAILURE) {
                        validationStatus = ValidationStatus.INVALID;
                        return;
                    }
                    if (validation.validationStatus == ValidationStatus.IN_PROGRESS) {
                        validationStatus = ValidationStatus.IN_PROGRESS
                        return;
                    }
                })
            if(validationStatus == ValidationStatus.VALID && !isConfigValidated) 
            setIfMounted(_isMounted.current, setIsConfigValidated, true);   
            return validationStatus
        } else {
            return ""
        }
    }

    const getConfigValidityView = () => {
        const configValidity: any = getConfigValidity()
        return (
            <>
                {
                    isConfigValidatorPresent() &&
                    <TableRow>
                        <VKey>Validation Status</VKey>
                        <VValue>
                            <VSubmitButton onClick={(event: any) => {
                                dispatch(startConfigValidation({
                                    configId: configMetadata.configId,
                                    requestedBy: userName,
                                    configChangeBody: jsonConfig
                                }))
                            }}>
                                Validate
                            </VSubmitButton>
                            {
                                [ValidationStatus.STARTING, ValidationStatus.IN_PROGRESS].indexOf(configValidity) > -1 &&
                                <VSubmitButton onClick={(event: any) => {
                                    dispatch(fetchConfigValidationInfo(startConfigValidationSelector.validationId))
                                }}>
                                    <RefreshIcon style={colorWhite} size={null}/>
                                    &nbsp;&nbsp; Refresh Status
                                </VSubmitButton>
                            }
                            {
                                configValidationInfo.startFetchValidationInfoLoader ?
                                    <KatSpinner/> :
                                    <>
                                        {

                                            configValidationInfo.validationInfoFetched ?
                                                <>
                                                    {
                                                        configValidationInfo.validationInfoFetchSuccess ?
                                                            <>
                                                                <KatBadge label={configValidity}
                                                                          type={getValidationBadgeType(configValidity)}
                                                                />
                                                                {[ValidationStatus.INVALID].indexOf(configValidity) > -1 &&
                                                                <div onClick={() => {
                                                                    setIfMounted(_isMounted.current, setErrorPopupVisible, true);
                                                                }}>
                                                                    <strong>Show errors</strong>&nbsp;
                                                                </div>
                                                                }
                                                            </>
                                                            : <KatAlert variant={"danger"}
                                                                        header={"Unable to load validation status"}
                                                            >
                                                            </KatAlert>
                                                    }
                                                </> :
                                                <></>
                                        }
                                    </>
                            }
                        </VValue>
                    </TableRow>
                }
            </>
        )
    }

    const getValidatorHeader = (validatorType: string) => {
        switch (validatorType) {
            case ValidatorType.SYNTACTIC:
                return "JSON Validator"
            case ValidatorType.SEMANTIC:
                return "Custom Validator"
        }
    }

    /**
     * Pop-up view for config validation errors.
     */
    const getValidationErrorsPopup = () => {
        return (
            <>
                <PopUp visible={errorPopupVisible}
                       onOpen={(event: any) => {
                           setIfMounted(_isMounted.current, setErrorPopupVisible, true);
                       }}
                       onClose={(event: any) => {
                           setIfMounted(_isMounted.current, setErrorPopupVisible, false);
                       }}
                >
                    <span slot={"title"}>Errors</span>
                    <p>
                        {
                            configValidationInfo.validationInfoFetched &&
                            configValidationInfo.validationData.validationResults.map((validationResult: any) => {
                                return (
                                    <KatCard>
                                        <span
                                            slot='subtitle'>{getValidatorHeader(validationResult.validatorType)}</span>
                                        {
                                            validationResult.validationStatus == ValidationStatus.INTERNAL_FAILURE ?
                                                <strong>INTERNAL SERVICE FAILURE</strong> :
                                                <ol>
                                                    {
                                                        validationResult.errors.length > 0 ?
                                                            validationResult.errors.map((error: string) => {
                                                                return (
                                                                    <li>{error}</li>
                                                                )
                                                            }) : <strong>VALID</strong>
                                                    }
                                                </ol>
                                        }
                                    </KatCard>
                                )
                            })
                        }
                    </p>
                </PopUp>
            </>
        );
    }

    const isValidJsonSchemaValidator = () => {
        return isValidJSON(jsonSchemaValidator) || isEmpty(jsonSchemaValidator)
    }

    const addValidators = () => {
        return (
            <>
                <VDiv>
                    <VTable>
                        <TableBody>
                            <div>
                            <TableRow>
                                <VKey>
                                    <KatLabel tooltipText="
                                    The JSON schema against which your configuration body will be validated.
                                    Please refer to https://json-schema.org/ for writing json schema's
                                    ">
                                        JSON schema validator
                                        <VStatusIndicator
                                            variant={isValidJsonSchemaValidator() ? "success" : "error"}
                                            label={""}
                                        />
                                    </KatLabel>
                                </VKey>
                                <VValue>
                                    <div id='file-drop-area-json-validator' onDragOver={handleFileDrag}
                                         onDrop={(event) => {
                                             handleFileEvent(event, true, setJsonSchemaValidator)
                                         }
                                         }
                                         style={{height: "400px", width: "800px"}}>
                                        <JSONEditor
                                            fieldName={"JSON schema validator"}
                                            value={jsonSchemaValidator}
                                            onChange={(name: any, value: any) => {
                                                setIfMounted(
                                                    _isMounted.current,
                                                    setJsonSchemaValidator,
                                                    value
                                                )
                                            }}
                                            readOnly={false}
                                        />
                                    </div>
                                    <br/>
                                    <div className={"container"} style={{maxWidth: "800px"}}>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                                <div className={"float-left"}
                                                     style={{display: "flex", marginLeft: "-10px"}}>
                                                    <CopyToClipboard text={`${jsonSchemaValidator}`}>
                                                        <div onClick={() => {
                                                            dispatch(showSnackBar("Schema Copied"))
                                                        }}>
                                                            <strong>Copy</strong>&nbsp;
                                                            <CopyIcon size={smallIconSize}/>
                                                        </div>
                                                    </CopyToClipboard>
                                                    <div style={{marginLeft: "20px", cursor: "pointer"}}
                                                         onClick={(event: any) => {
                                                             handleFileUpload("#file-upload-schema-validator-input")
                                                         }}>
                                                        <strong title="Choose File or Drag and Drop">Choose
                                                            File</strong>&nbsp;&nbsp;
                                                        <UploadIcon size={"15px"}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </VValue>
                            </TableRow>
                </div>

                <TableRow>
                    <CustomLambdaConfigValidator 
                        operationType= {OperationType.UPDATE}
                        lambdaValidatorType= {lambdaValidatorType}
                        setLambdaValidatorType={setLambdaValidatorType}
                        customValidatorLambdaArn= {customValidatorLambdaArn}
                        setCustomValidatorLambdaArn= {setCustomValidatorLambdaArn}
                        customValidatorLambdaName= {customValidatorLambdaName}
                        setCustomValidatorLambdaName= {setCustomValidatorLambdaName}
                    />
                </TableRow>
              </TableBody>
              <PaddedTableFooter>
                <VSubmitButton
                  disabled={
                    !(
                      (isValidSemanticValidatorLambdaName(
                        customValidatorLambdaName
                      ) &&
                        isValidLambdaArn(customValidatorLambdaArn)) &&
                      isValidJsonSchemaValidator()
                    )
                  }
                  onClick={() => {
                    setIfMounted(
                      _isMounted.current,
                      setAddValidatorsPopUpVisible,
                      true
                    );
                  }}
                >
                  Submit
                </VSubmitButton>
                <VInputHiddenDiv>
                  <input
                    id="file-upload-schema-validator-input"
                    type="file"
                    onChange={(event) => {
                      handleFileEvent(event, false, setJsonSchemaValidator);
                    }}
                    accept=".json"
                  />
                </VInputHiddenDiv>
              </PaddedTableFooter>
            </VTable>
          </VDiv>
        </>
      );
    };

    const getUpdateValidatorsResultView = () => {
        switch (updateConfigMetadataResult) {
            case RequestStatus.FAILURE:
                return (
                    <>
                        <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                            Error updating validators
                        </CouldNotLoadAlert>
                    </>
                )
            case RequestStatus.LOADING:
                return (
                    <>
                        <GreenSpinner variant={"default"} size={"large"}/>
                    </>
                )
            case RequestStatus.SUCCESS:
                return (
                    <>
                        <KatAlert variant={"success"}>
                            Successfully updated config validators
                        </KatAlert>
                    </>
                )
        }
    }

    const display = () => {
        let currentConfigLoaded = !isEmpty(currentConfig)

        if (errorOccurred) {
            return (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    Could Not Load Configuration
                </CouldNotLoadAlert>
            );
        }

        if (!currentConfigLoaded || reviewSpinner) {
            return (
                <GreenSpinner variant={"default"} size={"large"}/>
            );
        }

        const versionOptions = getVersionOptions(versions);

        return (
            <>
                {getValidationErrorsPopup()}
                {getUpdateValidatorsResultView()}
                {getProdWarning(configMetadata)}
                <VDiv>
                    {(popUpVisible || shouldShowUploadStatus()) && getPopUp()}
                    {addValidatorsPopUpVisible && submitValidatorsPopUp()}
                    <VTable>
                        <TableHead>
                            <TableHeadRow>
                                <TableCell>
                                    <VTableHeader>Edit</VTableHeader>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableHeadRow>
                            <TableHeadRow>
                                <TableCell> Key </TableCell>
                                <TableCell> Value </TableCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <VKey>Configuration Name</VKey>
                                <VValue>
                                    <VInput value={configMetadata.configName} readonly/>
                                </VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Description</VKey>
                                <VValue>
                                    <VInput value={configMetadata.description} readonly/>
                                </VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Service Name</VKey>
                                <VValue>
                                    <VInput value={configMetadata.serviceName} readonly/>
                                </VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Realm</VKey>
                                <VValue>
                                    <VValue>{!isEmpty(configMetadata.applicationRealm) ? configMetadata.applicationRealm : configMetadata.realm}</VValue>
                                </VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Domain</VKey>
                                <VValue>
                                    <VInput value={configMetadata.domain} readonly/>
                                </VValue>
                            </TableRow>
                        </TableBody>
                    </VTable>
                </VDiv>
                <KatTabs selected={"configMetadata"}>
                    <KatTab tabId="configMetadata" label="Config">
                        <VDiv>
                            <VTable>
                                <TableBody>
                                    <TableRow>
                                        <VKey>Version</VKey>
                                        <VValue>
                                            <VDropDown
                                                options={versionOptions}
                                                value={version}
                                                placeholder="Choose Version"
                                                onChange={(e: any) => {
                                                    handleVersionChange(e.detail.value);
                                                }}
                                            />
                                            {getLoadMore()}
                                        </VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>
                                            Configuration
                                            <VStatusIndicator
                                                variant={isValidJSON(jsonConfig) ? "success" : "error"}
                                                label={""}
                                            />
                                        </VKey>
                                        <VValue>
                                            <div className={"float-right"}
                                                 onClick={() => {
                                                     setIfMounted(_isMounted.current, setNewExperience, !newExperience)
                                                 }}>
                                                {
                                                    newExperience ?
                                                        <div className={"custom-toggle"}>
                                                            <VStatusIndicator variant="success" label="New Experience"/>
                                                            &nbsp;&nbsp;
                                                            <ToggleOn/>
                                                        </div> :
                                                        <div className={"custom-toggle"}>
                                                            <VStatusIndicator variant="alert" label="Old Experience"/>
                                                            &nbsp;&nbsp;
                                                            <ToggleOff/>
                                                        </div>
                                                }
                                            </div>
                                            <br/>
                                            <div id='file-drop-area' onDragOver={handleFileDrag} onDrop={(event) => {
                                                handleFileEvent(event, true, setJsonConfig);
                                            }}
                                                 style={{height: "400px", width: "800px"}}>
                                                {newExperience ?
                                                    <JSONEditor
                                                        fieldName={"configEdit"}
                                                        value={jsonConfig}
                                                        onChange={(name: any, value: any) => {
                                                            dispatch({type: DISABLE_CONFIG_VALIDATION})
                                                            setIfMounted(
                                                                _isMounted.current,
                                                                setIsConfigValidated,
                                                                false
                                                            )
                                                            setIfMounted(
                                                                _isMounted.current,
                                                                setJsonConfig,
                                                                value
                                                            )
                                                        }}
                                                        readOnly={false}
                                                    /> :
                                                    <VJSON
                                                        value={jsonConfig}
                                                        onChange={(e: any) => {
                                                            setIfMounted(
                                                                _isMounted.current,
                                                                setJsonConfig,
                                                                e.target.value
                                                            )
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <br/>
                                            <div className={"container"} style={{maxWidth: "800px"}}>
                                                <div className={"row"}>
                                                    <div className={"col"}>
                                                        <div className={"float-left"}
                                                             style={{display: "flex", marginLeft: "-10px"}}>
                                                            <CopyToClipboard text={`${jsonConfig}`}>
                                                                <div onClick={() => {
                                                                    dispatch(showSnackBar("Config Copied"))
                                                                }}>
                                                                    <strong>Copy</strong>&nbsp;
                                                                    <CopyIcon size={smallIconSize}/>
                                                                </div>
                                                            </CopyToClipboard>
                                                            <div style={{marginLeft: "20px", cursor: "pointer"}}
                                                                 onClick={(event: any) => {
                                                                     handleFileUpload("#file-upload-input")
                                                                 }}
                                                            >
                                                                <strong title="Choose File or Drag and Drop">Choose
                                                                    File</strong>&nbsp;&nbsp;
                                                                <UploadIcon size={"15px"}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>


                                        </VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey></VKey>
                                        <KatAlert
                                            variant={"info"}
                                            header={""}
                                            persistent
                                        >
                                            Click <a href="https://w.amazon.com/bin/view/Advertising/SupplyQuality/SQI/Experimentation/Expresso-Console/Configuration-Panel/User-Guide/#H4.ADDITIONALRESOURCES:" target="_blank"> here</a> to know about the rules of JSON Editor
                                        </KatAlert>
                                    </TableRow>
                                    {!__isProdDomain(configMetadata?.domain ?? "") && <TableRow>
                                        <VKey>Comments *</VKey>
                                        <VValue>
                                            <VInput
                                                required
                                                value={comment}
                                                placeholder={"Comment for Updating"}
                                                onChange={(e: any) => {
                                                    setIfMounted(
                                                        _isMounted.current,
                                                        setComment,
                                                        e.target.value
                                                    )
                                                }}
                                            />
                                        </VValue>
                                    </TableRow>
                                    }
                                    {
                                        getConfigValidityView()
                                    }
                                </TableBody>
                                <PaddedTableFooter>
                                    <VCancelButton
                                        onClick={handleReset}
                                        variant={"danger"}
                                    >
                                        <CancelIcon style={colorWhite} size={null}/>
                                        &nbsp; &nbsp; Reset
                                    </VCancelButton>
                                    {(configMetadata?.domain.toLowerCase() === "beta" || configMetadata?.domain.toLowerCase() === "gamma") &&
                                    <VSubmitButton onClick={handleShowJSONDiff}
                                                   disabled={(isConfigValidatorPresent() && !isConfigValidated) || !isValidJSON(jsonConfig)}>
                                        <ContinueIcon style={colorWhite} size={null}/>
                                        &nbsp;&nbsp; Continue
                                    </VSubmitButton>}
                                    {
                                        configMetadata?.domain.toLowerCase() !== "beta" && configMetadata?.domain.toLowerCase() !== "gamma" &&
                                        <VReview onClick={handleCreateReview}
                                                 disabled={(isConfigValidatorPresent() && !isConfigValidated) || !isValidJSON(jsonConfig)}
                                        >
                                            Review
                                        </VReview>
                                    }
                                    <VInputHiddenDiv>
                                        <input id='file-upload-input' type='file' onChange={(event) => {
                                            handleFileEvent(event, false, setJsonConfig);
                                        }} accept='.json'/>
                                    </VInputHiddenDiv>
                                </PaddedTableFooter>
                            </VTable>
                        </VDiv>
                    </KatTab>
                    <KatTab tabId="configValidators" label="Validators">
                        {addValidators()}
                    </KatTab>
                </KatTabs>

            </>
        );
    }

    return (
        <>
            {display()}
        </>
    )
}