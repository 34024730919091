import React, {useEffect, useRef, useState} from "react";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles";
import {KatAlert, KatButton, KatFlashbar, KatLabel, KatRadiobuttonGroup, KatSpinner} from "@amzn/katal-react";
import {INSTANCE_TYPES} from "src/view/modelTraining/experiments/instance_types";
import {useParams} from "react-router-dom";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {
    EXPERIMENT_FETCH_FAILED_MESSAGE,
    FIELDS_REQUIRED_MESSAGE,
    getKernelOptions,
    Stages,
} from "src/view/modelTraining/constants";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {
    editExperimentSelector,
    fetchedExperimentSelector,
    namespaceSelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {loadExperimentResult, updateExperiment} from "src/control/actions/modelTraining/experiment_actions";
import {START_UPDATE_EXPERIMENT_LOADER} from "src/control/actions/action_types";
import {GitRepository, RepositoryType, S3Repository} from "src/model/codeRepository/code_repository_models";
import LifecycleConfigScript from "./lifecycle_config_management";
import {isValidCustomScriptPath} from "src/utils/model_training_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {OperationType} from "src/constant";


const EditExperiment = () => {
    const params: any = useParams()
    const dispatch = useDispatch();

    let _isMounted = useRef(true);
    const [kernel, setKernel] = useState('')
    const [instanceType, setInstanceType] = useState('ml.t2.medium')
    const [lcScriptType, setLcScriptType] = useState<RepositoryType | null> (null);
    const [gitRepository, setGitRepository] = useState<GitRepository>({
      packageName: "",
      branchList: ["mainline"],
    });
    const [s3Repository, setS3Repository] = useState<S3Repository>({
      bucketName: "",
    });
    const [scriptPath, setScriptPath] = useState('');

    let userId = useSelector(usernameSelector);
    let editExperimentState = useSelector(editExperimentSelector);
    let fetchedExperimentState = useSelector(fetchedExperimentSelector);
    let fetchedNamespace = useSelector(namespaceSelector);
    const hasExperimentDataLoaded: boolean = !isEmpty(
      fetchedExperimentState?.experimentData.experimentName
    );

    let showMessage = editExperimentState.showUpdateExperimentDisplayMessage ||
        (fetchedExperimentState.experimentFetched && !fetchedExperimentState.experimentFetchSuccess)

    let isSuccessMessage = editExperimentState.showUpdateExperimentSuccess &&
        fetchedExperimentState.experimentFetchSuccess

    let disableSubmit = !(fetchedExperimentState.experimentFetched && fetchedExperimentState.experimentFetchSuccess) ||
        isEmpty(userId)

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, [Stages.EXPERIMENTATION]))
            dispatch(loadExperimentResult(params.experimentName))
        }
    }, [userId, params.teamId, params.namespaceId, params.experimentName]);

    useEffect(() => {
        if (_isMounted.current) {
            initializeStates()
        }
    }, [fetchedExperimentState]);

    const initializeStates = () => {
        setIfMounted(_isMounted.current, setKernel, fetchedExperimentState.experimentData.kernel)
        if (!isEmpty(fetchedExperimentState.experimentData.lcScriptInfo) && 
        !isEmpty(fetchedExperimentState.experimentData.lcScriptInfo.codeRepository)) {
            setIfMounted(_isMounted.current, setLcScriptType, fetchedExperimentState.experimentData.lcScriptInfo.codeRepository.repositoryType)
            setIfMounted(_isMounted.current, setGitRepository, fetchedExperimentState.experimentData.lcScriptInfo.codeRepository.gitRepository)
            setIfMounted(_isMounted.current, setS3Repository, fetchedExperimentState.experimentData.lcScriptInfo.codeRepository.s3Repository)
            setIfMounted(_isMounted.current, setScriptPath, fetchedExperimentState.experimentData.lcScriptInfo.scriptPath)
        }
    }

    const onSubmit = (event: any) => {
        event.preventDefault()
        dispatch({type: START_UPDATE_EXPERIMENT_LOADER})
        if (isEmpty(params.experimentName) ||
            isEmpty(kernel) || 
            (lcScriptType === RepositoryType.GIT && isEmpty(gitRepository?.packageName)) ||
            (lcScriptType === RepositoryType.S3 && isEmpty(s3Repository?.bucketName)) ||
            (!isEmpty(lcScriptType) && isEmpty(scriptPath))) {
            alert(FIELDS_REQUIRED_MESSAGE)
            return
        }

        if(!isEmpty(lcScriptType) && !isValidCustomScriptPath(scriptPath)){
            dispatch(showSnackBar("Invalid custom script path. It should end with '.sh'"));
            return;
        }
        
        dispatch(updateExperiment({
            namespaceId: fetchedExperimentState.experimentData.namespaceId,
            kernel: kernel,
            runwayId: fetchedExperimentState.experimentData.runwayId,
            notebookInstanceName: params.experimentName,
            instanceType: isEmpty(instanceType) ? null : instanceType,
            teamId: params.teamId,
            lcScriptInfo: isEmpty(lcScriptType) ? null : {
                codeRepository: {
                    repositoryType: lcScriptType,
                    gitRepository: lcScriptType === RepositoryType.GIT ? gitRepository : null,
                    s3Repository: lcScriptType === RepositoryType.S3 ? s3Repository : null
                },
                scriptPath: isEmpty(lcScriptType) ? "" : scriptPath
            }
        }))
    }

    const handleSelectInstanceType = (event: any) => {
        setIfMounted(_isMounted.current, setInstanceType, event.target.value)
    }

    const handleSelectKernel = (event: any) => {
        setIfMounted(_isMounted.current, setKernel, event.target.value)
    }

    const getMessage = () => {
        if(!fetchedExperimentState.experimentFetchSuccess) {
            return EXPERIMENT_FETCH_FAILED_MESSAGE
        }  else {
            return editExperimentState.updateExperimentMessage
        }
    }

    if(!hasExperimentDataLoaded) return <KatSpinner />

    return !isEmpty(fetchedNamespace.namespaceData.namespace) ? (
        <>
        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}/>
        <div className="EditExperiment" style={{marginBottom: "50px"}}>
            <KatAlert variant="info" dismissed={false}>
                Click <a href="https://w.amazon.com/bin/view/Advertising/SupplyQuality/SQI/Experimentation/Expresso-Console/Model-Training/SageMaker-Notebooks/User-Guide#H3.Howtocreateexperimentoversagemakernotebooks3F" target="_blank">here </a> for user guide
            </KatAlert>
            {showMessage ?
                <KatFlashbar variant={isSuccessMessage ? "success" : "danger"}
                             header={getMessage()}
                             link-label={isSuccessMessage ? "Track status" : null}
                             linkHref={isSuccessMessage ? `/notebooks/${params.teamId}/namespaces/${fetchedExperimentState.experimentData.namespaceId}/${fetchedExperimentState.experimentData.runwayId}/experiments` : undefined}>
                </KatFlashbar>
                : editExperimentState.showUpdateExperimentLoading ? <KatSpinner/>: null
            }
            <form className='EditExperiment-form' onSubmit={onSubmit}>
                <FormInput
                    label="Experiment Name *"
                    type='text'
                    placeholder='Experiment name'
                    tooltipText="This is the name of the sagemaker notebook to be created."
                    value={params.experimentName}
                    disabled
                    required
                />
                <br/>
                {/*TODO: Change values in backend instead of replacing EMR with spark.*/}
                <br/>
                <KatLabel tooltipText="This indicates whether the notebook will have spark kernel support or not.
                Experiment with Training indicates support for spark won't be there"> Kernel Use case * :&nbsp;</KatLabel>
                <KatRadiobuttonGroup 
                    options={getKernelOptions()} 
                    name={"Kernel Use case"} 
                    onChange={handleSelectKernel}
                    value={kernel}
                />
                <br/>
                <KatLabel tooltipText="Instance type of the notebook instance created.">
                    Notebook Instance Type :&nbsp;
                </KatLabel>
                <FormDropDown value="ml.t2.medium" options={INSTANCE_TYPES} onChange={handleSelectInstanceType}>
                </FormDropDown>
                <br/>
                <LifecycleConfigScript
                    operationType= {OperationType.UPDATE}
                    lcScriptType={lcScriptType}
                    setLcScriptType={setLcScriptType}
                    gitRepository={gitRepository}
                    setGitRepository={setGitRepository}
                    s3Repository={s3Repository}
                    setS3Repository={setS3Repository}
                    scriptPath={scriptPath}
                    setScriptPath={setScriptPath}
                />
                <br />
                <KatButton type="submit" label="Update" variant="primary" size="base"
                           disabled={disableSubmit}/>
            </form>
        </div>
       </>
    ) : <KatSpinner/>;
}

export default EditExperiment;
