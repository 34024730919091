import {
    SELECT_REGION, SHOW_REGION, LOAD_REGION_SUCCESS, LOAD_SUPPORTED_REGION, REGION_LOADED
} from "src/control/actions/action_types";
import {regionInitialState} from "src/model/commons/commons_models";

export default function (state = regionInitialState, action: any) {
    switch (action.type) {
        case SELECT_REGION:
            return {
                ...state,
                region: action.payload
            }
        case SHOW_REGION:
             return {
                 ...state,
                 showRegions: action.payload
             }
        case LOAD_SUPPORTED_REGION:
             return {
                 ...state,
                 supportedRegions: action.payload
             }
        case REGION_LOADED:
             return {
                 ...state,
                 loaded: action.payload
             }

        default:
            return state;
    }
}
