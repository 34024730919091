import { KatButton, KatCard } from "@amzn/katal-react";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import {
  FormDropDown,
  FormInput,
} from "src/view/style/modelTraining/form_input_styles";
import { closeVersionCheckPopUp, setVersions, showVersionCheckPopUp } from "../constants";
import JobComponent from "../job_components";
import {
  isJobTemplateSpinnerLoadingSelector,
  jobTemplateDataSelector,
  jobTemplateLatestVersionDataSelector,
} from "src/control/selectors/runways/job_template_selectors";
import {
  getJobTemplate,
  getJobTemplateLatestVersionData,
  updateJobTemplate,
} from "src/control/actions/runways/job_template_actions";
import { isEmpty } from "src/utils/common_utils";
import { GreenSpinner } from "src/view/style/header_styles";
import { validatePrerequisites } from "src/view/validation/runways/job_template_validations";
import {initialJobTemplateState} from "src/model/runways/job_template_model";
import { VersionCheckPopUp } from "../utils";
import {OperationType} from "src/constant";

/**
 * renders the view for update job template form
 * @returns JSX
 */
export default function UpdateJobTemplate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  //selectors
  let userName = useSelector(usernameSelector);
  let jobTemplateData = useSelector(jobTemplateDataSelector);
  let isSpinnerLoading = useSelector(isJobTemplateSpinnerLoadingSelector);
  let latestVersionJobTemplateData: any = useSelector(
    jobTemplateLatestVersionDataSelector
  );

  let latestVersion = latestVersionJobTemplateData.latestVersion;

  //state hooks
  const [service, setService] = useState<any>({});
  const [artifactInfo, setArtifactInfo] = useState<any>(initialJobTemplateState.fetchedJobTemplate.artifactInfo);
  const [isValidationSuccess, setIsValidationSuccess] = useState(true);
  const [versionCheckPopUp, setVersionCheckPopUp] = useState(false);

  //useEffect hooks
  useEffect(() => {
    dispatch(getJobTemplateLatestVersionData(params.jobTemplateId));
    dispatch(getJobTemplate(params.jobTemplateId));
  }, []);

  useEffect(() => {
    setService(jobTemplateData.service);
    setArtifactInfo(jobTemplateData.artifactInfo);
  }, [jobTemplateData]);

  /**
   * responsible for calling relevant action on submit click
   * redirects to view job template page
   */
  const submitUpdateJobTemplateForm = async () => {
    if (
      !validatePrerequisites({
        templateName: jobTemplateData.templateName,
        serviceType: jobTemplateData.serviceType,
        service,
        artifactInfo,
      })
    ) {
      setIsValidationSuccess(false);
    } else {
      setIsValidationSuccess(true);
      let latestData: any = await dispatch(getJobTemplateLatestVersionData(
        params.jobTemplateId
      ));
      if (jobTemplateData.version !== latestData.latestVersion) {
        setVersionCheckPopUp(true);
      } else {
        try {
          await dispatch(
            updateJobTemplate(
              jobTemplateData.jobTemplateId,
              userName,
              service,
              artifactInfo
            )
          );
          let currentPath = window.location.pathname.replace("update", "view");
          history.push(currentPath);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  if (isEmpty(jobTemplateData.jobTemplateId)) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
    <>
      {versionCheckPopUp && (
        <VersionCheckPopUp
          popUpVisible={versionCheckPopUp}
          setPopUpVisible={setVersionCheckPopUp}
          showPopUp={() => showVersionCheckPopUp(setVersionCheckPopUp)}
          closePopUp={() => closeVersionCheckPopUp(setVersionCheckPopUp)}
          continueActionMethod={async () => {
            try {
              await dispatch(
                updateJobTemplate(
                  jobTemplateData.jobTemplateId,
                  userName,
                  service,
                  artifactInfo
                )
              );
              let currentPath = window.location.pathname.replace(
                "update",
                "view"
              );
              history.push(currentPath);
            } catch (err) {
              console.log(err);
            }
          }}
          cancelActionMethod={() =>
            closeVersionCheckPopUp(setVersionCheckPopUp)
          }
        ></VersionCheckPopUp>
      )}

      <KatCard>
        <span slot="title"> Update Job Template</span>

        <FormInput
          label="Template Id"
          type="text"
          placeholder="Template Id"
          value={jobTemplateData.jobTemplateId}
          tooltipText="Template Id"
          disabled={true}
        />

        <FormInput
          label="Job Template Name"
          type="text"
          placeholder="Job Template name"
          value={jobTemplateData.templateName}
          disabled={true}
        />

        <FormDropDown
          label="Version"
          searchable={true}
          value={jobTemplateData.version}
          maxHeight="100px"
          options={setVersions(latestVersion)}
          placeholder={
            latestVersion === -1
              ? `${jobTemplateData.version} (Could not load other versions)`
              : "Select versions"
          }
          disabled={latestVersion === -1}
          onChange={(event: any) => {
            dispatch(
              getJobTemplate(jobTemplateData.jobTemplateId, event.target.value)
            );
          }}
        ></FormDropDown>
        {latestVersion === -1 && (
          <div>NOTE: Please refresh to load other versions</div>
        )}
      </KatCard>

      <JobComponent
        operationType={OperationType.UPDATE}
        serviceType={jobTemplateData.serviceType}
        service={service}
        setService={setService}
        artifactInfo={artifactInfo}
        setArtifactInfo={setArtifactInfo}
      ></JobComponent>

      <KatButton
        type="submit"
        label="Submit"
        loading={isSpinnerLoading}
        onClick={submitUpdateJobTemplateForm}
      ></KatButton>
      <br />
      <br />
    </>
  );
}
