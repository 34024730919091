import {KatCard, KatRadiobuttonGroup, KatSpinner} from "@amzn/katal-react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {namespaceSelector} from "../../control/selectors/modelTraining/model_training_selectors";
import {runwayDataSelector, runwayLatestVersionDataSelector,} from "../../control/selectors/runways/runway_selectors";
import Collapsible from "../commons/collapsible";
import {FormDropDown, FormInput,} from "../style/modelTraining/form_input_styles";
import {isEmpty} from "src/utils/common_utils";
import {GreenSpinner} from "../style/header_styles";
import {Pipeline} from "./pipelines";
import {getRunwayLatestVersionData, getV2Runway} from "../../control/actions/runways/runway_actions";
import {CloneButton, ClonePopover, UpdateButton} from "../style/runway_styles";
import {getStageOptions, isCreatingOrUpdatingRunwayStatus, RUNWAY_STATUS_POLL_TIMER, setVersions} from "./constants";
import {getRunwayStatusComponent} from "./utils";
import {RunwayStatus} from "src/model/runways/runway_model";
import {GenericAlert} from "../style/view_config_styles";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

/**
 * renders the view into a view runway form
 * @returns JSX
 */
export default function ViewV2Runway() {
    const dispatch = useDispatch();
    const params: any = useParams();
    const history = useHistory();

    //selectors
    let fetchedNamespace = useSelector(namespaceSelector);
    let userName = useSelector(usernameSelector);
    let runwayData = useSelector(runwayDataSelector);
    let latestVersionRunwayData = useSelector(runwayLatestVersionDataSelector);

    let latestVersion = latestVersionRunwayData.latestVersion;

    //state hooks
    const [timer, setTimer] = useState(RUNWAY_STATUS_POLL_TIMER);
    const [showTimer, setShowTimer] = useState<boolean>(false);

    let runwayStatus = latestVersionRunwayData.runwayStatus;

    //useEffect hooks
    useEffect(() => {
        //to remove unused query params from url
        history.replace({
            search: "",
        });
        dispatch(getV2Runway(params.runwayId))
        dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
    }, [userName]);

    useEffect(() => {
        if (
            isCreatingOrUpdatingRunwayStatus(runwayStatus) && timer > 0
        ) {
            setShowTimer(true);
            const interval = setInterval(() => {
                setTimer((timer) => timer - 1);
                if (timer == 1) {
                    dispatch(getRunwayLatestVersionData(params.runwayId));
                    dispatch(getV2Runway(params.runwayId))
                    setTimeout(() => {
                        setTimer(RUNWAY_STATUS_POLL_TIMER);
                    }, 5000)
                }
            }, 1000);
            return () => {
                clearInterval(interval);
            };
        }
        if (
            runwayStatus === RunwayStatus.DISABLED &&
            showTimer
        ) {
            dispatch(showSnackBar("operation completed successfully!"));
            setShowTimer(false);
        }
    }, [latestVersionRunwayData, timer]);

    const getUpdateRunwayButtonComponent = () => {
        return (
            <UpdateButton
                onClick={() => {
                    let redirecedPath = window.location.pathname.replace(
                        "view",
                        `${latestVersion}/update`
                    );
                    history.push(redirecedPath);
                }}
                label={
                    runwayStatus === RunwayStatus.DISABLED ||
                    runwayStatus === RunwayStatus.ENABLED
                        ? "Update"
                        : "Retry"
                }
                disabled={runwayStatus === RunwayStatus.ENABLED}
            ></UpdateButton>
        );
    };

    const getCloneRunwayButtonComponent = () => {
        return (
            <ClonePopover
                katAriaBehavior="tooltip"
                position="left"
                triggerType="hover"
            >
                <CloneButton
                    label="Clone"
                    variant="primary"
                    disabled={true}
                    slot="trigger"
                ></CloneButton>
                Coming Soon!
            </ClonePopover>
        );
    };

    if (
        isEmpty(fetchedNamespace.namespaceData.namespace) ||
        isEmpty(runwayData.runwayId)
    ) {
        return <GreenSpinner variant={"default"} size={"large"}/>;
    }

    return (
        <>
            {isCreatingOrUpdatingRunwayStatus(
                runwayStatus
            ) && (
                <GenericAlert
                    variant="info"
                    header={"This might take upto 2 minutes"}
                    description={timer == 0 ? `Operation still in progress` : `Auto-refresh happening in ${timer}s`}
                    persistent
                ></GenericAlert>
            )}
            {
                fetchedNamespace.namespaceData.namespace && runwayData.runwayName ?
                    <>
                        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                    runwayName={runwayData.runwayName}></BreadCrumb>
                        <KatCard>
                            <div>
                                Status:&nbsp;&nbsp;&nbsp;&nbsp;
                                {getRunwayStatusComponent(runwayStatus)}
                                {!isCreatingOrUpdatingRunwayStatus(
                                    runwayStatus
                                ) && getUpdateRunwayButtonComponent()}
                                {!isCreatingOrUpdatingRunwayStatus(
                                    runwayStatus
                                ) && getCloneRunwayButtonComponent()}
                            </div>
                            <br/>

                            <FormInput
                                label="Namespace Name"
                                type="text"
                                placeholder="Namespace name"
                                value={fetchedNamespace.namespaceData.namespace}
                                tooltipText="Namespace Name"
                                disabled={true}
                            />

                            <FormInput
                                label="Runway Id"
                                type="text"
                                placeholder="Runway Id"
                                value={runwayData.runwayId}
                                tooltipText="Runway Id"
                                disabled={true}
                            />

                            <FormInput
                                label="Runway Name"
                                type="text"
                                placeholder="Runway name"
                                value={runwayData.runwayName}
                                tooltipText="Runway Name"
                                disabled
                            />

                            <FormDropDown
                                label="Version"
                                searchable={true}
                                value={runwayData.version}
                                maxHeight="100px"
                                options={setVersions(latestVersion)}
                                placeholder={
                                    latestVersion === -1
                                        ? `${runwayData.version} (Could not load other versions)`
                                        : "Select versions"
                                }
                                disabled={latestVersion === -1}
                                onChange={(event: any) =>
                                    dispatch(getV2Runway(runwayData.runwayId, event.target.value))
                                }
                            ></FormDropDown>
                            {latestVersion === -1 && (
                                <div>NOTE: Please refresh to load other versions</div>
                            )}
                        </KatCard>

                        <Collapsible label="Stage">
                            <KatRadiobuttonGroup
                                name={"Stage"}
                                options={getStageOptions()}
                                value={runwayData.domain}
                                disabled
                            ></KatRadiobuttonGroup>
                        </Collapsible>

                        <Collapsible label="Jobs">
                            <KatCard>
                                {runwayStatus !== RunwayStatus.UPDATING && (
                                    <Pipeline
                                        jobData={runwayData.runwayDAG}
                                        domain={runwayData.domain}
                                        isViewJob={true}
                                    ></Pipeline>
                                )}
                                {runwayStatus === RunwayStatus.UPDATING && (
                                    <GreenSpinner/>
                                )}
                            </KatCard>
                        </Collapsible>
                    </> : <KatSpinner/>
            }
        </>
    );
}
