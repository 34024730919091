import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import JSONDiffViewer from "../commons/json_diff_viewer";
import { CrossIcon, DeleteIcon } from "../style/icons";
import { showSnackBar } from "src/control/actions/commons/snack_bar_actions";
import { useDispatch, useSelector } from "react-redux";
import { AddCommentButton, AddReviewerButton, ApproveButton, BoundaryBox, CommentArea, CommentBox, ComparisionArea, DescriptionArea, DiscardButton, InputReview, ContinueToMergeButton, PublishButton, ReviewBox, ReviewerBox, RevokeButton, TextComparision, TitleArea, DescriptionBox, InfoPara, SaveButton, OwnerArea } from "../style/review_styles";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { isEmpty } from "src/utils/common_utils";
import { APIRequestStatus, ApproverInfo, CommentInfo, ReviewType, ReviewStatus } from "../../model/review/review_types";
import { useHistory, useParams } from "react-router";
import { GreenSpinner } from "../style/header_styles";
import { GenericAlert, PopUp } from "../style/view_config_styles";
import { getReview, approveReview, discardReview, publishReview, revokeApproval, saveReview } from "../../control/actions/review/review_actions";
import { basicDescriptionSelector, canMergeReviewSelector, ownerSelector, reviewDataSelector, APIRequestStatusSelector, reviewStatusSelector, changeContentSelector, sourceContentSelector } from "../../control/selectors/review/review_selectors";
import { getPrettyJsonString } from "src/utils/json_utils";
import { formattedUTCTimestamp } from "src/utils/date_utils";
import { v4 } from "uuid";
import review from "src/control/reducers/review";
export default function Review() {
    const dispatch = useDispatch();
    let userName = useSelector(usernameSelector);
    let reviewData = useSelector(reviewDataSelector);
    let APIStatus = useSelector(APIRequestStatusSelector);
    let canMerge = useSelector(canMergeReviewSelector)
    let reviewStatus = useSelector(reviewStatusSelector);
    let owner = useSelector(ownerSelector);
    let basicDescription = useSelector(basicDescriptionSelector);
    let sourceContent = useSelector(sourceContentSelector);
    let changeContent = useSelector(changeContentSelector)
    const initialReviewerList: ApproverInfo[] = [];
    const initialCommentList: CommentInfo[] = [];
    const [reviewerList, setReviewerList] = useState<ApproverInfo[]>(initialReviewerList);
    const [reviewerInputValue, setReviewerValue] = useState("");
    const [commentValue, setCommentValue] = useState("");
    const [description, setDescription] = useState("");
    const [title, setTitle] = useState("");
    const [reviewType, setReviewType] = useState(ReviewType.CODE_REVIEW);
    const [isApprovedForSignedUser, setIsApprovedForSignedUser] = useState(false);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [operationType, setOperationType] = useState("");
    const [showPopUpSpinner, setShowPopUpSpinner] = useState(false)
    const [approverListLength, setApproverListLength] = useState(0);
    const [leftJsonValue, setLeftJsonValue] = useState("");
    const [commentMap, setCommentMap] = useState(new Map());
    //checks if signed in user is the owner or approver and sets permission for signed in user accordingly
    const [isCurrentUserPermitted, setIsCurrentUserPermitted] = useState(false);

    const { reviewId } = useParams<any>();
    const history = useHistory();

    useEffect(() => {
        window.history.replaceState({}, window.document.title, window.location.pathname)
        if (userName) {
            dispatch(getReview(reviewId))
        }
    }, [userName])
    useEffect(() => {
        if (reviewData && APIStatus === APIRequestStatus.SUCCESS) {
            setReviewDetails();
        } else if (APIStatus == APIRequestStatus.ERROR) {
            history.push("/notFound")
        }

    }, [reviewData, APIStatus])
    useEffect(() => {
        if (reviewStatus === ReviewStatus.CREATED) {
            knowApprvoalStatusForSignedUser();
        }
    }, [reviewStatus])

    useEffect(() => {
        if (reviewStatus !== ReviewStatus.CREATED && reviewerList && reviewerList.length) {
            knowApprvoalStatusForSignedUser();
        }
    }, [reviewStatus, reviewerList])

    const getCommentList = (): [] => {
        const comments: any = []
        commentMap.forEach((commentItem)=>{
            if(commentItem.is_editable){
                delete commentItem.is_editable
                comments.push(commentItem)
            }
        })
        return comments;
    }

    const knowApprvoalStatusForSignedUser = () => {
        //  checking if signed in user is permitted to perform any operation on the review or not, if the signed in user is an approver, fetchding his/her approval status 
        if (owner === userName) setIsCurrentUserPermitted(true);
        reviewerList.forEach(approver => {
            if (approver.alias === userName) {
                setIsCurrentUserPermitted(true);
                if (approver.isApproved) {
                    setIsApprovedForSignedUser(true)
                    return;
                }
            }
        })
    }

    const removeReviewers = (alias: string) => {
        // removing reviewer from the UI part if the user click on remove icon, it will be removed from the DDB once the changes are Saved
        const filteredReviewerList = reviewerList.filter((item) => {
            return alias !== item.alias;
        })
        setReviewerList(filteredReviewerList);
    }

    const removeComments = (commentId: string) => {
        // removing  comments from the UI only if the user clicks on the remove icon
        commentMap.delete(commentId)
        setCommentMap(new Map(commentMap))
    }

    const getAliasComponent = () => {
        if (reviewerList.length) {
            return reviewerList.map((aliasItem) => {
                return <div style={{ display: "inline-block", marginTop: "1%", boxShadow: "rgb(0 28 36 / 50%) 0px 1px 4px 0px", marginRight: "10px", padding: "5px" }}>
                    {reviewStatus !== ReviewStatus.MERGED && reviewStatus != ReviewStatus.CANCELLED && isCurrentUserPermitted && !isApprovedForSignedUser && <CrossIcon style={{ cursor: "hand", color: "red" }} size={"15px"} onClick={() => {
                        removeReviewers(aliasItem.alias);
                    }} />}&nbsp;<span>{aliasItem.alias}</span>
                </div>
            })
        } else {
            return <p style={{ opacity: "0.6" }}>Please add reviewers over here</p>
        }
    }

    const getCommentComponent = () => {
        if (commentMap.size) {
            const commentList: any = []
            commentMap.forEach((commentItem, commentId) => {
                let commentDateTime = null;
                try {
                    commentDateTime = formattedUTCTimestamp(new Date(commentItem.commentTime))
                }
                catch {
                    commentDateTime = ""
                }
                let userImage = `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${commentItem.commentBy}`;
                commentList.unshift(<div style={{ width: "100%", marginTop: "2%", backgroundColor: "#fafafa", boxShadow: "0 1px 1px 0 rgb(0 28 36 / 50%)" }}>
                    <img className={"header-user-img"} src={userImage} alt={"user-image"} />&nbsp;&nbsp;
                    <a href={`https://phonetool.amazon.com/users/${commentItem.commentBy}`} target="_blank">
                        <strong style={{ fontSize: "100%" }} >{commentItem.commentBy}
                        </strong>
                    </a>
                    <strong> | {commentDateTime}</strong>
                    {commentItem.is_editable && <DeleteIcon style={{ cursor: "hand", float: "right" }} size={"25px"} onClick={() => {
                        removeComments(commentItem.commentId);
                    }} />}
                    <div style={{ wordBreak: "break-all", padding: "5px", height: "auto", backgroundColor: "white" }} >
                        &nbsp;
                        <span>{commentItem.comment}</span>
                    </div>
                </div>)
            })
            return commentList
        }
    }

    const addReviewers = () => {
        if (reviewerInputValue.length) {
            let id = v4();
            const alias: ApproverInfo = {
                approverId: id,
                alias: reviewerInputValue,
                isApproved: false,
                approvalTime: null

            }
            let alreadyPresent = false;
            reviewerList.forEach(approver => {
                if (approver.alias === reviewerInputValue) alreadyPresent = true;
            })
            if (alreadyPresent) {
                // checking if approver name is already present
                dispatch(showSnackBar("appover name already present"))
            }
            else {
                if (owner === reviewerInputValue) {
                    // checking if owner is not provided as an approver too
                    dispatch(showSnackBar("owner can't be approver"))
                }
                else {
                    setReviewerList([...reviewerList, alias]);
                }
                setReviewerValue("");
            }
        }
        // restricting all other cases like 0 length approver name etc
        else dispatch(showSnackBar("Please provide a valid approver"))
    }

    const addComments = () => {
        let id = v4();
        if (commentValue.length) {
            const comment: CommentInfo = {
                commentId: id,
                commentBy: userName,
                commentTime: new Date(),
                comment: commentValue,
                is_editable: true
            }
            commentMap.set(id,comment)
            setCommentValue("");
        }
        // restricting all other cases like 0 length comment etc
        else dispatch(showSnackBar("Please provide valid comments"))
    }

    const handleDescriptionChange = (event: any) => {
        setDescription(event.target.value);
    }

    const handleTitleChange = (event: any) => {
        setTitle(event.target.value);
    }

    const ApprovedUserList = () => {
        let approversNames: string[] = [];
        reviewerList.forEach(approver => {
            if (approver.isApproved) {
                approversNames.push(approver.alias);
            }
        })
        if (approversNames.length)
            return (
                <InfoPara style={{ width: "60%" }}>This review has been approved by {approversNames.join(", ")}</InfoPara>
            )
        else return (
            <InfoPara style={{ width: "60%" }}>No one has yet approved this review</InfoPara>
        )
    }

    const showPopUp = () => {
        setPopUpVisible(true);
    }

    const closePopUp = () => {
        setPopUpVisible(false);
    }

    const handleSave = () => {
        if (reviewerList.length < 2) dispatch(showSnackBar("There should be atleast two reviewers "))
        else {
            setOperationType("SAVE");
            showPopUp();
        }
    }

    const handleRevoke = () => {
        setOperationType("REVOKE");
        showPopUp();
    }

    const handleApprove = () => {
        setOperationType("APPROVE");
        showPopUp();
    }

    const handleContinueToMerge = () => {
        if (reviewType === ReviewType.CONFIG_REVIEW) {
            let sourceInfo = JSON.parse(reviewData.reviewInfo.sourceInfo)
            const sourceURL = sourceInfo.url;
            const splitSourceURL = sourceURL.split("/")
            let mergeURL = "/"
            for (let index = 3; index < splitSourceURL.length - 1; index++) {
                mergeURL = mergeURL + splitSourceURL[index] + '/'
            }
            mergeURL = mergeURL + `merge?reviewId=${reviewId}`
            window.open(mergeURL, '_blank')
        }
        else if (reviewType === ReviewType.CODE_REVIEW) {
            let sourceURL = window.location.host
            let mergeURL = 'https://' + sourceURL + `/repository/merge?reviewId=${reviewId}`
            window.open(mergeURL, '_blank')
        }
    }

    const handleDiscard = () => {
        setOperationType("DISCARD");
        showPopUp();
    }

    const handlePublish = () => {
        if (reviewerList.length < 2) dispatch(showSnackBar("Please select atleast two reviewers "))
        else {
            if (isEmpty(title)) dispatch(showSnackBar("Please provide title "))
            else {
                setOperationType("PUBLISH");
                showPopUp();
            }
        }
    }

    const handleContinue = async (operationType: string) => {
        operationType = operationType.toUpperCase()
        setShowPopUpSpinner(true)
        switch (operationType) {
            case 'PUBLISH': {
                try {
                    await dispatch(publishReview(reviewId, userName,
                        reviewerList,
                        getCommentList(),
                        {
                            ...reviewData.descriptionInfo,
                            title: title,
                            additionalDescription: description
                        }))
                } catch (err) {
                    console.log(err)
                }
                break;
            }
            case 'DISCARD': {
                try {
                    await dispatch(discardReview(reviewId, userName))
                } catch (err) {
                    console.log(err)
                }
                break;
            }
            case 'SAVE': {
                try {
                    await dispatch(saveReview(reviewId, userName, reviewerList, getCommentList()))
                }
                catch (err) {
                    console.log(err)
                }
                break;
            }
            case 'REVOKE': {
                try {
                    await dispatch(revokeApproval(reviewId, userName))
                    setIsApprovedForSignedUser(false);
                }
                catch (err) {
                    console.log(err)
                }
                break;
            }
            case 'APPROVE': {
                try {
                    await dispatch(approveReview(reviewId, userName))
                    setIsApprovedForSignedUser(true);
                }
                catch (err) {
                    console.log(err)
                }
                break;
            }
            default: break;
        }
        setShowPopUpSpinner(false)
        closePopUp();
    }

    const getFormattedReviewContent = (content : string) => {
        let formattedContent = (reviewType === ReviewType.CODE_REVIEW ? (content) : getPrettyJsonString(content))
        return formattedContent ?? ""
    }

    const setReviewDetails = () => {
        const data = reviewData;
        if (reviewStatus === ReviewStatus.CREATED && data.owner !== userName) {
            history.push("/notFound")
        } else {
            data.commentInfo.forEach((comment: any) => {
                commentMap.set(comment.commentId, comment)
            })
            setReviewerList(data.approverInfo)
            setTitle(data.descriptionInfo.title)
            setDescription(data.descriptionInfo.additionalDescription)
            setReviewType(data.type)
            setApproverListLength(data.approverInfo.length)
            let leftValue = getFormattedReviewContent(sourceContent)
            if (leftValue) setLeftJsonValue(leftValue)
        }
    }
    const getBasicDescription = () => {
        if (basicDescription) {
            let basicDescriptionJSON = JSON.parse(basicDescription)
            try {
                if (reviewType === ReviewType.CONFIG_REVIEW)
                    return <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                        <p>Config Name: {basicDescriptionJSON.config_name}</p>
                        <p>Config Domain: {basicDescriptionJSON.domain}</p>
                        <p>Config Realm: {!isEmpty(basicDescriptionJSON.applicationRealm) ? basicDescriptionJSON.applicationRealm : basicDescriptionJSON.realm}</p>
                        <p>Config Service Name: {basicDescriptionJSON.service_name}</p>
                        <p>Config Version: {basicDescriptionJSON.version}</p>
                    </div>
                else return <div style={{ display: "flex", justifyContent: "space-between", width: "80%" }}>
                    <p>Repository Name: {basicDescriptionJSON.expresso_repo_name}</p>
                    <p>File: {basicDescriptionJSON.file}</p>
                    <p>Repository Type: {basicDescriptionJSON.repo_name}</p>
                    <p>Version: {basicDescriptionJSON.version}</p>
                </div>
            } catch (err) {
                return null
            }
        }
        else return null
    }

    if (popUpVisible) {
        return (
            <PopUp visible={popUpVisible} onOpen={showPopUp} onClose={closePopUp}>
                {showPopUpSpinner ? <span slot={"title"} >Processing</span> : <span slot={"title"} >Do you wish to continue?</span>}
                {showPopUpSpinner && <GreenSpinner variant={"default"} size={"large"} />}
                {!showPopUpSpinner && <Button style={{ float: "right", background: "#002e36", color: "white", marginTop: "10px" }} onClick={closePopUp}>CANCEL</Button>}
                {!showPopUpSpinner && <Button style={{ float: "right", marginRight: "10px", background: "#002e36", color: "white", marginTop: "10px" }} onClick={() => {
                    handleContinue(operationType);
                }}> Continue </Button>}
            </PopUp>
        )
    }

    if (APIStatus === APIRequestStatus.LOADING || !userName) {
        return (
            <GreenSpinner variant={"default"} size={"large"} />
        );
    }

    return (
        <>
            {(reviewStatus === ReviewStatus.CREATED || reviewStatus === ReviewStatus.PUBLISHED) && !canMerge && <GenericAlert
                variant={"danger"}
                header={"NOTE"}
                persistent
            >
                The source has changed since the review was raised. The owner needs to discard this review and raise a new review with the updated source
            </GenericAlert>}
            <div style={{ display: "flex", marginLeft: "6%", width: "88%", marginTop: "2%" }}>
                {reviewStatus === ReviewStatus.PUBLISHED && ApprovedUserList()}
                {owner === userName && reviewStatus === ReviewStatus.PUBLISHED && (
                    <>
                        <ContinueToMergeButton disabled={!canMerge} onClick={handleContinueToMerge} >CONTINUE</ContinueToMergeButton>
                        <DiscardButton onClick={handleDiscard}>DISCARD</DiscardButton>
                    </>
                )}
                {owner !== userName && reviewStatus === ReviewStatus.PUBLISHED && isCurrentUserPermitted && (
                    <>
                        <ApproveButton disabled={!canMerge || isApprovedForSignedUser} onClick={handleApprove} >APPROVE</ApproveButton>
                        <RevokeButton disabled={!canMerge || !isApprovedForSignedUser} onClick={handleRevoke} >REVOKE</RevokeButton>
                    </>
                )}
            </div>
            {reviewStatus === ReviewStatus.MERGED && <InfoPara style={{ marginLeft: "6%", width: "60%" }}>The changes in this review were merged by the owner: {owner}</InfoPara>}
            {reviewStatus === ReviewStatus.CANCELLED && <InfoPara style={{ marginLeft: "6%", width: "60%" }}>This review was cancelled by the owner: {owner}</InfoPara>}

            <BoundaryBox >
                <DescriptionBox>
                    <div>
                        {getBasicDescription()}
                    </div>
                    <div><strong style={{ fontSize: "150%" }}>Owner: </strong>
                        <OwnerArea readOnly={true} value={owner}></OwnerArea>
                        {reviewStatus == ReviewStatus.CREATED && <PublishButton onClick={handlePublish}>PUBLISH</PublishButton>}
                        {reviewStatus == ReviewStatus.PUBLISHED && isCurrentUserPermitted && <SaveButton disabled={!canMerge || isApprovedForSignedUser} onClick={handleSave}>SAVE</SaveButton>}
                    </div>
                    <div><strong style={{ fontSize: "150%" }}>Title: </strong>
                        <TitleArea placeholder="Please add title" readOnly={!(reviewStatus == ReviewStatus.CREATED)} onChange={handleTitleChange} value={title}></TitleArea>
                    </div>
                    <DescriptionArea placeholder="Add description for this review" readOnly={!(reviewStatus == ReviewStatus.CREATED)} onChange={handleDescriptionChange} value={description} />

                </DescriptionBox>
                <hr />
                <ReviewBox >
                    <ReviewerBox className="col-sm">
                        <div><strong style={{ fontSize: "150%" }}>Reviewers: </strong></div>
                        {getAliasComponent()}
                        <div style={{ marginTop: "1%" }}><b>Please be careful in providing alias. Do not provide @amazon.com</b></div>
                    </ReviewerBox>
                    <div>
                        {reviewStatus !== ReviewStatus.MERGED && reviewStatus !== ReviewStatus.CANCELLED && isCurrentUserPermitted && (
                            <>
                                <InputReview
                                    required
                                    value={reviewerInputValue}
                                    placeholder={"Add user/team alias"}
                                    onChange={(event: any) => {
                                        setReviewerValue(event.target.value);
                                    }}
                                />
                                <AddReviewerButton disabled={!canMerge || isApprovedForSignedUser} onClick={() => {
                                    addReviewers();
                                }}>ADD REVIEWER </AddReviewerButton>
                            </>
                        )}

                    </div>
                </ReviewBox>
                <hr />
                <TextComparision className="row justify-content-around">
                    <div className="col-6">
                        <h5 style={{ paddingLeft: "3vw" }}>Latest Version</h5>
                    </div>
                    <div className="col-6">
                        <h5 style={{ paddingLeft: "5vw" }}>Changed Version</h5>
                    </div>
                </TextComparision>
                <ComparisionArea className={"row"}>
                    <div className={"col-12"}>
                        <div style={{ height: "50vh" }}>
                            <JSONDiffViewer
                                left_content={getFormattedReviewContent(sourceContent)}
                                right_content={getFormattedReviewContent(changeContent)}
                            />
                        </div>
                    </div>
                </ComparisionArea>
                <hr />
                <CommentBox className={"row"}><div style={{ width: "100%" }}>
                    {reviewStatus !== ReviewStatus.MERGED && reviewStatus !== ReviewStatus.CANCELLED && isCurrentUserPermitted && <AddCommentButton disabled={!canMerge || isApprovedForSignedUser} onClick={addComments}>ADD COMMENT</AddCommentButton>}</div>
                    <div style={{ maxWidth: "100%", flex: "0 0 100%", padding: "0px" }} className="col-sm-9">
                        {reviewStatus !== ReviewStatus.MERGED && reviewStatus !== ReviewStatus.CANCELLED && isCurrentUserPermitted && <CommentArea value={commentValue} onChange={(event: any) => {
                            setCommentValue(event.target.value);
                        }} placeholder="Please provide comments" ></CommentArea>}
                        <strong style={{ fontSize: "150%" }}>Comments:</strong>
                        {commentMap.size > 0 && getCommentComponent()}
                    </div>
                </CommentBox>
            </BoundaryBox>
        </>
    )
}