import {constructMap, readJSON} from "src/utils/json_utils";
import * as namespaces from "src/view/configPanel/data/namespaces.json";
import * as teamIdNamespaceMap from "src/view/configPanel/data/teamIdNamespaceMap.json";
import * as configurations from "src/view/configPanel/data/configurations.json";
import * as namespaceConfigMap from "src/view/configPanel/data/namespaceConfigMap.json";
import * as overridesMap from "src/view/configPanel/data/overridesMap.json";

export function getNamespaces() {
    const allNamespaces: any = readJSON(namespaces);
    const teamNamespaces: any = readJSON(teamIdNamespaceMap);

    const namespaceBasedAllNamespacesMap: any = constructMap(allNamespaces, "namespaceId");
    const teamNamespaceJoin = teamNamespaces.map((object: any) => {
        let teamId = object.teamId
        return {
            teamId,
            ...namespaceBasedAllNamespacesMap.get(object.namespaceId)
        }
    });

    return teamNamespaceJoin;
}

export function getConfigs() {
    const allConfigurations: any = readJSON(configurations);
    const configNamespaces: any = readJSON(namespaceConfigMap);
    const teamNamespaces: any = readJSON(teamIdNamespaceMap);

    const configIdBasedAllConfigMap: any = constructMap(allConfigurations, "configId");
    const namespaceBasedTeamMap: any = constructMap(teamNamespaces, "namespaceId");

    let teamNamespaceConfigJoin = configNamespaces.map((object: any) => {
        let namespaceId = object.namespaceId;
        let configId = object.configId;
        return {
            configId,
            ...namespaceBasedTeamMap.get(namespaceId)
        }
    });


    teamNamespaceConfigJoin = teamNamespaceConfigJoin.map((object: any) => {
        let configDetails = configIdBasedAllConfigMap.get(object.configId);
        let namespaceId = object.namespaceId
        let teamId = object.teamId
        return {
            ...configDetails,
            namespaceId,
            teamId
        }
    });
    return teamNamespaceConfigJoin;
}

export function getOverridesMap() {
    return readJSON(overridesMap);
}

export function changeProperty(object: any, propertyName: string, fromValue: string, toValue: string) {
    let property = object[propertyName]
    if (property === fromValue) {
        property = toValue
    }
    return {
        ...object,
        [propertyName]: property
    }
}