import { isEmpty } from "../../../utils/common_utils";
import { ServiceType, RepositoryType } from "../../../model/runways/job_model";
import { modelTrainingInstances } from "src/view/runways/constants";
import { isValidJSON } from "src/utils/json_utils";
export const isValidServiceType = (serviceType: string) => {
  return Object.values(ServiceType).includes(serviceType as ServiceType);
};

export const isValidEmrClusterName = (emrClusterName: any) => {
  return !isEmpty(emrClusterName);
};

export const isValidEmrJobFlowStep = (emrJobFlowStep: any) => {
  return isValidJSON(emrJobFlowStep)
}

export const isValidExecutionCode = (executionCode: string) => {
  return !isEmpty(executionCode);
};

export const isValidCodeRepositoryType = (repositoryType: string) => {
  return Object.values(RepositoryType).includes(
    repositoryType as RepositoryType
  );
};

export const isValidSagemakerInstanceType = (sagemakerInstanceType: string) => {
  return modelTrainingInstances.includes(sagemakerInstanceType);
};

export const isValidSagemakerInstanceCount = (
  sagemakerInstanceCount: string
) => {
  return !isEmpty(sagemakerInstanceCount) && Number(sagemakerInstanceCount) > 0;
};

export const isValidSagemakerVolumeSize = (sagemakerVolumeSize: string) => {
  return !isEmpty(sagemakerVolumeSize) && Number(sagemakerVolumeSize) > 0;
};

export const isValidContainerName = (
  containerName: string,
  serviceType: ServiceType
) => {
  if (serviceType !== ServiceType.EMR) {
    return !isEmpty(containerName);
  }
  return true;
};

export const isValidContainerArn = (
  containerArn: string,
  serviceType: ServiceType
) => {
  if (serviceType !== ServiceType.EMR) {
    return !isEmpty(containerArn);
  }
  return true;
};

export const isValidPreRequisite = (payload: any) => {
  return (
    ((payload.serviceType === ServiceType.EMR &&
      isValidEmrClusterName(
        payload.service.emrService.computeInfo?.clusterName
      ) &&
      isValidEmrJobFlowStep(payload.service.emrService.serviceInfo.jobFlowStep)) ||
      (payload.serviceType === ServiceType.SAGEMAKER &&
        isValidSagemakerInstanceType(
          payload.service.sagemakerService.computeInfo.instanceType
        ) &&
        isValidSagemakerInstanceCount(
          payload.service.sagemakerService.computeInfo.instanceCount
        ) &&
        isValidSagemakerVolumeSize(
          payload.service.sagemakerService.computeInfo.volumeSizeInGB
        )) ||
      payload.serviceType === ServiceType.ECS) &&
    isValidCodeRepositoryType(
      payload.artifactInfo.codeInfo?.codeRepository.repositoryType
    ) &&
    isValidExecutionCode(payload.artifactInfo.codeInfo.executionInfo.codeUri) &&
    isValidContainerName(
      payload.artifactInfo.containerInfo?.imageContainer?.containerName,
      payload.serviceType
    )
  );
};
