import {
    REGISTER_CODE_REPOSITORY_FAILURE,
    REGISTER_CODE_REPOSITORY_SUCCESS,
    FETCH_ALL_CODE_REPOSITORIES_FAILURE,
    FETCH_ALL_CODE_REPOSITORIES_SUCCESS,
    GET_CODE_VERSIONS_FAILURE,
    EXECUTION_GET_CODE_VERSIONS_SUCCESS,
    START_REGISTER_CODE_REPOSITORY_LOADER,
    EXECUTION_START_GET_CODE_VERSIONS_LOADER,
    PRE_EXECUTION_START_GET_CODE_VERSIONS_LOADER,
    POST_EXECUTION_START_GET_CODE_VERSIONS_LOADER,
    PRE_EXECUTION_GET_CODE_VERSIONS_SUCCESS,
    POST_EXECUTION_GET_CODE_VERSIONS_SUCCESS,
    START_GET_CODE_REPOSITORY_LOADER,
    GET_CODE_REPOSITORY_FAILURE,
    GET_CODE_REPOSITORY_SUCCESS,
} from "src/control/actions/action_types";
import {isEmpty} from "src/utils/common_utils";
import {Failure, Success} from "src/utils/action_utils";
import {
    CreateCodeRepositoryRequest,
    GetCodeRepositoryRequest,
    ListCodeRepositoriesRequest,
    ListCodeVersionsRequest
} from "src/model/codeRepository/code_repository_models";
import {JobStep} from "src/constant";
import {
    codeRepositoriesEndpoint,
    getCodeRepositoryEndpoint,
    getCodeRepositoryVersionsEndpoint
} from "src/control/targets";
import {apiGetPromise, apiPostPromise} from "src/utils/api_handler";


// Preventing store update in case of create/edit experiment
// to store Git/S3 repos locally at the component level
// for custom LC scripts.
// TODO: Remove this hack with better ways to manage local states
// vs global states (store)
export const loadCodeRepositories = (listCodeRepositoriesRequest: ListCodeRepositoriesRequest, shouldPreventStoreUpdate: boolean=false) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = FETCH_ALL_CODE_REPOSITORIES_FAILURE;
        try {
            let data: any = await apiGetPromise(codeRepositoriesEndpoint(), listCodeRepositoriesRequest);
            if (!isEmpty(data)) {
                if(!shouldPreventStoreUpdate){
                dispatch(Success(FETCH_ALL_CODE_REPOSITORIES_SUCCESS, {
                    codeRepositories: data.codeRepositories,
                    nextToken: data.nextToken
                }));}
                resolve(data.codeRepositories);
                return;
            }
            dispatch(Failure(FETCH_ALL_CODE_REPOSITORIES_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_ALL_CODE_REPOSITORIES_FAILURE, err));
            reject(err);
        }
    })
}

export const registerCodeRepository = (createCodeRepositoryRequest: CreateCodeRepositoryRequest) => async (dispatch: any) => {
    dispatch({type: START_REGISTER_CODE_REPOSITORY_LOADER})
    return new Promise(async (resolve, reject) => {
        const error = REGISTER_CODE_REPOSITORY_FAILURE;
        try {
            let data: any = await apiPostPromise(codeRepositoriesEndpoint(), createCodeRepositoryRequest);
            if (!isEmpty(data)) {
                dispatch(Success(REGISTER_CODE_REPOSITORY_SUCCESS, {
                    requestStatus: data.requestStatus,
                    codeRepository: data.codeRepository
                }));
                resolve();
                return;
            }
            dispatch(Failure(REGISTER_CODE_REPOSITORY_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(REGISTER_CODE_REPOSITORY_FAILURE, err));
            reject(err);
        }
    })
}

export const getCodeRepository = (getCodeRepositoryRequest: GetCodeRepositoryRequest, repositoryName: string) => async (dispatch: any) => {
    dispatch({type: START_GET_CODE_REPOSITORY_LOADER})
    return new Promise(async (resolve, reject) => {
        const error = GET_CODE_REPOSITORY_FAILURE;
        try {
            let data: any = await apiGetPromise(getCodeRepositoryEndpoint(repositoryName), getCodeRepositoryRequest);
            if (!isEmpty(data)) {
                dispatch(Success(GET_CODE_REPOSITORY_SUCCESS, {
                    repository: data.repository
                }));
                resolve();
                return;
            }
            dispatch(Failure(GET_CODE_REPOSITORY_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(GET_CODE_REPOSITORY_FAILURE, error));
            reject(error);
        }
    })
}

export const getCodeVersions = (listCodeVersionsRequest: ListCodeVersionsRequest, repositoryName: string, step: JobStep) => async (dispatch: any) => {
    switch (step){
        case JobStep.EXECUTION:
            dispatch({type: EXECUTION_START_GET_CODE_VERSIONS_LOADER})
            break;
        case JobStep.PRE_EXECUTION:
            dispatch({type: PRE_EXECUTION_START_GET_CODE_VERSIONS_LOADER})
            break;
        case JobStep.POST_EXECUTION:
            dispatch({type: POST_EXECUTION_START_GET_CODE_VERSIONS_LOADER})
            break;
    }
    return new Promise(async (resolve, reject) => {
        const error = GET_CODE_VERSIONS_FAILURE;
        try {
            let data: any = await apiGetPromise(getCodeRepositoryVersionsEndpoint(repositoryName), listCodeVersionsRequest);
            switch (step){
                case JobStep.EXECUTION:
                    dispatch(Success(EXECUTION_GET_CODE_VERSIONS_SUCCESS, data.codeVersionsList))
                    break;
                case JobStep.PRE_EXECUTION:
                    dispatch(Success(PRE_EXECUTION_GET_CODE_VERSIONS_SUCCESS, data.codeVersionsList))
                    break;
                case JobStep.POST_EXECUTION:
                    dispatch(Success(POST_EXECUTION_GET_CODE_VERSIONS_SUCCESS, data.codeVersionsList))
                    break;
            }
            resolve();
            return;
        } catch (err) {
            dispatch(Failure(GET_CODE_VERSIONS_FAILURE, err));
            reject(err);
        }
    })
}
