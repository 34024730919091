import { KatButton, KatPopover } from "@amzn/katal-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router";
import { DELETE_JOB_NODE } from "src/control/actions/action_types";
import { JobPopUp } from "../style/common_styles";
import {
  DeleteIcon,
  EditIconTable,
  NextPointerIcon,
  ViewIconTable,
  ExecutionsIcon,
} from "../style/icons";
import CreateJob from "./job/create_job";
import UpdateJob from "./job/update_job";
import ViewJob from "./job/view_job";
import { OperationIconBox } from "../style/runway_styles";
import { isEmpty } from "src/utils/common_utils";
import { Button } from "@material-ui/core";
import { RunwayStatus } from "src/model/runways/runway_model";
import { OperationType } from "src/constant";

/**
 * renders given job data array into a pipeline having actions depending upon operation type
 * @param props: jobData, isViewJob
 * @returns JSX
 */
export const Pipeline = (props: {
  jobData: any[];
  runwayStatus?: RunwayStatus;
  isViewJob?: boolean;
  isViewExecutions?: boolean;
  domain?: string;
}) => {
  const dispatch = useDispatch();
  const params = useParams<any>();
  const history = useHistory();

  //state hooks
  const [jobPopUpVisible, setJobPopUpVisible] = useState(false);
  const [jobId, setJobId] = useState("");
  const [operationType, setOperationType] = useState<OperationType>(
    OperationType.VIEW
  );
  const [isFirstJob, setIsFirstJob] = useState(false);
  const [liveVersion, setLiveVersion] = useState<null | number>(null);
  /**
   * sets job pop up visible state to true to open job pop up
   */
  const showJobPopUp = () => {
    setJobPopUpVisible(true);
  };

  /**
   * sets job pop up visible state to false to close job pop up
   */
  const closeJobPopUp = () => {
    setJobPopUpVisible(false);
  };

  /**
   * renders job data into a pipeline with suitable actions
   * @returns JSX
   */
  const getJobPipeline = () => {
    let currIndex = 0;
    let jobDataLength = props.jobData?.length;
    let newJobData = props.jobData?.map((jobItem) => {
      currIndex = currIndex + 1;
      return (
        <div
          className="jobPointerWrapper"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div
            className="jobItem"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
            key={jobItem.jobId}
          >
            <div
              className="jobItemContent"
              style={{
                border: "2px solid #000",
                minWidth: "150px",
                padding: "10px",
                minHeight: "50px",
              }}
            >
              <span>Name: {jobItem.jobName}</span>
              <br />
              <span>Service: {jobItem.serviceType}</span>
              <br />
              <span>
                Live Version:&nbsp;
                {jobItem.prevLiveVersion === jobItem.version ? (
                  jobItem.prevLiveVersion
                ) : (
                  <b style={{ color: "red" }}>{jobItem.version}</b>
                )}
              </span>
            </div>
            <div
              className="actionIconContainer"
              style={{
                display: "flex",
                minWidth: "150px",
              }}
            >
              <OperationIconBox>
                <div title="View">
                  <ViewIconTable
                    style={{
                      margin: "10px",
                      width: "20px",
                      color: "#002e36",
                    }}
                    onClick={() => {
                      setJobId(jobItem.jobId);
                      setLiveVersion(jobItem.version);
                      setIsFirstJob(currIndex === 1);
                      setOperationType(OperationType.VIEW);
                      showJobPopUp();
                    }}
                  />
                </div>
              </OperationIconBox>
              {(props.isViewJob || props.isViewExecutions) && (
                <OperationIconBox>
                  <div title="View Executions">
                    <ExecutionsIcon
                      style={{
                        margin: "10px",
                        width: "20px",
                        color: "#002e36",
                      }}
                      onClick={() => {
                        window.open(
                          `${window.location.origin}/runways/${params.teamId}/namespaces/${params.namespaceId}/${params.runwayId}/jobs/${jobItem.jobId}/executions?domain=${props.domain}`,
                          "_self"
                        );
                      }}
                    />
                  </div>
                </OperationIconBox>
              )}
              {!(
                props.isViewJob ||
                props.isViewExecutions ||
                props.runwayStatus === RunwayStatus.ENABLED
              ) && (
                <OperationIconBox>
                  <div title="Edit">
                    <EditIconTable
                      style={{
                        margin: "10px",
                        width: "20px",
                        color: "#002e36",
                      }}
                      onClick={() => {
                        setJobId(jobItem.jobId);
                        setLiveVersion(jobItem.version);
                        setIsFirstJob(props.jobData[0].jobId === jobItem.jobId);
                        setOperationType(OperationType.UPDATE);
                        showJobPopUp();
                      }}
                    />
                  </div>
                </OperationIconBox>
              )}
              {!(props.isViewJob || props.isViewExecutions) && (
                <OperationIconBox>
                  <div title="Delete">
                    <DeleteIcon
                      style={{
                        margin: "10px",
                        width: "20px",
                        color: "#002e36",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOperationType(OperationType.DELETE);
                        setLiveVersion(null);
                        setJobId(jobItem.jobId);
                        showJobPopUp();
                      }}
                    />
                  </div>
                </OperationIconBox>
              )}
            </div>
          </div>
          {!(props.isViewJob || props.isViewExecutions) && (
            <div>
              <NextPointerIcon />
            </div>
          )}
          {(props.isViewJob || props.isViewExecutions) &&
            currIndex !== jobDataLength && (
              <div>
                <NextPointerIcon />
              </div>
            )}
        </div>
      );
    });
    return newJobData;
  };

  /**
   * Get Job Component based upon operation type
   * @returns Job component
   */
  const getJobComponent = () => {
    switch (operationType) {
      case OperationType.CREATE: {
        return (
          <CreateJob
            isRunwayJob={true}
            domain={props.domain}
            closeJobPopUp={closeJobPopUp}
            isFirstJob={isFirstJob}
          />
        );
      }
      case OperationType.VIEW: {
        return (
          <ViewJob isRunwayJob={true} jobId={jobId} liveVersion={liveVersion} />
        );
      }
      case OperationType.UPDATE: {
        return (
          <UpdateJob
            isRunwayJob={true}
            jobId={jobId}
            liveVersion={liveVersion}
            closeJobPopUp={closeJobPopUp}
            isFirstJob={isFirstJob}
          />
        );
      }
      case OperationType.DELETE: {
        return (
          <>
            <div style={{ fontSize: "15px" }}>
              Are you sure?
              <br />
              <b>Note:</b> You may need to change the dependency config for
              adjacent jobs.
            </div>
            <Button
              style={{
                float: "right",
                background: "#002e36",
                color: "white",
                marginTop: "20px",
              }}
              onClick={closeJobPopUp}
            >
              CANCEL
            </Button>
            <Button
              style={{
                float: "right",
                marginRight: "10px",
                background: "#002e36",
                color: "white",
                marginTop: "20px",
              }}
              onClick={() => {
                dispatch({
                  type: DELETE_JOB_NODE,
                  payload: jobId,
                });
                closeJobPopUp();
              }}
            >
              Continue
            </Button>
          </>
        );
      }
    }
  };

  return (
    <div
      className="jobWrapper"
      style={{
        display: "flex",
        alignItems: "center",
        overflowX: "scroll",
        padding: "20px",
      }}
    >
      {getJobPipeline()}
      {!(props.isViewJob || props.isViewExecutions) && (
        <KatPopover
          katAriaBehavior="tooltip"
          position="right"
          triggerType="hover"
        >
          <KatButton
            onClick={() => {
              setOperationType(OperationType.CREATE);
              setIsFirstJob(props.jobData.length < 1);
              showJobPopUp();
            }}
            disabled={isEmpty(props.domain)}
            slot="trigger"
          >
            Add job
          </KatButton>
          Please ensure stage is selected
        </KatPopover>
      )}
      <div className="popUpWrapper">
        <JobPopUp
          visible={jobPopUpVisible}
          onOpen={showJobPopUp}
          onClose={closeJobPopUp}
        >
          {jobPopUpVisible && getJobComponent()}
        </JobPopUp>
      </div>
    </div>
  );
};
