import {NavItem, onClickParams} from "src/view/commons/navigationPanel/nav_item_commons";
import {CodeRepositoryIcon, colorWhite} from "src/view/style/icons";
import React from "react";

const _navigateToCodeRepository = (props: onClickParams) => {
    let history = props.history;
    history.push(`/codeRepositories`);
}

const CodeRepositoriesNavItem: NavItem = {
    displayName: "Code Repositories",
    icon: <CodeRepositoryIcon style={colorWhite}/>,
    labelOnClick: _navigateToCodeRepository,
    hasSubNav: false
}

export default CodeRepositoriesNavItem