
export const INSTANCE_TYPES = [
    {
        "name" : "ml.t2.medium",
        "value" : "ml.t2.medium"
    },

    {
        "name" : "ml.t2.large",
        "value" : "ml.t2.large"
    },

    {
        "name" : "ml.t2.xlarge",
        "value" : "ml.t2.xlarge"
    },

    {
        "name" : "ml.t2.2xlarge",
        "value" : "ml.t2.2xlarge"
    },

    {
        "name" : "ml.t3.medium",
        "value" : "ml.t3.medium"
    },

    {
        "name" : "ml.t3.large",
        "value" : "ml.t3.large"
    },

    {
        "name" : "ml.t3.xlarge",
        "value" : "ml.t3.xlarge"
    },

    {
        "name" : "ml.t3.2xlarge",
        "value" : "ml.t3.2xlarge"
    },

    {
        "name" : "ml.m4.xlarge",
        "value" : "ml.m4.xlarge"
    },

    {
        "name" : "ml.m4.2xlarge",
        "value" : "ml.m4.2xlarge"
    },

    {
        "name" : "ml.m4.4xlarge",
        "value" : "ml.m4.4xlarge"
    },

    {
        "name" : "ml.m4.10xlarge",
        "value" : "ml.m4.10xlarge"
    },

    {
        "name" : "ml.m4.16xlarge",
        "value" : "ml.m4.16xlarge"
    },

    {
        "name" : "ml.m5.xlarge",
        "value" : "ml.m5.xlarge"
    },

    {
        "name" : "ml.m5.2xlarge",
        "value" : "ml.m5.2xlarge"
    },

    {
        "name" : "ml.m5.4xlarge",
        "value" : "ml.m5.4xlarge"
    },

    {
        "name" : "ml.m5.12xlarge",
        "value" : "ml.m5.12xlarge"
    },

    {
        "name" : "ml.m5.24xlarge",
        "value" : "ml.m5.24xlarge"
    },

    {
        "name" : "ml.m5d.large",
        "value" : "ml.m5d.large"
    },

    {
        "name" : "ml.m5d.xlarge",
        "value" : "ml.m5d.xlarge"
    },

    {
        "name" : "ml.m5d.2xlarge",
        "value" : "ml.m5d.2xlarge"
    },

    {
        "name" : "ml.m5d.4xlarge",
        "value" : "ml.m5d.4xlarge"
    },

    {
        "name" : "ml.m5d.8xlarge",
        "value" : "ml.m5d.8xlarge"
    },

    {
        "name" : "ml.m5d.12xlarge",
        "value" : "ml.m5d.12xlarge"
    },

    {
        "name" : "ml.m5d.16xlarge",
        "value" : "ml.m5d.16xlarge"
    },

    {
        "name" : "ml.m5d.24xlarge",
        "value" : "ml.m5d.24xlarge"
    },

    {
        "name" : "ml.c4.xlarge",
        "value" : "ml.c4.xlarge"
    },

    {
        "name" : "ml.c4.2xlarge",
        "value" : "ml.c4.2xlarge"
    },

    {
        "name" : "ml.c4.4xlarge",
        "value" : "ml.c4.4xlarge"
    },

    {
        "name" : "ml.c4.8xlarge",
        "value" : "ml.c4.8xlarge"
    },

    {
        "name" : "ml.c5.xlarge",
        "value" : "ml.c5.xlarge"
    },

    {
        "name" : "ml.c5.2xlarge",
        "value" : "ml.c5.2xlarge"
    },

    {
        "name" : "ml.c5.4xlarge",
        "value" : "ml.c5.4xlarge"
    },

    {
        "name" : "ml.c5.9xlarge",
        "value" : "ml.c5.9xlarge"
    },

    {
        "name" : "ml.c5.18xlarge",
        "value" : "ml.c5.18xlarge"
    },

    {
        "name" : "ml.c5d.xlarge",
        "value" : "ml.c5d.xlarge"
    },

    {
        "name" : "ml.c5d.2xlarge",
        "value" : "ml.c5d.2xlarge"
    },

    {
        "name" : "ml.c5d.4xlarge",
        "value" : "ml.c5d.4xlarge"
    },

    {
        "name" : "ml.c5d.9xlarge",
        "value" : "ml.c5d.9xlarge"
    },

    {
        "name" : "ml.c5d.18xlarge",
        "value" : "ml.c5d.18xlarge"
    },

    {
        "name" : "ml.p2.xlarge",
        "value" : "ml.p2.xlarge"
    },

    {
        "name" : "ml.p2.8xlarge",
        "value" : "ml.p2.8xlarge"
    },

    {
        "name" : "ml.p2.16xlarge",
        "value" : "ml.p2.16xlarge"
    },

    {
        "name" : "ml.p3.2xlarge",
        "value" : "ml.p3.2xlarge"
    },

    {
        "name" : "ml.p3.8xlarge",
        "value" : "ml.p3.8xlarge"
    },

    {
        "name" : "ml.p3.16xlarge",
        "value" : "ml.p3.16xlarge"
    },

    {
        "name" : "ml.p3dn.24xlarge",
        "value" : "ml.p3dn.24xlarge"
    },

    {
        "name" : "ml.g4dn.xlarge",
        "value" : "ml.g4dn.xlarge"
    },

    {
        "name" : "ml.g4dn.2xlarge",
        "value" : "ml.g4dn.2xlarge"
    },

    {
        "name" : "ml.g4dn.4xlarge",
        "value" : "ml.g4dn.4xlarge"
    },

    {
        "name" : "ml.g4dn.8xlarge",
        "value" : "ml.g4dn.8xlarge"
    },

    {
        "name" : "ml.g4dn.12xlarge",
        "value" : "ml.g4dn.12xlarge"
    },

    {
        "name" : "ml.g4dn.16xlarge",
        "value" : "ml.g4dn.16xlarge"
    },

    {
        "name" : "ml.r5.large",
        "value" : "ml.r5.large"
    },

    {
        "name" : "ml.r5.xlarge",
        "value" : "ml.r5.xlarge"
    },

    {
        "name" : "ml.r5.2xlarge",
        "value" : "ml.r5.2xlarge"
    },

    {
        "name" : "ml.r5.4xlarge",
        "value" : "ml.r5.4xlarge"
    },

    {
        "name" : "ml.r5.8xlarge",
        "value" : "ml.r5.8xlarge"
    },

    {
        "name" : "ml.r5.12xlarge",
        "value" : "ml.r5.12xlarge"
    },

    {
        "name" : "ml.r5.16xlarge",
        "value" : "ml.r5.16xlarge"
    },

    {
        "name" : "ml.r5.24xlarge",
        "value" : "ml.r5.24xlarge"
    },

    {
        "name" : "ml.g5.xlarge",
        "value" : "ml.g5.xlarge"
    },

    {
        "name" : "ml.g5.2xlarge",
        "value" : "ml.g5.2xlarge"
    },

    {
        "name" : "ml.g5.4xlarge",
        "value" : "ml.g5.4xlarge"
    },

    {
        "name" : "ml.g5.8xlarge",
        "value" : "ml.g5.8xlarge"
    },

    {
        "name" : "ml.g5.16xlarge",
        "value" : "ml.g5.16xlarge"
    },

    {
        "name" : "ml.g5.12xlarge",
        "value" : "ml.g5.12xlarge"
    },

    {
        "name" : "ml.g5.24xlarge",
        "value" : "ml.g5.24xlarge"
    },

    {
        "name" : "ml.g5.48xlarge",
        "value" : "ml.g5.48xlarge"
    }
]