import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchConfig,
    fetchConfigMetadata,
    fetchMoreConfig
} from "src/control/actions/configPanel/config_panel_actions";
import {Configuration} from "./config_constants";
import {useHistory, useLocation} from "react-router-dom";
import {
    TableCell,
    TableBody,
    TableHead,
    TableHeadRow,
    TableRow, PaddedTableFooter
} from "src/view/style/table_styles";
import {
    getParamFromQueryString,
    getParamFromURL,
    resetParamsQueryString,
    setParamToQueryString
} from "src/utils/url_utils";
import {GreenSpinner, WhiteSpinner} from "src/view/style/header_styles";
import {CouldNotLoadAlert} from "src/view/style/common_styles";
import {
    VDeleteButton,
    VDiv, VTable, VTableHeader,
    VEditButton, VKey, VValue, VDropDown, VJSON, VLoadMore, VStatusIndicator, VInput, VSubmitButton, VInputHiddenDiv
} from "src/view/style/view_config_styles";
import {
    ReLoadIcon,
    EditIconTable,
    DeleteIconTable,
    colorWhite,
    ToggleOn,
    ToggleOff,
    CopyIcon, smallIconSize, UploadIcon
} from "src/view/style/icons";
import {getPrettyJsonString} from "src/utils/json_utils";
import {
    configBodySelector,
    configMetadataSelector,
    versionsSelector
} from "src/control/selectors/configPanel/config_panel_selectors";
import {isEmpty, isValidLambdaArn, setIfMounted} from "src/utils/common_utils";
import {ERROR_CONFIG_NOT_FOUND, ERROR_EMPTY_TEAM_OR_NAMESPACE} from "src/control/errors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import JSONEditor from "src/view/commons/json_editor";
import {KatInput, KatLabel, KatTab, KatTabs} from "@amzn/katal-react";
import CopyToClipboard from "react-copy-to-clipboard";
import { FormInput } from "../style/modelTraining/form_input_styles";
import CustomLambdaConfigValidator, { LambdaValidatorType } from "./custom_lambda_validator";
import { OperationType } from "src/constant";


export default function ViewConfiguration() {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const teamId = getParamFromURL("teamId");
    const namespaceId = getParamFromURL("namespaceId");
    const configId = getParamFromURL("configId");
    let versionFromQueryString = getParamFromQueryString(location.search, "version")

    let config = useSelector(configBodySelector);
    let configMetadata = useSelector(configMetadataSelector);
    let versions = useSelector(versionsSelector);

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [version, setVersion]: any = useState(versionFromQueryString);
    const [loading, setLoading] = useState(false);
    const [currentConfig, setCurrentConfig]: any = useState(null);
    const [errorOccurred, setErrorOccurred]: any = useState(false);
    const [newExp, setNewExp] = useState(true);


    let _isMounted = useRef(true);
    let resetQueryParams = useRef(false);

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    useEffect(() => {
        if (resetQueryParams.current)
            resetParamsQueryString();
    }, [resetQueryParams])

    useEffect(() => {
        if (_isMounted.current)
            initialize()
    }, [dispatch, teamId, namespaceId, configId, versionFromQueryString]);

    useEffect(() => {
        if (_isMounted.current)
            initializeVersion();
    }, [dispatch, versionFromQueryString, versions, fetchSuccess])

    useEffect(() => {
        if (_isMounted.current)
            initializeStates();
    }, [config, version, fetchSuccess, configMetadata])

    const initialize = async () => {
        try {
            setIfMounted(_isMounted.current, setCurrentConfig, null);
            if (isEmpty(teamId) || isEmpty(namespaceId)) {
                throw ERROR_EMPTY_TEAM_OR_NAMESPACE;
            }

            if (isEmpty(configId)) {
                throw ERROR_CONFIG_NOT_FOUND;
            }

            await dispatch(fetchConfigMetadata(configId, {
                includeJsonSchemaBody: true,
            }));
            await dispatch(fetchConfig(configId));

            if (!isEmpty(versionFromQueryString)) {
                await dispatch(fetchMoreConfig(configId, versionFromQueryString as string))
            }

            setIfMounted(_isMounted.current, setFetchSuccess, true);
            setIfMounted(_isMounted.current, setErrorOccurred, false);
        } catch (error) {
            setIfMounted(_isMounted.current, setFetchSuccess, false);
            setIfMounted(_isMounted.current, setErrorOccurred, true);
            dispatch(showSnackBar(error.message));
        }
    }

    const initializeVersion = async () => {
        if (!isEmpty(versionFromQueryString)) {
            setVersionAndQueryParam(versionFromQueryString as string);
        } else if (!isEmpty(versions) && fetchSuccess) {
            setVersionAndQueryParam(versions[0])
        }
    }

    const initializeStates = () => {
        if (fetchSuccess && !isEmpty(config) && !isEmpty(version)) {
            let currentConfiguration = getVersionDetails(version);
            if (!isEmpty(currentConfiguration) && configMetadata.configId == configId) {
                setIfMounted(_isMounted.current, setCurrentConfig, currentConfiguration);
            }
        }
    }

    const setVersionAndQueryParam = (version: string) => {
        if (resetQueryParams.current)
            return;

        setParamToQueryString("version", version);
        setIfMounted(_isMounted.current, setVersion, version);
    }

    const getVersionOptions = (versions: any) => {
        return versions.map((v: any) => ({name: v, value: v.toString()}));
    }

    const getVersionDetails = (selectedVersion: string) => {
        return config.find((config: Configuration) => config.version == selectedVersion)
    }


    const getLoadMore = () => {
        let getIcon = loading ? <WhiteSpinner size={"small"}/> : <ReLoadIcon style={colorWhite} size={"25px"}/>
        return (
            <VLoadMore onClick={(e: any) => handleLoadMore()} disabled={loading}>
                {getIcon}
                &nbsp; &nbsp; Load More
            </VLoadMore>
        )
    }

    const handleVersionChange = (version: string) => {
        setVersionAndQueryParam(version);
    }

    const handleEdit = () => {
        resetQueryParams.current = true;
        history.push(
            `/configPanel/${teamId}/namespaces/${namespaceId}/configs/${configId}/edit`
        );
    }

    const handleLoadMore = async (versionNeeded?: string) => {
        try {
            setIfMounted(_isMounted.current, setLoading, true);
            await dispatch(fetchMoreConfig(configId, versionNeeded));
            setIfMounted(_isMounted.current, setLoading, false);
        } catch (error) {
            setIfMounted(_isMounted.current, setLoading, false);
            dispatch(showSnackBar(error.message))
        }

    }

    const addValidators = () => {
        return (
          <>
            <VDiv style={{ paddingBottom: "50px" }}>
                    <VTable>
                        <TableHead>
                            <TableHeadRow>
                                <TableCell>
                                    <VTableHeader>Validators</VTableHeader>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableHeadRow>

                            <TableHeadRow>
                                <TableCell> Key </TableCell>
                                <TableCell> Value </TableCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                  <div>
                            <TableRow>
                                <VKey>
                                    <KatLabel tooltipText="
                                    The JSON schema against which your configuration body will be validated.
                                    Please refer to https://json-schema.org/ for writing json schema's
                                    ">
                                        JSON schema validator
                                    </KatLabel>
                                </VKey>
                                <VValue>
                                    <div id='file-drop-area-json-validator'
                                         style={{height: "400px", width: "800px"}}>
                                        <JSONEditor
                                            fieldName={"JSON schema validator"}
                                            value={isEmpty(configMetadata.syntaxValidationSchemaBody) ? "-" : configMetadata.syntaxValidationSchemaBody}
                                            onChange={() => {
                                            }}
                                            readOnly={true}
                                        />
                                    </div>
                                    <br/>
                                    <div className={"container"} style={{maxWidth: "800px"}}>
                                        <div className={"row"}>
                                            <div className={"col"}>
                                            <div className={"float-left"}
                                                     style={{display: "flex", marginLeft: "-10px"}}>
                                                    <CopyToClipboard
                                                        text={`${configMetadata.syntaxValidationSchemaBody}`}>
                                                        <div onClick={() => {
                                                            dispatch(showSnackBar("Schema Copied"))
                                                        }}>
                                                            <strong>Copy</strong>&nbsp;
                                                            <CopyIcon size={smallIconSize}/>
                                                        </div>
                                                    </CopyToClipboard>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                              </VValue>
                            </TableRow>
                  </div>

                  <TableRow>
                    <CustomLambdaConfigValidator
                        operationType= {OperationType.VIEW}
                        lambdaValidatorType= {
                            (!isEmpty(configMetadata.semanticValidationLambdaARN) && LambdaValidatorType.CUSTOM) 
                            || (!isEmpty(configMetadata.semanticValidationLambdaName) && LambdaValidatorType.ONBOARDED)
                            || LambdaValidatorType.NONE
                        }
                        customValidatorLambdaArn= {configMetadata.semanticValidationLambdaARN}
                        customValidatorLambdaName= {configMetadata.semanticValidationLambdaName}
                     />
                  </TableRow>
                    
                </TableBody>
              </VTable>
            </VDiv>
          </>
        );
    }

    const display = () => {
        let currentConfigLoaded = !isEmpty(currentConfig);

        if (errorOccurred) {
            return (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    Could Not Load Configuration
                </CouldNotLoadAlert>
            );
        }

        if (!currentConfigLoaded) {
            return (
                <GreenSpinner variant={"default"} size={"large"}/>
            );
        }

        const versionOptions = getVersionOptions(versions);
        const configuration: Configuration = currentConfig
        let jsonConfig = getPrettyJsonString(configuration.jsonConfig as string);
        jsonConfig = jsonConfig ? jsonConfig : "Could not Load JSON_CONFIG";

        return (
            <>
                <VDiv style={{paddingBottom : "50px"}}>
                    <VTable>
                        <TableHead>
                            <TableHeadRow>
                                <TableCell>
                                    <VTableHeader>Configuration</VTableHeader>
                                </TableCell>
                                <TableCell>
                                    <VTableHeader>
                                        <VDeleteButton className={"disabled-link"}>
                                            <DeleteIconTable style={colorWhite} size={null}/>
                                        </VDeleteButton>
                                        <VEditButton onClick={handleEdit}>
                                            <EditIconTable style={colorWhite} size={null}/>
                                        </VEditButton>
                                    </VTableHeader>
                                </TableCell>
                            </TableHeadRow>
                            <TableHeadRow>
                                <TableCell> Key </TableCell>
                                <TableCell> Value </TableCell>
                            </TableHeadRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <VKey>Configuration Name</VKey>
                                <VValue>{configMetadata.configName}</VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Description</VKey>
                                <VValue>{configMetadata.description}</VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Service Name</VKey>
                                <VValue>{configMetadata.serviceName}</VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Realm</VKey>
                                <VValue>{!isEmpty(configMetadata.applicationRealm) ? configMetadata.applicationRealm : configMetadata.realm}</VValue>
                            </TableRow>
                            <TableRow>
                                <VKey>Domain</VKey>
                                <VValue>{configMetadata.domain}</VValue>
                            </TableRow>
                        </TableBody>
                    </VTable>
                </VDiv>
                <KatTabs selected={"configMetadata"}>
                    <KatTab tabId="configMetadata" label="Config">
                        <VDiv>
                            <VTable>
                                <TableBody>
                                        <TableRow>
                                            <VKey>Version</VKey>
                                            <VValue>
                                                <VDropDown
                                                    options={versionOptions}
                                                    value={version}
                                                    placeholder="Choose Version"
                                                    onChange={(e: any) => {
                                                        handleVersionChange(e.detail.value);
                                                    }}
                                                />
                                                {getLoadMore()}
                                            </VValue>
                                        </TableRow>
                                    <TableRow>
                                        <VKey>Configuration</VKey>
                                        <VValue>
                                            <div className={"float-right"}
                                                 onClick={() => {
                                                     setIfMounted(_isMounted.current, setNewExp, !newExp)
                                                 }}>
                                                {
                                                    newExp ?
                                                        <div className={"custom-toggle"}>
                                                            <VStatusIndicator variant="success" label="New Experience"/>
                                                            &nbsp;&nbsp;
                                                            <ToggleOn/>
                                                        </div> :
                                                        <div className={"custom-toggle"}>
                                                            <VStatusIndicator variant="alert" label="Old Experience"/>
                                                            &nbsp;&nbsp;
                                                            <ToggleOff/>
                                                        </div>
                                                }
                                            </div>
                                            <br/>
                                            <div style={{height: "400px", width: "800px"}}>
                                                {newExp ?
                                                    <JSONEditor
                                                        fieldName={"configView"}
                                                        value={jsonConfig}
                                                        onChange={() => {
                                                        }}
                                                        readOnly={true}
                                                    /> :
                                                    <VJSON
                                                        disabled
                                                        value={jsonConfig}
                                                    />
                                                }
                                            </div>
                                        </VValue>
                                    </TableRow>
                                </TableBody>
                            </VTable>
                        </VDiv>
                    </KatTab>
                    <KatTab tabId="configValidators" label="Validators">
                        {addValidators()}
                    </KatTab>
                </KatTabs>
            </>
        );

    }

    return (
        <>
            {display()}
        </>
    )
}
