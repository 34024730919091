import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TableCellItem,
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableHeadRow,
  TableRowWithShadow,
} from "../../style/table_styles";
import {formatDate, getFromLocalStorageAndSetTeam, isEmpty} from "../../../utils/common_utils";
import { GreenSpinner } from "../../style/header_styles";
import { Ordering } from "../../dataset/dataset_constants";
import {
  colorGrey,
  EditIconTable,
  SortAscending,
  SortDescending,
  ViewIconTable,
} from "../../style/icons";
import { JobTemplate } from "src/model/runways/job_template_model";
import { isJobTemplateSpinnerLoadingSelector, jobTemplateListSelector } from "src/control/selectors/runways/job_template_selectors";
import { useHistory, useParams } from "react-router";
import { listJobTemplates } from "src/control/actions/runways/job_template_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { CreateButton } from "src/view/style/runway_styles";
import { KatCard, KatTableCell } from "@amzn/katal-react";
import ReactTooltip from "react-tooltip";
import { sortList } from "src/utils/list_utils";
import { toggledSortOrdering } from "../constants";
import {
  ErrorContainer,
  ErrorHeading,
} from "src/view/style/modelTraining/errors";

enum SortBy {
  templateName = "templateName",
  createdBy = "createdBy",
  creationTime = "creationTime",
  lastUpdatedBy = "lastUpdatedBy",
  lastUpdatedTime = "lastUpdatedTime",
}

/**
 * renders the view into a list job templates table
 * @returns JSX
 */
export default function ListJobTemplates() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();

  //selectors
  let userName = useSelector(usernameSelector);
  let jobTemplateList: JobTemplate[] = useSelector(jobTemplateListSelector);
  let isSpinnerLoading = useSelector(isJobTemplateSpinnerLoadingSelector)

  //state hooks
  const [sortBy, setSortBy] = useState(SortBy.templateName);
  const [sortOrder, setSortOrder] = useState(Ordering.Ascending);

  //useEffect hooks
  useEffect(() => {
    dispatch(listJobTemplates());
    getFromLocalStorageAndSetTeam(dispatch)
  }, [userName]);

  /**
   * sets the property on which sorting needs to happen
   * @param sortByProperty
   */
  const handleOnSortClick = (sortByProperty: SortBy) => {
    if (sortBy == sortByProperty) {
      setSortOrder(toggledSortOrdering(sortOrder));
    } else {
      setSortBy(sortByProperty);
      setSortOrder(Ordering.Ascending);
    }
  };

  /**
   * returns the sort icon based upon sorting order
   * @param property
   * @returns JSX
   */
  const getSortIcon = (property: SortBy) => {
    if (sortBy == property) {
      return sortOrder == Ordering.Ascending ? (
        <div
          className={"float-right"}
          onClick={() => handleOnSortClick(property)}
        >
          <SortAscending />
        </div>
      ) : (
        <div
          className={"float-right"}
          onClick={() => handleOnSortClick(property)}
        >
          <SortDescending />
        </div>
      );
    } else {
      return (
        <div
          className={"float-right"}
          onClick={() => handleOnSortClick(property)}
        >
          <SortAscending style={colorGrey} />
        </div>
      );
    }
  };

  /**
   * redirects to view page
   * @param jobTemplateId
   */
  const handleRedirectToView = (jobTemplateId: string) => {
    let redirectedPath = window.location.pathname + `/${jobTemplateId}/view`;
    history.push(redirectedPath);
  };

  /**
   * renders list of job templates in table rows
   * @returns jobTemplateList<JSX>
   */
  const fillRows = () => {
    let templateList = sortList(jobTemplateList, sortBy);
    templateList =
      sortOrder == Ordering.Ascending ? templateList : templateList.reverse();

    return templateList.map((template: JobTemplate, key: number) => {
      return (
        <TableRowWithShadow key={key}>
          <KatTableCell
            onClick={() => handleRedirectToView(template.jobTemplateId)}
          >
            <TableCellItem>{template.templateName}</TableCellItem>
          </KatTableCell>
          <KatTableCell
            onClick={() => handleRedirectToView(template.jobTemplateId)}
          >
            <TableCellItem>{template.createdBy}</TableCellItem>
          </KatTableCell>
          <KatTableCell
            onClick={() => handleRedirectToView(template.jobTemplateId)}
          >
            <TableCellItem>
              {template.creationTime && formatDate(template.creationTime)}
            </TableCellItem>
          </KatTableCell>
          <KatTableCell>
            <ReactTooltip place="bottom" type="dark" />
            <TableCellItem>
              <TableCellItem>
                <div
                  data-tip="Edit Template Details"
                  onClick={() => {
                    history.push(
                      window.location.pathname +
                        `/${template.jobTemplateId}/update`
                    );
                  }}
                >
                  <EditIconTable
                    style={{
                      margin: "10px",
                      width: "20px",
                      color: "#002e36",
                    }}
                  />
                </div>
                <div
                  data-tip="View Template Details"
                  onClick={() => {
                    handleRedirectToView(template.jobTemplateId);
                  }}
                >
                  <ViewIconTable
                    style={{
                      margin: "10px",
                      width: "20px",
                      color: "#002e36",
                    }}
                  />
                </div>
              </TableCellItem>
            </TableCellItem>
          </KatTableCell>
        </TableRowWithShadow>
      );
    });
  };

  /**
   * renders data into table headers
   * @returns JSX
   */
  const display = () => {
    return (
      <>
        <Table>
          {jobTemplateList.length == 0 ? (
            <ErrorContainer>
              <ErrorHeading>No Results Found</ErrorHeading>
            </ErrorContainer>
          ) : (
            <>
              <TableHead>
                <TableHeadRow>
                  <KatTableCell>
                    <TableCellItem>
                      Template Name&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.templateName)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <TableCellItem>
                      Created By&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.createdBy)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <TableCellItem>
                      Created At&nbsp;&nbsp;&nbsp;
                      {getSortIcon(SortBy.creationTime)}
                    </TableCellItem>
                  </KatTableCell>
                  <KatTableCell>
                    <div>Actions</div>
                  </KatTableCell>
                </TableHeadRow>
              </TableHead>
              <TableBody>{fillRows()}</TableBody>
            </>
          )}
        </Table>
      </>
    );
  };

  if (isSpinnerLoading) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
    <>
      <KatCard>
        <div>
          <CreateButton
            variant="primary"
            label="Create New"
            onClick={() => {
              let redirectedPath = window.location.pathname + "/create";
              history.push(redirectedPath);
            }}
          />
        </div>
        <TableHeader>Job Templates</TableHeader>
        <br />
        {display()}
      </KatCard>
    </>
  );
}
