export enum RepositoryType {
  "S3" = "S3",
  "GIT" = "GIT",
}

export interface OffsetInfo {
  days: number;
  hours: number;
  minutes: number;
}

export enum ServiceType {
  EMR = "EMR",
  SAGEMAKER = "SAGEMAKER",
  ECS = "ECS",
}

export enum SagemakerFeature {
  TRAINING = "TRAINING",
}

export enum ContainerType {
  IMAGE = "IMAGE",
  VERSION_SET = "VERSION_SET",
}

export interface ImageContainer {
  containerType: ContainerType;
  containerName: string;
  containerArn: string;
}

export interface VersionSetContainer {
  containerType: ContainerType;
  containerName: string;
}

export type ContainerInfo = ImageContainer | VersionSetContainer;

export interface S3Repository {
  bucketName: string;
  isAccessible: Boolean;
  isVersionEnabled: Boolean;
  message: string;
}

export interface GitRepository {
  packageName: string;
  branchList: string[];
  gitRepoURL: string;
  syncEnabled: Boolean;
  message: string;
}

export interface CodeRepository {
  repositoryType: RepositoryType | string;
  gitRepository: GitRepository | {} | null;
  s3Repository: S3Repository | {} | null;
}

export interface PreExecutionInfo {
  codeUri: string;
  codeVersion: string;
}

export interface ExecutionInfo {
  codeUri: string;
  codeVersion: string;
}

export interface PostExecutionInfo {
  codeUri: string;
  codeVersion: string;
}

export interface CodeInfo {
  codeRepository: CodeRepository;
  preExecutionInfo: PreExecutionInfo;
  executionInfo: ExecutionInfo;
  postExecutionInfo: PostExecutionInfo;
}

export interface ArtifactInfo {
  containerType: ContainerType | string;
  jobMetadata: string | null;
  arguments: string | null;
  codeInfo: CodeInfo;
  containerInfo: ContainerInfo | {};
}

export interface Job {
  namespaceId: string;
  jobId: string;
  version: number;
  latestVersion: number;
  jobName: string;
  artifactInfo: ArtifactInfo;
  serviceType: ServiceType | string;
  service: Service | {};
  jobScheduleInfo: JobScheduleInfo;
  jobDependencies: JobDependencyInfo[];
  jobStatus: JobStatus | string;
  domain: string;
  createdBy: string;
  creationTime: string;
  lastUpdatedBy: string;
  lastUpdatedTime: string;
  customTags: string[];
}

export interface JobScheduleInfo {
  schedulerJobId?: string;
  scheduleFrequencyInMins: number;
  baseTimestamp: number;
  offsetInfo: OffsetInfo;
}

export enum JobStatus {
  ENABLED = "ENABLED",
  DISABLED = "DISABLED",
}

export type Service = EmrService | EcsService | SagemakerService;

export type SagemakerServiceInfo = SagemakerModelTrainingServiceInfo;

export interface EmrService {
  serviceType: ServiceType;
  emrClusterType: EmrClusterType;
  computeInfo: EmrComputeInfo;
  serviceInfo: EmrServiceInfo;
}

export interface EcsService {
  serviceType: ServiceType;
  computeInfo: EcsComputeInfo;
  serviceInfo: EcsServiceInfo;
}

export interface SagemakerService {
  serviceType: ServiceType;
  sagemakerFeature: SagemakerFeature;
  computeInfo: SagemakerComputeInfo;
  serviceInfo: SagemakerServiceInfo;
}

export enum EmrClusterType {
  ADHOC = "ADHOC",
  BYOC = "BYOC",
  DEFAULT = "DEFAULT",
}

export interface EmrComputeInfo {
  clusterConfig: string;
  clusterName: string;
}

export interface EcsComputeInfo {}

export interface SagemakerComputeInfo {
  volumeSizeInGB: string;
  instanceType: string;
  instanceCount: string;
}

export interface EmrServiceInfo {
  jobFlowStep: string;
}

export interface EcsServiceInfo {}

export interface SagemakerModelTrainingServiceInfo {
  sagemakerFeature: SagemakerFeature;
}

export interface JobInstance {
  jobInstanceId: string;
  timestamp: string;
  status: string;
}

export interface JobDependencyInfo {
  schedulerJobId?: string;
  dependencyOffsetInMins: number;
  dependentInstancesCount: number;
  dependencyInstanceFrequencyInMins: number;
}

export interface InitialJobState {
  fetchedLatestVersionJob: Job;
  fetchedJob: Job;
  fetchedJobVersionsList: Job[];
  fetchedJobExecutionsList: {
    executionsList: any[];
    // Keeping load more enabled
    loadMore: boolean;
    jobsExecutionsFetched: boolean;
    fetchAllExecutionsSuccess: boolean;
  };
  executionMetrics: {
    metricData: any;
    jobsExecutionMetricsFetched: boolean;
    fetchJobsExecutionMetricsSuccess: boolean;
  };
  isSpinnerLoading: boolean;
  fetchedJobList: Job[];
}

export const initialJobState: InitialJobState = {
  fetchedLatestVersionJob: {
    namespaceId: "",
    jobId: "",
    version: 1,
    latestVersion: -1,
    jobName: "",
    artifactInfo: {
      containerType: "",
      containerInfo: {},
      jobMetadata: "",
      arguments: "",
      codeInfo: {
        codeRepository: {
          repositoryType: "",
          gitRepository: {},
          s3Repository: {},
        },
        preExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        executionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        postExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        },
      },
    },
    serviceType: "",
    service: {},
    jobScheduleInfo: {
      schedulerJobId: "",
      scheduleFrequencyInMins: 60,
      baseTimestamp: 0,
      offsetInfo: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
    },
    jobDependencies: [
      {
        dependencyOffsetInMins: 0,
        dependentInstancesCount: 1,
        dependencyInstanceFrequencyInMins: 60,
      },
    ],
    jobStatus: "",
    domain: "",
    createdBy: "",
    creationTime: "",
    lastUpdatedBy: "",
    lastUpdatedTime: "",
    customTags: [],
  },
  fetchedJob: {
    namespaceId: "",
    jobId: "",
    version: 1,
    latestVersion: 1,
    jobName: "",
    artifactInfo: {
      containerType: "",
      containerInfo: {},
      jobMetadata: "",
      arguments: "",
      codeInfo: {
        codeRepository: {
          repositoryType: "",
          gitRepository: {},
          s3Repository: {},
        },
        preExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        executionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        postExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        },
      },
    },
    serviceType: "",
    service: {},
    jobScheduleInfo: {
      schedulerJobId: "",
      scheduleFrequencyInMins: 60,
      baseTimestamp: 0,
      offsetInfo: {
        days: 0,
        hours: 0,
        minutes: 0,
      },
    },
    jobDependencies: [
      {
        dependencyOffsetInMins: 0,
        dependentInstancesCount: 1,
        dependencyInstanceFrequencyInMins: 60,
      },
    ],
    jobStatus: "",
    domain: "",
    createdBy: "",
    creationTime: "",
    lastUpdatedBy: "",
    lastUpdatedTime: "",
    customTags: [],
  },
  fetchedJobExecutionsList: {
    executionsList: [],
    loadMore: true,
    jobsExecutionsFetched: false,
    fetchAllExecutionsSuccess: false,
  },
  executionMetrics: {
    metricData: {},
    jobsExecutionMetricsFetched: false,
    fetchJobsExecutionMetricsSuccess: false,
  },
  fetchedJobVersionsList: [],
  isSpinnerLoading: false,
  fetchedJobList: [],
};
