import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRunwayLatestVersionData } from "src/control/actions/runways/runway_actions";
import { isEmpty } from "src/utils/common_utils";
import ViewV1Runway from "./modelTraining/runways/view_runways";
import ViewV2Runway from "./runways/view_runway";
import { GreenSpinner } from "./style/header_styles";
import { runwayReleaseSelector } from "src/control/selectors/runways/runway_selectors";

/**
 * responsible for calling the corresponding v1/v2 view runway component
 * @returns JSX
 */
export default function ViewRunway() {
  const dispatch = useDispatch();
  const params: any = useParams();

  //selectors
  let runwayRelease = useSelector(runwayReleaseSelector);

  //useEffect hooks
  useEffect(() => {
    dispatch(getRunwayLatestVersionData(params.runwayId));
  }, [params.runwayId, params.version]);

  if (runwayRelease === -1) {
    return <GreenSpinner />;
  }

  return (
    <>
      {!isEmpty(runwayRelease) && runwayRelease.toString() === "2" && (
        <ViewV2Runway />
      )}
      {isEmpty(runwayRelease) && <ViewV1Runway />}
    </>
  );
}
