import {apiGetPromise, apiPostPromise} from "src/utils/api_handler";
import {
    getCreateExperimentEndpoint,
    getDeleteExperimentEndPoint,
    getFetchClusterStatus,
    getFetchExperimentsEndpoint,
    getRestartExperimentEndPoint,
    getUpdateExperimentEndpoint
} from "src/control/targets";
import {isEmpty} from "src/utils/common_utils";
import {Failure, Success} from "src/utils/action_utils";
import {
    CREATE_EXPERIMENT_FAILURE,
    CREATE_EXPERIMENT_SUCCESS,
    DELETE_NOTEBOOK_FAILURE,
    DELETE_NOTEBOOK_SUCCESS,
    ERROR_CREATING_EXPERIMENT,
    ERROR_DELETING_NOTEBOOK,
    ERROR_FETCHING_ALL_EXPERIMENTS,
    ERROR_FETCHING_CLUSTER_STATUS,
    ERROR_FETCHING_EXPERIMENT,
    ERROR_RESTARTING_NOTEBOOK,
    FETCH_ALL_EXPERIMENTS_FAILURE,
    FETCH_ALL_EXPERIMENTS_SUCCESS,
    FETCH_CLUSTER_STATUS_FAILURE,
    FETCH_CLUSTER_STATUS_SUCCESS,
    FETCH_EXPERIMENT_FAILURE,
    FETCH_EXPERIMENT_SUCCESS,
    RESTART_NOTEBOOK_FAILURE,
    RESTART_NOTEBOOK_SUCCESS,
    UPDATE_EXPERIMENT_FAILURE,
    UPDATE_EXPERIMENT_SUCCESS
} from "src/control/actions/action_types";
import {
    EXPERIMENT_UPDATE_FAILED_MESSAGE,
    getDeleteExperimentFailureMessage,
    getDeleteExperimentSuccessMessage,
    getRestartExperimentFailureMessage,
    getRestartExperimentSuccessMessage
} from "src/view/modelTraining/constants";

export const createExperiment = (postData: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_CREATING_EXPERIMENT;
        try {
            let data: any = await apiPostPromise(getCreateExperimentEndpoint(), postData);
            if (!isEmpty(data)) {
                dispatch(Success(CREATE_EXPERIMENT_SUCCESS, {
                    ...data,
                    notebookInstanceName: postData.notebookInstanceName,
                    userId: postData.userId
                }));
                resolve();
                return;
            }
            dispatch(Failure(CREATE_EXPERIMENT_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(CREATE_EXPERIMENT_FAILURE, err));
            reject(error);
        }
    });
}

export const loadExperimentResult = (experimentName: string) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_EXPERIMENT
        try {
            let data: any = await apiGetPromise(getFetchExperimentsEndpoint(), {
                experimentName: experimentName
            });
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_EXPERIMENT_SUCCESS, {
                    ...data.experiments[0]
                }));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_EXPERIMENT_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_EXPERIMENT_FAILURE, error));
            reject(err);
        }
    });
}

export const updateExperiment = (postData: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = EXPERIMENT_UPDATE_FAILED_MESSAGE
        try {
            let data: any = await apiPostPromise(getUpdateExperimentEndpoint(), postData);
            if (!isEmpty(data)) {
                dispatch(Success(UPDATE_EXPERIMENT_SUCCESS, data));
                resolve();
                return;
            }
            dispatch(Failure(UPDATE_EXPERIMENT_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(UPDATE_EXPERIMENT_FAILURE, error));
            reject(err);
        }
    });
}

export const loadAllExperiments = (params: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_ALL_EXPERIMENTS
        try {
            let data: any = await apiGetPromise(getFetchExperimentsEndpoint(), params);
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_ALL_EXPERIMENTS_SUCCESS, data.experiments));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_ALL_EXPERIMENTS_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_ALL_EXPERIMENTS_FAILURE, error));
            reject(err);
        }
    });
}

export const loadClusterStatus = (params: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_CLUSTER_STATUS
        try {
            let data: any = await apiGetPromise(getFetchClusterStatus(), params);
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_CLUSTER_STATUS_SUCCESS, data));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_CLUSTER_STATUS_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_CLUSTER_STATUS_FAILURE, error));
            reject(err);
        }
    });
}

export const restartNotebook = (postData: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_RESTARTING_NOTEBOOK
        try {
            let data: any = await apiPostPromise(getRestartExperimentEndPoint(), postData);
            if (!isEmpty(data)) {
                dispatch(Success(RESTART_NOTEBOOK_SUCCESS, {
                    ...data,
                    message: getRestartExperimentSuccessMessage(postData.notebookInstanceName)
                }));
                resolve();
                return;
            }
            dispatch(Failure(RESTART_NOTEBOOK_FAILURE, getRestartExperimentFailureMessage(postData.notebookInstanceName)));
            reject(error);
        } catch (err) {
            dispatch(Failure(RESTART_NOTEBOOK_FAILURE, error));
            reject(err);
        }
    });
}

export const deleteNotebook = (postData: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_DELETING_NOTEBOOK
        try {
            let data: any = await apiPostPromise(getDeleteExperimentEndPoint(), postData);
            if (!isEmpty(data)) {
                dispatch(Success(DELETE_NOTEBOOK_SUCCESS, {
                    ...data,
                    message: getDeleteExperimentSuccessMessage(postData.notebookInstanceName)
                }));
                resolve();
                return;
            }
            dispatch(Failure(DELETE_NOTEBOOK_FAILURE, getDeleteExperimentFailureMessage(postData.notebookInstanceName)));
            reject(error);
        } catch (err) {
            dispatch(Failure(DELETE_NOTEBOOK_FAILURE, error));
            reject(err);
        }
    });
}