import { DryadAccountName } from "src/view/featureGeneration/constants";
import {RepositoryType} from "src/model/runways/job_model";
import {DataStudioAddonType} from "src/model/notebooks/data_studio_models";
import {isEmpty} from "src/utils/common_utils";

export const TEAM_API_TARGET = "team";
export const USERNAME_API_TARGET = "username";
export const MODEL_TRAINING_NAMESPACE_TARGET = "namespace/runway";
export const RUNWAYS_TARGET = "runways";
export const CODE_REPOSITORY_TARGET = "codeRepository";
export const CODE_REPOSITORY_VERSIONS_TARGET = "versions";
export const REGISTER_CODE_REPOSITORY = "namespace/registerRepository";
export const CREATE_EXPERIMENT_TARGET = "notebook/triggerCreation";
export const RESTART_EXPERIMENT_TARGET = "notebook/restart";
export const DELETE_EXPERIMENT_TARGET = "notebook/triggerDelete";
export const GET_EXPERIMENTS_TARGET = "notebook/experiments";
export const CONFIG_TARGET = "config";
export const VALIDATION_CONFIG_TARGET = "validation/config";
export const GET_CLUSTER_STATUS_TARGET = "cluster";
export const UPDATE_EXPERIMENTS_TARGET = "notebook/triggerNotebookUpdate";
export const NAMESPACE_API_TARGET = "namespace";
export const CONFIG_PANEL = "configPanel";
export const MODEL_TRAINING = "runway";
export const FEATURE_GENERATION = "featureGeneration";
export const CONFIG_API_TARGET = "metadataconfigs";
export const FEATURE_GENERATION_JOB = "job";
export const PROMOTE_JOB_TARGET = "promote";
export const DATASET_API_TARGET = "datasetInfo";
export const GET_ACCOUNT_INFO_TARGET = "accountInfra";
export const UPDATE_RUNWAY_STATUS_TARGET = "status";
export const GET_TRAINING_IMAGES_TARGET = "repositoryInfo";
export const JOB_INSTANCES = "jobInstances";
export const REVIEW_TARGET = "reviews";
export const CONFIGS_TARGET = "configs";
export const CODE_VERSIONS_TARGET = "listCodeVersions";
export const JOB_TEMPLATE_TARGET = "jobTemplate";
export const JOB_TARGET = "jobs";
export const DATA_STUDIO_ONBOARDING_TARGET = "onboarding";
export const DATA_STUDIO_ADDON_TARGET = "addon";

export const getCreateJobEndpoint = () => {
  return JOB_TARGET;
}

export const getListJobsEndpoint = () => {
  return JOB_TARGET;
}

export const getUpdateJobEndpoint = (jobId: string) => {
  return JOB_TARGET + "/" + jobId;
}

export const getViewJobEndpoint = (jobId: string) => {
  return JOB_TARGET + "/" + jobId;
}

//indicates job instances for given jobId
export const getListJobsInstancesEndpointV2 = (jobId: string) => {
  return JOB_TARGET + "/" + jobId + "/" + JOB_INSTANCES;
}

export const getCreateJobTemplateEndpoint= () => {
  return JOB_TEMPLATE_TARGET;
}

export const getListJobTemplatesEndpoint = () => {
  return JOB_TEMPLATE_TARGET;
};

export const getUpdateJobTemplateEndpoint = (jobTemplateId: string) => {
  return JOB_TEMPLATE_TARGET + "/" + jobTemplateId;
}

export const getViewJobTemplateEndpoint = (jobTemplateId: string) => {
  return JOB_TEMPLATE_TARGET + "/" + jobTemplateId;
}

export const getCreateV2RunwayEndpoint = () => {
  return RUNWAYS_TARGET;
}

export const getUpdateV2RunwayStatusEndpoint = (
  runwayId: string,
  runwayStatus: string
) => {
  return (
    RUNWAYS_TARGET +
    "/" +
    runwayId +
    "/" +
    UPDATE_RUNWAY_STATUS_TARGET +
    "/" +
    runwayStatus
  );
};

export const getViewV2RunwayEndpoint = (runwayId: string) => {
  return RUNWAYS_TARGET + "/" + runwayId; 
}

export const getUpdateV2RunwayEndpoint = (runwayId: string) => {
  return RUNWAYS_TARGET + "/" + runwayId;
}

export const getListV2RunwaysEndpoint = () => {
  return RUNWAYS_TARGET;
} 

export const getCreateConfigReviewEndpoint = () => {
  return CONFIGS_TARGET + "/review";
};

export const getReviewTargetEndpoint = (reviewId: string) => {
  return REVIEW_TARGET + "/" + reviewId;
};

export const getFetchAllTeamsTarget = () => {
  return TEAM_API_TARGET;
};

export const getFetchTeamTarget = (teamId: string) => {
  return TEAM_API_TARGET + "/" + teamId;
};

export const getUsernameTarget = () => {
  return USERNAME_API_TARGET;
};

export const getCodeVersionTarget = () => {
    return CODE_VERSIONS_TARGET;
}

export const getModelTrainingNamespaceEndpoint = (teamId: string, namespaceId: string) => {
    return MODEL_TRAINING_NAMESPACE_TARGET + "/" + teamId + "/" + namespaceId
}

export const getAccountInfoEndpoint = (accountId: string) => {
  return GET_ACCOUNT_INFO_TARGET + "/" + accountId;
};

export const getModelTrainingRunwayEndpoint = (runwayId: string) => {
  return RUNWAYS_TARGET + "/" + runwayId;
};

export const getFetchRunwaysEndpoint = () => {
  return RUNWAYS_TARGET + "/";
};

export const getTrainingImagesEndpoint = (repositoryType: string) => {
  return GET_TRAINING_IMAGES_TARGET + "/" + repositoryType;
};

export const getUpdateRunwayStatusEndpoint = (
  runwayId: string,
  runwayStatus: string
) => {
  return (
    RUNWAYS_TARGET +
    "/" +
    runwayId +
    "/" +
    UPDATE_RUNWAY_STATUS_TARGET +
    "/" +
    runwayStatus
  );
};

export const getListJobInstancesEndpoint = (runwayId: string) => {
  return RUNWAYS_TARGET + "/" + runwayId + "/" + JOB_INSTANCES;
};

export const codeRepositoriesEndpoint = () => {
  return CODE_REPOSITORY_TARGET;
};

export const getCodeRepositoryEndpoint = (repositoryName: string) => {
  return CODE_REPOSITORY_TARGET + "/" + repositoryName;
};

export const getCodeRepositoryVersionsEndpoint = (repositoryName: string) => {
  return CODE_REPOSITORY_TARGET + "/" + repositoryName + "/" + CODE_REPOSITORY_VERSIONS_TARGET;
};

export const getCreateExperimentEndpoint = () => {
  return CREATE_EXPERIMENT_TARGET;
};

export const getFetchExperimentsEndpoint = () => {
  return GET_EXPERIMENTS_TARGET;
};

export const getConfigMetadataEndpoint = (configId: string) => {
  return CONFIG_TARGET + "/" + configId + "/metadata";
};

export const getFetchConfigValidationEndpoint = (validationId: string) => {
  return VALIDATION_CONFIG_TARGET + "/" + validationId;
};

export const getTriggerConfigValidationEndpoint = () => {
  return VALIDATION_CONFIG_TARGET;
};

export const getConfigBodyEndpoint = (configId: string) => {
  return CONFIG_TARGET + "/" + configId;
};

export const getListConfigsEndpoint = () => {
  return CONFIG_TARGET;
};

export const getFetchClusterStatus = () => {
  return GET_CLUSTER_STATUS_TARGET;
};

export const getUpdateExperimentEndpoint = () => {
  return UPDATE_EXPERIMENTS_TARGET;
};

export const getCreateModelTrainingNamespaceEndpoint = () => {
  return MODEL_TRAINING_NAMESPACE_TARGET;
};

export const getCreateRunwayEndpoint = () => {
  return RUNWAYS_TARGET;
};

export const getFetchRunwayEndpoint = (namespaceId: string) => {
  return RUNWAYS_TARGET + "?namespaceId=" + namespaceId;
};

export const getFetchConfigPanelNamespaceTarget = (teamId: string) => {
  return NAMESPACE_API_TARGET + "/" + CONFIG_PANEL + "/" + teamId;
};

export const getFetchModelTrainingNamespaceTarget = (teamId: string) => {
  return NAMESPACE_API_TARGET + "/" + MODEL_TRAINING + "/" + teamId;
};

export const getFetchFeatureGenerationNamespaceTarget = (teamId: string) => {
  return NAMESPACE_API_TARGET + "/" + FEATURE_GENERATION + "/" + teamId;
};

export const getRestartExperimentEndPoint = () => {
  return RESTART_EXPERIMENT_TARGET;
};

export const getDeleteExperimentEndPoint = () => {
  return DELETE_EXPERIMENT_TARGET;
};

export const getFetchConfigTarget = () => {
  return CONFIG_API_TARGET;
};
export const getCreateFeatureGenerationNamespaceTarget = () => {
  return `${NAMESPACE_API_TARGET}/${FEATURE_GENERATION}`;
};

export const getFeatureGenerationNamespaceTarget = (
  teamId: string,
  namespaceId: string
) => {
  return `${NAMESPACE_API_TARGET}/${FEATURE_GENERATION}/${teamId}/${namespaceId}`;
};

export const getCreateFeatureGenerationJobTarget = (namespaceId: string) => {
  return `${FEATURE_GENERATION}/${namespaceId}/${FEATURE_GENERATION_JOB}`;
};

export const getListFeatureGenerationJobTarget = (namespaceId: string) => {
  return `${FEATURE_GENERATION}/${namespaceId}/${FEATURE_GENERATION_JOB}`;
};

export const getPromoteFeatureGenerationJobTarget = (
  namespaceId: string,
  jobId: string
) => {
  return `${FEATURE_GENERATION}/${namespaceId}/${FEATURE_GENERATION_JOB}/${jobId}/${PROMOTE_JOB_TARGET}`;
};

export const getCradleProfileTarget = (jobId: string) => {
  return `https://cradle.corp.amazon.com/#/${DryadAccountName}/profiles/${jobId}`;
};

export const getDatasetFetchAllTarget = () => {
  return DATASET_API_TARGET;
};

export const getRegisterCodeRepositoryEndpoint = (
  namespaceId: string
) => {
  return REGISTER_CODE_REPOSITORY  + "/" + namespaceId;
};

export const getDataStudioOnboardingsTarget = () => {
  return `${DATA_STUDIO_ONBOARDING_TARGET}s`
}

export const getDataStudioAddonsTarget = () => {
  return `${DATA_STUDIO_ADDON_TARGET}s`
}

export const getDataStudioOnboardingTarget = () => {
  return `${DATA_STUDIO_ONBOARDING_TARGET}`
}

export const getTargetForDataStudioAddonType = (addonType: DataStudioAddonType) => {
  switch (addonType) {
    case DataStudioAddonType.JUPYTER_MANAGED_ENDPOINT:
      return "managed-endpoint";
    default:
      return ""
  }
}

export const getDataStudioAddonTargetWithAddonId = (addonId: string, addonType: DataStudioAddonType) => {
  return `${DATA_STUDIO_ADDON_TARGET}/${getTargetForDataStudioAddonType(addonType)}/${addonId}`
}

export const getDataStudioManagedEndpointTarget = (addonId? : string) => {
  return `${DATA_STUDIO_ADDON_TARGET}/${getTargetForDataStudioAddonType(DataStudioAddonType.JUPYTER_MANAGED_ENDPOINT)}${!isEmpty(addonId) ? `/${addonId}` : ""}`
}