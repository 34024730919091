import {
    SHOW_SNACK_BAR
} from "../../actions/action_types";
import {snackBarInitialState} from "src/model/commons/commons_models";


export default function (state = snackBarInitialState, action: any) {
    switch (action.type) {
        case SHOW_SNACK_BAR:
            return {
                ...state,
                message: action.payload
            }
        default:
            return state;
    }
}
