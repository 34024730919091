import {codeRepositoryInitialState} from "src/model/codeRepository/code_repository_models";
import {
    REGISTER_CODE_REPOSITORY_FAILURE,
    REGISTER_CODE_REPOSITORY_RESET,
    REGISTER_CODE_REPOSITORY_SUCCESS,
    FETCH_ALL_CODE_REPOSITORIES_FAILURE,
    FETCH_ALL_CODE_REPOSITORIES_SUCCESS,
    GET_CODE_REPOSITORY_FAILURE,
    GET_CODE_REPOSITORY_RESET,
    GET_CODE_REPOSITORY_SUCCESS,
    GET_CODE_VERSIONS_FAILURE,
    EXECUTION_GET_CODE_VERSIONS_SUCCESS,
    START_REGISTER_CODE_REPOSITORY_LOADER,
    START_FETCH_ALL_CODE_REPOSITORIES_LOADER,
    START_GET_CODE_REPOSITORY_LOADER,
    EXECUTION_START_GET_CODE_VERSIONS_LOADER,
    PRE_EXECUTION_START_GET_CODE_VERSIONS_LOADER,
    POST_EXECUTION_START_GET_CODE_VERSIONS_LOADER,
    PRE_EXECUTION_GET_CODE_VERSIONS_SUCCESS,
    POST_EXECUTION_GET_CODE_VERSIONS_SUCCESS
} from "src/control/actions/action_types";

export default function codeRepoReducer(state = codeRepositoryInitialState, action: any) {
    switch (action.type) {
        case FETCH_ALL_CODE_REPOSITORIES_SUCCESS:
            return {
                ...state,
                fetchedCodeRepositories: {
                    codeRepositories: action.payload.codeRepositories,
                    codeRepositoriesFetched: true,
                    fetchAllCodeRepositoriesSuccess: true,
                    startCodeRepositoriesLoad: false
                }
            }

        case FETCH_ALL_CODE_REPOSITORIES_FAILURE:
            return {
                ...state,
                fetchedCodeRepositories: {
                    codeRepositories: [],
                    codeRepositoriesFetched: true,
                    fetchAllCodeRepositoriesSuccess: false,
                    startCodeRepositoriesLoad: false
                }
            }
        case START_FETCH_ALL_CODE_REPOSITORIES_LOADER:
            return {
                ...state,
                fetchedCodeRepositories: {
                    codeRepositories: [],
                    codeRepositoriesFetched: false,
                    fetchAllCodeRepositoriesSuccess: false,
                    startCodeRepositoriesLoad: true
                }
            }
        case GET_CODE_REPOSITORY_SUCCESS:
            return {
                ...state,
                getCodeRepository: {
                    ...state.getCodeRepository,
                    startCodeRepositoryFetch: false,
                    getCodeRepositorySuccess: true,
                    codeRepositoryFetched: true,
                    repository: action.payload.repository
                }
            }
        case GET_CODE_REPOSITORY_FAILURE:
            return {
                ...state,
                getCodeRepository: {
                    startCodeRepositoryFetch: false,
                    getCodeRepositorySuccess: false,
                    codeRepositoryFetched: true,
                    repository: {}
                }
            }
        case START_GET_CODE_REPOSITORY_LOADER:
            return {
                ...state,
                getCodeRepository: {
                    startCodeRepositoryFetch: true,
                    getCodeRepositorySuccess: false,
                    codeRepositoryFetched: false,
                    repository: {}
                }
            }
        case GET_CODE_REPOSITORY_RESET:
            return {
                ...state,
                getCodeRepository: {
                    startCodeRepositoryFetch: false,
                    getCodeRepositorySuccess: false,
                    codeRepositoryFetched: false,
                    repository: {}
                }
            }
        case REGISTER_CODE_REPOSITORY_RESET:
            return {
                ...state,
                registerCodeRepository: {
                    ...state.registerCodeRepository,
                    requestStatus : "",
                    codeRepository: {},
                    registerRepositorySuccess: false,
                    registerRepositoryResponseFetched: false,
                    startRegisterCodeRepository: false,
                    errorMessage: ""
                }
            }
        case REGISTER_CODE_REPOSITORY_SUCCESS:
            return {
                ...state,
                registerCodeRepository: {
                    ...state.registerCodeRepository,
                    requestStatus: action.payload.requestStatus,
                    codeRepository: action.payload.codeRepository,
                    registerRepositoryResponseFetched: true,
                    registerRepositorySuccess: true,
                    startRegisterCodeRepository: false,
                    errorMessage: ""
                }
            }
        case REGISTER_CODE_REPOSITORY_FAILURE:
            return {
                ...state,
                registerCodeRepository: {
                    ...state.registerCodeRepository,
                    requestStatus : "",
                    codeRepository: {},
                    registerRepositorySuccess: false,
                    registerRepositoryResponseFetched: true,
                    startRegisterCodeRepository: false,
                    errorMessage: JSON.stringify(action.error.response.data.message)
                }
            }
        case START_REGISTER_CODE_REPOSITORY_LOADER:
            return {
                ...state,
                registerCodeRepository: {
                    ...state.registerCodeRepository,
                    requestStatus : "",
                    codeRepository: {},
                    registerRepositorySuccess: false,
                    registerRepositoryResponseFetched: false,
                    startRegisterCodeRepository: true,
                    errorMessage: ""
                }
            }
        case GET_CODE_VERSIONS_FAILURE:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    preExecution: {
                        ...state.codeVersions.preExecution,
                        startLoader: false
                    },
                    execution: {
                        ...state.codeVersions.execution,
                        startLoader: false
                    },
                    postExecution: {
                        ...state.codeVersions.postExecution,
                        startLoader: false
                    }
                }
            }
        case EXECUTION_START_GET_CODE_VERSIONS_LOADER:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    execution: {
                        ...state.codeVersions.execution,
                        startLoader: true
                    },
                }
            }
        case PRE_EXECUTION_START_GET_CODE_VERSIONS_LOADER:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    preExecution: {
                        ...state.codeVersions.preExecution,
                        startLoader: true
                    },
                }
            }
        case POST_EXECUTION_START_GET_CODE_VERSIONS_LOADER:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    postExecution: {
                        ...state.codeVersions.postExecution,
                        startLoader: true
                    },
                }
            }
        case EXECUTION_GET_CODE_VERSIONS_SUCCESS:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    execution: {
                        ...state.codeVersions.execution,
                        codeVersionsList: action.payload,
                        startLoader: false
                    }
                }
            }
        case PRE_EXECUTION_GET_CODE_VERSIONS_SUCCESS:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    preExecution: {
                        ...state.codeVersions.preExecution,
                        codeVersionsList: action.payload,
                        startLoader: false
                    }
                }
            }
        case POST_EXECUTION_GET_CODE_VERSIONS_SUCCESS:
            return {
                ...state,
                codeVersions: {
                    ...state.codeVersions,
                    postExecution: {
                        ...state.codeVersions.postExecution,
                        codeVersionsList: action.payload,
                        startLoader: false
                    }
                }
            }
        default:
            return state;
    }
}