export const FETCH_ALL_TEAMS_REQUEST = "FETCH_ALL_TEAMS_REQUEST";
export const FETCH_ALL_TEAMS_SUCCESS = "FETCH_ALL_TEAMS_SUCCESS";
export const FETCH_ALL_TEAMS_FAILURE = "FETCH_ALL_TEAMS_FAILURE";

export const FETCH_TEAM_REQUEST = "FETCH_TEAM_REQUEST";
export const FETCH_TEAM_SUCCESS = "FETCH_TEAM_SUCCESS";
export const FETCH_TEAM_FAILURE = "FETCH_TEAM_FAILURE";

export const FETCH_USERNAME_REQUEST = "FETCH_USERNAME_REQUEST";
export const FETCH_USERNAME_SUCCESS = "FETCH_USERNAME_SUCCESS";
export const FETCH_USERNAME_FAILURE = "FETCH_USERNAME_FAILURE";

export const SELECT_REGION = "SELECT_REGION";
export const SHOW_REGION = "SHOW_REGION";
export const LOAD_REGION_SUCCESS = "LOAD_REGION_SUCCESS";
export const LOAD_SUPPORTED_REGION = "LOAD_SUPPORTED_REGION";
export const REGION_LOADED = "REGION_LOADED";

export const FETCH_ALL_CONFIG_NAMESPACES_REQUEST = "FETCH_ALL_CONFIG_NAMESPACES_REQUEST";
export const FETCH_ALL_CONFIG_NAMESPACES_SUCCESS = "FETCH_ALL_CONFIG_NAMESPACES_SUCCESS";
export const FETCH_ALL_CONFIG_NAMESPACES_FAILURE = "FETCH_ALL_CONFIG_NAMESPACES_FAILURE";

export const FETCH_ALL_FEATURE_NAMESPACES_REQUEST = "FETCH_ALL_FEATURE_NAMESPACES_REQUEST";
export const FETCH_ALL_FEATURE_NAMESPACES_SUCCESS = "FETCH_ALL_FEATURE_NAMESPACES_SUCCESS";
export const FETCH_ALL_FEATURE_NAMESPACES_FAILURE = "FETCH_ALL_FEATURE_NAMESPACES_FAILURE";

export const FETCH_ALL_MODEL_NAMESPACES_REQUEST = "FETCH_ALL_MODEL_NAMESPACES_REQUEST";
export const FETCH_ALL_MODEL_NAMESPACES_SUCCESS = "FETCH_ALL_MODEL_NAMESPACES_SUCCESS";
export const FETCH_ALL_MODEL_NAMESPACES_FAILURE = "FETCH_ALL_MODEL_NAMESPACES_FAILURE";

export const LIST_DATA_STUDIO_REQUEST = "LIST_DATA_STUDIO_REQUEST";
export const LIST_DATA_STUDIO_SUCCESS = "LIST_DATA_STUDIO_SUCCESS";
export const LIST_DATA_STUDIO_FAILURE = "LIST_DATA_STUDIO_FAILURE";

export const SHOW_SNACK_BAR = "SHOW_SNACK_BAR";

export const CONFIG_FETCH_ALL_SUCCESS = "CONFIG_FETCH_ALL_SUCCESS";
export const CONFIG_FETCH_ALL_FAILURE = "CONFIG_FETCH_ALL_FAILURE";

export const CONFIG_SET_DOMAINS = "CONFIGS_SET_DOMAINS";

export const CONFIG_SET_VERSIONS = "CONFIG_SET_VERSIONS";

export const CONFIG_FETCH_REQUEST = "CONFIG_FETCH_REQUEST";
export const CONFIG_FETCH_SUCCESS = "CONFIG_FETCH_SUCCESS";
export const CONFIG_FETCH_FAILURE = "CONFIG_FETCH_FAILURE";

export const DISABLE_CONFIG_VALIDATION = "DISABLE_CONFIG_VALIDATION";

export const DATASET_FETCH_ALL_REQUEST = "DATASET_FETCH_REQUEST";
export const DATASET_FETCH_ALL_SUCCESS = "DATASET_FETCH_SUCCESS";
export const DATASET_FETCH_ALL_FAILURE = "DATASET_FETCH_FAILURE";

export const FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS = "FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS";
export const FETCH_MODEL_TRAINING_NAMESPACE_FAILURE = "FETCH_MODEL_TRAINING_NAMESPACE_FAILURE";
export const MODEL_TRAINING_NAMESPACE_NOT_FOUND = "MODEL_TRAINING_NAMESPACE_NOT_FOUND";
export const ERROR_FETCHING_MODEL_TRAINING_NAMESPACE = "ERROR_FETCHING_MODEL_TRAINING_NAMESPACE";

export const FETCH_DATA_STUDIO_INFRA_SUCCESS = "FETCH_DATA_STUDIO_INFRA_SUCCESS";
export const FETCH_DATA_STUDIO_INFRA_FAILURE = "FETCH_DATA_STUDIO_INFRA_FAILURE";
export const LIST_DATA_STUDIO_ONBOARDING_SUCCESS = "LIST_DATA_STUDIO_ONBOARDING_SUCCESS";
export const LIST_DATA_STUDIO_ONBOARDING_FAILURE = "LIST_DATA_STUDIO_ONBOARDING_FAILURE";
export const ERROR_LISTING_DATA_STUDIO_ONBOARDING = "ERROR_LISTING_DATA_STUDIO_ONBOARDING";
export const LIST_DATA_STUDIO_ADDONS_SUCCESS = "LIST_DATA_STUDIO_ADDONS_SUCCESS";
export const LIST_DATA_STUDIO_ADDONS_FAILURE = "LIST_DATA_STUDIO_ADDONS_FAILURE";
export const ERROR_LISTING_DATA_STUDIO_ADDONS = "ERROR_LISTING_DATA_STUDIO_ADDONS";
export const GET_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS = "GET_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS";
export const GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE = "GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE";
export const ERROR_GETTING_DATA_STUDIO_MANAGED_ENDPOINT = "ERROR_GETTING_DATA_STUDIO_MANAGED_ENDPOINT";
export const GET_DATA_STUDIO_ONBOARDING_SUCCESS = "GET_DATA_STUDIO_ONBOARDING_SUCCESS";
export const GET_DATA_STUDIO_ONBOARDING_FAILURE = "GET_DATA_STUDIO_ONBOARDING_FAILURE";
export const ERROR_GETTING_DATA_STUDIO_ONBOARDING = "ERROR_GETTING_DATA_STUDIO_ONBOARDING";
export const DELETE_DATA_STUDIO_ADDON_SUCCESS = "DELETE_DATA_STUDIO_ADDON_SUCCESS";
export const DELETE_DATA_STUDIO_ADDON_FAILURE = "DELETE_DATA_STUDIO_ADDON_FAILURE";
export const ERROR_DELETING_DATA_STUDIO_ADDON = "ERROR_DELETING_DATA_STUDIO_ADDON";
export const CREATE_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS = "CREATE_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS";
export const CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE = "CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE";
export const ERROR_CREATING_DATA_STUDIO_MANAGED_ENDPOINT = "ERROR_CREATING_DATA_STUDIO_MANAGED_ENDPOINT";

export const EXP_ACCOUNT_INFRA_NOT_FOUND = "EXP_ACCOUNT_INFRA_NOT_FOUND"
export const ERROR_FETCHING_EXP_ACCOUNT_INFRA = "ERROR_FETCHING_EXP_ACCOUNT_INFRA"
export const FETCH_EXP_ACCOUNT_INFRA_SUCCESS = "FETCH_EXP_ACCOUNT_INFRA_SUCCESS";
export const FETCH_EXP_ACCOUNT_INFRA_FAILURE = "FETCH_EXP_ACCOUNT_INFRA_FAILURE";

export const ERROR_FETCHING_GAMMA_ACCOUNT_INFRA = "ERROR_FETCHING_GAMMA_ACCOUNT_INFRA"
export const GAMMA_ACCOUNT_INFRA_NOT_FOUND = "GAMMA_ACCOUNT_INFRA_NOT_FOUND"
export const FETCH_GAMMA_ACCOUNT_INFRA_SUCCESS = "FETCH_GAMMA_ACCOUNT_INFRA_SUCCESS";
export const FETCH_GAMMA_ACCOUNT_INFRA_FAILURE = "FETCH_GAMMA_ACCOUNT_INFRA_FAILURE";

export const ERROR_FETCHING_PROD_ACCOUNT_INFRA = "ERROR_FETCHING_PROD_ACCOUNT_INFRA"
export const PROD_ACCOUNT_INFRA_NOT_FOUND = "PROD_ACCOUNT_INFRA_NOT_FOUND"
export const FETCH_PROD_ACCOUNT_INFRA_SUCCESS = "FETCH_PROD_ACCOUNT_INFRA_SUCCESS";
export const FETCH_PROD_ACCOUNT_INFRA_FAILURE = "FETCH_PROD_ACCOUNT_INFRA_FAILURE";

export const RUNWAY_NOT_FOUND = "RUNWAY_NOT_FOUND";
export const FETCH_RUNWAY_SUCCESS = "FETCH_RUNWAY_SUCCESS";
export const FETCH_RUNWAY_FAILURE = "FETCH_RUNWAY_FAILURE";
export const ERROR_FETCHING_RUNWAY = "ERROR_FETCHING_RUNWAY"

export const CREATE_EXPERIMENT_SUCCESS = "CREATE_EXPERIMENT_SUCCESS";
export const CREATE_EXPERIMENT_FAILURE = "CREATE_EXPERIMENT_FAILURE";
export const UPDATE_CREATE_EXPERIMENT_ON_SUCCESS = "UPDATE_CREATE_EXPERIMENT_DISPLAY_ON_SUCCESS";
export const UPDATE_CREATE_EXPERIMENT_ON_FAILURE = "UPDATE_CREATE_EXPERIMENT_DISPLAY_ON_FAILURE";
export const ERROR_CREATING_EXPERIMENT = "ERROR_CREATING_EXPERIMENT";

export const START_CREATE_EXPERIMENT_LOADER = "START_CREATE_EXPERIMENT_LOADER";
export const FETCH_EXPERIMENT_SUCCESS = "FETCH_EXPERIMENT_SUCCESS";
export const FETCH_EXPERIMENT_FAILURE = "FETCH_EXPERIMENT_FAILURE";
export const ERROR_FETCHING_EXPERIMENT = "ERROR_FETCHING_EXPERIMENT";
export const UPDATE_EXPERIMENT_SUCCESS = "UPDATE_EXPERIMENT_SUCCESS";
export const UPDATE_EXPERIMENT_FAILURE = "UPDATE_EXPERIMENT_FAILURE";
export const UPDATE_EXPERIMENT_FLOW_STARTED = "UPDATE_EXPERIMENT_FLOW_STARTED";
export const START_UPDATE_EXPERIMENT_LOADER = "START_UPDATE_EXPERIMENT_LOADER";

export const FETCH_ALL_EXPERIMENTS_SUCCESS = "FETCH_ALL_EXPERIMENTS_SUCCESS";
export const FETCH_ALL_EXPERIMENTS_FAILURE = "FETCH_ALL_EXPERIMENTS_FAILURE";
export const ERROR_FETCHING_ALL_EXPERIMENTS = "ERROR_FETCHING_ALL_EXPERIMENTS";

export const FETCH_CLUSTER_STATUS_SUCCESS = "FETCH_CLUSTER_STATUS_SUCCESS";
export const FETCH_CLUSTER_STATUS_FAILURE = "FETCH_CLUSTER_STATUS_FAILURE";
export const ERROR_FETCHING_CLUSTER_STATUS = "ERROR_FETCHING_CLUSTER_STATUS";

export const RESTART_NOTEBOOK_SUCCESS = "RESTART_NOTEBOOK_SUCCESS";
export const RESTART_NOTEBOOK_FAILURE = "RESTART_NOTEBOOK_FAILURE";
export const ERROR_RESTARTING_NOTEBOOK = "ERROR_RESTARTING_NOTEBOOK";

export const DELETE_NOTEBOOK_SUCCESS = "DELETE_NOTEBOOK_SUCCESS";
export const DELETE_NOTEBOOK_FAILURE = "DELETE_NOTEBOOK_FAILURE";
export const ERROR_DELETING_NOTEBOOK = "ERROR_DELETING_NOTEBOOK";

export const SET_EXPERIMENT_OPERATION_STARTED = "SET_OPERATION_STARTED";
export const FETCH_CLUSTER_STATUS_INIT = "FETCH_CLUSTER_STATUS_INIT"

export const LIST_EXPERIMENTS_LOADER = "LIST_EXPERIMENTS_LOADER";

export const CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS = "CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS";
export const CREATE_MODEL_TRAINING_NAMESPACE_FAILURE = "CREATE_MODEL_TRAINING_NAMESPACE_FAILURE";
export const ERROR_CREATING_MODEL_TRAINING_NAMESPACE = "ERROR_CREATING_MODEL_TRAINING_NAMESPACE";

export const START_UPDATE_NAMESPACE_LOADER = "START_UPDATE_NAMESPACE_LOADER";
export const UPDATE_MODEL_TRAINING_NAMESPACE_SUCCESS = "UPDATE_MODEL_TRAINING_NAMESPACE_SUCCESS";
export const UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE = "UPDATE_MODEL_TRAINING_NAMESPACE_FAILURE";
export const ERROR_UPDATING_MODEL_TRAINING_NAMESPACE = "ERROR_UPDATING_MODEL_TRAINING_NAMESPACE";

export const START_REGISTER_CODE_PACKAGE_LOADER = "START_REGISTER_CODE_PACKAGE_LOADER";
export const LIST_PRE_TRAIN_CODE_VERSIONS_SUCCESS = "LIST_PRE_TRAIN_CODE_VERSIONS_SUCCESS";
export const LIST_POST_TRAIN_CODE_VERSIONS_SUCCESS = "LIST_POST_TRAIN_CODE_VERSIONS_SUCCESS";
export const LIST_TRAIN_CODE_VERSIONS_SUCCESS = "LIST_TRAIN_CODE_VERSIONS_SUCCESS";
export const START_FETCH_CODE_VERSIONS_PRE_TRAIN_LOADER = "START_FETCH_CODE_VERSIONS_PRE_TRAIN_LOADER";
export const START_FETCH_CODE_VERSIONS_TRAIN_LOADER = "START_FETCH_CODE_VERSIONS_TRAIN_LOADER";
export const START_FETCH_CODE_VERSIONS_POST_TRAIN_LOADER = "START_FETCH_CODE_VERSIONS_POST_TRAIN_LOADER";
export const ERROR_IN_FETCHING_CODE_VERSION = "ERROR_IN_FETCHING_CODE_VERSION";
export const LIST_CODE_VERSIONS_FAILURE = "LIST_CODE_VERSIONS_FAILURE";
export const ERROR_REGISTERING_CODE_REPOSITORY = "ERROR_REGISTER_CODE_REPOSITORY";

export const CREATE_RUNWAY_SUCCESS = "CREATE_RUNWAY_SUCCESS";
export const CREATE_RUNWAY_FAILURE = "CREATE_RUNWAY_FAILURE";
export const ERROR_CREATING_RUNWAY = "ERROR_CREATING_RUNWAY";
export const START_CREATE_NAMESPACE_LOADER = "START_CREATE_NAMESPACE_LOADER";
export const START_CREATE_RUNWAY_LOADER = "START_CREATE_RUNWAY_LOADER";

export const FETCH_ALL_RUNWAYS_SUCCESS = "FETCH_ALL_RUNWAYS_SUCCESS";
export const FETCH_ALL_RUNWAYS_FAILURE = "FETCH_ALL_RUNWAYS_FAILURE";
export const ERROR_FETCHING_ALL_RUNWAYS = "ERROR_FETCHING_ALL_RUNWAYS";

export const START_FETCH_TRAINING_IMAGES_LOADER = "START_FETCH_TRAINING_IMAGES_LOADER";
export const FETCH_TRAINING_IMAGES_SUCCESS = "FETCH_TRAINING_IMAGES";
export const FETCH_TRAINING_IMAGES_FAILURE = "FETCH_TRAINING_IMAGES_FAILURE";
export const ERROR_FETCHING_TRAINING_IMAGES = "ERROR_FETCHING_TRAINING_IMAGES";

export const START_UPDATE_RUNWAY_LOADER = "START_UPDATE_RUNWAY_LOADER"
export const UPDATE_RUNWAY_SUCCESS = "UPDATE_RUNWAY_SUCCESS";
export const UPDATE_RUNWAY_FAILURE = "UPDATE_RUNWAY_FAILURE";

export const ERROR_UPDATE_RUNWAY = "ERROR_UPDATE_RUNWAY";
export const ERROR_UPDATE_RUNWAY_STATUS = "ERROR_UPDATE_RUNWAY_STATUS";

export const UPDATE_LATEST_VERSION_SUCCESS = "UPDATE_LATEST_VERSION_SUCCESS";

export const START_UPDATE_RUNWAY_STATUS = "START_UPDATE_RUNWAY_STATUS";
export const UPDATE_RUNWAY_STATUS_SUCCESS = "UPDATE_RUNWAY_STATUS_SUCCESS";
export const UPDATE_RUNWAY_STATUS_FAILURE = "UPDATE_RUNWAY_STATUS_FAILURE";

export const START_LOAD_RUNWAY_EXECUTIONS = "START_LOAD_RUNWAY_EXECUTIONS";
export const LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS = "LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS";
export const LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE = "LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE";
export const DISABLE_RUNWAY_EXECUTIONS_LOAD_MORE = "DISABLE_RUNWAY_EXECUTIONS_LOAD_MORE";
export const ERROR_LOADING_EXECUTIONS = "ERROR_LOADING_EXECUTIONS";

export const START_LOAD_EXECUTION_METRICS = "START_LOAD_EXECUTION_METRICS";
export const LOAD_EXECUTION_METRICS_SUCCESS = "LOAD_EXECUTION_METRICS_SUCCESS";
export const LOAD_EXECUTION_METRICS_FAILURE = "LOAD_EXECUTION_METRICS_FAILURE";
export const ERROR_LOADING_METRICS = "ERROR_LOADING_METRICS";

export const CREATE_CONFIG_REVIEW_SUCCESS = "CREATE_CONFIG_REVIEW_SUCCESS"
export const CREATE_CONFIG_REVIEW_FAILURE = "CREATE_CONFIG_REVIEW_FAILURE"
export const REVIEW_DATA_FETCH_SUCCESS = "REVIEW_DATA_FETCH_SUCCESS";
export const REVIEW_DATA_FETCH_FAILURE = "REVIEW_DATA_FETCH_FAILURE";
export const REVIEW_FETCH_START = "REVIEW_FETCH_START";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";
export const UPDATE_REVIEW_FAILURE = "UPDATE_REVIEW_FAILURE";
export const PUBLISH_REVIEW_SUCCESS = "PUBLISH_REVIEW_SUCCESS";
export const PUBLISH_REVIEW_FAILURE = "PUBLISH_REVIEW_FAILURE";
export const SAVE_REVIEW_SUCCESS = "SAVE_REVIEW_SUCCESS";
export const SAVE_REVIEW_FAILURE = "SAVE_REVIEW_FAILURE";
export const MERGE_REVIEW_SUCCESS = "MERGE_REVIEW_SUCCESS";
export const MERGE_REVIEW_FAILURE = "MERGE_REVIEW_FAILURE";
export const DISCARD_REVIEW_SUCCESS = "DISCARD_REVIEW_SUCCESS";
export const DISCARD_REVIEW_FAILURE = "DISCARD_REVIEW_FAILURE";
export const APPROVE_REVIEW_SUCCESS = "APPROVE_REVIEW_SUCCESS";
export const APPROVE_REVIEW_FAILURE = "APPROVE_REVIEW_FAILURE";
export const REVOKE_APPROVAL_SUCCESS = "REVOKE_APPROVAL_SUCCESS";
export const REVOKE_APPROVAL_FAILURE = "REVOKE_APPROVAL_FAILURE";
export const REVIEW_ID_NOT_FOUND = "REVIEW_ID_NOT_FOUND"
export const ERROR_WHILE_FETCHING_REVIEW_DETAILS = "ERROR_WHILE_FETCHING_REVIEW_DETAILS"
export const ERROR_WHILE_UPDATING_REVIEW = "ERROR_WHILE_UPDATING_REVIEW"
export const ERROR_WHILE_PUBLISHING_REVIEW = "ERROR_WHILE_PUBLISHING_REVIEW"
export const ERROR_WHILE_SAVING_REVIEW = "ERROR_WHILE_SAVING_REVIEW"
export const ERROR_WHILE_MERGING_REVIEW = "ERROR_WHILE_MERGING_REVIEW"
export const ERROR_WHILE_DISCARDING_REVIEW = "ERROR_WHILE_DISCARDING_REVIEW"
export const ERROR_WHILE_APPROVING_REVIEW = "ERROR_WHILE_APPROVING_REVIEW"
export const ERROR_WHILE_REVOKING_REVIEW = "ERROR_WHILE_REVOKING_REVIEW"
export const ERROR_WHILE_CREATING_CONFIG_REVIEW = "ERROR_WHILE_CREATING_CONFIG_REVIEW"

export const ERROR_UPDATING_CONFIG_METADATA = "ERROR_UPDATING_CONFIG_METADATA";
export const UPDATE_CONFIG_METADATA_SUCCESS = "UPDATE_CONFIG_METADATA_SUCCESS";
export const UPDATE_CONFIG_METADATA_FAILURE = "UPDATE_CONFIG_METADATA_FAILURE";

export const ERROR_FETCHING_CONFIG_METADATA = "ERROR_UPDATING_CONFIG_METADATA";
export const FETCH_CONFIG_METADATA_SUCCESS = "FETCH_CONFIG_METADATA_SUCCESS";
export const FETCH_CONFIG_METADATA_FAILURE = "FETCH_CONFIG_METADATA_FAILURE";

export const ERROR_FETCHING_CONFIG_BODY = "ERROR_FETCHING_CONFIG_BODY";
export const START_UPDATE_CONFIG_METADATA = "START_UPDATE_CONFIG_METADATA";

export const FETCH_CONFIG_VALIDATION_START_LOADER = "FETCH_CONFIG_VALIDATION_START_LOADER";
export const ERROR_FETCHING_CONFIG_VALIDATION_INFO = "ERROR_FETCHING_CONFIG_VALIDATION_INFO";
export const FETCH_CONFIG_VALIDATION_INFO_SUCCESS = "FETCH_CONFIG_VALIDATION_INFO_SUCCESS";
export const FETCH_CONFIG_VALIDATION_INFO_FAILURE = "FETCH_CONFIG_VALIDATION_INFO_FAILURE";

export const START_CONFIG_VALIDATION_FLOW_LOADER = "START_CONFIG_VALIDATION_FLOW_LOADER";
export const ERROR_STARTING_CONFIG_VALIDATION_FLOW = "ERROR_STARTING_CONFIG_VALIDATION_FLOW";
export const START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS = "START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS";
export const START_CONFIG_VALIDATION_FLOW_INFO_FAILURE = "START_CONFIG_VALIDATION_FLOW_INFO_FAILURE";

export const START_SPINNER_LOADER = "START_SPINNER_LOADER"
export const STOP_SPINNER_LOADER = "STOP_SPINNER_LOADER";

export const START_JOB_LOADER = "START_JOB_LOADER";
export const CREATE_JOB_SUCCESS = "CREATE_JOB_SUCCESS";
export const CREATE_JOB_FAILURE = "CREATE_JOB_FAILURE";
export const ERROR_CREATING_JOB = "ERROR_CREATING_JOB";
export const FETCH_JOB_SUCCESS = "FETCH_JOB_SUCCESS";
export const FETCH_JOB_FAILURE = "FETCH_JOB_FAILURE";
export const ERROR_FETCHING_JOB = "ERROR_FETCHING_JOB";
export const UPDATE_JOB_SUCCESS = "UPDATE_JOB_SUCCESS";
export const UPDATE_JOB_FAILURE = "UPDATE_JOB_FAILURE";
export const ERROR_UPDATING_JOB = "ERROR_UPDATING_JOB";
export const LIST_JOBS_SUCCESS = "LIST_JOBS_SUCCESS";
export const LIST_JOBS_FAILURE = "LIST_JOBS_FAILURE";
export const ERROR_LISTING_JOBS = "ERROR_LISTING_JOBS";
export const START_LOAD_JOB_EXECUTIONS = "START_LOAD_JOB_EXECUTIONS";
export const ERROR_LOADING_JOB_EXECUTIONS = "ERROR_LOADING_JOB_EXECUTIONS";
export const LOAD_JOB_EXECUTIONS_STATUS_SUCCESS = "LOAD_JOB_EXECUTIONS_STATUS_SUCCESS";
export const LOAD_JOB_EXECUTIONS_STATUS_FAILURE = "LOAD_JOB_EXECUTIONS_STATUS_FAILURE";
export const DISABLE_JOB_EXECUTIONS_LOAD_MORE = "DISABLE_JOB_EXECUTIONS_LOAD_MORE";
export const FETCH_JOB_LATEST_VERSION_DATA_SUCCESS = "FETCH_JOB_LATEST_VERSION_DATA_SUCCESS";

export const START_JOB_TEMPLATE_LOADER = "START_JOB_TEMPLATE_LOADER";
export const CREATE_JOB_TEMPLATE_SUCCESS = "CREATE_JOB_TEMPLATE_SUCCESS";
export const CREATE_JOB_TEMPLATE_FAILURE = "CREATE_JOB_TEMPLATE_FAILURE";
export const ERROR_CREATING_JOB_TEMPLATE = "ERROR_CREATING_JOB_TEMPLATE";
export const FETCH_JOB_TEMPLATE_SUCCESS = "FETCH_JOB_TEMPLATE_SUCCESS";
export const FETCH_JOB_TEMPLATE_FAILURE = "FETCH_JOB_TEMPLATE_FAILURE";
export const ERROR_FETCHING_JOB_TEMPLATE = "ERROR_FETCHING_JOB_TEMPLATE";
export const UPDATE_JOB_TEMPLATE_SUCCESS = "UPDATE_JOB_TEMPLATE_SUCCESS";
export const UPDATE_JOB_TEMPLATE_FAILURE = "UPDATE_JOB_TEMPLATE_FAILURE";
export const ERROR_UPDATING_JOB_TEMPLATE = "ERROR_UPDATING_JOB_TEMPLATE";
export const LIST_JOB_TEMPLATES_SUCCESS = "LIST_JOB_TEMPLATES_SUCCESS";
export const LIST_JOB_TEMPLATES_FAILURE = "LIST_JOB_TEMPLATES_FAILURE";
export const ERROR_LISTING_JOB_TEMPLATES = "ERROR_LISTING_JOB_TEMPLATES";
export const FETCH_JOB_TEMPLATE_LATEST_VERSION_DATA_SUCCESS = "FETCH_JOB_TEMPLATE_LATEST_VERSION_DATA_SUCCESS";

export const START_V2_RUNWAY_LOADER = "START_V2_RUNWAY_LOADER";
export const OPEN_RUNWAY_JOB_POPUP = "OPEN_RUNWAY_JOB_POPUP";
export const CLOSE_RUNWAY_JOB_POPUP = "CLOSE_RUNWAY_JOB_POPUP";
export const CREATE_V2_RUNWAY_SUCCESS = "CREATE_V2_RUNWAY_SUCCESS";
export const CREATE_V2_RUNWAY_FAILURE = "CREATE_V2_RUNWAY_FAILURE";
export const ERROR_CREATING_V2_RUNWAY = "ERROR_CREATING_V2_RUNWAY";
export const FETCH_V2_RUNWAY_SUCCESS = "FETCH_V2_RUNWAY_SUCCESS";
export const FETCH_V2_RUNWAY_FAILURE = "FETCH_V2_RUNWAY_FAILURE";
export const ERROR_FETCHING_V2_RUNWAY = "ERROR_FETCHING_V2_RUNWAY";
export const UPDATE_V2_RUNWAY_SUCCESS = "UPDATE_V2_RUNWAY_SUCCESS";
export const UPDATE_V2_RUNWAY_FAILURE = "UPDATE_V2_RUNWAY_FAILURE";
export const ERROR_UPDATING_V2_RUNWAY = "ERROR_UPDATING_V2_RUNWAY";
export const LIST_V2_RUNWAYS_SUCCESS = "LIST_V2_RUNWAYS_SUCCESS";
export const LIST_V2_RUNWAYS_FAILURE = "LIST_V2_RUNWAYS_FAILURE";
export const ERROR_LISTING_V2_RUNWAYS = "ERROR_LISTING_V2_RUNWAYS";
export const CREATE_RUNWAY_JOB_SUCCESS = "CREATE_RUNWAY_JOB_SUCCESS";
export const CREATE_RUNWAY_JOB_FAILURE = "CREATE_RUNWAY_JOB_FAILURE";
export const ERROR_CREATING_RUNWAY_JOB = "ERROR_CREATING_RUNWAY_JOB";
export const UPDATE_JOB_VERSION_IN_DAG_SUCCESS = "UPDATE_JOB_VERSION_IN_DAG_SUCCESS";
export const UPDATE_RUNWAY_JOB_FAILURE = "UPDATE_RUNWAY_JOB_FAILURE";
export const ERROR_UPDATING_RUNWAY_JOB = "ERROR_UPDATING_RUNWAY_JOB";
export const DELETE_JOB_NODE = "DELETE_JOB_NODE";
export const UPDATE_V2_RUNWAY_STATUS_SUCCESS = "UPDATE_V2_RUNWAY_STATUS_SUCCESS";
export const UPDATE_V2_RUNWAY_STATUS_FAILURE = "UPDATE_V2_RUNWAY_STATUS_FAILURE";
export const ERROR_UPDATING_V2_RUNWAY_STATUS = "ERROR_UPDATING_V2_RUNWAY_STATUS";
export const START_LOAD_V2_RUNWAYS_EXECUTIONS = "START_LOAD_V2_RUNWAYS_EXECUTIONS";
export const ERROR_LOADING_V2_RUNWAYS_EXECUTIONS = "ERROR_LOADING_V2_RUNWAYS_EXECUTIONS"; 
export const LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS = "LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS"; 
export const LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE = "LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE"; 
export const START_LOAD_V2_RUNWAYS_EXECUTION_METRICS = "START_LOAD_V2_RUNWAYS_EXECUTION_METRICS"; 
export const LOAD_V2_RUNWAYS_EXECUTION_METRICS_SUCCESS = "LOAD_V2_RUNWAYS_EXECUTION_METRICS_SUCCESS"; 
export const LOAD_V2_RUNWAYS_EXECUTION_METRICS_FAILURE = "LOAD_V2_RUNWAYS_EXECUTION_METRICS_FAILURE"; 
export const DISABLE_V2_RUNWAYS_EXECUTIONS_LOAD_MORE = "DISABLE_V2_RUNWAYS_EXECUTIONS_LOAD_MORE"
export const FETCH_RUNWAY_LATEST_VERSION_DATA_SUCCESS = "FETCH_RUNWAY_LATEST_VERSION_DATA_SUCCESS";

export const FETCH_ALL_CODE_REPOSITORIES_SUCCESS = "FETCH_ALL_CODE_REPOSITORIES_SUCCESS"
export const FETCH_ALL_CODE_REPOSITORIES_FAILURE = "FETCH_ALL_CODE_REPOSITORIES_FAILURE"
export const START_FETCH_ALL_CODE_REPOSITORIES_LOADER = "START_FETCH_ALL_CODE_REPOSITORIES_LOADER"

export const REGISTER_CODE_REPOSITORY_SUCCESS = "REGISTER_CODE_REPOSITORY_SUCCESS"
export const REGISTER_CODE_REPOSITORY_FAILURE = "REGISTER_CODE_REPOSITORY_FAILURE"
export const START_REGISTER_CODE_REPOSITORY_LOADER = "START_REGISTER_CODE_REPOSITORY_LOADER"
export const REGISTER_CODE_REPOSITORY_RESET = "REGISTER_CODE_REPOSITORY_RESET"

export const GET_CODE_REPOSITORY_RESET = "GET_CODE_REPOSITORY_RESET"
export const GET_CODE_REPOSITORY_SUCCESS = "GET_CODE_REPOSITORY_SUCCESS"
export const GET_CODE_REPOSITORY_FAILURE = "GET_CODE_REPOSITORY_FAILURE"
export const START_GET_CODE_REPOSITORY_LOADER = "START_GET_CODE_REPOSITORY_LOADER"

export const GET_CODE_VERSIONS_FAILURE = "GET_CODE_VERSIONS_FAILURE"

export const EXECUTION_GET_CODE_VERSIONS_SUCCESS = "EXECUTION_GET_CODE_VERSIONS_SUCCESS"
export const EXECUTION_START_GET_CODE_VERSIONS_LOADER = "EXECUTION_START_GET_CODE_VERSIONS_LOADER"

export const PRE_EXECUTION_GET_CODE_VERSIONS_SUCCESS = "PRE_EXECUTION_GET_CODE_VERSIONS_SUCCESS"
export const PRE_EXECUTION_START_GET_CODE_VERSIONS_LOADER = "PRE_EXECUTION_START_GET_CODE_VERSIONS_LOADER"

export const POST_EXECUTION_GET_CODE_VERSIONS_SUCCESS = "POST_EXECUTION_GET_CODE_VERSIONS_SUCCESS"
export const POST_EXECUTION_START_GET_CODE_VERSIONS_LOADER = "POST_EXECUTION_START_GET_CODE_VERSIONS_LOADER"

export const REFRESH_STATES = "REFRESH_STATES";