import React from "react";
import {TableBody, TableRow} from "src/view/style/table_styles";
import {PopUp, VKey, VTable, VValue} from "src/view/style/view_config_styles";
import {formatDate} from "src/utils/common_utils";
import {Dataset, SetFunction} from "src/view/dataset/dataset_constants";

export interface ViewDatasetProps {
    setVisiblity: SetFunction<boolean>
    visibility: boolean
    dataset: Dataset | null
}

export default function ViewDataset(props: ViewDatasetProps) {

    return (
        <>
            <PopUp visible={props.visibility}
                   onOpen={() => props.setVisiblity(true)}
                   onClose={() => props.setVisiblity(false)}
            >
                <span slot={"title"}>Viewing Dataset: {props.dataset?.datasetName}</span>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className="col-12">
                            <VTable>
                                <TableBody>
                                    <TableRow>
                                        <VKey>Dataset Name</VKey>
                                        <VValue>{props.dataset?.datasetName}</VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>S3 Path</VKey>
                                        <VValue>{props.dataset?.s3Path}</VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Account ID</VKey>
                                        <VValue>{props.dataset?.accountId}</VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Encryption KMS ARN</VKey>
                                        <VValue>{props.dataset?.encryptionKMSArn}</VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Created By</VKey>
                                        <VValue>{props.dataset?.createdBy}</VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Creation Time</VKey>
                                        <VValue>{props.dataset?.creationTime && formatDate(props.dataset.creationTime)}</VValue>
                                    </TableRow>
                                </TableBody>
                            </VTable>
                        </div>
                    </div>
                </div>
            </PopUp>
        </>
    );
}