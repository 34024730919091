import {colorWhite, NavHomeIcon} from "src/view/style/icons";
import React from "react";
import {NavItem, onClickParams} from "./nav_item_commons";

const HomeNavItem: NavItem = {
    displayName: "Home",
    icon: <NavHomeIcon style={colorWhite}/>,
    hasSubNav: false,
    labelOnClick: (props: onClickParams) => {
        let history = props.history;
        history.push("/");
    }
}

export default HomeNavItem;