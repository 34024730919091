import {LATEST_CODE_VERSION} from "src/constant";
import {fetchTeam} from "src/control/actions/commons/team_actions";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

export const setIfMounted = (isMounted: boolean, setFunction: any, value: any) => {
    if (isMounted) {
        setFunction(value);
    }
}
// Two different types of dates (string, long) are present due to the way dates are currently stored in backend.
// Once the backend dates are using the same format, we can remove one of these and format it in a single way.

const dateFormatterOptions = {
    timeZone: "Asia/Kolkata", timeZoneName: "short", hour12: true,
    year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit"
}
const dateLocale = 'en-GB' // used for dd-mm-yyyy format
const dateFormatter = new Intl.DateTimeFormat(dateLocale, dateFormatterOptions)

export function formatDate(date: string) {
    return (/^\d+$/.test(date)) ? // check if string is numeric
        dateFormatter.format(new Date(Number(date))).toUpperCase() :
        dateFormatter.format(new Date(date)).toUpperCase()
}

// Validations

export const isEmptyObject = (value: any) => {
    return value == null || typeof value == "object" && Object.keys(value).length == 0;
}

export const isEmpty = (value: string | null | undefined | any) => {
    return value == null || value.length == 0 || value == '' || value === undefined;
}

export function isValidAWSAccountId(awsAccountId: string) {
    return !isEmpty(awsAccountId) && awsAccountId.length == 12 && !isNaN(Number(awsAccountId))
}

export function isValidMcmOrSev2Link(mcmOrSev2Link: string) {
    let valid: boolean = true
    valid = !isEmpty(mcmOrSev2Link) &&
        (mcmOrSev2Link.includes('t.corp.amazon.com/') ||
            /mcm.amazon.com\/cms\/MCM-\d+$/.test(mcmOrSev2Link) ||
            /mcm.amazon.com\/MCM-\d+$/.test(mcmOrSev2Link)
        )
    return valid
}

export function isValidLambdaArn(lambdaArn: string) {
    let valid: boolean = true
    valid = isEmpty(lambdaArn) ||
        (/(arn:aws:lambda:[a-zA-Z0-9-]+:\d{12}:function:[a-zA-Z0-9-_]+)/.test(lambdaArn))
    return valid
}


export function isValidSemanticValidatorLambdaName(lambdaName: string) {
    let valid: boolean = true
    valid = isEmpty(lambdaName) ||
        (/(^[a-zA-Z][a-zA-Z0-9]*$)/.test(lambdaName))
    return valid
}

// TODO use AppConfig to load values and remove this method.
export function isProdDomain() {
    let isProd: boolean = true
    const nonProdChecks: Array<string> = ['beta', 'gamma', '0.0.0.0', 'localhost']
    nonProdChecks.forEach((urlSlice) => {
        if (window.location.href.includes(urlSlice)) isProd = false
    })
    return isProd
}

export function isValidS3Path(path: string) {

    const AmazonS3URI = require("amazon-s3-uri");

    try {
        AmazonS3URI(path);
        return true;
    } catch (error) {
        console.log("Invalid S3 URL: ", error)
        return false;
    }
}

export function isValidKMSArn(arn: string) {
    return !isEmpty(arn) &&
        (/arn:aws:kms:[\w-]+:\d{12}:key\/([\w-]+)/.test(arn))
}

export function extractVersionIdFromSignedUrl(signedUrl: string) {
    if (signedUrl === LATEST_CODE_VERSION) {
        return LATEST_CODE_VERSION
    }
    const versionIdRegex = new RegExp("versionId=(.*)&X-Amz-Security-Token", "g")
    const match = versionIdRegex.exec(signedUrl)
    return match !== null ? match[1] : null
}

export const getValueOrEmptyPlaceholder = (value: any) => {
    return !isEmpty(value) ? value : "-";
};

export const getValueOrDefaultJsonString = (jsonString: string) => {
    return isEmpty(jsonString) ? "{}" : jsonString;
}

export const getEmptyStringValue = (value: string) => {
    return isEmpty(value) && value != "0" ? "" : value;
};

export const getFromLocalStorageAndSetTeam = (dispatch: any, isAdmin?: boolean) => {
    const team = (isAdmin === true) ? window.localStorage.getItem("ADMIN_TEAM") :
        window.localStorage.getItem("SELECTED_TEAM") ;
    if (team != null) {
        dispatch(
            fetchTeam(
                team,
                null,
                (error: any) => {
                    dispatch(showSnackBar(error.message))
                })
        )
    }
}

export const isValidIAMRoleArn = (arn: string) => {
    const arnRegex = new RegExp("^arn:aws:iam::[0-9]{12}:role\\/[\\w+=,.@-]+$")
    return arnRegex.test(arn)
}

export const isValidEMRReleaseLabel = (releaseLabel: string) => {
    const arnRegex = new RegExp("^emr-[0-9]{1,2}\..*$")
    return arnRegex.test(releaseLabel)
}
