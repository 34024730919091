import {useDispatch, useSelector} from "react-redux";
import {
    experimentationAccountInfraSelector,
    gammaAccountInfraSelector,
    namespaceSelector,
    prodAccountInfraSelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {dataStudioInfraSelector} from "src/control/selectors/notebooks/data_studio_selectors"
import React, {useEffect, useRef} from "react";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {Stages} from "src/view/modelTraining/constants";
import {useParams} from "react-router-dom";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {loadDataStudioInfra} from "src/control/actions/notebooks/data_studio_actions";
import {
    KatAlert,
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow,
} from "@amzn/katal-react";
import {SubTabs, Tab, Tabs} from "src/view/style/header_styles";
import {Typography} from "@material-ui/core";
import Collapsible from "src/view/commons/collapsible";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {isEmpty} from "src/utils/common_utils";
import {DATA_STUDIO_ONBOARDING_SIM, EXPRESSO_ONBOARDING_SIM} from "src/external_links";
import {VKey, VTable, VTableHeader, VValue} from "src/view/style/view_config_styles";
import {TableBody, TableCell, TableHead, TableHeadRow, TableRow} from "src/view/style/table_styles";
import {TabStyle} from "src/view/style/common_styles";
import {FLOWS, getFlowFromURL} from "src/utils/url_utils";

const ViewNamespace = () => {
    const params: any = useParams();
    const dispatch = useDispatch();
    const flow = getFlowFromURL(window.location.pathname)

    let userId = useSelector(usernameSelector);
    let dataStudioInfra = useSelector(dataStudioInfraSelector)
    let experimentationAccountInfraData = useSelector(experimentationAccountInfraSelector);
    let gammaAccountInfraData = useSelector(gammaAccountInfraSelector);
    let prodAccountInfraData = useSelector(prodAccountInfraSelector);
    let fetchedNamespace = useSelector(namespaceSelector)

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(
                params.teamId,
                params.namespaceId,
                [
                    Stages.EXPERIMENTATION, Stages.GAMMA, Stages.PROD
                ]
            ))
        }
    }, [userId, params.teamId, params.namespaceId]);

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadDataStudioInfra(fetchedNamespace.namespaceData.accountTypeMap.experimentation))
        }
    }, [fetchedNamespace]);

    const getInfraData = (accountType: Stages) => {
        switch (accountType) {
            case Stages.EXPERIMENTATION:
                return experimentationAccountInfraData.infraData;
            case Stages.GAMMA:
                return gammaAccountInfraData.infraData;
            case Stages.PROD:
                return prodAccountInfraData.infraData;
            default:
                throw Error("Invalid Account Type")
        }
    }

    const getRow = (exportId: string, value: string) => {
        return (<KatTableRow>
            <KatTableCell>
                {exportId}
            </KatTableCell>
            <KatTableCell>
                {value}
            </KatTableCell>
        </KatTableRow>)
    }

    const getKeyValueTable = (columns: string[], rowsMap: any) => {
        return (
            <KatTable>
                <KatTableHead>
                    <KatTableRow>
                        {
                            columns.map((column) => {
                                return (<KatTableCell>{column}</KatTableCell>)
                            })
                        }
                    </KatTableRow>
                </KatTableHead>
                <KatTableBody>
                    {
                        !isEmpty(rowsMap) ? Object.keys(rowsMap).map((row) => {
                            return getRow(row, rowsMap[row])
                        }) : <KatSpinner></KatSpinner>
                    }
                </KatTableBody>
            </KatTable>)
    }

    const getExpressoTab = (accountType: Stages, stackName: string) => {
        let infraExports = getInfraData(accountType)?.infraExports;
        return (<Tab label={stackName} tabId={stackName}>
            <div style={TabStyle}>
                {
                    !isEmpty(infraExports) && Object.keys(infraExports).length != 0 ?
                        getKeyValueTable(["ExportId", "Value"], infraExports) :
                        <KatAlert variant={"warning"} persistent={true}>
                            It seems your account is not onboarded to Expresso to leverage native SM solutions. To
                            onboard, click <a href={EXPRESSO_ONBOARDING_SIM}>here</a> to raise an onboarding SIM
                        </KatAlert>
                }
            </div>
        </Tab>)
    }

    const getDataStudioTable = (dataStudioInfraData: any) => {
        return (
            <div style={TabStyle}>
                {
                    !isEmpty(dataStudioInfraData) ?
                        <Collapsible
                            label={"Studio Name : " + (!isEmpty(dataStudioInfraData) ? dataStudioInfraData.studioName : "")}
                            expanded={true}>
                            {getKeyValueTable(["Key", "Value"], dataStudioInfraData.infra)}
                        </Collapsible> : <KatSpinner/>
                }
            </div>
        )
    }

    const getDataStudioTab = () => {
        return (
            <Tab label={"Data Studio"} tabId={"DataStudio"}>
                {
                    !isEmpty(dataStudioInfra) ?
                        dataStudioInfra.map(getDataStudioTable) :
                        <div style={TabStyle}>
                            <KatAlert variant={"warning"} persistent={true}>
                                It seems your account is not onboarded to Expresso to leverage native SM solutions. To
                                onboard, click <a href={DATA_STUDIO_ONBOARDING_SIM}>here</a> to raise an onboarding SIM
                            </KatAlert>
                        </div>
                }
            </Tab>
        )
    }

    const getFailedStacksTab = (accountId: string, accountType: Stages) => {
        let failedStacks = getInfraData(accountType)?.failedStacks;
        return (<Tab label={"Failed Stacks"} tabId={"FailedStacks"}>
            <div style={TabStyle}>
                {
                    !isEmpty(accountId) && !isEmpty(failedStacks) ?
                        <KatTable aria-label="simple table">
                            <KatTableHead>
                                <KatTableRow>
                                    <KatTableCell>Stack Name</KatTableCell>
                                </KatTableRow>
                            </KatTableHead>
                            <KatTableBody>
                                {failedStacks.map(
                                    (stackName: any) => {
                                        return (
                                            <KatTableRow>
                                                <KatTableCell>{stackName}</KatTableCell>
                                            </KatTableRow>
                                        )
                                    }
                                )}
                            </KatTableBody>
                        </KatTable> : <KatAlert variant={"success"} persistent={true}>No Failed Stacks</KatAlert>
                }
            </div>
        </Tab>)
    }

    const getExperimentationStackTabs = (accountId: string, accountType: Stages) => {
        if (accountType === Stages.EXPERIMENTATION) {
            return (
                <>
                    {getExpressoTab(accountType, "SM Notebooks")}
                    {getExpressoTab(accountType, "SM Studio")}
                </>
            )
        }
        return <></>
    }

    const getAccountTab = (accountId: string, accountType: Stages) => {
        return (<Tab label={accountType.toString()} tabId={accountType.toString()}>
            {
                !isEmpty(accountId) ?
                    <div style={TabStyle}>
                        <Typography
                            style={{
                                marginBottom: "10px"
                            }}
                            variant="h6"
                            id="tableTitle"
                            component="div"
                        >
                            Account Id : {accountId}
                        </Typography>
                        <SubTabs>
                            {
                                getExperimentationStackTabs(accountId, accountType)
                            }
                            {
                                accountType !== Stages.EXPERIMENTATION ?
                                    getExpressoTab(accountType, "Runways") : <></>
                            }
                            {getFailedStacksTab(accountId, accountType)}
                        </SubTabs>
                    </div> : <KatAlert persistent={true}>
                        Nothing Here!!
                    </KatAlert>
            }
        </Tab>)
    }

    const getDomainTab = (accountType: Stages) => {
        if (flow === FLOWS.NOTEBOOKS && (accountType === Stages.GAMMA || accountType === Stages.PROD))
            return <></>
        if (flow === FLOWS.RUNWAYS && accountType === Stages.EXPERIMENTATION)
            return <></>
        let infraData = getInfraData(accountType)
        return getAccountTab(infraData.accountId, accountType)
    }

    return <>
        {
            fetchedNamespace.namespaceData.namespace && fetchedNamespace.showNamespaceSuccess == true ?
                <>
                    <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}></BreadCrumb>
                    <div>
                        <div style={{
                            marginTop: "20px",
                            marginBottom: "20px"
                        }}>
                            <VTable>
                                <TableHead>
                                    <TableHeadRow>
                                        <TableCell>
                                            <VTableHeader>Namespace Details</VTableHeader>
                                        </TableCell>
                                        <TableCell/>
                                    </TableHeadRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <VKey>Namespace Name</VKey>
                                        <VValue>{fetchedNamespace.namespaceData.namespace}</VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Description</VKey>
                                        <VValue>{fetchedNamespace.namespaceData.description}</VValue>
                                    </TableRow>
                                </TableBody>
                            </VTable>
                        </div>
                        <Tabs
                            selected={flow === FLOWS.NOTEBOOKS ? Stages.EXPERIMENTATION.toString() : Stages.GAMMA.toString()}>
                            {Object.values(Stages).map(getDomainTab)}
                        </Tabs>
                    </div>
                </> :
                (
                    fetchedNamespace.namespaceFetched == true && fetchedNamespace.showNamespaceSuccess == false ?
                        <KatAlert variant={"danger"} persistent={true}>Encountered error while fetching
                            namespace</KatAlert> : <KatSpinner></KatSpinner>
                )
        }
    </>
};

export default ViewNamespace;