import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {KatBadge, KatButton, KatCard, KatFlashbar, KatSpinner} from "@amzn/katal-react";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles";
import React, {useEffect, useRef, useState} from "react";
import {DataStudioEndpointType, dataStudioInitialState} from "src/model/notebooks/data_studio_models";
import JSONEditor from "src/view/commons/json_editor";
import {
    createDataStudioManagedEndpoint,
    getDataStudioManagedEndpoint,
    getDataStudioOnboarding
} from "src/control/actions/notebooks/data_studio_actions";
import {isEmpty, isValidEMRReleaseLabel, isValidIAMRoleArn, setIfMounted} from "src/utils/common_utils";
import {OperationType, REQUIRED_FIELDS_ERROR_MESSAGE, Status} from "src/constant";
import {isValidJSON} from "src/utils/json_utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {
    dataStudioManagedEndpointSelector,
    dataStudioOnboardingSelector
} from "src/control/selectors/notebooks/data_studio_selectors";
import {useHistory} from "react-router";
import {DisplayError} from "src/view/commons/display_error";
import {VStatusIndicator} from "src/view/style/view_config_styles";
import {getAddonStatusBadgeType} from "src/view/notebooks/datastudio_utils";
import {PaddedSpan} from "src/view/style/table_styles";
import {isAdminWindow} from "src/utils/url_utils";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

interface ManagedEndpointProps {
    operationType: OperationType
}

export default function DataStudioManagedEndpoint(props: ManagedEndpointProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();
    const isAdminView = isAdminWindow()

    const [managedEndpoint, setManagedEndpoint] = useState(dataStudioInitialState.managedEndpoint.value);
    const [showCreateLoader, setShowCreateLoader] = useState(false);

    const _isMounted = useRef(true);

    const CONFIGURATION_TAB_SPACES = 4;

    let username = useSelector(usernameSelector);
    let managedEndpointState = useSelector(dataStudioManagedEndpointSelector);
    let onboarding = useSelector(dataStudioOnboardingSelector);
    let fetchedNamespace = useSelector(namespaceSelector);

    useEffect(() => {
        _isMounted.current = true;
        dispatch(getDataStudioOnboarding(params.onboardingId))
        props.operationType !== OperationType.CREATE && dispatch(getDataStudioManagedEndpoint(params.addonId))
        !isAdminView && dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        props.operationType !== OperationType.CREATE && setManagedEndpoint(managedEndpointState.value)
    }, [managedEndpointState]);

    const handleSubmit = async () => {
        if (
            !isEmpty(managedEndpoint.virtualClusterId) &&
            !isEmpty(managedEndpoint.name) &&
            !isEmpty(managedEndpoint.type) &&
            isValidEMRReleaseLabel(managedEndpoint.releaseLabel) &&
            isValidIAMRoleArn(managedEndpoint.executionRoleArn) &&
            isValidJSON(managedEndpoint.configurationOverrides)
        ) {
            try {
                setIfMounted(_isMounted.current, setShowCreateLoader, true)
                await dispatch(createDataStudioManagedEndpoint({
                    ...managedEndpoint,
                    requestedBy: username,
                    onboardingId: params.onboardingId
                }));
                history.push(window.location.pathname.replace("/managed-endpoint/create", ""))
            } catch (err: any) {
                dispatch(showSnackBar("Create managed endpoint failed"))
            } finally {
                setIfMounted(_isMounted.current, setShowCreateLoader, false)
            }
        }
    };

    const getEndpointTypes = () => {
        return [
            {
                name: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY,
                value: DataStudioEndpointType.JUPYTER_ENTERPRISE_GATEWAY
            },
            Object.keys(DataStudioEndpointType).map((key: string) => {
                return {
                    name: key.toString(),
                    value: DataStudioEndpointType[key as keyof typeof DataStudioEndpointType].toString()
                }
            })
        ];
    };

    const getCardTitle = () => {
        return props.operationType === OperationType.CREATE ? <>Managed Endpoint</> : <>
            Managed Endpoint : <KatBadge label={managedEndpointState.value.status}
                                         type={getAddonStatusBadgeType(managedEndpointState.value.status)}/>
        </>
    }

    const getErrorFlashBar = (failedEntity: string, entityId: string) => {
        return <KatFlashbar
            variant="danger"
            header={"Error"}
            description={`Error occurred while loading ${failedEntity} for id: ${entityId}`}
        />
    }

    return (
        <>
            {
                (props.operationType !== OperationType.CREATE && managedEndpointState.status === Status.ERROR) ?
                    getErrorFlashBar("addon", params.addonId) :
                    onboarding.status === Status.ERROR ?
                        getErrorFlashBar("onboarding", params.onboardingId) :
                        (!isAdminView && isEmpty(fetchedNamespace.namespaceData.namespace)) ||
                        ((props.operationType !== OperationType.CREATE && managedEndpointState.status === Status.LOADING) ||
                        onboarding.status === Status.LOADING ?
                            <KatSpinner/> :
                            <>
                                <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                            dataStudioOnboardingName={onboarding.value.name}
                                            dataStudioManagedEndpointName={managedEndpointState.value.name}/>
                                <KatCard>
                                    <span slot={"subtitle"}>{getCardTitle()}</span>
                                    {props.operationType !== OperationType.CREATE &&
                                        <PaddedSpan>id: {managedEndpoint.addonId}</PaddedSpan>}
                                    <FormInput
                                        label="Name*"
                                        type="text"
                                        required
                                        value={managedEndpoint.name}
                                        placeholder="Name"
                                        tooltipText="Name for your endpoint"
                                        readonly={props.operationType === OperationType.VIEW}
                                        onChange={(event: any) => setManagedEndpoint({
                                            ...managedEndpoint,
                                            name: event.target.value
                                        })}
                                    />
                                    {
                                        isEmpty(managedEndpoint.name) && <DisplayError
                                            message={REQUIRED_FIELDS_ERROR_MESSAGE}
                                            operationType={props.operationType}
                                        />
                                    }
                                    <FormDropDown
                                        label="Endpoint Type*"
                                        value={props.operationType !== OperationType.CREATE ? managedEndpoint.managedEndpointType : managedEndpoint.type}
                                        options={getEndpointTypes()}
                                        tooltipText="click to choose endpoint type"
                                        placeholder="Select from endpoint type"
                                        disabled={props.operationType === OperationType.VIEW}
                                        onChange={(event: any) => {
                                            setManagedEndpoint({
                                                ...managedEndpoint,
                                                type: event.target.value
                                            })
                                        }}
                                    ></FormDropDown>
                                    <FormInput
                                        label="Virtual ClusterId*"
                                        type="text"
                                        required
                                        value={managedEndpoint.virtualClusterId}
                                        placeholder="Virtual Cluster Id"
                                        tooltipText="Virtual Cluster Id to use for endpoint"
                                        readonly={props.operationType === OperationType.VIEW}
                                        onChange={(event: any) => setManagedEndpoint({
                                            ...managedEndpoint,
                                            virtualClusterId: event.target.value
                                        })}
                                    />
                                    {
                                        isEmpty(managedEndpoint.virtualClusterId) && <DisplayError
                                            message={REQUIRED_FIELDS_ERROR_MESSAGE}
                                            operationType={props.operationType}
                                        />
                                    }
                                    <FormInput
                                        label="Release Label*"
                                        required
                                        type="text"
                                        value={managedEndpoint.releaseLabel}
                                        placeholder="EMR Release Label"
                                        tooltipText="EMR Release Labe"
                                        readonly={props.operationType === OperationType.VIEW}
                                        onChange={(event: any) => setManagedEndpoint({
                                            ...managedEndpoint,
                                            releaseLabel: event.target.value
                                        })}
                                    />
                                    {
                                        (isEmpty(managedEndpoint.releaseLabel) || !isValidEMRReleaseLabel(managedEndpoint.releaseLabel)) &&
                                        <DisplayError
                                            message="Release label can not be empty or should be correct emr release label as per aws"
                                            operationType={props.operationType}
                                        />
                                    }
                                    <FormInput
                                        label="Execution Role Arn*"
                                        required
                                        type="text"
                                        value={managedEndpoint.executionRoleArn}
                                        placeholder="Execution Role Arn"
                                        tooltipText="Execution Role Arn"
                                        readonly={props.operationType === OperationType.VIEW}
                                        onChange={(event: any) => setManagedEndpoint({
                                            ...managedEndpoint,
                                            executionRoleArn: event.target.value
                                        })}
                                    />
                                    {
                                        (isEmpty(managedEndpoint.executionRoleArn) || !isValidIAMRoleArn(managedEndpoint.executionRoleArn)) &&
                                        <DisplayError
                                            message="Please enter correct execution role arn"
                                            operationType={props.operationType}
                                        />
                                    }
                                    <div style={{height: "450px", width: "800px"}}>
                                        <span>Cluster Configurations</span>
                                        <VStatusIndicator
                                            variant={isValidJSON(managedEndpoint.configurationOverrides) ? "success" : "error"}
                                            label={""}
                                        />
                                        <JSONEditor
                                            fieldName={"Cluster Configurations"}
                                            value={
                                                props.operationType !== OperationType.CREATE ?
                                                    JSON.stringify(JSON.parse(managedEndpoint.configurationOverrides), null, CONFIGURATION_TAB_SPACES)
                                                    : managedEndpoint.configurationOverrides
                                            }
                                            onChange={(name: any, value: any) => {
                                                setManagedEndpoint({
                                                    ...managedEndpoint,
                                                    configurationOverrides: value
                                                })
                                            }}
                                            readOnly={props.operationType === OperationType.VIEW}
                                        />
                                    </div>
                                </KatCard>
                                {
                                    props.operationType === OperationType.CREATE && <KatButton
                                        onClick={handleSubmit}
                                        loading={showCreateLoader}
                                    >Create Endpoint</KatButton>
                                }
                            </>)
            }
        </>
    );
}