import {
    FETCH_USERNAME_FAILURE,
    FETCH_USERNAME_SUCCESS
} from "src/control/actions/action_types";
import {userInitialState} from "src/model/commons/commons_models"

export default function (state = userInitialState, action: any) {
    switch (action.type) {
        case FETCH_USERNAME_SUCCESS:
            return {
                ...state,
                username: action.payload
            }

        case FETCH_USERNAME_FAILURE:
            return {
                ...state,
                username: null
            }

        default:
            return state;
    }
}
