import {KatCard, KatRadiobuttonGroup, KatSpinner} from "@amzn/katal-react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { loadModelTrainingNamespace } from "src/control/actions/modelTraining/namespace_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { namespaceSelector } from "../../control/selectors/modelTraining/model_training_selectors";
import {
  runwayDAGSelector,
  runwayDataSelector,
  runwayLatestVersionDataSelector,
} from "../../control/selectors/runways/runway_selectors";
import Collapsible from "../commons/collapsible";
import {
  FormDropDown,
  FormInput,
} from "../style/modelTraining/form_input_styles";
import { UpdateRunwayButton } from "../style/runway_styles";
import { isEmpty } from "src/utils/common_utils";
import { GreenSpinner } from "../style/header_styles";
import { Pipeline } from "./pipelines";
import {
  createV2Runway,
  getRunwayLatestVersionData,
  getV2Runway,
  updateV2Runway,
} from "../../control/actions/runways/runway_actions";
import { closeVersionCheckPopUp, getStageOptions, setVersions, showVersionCheckPopUp } from "./constants";
import { getRunwayStatusComponent, getSaveMessageComponent, VersionCheckPopUp } from "./utils";
import { DisplayError } from "../commons/display_error";
import { isValidRunwayDAG } from "../validation/runways/runways_validation";
import { RunwayStatus } from "src/model/runways/runway_model";
import { GenericAlert } from "../style/view_config_styles";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

/**
 * renders the view into a update runway form
 * @returns JSX
 */
export default function UpdateV2Runway() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  //selectors
  let fetchedNamespace = useSelector(namespaceSelector);
  let userName = useSelector(usernameSelector);
  let runwayData = useSelector(runwayDataSelector);
  let runwayDAG = useSelector(runwayDAGSelector);
  let latestVersionRunwayData = useSelector(runwayLatestVersionDataSelector);

  let latestVersion = latestVersionRunwayData.latestVersion;

  //state hooks
  const [isValidationSuccess, setIsValidationSuccess] = useState(true);
  const [versionCheckPopUp, setVersionCheckPopUp] = useState(false);

  //useEffect hooks
  useEffect(() => {
    //to remove unused query params from url
    history.replace({
      search: "",
    });
    dispatch(getV2Runway(params.runwayId, params.version));
    dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
  }, [params.version]);

  /**
   * checks if all fields have valid values or not
   * @returns boolean
   */
  const validatePrerequisites = () => {
    return isValidRunwayDAG(runwayDAG);
  };

  /**
   * responsible for calling relevant action on submit click
   * redirects to view runway page
   */
  const submitUpdateRunwayForm = async () => {
    if (!validatePrerequisites()) {
      setIsValidationSuccess(false);
    } else {
      setIsValidationSuccess(true);
      let latestData: any = await dispatch(getRunwayLatestVersionData(params.runwayId));
      if (runwayData.version !== latestData.latestVersion) {
        setVersionCheckPopUp(true);
      } else {
        try {
          //Awaiting till the update operation is completed to redirect it to the view page
          await dispatch(
            updateV2Runway(
              runwayData.runwayId,
              params.namespaceId,
              userName,
              runwayDAG
            )
          );
          let redirectedPath = window.location.pathname.replace(
            `/${params.version}/update`,
            "/view"
          );
          history.push(redirectedPath);
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  /**
   * responsible for calling relevant action on submit click
   * redirects to view runway page
   */
  const submitRetryCreateRunwayForm = async () => {
    if (!validatePrerequisites()) {
      setIsValidationSuccess(false);
    } else {
      setIsValidationSuccess(true);
      try {
        await dispatch(
          createV2Runway(
            runwayData.runwayName,
            params.namespaceId,
            userName,
            runwayData.domain,
            runwayDAG,
            runwayData.runwayId
          )
        );
        let redirectedPath = window.location.pathname.replace(
          `${params.version}/update`,
          "view"
        );
        history.push(redirectedPath);
      } catch (err) {
        console.log(err);
      }
    }
  };

  /**
   * responsible for calling relevant action on submit click
   * redirects to view runway page
   */
  const submitRetryUpdateRunwayForm = async () => {
    if (!validatePrerequisites()) {
      setIsValidationSuccess(false);
    } else {
      setIsValidationSuccess(true);
      try {
        await dispatch(
          updateV2Runway(
            runwayData.runwayId,
            params.namespaceId,
            userName,
            runwayDAG
          )
        );
        let redirectedPath = window.location.pathname.replace(
          `${params.version}/update`,
          "view"
        );
        history.push(redirectedPath);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getUpdateRunwayButtonComponent = () => {
    switch (latestVersionRunwayData.runwayStatus) {
      case RunwayStatus.DISABLED: {
        return (
          <UpdateRunwayButton onClick={submitUpdateRunwayForm}>
            SUBMIT
          </UpdateRunwayButton>
        );
      }
      case RunwayStatus.CREATE_FAILED: {
        return (
          <UpdateRunwayButton onClick={submitRetryCreateRunwayForm}>
            Retry
          </UpdateRunwayButton>
        );
      }
      case RunwayStatus.UPDATE_FAILED: {
        return (
          <UpdateRunwayButton onClick={submitRetryUpdateRunwayForm}>
            Retry
          </UpdateRunwayButton>
        );
      }
      default: {
        return <></>;
      }
    }
  };

  if (
    isEmpty(fetchedNamespace.namespaceData.namespace) ||
    isEmpty(runwayData.runwayId)
  ) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
    <>
      {versionCheckPopUp && (
        <VersionCheckPopUp
          popUpVisible={versionCheckPopUp}
          setPopUpVisible={setVersionCheckPopUp}
          showPopUp={() => showVersionCheckPopUp(setVersionCheckPopUp)}
          closePopUp={() => closeVersionCheckPopUp(setVersionCheckPopUp)}
          continueActionMethod={async () => {
            try {
              //Awaiting till the update operation is completed to redirect it to the view page
              await dispatch(
                updateV2Runway(
                  runwayData.runwayId,
                  params.namespaceId,
                  userName,
                  runwayDAG
                )
              );
              let redirectedPath = window.location.pathname.replace(
                `/${params.version}/update`,
                "/view"
              );
              history.push(redirectedPath);
            } catch (err) {
              console.log(err);
            }
          }}
          cancelActionMethod={() =>
            closeVersionCheckPopUp(setVersionCheckPopUp)
          }
        ></VersionCheckPopUp>
      )}

      {latestVersionRunwayData.runwayStatus === RunwayStatus.ENABLED && (
        <GenericAlert
          variant="warning"
          header={"Please disable the runway to make any updates"}
          persistent
        ></GenericAlert>
      )}
      {
        !isEmpty(fetchedNamespace.namespaceData.namespace) && !isEmpty(runwayData.runwayName) ?
        <>
          <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace} runwayName={runwayData.runwayName}></BreadCrumb>
          <KatCard>
            <div>
              Status:&nbsp;&nbsp;&nbsp;&nbsp;
              {getRunwayStatusComponent(latestVersionRunwayData.runwayStatus)}
            </div>
            <br />

            <FormInput
              label="Namespace Name"
              type="text"
              placeholder="Namespace name"
              value={fetchedNamespace.namespaceData.namespace}
              tooltipText="Namespace Name"
              disabled={true}
            />

            <FormInput
              label="Runway Id"
              type="text"
              placeholder="Runway Id"
              value={runwayData.runwayId}
              tooltipText="Runway Id"
              disabled={true}
            />

            <FormInput
              label="Runway Name"
              type="text"
              placeholder="Runway name"
              value={runwayData.runwayName}
              tooltipText="Runway Name"
              disabled
            />

            <FormDropDown
              label="Version"
              searchable={true}
              value={runwayData.version}
              maxHeight="100px"
              options={setVersions(latestVersion)}
              placeholder={
                latestVersion === -1
                  ? `${runwayData.version} (Could not load other versions)`
                  : "Select versions"
              }
              disabled={latestVersion === -1}
              onChange={(event: any) => {
                let path = window.location.pathname;
                let redirectedPath = path.replace(
                  `/runways/${params.runwayId}/${params.version}/update`,
                  `/runways/${params.runwayId}/${event.target.value}/update`
                );
                history.push(redirectedPath);
              }}
            ></FormDropDown>
            {latestVersion === -1 && (
              <div>NOTE: Please refresh to load other versions</div>
            )}
          </KatCard>

          <Collapsible label="Stage">
            <KatRadiobuttonGroup
              name={"Stage"}
              options={getStageOptions()}
              value={runwayData.domain}
              disabled
            ></KatRadiobuttonGroup>
          </Collapsible>

          <Collapsible label="Jobs">
            <KatCard>
              <Pipeline
                jobData={runwayDAG}
                domain={runwayData.domain}
                runwayStatus={latestVersionRunwayData.runwayStatus}
              ></Pipeline>
            </KatCard>
            {!isValidationSuccess && !isValidRunwayDAG(runwayDAG) && (
              <DisplayError message="Please add atleast one job" marginTop={1} />
            )}
            <br />
          </Collapsible>
          <br />

          {getSaveMessageComponent(runwayDAG)}

          <br />

          {getUpdateRunwayButtonComponent()}
          <br />
          <br />
        </> : <KatSpinner></KatSpinner>
      }
  </>
  );
}
