import {
  DISABLE_JOB_EXECUTIONS_LOAD_MORE,
  LOAD_JOB_EXECUTIONS_STATUS_SUCCESS,
} from "../../../control/actions/action_types";
import { JOB_EXECUTIONS_DEFAULT_PAGE_SIZE } from "../../../view/runways/constants";

const disableLoadMoreForJobInstances =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    const nextAction = next(action);
    if (action.type === LOAD_JOB_EXECUTIONS_STATUS_SUCCESS) {
      if (action.payload.length < JOB_EXECUTIONS_DEFAULT_PAGE_SIZE) {
        // To disable load more button in case there are no more pages to be fetched.
        dispatch({ type: DISABLE_JOB_EXECUTIONS_LOAD_MORE });
      }
    }
    return nextAction;
  };

export default [disableLoadMoreForJobInstances];
