import {apiGetCallback} from "../../../utils/api_handler";
import {Request, Success, Failure} from "../../../utils/action_utils";
import {FETCH_USERNAME_FAILURE, FETCH_USERNAME_REQUEST, FETCH_USERNAME_SUCCESS} from "src/control/actions/action_types";
import {getUsernameTarget} from "src/control/targets";
import {ERROR_WHILE_FETCHING_USER_INFO} from "src/control/errors";
import {isEmpty} from "src/utils/common_utils";

export const fetchUsername = (callbackOnSuccess: any, callbackOnFailure: any) => (dispatch: any) => {
    dispatch(Request(FETCH_USERNAME_REQUEST));
    apiGetCallback(getUsernameTarget(),
        (data: any) => {
            if(isEmpty(data) || isEmpty(data.username)) {
                let error = ERROR_WHILE_FETCHING_USER_INFO;
                dispatch(Failure(FETCH_USERNAME_FAILURE, error));
                callbackOnFailure && callbackOnFailure(error);
                return;
            }
            dispatch(Success(FETCH_USERNAME_SUCCESS, data.username));
            callbackOnSuccess && callbackOnSuccess(data);
        },
        (error: any) => {
            dispatch(Failure(FETCH_USERNAME_FAILURE, error));
            callbackOnFailure && callbackOnFailure(error);
        });
}
