import styled from "styled-components";
import {KatAlert, KatButton, KatModal} from "@amzn/katal-react";


export const CouldNotLoadAlert = styled(KatAlert)`
    margin: auto !important;
    left: 0;
    right: 0;
    max-width: 98vw;
    width: 100%;
    position: fixed;
`

export const PopUp = styled(KatModal)`
`

export const JobPopUp = styled(PopUp)`
    max-width: 600px;
`

export const UpdateJobPopUp = styled(PopUp)`
    max-width: 600px;
`

export const JobOptionsPopUp = styled(JobPopUp)`
`

export const Alert = styled(KatAlert)<{ show: any }>`
    background: #000;
    color: #fff;
    display: flex;
    padding: 0.5rem;
    top: 90vh;
    right: ${({show}) => (show ? '1vw' : '-100%')};
    position: fixed;
    transition: 350ms;
    z-index: 1200;
`;

export const LinkButton = styled(KatButton)`
    border: 2px solid black;
`;

export const TabStyle = {marginTop: "30px", marginLeft: "50px", marginRight: "50px"}

export const ClickablePaddedSpan = styled.span`
    padding: 10px;
    padding-right: 15px;
    &:hover {
      cursor: pointer;
    }
`