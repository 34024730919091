import { isProdDomain } from "src/utils/common_utils"

export enum JobDomain {
    EXPERIMENTATION="EXPERIMENTATION",
    GAMMA="GAMMA",
    PROD="PROD",
}

export const lookupKeysList = [
    {
        'value': 'ip',
        'label': 'IP',
    },
    {
        'value': 'customer_id',
        'label': 'Customer ID',
    },
    {
        'value': 'session_id',
        'label': 'Session ID',
    }
]

export enum RealTimeService {
    Bromine="Bromine",
    Chlorine="Chlorine",
    Fluorine="Fluorine",
}

export const DryadAccountName = isProdDomain() ? 'Expresso' : 'ExpressoGamma'