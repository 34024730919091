import React from 'react'
import {KatButton, KatTabs, KatTab, KatSpinner, KatCard, KatInput} from '@amzn/katal-react'
import {Link } from 'react-router-dom'
import {apiGetCallback} from 'src/utils/api_handler'
import { getFeatureGenerationNamespaceTarget } from 'src/control/targets'
import { isEmpty } from 'src/utils/common_utils'
import {withRouter} from 'react-router'

class FeatureGenerationNamespaceDetail extends React.Component<any, any> {
    _mounted: boolean = false
    
    constructor(props: any) {
        super(props)
        this.state = {
            namespace: null,
            namespaceId: this.props.match.params.namespaceId,
            teamId: this.props.match.params.teamId,
            apiErrorFlag: false,
        }
        this.loadNamespaceName = this.loadNamespaceName.bind(this)
    }

    componentDidMount() {
        this.loadNamespaceName(this.props.match.params.teamId, this.props.match.params.namespaceId)
        this._mounted = true
    }
    componentWillUnmount() {
        this._mounted = false
    }

    loadNamespaceName(teamId: string, namespaceId: string) {
        apiGetCallback(getFeatureGenerationNamespaceTarget(teamId, namespaceId), (responseData: any) => {
            responseData == null ? this._mounted && this.setState({'apiErrorFlag': true}) :
            this._mounted && this.setState({'namespace': responseData, 'teamId': teamId, 'namespaceId': namespaceId})

        }, (error: any) => {
            this._mounted && this.setState({'apiErrorFlag': true})
        });
    }

    render() {
        return (
            <>
                    &nbsp;
                    {isEmpty(this.state.namespace) && <KatSpinner/>}
                    {!isEmpty(this.state.namespace) &&
                    <KatCard>
                        <span slot='title'> {this.state.namespace.namespace} </span>
                        <span slot='subtitle'> {`Created by ${this.state.namespace.createdBy} at ${new Date(this.state.namespace.creationTime).toLocaleString()}`} </span>
                        <span slot='action'>
                            <Link to={`/featureGeneration/${this.state.teamId}/namespaces/${this.state.namespaceId}/jobs/`}>
                            <KatButton label="View Jobs"/>
                            </Link>
                        </span>
                        &nbsp;
                        {this.state.apiErrorFlag &&
                        <kat-alert header="Server Error" description="Could not load namespace details." variant="danger"/>}
                        <div>
                            <h3> AWS Account Information</h3>
                            &nbsp;
                            <KatTabs selected='experimentation'>
                                &nbsp;
                                {Object.entries(this.state.namespace.accountTypeMap).map(([key, _]) => {
                                    return (
                                            
                                            <KatTab key={key} tabId={key} label={key.charAt(0).toUpperCase() + key.slice(1)}>
                                                {this.state.namespace.accountTypeMap[key] == null &&
                                                <kat-alert
                                                header="No view.configPanel.data"
                                                description={`No AWS account details registered in this namespace for ${key}.`}
                                                variant="info"
                                                persistent
                                                />}
                                                {this.state.namespace.accountTypeMap[key] != null &&
                                                <div>
                                                    <KatInput
                                                    value={this.state.namespace.accountTypeMap[key].awsAccountId}
                                                    type="text"
                                                    label="AWS Account ID"
                                                    name="accountId"
                                                    disabled
                                                    />
                                                    &nbsp;
                                                    <KatInput
                                                    value={this.state.namespace.accountTypeMap[key].awsAccountName}
                                                    type="text"
                                                    label="AWS Account Name"
                                                    name="accountName"
                                                    disabled
                                                    />
                                                    &nbsp;
                                                    <KatInput
                                                    value={this.state.namespace.accountTypeMap[key].featureGenerationIAMRole}
                                                    type="text"
                                                    label="IAM Role"
                                                    name="iamRole"
                                                    disabled
                                                    />
                                                    &nbsp;
                                                    <KatInput
                                                    value={this.state.namespace.accountTypeMap[key].outputS3Bucket}
                                                    type="text"
                                                    label="Output S3 bucket"
                                                    name="outputS3Bucket"
                                                    disabled
                                                    />
                                                </div>}
                                            </KatTab>
                                    )   
                                })}
                            </KatTabs>
                        </div>
                    </KatCard>}
            </>
        )
    }
}

export default withRouter(FeatureGenerationNamespaceDetail)