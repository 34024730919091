import styled from "styled-components";
import {
    KatButton,
    KatTable
} from "@amzn/katal-react";

export const BoundaryBox = styled(KatTable)`
    box-shadow: 0 1px 3px 0 #b5b5b5;
    border: 1px solid #d5dbdb;
    margin-left: 5%;
    margin-top: 2%;
    width: 90% !important;
    margin-bottom: 1%;
`

export const DescriptionArea = styled.textarea`
    width: 100%;
    height: 80px;
    margin-top: 10px;
`
export const TitleArea = styled(DescriptionArea)`
    width: 70%;
    height: 25px;
    border: none;
    outline: none;
    resize: none;
    font-size: large;
    border-bottom: 1px solid black;
    margin-left: 0.5%;
`

export const OwnerArea = styled(TitleArea)`
`

export const MCMorSEV2Area = styled(TitleArea)`
    width: 90%
`

export const DescriptionBox = styled.div`
    padding: 0px;
    margin-top: 2%;
    margin-left: 1%;
    width: 98%;
`

export const MergeSummaryBox = styled(DescriptionBox)`
`

export const SourceDetailsBox = styled(DescriptionBox)`
`

export const ReviewDetailsBox = styled(DescriptionBox)`
`

export const MergeRequirementsBox = styled(DescriptionBox)`
`

export const PublishButton = styled(KatButton)`
    float: right;
    width: 160px;
    --background: #002e36;
    --hover-ring-color: #002e36;
    --background-hover: #002e36;
    --font-size: 0.875rem;
    color: white;
    border-radius: 4px;
`
export const MergeButton = styled(KatButton)`
    float: right;
    width: 120px;
    border-radius: 4px;
`
export const OverrideButton = styled(KatButton)`
    float: right;
    margin-right: 1%;
    width: 120px;
    border-radius: 4px;
`


export const ReviewBox = styled.div`
    margin: 0px;
    margin-left: 1%;
    width: 98%;
    display: flex;
`

export const ReviewerBox = styled.div`
    padding: 0px;
`

export const InputReview = styled.input`
    border: none;
    outline: none;
    border-bottom: 1px solid black; 
    margin-right: 20px; 
    padding: 6px;
`

export const AddReviewerButton = styled(PublishButton)`
`

export const TextComparision = styled.div`
    margin: 0px;
    margin-left: 1%;
    width: 98%
`

export const ComparisionArea = styled(TextComparision)`
`

export const CommentBox = styled(TextComparision)`
      
`
export const AddCommentButton = styled(PublishButton)`
`
export const CommentArea = styled(DescriptionArea)`
    resize: none;
    height: 100px;
    margin-bottom: 3%;
`

export const ApproveButton = styled(PublishButton)`
    --background: #008296;
    --hover-ring-color: #008296;
    --background-hover: #008296;
    --background-disabled: #00556359;
    --border-color-disabled: #00000000;
    width: 160px;
    margin-left: 5%;
`

export const RevokeButton = styled(PublishButton)`
    margin-left: 7%;
    --background: #c81839;
    --hover-ring-color: #c81839;
    --background-hover: #c81839;
    --background-disabled: #c8183945;
    --border-color-disabled: #00000000;
`

export const ContinueToMergeButton = styled(PublishButton)`
    --background: #008296;
    --hover-ring-color: #008296;
    --background-hover: #008296;
    --background-disabled: #00556359;
    --border-color-disabled: #00000000;
    width: 160px;
    margin-left: 7%;
`

export const DiscardButton = styled(PublishButton)`
    margin-left: 5%;
    --background: #c81839;
    --hover-ring-color: #c81839;
    --background-hover: #c81839;
`
export const InfoPara = styled.p`
    margin-
    width: 50%;
    text-align: left;
    font-size: 20px;
`

export const SaveButton = styled(PublishButton)`
`