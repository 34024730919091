import {KatButton, KatDivider, KatFlashbar, KatSpinner, KatTab, KatTabs} from "@amzn/katal-react";
import React, {useEffect, useRef, useState} from "react";
import {FormInput} from "src/view/style/modelTraining/form_input_styles";
import {
    FIELDS_REQUIRED_MESSAGE,
    NAMESPACE_UPDATE_FAILED,
    NAMESPACE_UPDATE_SUCCESS,
    RUNWAY_ACCOUNT_REQUIRED_MESSAGE,
} from "src/view/modelTraining/constants";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {
    loadModelTrainingNamespace,
    updateModelTrainingNamespace,
} from "src/control/actions/modelTraining/namespace_actions";

import {START_UPDATE_NAMESPACE_LOADER} from "src/control/actions/action_types";
import {
    namespaceSelector,
    updateModelTrainingNamespaceSelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {FLOWS, getFlowFromURL} from "src/utils/url_utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

const UpdateNamespace = () => {
    const params: any = useParams()
    const dispatch = useDispatch();

    let userId = useSelector(usernameSelector);
    let updateNamespaceState = useSelector(updateModelTrainingNamespaceSelector)
    let fetchedNamespace = useSelector(namespaceSelector)

    let _isMounted = useRef(true);
    const [namespaceName, setNamespaceName] = useState('')
    const [prodAccountId, setProdAccountId] = useState('')
    const [gammaAccountId, setGammaAccountId] = useState('')
    const [expAccountId, setExpAccountId] = useState('')
    const [namespaceDescription, setNamespaceDescription] = useState('')
    const flow = getFlowFromURL(window.location.pathname)

    let displayMessage = (updateNamespaceState.isError) || (updateNamespaceState.isSuccess)

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    const getMessage = () => {
        if (updateNamespaceState.isError) {
            return NAMESPACE_UPDATE_FAILED
        } else if (updateNamespaceState.isSuccess) {
            return NAMESPACE_UPDATE_SUCCESS
        }
        return ""
    }

    const loadNamespace = () => {
        dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
    }

    const initializeStates = () => {
        setIfMounted(_isMounted.current, setNamespaceName, fetchedNamespace.namespaceData.namespace)
        setIfMounted(_isMounted.current, setNamespaceDescription, fetchedNamespace.namespaceData.description)
        setIfMounted(_isMounted.current, setExpAccountId, fetchedNamespace.namespaceData.accountTypeMap.experimentation)
        setIfMounted(_isMounted.current, setGammaAccountId, fetchedNamespace.namespaceData.accountTypeMap.gamma)
        setIfMounted(_isMounted.current, setProdAccountId, fetchedNamespace.namespaceData.accountTypeMap.prod)
    }

    useEffect(() => {
        if (_isMounted.current) {
            loadNamespace()
        }
    }, [userId, params.teamId, params.namespaceId, dispatch]);

    useEffect(() => {
        if (_isMounted.current) {
            initializeStates()
        }
    }, [fetchedNamespace]);

    const onSubmit = (event: any) => {
        event.preventDefault()
        if (flow == FLOWS.NOTEBOOKS &&
            isEmpty(namespaceName) ||
            isEmpty(expAccountId)) {
            alert(FIELDS_REQUIRED_MESSAGE)
            return
        }
        if (flow == FLOWS.RUNWAYS &&
            (isEmpty(namespaceName) ||
                (isEmpty(prodAccountId) && isEmpty(gammaAccountId)))) {
            alert(RUNWAY_ACCOUNT_REQUIRED_MESSAGE)
            return
        }
        dispatch({type: START_UPDATE_NAMESPACE_LOADER})
        dispatch(updateModelTrainingNamespace(params.teamId, params.namespaceId, {
            requestedBy: userId,
            description: namespaceDescription,
            accountTypeMap: {
                experimentation: expAccountId,
                gamma: gammaAccountId,
                prod: prodAccountId
            }
        }))
    }

    return !isEmpty(namespaceName) ? (
        <><BreadCrumb namespaceName={namespaceName}/>
            <div className="UpdateNamespace">
                {displayMessage ?
                    <KatFlashbar variant={updateNamespaceState.isSuccess ? "success" : "danger"}
                                 header={getMessage()}
                    >
                    </KatFlashbar>
                    : updateNamespaceState.startLoader ? <KatSpinner/> : null
                }
                <form className='UpdateNamespace-form' onSubmit={onSubmit}>
                    <FormInput
                        label="Namespace name *"
                        type='text'
                        value={namespaceName}
                        onChange={(event: any) => setNamespaceName(event.target.value)}
                        required
                        disabled
                    />
                    <br/>
                    <FormInput
                        label="Description"
                        type='text'
                        value={namespaceDescription}
                        onChange={(event: any) => setNamespaceDescription(event.target.value)}
                    />
                    <br/>
                    <KatDivider variant="eastern"/>
                    {
                        <KatTabs selected={flow == FLOWS.NOTEBOOKS ? "Experimentation" : "Gamma"}>
                            {
                                flow == FLOWS.NOTEBOOKS && <KatTab tabId="Experimentation" label="Experimentation">
                                    <FormInput
                                        label="Account Id *"
                                        placeholder='Experimentation Account Id'
                                        type='text'
                                        value={expAccountId}
                                        onChange={(event: any) => setExpAccountId(event.target.value)}
                                        required
                                        disabled={!isEmpty(expAccountId)}
                                    />
                                    <br/>
                                </KatTab>
                            }
                            {
                                flow == FLOWS.RUNWAYS && <KatTab tabId="Gamma" label="Gamma">
                                    <FormInput
                                        label="Account Id"
                                        type='text'
                                        placeholder='Gamma Account Id'
                                        value={gammaAccountId}
                                        onChange={(event: any) => setGammaAccountId(event.target.value)}
                                        disabled={!isEmpty(gammaAccountId)}
                                    />
                                    <br/>
                                </KatTab>
                            }
                            {
                                flow == FLOWS.RUNWAYS && <KatTab tabId="Prod" label="Prod">
                                    <FormInput
                                        label="Account Id"
                                        type='text'
                                        placeholder='Prod Account Id'
                                        value={prodAccountId}
                                        onChange={(event: any) => setProdAccountId(event.target.value)}
                                        disabled={!isEmpty(prodAccountId)}
                                    />
                                    <br/>
                                </KatTab>
                            }
                        </KatTabs>
                    }
                    <br/>
                    <KatButton type="submit" label="Update" variant="primary" size="base"
                               disabled={isEmpty(userId) || isEmpty(namespaceName)}/>
                </form>
                <div>
                    <KatDivider variant="zircon"/>
                </div>
                <br/>
                <br/>
            </div>
        </>
    ) : <KatSpinner/>;
}

export default UpdateNamespace;