import {DataStudioAddonStatus} from "src/model/notebooks/data_studio_models";

export const getAddonStatusBadgeType = (status: DataStudioAddonStatus) => {
    switch (status) {
        case DataStudioAddonStatus.ACTIVE:
            return "success"
        case DataStudioAddonStatus.FAILED:
            return "warning"
        case DataStudioAddonStatus.CREATE_IN_PROGRESS:
            return "info"
        case DataStudioAddonStatus.DELETED:
            return "default"
        default:
            return "tag"
    }
}