import React, {useEffect, useRef} from "react";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import config_panel_logo from "../images/config_panel_logo.png"
import runways_logo from "../images/runways_logo.png"
import code_repo_logo from "../images/code_repos_logo.png"
import datasets_logo from "../images/datasets_logo.jpeg"
import job_templates_logo from "../images/job_templates_logo.png"
import flash_logo from "../images/flash_logo.png"
import notebooks_logo from "../images/notebooks.png"
import {GreenSpinner} from "../style/header_styles";
import {
    Card,
    CardBody,
    CardImageWrapper,
    CardsRow,
    CardsWrapper,
    CardTitle,
    LearnMoreLink,
    MainWrapper,
    SubWrapper,
    WelcomeBanner
} from "../style/home_styles";
import {FLOWS, isAdminWindow, isAnchor} from "src/utils/url_utils";
import {getFromLocalStorageAndSetTeam, isEmpty} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {MODEL_TRAINING_USER_GUIDE} from "src/external_links";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {OPERATION_NOT_SUPPORTED_MESSAGE} from "src/constant";


{
    /**
     * TODO : Add Learn more link
     * */
}

export default function Home() {
    const history = useHistory();
    const dispatch = useDispatch();
    let selectedTeam = useSelector((state: any) => state.team.team)
    let username = useSelector(usernameSelector);
    const isAdminView = isAdminWindow()

    const _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    const reRouteToNamespace = (event: any, flow: string, domain = "") => {
        if (isEmpty(selectedTeam)) {
            dispatch(showSnackBar("Please select team to continue"));
        } else {
            let path = `/${flow}/${selectedTeam.teamId}/namespaces` + (domain != "" ? `?domain=` + domain : "");
            if (!isAnchor(event) && !isEmpty(selectedTeam)) {
                history.push(path);
            }
        }
    }

    const reRouteToGlobalUtils = (event: any, flow: string) => {
        let path = `/${flow}`
        if (!isAnchor(event)) {
            history.push(path);
        }
    }

    useEffect(() => {
        // to disable the flex property of the WelcomeBanner that it inherits from the KatAlert katal component.
        // the KatAlert uses the .sc-iUuytg class
        if (username) {
            setTimeout(() => {
                let container = document.querySelector<HTMLElement>(".sc-iUuytg")
                    ?.shadowRoot?.querySelector<HTMLElement>(".content")
                if (container) {
                    container.style.flex = "none"
                }
            }, 0)
        }
        getFromLocalStorageAndSetTeam(dispatch, isAdminView)
    }, [username])

    const getWelcomeBanner = () => {

        if (!username) {
            return <GreenSpinner variant={"default"} size={"small"}/>
        }

        return (
            <WelcomeBanner
                header={"Welcome " + username.toUpperCase() + ", What would you like to do today?"}
                description={"Please choose team to continue"}
                persistent
            />
        )

    }

    const getFlowLogo = (flow : string) => {
        switch (flow) {
            case FLOWS.NOTEBOOKS:
                return notebooks_logo;
            case FLOWS.RUNWAYS:
                return runways_logo;
            case FLOWS.CONFIG_PANEL:
                return config_panel_logo;
            case FLOWS.FLASH:
                return flash_logo;
            case FLOWS.DATASETS:
                return datasets_logo;
            case FLOWS.CODE_REPOSITORIES:
                return code_repo_logo;
            case FLOWS.JOB_TEMPLATE:
                return job_templates_logo;
            default:
                return;
        }
    }

    const getCard = (flow: string, title: string, body: string, linkText: string, onClickCallback: (event: any, flow: string) => void) => {
        return <Card onClick={(event: any) => {
                onClickCallback(event, flow)
        }}>
            <CardImageWrapper><img className="flow-logo" src={getFlowLogo(flow)}></img></CardImageWrapper>
            <CardTitle>{title}</CardTitle>
            {!isAdminView ? <CardBody>{body}</CardBody> : <></>}
            <LearnMoreLink href={MODEL_TRAINING_USER_GUIDE} target="_blank" rel="noopener noreferrer">
                {!isEmpty(linkText) ? linkText : <>manageInfra</>}
            </LearnMoreLink>
        </Card>
    }

    const reRouteToNextPage = (event: any, flow: string) => {
        if(!isAdminView) {
            reRouteToNamespace(event, flow);
        } else if(isEmpty(selectedTeam)){
            dispatch(showSnackBar("Please Select Admin Team"));
        } else if(flow === FLOWS.NOTEBOOKS) {
            history.push(`/admin/${selectedTeam.teamId}/${flow}`);
        } else {
            dispatch(showSnackBar(OPERATION_NOT_SUPPORTED_MESSAGE));
        }
    }

    return (
        <MainWrapper>
            <SubWrapper>
                {getWelcomeBanner()}
            </SubWrapper>
            <CardsWrapper>
                <span slot="subtitle">Products</span>
                <CardsRow>
                    {
                        getCard(FLOWS.NOTEBOOKS,
                            "Notebooks",
                            "Create experiments to test and build your ML models",
                            "learn more",
                            reRouteToNextPage
                        )
                    }
                    {
                        getCard(FLOWS.RUNWAYS,
                            "Runways",
                            "Create, train, re-train and deploy your ML workflows in the production with support for EMR-based processing jobs",
                            "learn more",
                            reRouteToNextPage
                        )
                    }
                    {
                        getCard(FLOWS.CONFIG_PANEL,
                            "ConfigPanel",
                            "Update live configurations to productions in near-real time and safely without any deployment",
                            "learn more",
                            reRouteToNextPage
                        )
                    }
                    {
                        getCard(FLOWS.FLASH,
                            "Flash",
                            "Update scripts and lists in production quickly and safely",
                            "coming soon..",
                            reRouteToNextPage
                        )
                    }
                </CardsRow>
            </CardsWrapper>
            <>
                { !isAdminView ?
                    <CardsWrapper>
                        <span slot="subtitle">Resources</span>
                        <CardsRow>
                            {
                                getCard(FLOWS.CODE_REPOSITORIES,
                                    "Code Repositories",
                                    "Onboard code repositories to Expresso and manage them for usage within Expresso",
                                    "learn more",
                                    reRouteToGlobalUtils)}
                            {
                                getCard(FLOWS.DATASETS,
                                    "Datasets",
                                    "Manage your datasets for use in runways",
                                    "learn more",
                                    reRouteToGlobalUtils)
                            }
                            {
                                getCard(FLOWS.JOB_TEMPLATE,
                                    "Job Templates",
                                    "Create templates for iterative usage in your Runways",
                                    "learn more",
                                    reRouteToGlobalUtils)
                            }
                        </CardsRow>
                    </CardsWrapper> : <></>
                }
            </>
        </MainWrapper>
    )
}