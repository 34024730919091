import {
    FETCH_DATA_STUDIO_INFRA_FAILURE,
    FETCH_DATA_STUDIO_INFRA_SUCCESS,
    FETCH_EXP_ACCOUNT_INFRA_FAILURE,
    FETCH_EXP_ACCOUNT_INFRA_SUCCESS,
    FETCH_GAMMA_ACCOUNT_INFRA_FAILURE,
    FETCH_GAMMA_ACCOUNT_INFRA_SUCCESS,
    FETCH_PROD_ACCOUNT_INFRA_FAILURE,
    FETCH_PROD_ACCOUNT_INFRA_SUCCESS,
} from "src/control/actions/action_types";
import {accountInfraInitialState} from "src/model/modelTraining/model_training_models"

export default function (state = accountInfraInitialState, action: any) {
    switch (action.type) {
        case FETCH_EXP_ACCOUNT_INFRA_SUCCESS:
            return {
                ...state,
                experimentation: {
                    ...state.experimentation,
                    infraFetched: true,
                    infraFetchSuccess: true,
                    infraData: action.payload
                }
            }

        case FETCH_EXP_ACCOUNT_INFRA_FAILURE:
            return {
                ...state,
                experimentation: {
                    ...state.experimentation,
                    infraFetched: true,
                    infraFetchSuccess: false
                }
            }
        case FETCH_GAMMA_ACCOUNT_INFRA_SUCCESS:
            return {
                ...state,
                gamma: {
                    ...state.gamma,
                    infraFetched: true,
                    infraFetchSuccess: true,
                    infraData: action.payload
                }
            }
        case FETCH_GAMMA_ACCOUNT_INFRA_FAILURE:
            return {
                ...state,
                gamma: {
                    ...state.gamma,
                    infraFetched: true,
                    infraFetchSuccess: false
                }
            }
        case FETCH_PROD_ACCOUNT_INFRA_SUCCESS:
            return {
                ...state,
                prod: {
                    ...state.prod,
                    infraFetched: true,
                    infraFetchSuccess: true,
                    infraData: action.payload
                }
            }
        case FETCH_PROD_ACCOUNT_INFRA_FAILURE:
            return {
                ...state,
                prod: {
                    ...state.prod,
                    infraFetched: true,
                    infraFetchSuccess: false
                }
            }
        case FETCH_DATA_STUDIO_INFRA_SUCCESS:
            return {
                ...state,
                experimentation: {
                    ...state.experimentation,
                    dataStudioInfra : action.payload,
                    infraFetched: true,
                    infraFetchSuccess: true
                }
            }
        case FETCH_DATA_STUDIO_INFRA_FAILURE:
            return {
                ...state,
                experimentation: {
                    ...state.experimentation,
                    dataStudioInfra : {},
                    infraFetched: true,
                    infraFetchSuccess: false
                }
            }
        default:
            return state;
    }
}
