import styled from "styled-components";
import {KatDropdown, KatSpinner, KatTab, KatTabs,} from "@amzn/katal-react";

export const HeaderDiv = styled.div`
    top: 0;
    left: 0;
    right: 0;
    height: 50px;
    position: fixed;
    max-width: 98vw;
    z-index: 11;
    margin: 0 auto !important;
    background: #002e36;
    color: white !important;
`

export const WelcomeUser = styled.div`
    float: right;
    margin: 15px;
    color: white;
    text-align: right;
    width: 30%;
`

export const DropDownDiv = styled.div`
    padding-bottom: 5px;
    padding-right: 5px;
    top: 50px;
    right: 1vw;
    max-width: 98vw;
    position: fixed;
    z-index: 13;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

export const DropDownWrapper = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: row;
`

export const HeaderDropDown = styled(KatDropdown)`
    --background: #002e36;
    --color: #fff;
    --option-color: #fff;
    --height: 0.5rem;
    min-width: 18rem;
    --placeholder-color:#c3cecf;
    --option-background: #383f40;
    --menu-background: #383f40;
    --option-background-selected: #575f60;
    --option-hover-ring-color: #fff;
`

export const SpinnerDiv = styled.div`
    padding-bottom: 5px;
    padding-right: 5px;
    height: 30px;;
    margin: 10px;
    z-index: 13;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`

export const WhiteSpinner = styled(KatSpinner)`
    --color: white;
`

export const GreenSpinner = styled(KatSpinner)`
    --color: #002e36;
`
export const MainDiv = styled.div`
  max-width: 98vw;
  margin: 0 auto !important;
  left: 0;
  right: 0;
`;

export const BodyDiv = styled.div`
  max-width: 98vw;
  margin: 0 auto !important;
  position: absolute;
  top: 130px;
  left: 0;
  right: 0;
`;

export const Tabs = styled(KatTabs)`
    --header-background: #002e36;
    --label-focus-ring-width: 0px;
`;

export const SubTabs = styled(KatTabs)`
    --header-background: grey;
    --label-focus-ring-width: 0px;
`;

export const Tab = styled(KatTab)`
    --label-font-size: 15px;
    --label-padding: 10px;
    --label-color: #fff;
    --label-color-hover: #e2e2e2;
`;
