import {Failure, Request, sleep, Success} from "src/utils/action_utils";
import {
    DATASET_FETCH_ALL_FAILURE,
    DATASET_FETCH_ALL_REQUEST,
    DATASET_FETCH_ALL_SUCCESS
} from "src/control/actions/action_types";
import {ERROR_WHILE_FETCHING_ALL_DATASETS_INFO} from "src/control/errors";
import {apiGetCallback, apiPostCallback} from "src/utils/api_handler";
import {getDatasetFetchAllTarget} from "src/control/targets";
import {isEmpty} from "src/utils/common_utils";
import {
    Dataset,
    DatasetInfo,
    DatasetInfoCreateRequestBody,
    DatasetInfosListRequestParams
} from "src/view/dataset/dataset_constants";

export const fetchAllDatasets = (callbackOnSuccess: any, callbackOnFailure: any, params?: DatasetInfosListRequestParams) => async (dispatch: any) => {
    dispatch(Request(DATASET_FETCH_ALL_REQUEST));
    let error = ERROR_WHILE_FETCHING_ALL_DATASETS_INFO;

    apiGetCallback(
        getDatasetFetchAllTarget(),
        (data: any) => {
            if(isEmpty(data) || isEmpty(data.datasetInfosList)) {
                dispatch(Failure(DATASET_FETCH_ALL_FAILURE, error));
                callbackOnFailure && callbackOnFailure(error);
                return;
            }
            const payload = data.datasetInfosList.map((dataset: DatasetInfo) => {
                return Dataset.fromDatasetInfo(dataset)
            })
            dispatch(Success(DATASET_FETCH_ALL_SUCCESS, payload));
            callbackOnSuccess && callbackOnSuccess(data);
        },
        (error: any) => {
            dispatch(Failure(DATASET_FETCH_ALL_FAILURE, error));
            callbackOnFailure && callbackOnFailure(error);
        },
        params
    );
}

export const createDataset = (callbackOnSuccess: any, callbackOnFailure: any, requestBody: DatasetInfoCreateRequestBody) => async (dispatch: any) => {
    apiPostCallback(
        getDatasetFetchAllTarget(),
        requestBody,
        callbackOnSuccess,
        callbackOnFailure
    )
}