import {colorWhite, NavDashIcon, NavRunwaysIcon, NavNotebooksIcon} from "src/view/style/icons";
import React from "react";
import {NavItem, onClickParams, CreateNamespaceItem, NamespaceItem} from "./nav_item_commons";
import {fetchAllNamespaces} from "src/control/actions/modelTraining/namespace_actions";
import {allNamespacesSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";


const _navigateToNamespacesPage = (props: onClickParams) => {
    let history = props.history;
    let teamId = props.otherParams.teamId
    history.push(`/runways/${teamId}/namespaces`);
}

const _navigateToNamespaceCreatePage = (props: onClickParams) => {
    let history = props.history;
    let teamId = props.otherParams.teamId
    history.push(
        `/runways/${teamId}/namespaces/create`
    );
}

const _loadNamespaces = async (props: onClickParams) => {
    let dispatch = props.dispatch;
    let isMounted = props.isMounted;
    let setLoading = props.setLoading;
    let teamId = props.otherParams.teamId
    try {
        setIfMounted(isMounted.current, setLoading, true);
        await dispatch(fetchAllNamespaces(teamId));
        setIfMounted(isMounted.current, setLoading, false);
    } catch (error) {
        setIfMounted(isMounted.current, setLoading, false);
        dispatch(showSnackBar(error.message))
    }
}

const _constructNavItem = (namespaceItem: any) => {
    return !isEmpty(namespaceItem.accountTypeMap.gamma) || !isEmpty(namespaceItem.accountTypeMap.prod) ? {
        displayName: namespaceItem.namespace,
        icon: <NavDashIcon style={colorWhite}/>,
        hasSubNav: false,
        labelOnClick: (props:onClickParams) => {
            let history = props.history;
            let teamId = props.otherParams.teamId
            history.push(`/runways/${teamId}/namespaces/${namespaceItem.namespaceId}?domain=PROD`);
        }
    } : {}
}

const RunwaysNavItem: NavItem = {
    displayName: "Runways",
    icon: <NavRunwaysIcon style={colorWhite}/>,
    labelOnClick: _navigateToNamespacesPage,
    hasSubNav: true,
    subNavItems: [
        {
            ...NamespaceItem,
            labelOnClick: _navigateToNamespacesPage,
            hasSubNav: true,
            onClick: _loadNamespaces,
            subNavSelector: allNamespacesSelector,
            navItemConstructor: _constructNavItem
        },
        {
            ...CreateNamespaceItem,
            labelOnClick: _navigateToNamespaceCreatePage,
            hasSubNav: false
        }
    ]
}

export default RunwaysNavItem;
