import { KatButton, KatCard } from "@amzn/katal-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { loadModelTrainingNamespace } from "src/control/actions/modelTraining/namespace_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { namespaceSelector } from "src/control/selectors/modelTraining/model_training_selectors";
import {
  FormDropDown,
  FormInput,
} from "src/view/style/modelTraining/form_input_styles";
import Collapsible from "../../commons/collapsible";
import { getEmptyStringValue, getValueOrEmptyPlaceholder, isEmpty } from "src/utils/common_utils";
import { GreenSpinner } from "src/view/style/header_styles";
import {
  getJob,
  getJobLatestVersionData,
  updateJob,
} from "../../../control/actions/runways/job_actions";
import {
  isJobSpinnerLoadingSelector,
  jobDataSelector,
  jobLatestVersionDataSelector,
} from "../../../control/selectors/runways/job_selectors";
import { UPDATE_JOB_VERSION_IN_DAG_SUCCESS } from "src/control/actions/action_types";
import { Success } from "src/utils/action_utils";
import JobComponent from "../job_components";
import {
  closeVersionCheckPopUp,
  getJobTemplatesOptions,
  showVersionCheckPopUp,
} from "../constants";
import {
  isJobTemplateSpinnerLoadingSelector,
  jobTemplateDataSelector,
  jobTemplateListSelector,
} from "src/control/selectors/runways/job_template_selectors";
import {
  getJobTemplate,
  listJobTemplates,
} from "src/control/actions/runways/job_template_actions";
import { showSnackBar } from "src/control/actions/commons/snack_bar_actions";
import { CrossIcon } from "src/view/style/icons";
import { AddTagButton, InputTag, TagBox } from "src/view/style/runway_styles";
import {ArtifactInfo, initialJobState, ServiceType} from "src/model/runways/job_model";
import {
  isValidScheduleFrequency,
  isValidOffsetMinutes,
  isValidDependentOffsetMinutes,
  isValidDependentInstanceCount,
  isValidDependentInstanceFrequency,
  validatePrerequisites,
} from "src/view/validation/runways/job_validations";
import { DisplayError } from "src/view/commons/display_error";
import { getJobVersionDropdownOptions, VersionCheckPopUp } from "../utils";
import {OperationType} from "src/constant";

/**
 * renders the view for update job form
 * @param props
 * @returns JSX
 */
export default function UpdateJob(props: {
  isRunwayJob?: boolean;
  jobId?: any;
  liveVersion?: any;
  closeJobPopUp?: any;
  isFirstJob?: boolean;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const moment: any = require("moment");
  const TIMESTAMP_FORMAT = "YYYYMMDDHHmmss";
  const DISPLAY_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm";
  const params: any = useParams();

  //selectors
  let fetchedNamespace = useSelector(namespaceSelector);
  let userName = useSelector(usernameSelector);
  let jobTemplateList = useSelector(jobTemplateListSelector);
  let jobTemplateData = useSelector(jobTemplateDataSelector);
  let jobData = useSelector(jobDataSelector);
  let isSpinnerLoading = useSelector(isJobSpinnerLoadingSelector);
  let isJobTemplateSpinnerLoading = useSelector(
    isJobTemplateSpinnerLoadingSelector
  );
  let latestVersionJobData = useSelector(jobLatestVersionDataSelector);

  let latestVersion = latestVersionJobData.latestVersion;
  let version = jobData.version;

  //state hooks
  const [customTags, setCustomTags] = useState<string[]>([]);
  const [tag, setTag] = useState("");
  const [scheduleFrequencyInMins, setScheduleFrequencyInMins] = useState(60);
  const [offsetInfo, setOffsetInfo] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [baseTimestamp, setBaseTimestamp] = useState(
    moment("20220101000000", TIMESTAMP_FORMAT).toDate()
  );
  const [service, setService] = useState<any>({});
  const [artifactInfo, setArtifactInfo] = useState<any>(initialJobState.fetchedJob.artifactInfo);
  const [dependencyOffsetInMins, setDependencyOffsetInMins] =
    useState("0");
  const [dependentInstancesCount, setDependencyInstanceCount] = useState("1");
  const [
    dependencyInstanceFrequencyInMins,
    setDependencyInstanceFrequencyInMins,
  ] = useState("");
  const [isTemplatized, setIsTemplatized] = useState(false);
  const [jobTemplate, setJobTemplate] = useState("NONE");
  const [jobTemplateId, setJobTemplateId] = useState("");
  const [isValidationSuccess, setIsValidationSuccess] = useState(true);
  const [versionCheckPopUp, setVersionCheckPopUp] = useState(false);

  //useEffect hooks
  useEffect(() => {
    dispatch(getJobLatestVersionData(props.jobId));
    dispatch(listJobTemplates());
    dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
  }, [userName]);

  useEffect(() => {
    if (props.isRunwayJob) {
      if (isTemplatized && !isEmpty(jobTemplateId)) {
        dispatch(getJobTemplate(jobTemplateId));
      } else {
        if (isEmpty(jobTemplateId)) {
          dispatch(getJob(props.jobId,props.liveVersion));
        }
      }
    } else {
      dispatch(getJob(params.jobJobId));
    }
  }, [userName, isTemplatized, jobTemplateId]);

  useEffect(() => {
    if (isTemplatized) {
      if (jobTemplateData.serviceType === jobData.serviceType)
        setInitialJobTemplateData();
      else {
        dispatch(showSnackBar("Can not choose. Service Type is different"));
        setJobTemplate("NONE")
      }
    } else {
      setInitialJobData();
    }
  }, [jobData, jobTemplateData]);

  useEffect(() => {
    // To adjust popUp size & zIndex of the DatePicker
    let container = document
      .querySelector<HTMLElement>(".popUpWrapper kat-modal")
      ?.shadowRoot?.querySelector<HTMLElement>(".container");
    if (container) {
      container.style.zIndex = "1200";
      let dialogContainer = container.querySelector<HTMLElement>(".dialog");
      if (dialogContainer) dialogContainer.style.minWidth = "80%";
    }
  }, []);

  /**
   * set initial job template data to job fields
   */
  const setInitialJobTemplateData = () => {
    setService(jobTemplateData.service);
    setArtifactInfo(jobTemplateData.artifactInfo);
  };

  /**
   * set initial job data to job fields
   */
  const setInitialJobData = () => {
    setCustomTags(jobData.customTags);
    setBaseTimestamp(
      moment
        .utc(jobData.jobScheduleInfo.baseTimestamp, TIMESTAMP_FORMAT)
        .format(DISPLAY_TIMESTAMP_FORMAT)
    );
    setOffsetInfo(jobData.jobScheduleInfo.offsetInfo);
    setScheduleFrequencyInMins(jobData.jobScheduleInfo.scheduleFrequencyInMins);
    setDependencyInstanceCount(getEmptyStringValue(
      jobData.jobDependencies[0]?.dependentInstancesCount
    ));
    setDependencyInstanceFrequencyInMins(getEmptyStringValue(
      jobData.jobDependencies[0]?.dependencyInstanceFrequencyInMins
    ));
    setDependencyOffsetInMins(getEmptyStringValue(
      jobData.jobDependencies[0]?.dependencyOffsetInMins
    ));
    setService(jobData.service);
    setArtifactInfo(jobData.artifactInfo);
  };

  const submitUpdateJobForm = async () => {
    if (
      !validatePrerequisites(
        {
          jobName: jobData.jobName,
          scheduleFrequencyInMins,
          offsetInfo,
          dependencyOffsetInMins,
          dependentInstancesCount,
          dependencyInstanceFrequencyInMins,
          jobData,
          serviceType: jobData.serviceType,
          service,
          artifactInfo,
        },
        props.isFirstJob
      )
    ) {
      setIsValidationSuccess(false);
    } else {
      setIsValidationSuccess(true);
      let latestData: any = await dispatch(getJobLatestVersionData(props.jobId));
      if (version !== latestData.latestVersion) {
        setVersionCheckPopUp(true);
      } 
      else {
        try {
          await dispatch(
            updateJob(
              props.jobId,
              userName,
              service,
              artifactInfo as ArtifactInfo,
              {
                scheduleFrequencyInMins: scheduleFrequencyInMins,
                offsetInfo: offsetInfo,
                baseTimestamp: Number(
                  moment(baseTimestamp).format(TIMESTAMP_FORMAT)
                ),
              },
              props.isFirstJob
                ? []
                : [
                    {
                      dependencyOffsetInMins: Number(dependencyOffsetInMins),
                      dependentInstancesCount: Number(dependentInstancesCount),
                      dependencyInstanceFrequencyInMins: Number(
                        dependencyInstanceFrequencyInMins
                      ),
                    },
                  ],
              customTags
            )
          );
          if (props.isRunwayJob) {
            props.closeJobPopUp();
          } else {
            let currentPath = window.location.pathname.replace(
              "update",
              "view"
            );
            history.push(currentPath);
          }
        } catch (err) {
          console.log(err);
        }
      }
    }
  };

  /**
   * deletes the tag from customTags set
   * @param tag: tag to be removed
   */
  const removeTag = (tag: string) => {
    const filteredCustomTags = customTags.filter((item) => {
      return tag !== item;
    });
    setCustomTags(filteredCustomTags);
  };

  /**
   * renders the available tag set into a block of tag divs
   * @returns JSX: array of tag divs
   */
  const getCustomTags = () => {
    if (customTags.length > 0) {
      return customTags.map((tagValue) => {
        return (
          <TagBox>
            <CrossIcon
              style={{ cursor: "hand", color: "red" }}
              size={"15px"}
              onClick={() => {
                removeTag(tagValue);
              }}
            />
            &nbsp;<span>{tagValue}</span>
          </TagBox>
        );
      });
    } else {
      return <p style={{ opacity: "0.6" }}>Add tags if any</p>;
    }
  };

  const getLiveVersionComponent = () => {
    if (latestVersion !== -1) {
      return (
        <div style={{ float: "left", marginTop: "30px", marginLeft: "15px" }}>
          <KatButton
            onClick={() => {
              dispatch(
                Success(UPDATE_JOB_VERSION_IN_DAG_SUCCESS, {
                  jobId: jobData.jobId,
                  version: version,
                })
              );

              props.closeJobPopUp();
            }}
          >
            Set version as Live
          </KatButton>
        </div>
      );
    } else {
      return (
        <div style={{clear: "both"}}>
          NOTE: Please refresh to load other versions
        </div>
      );
    }
  };

  if (
    isEmpty(fetchedNamespace.namespaceData.namespace) ||
    isSpinnerLoading ||
    isJobTemplateSpinnerLoading 
  ) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
    <>
      {versionCheckPopUp && (
        <VersionCheckPopUp
          popUpVisible={versionCheckPopUp}
          setPopUpVisible={setVersionCheckPopUp}
          showPopUp={() => showVersionCheckPopUp(setVersionCheckPopUp)}
          closePopUp={() => closeVersionCheckPopUp(setVersionCheckPopUp)}
          continueActionMethod={async () => {
            try {
              setIsValidationSuccess(true);
              await dispatch(
                updateJob(
                  props.jobId,
                  userName,
                  service,
                  artifactInfo as ArtifactInfo,
                  {
                    scheduleFrequencyInMins: scheduleFrequencyInMins,
                    offsetInfo: offsetInfo,
                    baseTimestamp: Number(
                      moment(baseTimestamp).format(TIMESTAMP_FORMAT)
                    ),
                  },
                  props.isFirstJob
                    ? []
                    : [
                        {
                          dependencyOffsetInMins: Number(
                            dependencyOffsetInMins
                          ),
                          dependentInstancesCount: Number(
                            dependentInstancesCount
                          ),
                          dependencyInstanceFrequencyInMins: Number(
                            dependencyInstanceFrequencyInMins
                          ),
                        },
                      ],
                  customTags
                )
              );
              if (props.isRunwayJob) {
                props.closeJobPopUp();
              } else {
                let currentPath = window.location.pathname.replace(
                  "update",
                  "view"
                );
                history.push(currentPath);
              }
            } catch (err) {
              console.log(err);
            }
          }}
          cancelActionMethod={() =>
            closeVersionCheckPopUp(setVersionCheckPopUp)
          }
        ></VersionCheckPopUp>
      )}
      
      <FormDropDown
        label="Select from existing templates"
        searchable={true}
        options={getJobTemplatesOptions(jobTemplateList)}
        value={jobTemplate}
        tooltipText="click to select from existing templates"
        placeholder="Select from existing templates"
        onChange={(event: any) => {
          setJobTemplate(event.target.value);
          if (event.target.value !== "NONE") {
            setIsTemplatized(true);
            setJobTemplateId(event.target.value);
          } else {
            setIsTemplatized(false);
          }
        }}
      ></FormDropDown>
      <br />

      <KatCard>
        <span slot="title"> Update Job</span>

        <FormInput
          label="Namespace Name"
          type="text"
          placeholder="Namespace name"
          value={fetchedNamespace.namespaceData.namespace}
          tooltipText="Namespace Name"
          disabled={true}
        />

        <FormInput
          label="Job Name"
          type="text"
          placeholder="Job name"
          value={jobData.jobName}
          disabled={true}
        />

        <div>
          <span>Custom Tags: </span>

          <div style={{ display: "flex", float: "right" }}>
            <InputTag
              required
              value={tag}
              style={{ float: "right" }}
              placeholder="Add tag"
              onChange={(event: any) => setTag(event.target.value)}
            />
            <AddTagButton
              onClick={() => {
                if (customTags.includes(tag))
                  dispatch(showSnackBar("The tag is already present"));
                else if (isEmpty(tag))
                  dispatch(showSnackBar("value can't be empty"));
                else setCustomTags([...customTags, tag]);
                setTag("");
              }}
            >
              ADD
            </AddTagButton>
          </div>
        </div>

        {getCustomTags()}

        <br />
        <div>
          <div style={{ float: "left" }}>
            <FormDropDown
              label="Version"
              searchable={true}
              value={version}
              placeholder={
                latestVersion === -1
                  ? `${version} (Could not load other versions)`
                  : "Select versions"
              }
              disabled={latestVersion === -1}
              maxHeight="100px"
              onChange={(event: any) => {
                dispatch(getJob(jobData.jobId, event.target.value));
              }}
            >
              {getJobVersionDropdownOptions(latestVersion, props.liveVersion)}
            </FormDropDown>
          </div>
          {getLiveVersionComponent()}
        </div>
      </KatCard>

      <Collapsible label="Scheduling">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            variant="inline"
            ampm={false}
            label="Job base time (UTC)"
            value={baseTimestamp}
            onChange={(date: MaterialUiPickersDate) => {
              if (date) setBaseTimestamp(date);
            }}
            format="yyyy/MM/dd HH:mm"
          />
        </MuiPickersUtilsProvider>

        <FormInput
          label="Scheduling frequency(in minutes)"
          type="number"
          placeholder="Scheduling frequency"
          min={1}
          value={scheduleFrequencyInMins.toString()}
          tooltipText="Volume size"
          onChange={(event: any) =>
            setScheduleFrequencyInMins(parseInt(event.target.value))
          }
        />
        {!isValidationSuccess &&
          !isValidScheduleFrequency(scheduleFrequencyInMins) && (
            <DisplayError message="field can't be empty or negative" />
          )}

        <FormInput
          label="Offset (in minutes)"
          type="number"
          value={offsetInfo.minutes.toString()}
          tooltipText="Timestamp offset for the scheduled executions. Ex: if offset is -60, a job executed at 5:00 hrs, will resolve the timestamp for jobData as 4:00 hrs"
          onChange={(event: any) =>
            setOffsetInfo({
              days: 0,
              hours: 0,
              minutes: parseInt(event.target.value),
            })
          }
        />
        {!isValidationSuccess &&
          !isValidOffsetMinutes(offsetInfo.minutes.toString()) && (
            <DisplayError message="field can't be empty" />
          )}
        <br />
      </Collapsible>

      {!props.isFirstJob && (
        <Collapsible label="Dependency">
          <FormInput
            label="Offset (in minutes)"
            type="number"
            value={dependencyOffsetInMins.toString()}
            onChange={(event: any) =>
              setDependencyOffsetInMins(event.target.value)
            }
          ></FormInput>
          {!isValidationSuccess &&
            !isValidDependentOffsetMinutes(dependencyOffsetInMins.toString()
            ) && <DisplayError message="field can't be empty" />}

          <FormInput
            label="Instance Count"
            type="number"
            value={dependentInstancesCount.toString()}
            onChange={(event: any) =>
              setDependencyInstanceCount(event.target.value)
            }
          ></FormInput>
          {!isValidationSuccess &&
            !isValidDependentInstanceCount(dependentInstancesCount.toString()
            ) && <DisplayError message="field can't be empty" />}

          <FormInput
            label="Instance Frequency (in minutes)"
            type="number"
            value={dependencyInstanceFrequencyInMins.toString()}
            onChange={(event: any) =>
              setDependencyInstanceFrequencyInMins(event.target.value)
            }
          ></FormInput>
          {!isValidationSuccess &&
            !isValidDependentInstanceFrequency(
              dependencyInstanceFrequencyInMins
            ) && <DisplayError message="field can't be empty or negative" />}
        </Collapsible>
      )}

      <JobComponent
        operationType={OperationType.UPDATE}
        serviceType={jobData.serviceType}
        setServiceType={() => {}}
        service={service}
        setService={setService}
        artifactInfo={artifactInfo}
        setArtifactInfo={setArtifactInfo}
        jobName = {jobData.jobName}
      />

      <KatButton
        type="submit"
        label="Submit"
        loading={isSpinnerLoading}
        onClick={submitUpdateJobForm}
      ></KatButton>
      <br />
      <br />
    </>
  );
}
