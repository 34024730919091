export const snackBarInitialState = {
    message: ""
}

export const teamInitialState = {
    allTeams: [],
    team: null
}

export const userInitialState = {
    username: null
}

export const regionInitialState = {
    region: null,
    showRegions: false,
    supportedRegions: [],
    loaded: false
}