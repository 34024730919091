import {Failure, Request, Success} from "src/utils/action_utils";
import {
    FETCH_ALL_CONFIG_NAMESPACES_FAILURE,
    FETCH_ALL_CONFIG_NAMESPACES_SUCCESS,
    FETCH_ALL_FEATURE_NAMESPACES_FAILURE,
    FETCH_ALL_FEATURE_NAMESPACES_REQUEST, FETCH_ALL_FEATURE_NAMESPACES_SUCCESS,
} from "src/control/actions/action_types";
import {apiGetPromise} from "src/utils/api_handler";
import {getFetchFeatureGenerationNamespaceTarget} from "src/control/targets";
import {ERROR_NAMESPACES_NOT_FOUND, ERROR_WHILE_FETCHING_NAMESPACE_INFO} from "src/control/errors";
import {isEmpty} from "src/utils/common_utils";

export const fetchAllNamespaces = (teamId: string) => async (dispatch: any) => {
    dispatch(Request(FETCH_ALL_FEATURE_NAMESPACES_REQUEST));
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getFetchFeatureGenerationNamespaceTarget(teamId));
            if(!isEmpty(data) && !isEmpty(data.namespaceList)){
                dispatch(Success(FETCH_ALL_FEATURE_NAMESPACES_SUCCESS, data.namespaceList));
                resolve();
                return;
            }
            let error = ERROR_NAMESPACES_NOT_FOUND;
            dispatch(Failure(FETCH_ALL_FEATURE_NAMESPACES_FAILURE, error));
            reject(error);
        } catch (err) {
            let error = ERROR_WHILE_FETCHING_NAMESPACE_INFO;
            dispatch(Failure(FETCH_ALL_FEATURE_NAMESPACES_FAILURE, err));
            reject(error);
        }
    });
}