import {VCancelPopUpButton, VInput, VKey, VSubmitPopUpButton, VTable, VValue} from "src/view/style/view_config_styles";
import {TableBody, TableRow} from "src/view/style/table_styles";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import React, {useRef, useState} from "react";
import {CancelIcon, colorWhite, UploadIcon} from "src/view/style/icons";
import {SetFunction} from "src/view/dataset/dataset_constants";
import {registerCodeRepository} from "src/control/actions/codeRepository/code_repository_actions";
import {RepositoryType} from "src/model/runways/job_model";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";

export interface S3RepoFormProps {
    setVisibility: SetFunction<boolean>,
    setRepoNameWrapper: SetFunction<string>
}

const S3RepoRegisterForm = (props: S3RepoFormProps) => {
    const dispatch = useDispatch();
    const userName = useSelector(usernameSelector);
    const [bucketName, setBucketName] = useState("");
    const _isMounted = useRef(true);

    const isParamsValid = () => {
        return !isEmpty(bucketName)
    }

    const handleCreate = () => {
        props.setRepoNameWrapper(bucketName)
        dispatch(registerCodeRepository({
            requestedBy: userName,
            codeRepository: {
                repositoryType: RepositoryType.S3,
                s3Repository: {
                    bucketName: bucketName
                }
            }
        }))
    }

    return (
        <>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <VTable>
                            <TableBody>
                                <TableRow>
                                    <VKey>Bucket Name *</VKey>
                                    <VValue>
                                        <VInput
                                            required
                                            value={bucketName}
                                            placeholder={"Bucket Name"}
                                            onChange={(event: any) => setIfMounted(
                                                _isMounted.current,
                                                setBucketName,
                                                event.target.value
                                            )
                                            }
                                        />
                                    </VValue>
                                </TableRow>
                            </TableBody>
                        </VTable>
                    </div>
                </div>
                <br/>
            </div>
            <div slot="footer" className="kat-group-horizontal">
                <VCancelPopUpButton
                    onClick={() => props.setVisibility(false)}
                    variant={"danger"}
                >
                    <CancelIcon style={colorWhite} size={null}/>
                    &nbsp; &nbsp; Cancel
                </VCancelPopUpButton>
                <VSubmitPopUpButton onClick={handleCreate} disabled={!isParamsValid()}>
                    <UploadIcon style={colorWhite} size={null}/>
                    &nbsp;&nbsp; Create
                </VSubmitPopUpButton>
            </div>
        </>
    )
}

export default S3RepoRegisterForm