import {LOCATION_CHANGE} from "connected-react-router";
import {loadRegion, setRegionLoaded, showRegionDropDown} from "src/control/actions/commons/regions_actions";
import {FLOWS, getFlowFromURL} from "src/utils/url_utils";
import {LOAD_REGION_SUCCESS} from "src/control/actions/action_types";

const RegionControlFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const flow = getFlowFromURL(window.location.pathname);
    const nextAction = next(action)
    if (action.type === LOCATION_CHANGE) {
        if(flow === FLOWS.MODEL_TRAINING || flow === FLOWS.NOTEBOOKS || flow == FLOWS.RUNWAYS){
            dispatch(showRegionDropDown(true));
            dispatch(setRegionLoaded(false));
            dispatch(loadRegion());
        }
        else{
            dispatch(showRegionDropDown(false));
        }
    }
    return nextAction
};

const RegionViewFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if (action.type === LOAD_REGION_SUCCESS) {
            dispatch(setRegionLoaded(true));
    }
    return nextAction
};

export default [
    RegionControlFlow,
    RegionViewFlow
]

