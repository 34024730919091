import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useRef, useState} from "react";
import {TableBody, TableRow} from "src/view/style/table_styles";
import {
    PopUp,
    VCancelPopUpButton,
    VInput,
    VKey,
    VStatusIndicator,
    VSubmitPopUpButton,
    VTable,
    VValue
} from "src/view/style/view_config_styles";
import {isEmpty, isValidAWSAccountId, isValidKMSArn, isValidS3Path, setIfMounted} from "src/utils/common_utils";
import {CancelIcon, colorWhite, UploadIcon} from "src/view/style/icons";
import {
    BannerTypes,
    CreateDatasetInfoResponse,
    RequestStatus,
    SetFunction
} from "src/view/dataset/dataset_constants";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {createDataset} from "src/control/actions/dataset/dataset_actions";


export interface CreateDatasetProps {
    visibility: boolean,
    setVisiblity: SetFunction<boolean>,
    setBannerType: SetFunction<BannerTypes>,
    setBannerMessage: SetFunction<string>,
    setBannerHeading: SetFunction<string>,
    setBannerVisibility: SetFunction<boolean>
}

//TODO: Add Template Validation For S3Path
export default function CreateDataset(props: CreateDatasetProps) {
    const dispatch = useDispatch();
    const userName = useSelector(usernameSelector);
    const [datasetName, setDatasetName] = useState("");
    const [s3Path, setS3Path] = useState("");
    const [accountId, setAccountId] = useState("");
    const [encryptionKMSArn, setEncryptionKMSArn] = useState("");
    const [isS3PathValid, setIsS3PathValid] = useState(false);
    const [isAccountIdValid, setIsAccountIdValid] = useState(false);
    const [isEncryptionKMSArnValid, setIsEncryptionKMSArnValid] = useState(false);


    const _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    });


    const handleCreate = () => {
        dispatch(createDataset(
            (data: CreateDatasetInfoResponse) => {
                if(RequestStatus.Success == data.requestStatus) {
                    props.setBannerType(BannerTypes.SUCCESS);
                    props.setBannerHeading(`[Success] Created: ${data.datasetInfoName}`);
                } else if (RequestStatus.AlreadyExists == data.requestStatus) {
                    props.setBannerType(BannerTypes.WARNING);
                    props.setBannerHeading(`[Warning] Already Exists: ${data.datasetInfoName}`);
                }
                props.setBannerMessage(`Message: ${data.message}`)
                props.setBannerVisibility(true);
            },
            (error: any) => {
                props.setBannerType(BannerTypes.DANGER);
                props.setBannerHeading(`[Failure] Encountered Error: ${datasetName}`)
                props.setBannerMessage(`Message: ${error.message}`)
                props.setBannerVisibility(true);
            },
            {
                "datasetInfoName": datasetName,
                "accountId": accountId,
                "s3PathTemplate": s3Path,
                "requestedBy": userName,
                "encryptionKMSArn": isEmpty(encryptionKMSArn) ? null: encryptionKMSArn
            }
        ));
        props.setBannerHeading(`Creation Request For ${datasetName} initiated`)
        props.setBannerType(BannerTypes.INFO)
        props.setBannerVisibility(true);
        props.setVisiblity(false);
    }

    const isParamsValid = () => {
        let isKMSValid = (isEmpty(encryptionKMSArn) || (!isEmpty(encryptionKMSArn) && isEncryptionKMSArnValid))
        return !isEmpty(datasetName) &&
            isS3PathValid &&
            isAccountIdValid &&
            isKMSValid
    }

    return (
        <>
            <PopUp visible={props.visibility}
                   onOpen={() => props.setVisiblity(true)}
                   onClose={() => props.setVisiblity(false)}
            >
                <span slot={"title"}>Create Dataset</span>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <VTable>
                                <TableBody>
                                    <TableRow>
                                        <VKey>Dataset Name *</VKey>
                                        <VValue>
                                            <VInput
                                                required
                                                value={datasetName}
                                                placeholder={"Dataset Name"}
                                                onChange={(event: any) => setIfMounted(
                                                    _isMounted.current,
                                                    setDatasetName,
                                                    event.target.value)
                                                }
                                            />
                                        </VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>S3 Path *
                                            &nbsp; &nbsp; &nbsp;
                                            {!isEmpty(s3Path) && <VStatusIndicator
                                                variant={isS3PathValid? "success": "error"}
                                                label={""}
                                            />}

                                        </VKey>
                                        <VValue>
                                            <VInput
                                                required
                                                value={s3Path}
                                                placeholder={"S3 Path"}
                                                onInput={(event: any) => {
                                                    setIfMounted(
                                                        _isMounted.current,
                                                        setS3Path,
                                                        event.target.value
                                                    );
                                                    setIfMounted(
                                                        _isMounted.current,
                                                        setIsS3PathValid,
                                                        isValidS3Path(event.target.value)
                                                    );
                                                }}
                                            />
                                        </VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Account ID *
                                            &nbsp; &nbsp; &nbsp;
                                            {!isEmpty(accountId) && <VStatusIndicator
                                                variant={isAccountIdValid? "success": "error"}
                                                label={""}
                                            />}
                                        </VKey>
                                        <VValue>
                                            <VInput
                                                required
                                                value={accountId}
                                                placeholder={"Account ID"}
                                                onInput={(event: any) => {
                                                        setIfMounted(
                                                            _isMounted.current,
                                                            setAccountId,
                                                            event.target.value
                                                        )
                                                        setIfMounted(
                                                            _isMounted.current,
                                                            setIsAccountIdValid,
                                                            isValidAWSAccountId(event.target.value)
                                                        )
                                                    }
                                                }
                                            />
                                        </VValue>
                                    </TableRow>
                                    <TableRow>
                                        <VKey>Encryption KMS ARN (recommended)
                                            &nbsp; &nbsp; &nbsp;
                                            {!isEmpty(encryptionKMSArn) && <VStatusIndicator
                                                variant={isEncryptionKMSArnValid? "success": "error"}
                                                label={""}
                                            />}
                                        </VKey>
                                        <VValue>
                                            <VInput
                                                value={encryptionKMSArn}
                                                placeholder={"Encryption KMS ARN"}
                                                onInput={(event: any) => {
                                                        setIfMounted(
                                                            _isMounted.current,
                                                            setEncryptionKMSArn,
                                                            event.target.value
                                                        );
                                                        setIfMounted(
                                                            _isMounted.current,
                                                            setIsEncryptionKMSArnValid,
                                                            isValidKMSArn(event.target.value)
                                                        )

                                                    }
                                                }
                                            />
                                        </VValue>
                                    </TableRow>
                                </TableBody>
                            </VTable>
                        </div>
                    </div>
                    <br/>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <b>Note: </b> <i>Once created, datasets cannot be edited/ deleted. Ensure proper details are provided.</i>
                        </div>
                    </div>
                </div>
                <div slot="footer" className="kat-group-horizontal">
                    <VCancelPopUpButton
                        onClick={() => props.setVisiblity(false)}
                        variant={"danger"}

                    >
                        <CancelIcon style={colorWhite} size={null}/>
                        &nbsp; &nbsp; Cancel
                    </VCancelPopUpButton>
                    <VSubmitPopUpButton onClick={handleCreate} disabled={!isParamsValid()}>
                        <UploadIcon style={colorWhite} size={null}/>
                        &nbsp;&nbsp; Create
                    </VSubmitPopUpButton>
                </div>
            </PopUp>
        </>
    );
}