import {
    DATASET_FETCH_ALL_FAILURE,
    DATASET_FETCH_ALL_SUCCESS,
    DATASET_FETCH_ALL_REQUEST
} from "../../actions/action_types";
import {datasetInitialState} from "src/model/dataset/dataset_model"

export default function (state = datasetInitialState, action: any) {
    switch (action.type) {
        case DATASET_FETCH_ALL_SUCCESS:
            return {
                ...state,
                allDatasets: action.payload
            }

        case DATASET_FETCH_ALL_FAILURE:
            return {
                ...state,
                allDatasets: []
            }

        case DATASET_FETCH_ALL_REQUEST:
            return {
                ...state,
                allDatasets: []
            }

        default:
            return state;
    }
}
