export const allNamespacesSelector = (state: any) => state.configPanel.namespaces.namespaceList;

export const allConfigsSelector = (state: any) => state.configPanel.configs.configList;

export const allDomainsSelector = (state: any) => state.configPanel.configs.domainList;

export const configBodySelector = (state: any) => state.configPanel.configs.fetchedConfig.configBody;
export const configMetadataSelector = (state: any) => state.configPanel.configs.fetchedConfig.configMetadata;

export const versionsSelector = (state: any) => state.configPanel.configs.versions;

export const updateConfigMetadataSelector = (state: any) => state.configPanel.configs.updateConfigMetadata;

export const configValidationSelector = (state: any) => state.configPanel.configs.fetchConfigValidation;
export const validationTriggeredSelector = (state: any) => state.configPanel.configs.startConfigValidation;
