import React, {useEffect, useRef, useState} from 'react';
import AceEditor from 'react-ace';
import {setIfMounted} from "src/utils/common_utils";
import 'brace/theme/github';
import 'brace/mode/json';
import 'brace/ext/searchbox';


type JSONEditorOnChangeCallBack = (name: string, value: any) => void;

export interface JSONEditorProps {
    fieldName: string,
    value: string,
    onChange?: JSONEditorOnChangeCallBack,
    readOnly?: boolean,
    width?: string,
    height?: string
}

export default function JSONEditor(props: JSONEditorProps){

    const [stringValue, setStringValue] = useState(props.value);
    const _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        };
    });

    useEffect(
        () => {
            setIfMounted(_isMounted.current, setStringValue, props.value)
        },
        [props]
    )


    const setValue = (stringValue: string) => {
        props.onChange && props.onChange(props.fieldName, stringValue);
        setIfMounted(_isMounted.current, setStringValue, stringValue);
    }

    const display = () => {
        const fieldName = props.fieldName;
        const readOnly = props.readOnly;
        const width = props.width ? `${props.width}` : '800px';
        const height = props.height ? `${props.height}` : '400px';

        return (<AceEditor
            mode="json"
            theme="github"
            name={`${fieldName}`}
            editorProps={{$blockScrolling: true}}
            fontSize="12px"
            width={width}
            height={height}
            showGutter={true}
            showPrintMargin={false}
            wrapEnabled={true}
            onChange={setValue}
            value={stringValue}
            readOnly={readOnly}
        />);
    }

    return (
        <>
            {display()}
        </>
    )
}