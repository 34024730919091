import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    KatButton,
    KatCard,
    KatDivider,
    KatFlashbar,
    KatLabel,
    KatRadiobuttonGroup,
    KatSpinner
} from "@amzn/katal-react";
import React, {useEffect, useRef, useState} from "react";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles";
import {extractVersionIdFromSignedUrl, isEmpty, setIfMounted} from "src/utils/common_utils";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import JSONEditor from "src/view/commons/json_editor";
import {loadModelTrainingNamespace,} from "src/control/actions/modelTraining/namespace_actions";
import {
    createRunwaySelector,
    namespaceSelector,
    runwaySelector,
    trainingImagesSelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {fetchAllDatasets} from "src/control/actions/dataset/dataset_actions";
import {Dataset, DatasetDefaultConstants} from "src/view/dataset/dataset_constants";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {allDatasetsSelector} from "src/control/selectors/dataset/dataset_selectors";
import {createRunway, loadRunway, loadTrainingImages} from "src/control/actions/modelTraining/runway_actions";
import {INSTANCE_TYPES} from "src/view/modelTraining/experiments/instance_types";
import {
    CREATE_RUNWAY_FAILURE_MESSAGE,
    CREATE_RUNWAY_SUCCESS_MESSAGE,
    CUSTOM_TRAINING_IMAGE,
    getEcrReposOptions,
    NO_DATASETS_FOUND_MESSAGE,
    NO_ONBOARDED_PACKAGES_MESSAGE,
    Stages,
} from "src/view/modelTraining/constants";
import {StepInfo} from "src/view/modelTraining/runways/step_info";
import {VStatusIndicator} from "src/view/style/view_config_styles";
import {convertToJsonWithStrings, getPrettyJsonString, isValidJSON} from "src/utils/json_utils";
import {getFeaturesAsList, isValidFeatureList} from "src/view/modelTraining/runways/commons";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {DEFAULT_BRANCH, JobStep, LATEST_CODE_VERSION, ModelTrainingStep} from "src/constant";
import {getParamFromQueryString} from "src/utils/url_utils";
import {getCodeVersions, loadCodeRepositories} from "src/control/actions/codeRepository/code_repository_actions";
import {RepositoryType} from "src/model/runways/job_model";
import {
    allCodeRepositoriesSelector,
    executionCodeVersionsSelector,
    postExecutionCodeVersionsSelector,
    preExecutionCodeVersionsSelector
} from "src/control/selectors/codeRepository/code_repo_selectors";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

const moment = require("moment")

const CreateV1Runway = () => {
    // runwayId for the cloning runway
    const baseRunwayId = getParamFromQueryString(location.search, "runwayId") as string;
    const params: any = useParams();
    const dispatch = useDispatch();
    const TIMESTAMP_FORMAT = "YYYYMMDDHHmmss"

    // Selectors
    let trainingImagesInfo = useSelector(trainingImagesSelector);
    let userId = useSelector(usernameSelector);
    let datasets: Dataset[] = useSelector(allDatasetsSelector);
    const fetchedNamespace = useSelector(namespaceSelector);
    let createRunwayData = useSelector(createRunwaySelector)
    let codeRepositoriesSelector = useSelector(allCodeRepositoriesSelector);

    let preTrainCodeVersions = useSelector(preExecutionCodeVersionsSelector);
    let postTrainCodeVersions = useSelector(postExecutionCodeVersionsSelector);
    let trainCodeVersions = useSelector(executionCodeVersionsSelector)
    let _isMounted = useRef(true);
    let runwayData = useSelector(runwaySelector);

    // State hooks for general runway information
    const [domain, setDomain] = useState('');
    const [runwayName, setRunwayName] = useState('');
    const [enableCustomImageInput, setEnableCustomImageInput] = useState(false);
    const [instanceType, setInstanceType] = useState('ml.t2.medium');
    const [trainingImage, setTrainingImage] = useState('');
    const [instanceCount, setInstanceCount] = useState("1");
    const [volumeSize, setVolumeSize] = useState("50");
    const [trainingExecutionRoleARN, setTrainingExecutionRoleARN] = useState();
    const [hyperParameters, setHyperParameters] = useState('{}');
    const [inputFeatures, setInputFeatures] = useState('');
    const [responseVariables, setResponseVariables] = useState('');
    const [modelTrainingMetadata, setModelTrainingMetadata] = useState('{}');
    const [modelOutputPath, setModelOutputPath] = useState('');
    const [scheduleFrequencyInMins, setScheduleFrequencyInMins] = useState(60)
    const [offsetInfo, setOffsetInfo] = useState({days: 0, hours: 0, minutes: 0})
    const [customCodeRepository, setCustomCodeRepository] = useState('')
    const [codeRepoOptions, setCodeRepoOptions] = useState<any[]>([]);
    const [ecrRepo, setEcrRepo] = useState('')
    const [baseTimestamp, setBaseTimestamp] = useState(moment('20220101000000', TIMESTAMP_FORMAT).toDate());
    const DISPLAY_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss"

    // State for step specific information
    const [stepInfo, setStepInfo] = useState<StepInfo>({
        preTrain: {
            preTrainCustomCodeURI: "",
            codeVersion: "",
            modelAgeThreshold: -1
        },
        train: {
            inputChannelInfoList: [],
            trainCustomCodeURI: "",
            codeVersion: "",
        },
        postTrain: {
            deployArtifactName: "",
            deploymentBucket: "",
            deploymentKey: "",
            thresholdConditionsJson: "[]",
            postTrainCustomCodeURI: "",
            codeVersion: "",
            archiveSubFolder: ""
        },
        features: {
            inputFeatures: [],
            responseVariables: []
        },
        modelTrainingMetadata: ""
    })

    // State for callback of fetch all data sets action.
    const [dataSetLoadErrorOccurred, setDataSetLoadErrorOccurred] = useState(false);

    const showMessage = createRunwayData.isSuccess || createRunwayData.isError

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    const isNullModelTrainingInfo = isEmpty(runwayData.runway.modelTrainingInfo)
    const isNullPreTrain = isNullModelTrainingInfo || isEmpty(runwayData.runway.modelTrainingInfo.preTrain)
    const isNullTrain = isNullModelTrainingInfo || isEmpty(runwayData.runway.modelTrainingInfo.train)
    const isNullPostTrain = isNullModelTrainingInfo || isEmpty(runwayData.runway.modelTrainingInfo.postTrain)
    const isNullFeatures = isNullModelTrainingInfo || isEmpty(runwayData.runway.modelTrainingInfo.features)
    const isNullModelTrainingMetadata = isNullModelTrainingInfo || isEmpty(runwayData.runway.modelTrainingInfo.modelTrainingMetadata)

    const setCodeRepositories = () => {
        const codeRepositories: any[] = []
        if (codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess) {
            codeRepositoriesSelector.codeRepositories.forEach((codeRepository: any) => {
                    let repositoryName = ""
                    if (codeRepository.codeRepository.gitRepository.syncEnabled) {
                        repositoryName = codeRepository.codeRepository.gitRepository.packageName
                    }
                    if (!isEmpty(repositoryName)) {
                        codeRepositories.push({
                            name: repositoryName,
                            value: repositoryName
                        })
                    }
                }
            )
            setCodeRepoOptions(codeRepositories)
        }
    }

    useEffect(() => {
        setCodeRepositories()
    }, [codeRepositoriesSelector]);


    useEffect(() => {
        dispatch(loadCodeRepositories({
            repositoryType: RepositoryType.GIT
        }))
    }, []);

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
            dispatch(fetchAllDatasets(() => {
                },
                (error: any) => {
                    setIfMounted(_isMounted.current, setDataSetLoadErrorOccurred, true);
                    dispatch(showSnackBar(error.message));
                }, {
                    pageSize: DatasetDefaultConstants.PAGE_SIZE,
                    nextToken: null
                }))
            if (!isEmpty(baseRunwayId)) {
                dispatch(loadRunway(baseRunwayId))
            }
        }
    }, [userId, params.teamId, params.namespaceId, params.runwayId, dispatch]);

    useEffect(() => {
        if (!isEmpty(baseRunwayId)) {
            initializeStates()
            setEcrRepo(CUSTOM_TRAINING_IMAGE)
            if (!isEmpty(runwayData.runway.modelTrainingInfo.customCodePackageName)) {
                dispatch(getCodeVersions({
                        repositoryType: RepositoryType.GIT,
                        scriptPath: runwayData.runway.modelTrainingInfo.preTrain.preTrainCustomCodeURI,
                        branch: DEFAULT_BRANCH
                    }, runwayData.runway.modelTrainingInfo.customCodePackageName, JobStep.PRE_EXECUTION
                ));

                dispatch(getCodeVersions({
                        repositoryType: RepositoryType.GIT,
                        scriptPath: runwayData.runway.modelTrainingInfo.train.trainCustomCodeURI,
                        branch: DEFAULT_BRANCH
                    }, runwayData.runway.modelTrainingInfo.customCodePackageName, JobStep.EXECUTION
                ));

                dispatch(getCodeVersions({
                        repositoryType: RepositoryType.GIT,
                        scriptPath: runwayData.runway.modelTrainingInfo.postTrain.postTrainCustomCodeURI,
                        branch: DEFAULT_BRANCH
                    }, runwayData.runway.modelTrainingInfo.customCodePackageName, JobStep.POST_EXECUTION
                ));
            }
        }
    }, [runwayData])

    const initializeStates = () => {
        if (!isNullModelTrainingInfo) {
            setIfMounted(_isMounted.current, setBaseTimestamp, isEmpty(runwayData.runway.baseTimestamp) ? baseTimestamp : moment((runwayData.runway.baseTimestamp).toString(), TIMESTAMP_FORMAT).toDate())
            setIfMounted(_isMounted.current, setScheduleFrequencyInMins, runwayData.runway.scheduleFrequencyInMins)
            setIfMounted(_isMounted.current, setOffsetInfo, isEmpty(runwayData.runway.offsetInfo) ? {
                days: 0,
                hours: 0,
                minutes: 0
            } : runwayData.runway.offsetInfo)
        }

        if (!isNullPreTrain) {
            setIfMounted(_isMounted.current, setStepInfo, (prevValue: any) => {
                return {
                    ...prevValue,
                    preTrain: {
                        ...prevValue.preTrain,
                        preTrainCustomCodeURI: runwayData.runway.modelTrainingInfo.preTrain.preTrainCustomCodeURI,
                        modelAgeThreshold: runwayData.runway.modelTrainingInfo.preTrain.modelAgeThreshold,
                        codeVersion: runwayData.runway.modelTrainingInfo.preTrain.codeVersion
                    }
                }
            })
        }

        if (!isNullTrain) {
            setIfMounted(_isMounted.current, setTrainingImage, runwayData.runway.modelTrainingInfo.train.trainingImage)
            setIfMounted(_isMounted.current, setInstanceType, runwayData.runway.modelTrainingInfo.train.instanceType)
            setIfMounted(_isMounted.current, setInstanceCount, runwayData.runway.modelTrainingInfo.train.instanceCount)
            setIfMounted(_isMounted.current, setVolumeSize, runwayData.runway.modelTrainingInfo.train.volumeSizeInGB)
            setIfMounted(_isMounted.current, setTrainingExecutionRoleARN, runwayData.runway.modelTrainingInfo.train.trainingExecutionRole)
            setIfMounted(_isMounted.current, setHyperParameters, JSON.stringify(runwayData.runway.modelTrainingInfo.train.hyperparameters, null, 2))
            setIfMounted(_isMounted.current, setModelOutputPath, runwayData.runway.modelTrainingInfo.train.modelOutputPath)
            setIfMounted(_isMounted.current, setCustomCodeRepository, runwayData.runway.modelTrainingInfo.customCodePackageName)
            setIfMounted(_isMounted.current, setStepInfo, (prevValue: any) => {
                return {
                    ...prevValue,
                    train: {
                        ...prevValue.train,
                        trainCustomCodeURI: runwayData.runway.modelTrainingInfo.train.trainCustomCodeURI,
                        inputChannelInfoList: runwayData.runway.modelTrainingInfo.train.inputChannelInfoList,
                        codeVersion: runwayData.runway.modelTrainingInfo.train.codeVersion
                    }
                }
            })
        }
        if (!isNullPostTrain) {
            setIfMounted(_isMounted.current, setStepInfo, (prevValue: any) => {
                return {
                    ...prevValue,
                    postTrain: {
                        ...prevValue.postTrain,
                        postTrainCustomCodeURI: runwayData.runway.modelTrainingInfo.postTrain.postTrainCustomCodeURI,
                        thresholdConditionsJson: getPrettyJsonString(runwayData.runway.modelTrainingInfo.postTrain.thresholdConditionsJson),
                        archiveSubFolder: runwayData.runway.modelTrainingInfo.postTrain.archiveSubFolder,
                        codeVersion: runwayData.runway.modelTrainingInfo.postTrain.codeVersion
                    }
                }
            })
        }

        if (!isNullFeatures) {
            let _inputFeatures = runwayData.runway.modelTrainingInfo.features.inputFeatures
            _inputFeatures = _inputFeatures && _inputFeatures.join(",")
            let _responseVariables = runwayData.runway.modelTrainingInfo.features.responseVariables
            _responseVariables = _responseVariables && _responseVariables.join(",")
            setIfMounted(_isMounted.current, setInputFeatures, _inputFeatures)
            setIfMounted(_isMounted.current, setResponseVariables, _responseVariables)
        }

        if (!isNullModelTrainingMetadata) {
            let _modelTrainingMetadata = runwayData.runway.modelTrainingInfo.modelTrainingMetadata;
            _modelTrainingMetadata = getPrettyJsonString(_modelTrainingMetadata.toString())
            setIfMounted(_isMounted.current, setModelTrainingMetadata, _modelTrainingMetadata)
        }

        setIfMounted(_isMounted.current, setRunwayName, runwayData.runway.runwayName)
    }

    const getMessage = () => {
        if (createRunwayData.isSuccess) {
            return CREATE_RUNWAY_SUCCESS_MESSAGE
        } else {
            return createRunwayData.errorMessage || CREATE_RUNWAY_FAILURE_MESSAGE
        }
    }

    /**
     * Renders the view to select dataset for each channel.
     *
     * @param handleDropDownChange: The function to be called on event of change of selected dataset.
     */
    const getDataSetsView = (handleDropDownChange: any, channelIndex: number) => {
        let dataSetIndex = "-1"
        const dataSetOptions: any[] = []
        datasets.forEach((dataset: any, index: number) => {
                dataSetOptions.push({
                    "name": dataset.datasetName,
                    "value": index.toString()
                })
                if (stepInfo.train.inputChannelInfoList[channelIndex].datasetInfoName == dataset.datasetName) {
                    dataSetIndex = index.toString()
                }
            }
        )
        return (
            <>
                <FormDropDown
                    value={dataSetIndex === "-1" ? "" : dataSetIndex}
                    placeholder="Select the dataset"
                    options={dataSetOptions}
                    onChange={handleDropDownChange}/>
            </>
        )
    }

    /**
     * Renders the available code repositories in a radio button group field, from which the custom code package
     * can be chosen.
     *
     */
    const getCodeRepositories = () => {
        return (
            <>
                {

                    <>
                        <KatLabel
                            tooltipText={`Select package whose code you want to be made available.`}>
                            Packages :&nbsp;
                        </KatLabel>
                    </>
                }
                {
                    <>
                        {
                            <>
                                {
                                    codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess ?
                                        (codeRepoOptions.length > 0 ?
                                                <>
                                                    <FormDropDown
                                                        searchable={true}
                                                        placeholder="Select Repository"
                                                        name={"customCodeRepository"}
                                                        value={customCodeRepository}
                                                        options={
                                                            codeRepoOptions
                                                        }
                                                        onChange={(event: any) => {
                                                            setIfMounted(_isMounted.current, setCustomCodeRepository, event.target.value)
                                                        }}
                                                    >
                                                    </FormDropDown>
                                                </>
                                                :
                                                <KatFlashbar header="Note"
                                                             description={NO_ONBOARDED_PACKAGES_MESSAGE}
                                                />
                                        )
                                        :
                                        (codeRepositoriesSelector.codeRepositoriesFetched && !codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess ?
                                                <>
                                                    {
                                                        dispatch(showSnackBar("Error loading Code Repositories"))
                                                    }
                                                </> :
                                                (codeRepositoriesSelector.startCodeRepositoriesLoad ?
                                                        <KatSpinner/> : <></>
                                                )
                                        )
                                }
                            </>
                        }
                    </>
                }
            </>
        )
    }

    const getEcrRepos = () => {

        const handleEcrReposRadioChange = (event: any) => {
            if (event.target.value == CUSTOM_TRAINING_IMAGE) {
                setIfMounted(_isMounted.current, setEnableCustomImageInput, true)
            } else {
                setIfMounted(_isMounted.current, setEnableCustomImageInput, false)
                dispatch(loadTrainingImages(event.target.value))
            }
            setIfMounted(_isMounted.current, setEcrRepo, event.target.value)
        }


        return (
            <>
                <KatLabel tooltipText="ECR repository types">ECR Repository
                    :&nbsp;
                </KatLabel>
                <KatRadiobuttonGroup name={"ecrRepos"}
                                     options={getEcrReposOptions()}
                                     onChange={handleEcrReposRadioChange}>

                </KatRadiobuttonGroup>
                {enableCustomImageInput && isEmpty(baseRunwayId) ?
                    <FormInput
                        label="Training Image ARN"
                        type='text'
                        placeholder='Training Image ARN'
                        tooltipText="Training Image ARN"
                        onChange={(event: any) => setIfMounted(_isMounted.current, setTrainingImage, event.target.value)}
                        required
                    /> : null
                }
            </>
        )
    }

    /**
     * Renders the available training images in a radio button group field, from which training
     * image can be chosen.
     */
    const getTrainingImages = () => {
        const handleTrainingImagesChange = (event: any) => {
            setIfMounted(_isMounted.current, setTrainingImage, event.target.value)
        }
        const trainingImageOptions: any[] = []
        trainingImagesInfo.trainingImages.imageList.forEach((trainingImage: any) =>
            trainingImageOptions.push(
                {
                    "name": trainingImage.imageTag,
                    "value": trainingImage.imageTag
                }
            )
        )
        return (
            <>
                <KatLabel tooltipText="This is the training ECR docker image that will be used for the training job">Training
                    Image
                    :&nbsp;</KatLabel>
                {
                    <>
                        {
                            trainingImageOptions.length > 0 ?
                                <FormDropDown
                                    value={!isEmpty(trainingImage) ? trainingImage : ""}
                                    placeholder="Select Training Image"
                                    options={trainingImageOptions}
                                    onChange={handleTrainingImagesChange}
                                /> :
                                trainingImagesInfo.startLoader ?
                                    <KatSpinner/> :
                                    <KatFlashbar header="Note" description="No Images found"/>
                        }
                    </>
                }
            </>
        )
    }

    /**
     * Returns the view for code script field.
     */
    const getCodeScriptPathView = (uri: string, handleCodePathChange: (event: any) => void) => {
        return (
            <>
                <FormInput
                    label="Code script path"
                    type='text'
                    value={uri}
                    placeholder='Code script path'
                    tooltipText="Code script path"
                    onChange={handleCodePathChange}
                />
            </>
        )
    }

    const getCodeVersionList = (step: string) => {
        switch (step) {
            case ModelTrainingStep.PRE_TRAIN :
                return preTrainCodeVersions;
            case ModelTrainingStep.POST_TRAIN :
                return postTrainCodeVersions;
            case ModelTrainingStep.TRAIN :
                return trainCodeVersions;
            default :
                return {codeVersionsList: []}
        }
    }

    /**
     * Returns the view for selecting code version field.
     */
    const getCodeVersionsListDropDown = (step: string, handleCodeVersionChange: (event: any) => void) => {
        const options: any[] = []
        let codeVersions = getCodeVersionList(step)
        let codeVersionsList = codeVersions.codeVersionsList
        options.push({
            "name": LATEST_CODE_VERSION,
            "value": LATEST_CODE_VERSION
        })
        if (!isEmpty(codeVersionsList)) {
            let index = 1;
            codeVersionsList.forEach((codeVersion: any) => {
                const timestamp = moment.utc(new Date(codeVersion.timestamp * 1000)).format(DISPLAY_TIMESTAMP_FORMAT)
                options.push({
                    "name": `[` + timestamp.toString() + `UTC] V${index++}`,
                    "value": codeVersion.signedS3Url
                })
            });

        }

        return (
            <>
                {
                    codeVersions.startLoader ?
                        <KatSpinner/> :
                        !isEmpty(codeVersionsList) ?
                            <FormDropDown
                                max-height="200px"
                                options={options}
                                value={LATEST_CODE_VERSION}
                                label="Select Code Version"
                                placeholder='Select Code Version'
                                tooltipText='Select Code Version'
                                onChange={handleCodeVersionChange}>
                            </FormDropDown> :
                            <KatFlashbar header="Note" description="No Code Versions found"/>

                }
            </>
        )
    }

    /**
     * Adds a channel to the channels array.
     */
    const addChannel = () => {
        const channels = [...stepInfo.train.inputChannelInfoList]
        channels.push({
            channelName: "",
            datasetInfoName: "",
            s3PathTemplate: ""
        })
        setIfMounted(_isMounted.current, setStepInfo, {
            ...stepInfo,
            train: {
                ...stepInfo.train,
                inputChannelInfoList: channels
            }
        })
    }

    /**
     * Removes the the channel for training.
     *
     * @param index: Index of the channel in channels array.
     */
    const removeChannel = (index: number) => {
        const channels = [...stepInfo.train.inputChannelInfoList]
        channels.splice(index, 1)
        setIfMounted(_isMounted.current, setStepInfo, {
            ...stepInfo,
            train: {
                ...stepInfo.train,
                inputChannelInfoList: channels
            }
        })
    }

    const getSelectedDomainView = () => {
        const stageOptions: any[] = []
        stageOptions.push({
                "label": Stages.GAMMA.toUpperCase(),
                "value": Stages.GAMMA.toUpperCase()
            }
        )
        stageOptions.push({
                "label": Stages.PROD.toUpperCase(),
                "value": Stages.PROD.toUpperCase()
            }
        )
        return (
            <>
                <KatLabel
                    tooltipText={`Select runway stage.`}>
                    Stage :&nbsp;
                </KatLabel>
                <KatRadiobuttonGroup name={"runwayDomain"}
                                     options={stageOptions}
                                     onChange={(event: any) => {
                                         setIfMounted(_isMounted.current, setDomain, event.target.value)
                                     }}
                >
                </KatRadiobuttonGroup>
            </>
        )
    }

    /**
     * Renders the input for channels required for training.
     */
    const getChannels = () => {
        const handleChannelNameChange = (event: any, index: number) => {
            const channels = [...stepInfo.train.inputChannelInfoList]
            channels[index].channelName = event.target.value
            setIfMounted(_isMounted.current, setStepInfo, {
                ...stepInfo,
                train: {
                    ...stepInfo.train,
                    inputChannelInfoList: channels
                }
            })
        }
        const handleDataSetChange = (event: any, index: number) => {
            const channels = [...stepInfo.train.inputChannelInfoList]
            channels[index].datasetInfoName = datasets[parseInt(event.target.value)].datasetName
            channels[index].s3PathTemplate = datasets[parseInt(event.target.value)].s3Path
            setIfMounted(_isMounted.current, setStepInfo, {
                ...stepInfo,
                train: {
                    ...stepInfo.train,
                    inputChannelInfoList: channels
                }
            })
        }
        return (
            <>
                {stepInfo.train.inputChannelInfoList.map((channel: any, index: any) => {
                    return (
                        <>
                            <FormInput
                                label="Channel Name"
                                type='text'
                                placeholder='Channel Name'
                                value={channel.channelName}
                                tooltipText="Name of the model training channel"
                                onChange={(event: any) => handleChannelNameChange(event, index)}
                            />
                            <KatLabel tooltipText="Select dataset for the channel.">
                                Dataset :&nbsp;
                            </KatLabel>
                            {getDataSetsView((event: any) => {
                                handleDataSetChange(event, index)
                            }, index)}
                            <KatButton type="submit" label="Remove" variant="primary" size="small"
                                       onClick={(event: any) => {
                                           removeChannel(index)
                                       }}
                            />
                            <KatDivider variant={"eastern"}/>
                        </>
                    )
                })}
            </>
        )
    }

    const enforceRequiredParams = () => {
        const isEcrRepoPresent = !isEmpty(ecrRepo) || !isEmpty(baseRunwayId)
        const isCustomCodePackagePresent = isEmpty(stepInfo.train.trainCustomCodeURI) || !isEmpty(customCodeRepository)
        const isImageARNPresent = !isEmpty(trainingImage)
        !isEcrRepoPresent && dispatch(showSnackBar("Image not found"))
        !isImageARNPresent && dispatch(showSnackBar("Image ARN not found"))
        !isCustomCodePackagePresent && dispatch(showSnackBar("CustomCodePackage not found"))
        return isEcrRepoPresent && isCustomCodePackagePresent && isImageARNPresent
    }

    const performSanityChecksForCreate = () => {
        !isValidJSON(modelTrainingMetadata) && dispatch(showSnackBar("Invalid Model Training Metadata (JSON) found"))
        !isValidFeatureList(inputFeatures) && dispatch(showSnackBar("Invalid input feature list found"))
        !isValidFeatureList(responseVariables) && dispatch(showSnackBar("Invalid response variables found"))
        !isValidJSON(hyperParameters) && dispatch(showSnackBar("Invalid Hyperparmeters (JSON) found"))
        !isValidJSON(stepInfo.postTrain.thresholdConditionsJson) && dispatch(showSnackBar("Invalid Threshold Condition (JSON) found"))
        return (
            isValidJSON(modelTrainingMetadata)
            && isValidFeatureList(inputFeatures)
            && isValidFeatureList(responseVariables)
            && isValidJSON(hyperParameters)
            && isValidJSON(stepInfo.postTrain.thresholdConditionsJson)
        )
    }
    /**
     * Submits the create runway form to expresso service.
     *
     * @param event
     */
    const submitCreateRunwayForm = (event: any) => {
        enforceRequiredParams() &&
        performSanityChecksForCreate() &&
        dispatch(createRunway({
            runwayName: runwayName,
            teamId: params.teamId,
            namespaceId: params.namespaceId,
            scheduleFrequencyInMins: scheduleFrequencyInMins,
            offsetInfo: offsetInfo,
            baseTimestamp: Number(moment(baseTimestamp).format(TIMESTAMP_FORMAT)),
            modelTrainingInfo: {
                customCodePackageName: customCodeRepository,
                preTrain: {
                    preTrainCustomCodeURI: stepInfo.preTrain.preTrainCustomCodeURI,
                    modelAgeThreshold: stepInfo.preTrain.modelAgeThreshold,
                    codeVersion: extractVersionIdFromSignedUrl(stepInfo.preTrain.codeVersion)
                },
                train: {
                    trainingImage: ecrRepo == CUSTOM_TRAINING_IMAGE ? trainingImage :
                        (!isEmpty(trainingImagesInfo.trainingImages.imageUri) && !isEmpty(trainingImage) ? trainingImagesInfo.trainingImages.imageUri + ":" + trainingImage : ""),
                    trainCustomCodeURI: stepInfo.train.trainCustomCodeURI,
                    volumeSizeInGB: volumeSize,
                    trainingExecutionRole: trainingExecutionRoleARN,
                    instanceType: instanceType,
                    instanceCount: instanceCount,
                    hyperparameters: convertToJsonWithStrings(hyperParameters),
                    inputChannelInfoList: stepInfo.train.inputChannelInfoList,
                    modelOutputPath: modelOutputPath,
                    codeVersion: extractVersionIdFromSignedUrl(stepInfo.train.codeVersion)
                },
                postTrain: {
                    postTrainCustomCodeURI: stepInfo.postTrain.postTrainCustomCodeURI,
                    deployArtifactName: stepInfo.postTrain.deployArtifactName,
                    deploymentBucket: stepInfo.postTrain.deploymentBucket,
                    deploymentKey: stepInfo.postTrain.deploymentKey,
                    thresholdConditionsJson: stepInfo.postTrain.thresholdConditionsJson,
                    archiveSubFolder: stepInfo.postTrain.archiveSubFolder,
                    codeVersion: extractVersionIdFromSignedUrl(stepInfo.postTrain.codeVersion)
                },
                features: {
                    inputFeatures: getFeaturesAsList(inputFeatures),
                    responseVariables: getFeaturesAsList(responseVariables)
                },
                modelTrainingMetadata: getPrettyJsonString(modelTrainingMetadata)
            },
            requestedBy: userId,
            domain: domain
        }))
    }

    return (
        <>
            {
                (dataSetLoadErrorOccurred) ?
                    <KatFlashbar variant={"danger"} header={"Error"} description={"Could Not Load Datasets"}>
                    </KatFlashbar> : null
            }
            {
                !isEmpty(fetchedNamespace.namespaceData.namespace) ?
                    <>
                        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}></BreadCrumb>
                        <KatCard>
                            <span slot='title'> Create Runway </span>
                            <FormInput
                                label="Namespace Name"
                                type='text'
                                placeholder='Namespace name'
                                value={fetchedNamespace.namespaceData.namespace}
                                tooltipText="Namespace Name"
                                disabled={true}
                            />
                            <FormInput
                                label="Runway Name"
                                type='text'
                                placeholder='Runway name'
                                value={runwayName}
                                tooltipText="Runway Name"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setRunwayName, event.target.value)}
                                required
                            />
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'> Scheduling</span>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    variant="inline"
                                    ampm={false}
                                    label="Job base time (UTC)"
                                    value={baseTimestamp}
                                    onChange={(date: MaterialUiPickersDate) => {
                                        if (date)
                                            setBaseTimestamp(date)
                                    }}
                                    format="yyyy/MM/dd HH:mm"
                                />
                            </MuiPickersUtilsProvider>
                            <FormInput
                                label="Scheduling frequency(in minutes)"
                                type='number'
                                placeholder='Scheduling frequency'
                                min={1}
                                value={scheduleFrequencyInMins.toString()}
                                tooltipText="Volume size"
                                onChange={(event: any) => setScheduleFrequencyInMins(parseInt(event.target.value))}
                            />
                            <FormInput
                                label="Offset (in minutes)"
                                type='number'
                                value={offsetInfo.minutes.toString()}
                                tooltipText="Timestamp offset for the scheduled executions. Ex: if offset is -60, a job executed at 5:00 hrs, will resolve the timestamp for data as 4:00 hrs"
                                onChange={(event: any) => setOffsetInfo({
                                    days: 0,
                                    hours: 0,
                                    minutes: parseInt(event.target.value)
                                })}
                            />
                            {getCodeRepositories()}
                            <br/>
                        </KatCard>

                        <KatCard>
                            <span slot='subtitle'>Pre-Train</span>
                            {getCodeScriptPathView(stepInfo.preTrain.preTrainCustomCodeURI, (event: any) => {
                                    setIfMounted(
                                        _isMounted.current, setStepInfo, {
                                            ...stepInfo,
                                            preTrain: {
                                                ...stepInfo.preTrain,
                                                preTrainCustomCodeURI: event.target.value,
                                            }
                                        })
                                    dispatch(getCodeVersions({
                                                repositoryType: RepositoryType.GIT,
                                                scriptPath: event.target.value,
                                                branch: DEFAULT_BRANCH
                                            }, customCodeRepository,
                                            JobStep.PRE_EXECUTION)
                                    )
                                }
                            )
                            }
                            {
                                !isEmpty(customCodeRepository) && !isEmpty(stepInfo.preTrain.preTrainCustomCodeURI) ?
                                    getCodeVersionsListDropDown(ModelTrainingStep.PRE_TRAIN, (event: any) => {
                                        setIfMounted(
                                            _isMounted.current, setStepInfo, {
                                                ...stepInfo,
                                                preTrain: {
                                                    ...stepInfo.preTrain,
                                                    codeVersion: event.target.value,
                                                }
                                            })
                                        if (event.target.value != LATEST_CODE_VERSION) {
                                            window.open(event.target.value, '_blank')?.focus();
                                        }
                                    }) : null
                            }
                            <FormInput
                                label="Model age threshold"
                                type='number'
                                placeholder='Deployment Bucket'
                                value={stepInfo.preTrain.modelAgeThreshold.toString()}
                                tooltipText="Model age threshold"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setStepInfo, {
                                    ...stepInfo,
                                    preTrain: {
                                        ...stepInfo.preTrain,
                                        modelAgeThreshold: parseInt(event.target.value),
                                    }
                                })}
                            />
                        </KatCard>

                        <KatCard>
                            <span slot='subtitle'>Train</span>
                            <KatLabel tooltipText="Instance type of the training instance created.">
                                Training instance Type (Default: ml.t2.medium) :&nbsp;
                            </KatLabel>
                            <FormDropDown
                                value={instanceType}
                                options={INSTANCE_TYPES}
                                onChange={(event: any) => {
                                    setIfMounted(_isMounted.current, setInstanceType, event.target.value)
                                }}>
                            </FormDropDown>
                            <FormInput
                                label="Instance Count"
                                type='number'
                                placeholder='Instance Count'
                                value={instanceCount.toString()}
                                tooltipText="This is the instance count of the instance type specified while training."
                                onChange={(event: any) => setIfMounted(_isMounted.current, setInstanceCount, event.target.value)}
                                required
                            />
                            <FormInput
                                label="Volume Size(in Gb)"
                                type='number'
                                placeholder='Volume Size'
                                value={volumeSize.toString()}
                                tooltipText="Volume size"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setVolumeSize, event.target.value)}
                                required
                            />
                            {getEcrRepos()}
                            {!isEmpty(ecrRepo) && ecrRepo != CUSTOM_TRAINING_IMAGE ? getTrainingImages() : null}
                            {!isEmpty(baseRunwayId) && <FormInput
                                label="Training Image ARN"
                                type='text'
                                value={trainingImage}
                                placeholder='Training Image ARN'
                                tooltipText="Training Image ARN"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setTrainingImage, event.target.value)}
                                disabled={!enableCustomImageInput}
                                required
                            />}
                            <FormInput
                                label="Training Role ARN"
                                type='text'
                                placeholder='Training Role ARN'
                                value={trainingExecutionRoleARN}
                                tooltipText="Role used for training job. Default - arn:aws:iam::<account_id>:role/ExpressoModelTrainingRole"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setTrainingExecutionRoleARN, event.target.value)}
                            />

                            <KatLabel tooltipText="Hyper Parameters for the training job">
                                Hyper Parameters for the training job :&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidJSON(hyperParameters) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <kat-alert>"Attention: The values in this json will be available as string-to-string map in
                                custom code
                            </kat-alert>
                            <JSONEditor
                                fieldName={"Hyper Parameters"}
                                value={hyperParameters.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setHyperParameters,
                                        value
                                    )
                                }}
                                readOnly={false}
                            />
                            <FormInput
                                label="Model Output Path"
                                type='text'
                                placeholder='Model Output Path'
                                value={modelOutputPath}
                                tooltipText="Output path for the model training job."
                                onChange={(event: any) => setIfMounted(_isMounted.current, setModelOutputPath, event.target.value)}
                            />
                            <br/>
                            {
                                getCodeScriptPathView(stepInfo.train.trainCustomCodeURI, (event: any) => {
                                        setIfMounted(_isMounted.current, setStepInfo, {
                                            ...stepInfo,
                                            train: {
                                                ...stepInfo.train,
                                                trainCustomCodeURI: event.target.value,
                                            }
                                        })
                                        dispatch(getCodeVersions({
                                                repositoryType: RepositoryType.GIT,
                                                scriptPath: event.target.value,
                                                branch: DEFAULT_BRANCH
                                            }, customCodeRepository,
                                            JobStep.EXECUTION
                                        ));
                                    }
                                )
                            }
                            {
                                !isEmpty(customCodeRepository) && !isEmpty(stepInfo.train.trainCustomCodeURI) ?
                                    getCodeVersionsListDropDown(ModelTrainingStep.TRAIN, (event: any) => {
                                        setIfMounted(
                                            _isMounted.current, setStepInfo, {
                                                ...stepInfo,
                                                train: {
                                                    ...stepInfo.train,
                                                    codeVersion: event.target.value,
                                                }
                                            })
                                        if (event.target.value != LATEST_CODE_VERSION) {
                                            window.open(event.target.value, '_blank')?.focus();
                                        }
                                    }) : null
                            }
                            <br/>
                        </KatCard>
                        <KatCard>
                            <span slot='subtitle'>Channels</span>
                            {datasets.length > 0 ? getChannels() : NO_DATASETS_FOUND_MESSAGE}
                            <KatButton type="submit" label="Add Channel" variant="primary" size="small"
                                       onClick={addChannel}
                            />
                        </KatCard>

                        <KatCard>
                            <span slot='subtitle'>Post-Train</span>
                            {
                                getCodeScriptPathView(stepInfo.postTrain.postTrainCustomCodeURI, (event: any) => {
                                        setIfMounted(_isMounted.current, setStepInfo, {
                                            ...stepInfo,
                                            postTrain: {
                                                ...stepInfo.postTrain,
                                                postTrainCustomCodeURI: event.target.value,
                                            }
                                        })
                                        dispatch(getCodeVersions({
                                                repositoryType: RepositoryType.GIT,
                                                scriptPath: event.target.value,
                                                branch: DEFAULT_BRANCH
                                            }, customCodeRepository,
                                            JobStep.POST_EXECUTION
                                        ));
                                    }
                                )
                            }
                            {
                                !isEmpty(customCodeRepository) && !isEmpty(stepInfo.postTrain.postTrainCustomCodeURI) ?
                                    getCodeVersionsListDropDown(ModelTrainingStep.POST_TRAIN, (event: any) => {
                                        setIfMounted(
                                            _isMounted.current, setStepInfo, {
                                                ...stepInfo,
                                                postTrain: {
                                                    ...stepInfo.postTrain,
                                                    codeVersion: event.target.value,
                                                }
                                            })
                                        if (event.target.value != LATEST_CODE_VERSION) {
                                            window.open(event.target.value, '_blank')?.focus();
                                        }
                                    }) : null
                            }
                            <FormInput
                                label="Deployment artifact name"
                                type='text'
                                placeholder='Deployment artifact name'
                                value={stepInfo.postTrain.deployArtifactName}
                                tooltipText="Deployment artifact name"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setStepInfo, {
                                    ...stepInfo,
                                    postTrain: {
                                        ...stepInfo.postTrain,
                                        deployArtifactName: event.target.value,
                                    }
                                })}
                            />
                            <FormInput
                                label="Deployment Bucket"
                                type='text'
                                placeholder='Deployment Bucket'
                                value={stepInfo.postTrain.deploymentBucket}
                                tooltipText="Deployment Bucket"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setStepInfo, {
                                    ...stepInfo,
                                    postTrain: {
                                        ...stepInfo.postTrain,
                                        deploymentBucket: event.target.value,
                                    }
                                })}
                            />
                            <FormInput
                                label="Deployment key"
                                type='text'
                                placeholder='Deployment key'
                                value={stepInfo.postTrain.deploymentKey}
                                tooltipText="Deployment key"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setStepInfo, {
                                    ...stepInfo,
                                    postTrain: {
                                        ...stepInfo.postTrain,
                                        deploymentKey: event.target.value,
                                    }
                                })}
                            />
                            <KatLabel tooltipText="Threshold Configuration">
                                Threshold Configuration :&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidJSON(stepInfo.postTrain.thresholdConditionsJson) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"Threshold Configuration"}
                                value={stepInfo.postTrain.thresholdConditionsJson}
                                width="400px"
                                height="400px"
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setStepInfo,
                                        {
                                            ...stepInfo,
                                            postTrain: {
                                                ...stepInfo.postTrain,
                                                thresholdConditionsJson: value,
                                            }
                                        }
                                    )
                                }}
                                readOnly={false}
                            />
                            <FormInput
                                label="Archive Sub Folder"
                                type='text'
                                placeholder='Archive sub folder'
                                value={stepInfo.postTrain.archiveSubFolder}
                                tooltipText="Archive sub folder"
                                onChange={(event: any) => setIfMounted(_isMounted.current, setStepInfo, {
                                    ...stepInfo,
                                    postTrain: {
                                        ...stepInfo.postTrain,
                                        archiveSubFolder: event.target.value,
                                    }
                                })}
                            />
                        </KatCard>
                        <br/>
                        <KatCard>
                            <span slot='subtitle'> Features </span>
                            <KatLabel tooltipText="Input Features">
                                Input Features (comma separated):&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidFeatureList(inputFeatures) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"Input Features"}
                                width="400px"
                                height="250px"
                                value={inputFeatures.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setInputFeatures,
                                        value
                                    )
                                }}
                                readOnly={false}
                            />
                            <KatLabel tooltipText="response Variables">
                                Response Variables (comma separated):&nbsp;&nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidFeatureList(responseVariables) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"Response Variables"}
                                width="400px"
                                height="50px"
                                value={responseVariables.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setResponseVariables,
                                        value
                                    )
                                }}
                                readOnly={false}
                            />
                        </KatCard>
                        <br/>
                        <KatCard>
                            <span slot='subtitle'> Model Training Metadata </span>
                            <KatLabel tooltipText="model training metadata">
                                Metadata (JSON):&nbsp; &nbsp; &nbsp;
                                <VStatusIndicator
                                    variant={isValidJSON(modelTrainingMetadata) ? "success" : "error"}
                                    label={""}
                                />
                            </KatLabel>
                            <JSONEditor
                                fieldName={"model training metadata"}
                                width="400px"
                                height="250px"
                                value={modelTrainingMetadata.toString()}
                                onChange={(name: any, value: any) => {
                                    setIfMounted(
                                        _isMounted.current,
                                        setModelTrainingMetadata,
                                        value
                                    )
                                }}
                                readOnly={false}
                            />
                        </KatCard>
                        <br/>
                        <KatCard>
                            {getSelectedDomainView()}
                        </KatCard>

                        <br/>
                        {
                            showMessage ?
                                <KatFlashbar variant={createRunwayData.isSuccess ? "success" : "danger"}
                                             header={getMessage()}
                                >
                                </KatFlashbar>
                                : createRunwayData.startLoader ? <KatSpinner/> : null
                        }

                        <br/>
                        <KatButton type="submit" label="Create" variant="primary" size="base"
                                   onClick={submitCreateRunwayForm}/>
                    </> : <KatSpinner/>}
        </>
    )
}

export default CreateV1Runway