import React, {useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom"
import {
    KatAlert,
    KatButton,
    KatCheckbox,
    KatFlashbar,
    KatLabel,
    KatRadiobuttonGroup,
    KatSpinner
} from '@amzn/katal-react';
import {INSTANCE_TYPES} from "src/view/modelTraining/experiments/instance_types";
import {FormDropDown, FormInput} from "src/view/style/modelTraining/form_input_styles"
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {
    EXP_ACCOUNT_INFRA_FAILURE,
    FETCH_RUNWAY_ERROR_MESSAGE,
    FIELDS_REQUIRED_MESSAGE,
    getKernelOptions,
    NAMESPACE_ERROR_MESSAGE,
    PACKAGE_LIMIT_MESSAGE,
    Stages,
} from "src/view/modelTraining/constants";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {isExperimentNameValid, isValidCustomScriptPath} from "src/utils/model_training_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {createExperiment} from "src/control/actions/modelTraining/experiment_actions";
import {
    createExperimentSelector,
    experimentationAccountInfraSelector,
    namespaceSelector,
    runwaySelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {
    START_CREATE_EXPERIMENT_LOADER,
    START_FETCH_ALL_CODE_REPOSITORIES_LOADER
} from "src/control/actions/action_types";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {loadRunway} from "src/control/actions/modelTraining/runway_actions";
import {loadCodeRepositories} from "src/control/actions/codeRepository/code_repository_actions";
import {allCodeRepositoriesSelector} from "src/control/selectors/codeRepository/code_repo_selectors";
import LifecycleConfigScript from "./lifecycle_config_management";
import {GitRepository, RepositoryType, S3Repository} from "src/model/codeRepository/code_repository_models";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {OperationType} from "src/constant";

const CreateExperiment = () => {
    let params: any = useParams()
    const dispatch = useDispatch();
    let codeRepositoriesSelector = useSelector(allCodeRepositoriesSelector);

    let _isMounted = useRef(true);
    let loadPackageSuccess = useRef(false);

    const [gitRepositories, setGitRepositories] = useState(new Set)
    const [experimentName, setExperimentName] = useState('')
    const [kernel, setKernel] = useState('')
    const [instanceType, setInstanceType] = useState('ml.t2.medium')
    const [codeRepoOptions, setCodeRepoOptions] = useState<any[]>([]);
    const [lcScriptType, setLcScriptType] = useState<RepositoryType | null>(null);
    const [gitRepository, setGitRepository] = useState<GitRepository>({
        packageName: "",
        branchList: ["mainline"],
    });
    const [s3Repository, setS3Repository] = useState<S3Repository>({
        bucketName: "",
    });
    const [scriptPath, setScriptPath] = useState('');
    const createExperimentData = useSelector(createExperimentSelector);
    const fetchedNamespace = useSelector(namespaceSelector);
    const fetchedRunway = useSelector(runwaySelector);
    const experimentationAccountInfraData = useSelector(experimentationAccountInfraSelector);
    let userId = useSelector(usernameSelector);

    // Show message when experiment is created/failed or if there is any failure in fetching namespace/runway/infra data
    let showMessage = createExperimentData.showCreateExperimentDisplayMessage ||
        (fetchedNamespace.namespaceFetched && !fetchedNamespace.showNamespaceSuccess) ||
        (fetchedRunway.runwayFetched && !fetchedRunway.showRunwaySuccess) ||
        (experimentationAccountInfraData.infraFetched && !experimentationAccountInfraData.infraFetchSuccess)

    // Show success message when experiment is created successfully.
    let isSuccessMessage = createExperimentData.showCreateExperimentSuccess &&
        fetchedNamespace.showNamespaceSuccess &&
        fetchedRunway.showRunwaySuccess &&
        experimentationAccountInfraData.infraFetchSuccess

    const MAXIMUM_REPOSITORIES = 6;
    // TODO: Change values in backend instead of replacing EMR with spark.
    const EMR_KERNEL = "EMR"
    const TRAINING_EMR_KERNEL = "EMR+Training"
    const TRAINING = "Training"

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    useEffect(() => {
        dispatch({type: START_FETCH_ALL_CODE_REPOSITORIES_LOADER})
        dispatch(loadCodeRepositories({
            repositoryType: RepositoryType.GIT
        }))
    }, []);

    const setCodeRepositories = () => {
        const codeRepositories: any[] = []
        if (!loadPackageSuccess.current &&
            codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess) {
            codeRepositoriesSelector.codeRepositories.forEach((codeRepository: any) => {
                let repositoryName = ""
                if (codeRepository.codeRepository.gitRepository.syncEnabled) {
                    repositoryName = codeRepository.codeRepository.gitRepository.packageName
                }
                if (!isEmpty(repositoryName)) {
                    codeRepositories.push({
                        name: repositoryName,
                        value: repositoryName
                        })
                    }
                }
            )
            setCodeRepoOptions(codeRepositories)
            loadPackageSuccess.current = true;
        }
    }

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, [Stages.EXPERIMENTATION]))
            dispatch(loadRunway(params.runwayId))
        }
    }, [userId, params.teamId, params.namespaceId, params.runwayId]);

    const handleCheckboxChange = (event: any) => {
        if (gitRepositories.has(event.target.id)) {
            gitRepositories.delete(event.target.id)
        } else {
            gitRepositories.add(event.target.id)
        }
    };

    const getMessage = () => {
        if (!fetchedNamespace.showNamespaceSuccess) {
            return NAMESPACE_ERROR_MESSAGE
        } else if (!experimentationAccountInfraData.infraFetchSuccess) {
            return EXP_ACCOUNT_INFRA_FAILURE
        } else if (!fetchedRunway.showRunwaySuccess) {
            return FETCH_RUNWAY_ERROR_MESSAGE
        } else {
            return createExperimentData.createExperimentMessage
        }
    }

    const onSubmit = (event: any) => {
        event.preventDefault()
        if (isEmpty(experimentName) ||
            isEmpty(params.namespaceId) ||
            isEmpty(kernel) ||
            isEmpty(params.runwayId) ||
            (lcScriptType === RepositoryType.GIT && isEmpty(gitRepository?.packageName)) ||
            (lcScriptType === RepositoryType.S3 && isEmpty(s3Repository?.bucketName)) ||
            (!isEmpty(lcScriptType) && isEmpty(scriptPath))) {
            dispatch(showSnackBar((FIELDS_REQUIRED_MESSAGE)))
            return;
        }
        if (!isExperimentNameValid(experimentName, userId)) {
            dispatch(showSnackBar(("Invalid Experiment name")))
            return;
        }

        if (!isEmpty(lcScriptType) && !isValidCustomScriptPath(scriptPath)) {
            dispatch(showSnackBar("Invalid custom script path. It should end with '.sh'"))
            return;
        }

        if (gitRepositories.size > MAXIMUM_REPOSITORIES) {
            dispatch(showSnackBar((PACKAGE_LIMIT_MESSAGE)))
            return;
        }
        dispatch({type: START_CREATE_EXPERIMENT_LOADER})
        dispatch(createExperiment({
            namespaceId: params.namespaceId,
            kernel: kernel,
            runwayId: params.runwayId,
            notebookInstanceName: experimentName.trim(),
            notebookComputeInstance: isEmpty(instanceType) ? null : instanceType,
            userId: userId,
            teamId: params.teamId,
            additionalCodeRepositories: Array.from(gitRepositories),
            lcScriptInfo: isEmpty(lcScriptType) ? null : {
                codeRepository: {
                    repositoryType: lcScriptType,
                    gitRepository: lcScriptType === RepositoryType.GIT ? gitRepository : null,
                    s3Repository: lcScriptType === RepositoryType.S3 ? s3Repository : null
                },
                scriptPath: isEmpty(lcScriptType) ? "" : scriptPath
            }
        }))
    }

    const handleSelectKernel = (event: any) => {
        setIfMounted(_isMounted.current, setKernel, event.target.value)
    }

    const handleSelectInstanceType = (event: any) => {
        setIfMounted(_isMounted.current, setInstanceType, event.target.value)
    }

    return !isEmpty(fetchedNamespace.namespaceData.namespace) ? (
        <>
            <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}/>
            <div className="CreateExperiment" style={{marginBottom: "50px"}}>
                <KatAlert variant="info" dismissed={false}>
                    Click <a
                    href="https://w.amazon.com/bin/view/Advertising/SupplyQuality/SQI/Experimentation/Expresso-Console/Model-Training/SageMaker-Notebooks/User-Guide#H3.Howtocreateexperimentoversagemakernotebooks3F"
                    target="_blank">here </a> for user guide
                </KatAlert>
                {showMessage ?
                    <KatFlashbar variant={isSuccessMessage ? "success" : "danger"}
                                 header={getMessage()}
                                 link-label={isSuccessMessage ? "Track status" : null}
                                 linkHref={isSuccessMessage ? `/notebooks/${params.teamId}/namespaces/${params.namespaceId}/${params.runwayId}/experiments` : undefined}>
                    </KatFlashbar>
                    : createExperimentData.showCreateExperimentLoading ? <KatSpinner/> : null
                }
                <form className='CreateExperiment-form' onSubmit={onSubmit}>
                    <FormInput
                        label="Experiment Name *"
                        type='text'
                        placeholder='Experiment name'
                        value={experimentName}
                        tooltipText="This is the name of the sagemaker notebook to be created.
                    This name will be suffixed with your username.
                    The Experiment name should follow regex pattern '^[a-zA-Z0-9](-*[a-zA-Z0-9])*'
                    (Starting with number or alphabet and may contain only - special character)."
                        onChange={(event: any) => setExperimentName(event.target.value)}
                    />
                    <br/>
                    <FormInput
                        label="Runway *"
                        type='text'
                        tooltipText="Name of the runway under which this experiment is being created."
                        value={fetchedRunway.runway.runwayName}
                        required
                        disabled
                    />
                    <br/>
                    <FormInput
                        label="Namespace *"
                        type='text'
                        tooltipText="Name of the namespace under which this experiment is being created."
                        value={fetchedNamespace.namespaceData.namespace}
                        required
                        disabled
                    />
                    <br/>
                    <KatLabel tooltipText="This indicates whether the notebook will have spark kernel support or not.
                Experiment with Training indicates support for spark won't be there"> Kernel Use case *
                        :&nbsp;</KatLabel>
                    <KatRadiobuttonGroup
                        options={getKernelOptions()}
                        name={"Kernel Use case"}
                        onChange={handleSelectKernel}>
                    </KatRadiobuttonGroup>
                    <br/>
                    {
                        kernel.includes("EMR") &&
                        <FormInput
                            label="Cluster name *"
                            type='text'
                            tooltipText="Name of the cluster where data processing notebooks will run."
                            value={experimentationAccountInfraData.infraData.clusterName}
                            required
                            disabled
                        />
                    }
                    <br/>
                    <KatLabel tooltipText="Instance type of the notebook instance created.">
                        Notebook instance Type (Default: ml.t2.medium) :&nbsp;
                    </KatLabel>
                    <FormDropDown
                        value={instanceType}
                        options={INSTANCE_TYPES}
                        onChange={handleSelectInstanceType}>
                    </FormDropDown>
                    <br/>
                    <KatLabel
                        tooltipText="Select packages whose code you want to be made available on notebook instance created.">
                        Packages :&nbsp;
                    </KatLabel>
                    <br/>
                    {setCodeRepositories()}
                    {
                        codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess ?
                            (
                                codeRepoOptions.length > 0 ?
                                    codeRepoOptions.map((codePackage: any) => {
                                        return (<KatCheckbox label={codePackage.name} name={codePackage.value}
                                                             id={codePackage.name}
                                                             onChange={handleCheckboxChange}/>);
                                    })
                                    :
                                    <KatFlashbar header="Note"
                                                 description="No on-boarded packages found"/>
                            ) :
                            (
                                codeRepositoriesSelector.codeRepositoriesFetched && !codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess ?
                                    <>
                                        {
                                            dispatch(showSnackBar("Error loading Code Repositories"))
                                        }
                                    </> :
                                    (codeRepositoriesSelector.startCodeRepositoriesLoad ?
                                            <KatSpinner/> : <></>
                                    )
                            )
                    }
                    <br/>
                    <LifecycleConfigScript
                        operationType={OperationType.CREATE}
                        lcScriptType={lcScriptType}
                        setLcScriptType={setLcScriptType}
                        gitRepository={gitRepository}
                        setGitRepository={setGitRepository}
                        s3Repository={s3Repository}
                        setS3Repository={setS3Repository}
                        scriptPath={scriptPath}
                        setScriptPath={setScriptPath}
                    />
                    <br/>

                    <KatButton type="submit" label="Create" variant="primary" size="base"
                               disabled={
                                   isEmpty(fetchedNamespace.namespaceData.namespace) ||
                                   isEmpty(fetchedRunway.runway.runwayName) ||
                                   isEmpty(userId)
                               }
                    />
                </form>
            </div>
        </>
    ) : <KatSpinner/>;
}

export default CreateExperiment;