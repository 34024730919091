import {createStore, applyMiddleware} from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from "redux-thunk";
import rootReducer from "../control/reducers";
import middlewares from "src/control/middlewares"

const initialState = {};

const middleware = [thunk, ...middlewares];

const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(...middleware),
    )
);

export default store;
