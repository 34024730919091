import {JobTemplate} from "src/model/runways/job_template_model";
import {RunwayStatus} from "src/model/runways/runway_model";
import {isEmpty} from "src/utils/common_utils";
import {
  ContainerType,
  EmrClusterType,
  RepositoryType,
  SagemakerFeature,
  ServiceType,
} from "../../model/runways/job_model";
import {Ordering} from "../dataset/dataset_constants";

export const NO_ONBOARDED_GIT_REPOSITORIES_MESSAGE =
  "No on-boarded git packages found";
export const NO_ONBOARDED_S3_REPOSITORIES_MESSAGE =
  "No on-boardeded s3 packages found";
export const CUSTOM_TRAINING_IMAGE = "Custom Training Image";
export const JOB_EXECUTIONS_DEFAULT_PAGE_SIZE = 250;
export const V2_RUNWAYS_EXECUTIONS_DEFAULT_PAGE_SIZE = 250;
export const RUNWAY_STATUS_POLL_TIMER = 20;

export const getServiceType = () => {
  const serviceTypeOptions: any[] = [];
  Object.keys(ServiceType).forEach((item) => {
    serviceTypeOptions.push({
      label: item,
      value: item,
    });
  });
  return serviceTypeOptions;
};

export const getContainerType = () => {
  const containerTypeOptions: any[] = [];
  containerTypeOptions.push({
    label: "Image",
    value: ContainerType.IMAGE,
  });
  containerTypeOptions.push({
    label: "Runtime Closure",
    value: ContainerType.VERSION_SET,
  });
  return containerTypeOptions;
};

export const getSagemakerFeatureType = () => {
  const sagemakerFeatureTypeOptions: any[] = [];
  sagemakerFeatureTypeOptions.push({
    label: "MODEL TRAINING",
    value: SagemakerFeature.TRAINING,
  });
  return sagemakerFeatureTypeOptions;
};

export enum ModelTrainingImage {
  DNN = "DNN",
  XGB = "XGB",
  GBDT = "GBDT",
  CATBOOST = "CATBOOST",
  PYTORCH = "PYTORCH",
}

export const getModelTrainingImageOptions = () => {
  const imageOptions: any[] = [];
  imageOptions.push({
    name: "Custom",
    value: CUSTOM_TRAINING_IMAGE,
  });
  Object.keys(ModelTrainingImage).forEach((item) => {
    imageOptions.push({
      name: item,
      value: item,
    });
  });
  return imageOptions;
};

export const getEcsImageOptions = () => {
  const imageOptions: any[] = [];
  imageOptions.push({
    name: "PYTHON_DEFAULT",
    value: "PYTHON_DEFAULT",
  });
  return imageOptions;
};

// TODO: fetch from api
export const modelTrainingInstances = [
  "ml.t2.medium",
  "ml.t2.large",
  "ml.t2.xlarge",
  "ml.t2.2xlarge",
  "ml.t3.medium",
  "ml.t3.large",
  "ml.t3.xlarge",
  "ml.t3.2xlarge",
  "ml.m4.xlarge",
  "ml.m4.2xlarge",
  "ml.m4.10xlarge",
  "ml.m4.16xlarge",
  "ml.m5.xlarge",
  "ml.m5.2xlarge",
  "ml.m5.4xlarge",
  "ml.m5.12xlarge",
  "ml.m5.24xlarge",
  "ml.c4.xlarge",
  "ml.c4.2xlarge",
  "ml.c4.4xlarge",
  "ml.c4.8xlarge",
  "ml.c5.xlarge",
  "ml.c5.2xlarge",
  "ml.c5.4xlarge",
  "ml.c5.9xlarge",
  "ml.c5.18xlarge",
  "ml.c5d.xlarge",
  "ml.c5d.2xlarge",
  "ml.c5d.4xlarge",
  "ml.c5d.9xlarge",
  "ml.c5d.18xlarge",
  "ml.p2.xlarge",
  "ml.p2.8xlarge",
  "ml.p2.16xlarge",
  "ml.p3.2xlarge",
  "ml.p3.8xlarge",
  "ml.p3.16xlarge",
  "ml.p3dn.24xlarge",
  "ml.g4dn.xlarge",
  "ml.g4dn.2xlarge",
  "ml.g4dn.4xlarge",
  "ml.g4dn.8xlarge",
  "ml.g4dn.12xlarge",
  "ml.g4dn.16xlarge",
  "ml.r5.large",
  "ml.r5.xlarge",
  "ml.r5.2xlarge",
  "ml.r5.4xlarge",
  "ml.r5.8xlarge",
  "ml.r5.12xlarge",
  "ml.r5.16xlarge",
  "ml.r5.24xlarge",
  "ml.g5.xlarge",
  "ml.g5.2xlarge",
  "ml.g5.4xlarge",
  "ml.g5.8xlarge",
  "ml.g5.16xlarge",
  "ml.g5.12xlarge",
  "ml.g5.24xlarge",
  "ml.g5.48xlarge",
];

export const getModelTrainingInstanceTypes = () => {
  const modelTrainingInstanceTypeOptions: any[] = [];
  modelTrainingInstances.forEach((item) =>
    modelTrainingInstanceTypeOptions.push({
      name: item,
      value: item,
    })
  );
  return modelTrainingInstanceTypeOptions;
};

export const getEmrClusterType = () => {
  const emrClusterType: any[] = [];
  emrClusterType.push({
    label: EmrClusterType.ADHOC,
    value: EmrClusterType.ADHOC,
  }, {
    label: EmrClusterType.DEFAULT,
    value: EmrClusterType.DEFAULT,
  });
  return emrClusterType;
};

export const DEFAULT_EMR_CLUSTER_NAME = "ExpressoRunwaysDefaultCluster:root"
export const ADHOC_EMR_CLUSTER_NAME = "AdHocCluster"

export const constructCloudWatchDashboardLink = (
  accountId: string,
  dashboardName: string
) => {
  return `https://cw-dashboards.aka.amazon.com/cloudwatch/dashboardInternal?accountId=${accountId}#dashboards:name=${dashboardName}`;
};

export const getViewLogsMetricsUrl = (accountId: string, id: string) => {
  const dashboardName: string = `expresso-runway-${id}`;
  const dashboardUrl: string = constructCloudWatchDashboardLink(
    accountId,
    dashboardName
  );
  return dashboardUrl;
};

export const switchRunwayStatus = (status: string) => {
  return status === RunwayStatus.DISABLED
    ? RunwayStatus.ENABLED
    : status === RunwayStatus.ENABLED
    ? RunwayStatus.DISABLED
    : status;
};

export enum Stages {
  EXPERIMENTATION = "Experimentation",
  GAMMA = "GAMMA",
  PROD = "PROD",
}

export const getStageOptions = () => {
  const stageOptions: any[] = [];
  stageOptions.push({
    label: "Gamma",
    value: Stages.GAMMA,
  });
  stageOptions.push({
    label: "Prod",
    value: Stages.PROD,
  });
  return stageOptions;
};

export const getRepositoryTypeOptions = () => {
  const repoTypeOptions: any[] = [];
  Object.keys(RepositoryType).forEach((item) =>
    repoTypeOptions.push({
      label: item,
      value: item,
    })
  );
  return repoTypeOptions;
};

export const initialEmrInstances = {
  KeepJobFlowAliveWhenNoSteps: "false",
  TerminationProtected: "false",
  InstanceGroups: [
    {
      Name: "MasterNode",
      InstanceRole: "MASTER",
      InstanceType: "r4.8xlarge",
      InstanceCount: 1,
      Configurations: [],
    },
    {
      Name: "CoreNodes",
      InstanceRole: "CORE",
      InstanceType: "r4.8xlarge",
      InstanceCount: 2,
      Configurations: [],
    },
  ],
};

export const getJobTemplatesOptions = (jobTemplateList: JobTemplate[]) => {
  const jobTemplateOptions: any[] = [];
  jobTemplateOptions.push({
    name: "NONE",
    value: "NONE",
  });
  jobTemplateList.map((template: any) => {
    jobTemplateOptions.push({
      name: template.templateName,
      value: template.jobTemplateId,
    });
  });
  return jobTemplateOptions;
};

export const toggledSortOrdering = (currentSortOrdering: Ordering) => {
  return currentSortOrdering == Ordering.Ascending
    ? Ordering.Descending
    : Ordering.Ascending;
};

/**
 * set list of versions
 * @returns versions: string[]
 */
export const setVersions = (latestVersion: number) => {
  const versions: any[] = [];
  for (let currIndex = latestVersion; currIndex > 0; currIndex--) {
    versions.push({
      name: `${currIndex}`,
      value: `${currIndex}`,
    });
  }
  return versions;
};

export const isNonProdHostName = (hostname: string) => {
  return (
    hostname.includes("beta") ||
    hostname.includes("0.0.0.0") ||
    hostname.includes("localhost")
  );
};

export const initialEmrComputeConfig = {
  "ReleaseLabel": "emr-5.36.0",
  "BootstrapActions": [
    {
      "Name": "bootstrapScript",
      "ScriptBootstrapAction": {
        "Path": "s3://sample-bucket/path/bootstrap-script.sh"
      }
    }
  ],
  "Instances": {
    "KeepJobFlowAliveWhenNoSteps": true, 
    "TerminationProtected": true, 
    "InstanceGroups": [{
      "Name": "MasterNode", 
      "InstanceRole": "MASTER", 
      "InstanceType": "r4.8xlarge", 
      "InstanceCount": 1, 
      "Configurations": []
    }, 
    {
      "Name": "CoreNodes", 
      "InstanceRole": "CORE", 
      "InstanceType": "r4.8xlarge", 
      "InstanceCount": 2, 
      "Configurations": []
    }]},
  "Configurations": [],
};

export const getInitialEmrJobFlowStep = (jobName: string) => {
  if(isEmpty(jobName)) jobName = "jobName"
  return {
    "ActionOnFailure": "CONTINUE",
    "HadoopJarStep": {
      "Args": [
        "spark-submit",
        "--deploy-mode",
        "client",
        "--master",
        "yarn",
        "--conf",
        "spark.yarn.submit.waitAppCompletion=true",
        "${execution_code}",
      ],
      "Jar": "command-runner.jar",
    },
    "Name": `expresso_${jobName}_` + "${timestamp}",
  }
};

export const isCreatingOrUpdatingRunwayStatus = (
  runwayStatus: RunwayStatus
) => {
  return (
    runwayStatus === RunwayStatus.CREATING ||
    runwayStatus === RunwayStatus.UPDATING
  );
};

export const showVersionCheckPopUp = (setVersionCheckPopUp:any) => {
  setVersionCheckPopUp(true);
}

export const closeVersionCheckPopUp = (setVersionCheckPopUp:any) => {
  setVersionCheckPopUp(false)
}