import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    KatBadge,
    KatButton,
    KatCard,
    KatIcon,
    KatPagination,
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow,
} from "@amzn/katal-react";
import React, {useEffect, useRef, useState} from "react";
import {
    loadRunway,
    loadRunwayExecutions,
    loadRunwayInstanceExecutionMetrics
} from "src/control/actions/modelTraining/runway_actions";
import {
    listRunwayExecutionsSelector,
    listRunwayMetricsSelector,
    namespaceSelector,
    runwaySelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {PaddedSpan, TableRowWithShadow} from "src/view/style/table_styles";
import {JobStatus} from "src/view/modelTraining/constants";
import {Regions} from "src/constant";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {sortList} from "src/utils/list_utils";
import {FormInput} from "src/view/style/modelTraining/form_input_styles";
import styled from "styled-components";
import {PopUp} from "src/view/style/common_styles";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {convertToUTC, getDateTimeStringFromTimestamp, getLocalDateTimeStringFromTimestamp} from "src/utils/date_utils";
import {ErrorContainer, ErrorHeading} from "src/view/style/modelTraining/errors";
import {getParamFromQueryString} from "src/utils/url_utils";
import {getAWSRegion} from "src/utils/region_utils";
import {currentRegionSelector} from "src/control/selectors/commons/regions_selectors";
import {getBeatsDomain} from "src/utils/model_training_utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";

const moment = require("moment")

interface RunwayExecutions {
    executionId: string,
    executionTime: Date,
    executionStatus: JobStatus,
}

const ViewV1RunwayExecutions = () => {
    const params: any = useParams();
    const dispatch = useDispatch();
    const TIMESTAMP_FORMAT = "YYYYMMDDhhmmss"
    const getRunwayData = useSelector(runwaySelector);
    const region = useSelector(currentRegionSelector);
    const fetchedNamespace = useSelector(namespaceSelector);
    const domainFromQueryString = getParamFromQueryString(location.search, "domain") as string;

    const getStartDate = () => {
        const timeWindowStart = getParamFromQueryString(location.search, "timeWindowStart")
        if (isEmpty(timeWindowStart)) {
            const startDate = convertToUTC(new Date());
            startDate.setDate(startDate.getDate() - 1)
            return startDate;
        } else {
            return moment(timeWindowStart, TIMESTAMP_FORMAT).toDate()
        }
    }

    const getEndDate = () => {
        const timeWindowEnd = getParamFromQueryString(location.search, "timeWindowEnd")
        if (isEmpty(timeWindowEnd)) {
            return convertToUTC(new Date())
        } else {
            return moment(timeWindowEnd, TIMESTAMP_FORMAT).toDate()
        }
    }

    let listRunwaysExecution = useSelector(listRunwayExecutionsSelector);
    let listRunwaysExecutionMetrics = useSelector(listRunwayMetricsSelector);
    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
        dispatch(loadRunway(params.runwayId));
    }, [params.teamId, params.namespaceId, params.runwayId]);

    // Component States
    const [startTime, setStartTime] = useState(getStartDate());
    const [endTime, setEndTime] = useState(getEndDate());
    const [selectedPage, setSelectedPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [metricsPopUpVisible, setMetricsPopUpVisible] = useState(false);
    const [executionDetailsPopUpVisible, setExecutionDetailsPopUpVisible] = useState(false);
    const [logsRunway, setLogsRunway] = useState({
        executionId: "",
        executionTime: new Date(),
        executionStatus: "",
        stringTimestamp: "",
    });


    const runwayExecutions: RunwayExecutions[] = sortList(listRunwaysExecution.executionsList.map((execution: any) => {
            return (
                {
                    executionId: execution.jobInstanceId,
                    executionTime: execution.timestamp,
                    executionStatus: execution.status,
                    stringTimestamp: execution.timestamp,
                }
            )
        }),
        "executionTime"
    ).reverse()

    // Component Specific Styles
    const InlineContainer = styled.div`
     display: flex;
     flex-direction: row;
     justify-content: flex-start;
    `
    const InlineItem = styled.div`
    padding: 15px;
    `

    const ItemsPerPage = styled(FormInput)`
        width: 100px;
    `
    const onPageChanged = (event: any) => {
        if (!event.detail.page) {
            return;
        }
        setIfMounted(_isMounted.current, setSelectedPage, event.detail.page);
    }

    const getNumberOfPages = (): number => {
        return (Math.floor(runwayExecutions.length / itemsPerPage) + (runwayExecutions.length % itemsPerPage > 0 ? 1 : 0))
    }

    const fetchExecutionMetrics = (executionId: string) => {
        dispatch(loadRunwayInstanceExecutionMetrics(params.runwayId, executionId))
    }

    const getStatusBadge = (status: string) => {
        let type: any = ""
        switch (status) {
            case JobStatus.SUCCEEDED:
                type = "success"
                break
            case JobStatus.FAILED:
                type = "warning"
                break
            default:
                type = "info"
        }
        return (
            <>
                <KatBadge label={status} type={type}/>
            </>
        )
    }

    const getExecutionDetailsInfoPopUp = () => {
        const accountId: string = fetchedNamespace.namespaceData.accountTypeMap[domainFromQueryString.toLowerCase()]
        const awsRegion: string = getAWSRegion(Regions[region as keyof typeof Regions])

        return (
            <>
                <PopUp visible={executionDetailsPopUpVisible}
                       onOpen={(event: any) => {
                           setExecutionDetailsPopUpVisible(true)
                       }}
                       onClose={(event: any) => {
                           setExecutionDetailsPopUpVisible(false)
                       }}
                >
                    <span slot={"title"}>View Execution Details</span>
                    <KatCard>
                        <span slot='subtitle'>Step 1. Sign in to the AWS account</span>
                        <KatButton label={`Sign in to AWS account - ${accountId}`} size="base" variant="primary"
                                   onClick={(event: any) => {
                                       window.open(`https://conduit.security.a2z.com/accounts/aws/${accountId}`, '_blank')?.focus()
                                   }}
                        />
                    </KatCard>
                    <div/>
                    <br/>
                    <KatCard>
                        <span slot='subtitle'>Step 2. View Execution Details</span>
                        <KatButton label={`CloudWatch Logs`} size="base" variant="primary" onClick={(event: any) => {
                            window.open(`https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#logsV2:log-groups/log-group/ExpressoRunways$252F${params.runwayId}$252Fmodel_training/log-events/${logsRunway.stringTimestamp}`, '_blank')?.focus()
                        }}
                        />
                        <div/>
                        <br/>
                        <KatButton label={`CloudWatch Metrics`} size="base" variant="primary" onClick={(event: any) => {
                            window.open(`https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#dashboards:name=expresso-${params.runwayId}-modelTraining;start=${logsRunway.executionTime.toISOString()};end=${logsRunway.executionTime.toISOString()}`, '_blank')?.focus()
                        }}
                        />
                        <div/>
                        <br/>
                        <KatButton label={`SageMaker Training Job`} size="base" variant="primary"
                                   onClick={(event: any) => {
                                       window.open(`https://console.aws.amazon.com/sagemaker/home?region=${awsRegion}#/jobs/expresso-training-${logsRunway.executionId}`, '_blank')?.focus()
                                   }}
                        />
                        <div/>
                        <br/>
                        <KatButton label={"Training Metrics"} size="base" variant="primary" disabled={true}
                                   onClick={(event: any) => {
                                       fetchExecutionMetrics(logsRunway.executionId)
                                       setIfMounted(_isMounted.current, setExecutionDetailsPopUpVisible, false)
                                       setIfMounted(_isMounted.current, setMetricsPopUpVisible, true)
                                   }}
                        />
                    </KatCard>
                </PopUp>
            </>
        );
    }

    /**
     * Pop-up view for metrics.
     */
    const getMetricsPopUp = () => {
        return (
            <>
                <PopUp visible={metricsPopUpVisible}
                       onOpen={(event: any) => {
                           setIfMounted(_isMounted.current, setMetricsPopUpVisible, true);
                       }}
                       onClose={(event: any) => {
                           setIfMounted(_isMounted.current, setMetricsPopUpVisible, false);
                       }}
                >
                    <span slot={"title"}>Metrics</span>
                    <p>
                        {
                            (listRunwaysExecutionMetrics.runwaysExecutionMetricsFetched && listRunwaysExecutionMetrics.fetchRunwaysExecutionMetricsSuccess) ?
                                <KatTable>
                                    <KatTableHead>
                                        <KatTableRow>
                                            <KatTableCell>Metric Key</KatTableCell>
                                            <KatTableCell>Metric Value</KatTableCell>
                                        </KatTableRow>
                                    </KatTableHead>
                                    <KatTableBody>
                                        {
                                            !isEmpty(listRunwaysExecutionMetrics.metricData) ? Object.keys(listRunwaysExecutionMetrics.metricData).map((key) => {
                                                return (
                                                    <>
                                                        <TableRowWithShadow key={key}>
                                                            <KatTableCell>{key}</KatTableCell>
                                                            <KatTableCell>{listRunwaysExecutionMetrics.metricData[key]}</KatTableCell>
                                                        </TableRowWithShadow>
                                                    </>
                                                )
                                            }) : null
                                        }
                                    </KatTableBody>
                                </KatTable>
                                :
                                (listRunwaysExecutionMetrics.runwaysExecutionMetricsFetched && !listRunwaysExecutionMetrics.fetchRunwaysExecutionMetricsSuccess) ?
                                    <>
                                        <ErrorContainer>
                                            <ErrorHeading>Error loading runway executions Metrics</ErrorHeading>
                                        </ErrorContainer>
                                    </> :
                                    <KatSpinner/>
                        }
                    </p>
                </PopUp>
            </>
        );
    }

    /**
     * Executions table row.
     * @param runwayExecution
     */
    const getRow = (runwayExecution: RunwayExecutions) => {
        return (
            <TableRowWithShadow key={runwayExecution.executionId}>
                <KatTableCell>{runwayExecution.executionId}</KatTableCell>
                <KatTableCell>
                    <div style={{display: "flex"}}>
                        <div>
                            GMT:&nbsp;
                            {getDateTimeStringFromTimestamp(String(runwayExecution.executionTime))}
                        </div>
                        &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
                        <div>
                            Local:&nbsp;
                            {getLocalDateTimeStringFromTimestamp(String(runwayExecution.executionTime))}
                        </div>
                    </div>
                </KatTableCell>
                <KatTableCell>{getStatusBadge(runwayExecution.executionStatus)}</KatTableCell>
                <KatTableCell>
                    <PaddedSpan>
                        <KatButton label="View Details" size="small" variant="primary" onClick={
                            () => {
                                setIfMounted(_isMounted.current, setLogsRunway, runwayExecution)
                                setIfMounted(_isMounted.current, setExecutionDetailsPopUpVisible, true)
                            }
                        }>
                            <KatIcon size="small" slot="icon" name="info-circle"/>
                        </KatButton>
                    </PaddedSpan>
                </KatTableCell>
            </TableRowWithShadow>
        )
    }

    /**
     * Runway executions table head
     */
    const getExecutionsTableHead = () => {
        return (
            <>
                <InlineContainer>
                    <InlineItem>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                label="Start Timestamp(UTC)"
                                value={startTime}
                                onChange={(date: MaterialUiPickersDate) => {
                                    if (date)
                                        setStartTime(date)
                                }}
                                format="yyyy/MM/dd HH:mm"
                            />
                        </MuiPickersUtilsProvider>
                    </InlineItem>
                    <InlineItem>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                label="End Timestamp(UTC)"
                                value={endTime}
                                onChange={(date: MaterialUiPickersDate) => {
                                    if (date)
                                        setEndTime(date)
                                }}
                                format="yyyy/MM/dd HH:mm"
                            />
                        </MuiPickersUtilsProvider>
                    </InlineItem>
                    <InlineItem>
                        <KatButton label={"Show"} size="base" variant="primary"
                                   onClick={(event: any) => {
                                       setIfMounted(_isMounted.current, setSelectedPage, 1)
                                       dispatch(
                                           loadRunwayExecutions(
                                               params.runwayId,
                                               true,
                                               moment(startTime).format(TIMESTAMP_FORMAT),
                                               moment(endTime).format(TIMESTAMP_FORMAT),
                                           )
                                       )
                                   }}
                        /></InlineItem>
                    {/*Opening link in new page as this is a beats link*/}
                    <InlineItem>
                        <a
                            href={`https://${getBeatsDomain(region)}/job/${getRunwayData.runway.jobId}/jobHistory`}
                            target='_blank'
                            rel="noopener noreferrer"
                        >

                            <KatButton
                                label="View Executions"
                                size="base"
                                variant="primary"
                                disabled={!(getRunwayData.runwayFetched && getRunwayData.showRunwaySuccess) || isEmpty(getRunwayData.runway.jobId)}
                            />
                        </a>
                    </InlineItem>
                </InlineContainer>
            </>
        )
    }

    /**
     * Runway executions table
     */
    const getExecutionTable = () => {
        return (
            <>
                <KatTable>
                    <KatTableHead>
                        <KatTableRow>
                            <KatTableCell>Execution Id</KatTableCell>
                            <KatTableCell>Schedule Time</KatTableCell>
                            <KatTableCell>Execution Status</KatTableCell>
                            <KatTableCell>Actions</KatTableCell>
                        </KatTableRow>
                    </KatTableHead>
                    <KatTableBody>
                        {
                            (listRunwaysExecution.runwaysExecutionsFetched && listRunwaysExecution.fetchAllExecutionsSuccess) ?
                                <>
                                    {
                                        runwayExecutions.length > 0 ?
                                            runwayExecutions.slice(
                                                itemsPerPage * (selectedPage - 1), itemsPerPage * (selectedPage)
                                            ).map(getRow) :
                                            <ErrorContainer>
                                                <ErrorHeading>No Executions in given time range</ErrorHeading>
                                            </ErrorContainer>

                                    }
                                </>
                                :
                                (listRunwaysExecution.runwaysExecutionsFetched && !listRunwaysExecution.fetchAllExecutionsSuccess) ?
                                    <>
                                        <ErrorContainer>
                                            <ErrorHeading>Error loading runway Executions</ErrorHeading>
                                        </ErrorContainer>
                                    </>
                                    :
                                    <KatSpinner/>
                        }
                    </KatTableBody>
                </KatTable>
            </>
        )
    }

    /**
     * Runway executions table footer.
     */
    const getExecutionsTableFooter = () => {
        return (
            <>
                <InlineContainer>
                    <KatPagination
                        onChange={onPageChanged}
                        totalItems={runwayExecutions.length}
                        itemsPerPage={itemsPerPage}
                        page={selectedPage}
                    />
                    {
                        selectedPage == getNumberOfPages() && listRunwaysExecution.loadMore ?
                            <KatButton label={"Load More"} size="small" variant="primary" onClick={(event: any) => {
                                dispatch(
                                    loadRunwayExecutions(
                                        params.runwayId,
                                        false,
                                        moment(runwayExecutions[runwayExecutions.length - 1].executionTime).format(TIMESTAMP_FORMAT),
                                        moment(endTime).format(TIMESTAMP_FORMAT)
                                    )
                                )
                            }}
                            /> :
                            null
                    }
                    <ItemsPerPage
                        value={itemsPerPage.toString()}
                        type="number"
                        label="Items per page"
                        name="itemsPerPage"
                        onChange={
                            (event: any) => {
                                setIfMounted(_isMounted.current, setItemsPerPage, parseInt(event.target.value))
                            }
                        }
                    />
                </InlineContainer>
            </>
        )
    }

    return (
        <>
            {getMetricsPopUp()}
            {getExecutionDetailsInfoPopUp()}
            {
                !isEmpty(fetchedNamespace.namespaceData.namespace) && !isEmpty(getRunwayData.runway.runwayName) ?
                    <>
                        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                    runwayName={getRunwayData.runway.runwayName}></BreadCrumb>
                        <KatCard>
                            <span slot='title'>Runway Instances</span>
                            {getExecutionsTableHead()}
                            {getExecutionTable()}
                        </KatCard>
                        {getExecutionsTableFooter()}
                    </> : <KatSpinner></KatSpinner>
            }
        </>
    )
}

export default ViewV1RunwayExecutions