import {Regions} from "src/constant";
import history from "src/history";

/**
 * These are regEx for "dub.*" , "sin.*" , "pdx.*"
 *
 */
const region_flow = {
    DUB: /^dub\..*/,
    SIN: /^sin\..*/,
    PDX: /^pdx\..*/
}

export const getRegionFromHostname = (hostname: string) => {

    let match = hostname.match(region_flow.DUB);
    if ( match  && match[0] === hostname) {
        return Regions.DUB;
    }

    match = hostname.match(region_flow.SIN);
    if ( match  && match[0] === hostname) {
        return Regions.SIN;
    }

    match = hostname.match(region_flow.PDX);
    if ( match  && match[0] === hostname) {
        return Regions.PDX;
    }

    return Regions.IAD;

}

export const redirectToRegion = (region: Regions , hostname: string, teamId: string, component: string) => {
    history.push(`/${component}/${teamId}/namespaces`)
    const regionName = region.toLowerCase();
    Object.values(Regions).forEach((region) => {
       const removeRegion = region.toLowerCase();
       hostname = hostname.replace(removeRegion + "." , ""); //removing the regions in url if present.
    });
    if (!hostname.includes("0.0.0.0") && !hostname.includes("localhost")) {
        if(region != Regions.IAD) {
           const regionHostname = regionName + "." + hostname; //adding region prefix.
           hostname = regionHostname;
        }
   }
   window.location.hostname = hostname;
   return hostname;
}

export const getAWSRegion = (region: Regions) => {
    switch(region) {
        case Regions.DUB:
            return "eu-west-1"
        case Regions.IAD:
            return "us-east-1"
        case Regions.PDX:
            return "us-west-2"
        case Regions.SIN:
            return "ap-southeast-1"
    }
}