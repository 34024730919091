import {useDispatch, useSelector} from "react-redux";
import {Tab, Tabs} from "src/view/style/header_styles";
import {KatRadiobuttonGroup, KatSpinner, KatTabs, KatTab} from "@amzn/katal-react";
import React, {useRef, useState} from "react";
import {RepositoryType} from "src/model/runways/job_model";
import {
    CreateNewButton,
    PaddedSpan,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableHeadRow,
    TableRowWithShadow
} from "src/view/style/table_styles";
import {allCodeRepositoriesSelector} from "src/control/selectors/codeRepository/code_repo_selectors";
import {getCodeRepository, loadCodeRepositories} from "src/control/actions/codeRepository/code_repository_actions";
import {ErrorContainer, ErrorHeading} from "src/view/style/modelTraining/errors";
import {SetFunction} from "src/view/dataset/dataset_constants";
import {REGISTER_CODE_REPOSITORY_RESET, START_FETCH_ALL_CODE_REPOSITORIES_LOADER} from "src/control/actions/action_types";
import ReactTooltip from "react-tooltip";
import {NewBranchIcon, ViewIconTable} from "src/view/style/icons";
import {isEmpty, setIfMounted} from "src/utils/common_utils";

export interface ListRepositoriesProps {
    setVisibilityForRegisterCodeRepo: SetFunction<boolean>
    setVisibilityForViewCodeRepository: SetFunction<boolean>
    setViewCodeRepositoryData: SetFunction<any>
    setIsAlreadyExistingCodeRepository: SetFunction<boolean>
    setExistingPackageName: SetFunction<string>
}

const ListRepositories = (props: ListRepositoriesProps) => {
    let _isMounted = useRef(true);
    const dispatch = useDispatch();
    let codeRepositories = useSelector(allCodeRepositoriesSelector);

    const [repositoryType, setRepositoryType] = useState(RepositoryType.GIT)

    const getRepoName = (codeRepository: any) => {
        switch (repositoryType) {
            case "GIT":
                return codeRepository.gitRepository.packageName
            case "S3":
                return codeRepository.s3Repository.bucketName
        }
    }

    const showCodeRepoCreate = () => {
        dispatch({type: REGISTER_CODE_REPOSITORY_RESET})
        props.setVisibilityForRegisterCodeRepo(true);
        props.setIsAlreadyExistingCodeRepository(false);
        props.setExistingPackageName("")
    }

    const showCodeRepoView = (repoData: any) => {
        if (repositoryType == RepositoryType.S3) {
            dispatch(getCodeRepository({
                    repositoryType: RepositoryType.S3
                }, repoData.codeRepository.s3Repository.bucketName)
            )
        }
        props.setViewCodeRepositoryData(repoData)
        props.setVisibilityForViewCodeRepository(true);
    }

    const showAddBranchView = (repoData: any) => {
        props.setExistingPackageName(repoData.codeRepository.gitRepository.packageName)
        props.setIsAlreadyExistingCodeRepository(true);
        props.setVisibilityForRegisterCodeRepo(true);
        dispatch({type: REGISTER_CODE_REPOSITORY_RESET})
    }

    const getRow = (repoData: any) => {
        return (
            <TableRowWithShadow>
                <TableCell>
                    {repoData.codeRepositoryId}
                </TableCell>
                <TableCell>
                    {getRepoName(repoData.codeRepository)}
                </TableCell>
                <TableCell>
                    {repoData.createdBy}
                </TableCell>
                <TableCell>
                    {isEmpty(repoData.lastUpdatedBy) ? "-" : repoData.lastUpdatedBy}
                </TableCell>
                <TableCell>
                    <ReactTooltip place="bottom" type="dark"/>
                    <PaddedSpan data-tip="View Repository Details"
                                onClick={() => showCodeRepoView(repoData)}>
                        <ViewIconTable/>
                    </PaddedSpan>
                    {
                        repoData.codeRepository.repositoryType == RepositoryType.GIT ?
                            <PaddedSpan data-tip="Add new branch"
                                        onClick={() => showAddBranchView(repoData)}>
                                <NewBranchIcon/>
                            </PaddedSpan>
                            :
                            <></>
                    }
                </TableCell>
            </TableRowWithShadow>
        )
    }

    const getTableBody = () => {
        return (
            <>
            <TableHeader>
                Code Repositories
                <CreateNewButton
                    variant={"primary"}
                    disabled={false}
                    onClick={showCodeRepoCreate}
                >
                    Create New!
                </CreateNewButton>
            </TableHeader>
            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableCell>
                            Id
                        </TableCell>
                        <TableCell>
                            Repo Name
                        </TableCell>
                        <TableCell>
                            Created By
                        </TableCell>
                        <TableCell>
                            Last Updated By
                        </TableCell>
                        <TableCell>
                            Actions
                        </TableCell>
                    </TableHeadRow>
                </TableHead>
                <TableBody>
                    {
                        codeRepositories.codeRepositoriesFetched && codeRepositories.fetchAllCodeRepositoriesSuccess ?
                            (
                                codeRepositories.codeRepositories.length > 0 ?
                                    codeRepositories.codeRepositories.map(getRow)
                                    :
                                    <>
                                        <ErrorContainer>
                                            <ErrorHeading>No code repositories found for given repository
                                                type</ErrorHeading>
                                        </ErrorContainer>
                                    </>
                            ) :
                            (
                                codeRepositories.codeRepositoriesFetched && !codeRepositories.fetchAllCodeRepositoriesSuccess ?
                                    <>
                                        <ErrorContainer>
                                            <ErrorHeading>Error loading Code Repositories</ErrorHeading>
                                        </ErrorContainer>
                                    </> :
                                    (codeRepositories.startCodeRepositoriesLoad ?
                                            <KatSpinner/> : <></>
                                    )
                            )
                    }
                </TableBody>
            </Table>
            </>
        )
    }

    const loadRepositories = (repoType: string) => {
        dispatch({type: START_FETCH_ALL_CODE_REPOSITORIES_LOADER})
        dispatch(
            loadCodeRepositories({
                repositoryType: repoType
            }))
        setIfMounted(_isMounted.current, setRepositoryType, repoType)
    }

    return (
        <>
            <Tabs selected={repositoryType}>
                <Tab key={RepositoryType.GIT} tabId={RepositoryType.GIT} label ={RepositoryType.GIT}
                        onClick={
                            (event) => {
                                loadRepositories(RepositoryType.GIT)
                            }
                        }
                >
                    {getTableBody()}
                </Tab>
                <Tab key={RepositoryType.S3} tabId={RepositoryType.S3} label ={RepositoryType.S3}
                        onClick={
                            (event) => {
                                loadRepositories(RepositoryType.S3)
                            }
                        }
                >
                    {getTableBody()}
                </Tab>
            </Tabs>
        </>
    )
}

export default ListRepositories