import React, { useEffect, useRef, useState } from "react";
import {
  KatDropdown,
  KatLabel,
  KatRadiobutton,
  KatSpinner,
} from "@amzn/katal-react";
import { setIfMounted } from "src/utils/common_utils";
import { FormInput } from "src/view/style/modelTraining/form_input_styles";
import { useDispatch } from "react-redux";
import { loadCodeRepositories } from "src/control/actions/codeRepository/code_repository_actions";
import {OperationType, SetFunction} from "src/constant";
import {
  GitRepository,
  RepositoryType,
  S3Repository,
} from "src/model/codeRepository/code_repository_models";
import { showSnackBar } from "src/control/actions/commons/snack_bar_actions";

export default function LifecycleConfigScript(props: {
  operationType: OperationType;
  lcScriptType: RepositoryType | null;
  setLcScriptType: SetFunction<RepositoryType | null>;
  gitRepository: GitRepository;
  setGitRepository: SetFunction<GitRepository>;
  s3Repository: S3Repository;
  setS3Repository: SetFunction<S3Repository>;
  scriptPath: string;
  setScriptPath: SetFunction<string>;
}) {
  const _isMounted = useRef(true);
  const dispatch = useDispatch();

  const [gitRepos, setGitRepos] = useState<GitRepository[] | null>(null);
  const [s3Repos, setS3Repos] = useState<S3Repository[] | null>(null);
  const [hasGitReposLoaded, setHasGitReposLoaded] = useState<boolean>(false);
  const [hasS3ReposLoaded, setHasS3ReposLoaded] = useState<boolean>(false);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  });

  const getGitRepos = async () => {
    try {
      let gitRepoList: any = await dispatch(
        loadCodeRepositories({ repositoryType: RepositoryType.GIT }, true)
      );
      setIfMounted(_isMounted.current, setGitRepos, gitRepoList);
      setIfMounted(_isMounted.current, setHasGitReposLoaded, true);
    } catch (err: any) {
      console.log("Error loading git repos: ", err);
      dispatch(showSnackBar("Error loading Git repositories"));
    }
  };

  const getS3Repos = async () => {
    try {
      let s3RepoList: any = await dispatch(
        loadCodeRepositories({ repositoryType: RepositoryType.S3 }, true)
      );
      setIfMounted(_isMounted.current, setS3Repos, s3RepoList);
      setIfMounted(_isMounted.current, setHasS3ReposLoaded, true);
    } catch (err: any) {
      console.log("Error loading s3 repos: ", err);
      dispatch(showSnackBar("Error loading S3 repositories"));
    }
  };

  useEffect(() => {
    getGitRepos();
    getS3Repos();
  }, []);

  const getGitRepositoryList = () => {
    let gitRepositoryList: any[] = [];
    gitRepos?.forEach((repository: any) => {
      if (repository.codeRepository.gitRepository?.syncEnabled) {
        let repositoryName =
          repository.codeRepository.gitRepository.packageName;
        gitRepositoryList.push({
          name: repositoryName,
          value: repositoryName,
        });
      }
    });
    return gitRepositoryList;
  };

  const getBranchListForPackage = (packageName: string) => {
    let branchList: any[] = [];
    gitRepos?.forEach((repository: any) => {
      if( repository.codeRepository.gitRepository.packageName === packageName){
        let branches = repository.codeRepository.gitRepository.branchList;
        branches.forEach((branch: string) => {
          branchList.push({
            name: branch,
            value: branch
          })
        })
      }
    })
    return branchList;
  }

  const getS3RepositoryList = () => {
    let s3RepositoryList: any[] = [];
    s3Repos?.forEach((repository: any) => {
      let repositoryName = repository.codeRepository.s3Repository?.bucketName;
      s3RepositoryList.push({
        name: repositoryName,
        value: repositoryName,
      });
    });
    return s3RepositoryList;
  };

  return (
    <>
      <KatLabel tooltipText="Custom script is a shell script (.sh) file that will run whenever a notebook is created/restarted. Run time of custom script should be less than 2 minutes.">
        Custom LC Script:
      </KatLabel>
      <br />{" "}
      <KatRadiobutton
        value="None"
        label="None"
        checked={true}
        onChange={() => {
          setIfMounted(_isMounted.current, props.setLcScriptType, null);
          setIfMounted(_isMounted.current,props.setScriptPath, '');
        }}
      />
      <br />
      <KatRadiobutton
        value={RepositoryType.GIT}
        checked={props.lcScriptType === RepositoryType.GIT}
        onChange={() => {
          setIfMounted(
            _isMounted.current,
            props.setLcScriptType,
            RepositoryType.GIT
          );
        }}
      >
        Git <br />
        {props.lcScriptType === RepositoryType.GIT && (
          <>
            {!hasGitReposLoaded && <KatSpinner />}
            {hasGitReposLoaded && (
              <>
                <KatDropdown
                  label="Package Name *"
                  options={getGitRepositoryList()}
                  value={props.gitRepository?.packageName}
                  placeholder="Select package name"
                  onChange={(event: any) => {
                    setIfMounted(_isMounted.current, props.setGitRepository, {
                      ...props.gitRepository,
                      packageName: event.target.value,
                    });
                    //preventing change event of dropdown component
                    //from bubbling to radiobutton component
                    event.stopPropagation();
                  }}
                />
                <KatDropdown
                  label="Branch *"
                  options={getBranchListForPackage(props.gitRepository.packageName)}
                  value={props.gitRepository.branchList && props.gitRepository.branchList[0]}
                  placeholder="Select branch for the package"
                  onChange={(event: any) => {
                    setIfMounted(_isMounted.current, props.setGitRepository, {
                      ...props.gitRepository,
                      branchList: [event.target.value],
                    });
                    //preventing change event of dropdown component
                    //from bubbling to radiobutton component
                    event.stopPropagation();
                  }}
                />
                <FormInput
                  label="Path *"
                  placeholder="Provide path to .sh file"
                  value={props.scriptPath}
                  onChange={(event: any) => {
                    setIfMounted(
                      _isMounted.current,
                      props.setScriptPath,
                      event.target.value
                    );
                  }}
                />
              </>
            )}
          </>
        )}
      </KatRadiobutton>
      <br />
      <KatRadiobutton
        value={RepositoryType.S3}
        checked={props.lcScriptType === RepositoryType.S3}
        onChange={() => {
          setIfMounted(
            _isMounted.current,
            props.setLcScriptType,
            RepositoryType.S3
          );
        }}
      >
        S3
        {props.lcScriptType === RepositoryType.S3 && (
          <>
            {!hasS3ReposLoaded && <KatSpinner />}
            {hasS3ReposLoaded && (
              <>
                <KatDropdown
                  label="Bucket Name *"
                  placeholder="Select bucket name"
                  options={getS3RepositoryList()}
                  value={props.s3Repository?.bucketName}
                  onChange={(event: any) => {
                    setIfMounted(_isMounted.current, props.setS3Repository, {
                      ...props.s3Repository,
                      bucketName: event.target.value,
                    });
                    //preventing change event of dropdown component
                    //from bubbling to radiobutton component
                    event.stopPropagation();
                  }}
                ></KatDropdown>
                <FormInput
                  label="Path *"
                  placeholder="Provide path to .sh file"
                  value={props.scriptPath}
                  onChange={(event: any) => {
                    setIfMounted(
                      _isMounted.current,
                      props.setScriptPath,
                      event.target.value
                    );
                  }}
                />
              </>
            )}
          </>
        )}
      </KatRadiobutton>
    </>
  );
}
