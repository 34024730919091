import { KatButton, KatCheckbox, KatIcon, KatInput, KatModal, KatSpinner, KatTable, KatTableBody, KatTableCell, KatTableHead, KatTableRow } from '@amzn/katal-react';
import {apiPutCallback} from 'src/utils/api_handler'
import React from 'react'
import { connect } from 'react-redux';
import { isValidMcmOrSev2Link } from 'src/utils/common_utils';
import { JobDomain } from '../constants';
import { getCradleProfileTarget, getPromoteFeatureGenerationJobTarget } from 'src/control/targets';

class FeatureGenerationJobsTable extends React.Component<any, any> {
    _mounted: boolean = true
    constructor(props: any) {
        super(props)
        this.state = {
            'showPromotionConfirmation': false,
            'displaySuccessMessage': false,
            'displayErrorMessage': false,
            'jobToPromote': null,
            'promotionConfirmationCheck': false,
            'testingCheck': false,
            'mcmOrSev2Link': '',
            'apiLoading': false,
            'requestedBy': this.props.username,
        }
        this.handlePromoteClick = this.handlePromoteClick.bind(this)
        this.promoteJob = this.promoteJob.bind(this)
    }
    private handlePromoteClick(job: any) {
        this._mounted &&
        this.setState({
            'showPromotionConfirmation': true,
            'jobToPromote': job,
        })
    }

    componentDidMount() {
        this._mounted = true
    }
    componentWillUnmount() {
        this._mounted = false
    }

    private promoteJob() {
        const isGammaDomain: boolean = 'gamma' == this.props.domain.toLowerCase()
        if(isGammaDomain && !this.state.promotionConfirmationCheck) alert('No confirmation for promotion')
        else if(isGammaDomain && !this.state.testingCheck) alert('No confirmation for testing')
        else if(isGammaDomain && !isValidMcmOrSev2Link(this.state.mcmOrSev2Link)) alert('Invalid mcm / sev 2 link.')
        else {
            this._mounted &&
            this.setState({'apiLoading': true})
            apiPutCallback(getPromoteFeatureGenerationJobTarget(this.props.namespaceId, this.state.jobToPromote.jobId), {
                teamId: this.props.teamId,
                'requestedBy': this.state.requestedBy,
                'promoteMessage': this.state.mcmOrSev2Link,
            }, (response: any) => {
                this._mounted && this.setState({'displaySuccessMessage': true, 'apiLoading': false})
            }, (error: any) => {
                this._mounted && this.setState({'displayErrorMessage': true, 'apiLoading': false})
            })
        }
    }

    redirectToCradleProfile(jobId: string) {
        window.open(getCradleProfileTarget(jobId))

    }
    render() {
        return (
            <>
                <KatTable>
                    <KatTableHead>
                        <KatTableRow>
                            <KatTableCell>Name</KatTableCell>
                            <KatTableCell>Job ID</KatTableCell>
                            <KatTableCell>Actions</KatTableCell>
                        </KatTableRow>
                    </KatTableHead>
                    <KatTableBody>
                        {this.props.jobList.map((job: any) => (
                        <KatTableRow key={job.jobId}>
                        <KatTableCell>
                            <KatButton label={job.jobName} variant="link" onClick={() => this.redirectToCradleProfile(job.jobId)}/>
                        </KatTableCell>
                        <KatTableCell>
                            {job.jobId}
                        </KatTableCell>
                        <KatTableCell>
                        &nbsp;
                            {!(this.props.domain.toLowerCase() == 'prod') &&
                            <KatButton variant="link" onClick={() => this.handlePromoteClick(job)}>
                                <KatIcon size="small" slot="icon" name="arrow-right" />
                                Promote
                            </KatButton>
                            }
                            &nbsp;
                            <KatButton variant="link">
                                <KatIcon size="small" slot="icon" name="edit"/>
                                Edit
                            </KatButton>
                            &nbsp;
                            <KatButton variant="link">
                                <KatIcon size="small" slot="icon" name="delete"/>
                                Delete
                            </KatButton>
                        </KatTableCell>
                    </KatTableRow>
                ))}
                    </KatTableBody>
                </KatTable>
                <KatModal
                visible={this.state.showPromotionConfirmation}
                onClose={()=>{this.setState({
                    'showPromotionConfirmation': false,
                    'displaySuccessMessage': false,
                    'displayErrorMessage': false,
                })}}
                >
                    <span slot='title'>
                        {`Confirmation`}
                    </span>
                    {(JobDomain.EXPERIMENTATION.toString().toLowerCase() == this.props.domain.toLowerCase()) &&
                    <p>Are you sure you want to promote this job to gamma?</p>
                    }
                    {(JobDomain.GAMMA.toString().toLowerCase() == this.props.domain.toLowerCase()) &&
                    <div>
                        <kat-alert
                        header='Warning'
                        description='Promoting job to production. If you have checked the loadInServiceFleet option,
                        the output view.configPanel.data would be written in real-time services.'
                        variant="warning"
                        persistent
                        />
                        &nbsp;
                        <KatCheckbox
                        label={`This job has been promoted to prod in the corresponding query interface pipeline.`}
                        value={this.state.promotionConfirmationCheck}
                        onChange={(event: any)=>
                            this.setState({
                                'promotionConfirmationCheck': event.currentTarget.checked
                            })}
                        />
                        &nbsp;
                        <KatCheckbox
                        label={`This job has been tested in real time services and has sufficient hardware provisioned.`}
                        value={this.state.testingCheck}
                        onChange={(event: any)=>
                            this.setState({
                                'testingCheck': event.currentTarget.checked
                            })}
                        />
                        &nbsp;
                        <KatInput
                        value={this.state.approvalMessage}
                        type="text"
                        label="MCM/Sev-2 Link"
                        name="mcmOrSev2Link"
                        onChange={(event: any)=> 
                            this.setState({
                                'mcmOrSev2Link': event.currentTarget.value
                            })}
                        />
                    </div>
                    }
                    {this.state.apiLoading && <KatSpinner/>}
                    {this.state.displaySuccessMessage &&
                    <kat-alert header="Success" description={`Promoted job ${this.state.jobToPromote.jobName}`} variant="success"/>}
                    {this.state.displayErrorMessage &&
                    <kat-alert header="Server Error" description="Could not promote job." variant="danger"/>}
                    &nbsp;
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton label='Promote Job' onClick={()=>this.promoteJob()}/>
                        &nbsp;
                        <KatButton variant='secondary' label='Cancel'
                            onClick={()=>{this.setState({'showPromotionConfirmation': false})}}
                        />
                    </div>
                </KatModal>
        </>
            
        )
    }
}

function mapStateToProps(state: any) {
    return {
      username: state.user.username
    }
}

export default connect(mapStateToProps)(FeatureGenerationJobsTable)
