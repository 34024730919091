import React from 'react'
import {KatButton, KatTabs, KatTab, KatInput, KatSpinner} from '@amzn/katal-react'
import {apiPostCallback} from 'src/utils/api_handler'
import {connect} from 'react-redux'
import { isEmpty, isValidAWSAccountId } from 'src/utils/common_utils'
import {withRouter} from 'react-router'
import { getCreateFeatureGenerationNamespaceTarget } from 'src/control/targets'
import { JobDomain } from '../constants'

export class FeatureGenerationNamespaceForm extends React.Component<any, any> {

    _mounted: boolean = false
    
    constructor(props: any) {
        super(props)
        this.state = {
            namespace: 'my_new_namespace',
            accountTypeMap: {
                experimentation: {
                    awsAccountId: '',
                    awsAccountName: '',
                    outputS3Bucket: '',
                },
                gamma: {
                    awsAccountId: '',
                    awsAccountName: '',
                    outputS3Bucket: '',
                },
                prod: {
                    awsAccountId: '',
                    awsAccountName: '',
                    outputS3Bucket: '',
                }
            },
            requestedBy: null,
            teamId: this.props.match.params.teamId,
            displayApiLoading: false,
            displaySuccessMessage: false,
            displayErrorMessage: false,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleAccountMetadataChange = this.handleAccountMetadataChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.setState({'requestedBy': this.props.username})
        this._mounted = true
    }

    componentWillUnmount() {
        this._mounted = false
    }

    componentDidUpdate() {
        if(this.props.username != this.state.requestedBy) {
            this._mounted && this.setState({'requestedBy': this.props.username})
        }
    }

    private handleChange = (name: string, value: any) => {
        this._mounted && this.setState({[name]: value})
    }

    private handleAccountMetadataChange = (domain: string, name: string, value: string) => {
        this._mounted &&
        this.setState({'accountTypeMap': {
            ...this.state.accountTypeMap,
            [domain]: {
                ...this.state.accountTypeMap[domain],
                [name]: value
            }
        }})
    }
    private handleSubmit = (event: any) => {
        event.preventDefault()
        if(isEmpty(this.state.requestedBy)) alert('Invalid username')
        else if(!isValidAWSAccountId(this.state.accountTypeMap.experimentation.awsAccountId)) alert('Invalid account ID')
        else if(isEmpty(this.state.accountTypeMap.experimentation.awsAccountName.length)) alert('Invalid account name')
        else if(isEmpty(this.state.accountTypeMap.experimentation.outputS3Bucket.length)) alert('Invalid output S3 bucket')
        else {
            this._mounted && this.setState({'displayApiLoading': true})
            apiPostCallback(getCreateFeatureGenerationNamespaceTarget(), {
                ...this.state,
            }, (response: any) => {
                this._mounted && this.setState({'displaySuccessMessage': true, 'displayApiLoading': false})
            }, (error: any) => {
                this._mounted && this.setState({'displayErrorMessage': true, 'displayApiLoading': false})
            })
        }
    }

    render() {
        return (
            <>
                <h1>{`Create Namespace`}</h1>
                <form onSubmit={(event) => this.handleSubmit(event)}>
                    <KatInput
                    value={this.state.namespace}
                    type="text"
                    label="Name"
                    name="namespace"
                    onInput={(event: any) =>
                        this.handleChange(event.currentTarget.name, event.currentTarget.value)}
                    />
                    &nbsp;
                    <KatTabs selected={JobDomain.EXPERIMENTATION.toString().toLowerCase()}>
                        &nbsp;
                        {Object.entries(this.state.accountTypeMap).map(([key, _]) => {
                            return (
                                <KatTab key={key} tabId={key} label={key.charAt(0).toUpperCase() + key.slice(1)}>
                                    <KatInput
                                    value={this.state.accountTypeMap[key].awsAccountId}
                                    type="text"
                                    label="AWS Account ID"
                                    name="awsAccountId"
                                    onInput={(event: any) =>
                                        this.handleAccountMetadataChange(key, event.currentTarget.name, event.currentTarget.value)}
                                    />
                                    &nbsp;
                                    <KatInput
                                    value={this.state.accountTypeMap[key].awsAccountName}
                                    type="text"
                                    label="AWS Account Name"
                                    name="awsAccountName"
                                    onInput={(event: any) =>
                                        this.handleAccountMetadataChange(key, event.currentTarget.name, event.currentTarget.value)}
                                    />
                                    &nbsp;
                                    <KatInput
                                    value={this.state.accountTypeMap[key].outputS3Bucket}
                                    type="text"
                                    label="Output S3 bucket"
                                    name="outputS3Bucket"
                                    onInput={(event: any) =>
                                        this.handleAccountMetadataChange(key, event.currentTarget.name, event.currentTarget.value)}
                                    />
                                    &nbsp;
                                </KatTab>
                            )   
                        })}
                    </KatTabs>
                    &nbsp;
                    {this.state.displaySuccessMessage &&
                    <kat-alert header="Success" description={`Created namespace ${this.state.namespace}`} variant="success"/>}
                    {this.state.displayErrorMessage &&
                    <kat-alert header="Server Error" description="Could not create namespace." variant="danger"/>}
                    &nbsp;
                    {this.state.displayApiLoading && <KatSpinner/>}
                    <br/>
                    &nbsp;
                    <KatButton type='submit' label='Create Namespace'/>
                    &nbsp;
                </form>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    return {
      username: state.user.username
    }
}

export default withRouter(connect(mapStateToProps)(FeatureGenerationNamespaceForm))