import {Status} from "src/constant";

export const accountInfraInitialState = {
    experimentation: {
        infraFetched: false,
        infraFetchSuccess: false,
        infraData: {},
        dataStudioInfra : {}
    },
    gamma: {
        infraFetched: false,
        infraFetchSuccess: false,
        infraData: {}
    },
    prod: {
        infraFetched: false,
        infraFetchSuccess: false,
        infraData: {}
    }
}

export const experimentInitialState = {
    listExperiments: {
        experiments: [{}],
        pageToken: null,
        tableStatus: Status.LOADING,
        clusterStatusForExperiment: "",
        clusterFetchError: false,
        showLoader: false,
        showMessage: false,
        isSuccess: false,
        message: ""
    },
    createExperiment: {
        showCreateExperimentLoading: false,
        showCreateExperimentSuccess: false,
        showCreateExperimentDisplayMessage: false,
        createExperimentMessage: ''
    },
    editExperiment: {
        showUpdateExperimentLoading: false,
        showUpdateExperimentSuccess: false,
        showUpdateExperimentDisplayMessage: false,
        updateExperimentMessage: ""
    },
    fetchedExperiment: {
        experimentData: {
            kernel: "",
            namespaceId: "",
            runwayId: "",
            createTimestamp: "",
            experimentName: "",
            instanceType: "",
            lifecycleConfiguration: "",
            notebookStatus: "",
            updateTimestamp: "",
            userId: "",
            lcScriptInfo: null
        },
        experimentFetched: false,
        experimentFetchSuccess: false
    }
}

export const namespaceInitialState = {
    namespaceList: [],
    fetchedNamespace: {
        namespaceFetched: false,
        showNamespaceSuccess: false,
        namespaceData: {
            namespace: "",
            accountTypeMap: {},
            codePackages: [],
            codeRepositories: [],
            pipelineName: "",
            description: ""
        }
    }, createModelTrainingNamespace: {
        startLoader: false,
        isSuccess: false,
        isError: false
    }, updateModelTrainingNamespace: {
        startLoader: false,
        isSuccess: false,
        isError: false,
        showRegisterSuccess: false,
    }, registerCodeRepository: {
        startLoader: false,
        isSuccess: false,
        isError: false
    }, codeVersions: {
        preTrain: {
            startLoader: false,
            codeVersionsList: [],
        },
        postTrain: {
            startLoader: false,
            codeVersionsList: [],
        },
        train: {
            startLoader: false,
            codeVersionsList: [],
        }
    }
}

export const runwayInitialState = {
    fetchedRunway: {
        runway: {
            domain: "",
            namespaceId: "",
            runwayId: "",
            createdBy: "user",
            creationTime: "14/03/2021 08:00:00",
            runwayName: "",
            version: 1,
            scheduleFrequencyInMins: 60,
            baseTimestamp: 20220101000000,
            offsetInfo: {
                days: 0,
                hours: 0,
                minutes: 0
            },
            enabled: false,
            modelTrainingInfo: {
                customCodePackageName: "",
                preTrain: {
                    preTrainCustomCodeURI: "",
                    modelAgeThreshold: 0,
                    codeVersion: ""
                },
                train: {
                    trainingImage: "",
                    trainCustomCodeURI: "",
                    volumeSizeInGB: "50",
                    trainingExecutionRole: "",
                    instanceType: 'ml.t2.medium',
                    instanceCount: "1",
                    hyperparameters: {},
                    inputChannelInfoList: [],
                    modelOutputPath: "",
                    codeVersion: ""
                },
                postTrain: {
                    postTrainCustomCodeURI: "",
                    deployArtifactName: "",
                    deploymentBucket: "",
                    deploymentKey: "",
                    thresholdConditionsJson: "",
                    codeVersion : ""
                }
            },
            jobId: ""
        },
        latestVersion: 1,
        runwayFetched: false,
        showRunwaySuccess: false,
    }, createRunway: {
        startLoader: false,
        isSuccess: false,
        isError: false
    }, fetchedTrainingImages: {
        startLoader: false,
        trainingImagesFetched: false,
        trainingImagesFetchSuccess: false,
        trainingImages: {
            imageList:[],
            imageUri: ""
        }
    },
    updateRunway: {
        startLoader: false,
        isSuccess: false,
        isError: false
    },
    listRunways: {
        runwaysList: <any>[],
        runwaysFetched: false,
        fetchAllRunwaysSuccess: false,
        updateRunwayStatusSuccess: false,
        updateRunwayStatusFailure: false,
        startUpdateRunwayStatusLoader: false
    },
    fetchRunwayExecutions: {
        executionsList: <any>[],
        // Keeping load more enabled
        loadMore: true,
        runwaysExecutionsFetched: false,
        fetchAllExecutionsSuccess: false
    },
    executionMetrics: {
        metricData: {},
        runwaysExecutionMetricsFetched: false,
        fetchRunwaysExecutionMetricsSuccess: false
    }
}

