import {colorWhite, JobTemplateIcon, NavDatasetIcon} from "../../style/icons";
import React from "react";
import {NavItem, onClickParams} from "./nav_item_commons";

const _navigateToJobTemplatesPage = (props: onClickParams) => {
    let history = props.history;
    history.push(`/jobTemplate`);
}

const JobTemplatesNavItem: NavItem = {
    displayName: "Job Template",
    icon: <JobTemplateIcon style={colorWhite}/>,
    labelOnClick: _navigateToJobTemplatesPage,
    hasSubNav: false
}

export default JobTemplatesNavItem;