import {
    CREATE_EXPERIMENT_FAILURE,
    CREATE_EXPERIMENT_SUCCESS,
    CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS,
    DISABLE_RUNWAY_EXECUTIONS_LOAD_MORE,
    FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS,
    LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS,
    REFRESH_STATES,
    UPDATE_CREATE_EXPERIMENT_ON_FAILURE,
    UPDATE_CREATE_EXPERIMENT_ON_SUCCESS,
    UPDATE_EXPERIMENT_FLOW_STARTED,
    UPDATE_EXPERIMENT_SUCCESS
} from "src/control/actions/action_types";
import {Failure, Success} from "src/utils/action_utils";
import {
    DEFAULT_NAMESPACE_NAME,
    EXPERIMENT_CREATION_FAILED_MESSAGE,
    getCreateExperimentSuccessMessage,
    RUNWAY_EXECUTIONS_DEFAULT_PAGE_SIZE,
    Stages,
    UPDATING_EXPERIMENT_MESSAGE,
} from "src/view/modelTraining/constants";
import {loadAccountInfra} from "src/control/actions/modelTraining/account_infra_resources_actions";
import {isEmpty} from "src/utils/common_utils";
import {createRunway, loadRunwayExecutions,} from "src/control/actions/modelTraining/runway_actions";
import {getParamFromQueryString, matchURLRegex} from "src/utils/url_utils";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {getV2Runway, loadV2RunwayExecutions} from "src/control/actions/runways/runway_actions";


const getLoadNamespaceFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if (action.type === FETCH_MODEL_TRAINING_NAMESPACE_SUCCESS) {

        // LOAD INFRA POST NAMESPACE FETCH
        action.payload.stages.forEach((stage: Stages) => {
            if (!isEmpty(action.payload.accountTypeMap[stage.toLowerCase()])) {
                dispatch(loadAccountInfra(action.payload.accountTypeMap[stage.toLowerCase()], stage))
            }
        })
    }
    return nextAction
};

const getCreateExperimentResourcesFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if (action.type === CREATE_EXPERIMENT_SUCCESS) {
        if (action.payload.reusedNotebook != null) {
            dispatch(Success(UPDATE_CREATE_EXPERIMENT_ON_SUCCESS, {
                createExperimentMessage: action.payload.message
            }))
        } else if (action.payload.executionArn != null) {
            dispatch(Success(UPDATE_CREATE_EXPERIMENT_ON_SUCCESS, {
                createExperimentMessage: getCreateExperimentSuccessMessage(action.payload.notebookInstanceName, action.payload.userId)
            }))
        }
    } else if (action.type === CREATE_EXPERIMENT_FAILURE) {
        dispatch(Failure(UPDATE_CREATE_EXPERIMENT_ON_FAILURE, {
            createExperimentMessage: EXPERIMENT_CREATION_FAILED_MESSAGE
        }))
    }
    return nextAction
}

const getUpdateExperimentFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if (action.type === UPDATE_EXPERIMENT_SUCCESS) {
        if (action.payload.executionArn != null) {
            dispatch(Success(UPDATE_EXPERIMENT_FLOW_STARTED, {
                updateExperimentMessage: UPDATING_EXPERIMENT_MESSAGE
            }))
        } else if (action.payload.reusedNotebook != null) {
            dispatch(Success(UPDATE_EXPERIMENT_FLOW_STARTED, {
                updateExperimentMessage: action.payload.message
            }))
        }
    }
    return nextAction
}

const getCreateNamespaceFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if (action.type === CREATE_MODEL_TRAINING_NAMESPACE_SUCCESS && !isEmpty(action.payload) && !isEmpty(action.payload.accountTypeMap.experimentation)) {
        dispatch(createRunway({
            teamId: action.payload.teamId,
            namespaceId: action.payload.namespaceId,
            runwayName: DEFAULT_NAMESPACE_NAME,
            requestedBy: action.payload.userId,
            domain: Stages.EXPERIMENTATION.toUpperCase()
        }))
    }
    return nextAction
}

const loadRunwayExecutionsFlow = ({dispatch}: any) => (next: any) => async (action: any) => {
    const nextAction = next(action)
    // REFRESH STATES gets called on each location change, calling the dispatch after REFRESH_STATES, so
    // the changes due to the action don't get refreshed.
    if (action.type === REFRESH_STATES) {
        const params = matchURLRegex("/modelTraining/(.*)/namespaces/(.*)/runways/(.*)/executions")
        if (params && !window.location.pathname.includes("jobs")) {
            let latestRunwayData = await dispatch(getV2Runway(params[3]))
            if (!isEmpty(latestRunwayData.runwayRelease)) {
                dispatch(
                    loadV2RunwayExecutions(
                        latestRunwayData.runwayDAG[latestRunwayData.runwayDAG.length - 1].jobId,
                        true,
                        <string>getParamFromQueryString(location.search, "timeWindowStart"),
                        <string>getParamFromQueryString(location.search, "timeWindowEnd")
                    )
                )
            } else {
                dispatch(
                    loadRunwayExecutions(
                        params[3],
                        true,
                        <string>getParamFromQueryString(location.search, "timeWindowStart"),
                        <string>getParamFromQueryString(location.search, "timeWindowEnd")
                    )
                )
            }
            const teamId: string = params[1]
            const namespaceId: string = params[2]
            dispatch(loadModelTrainingNamespace(teamId, namespaceId, []))
        }
    }
    return nextAction
}

const listRunwaysFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    // REFRESH STATES gets called on each location change, calling the dispatch after REFRESH_STATES, so
    // the changes due to the action don't get refreshed.
    if (action.type === REFRESH_STATES) {
        const params = matchURLRegex("/modelTraining/(.*)/namespaces/(.*)/runways(.*)")
        const loadNamespace: boolean = window.location.pathname.endsWith("/runways") || window.location.pathname.includes("/runways?domain=")
        if (loadNamespace && params) {
            const teamId: string = params[1]
            const namespaceId: string = params[2]
            dispatch(loadModelTrainingNamespace(teamId, namespaceId, []))
        }
    }
    return nextAction
}

const disableLoadMoreForRunwayInstances = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if (action.type === LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS) {
        if (action.payload.length < RUNWAY_EXECUTIONS_DEFAULT_PAGE_SIZE) {
            // To disable load more button in case there are no more pages to be fetched.
            dispatch({type: DISABLE_RUNWAY_EXECUTIONS_LOAD_MORE})
        }
    }
    return nextAction
}

export default [
    getLoadNamespaceFlow,
    getCreateExperimentResourcesFlow,
    getUpdateExperimentFlow,
    getCreateNamespaceFlow,
    loadRunwayExecutionsFlow,
    disableLoadMoreForRunwayInstances,
    listRunwaysFlow
]
