export const sortList = (list: any[], sortParam: string) => {
    return list.sort((value1: any, value2: any) => {
        if (value1[sortParam] < value2[sortParam]) {
            return -1;
        }
        if (value1[sortParam] > value2[sortParam]) {
            return 1;
        }
        return 0;
    })
}

export const filterList = (list: any[], filterId: any, filterValue: any) => {
    let filteredList = list.filter(
        (listItem: any) => {
            return listItem[filterId] == filterValue;
        });
    return filteredList;
}

export const getUniqueItemsFromObjectList = (list: any[], key: any) => {
    let unique = new Set(list.map(listItem => listItem[key]));
    let uniqueList = Array.from(unique.values());
    return uniqueList;
}
