import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  KatButton,
  KatCard, KatIcon,
  KatPagination,
  KatSpinner,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableHead,
  KatTableRow,
} from "@amzn/katal-react";
import React, {useEffect, useRef, useState} from "react";
import {getJob, loadJobExecutions,} from "../../../control/actions/runways/job_actions";
import {namespaceSelector} from "../../../control/selectors/modelTraining/model_training_selectors";
import {
  jobDataSelector,
  listJobsExecutionsSelector,
  listJobsMetricsSelector,
} from "../../../control/selectors/runways/job_selectors";
import {PaddedSpan, TableRowWithShadow,} from "../../../view/style/table_styles";
import {JobStatus} from "../../../view/modelTraining/constants";
import {Regions} from "../../../constant";
import {getFromLocalStorageAndSetTeam, isEmpty, setIfMounted} from "../../../utils/common_utils";
import {sortList} from "../../../utils/list_utils";
import {FormInput} from "../../../view/style/modelTraining/form_input_styles";
import styled from "styled-components";
import {PopUp} from "../../../view/style/common_styles";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider,} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {
  convertToUTC,
  getDateTimeStringFromTimestamp,
  getLocalDateTimeStringFromTimestamp
} from "../../../utils/date_utils";
import {ErrorContainer, ErrorHeading,} from "../../../view/style/modelTraining/errors";
import {getParamFromQueryString} from "../../../utils/url_utils";
import {getAWSRegion} from "../../../utils/region_utils";
import {currentRegionSelector} from "../../../control/selectors/commons/regions_selectors";
import {getBeatsDomain} from "../../../utils/model_training_utils";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {ServiceType} from "src/model/runways/job_model";
import {getStatusBadge} from "../utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {fetchTeam} from "src/control/actions/commons/team_actions";

const moment = require("moment");

interface JobExecutions {
  executionId: string;
  executionTime: Date;
  executionStatus: JobStatus;
  stringTimeStamp: string;
}

export default function ViewJobExecutions() {
  const params: any = useParams();
  const dispatch = useDispatch();
  const TIMESTAMP_FORMAT = "YYYYMMDDhhmmss";
  const jobData = useSelector(jobDataSelector);
  const region = useSelector(currentRegionSelector);
  const fetchedNamespace = useSelector(namespaceSelector);

  let version = getParamFromQueryString(location.search, "liveVersion")

  const getStartDate = () => {
    const timeWindowStart = getParamFromQueryString(
      location.search,
      "timeWindowStart"
    );
    if (isEmpty(timeWindowStart)) {
      const startDate = convertToUTC(new Date());
      startDate.setDate(startDate.getDate() - 1);
      return startDate;
    } else {
      return moment(timeWindowStart, TIMESTAMP_FORMAT).toDate();
    }
  };

  const getEndDate = () => {
    const timeWindowEnd = getParamFromQueryString(
      location.search,
      "timeWindowEnd"
    );
    if (isEmpty(timeWindowEnd)) {
      return convertToUTC(new Date());
    } else {
      return moment(timeWindowEnd, TIMESTAMP_FORMAT).toDate();
    }
  };

  let listJobsExecution = useSelector(listJobsExecutionsSelector);
  let listJobsExecutionMetrics = useSelector(listJobsMetricsSelector);
  let _isMounted = useRef(true);

  useEffect(() => {
    dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
    dispatch(getJob(params.jobId, isEmpty(version) ? null : Number(version)));
    dispatch(loadJobExecutions(params.jobId, true));
  }, []);

  useEffect(() => {
    dispatch(fetchTeam(params.teamId, null, null))
  }, [params.teamId]);

  useEffect(() => {
    _isMounted.current = true;
  });

  useEffect(() => {
    return () => {
      _isMounted.current = false;
    };
  });

  // Component States
  const [startTime, setStartTime] = useState(getStartDate());
  const [endTime, setEndTime] = useState(getEndDate());
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [metricsPopUpVisible, setMetricsPopUpVisible] = useState(false);
  const [executionDetailsPopUpVisible, setExecutionDetailsPopUpVisible] =
    useState(false);
  const [logsJob, setLogsJob] = useState({
    executionId: "",
    executionTime: new Date(),
    executionStatus: "",
    stringTimestamp: "",
  });

  const jobExecutions: JobExecutions[] = sortList(
    listJobsExecution.executionsList.map((execution: any) => {
      return {
        executionId: execution.jobInstanceId,
        executionTime: execution.timestamp,
        executionStatus: execution.status,
        stringTimestamp: execution.timestamp,
      };
    }),
    "executionTime"
  ).reverse();

  // Component Specific Styles
  const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `;
  const InlineItem = styled.div`
    padding: 15px;
  `;

  const ItemsPerPage = styled(FormInput)`
    width: 100px;
  `;
  const onPageChanged = (event: any) => {
    if (!event.detail.page) {
      return;
    }
    setIfMounted(_isMounted.current, setSelectedPage, event.detail.page);
  };

  const getNumberOfPages = (): number => {
    return (
      Math.floor(jobExecutions.length / itemsPerPage) +
      (jobExecutions.length % itemsPerPage > 0 ? 1 : 0)
    );
  };

  const getCloudWatchLogs = (awsRegion: string, accountId: string) => {
    switch (jobData.serviceType) {
      case ServiceType.EMR:
        return `https://s3.console.aws.amazon.com/s3/buckets/expresso-artifacts-${accountId}?region=${awsRegion}&prefix=jobs/${jobData.jobId}/executions/${logsJob.stringTimestamp}/logs/`;
      case ServiceType.ECS:
        return `https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#logsV2:log-groups/log-group/ExpressoRunways$252Fimages$252FExpressoPythonDefaultImage$252Fjobs$252F${jobData.jobId}$252F${logsJob.stringTimestamp}`;
      default:
        return `https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#logsV2:log-groups/log-group/ExpressoRunways$252Fjobs$252F${jobData.jobId}/log-events/${logsJob.stringTimestamp}`;
    }
  };

  const getExecutionDetailsInfoPopUp = () => {
    const accountId: string =
      fetchedNamespace.namespaceData.accountTypeMap[
        jobData.domain.toLowerCase()
      ];
    const awsRegion: string = getAWSRegion(
      Regions[region as keyof typeof Regions]
    );

    return (
      <>
        <PopUp
          visible={executionDetailsPopUpVisible}
          onOpen={(event: any) => {
            setExecutionDetailsPopUpVisible(true);
          }}
          onClose={(event: any) => {
            setExecutionDetailsPopUpVisible(false);
          }}
        >
          <span slot={"title"}>View Execution Details</span>
          <KatCard>
            <span slot="subtitle">Step 1. Sign in to the AWS account</span>
            <KatButton
              label={`Sign in to AWS account - ${accountId}`}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                window
                  .open(
                    `https://conduit.security.a2z.com/accounts/aws/${accountId}`,
                    "_blank"
                  )
                  ?.focus();
              }}
            />
          </KatCard>
          <div />
          <br />
          <KatCard>
            <span slot="subtitle">Step 2. View Execution Details</span>
            <KatButton
              label={`CloudWatch Logs`}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                window
                  .open(getCloudWatchLogs(awsRegion, accountId), "_blank")
                  ?.focus();
              }}
            />
            <div />
            <br />
            <KatButton
              label={`CloudWatch Metrics`}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                window
                  .open(
                    `https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#dashboards:name=expresso-runway-${
                      params.runwayId
                    };start=${logsJob.executionTime.toISOString()};end=${logsJob.executionTime.toISOString()}`,
                    "_blank"
                  )
                  ?.focus();
              }}
            />
            <div />
          </KatCard>
        </PopUp>
      </>
    );
  };

  /**
   * Pop-up view for metrics.
   */
  const getMetricsPopUp = () => {
    return (
      <>
        <PopUp
          visible={metricsPopUpVisible}
          onOpen={(event: any) => {
            setIfMounted(_isMounted.current, setMetricsPopUpVisible, true);
          }}
          onClose={(event: any) => {
            setIfMounted(_isMounted.current, setMetricsPopUpVisible, false);
          }}
        >
          <span slot={"title"}>Metrics</span>
          <p>
            {listJobsExecutionMetrics.jobsExecutionMetricsFetched &&
            listJobsExecutionMetrics.fetchJobsExecutionMetricsSuccess ? (
              <KatTable>
                <KatTableHead>
                  <KatTableRow>
                    <KatTableCell>Metric Key</KatTableCell>
                    <KatTableCell>Metric Value</KatTableCell>
                  </KatTableRow>
                </KatTableHead>
                <KatTableBody>
                  {!isEmpty(listJobsExecutionMetrics.metricData)
                    ? Object.keys(listJobsExecutionMetrics.metricData).map(
                        (key) => {
                          return (
                            <>
                              <TableRowWithShadow key={key}>
                                <KatTableCell>{key}</KatTableCell>
                                <KatTableCell>
                                  {listJobsExecutionMetrics.metricData[key]}
                                </KatTableCell>
                              </TableRowWithShadow>
                            </>
                          );
                        }
                      )
                    : null}
                </KatTableBody>
              </KatTable>
            ) : listJobsExecutionMetrics.jobsExecutionMetricsFetched &&
              !listJobsExecutionMetrics.fetchJobsExecutionMetricsSuccess ? (
              <>
                <ErrorContainer>
                  <ErrorHeading>
                    Error loading job executions Metrics
                  </ErrorHeading>
                </ErrorContainer>
              </>
            ) : (
              <KatSpinner />
            )}
          </p>
        </PopUp>
      </>
    );
  };

  /**
   * Executions table row.
   * @param jobExecution
   */
  const getRow = (jobExecution: JobExecutions) => {
    return (
      <TableRowWithShadow key={jobExecution.executionId}>
        <KatTableCell>{jobExecution.executionId}</KatTableCell>
        <KatTableCell>
          <div style={{ display: "flex" }}>
            <div>
              GMT:&nbsp;
              {getDateTimeStringFromTimestamp(String(jobExecution.executionTime))}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              Local:&nbsp;
              {getLocalDateTimeStringFromTimestamp(String(jobExecution.executionTime))}
            </div>
          </div>
        </KatTableCell>
        <KatTableCell>
          {getStatusBadge(jobExecution.executionStatus)}
        </KatTableCell>
        <KatTableCell>
          <PaddedSpan>
            <KatButton
              label="View Details"
              size="small"
              variant="primary"
              onClick={() => {
                setIfMounted(_isMounted.current, setLogsJob, jobExecution);
                setIfMounted(
                  _isMounted.current,
                  setExecutionDetailsPopUpVisible,
                  true
                );
              }}
            >
              <KatIcon size="small" slot="icon" name="info-circle" />
            </KatButton>
          </PaddedSpan>
        </KatTableCell>
      </TableRowWithShadow>
    );
  };

  /**
   * Job executions table head
   */
  const getExecutionsTableHead = () => {
    return (
      <>
        <InlineContainer>
          <InlineItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Start Timestamp(UTC)"
                value={startTime}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) setStartTime(date);
                }}
                format="yyyy/MM/dd HH:mm"
              />
            </MuiPickersUtilsProvider>
          </InlineItem>
          <InlineItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="End Timestamp(UTC)"
                value={endTime}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) setEndTime(date);
                }}
                format="yyyy/MM/dd HH:mm"
              />
            </MuiPickersUtilsProvider>
          </InlineItem>
          <InlineItem>
            <KatButton
              label={"Show"}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                setIfMounted(_isMounted.current, setSelectedPage, 1);
                dispatch(
                  loadJobExecutions(
                    params.jobId,
                    true,
                    moment(startTime).format(TIMESTAMP_FORMAT),
                    moment(endTime).format(TIMESTAMP_FORMAT)
                  )
                );
              }}
            />
          </InlineItem>
          {/*Opening link in new page as this is a beats link*/}
          <InlineItem>
            <a
              href={`https://${getBeatsDomain(region)}/job/${
                jobData.jobScheduleInfo.schedulerJobId
              }/jobHistory`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <KatButton
                label="View Executions"
                size="base"
                variant="primary"
                disabled={isEmpty(jobData) || isEmpty(jobData.jobId)}
              />
            </a>
          </InlineItem>
        </InlineContainer>
      </>
    );
  };

  /**
   * Job executions table
   */
  const getExecutionTable = () => {
    return (
      <>
        <KatTable>
          <KatTableHead>
            <KatTableRow>
              <KatTableCell>Execution Id</KatTableCell>
              <KatTableCell>Schedule Time</KatTableCell>
              <KatTableCell>Execution Status</KatTableCell>
              <KatTableCell>Actions</KatTableCell>
            </KatTableRow>
          </KatTableHead>
          <KatTableBody>
            {listJobsExecution.jobsExecutionsFetched &&
            listJobsExecution.fetchAllExecutionsSuccess ? (
              <>
                {jobExecutions.length > 0 ? (
                  jobExecutions
                    .slice(
                      itemsPerPage * (selectedPage - 1),
                      itemsPerPage * selectedPage
                    )
                    .map(getRow)
                ) : (
                  <ErrorContainer>
                    <ErrorHeading>
                      No Executions in given time range
                    </ErrorHeading>
                  </ErrorContainer>
                )}
              </>
            ) : listJobsExecution.jobsExecutionsFetched &&
              !listJobsExecution.fetchAllExecutionsSuccess ? (
              <>
                <ErrorContainer>
                  <ErrorHeading>Error loading job Executions</ErrorHeading>
                </ErrorContainer>
              </>
            ) : (
              <KatSpinner />
            )}
          </KatTableBody>
        </KatTable>
      </>
    );
  };

  /**
   * Job executions table footer.
   */
  const getExecutionsTableFooter = () => {
    return (
      <>
        <InlineContainer>
          <KatPagination
            onChange={onPageChanged}
            totalItems={jobExecutions.length}
            itemsPerPage={itemsPerPage}
            page={selectedPage}
          />
          {selectedPage == getNumberOfPages() && listJobsExecution.loadMore ? (
            <KatButton
              label={"Load More"}
              size="small"
              variant="primary"
              onClick={(event: any) => {
                dispatch(
                  loadJobExecutions(
                    params.jobId,
                    false,
                    moment(
                      jobExecutions[jobExecutions.length - 1].executionTime
                    ).format(TIMESTAMP_FORMAT),
                    moment(endTime).format(TIMESTAMP_FORMAT)
                  )
                );
              }}
            />
          ) : null}
          <ItemsPerPage
            value={itemsPerPage.toString()}
            type="number"
            label="Items per page"
            name="itemsPerPage"
            onChange={(event: any) => {
              setIfMounted(
                _isMounted.current,
                setItemsPerPage,
                parseInt(event.target.value)
              );
            }}
          />
        </InlineContainer>
      </>
    );
  };

  return (
      <>
        {getMetricsPopUp()}
        {getExecutionDetailsInfoPopUp()}
        {
          !isEmpty(fetchedNamespace.namespaceData.namespace) && !isEmpty(jobData.jobName) ?
              <>
                <BreadCrumb
                    namespaceName={fetchedNamespace.namespaceData.namespace} runwayName={jobData.jobName}
                    jobName={jobData.jobName}/>
                <KatCard>
                  <span slot="title">Job Instances</span>
                  {getExecutionsTableHead()}
                  {getExecutionTable()}
                </KatCard>
                {getExecutionsTableFooter()}
              </> : <KatSpinner/>
        }
      </>
  );
}
