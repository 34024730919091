import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useLocation} from "react-router-dom";
import {
    FLOWS,
    getFlowFromURL,
    getParamFromQueryString,
    getParamFromURL,
    removeTrailingSlashes
} from "src/utils/url_utils";
import {
    CreateNewButton,
    PaddedSpan,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableHeadRow,
    TableRowWithShadow
} from "../style/table_styles";
import {CouldNotLoadAlert} from "../style/common_styles";
import {GreenSpinner} from "../style/header_styles";
import {colorGreen, colorGrey, DeleteIconTable, EditIconTable, InfoIcon, ViewIconTable} from "src/view/style/icons";
import * as configPanelActions from "../../control/actions/configPanel/config_panel_actions"
import * as featureGenerationActions from "../../control/actions/featureGeneration/namespace_actions"
import * as modelTrainingActions from "../../control/actions/modelTraining/namespace_actions"
import {ERROR_OPERATION_NOT_SUPPORTED, ERROR_TEAM_NOT_FOUND} from "src/control/errors";
import {formatDate, isEmpty, setIfMounted} from "src/utils/common_utils";
import * as configPanelSelectors from "src/control/selectors/configPanel/config_panel_selectors";
import * as featureGenerationSelectors from "src/control/selectors/featureGeneration/feature_generation_selectors";
import * as modelTrainingSelectors from "src/control/selectors/modelTraining/model_training_selectors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import ReactTooltip from "react-tooltip";
import {getUniqueItemsFromObjectList} from "src/utils/list_utils";
import {EXPRESSO_ONBOARDING_SIM} from "src/external_links";

const ListNamespaces = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const teamId = getParamFromURL("teamId");
    const flow = getFlowFromURL(location.pathname);

    //Used to prevent crashes due to memory leak problem
    let _isMounted = useRef(true);

    const [namespaceLoaded, setNamespaceLoaded] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);

    const getSelectorMethod = (flow: any) => {
        switch (flow) {
            case FLOWS.CONFIG_PANEL:
                return configPanelSelectors.allNamespacesSelector;
            case FLOWS.FEATURE_GENERATION:
                return featureGenerationSelectors.allNamespacesSelector;
            // TODO : Segregate Selectors for notebooks, runways and modelTraining
            case FLOWS.MODEL_TRAINING:
                return modelTrainingSelectors.allNamespacesSelector;
            case FLOWS.NOTEBOOKS:
                return modelTrainingSelectors.allNamespacesSelector;
            case FLOWS.RUNWAYS:
                return modelTrainingSelectors.allNamespacesSelector;
            default:
                return (state: any) => {
                };
        }
    }

    let namespaces = useSelector(getSelectorMethod(flow));

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    const getActionMethod = (flow: any) => {
        switch (flow) {
            case FLOWS.CONFIG_PANEL:
                return configPanelActions.fetchAllNamespaces;
            case FLOWS.MODEL_TRAINING:
                return modelTrainingActions.fetchAllNamespaces;
            case FLOWS.RUNWAYS:
                return modelTrainingActions.fetchAllNamespaces;
            case FLOWS.NOTEBOOKS:
                return modelTrainingActions.fetchAllNamespaces;
            case FLOWS.FEATURE_GENERATION:
                return featureGenerationActions.fetchAllNamespaces;
            default:
                return (teamId: any) => (dispatch: any) => {
                };
        }

    }

    const getCreateNewButton = (flow: any) => {
        return (
            <CreateNewButton
                variant={"primary"}
                onClick={handleCreateNew}
                disabled={flow === FLOWS.CONFIG_PANEL}
            >
                Create New!
            </CreateNewButton>
        )
    }

    const initialize = async () => {
        try {
            setIfMounted(_isMounted.current, setNamespaceLoaded, false);
            if (isEmpty(teamId)) {
                throw ERROR_TEAM_NOT_FOUND;
            }
            const fetchAllNamespaces = getActionMethod(flow);
            await dispatch(fetchAllNamespaces(teamId));
            setIfMounted(_isMounted.current, setErrorOccurred, false);
        } catch (error) {
            setIfMounted(_isMounted.current, setErrorOccurred, true);
            dispatch(showSnackBar(error.message));
        }

    }

    const initializeStates = () => {
        if (!isEmpty(namespaces)) {
            let loadedTeamIds = getUniqueItemsFromObjectList(namespaces, "teamId")
            loadedTeamIds.length == 1
            && setIfMounted(_isMounted.current, setNamespaceLoaded, loadedTeamIds[0] == teamId)
        }
    }

    useEffect(() => {
        if (_isMounted.current)
            initialize();
    }, [dispatch, teamId]);

    useEffect(() => {
        if (_isMounted.current)
            initializeStates();
    }, [dispatch, namespaces]);

    const domain = getParamFromQueryString(location.search, "domain");

    const handleNavigation = (namespaceId: string) => {
        let current_path = removeTrailingSlashes(location.pathname);
        let dest_path = current_path + "/" + namespaceId

        switch (flow) {
            case FLOWS.CONFIG_PANEL:
                dest_path = dest_path + "/configs";
                break;
            case FLOWS.MODEL_TRAINING:
                dest_path = dest_path + (domain == null ? "/runways" : "/runways?domain=" + domain.toString().toUpperCase());
                break;
            case FLOWS.NOTEBOOKS:
                dest_path = dest_path + (domain == null ? "" : "?domain=" + domain.toString().toUpperCase());
                break;
            case FLOWS.RUNWAYS:
                dest_path = dest_path + (domain == null ? "" : "?domain=" + domain.toString().toUpperCase());
                break;
            case FLOWS.FEATURE_GENERATION:
                dest_path = dest_path + "/jobs";
                break;
            default:
                dest_path = "/notFound";
                break;
        }

        history.push(
            dest_path
        );
    }

    const handleCreateNew = () => {
        let current_path = removeTrailingSlashes(location.pathname);
        let dest_path = current_path + "/new";
        if (flow === FLOWS.CONFIG_PANEL) {
            return;
        }
        if (FLOWS.MODEL_TRAINING || flow === FLOWS.NOTEBOOKS || flow === FLOWS.RUNWAYS) {
            dest_path = `${current_path}/create`;
        }
        history.push(dest_path);
    }

    const handleRedirectToViewNamespace = (namespaceId: string) => {
        let current_path = removeTrailingSlashes(location.pathname);
        let dest_path = current_path + "/" + namespaceId + "/view";
        switch (flow) {
            case FLOWS.CONFIG_PANEL:
                dispatch(showSnackBar(ERROR_OPERATION_NOT_SUPPORTED.message));
                return;
        }
        history.push(dest_path);

    }

    const handleRedirectToEditNamespace = (namespaceId: string) => {
        let current_path = removeTrailingSlashes(location.pathname);
        let dest_path = current_path + "/" + namespaceId + "/edit";
        switch (flow) {
            case FLOWS.CONFIG_PANEL:
            case FLOWS.FEATURE_GENERATION:
                dispatch(showSnackBar(ERROR_OPERATION_NOT_SUPPORTED.message));
                return;
        }
        history.push(dest_path);
    }

    const getFilteredRunwayNamespaces = (namespaceType: string) => {
        return namespaces.reduce((result: any[], namespace: any) => {
            switch (namespaceType) {
                case FLOWS.NOTEBOOKS:
                    if (!isEmpty(namespace.accountTypeMap.experimentation)) {
                        result.push(namespace);
                    }
                    break;
                case FLOWS.RUNWAYS:
                    if (!isEmpty(namespace.accountTypeMap.gamma) || !isEmpty(namespace.accountTypeMap.prod)) {
                        result.push(namespace);
                    }
                    break;
                default:
                    result.push(namespace);
            }
            return result;
        }, [])
    }

    const getNamespaceDescription = (namespace: any) => {
        if (FLOWS.CONFIG_PANEL === flow)
            return "Info";
        if (!isEmpty(namespace.description))
            return namespace.description;
        return "No Description"
    }

    const showNamespaces = () => {

        if (errorOccurred) {
            return (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    Could Not Find Namespaces
                </CouldNotLoadAlert>
            )
        }

        if (!namespaceLoaded) {
            return (<GreenSpinner variant={"default"} size={"large"}/>)
        }

        let namespacesToDisplay = namespaces;
        if (flow === FLOWS.NOTEBOOKS || flow === FLOWS.RUNWAYS) {
            // only filter if its Runways / Notebooks mamespace Flow
            namespacesToDisplay = getFilteredRunwayNamespaces(flow);
        }

        if (isEmpty(namespacesToDisplay)) {
            return flow === FLOWS.RUNWAYS || flow === FLOWS.NOTEBOOKS ? (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    If your AWS accounts ({flow === FLOWS.NOTEBOOKS ? "Experimentation" : "Gamma, Production"}) are
                    onboarded to Expresso, but you are not
                    able to see namespaces, you can create new namespaces. If your accounts are not onboarded, please
                    create a SIM <a href={EXPRESSO_ONBOARDING_SIM}>here</a>
                </CouldNotLoadAlert>
            ) : (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    Could Not Find Namespaces
                </CouldNotLoadAlert>
            )
        }

        return namespacesToDisplay.map((namespace: any, index: number) => (
            <TableRowWithShadow key={index}>
                <TableCell onClick={() => handleNavigation(namespace.namespaceId)}>
                    {namespace.namespace}
                </TableCell>
                <TableCell onClick={() => handleNavigation(namespace.namespaceId)}>
                    {namespace.createdBy}
                </TableCell>
                <TableCell onClick={() => handleNavigation(namespace.namespaceId)}>
                    {formatDate(namespace.creationTime)}
                </TableCell>
                <TableCell onClick={() => handleNavigation(namespace.namespaceId)}>
                    {namespace.lastUpdatedBy}
                </TableCell>
                <TableCell onClick={() => handleNavigation(namespace.namespaceId)}>
                    {formatDate(namespace.lastUpdatedTime)}
                </TableCell>
                <TableCell>
                    <ReactTooltip place="bottom" type="dark"/>

                    <PaddedSpan data-tip="View Namespace Details"
                                onClick={() => handleRedirectToViewNamespace(namespace.namespaceId)}>
                        <ViewIconTable style={flow === FLOWS.CONFIG_PANEL ? colorGrey : colorGreen}/>
                    </PaddedSpan>

                    <PaddedSpan data-tip="Edit Namespace"
                                onClick={() => handleRedirectToEditNamespace(namespace.namespaceId)}>
                        <EditIconTable
                            style={flow === FLOWS.CONFIG_PANEL || flow === FLOWS.FEATURE_GENERATION ? colorGrey : colorGreen}/>
                    </PaddedSpan>

                    <>
                        <ReactTooltip place="bottom" type="dark"/>
                        <PaddedSpan data-tip={getNamespaceDescription(namespace)}>
                            <InfoIcon
                                style={flow === FLOWS.CONFIG_PANEL || flow === FLOWS.FEATURE_GENERATION ? colorGrey : colorGreen}/>
                        </PaddedSpan>
                    </>

                    <PaddedSpan data-tip="Delete Namespace" onClick={() => {
                        dispatch(showSnackBar(ERROR_OPERATION_NOT_SUPPORTED.message))
                    }}>
                        <DeleteIconTable style={colorGrey}/>
                    </PaddedSpan>
                </TableCell>
            </TableRowWithShadow>
        ));

    }

    return (
        <>
            <div>
                <TableHeader> Namespaces
                    <>
                        <PaddedSpan
                            data-tip="Namespace is similar to a folder, that helps you group resources such as Notebooks, Runways, Live configs, or any other use cases under a common umbrella."
                        >
                            <InfoIcon/>
                        </PaddedSpan>
                    </>
                    {getCreateNewButton(flow)}
                </TableHeader>
            </div>
            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableCell>
                            Name
                        </TableCell>
                        <TableCell>
                            Created By
                        </TableCell>
                        <TableCell>
                            Created At
                        </TableCell>
                        <TableCell>
                            Last Updated By
                        </TableCell>
                        <TableCell>
                            Last Updated At
                        </TableCell>
                        <TableCell>
                            Actions
                        </TableCell>
                    </TableHeadRow>
                </TableHead>
                <TableBody>
                    {showNamespaces()}
                </TableBody>
            </Table>

        </>
    )
}

export default ListNamespaces;
