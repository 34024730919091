import {combineReducers} from "redux";
import namespaceReducer from "./namespace_reducer"
import experimentReducer from "./experiment_reducer"
import runwayReducer from "src/control/reducers/modelTraining/runway_reducer";
import accountInfraReducer from "src/control/reducers/modelTraining/account_infra_resources_reducer"
import dataStudioReducer from "src/control/reducers/notebooks/data_studio_reducers"

export default combineReducers({
    namespaces: namespaceReducer,
    runways: runwayReducer,
    accountInfra: accountInfraReducer,
    experiments: experimentReducer,
});