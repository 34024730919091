import {
    CREATE_RUNWAY_FAILURE,
    CREATE_RUNWAY_SUCCESS, DISABLE_RUNWAY_EXECUTIONS_LOAD_MORE,
    FETCH_ALL_RUNWAYS_FAILURE,
    FETCH_ALL_RUNWAYS_SUCCESS,
    FETCH_RUNWAY_FAILURE,
    FETCH_RUNWAY_SUCCESS,
    FETCH_TRAINING_IMAGES_FAILURE,
    FETCH_TRAINING_IMAGES_SUCCESS,
    LOAD_EXECUTION_METRICS_FAILURE,
    LOAD_EXECUTION_METRICS_SUCCESS,
    LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE,
    LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS,
    START_CREATE_RUNWAY_LOADER,
    START_FETCH_TRAINING_IMAGES_LOADER,
    START_LOAD_EXECUTION_METRICS,
    START_LOAD_RUNWAY_EXECUTIONS,
    START_UPDATE_RUNWAY_LOADER,
    START_UPDATE_RUNWAY_STATUS,
    UPDATE_LATEST_VERSION_SUCCESS,
    UPDATE_RUNWAY_FAILURE,
    UPDATE_RUNWAY_STATUS_FAILURE,
    UPDATE_RUNWAY_STATUS_SUCCESS,
    UPDATE_RUNWAY_SUCCESS,
} from "src/control/actions/action_types";
import {runwayInitialState} from "src/model/modelTraining/model_training_models"
import {switchRunwayStatus} from "src/view/modelTraining/constants";

export default function (state = runwayInitialState, action: any) {
    switch (action.type) {
        case FETCH_RUNWAY_SUCCESS:
            return {
                ...state,
                fetchedRunway: {
                    ...state.fetchedRunway,
                    runwayFetched: true,
                    showRunwaySuccess: true,
                    runway: action.payload
                }
            }
        case UPDATE_LATEST_VERSION_SUCCESS:
            return {
                ...state,
                fetchedRunway: {
                    ...state.fetchedRunway,
                    latestVersion: action.payload.version
                }
            }

        case FETCH_RUNWAY_FAILURE:
            return {
                ...state,
                fetchedRunway: {
                    ...state.fetchedRunway,
                    runwayFetched: true,
                    showRunwaySuccess: false
                }
            }
        case START_CREATE_RUNWAY_LOADER:
            return {
                ...state,
                createRunway: {
                    ...state.createRunway,
                    startLoader: true,
                    isSuccess: false,
                    isError: false
                }
            }
        case CREATE_RUNWAY_SUCCESS:
            return {
                ...state,
                createRunway: {
                    ...state.createRunway,
                    startLoader: false,
                    isSuccess: true,
                    isError: false
                }
            }
        case CREATE_RUNWAY_FAILURE:
            return {
                ...state,
                createRunway: {
                    ...state.createRunway,
                    startLoader: false,
                    isSuccess: false,
                    isError: true,
                    errorMessage : action.error
                }
            }
        case START_FETCH_TRAINING_IMAGES_LOADER:
            return {
                ...state,
                fetchedTrainingImages: {
                    ...state.fetchedTrainingImages,
                    trainingImagesFetched: false,
                    trainingImagesFetchSuccess: false,
                    startLoader: true
                }
            }
        case FETCH_TRAINING_IMAGES_SUCCESS:
            return {
                ...state,
                fetchedTrainingImages: {
                    ...state.fetchedTrainingImages,
                    trainingImagesFetched: true,
                    trainingImagesFetchSuccess: true,
                    trainingImages: action.payload,
                    startLoader: false,
                }
            }
        case FETCH_TRAINING_IMAGES_FAILURE:
            return {
                ...state,
                fetchedTrainingImages: {
                    ...state.fetchedTrainingImages,
                    trainingImagesFetched: true,
                    trainingImagesFetchSuccess: false,
                    startLoader: false,
                }
            }
        case START_UPDATE_RUNWAY_LOADER:
            return {
                ...state,
                updateRunway: {
                    ...state.updateRunway,
                    startLoader: true,
                    isSuccess: false,
                    isError: false
                }
            }
        case UPDATE_RUNWAY_SUCCESS:
            return {
                ...state,
                updateRunway: {
                    ...state.updateRunway,
                    startLoader: false,
                    isSuccess: true,
                    isError: false
                }
            }

        case UPDATE_RUNWAY_FAILURE:
            return {
                ...state,
                updateRunway: {
                    ...state.updateRunway,
                    startLoader: false,
                    isSuccess: false,
                    isError: true
                }
            }
        case FETCH_ALL_RUNWAYS_SUCCESS:
            return {
                ...state,
                listRunways: {
                    ...state.listRunways,
                    runwaysFetched: true,
                    fetchAllRunwaysSuccess: true,
                    runwaysList: action.payload
                }
            }
        case FETCH_ALL_RUNWAYS_FAILURE:
            return {
                ...state,
                listRunways: {
                    ...state.listRunways,
                    runwaysFetched: true,
                    fetchAllRunwaysSuccess: false,
                }
            }
        case UPDATE_RUNWAY_STATUS_SUCCESS:
            let newRunwayList: any[] = []
            state.listRunways.runwaysList.forEach((runway: any) => {
                if (runway.runwayId == action.payload.runwayId) {
                    newRunwayList.push({
                        ...runway,
                        runwayStatus: switchRunwayStatus(runway.runwayStatus)
                    })
                } else {
                    newRunwayList.push(runway)
                }
            })
            return {
                ...state,
                listRunways: {
                    ...state.listRunways,
                    runwaysList: newRunwayList,
                    updateRunwayStatusSuccess: true,
                    updateRunwayStatusFailure: false,
                    startUpdateRunwayStatusLoader: false
                }
            }
        case UPDATE_RUNWAY_STATUS_FAILURE:
            return {
                ...state,
                listRunways: {
                    ...state.listRunways,
                    updateRunwayStatusSuccess: false,
                    updateRunwayStatusFailure: true,
                    startUpdateRunwayStatusLoader: false
                }
            }
        case START_UPDATE_RUNWAY_STATUS:
            return {
                ...state,
                listRunways: {
                    ...state.listRunways,
                    updateRunwayStatusSuccess: false,
                    updateRunwayStatusFailure: false,
                    startUpdateRunwayStatusLoader: true
                }
            }
        case LOAD_RUNWAY_EXECUTIONS_STATUS_SUCCESS:
            return {
                ...state,
                fetchRunwayExecutions: {
                    ...state.fetchRunwayExecutions,
                    executionsList: [
                        ...state.fetchRunwayExecutions.executionsList,
                        ...action.payload
                    ],
                    runwaysExecutionsFetched: true,
                    fetchAllExecutionsSuccess: true
                }
            }
        case LOAD_RUNWAY_EXECUTIONS_STATUS_FAILURE:
            return {
                ...state,
                fetchRunwayExecutions: {
                    ...state.fetchRunwayExecutions,
                    runwaysExecutionsFetched: true,
                    fetchAllExecutionsSuccess: false
                }
            }
        case START_LOAD_RUNWAY_EXECUTIONS:
            return {
                ...state,
                fetchRunwayExecutions: {
                    ...state.fetchRunwayExecutions,
                    executionsList: [],
                    loadMore: true,
                    runwaysExecutionsFetched: false,
                    fetchAllExecutionsSuccess: false
                }
            }
        case DISABLE_RUNWAY_EXECUTIONS_LOAD_MORE:
            return {
                ...state,
                fetchRunwayExecutions: {
                    ...state.fetchRunwayExecutions,
                    loadMore: false
                }
            }
        case START_LOAD_EXECUTION_METRICS: {
            return {
                ...state,
                executionMetrics: {
                    ...state.executionMetrics,
                    metricData: {},
                    runwaysExecutionMetricsFetched: false,
                    fetchRunwaysExecutionMetricsSuccess: false
                }
            }
        }
        case LOAD_EXECUTION_METRICS_SUCCESS: {
            return {
                ...state,
                executionMetrics: {
                    ...state.executionMetrics,
                    metricData: action.payload,
                    runwaysExecutionMetricsFetched: true,
                    fetchRunwaysExecutionMetricsSuccess: true
                }
            }
        }
        case LOAD_EXECUTION_METRICS_FAILURE: {
            return {
                ...state,
                executionMetrics: {
                    ...state.executionMetrics,
                    runwaysExecutionMetricsFetched: true,
                    fetchRunwaysExecutionMetricsSuccess: false
                }
            }
        }
        default:
            return state;
    }
}
