import {Success, Event} from "../../../utils/action_utils";
import {SELECT_REGION, SHOW_REGION, LOAD_REGION_SUCCESS, LOAD_SUPPORTED_REGION, REGION_LOADED} from "src/control/actions/action_types";
import {Regions} from "src/constant";
import {FLOWS} from "src/utils/url_utils";
import {getRegionFromHostname} from "src/utils/region_utils";

export const selectRegion = (region:Regions) => (dispatch: any) => {
    dispatch(Success(SELECT_REGION, region));
}

export const showRegionDropDown = (showRegions:boolean) => (dispatch: any) => {
    dispatch(Success(SHOW_REGION, showRegions));
}

export const loadRegion = () => (dispatch: any) => {
    dispatch(Success(LOAD_SUPPORTED_REGION, Object.keys(Regions)));
    const region = getRegionFromHostname(window.location.hostname);
    dispatch(selectRegion(region));
    dispatch(Event(LOAD_REGION_SUCCESS));
}

export const setRegionLoaded = (loaded:boolean) => (dispatch: any) => {
    dispatch(Success(REGION_LOADED, loaded));
}
