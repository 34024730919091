import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {isEmpty, isEmptyObject, setIfMounted,} from "src/utils/common_utils";
import {useHistory, useParams} from "react-router";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {listV2Runways} from "src/control/actions/runways/runway_actions";
import {runwayListSelector,} from "src/control/selectors/runways/runway_selectors";
import {KatButton, KatFlashbar, KatSpinner} from "@amzn/katal-react";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {
    experimentationAccountInfraSelector,
    namespaceSelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {PopUp} from "../style/common_styles";
import sagemaker_studio_logo from "../images/sagemaker_studio_logo.png";
import spektr_studio_logo from "../images/spektr_datastudio_logo.png";
import sm_notebooks_logo from "../images/sm_notebook_logo.png";
import {currentRegionSelector} from "src/control/selectors/commons/regions_selectors";
import {getAWSRegion} from "src/utils/region_utils";
import {listDataStudios} from "src/control/actions/notebooks/data_studio_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {Card, CardBody, CardImageWrapper, CardsRow, CardsWrapper, CardTitle, LearnMoreLink} from "../style/home_styles";
import {DATA_STUDIO_USER_GUIDE, EXPRESSO_ONBOARDING_SIM} from "src/external_links";
import {OPERATION_NOT_SUPPORTED_MESSAGE, Regions} from "src/constant";
import {filterList} from "src/utils/list_utils";
import {Stages} from "src/view/modelTraining/constants";
import {isAdminWindow} from "src/utils/url_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

/**
 * renders the view into a list notebook products table
 * @returns JSX
 */
export default function ListNotebookProducts() {

    let _isMounted = useRef(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();

    //selectors
    let userName = useSelector(usernameSelector);
    let fetchedNamespace = useSelector(namespaceSelector);
    let runwayListData = useSelector(runwayListSelector);
    let experimentationAccountInfraData = useSelector(experimentationAccountInfraSelector);
    const region = useSelector(currentRegionSelector);

    const [sagemakerStudioPopUpVisibility, setSagemakerStudioPopUpVisibility] = useState(false);
    const [expresssoOnboardingPopUpVisibility, setExpresssoOnboardingPopUpVisibility] = useState(false);
    const [errorBarVisibility, setErrorBarVisibility] = useState(false);
    let isAdminView = isAdminWindow();

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    //useEffect hooks
    useEffect(() => {
        if (!isAdminView) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, [Stages.EXPERIMENTATION]));
            dispatch(listV2Runways(params.namespaceId));
        }
    }, [userName, params.teamId, params.namespaceId]);

    useEffect(() => {
        if (!isAdminView)
            dispatch(listDataStudios(fetchedNamespace.namespaceData.accountTypeMap.experimentation));
    }, [fetchedNamespace]);

    const getOnboardingPopUp = (type: string, visibility: boolean, setter: any, simLink: string) => {
        return (
            <>
                <PopUp
                    visible={visibility}
                    onOpen={() => {
                        setIfMounted(_isMounted.current, setter, true);
                    }}
                    onClose={() => {
                        setIfMounted(_isMounted.current, setter, false);
                    }}
                >
                    <span slot={"title"}>Do you want to onboard to {type}?</span>
                    <p>Account linked to this namespace is not onboarded to {type}. If you want to get started with Data
                        Studio, click on "Create SIM" below.</p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton
                            label="Cancel"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                setIfMounted(_isMounted.current, setter, false);
                            }}
                        />
                        <KatButton
                            label="Create SIM"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                window.open(simLink, "_blank")
                            }}
                        />
                    </div>
                </PopUp>
            </>
        );
    }

    const handleRedirectionForSMNotebooks = (runwayId: string) => {
        if (!isEmpty(runwayId))
            history.push(window.location.pathname + `/${runwayId}` + '/experiments');
        else
            setIfMounted(_isMounted.current, setErrorBarVisibility, true)
    }

    const getNotebookProductCard = (title: string, logo: any, body: string, onClickCallback: any, userGuideUrl?: string) => {
        return <Card onClick={onClickCallback}>
            <CardImageWrapper> <img
                className={"flow-logo"}
                src={logo}
                alt={title}
            /></CardImageWrapper>
            <CardTitle>{title}</CardTitle>
            <CardBody>{body}</CardBody>
            {!isEmpty(userGuideUrl) ? <LearnMoreLink href={userGuideUrl} target="_blank"
                                                     rel="noopener noreferrer"> user-guide </LearnMoreLink> : <></>}
        </Card>
    }

    const getNotebookCards = () => {
        let sagemakerDomainId = fetchedNamespace.namespaceData.sagemakerDomainId;
        return (
            <CardsWrapper>
                <span slot="subtitle">Notebooks</span>
                <CardsRow>
                    {
                        getNotebookProductCard(
                            "Sagemaker Notebooks",
                            sm_notebooks_logo,
                            "Standalone, fully managed Jupyter Notebooks for ML Experimentation",
                            () => {
                                if (isAdminView)
                                    dispatch(showSnackBar(OPERATION_NOT_SUPPORTED_MESSAGE))
                                else if (isEmptyObject(experimentationAccountInfraData.infraData?.infraExports))
                                    setIfMounted(_isMounted.current, setExpresssoOnboardingPopUpVisibility, true)
                                else
                                    handleRedirectionForSMNotebooks(getExperimentationRunwayId())
                            }
                        )
                    }
                    {
                        getNotebookProductCard(
                            "Sagemaker Studio",
                            sagemaker_studio_logo,
                            "Quick start, collaborative notebooks for ML development - from data preparation, training, to deploying models within an IDE-based environment",
                            () => {
                                if (isAdminView)
                                    dispatch(showSnackBar(OPERATION_NOT_SUPPORTED_MESSAGE))
                                else if (!isEmpty(sagemakerDomainId))
                                    setIfMounted(_isMounted.current, setSagemakerStudioPopUpVisibility, true)
                                else
                                    setIfMounted(_isMounted.current, setExpresssoOnboardingPopUpVisibility, true)
                            }
                        )
                    }
                    {
                        getNotebookProductCard(
                            "Data Studio",
                            spektr_studio_logo,
                            "Collaborative notebooks for fast Exploratory Data Analysis (EDA) leveraging high performant EMR-EKS compute.",
                            () => {
                                history.push(window.location.pathname + "/dataStudio/onboardings")
                            }, DATA_STUDIO_USER_GUIDE
                        )
                    }
                </CardsRow>
            </CardsWrapper>
        );
    }

    const getSMStudioNavigationPopUp = () => {
        const awsRegion = getAWSRegion(Regions[region as keyof typeof Regions])
        let experimentationAccountId =
            fetchedNamespace.namespaceData.accountTypeMap.experimentation;
        let sagemakerDomainId = fetchedNamespace.namespaceData.sagemakerDomainId;
        return (
            <PopUp
                visible={sagemakerStudioPopUpVisibility}
                onOpen={() => {
                    setIfMounted(_isMounted.current, setSagemakerStudioPopUpVisibility, true);
                }}
                onClose={() => {
                    setIfMounted(_isMounted.current, setSagemakerStudioPopUpVisibility, false);
                }}
            >
                <span slot={"title"}>Navigation steps to SM Studio</span>
                <KatButton
                    label={`Step 1. Sign in to AWS Account - ${experimentationAccountId}`}
                    onClick={() => {
                        window.open(
                            `https://conduit.security.a2z.com/accounts/aws/${experimentationAccountId}`
                        );
                    }}
                ></KatButton>
                <br/>
                <br/>
                <KatButton
                    label={`Step 2. Navigate to SM studio`}
                    onClick={() => {
                        window.open(
                            `https://${awsRegion}.console.aws.amazon.com/sagemaker/home?region=${awsRegion}#/studio/${sagemakerDomainId}`
                        );
                    }}
                ></KatButton>
                <br/>
                <br/>
                <div>
                    Click{" "}
                    <a
                        href="https://w.amazon.com/bin/view/Advertising/SupplyQuality/SQI/Expresso-User-Guide/SagemakerStudio/"
                        target="_blank"
                    >
                        here
                    </a>{" "}
                    for user guide
                </div>
            </PopUp>
        );
    };

    const getExperimentationRunwayId = () => {
        let filterRunways = filterList(runwayListData, "domain", Stages.EXPERIMENTATION.toUpperCase());
        filterRunways = filterList(filterRunways, "runwayName", "Default");
        return filterRunways.length == 1 ? filterRunways[0].runwayId : null;
    }


    return (
        <>
            {getOnboardingPopUp("Expresso", expresssoOnboardingPopUpVisibility, setExpresssoOnboardingPopUpVisibility, EXPRESSO_ONBOARDING_SIM)}
            {getSMStudioNavigationPopUp()}
            {
                errorBarVisibility ?
                    <KatFlashbar variant={"danger"}
                                 header={"Error"}
                                 description={"Error Loading Sagemaker notebooks"}
                    >
                    </KatFlashbar> : <></>
            }
            {
                !isAdminView ? <> {
                    experimentationAccountInfraData.infraFetchSuccess && !isEmpty(fetchedNamespace.namespaceData.namespace) ?
                        <>
                            <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}></BreadCrumb>
                            {getNotebookCards()}
                        </> : <KatSpinner/>
                }
                </> : getNotebookCards()
            }
        </>
    );
}
