import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    TableCellItem,
    TableHead,
    TableHeader,
    TableHeadRow,
    TableRow,
    TableRowWithShadow,
} from "../style/table_styles";
import {formatDate, isEmpty, isValidMcmOrSev2Link,} from "../../utils/common_utils";
import {getViewLogsMetricsUrl, Stages, switchRunwayStatus, toggledSortOrdering,} from "./constants";
import {GreenSpinner, Tab, Tabs} from "../style/header_styles";
import {Ordering} from "../dataset/dataset_constants";
import {
    colorGrey,
    EditIconTable,
    SortAscending,
    SortDescending,
    ToggleOff,
    ToggleOn,
    ViewIconTable,
} from "../style/icons";
import {useHistory, useParams} from "react-router";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {listV2Runways, updateV2RunwayStatus,} from "src/control/actions/runways/runway_actions";
import {RunwayStatus} from "src/model/runways/runway_model";
import {isRunwaySpinnerLoadingSelector, runwayListSelector,} from "src/control/selectors/runways/runway_selectors";
import {KatButton, KatCard, KatPopover, KatSpinner, KatTable, KatTableBody, KatTableCell,} from "@amzn/katal-react";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {CreateButton, RunwayStatusIndicator} from "../style/runway_styles";
import ReactTooltip from "react-tooltip";
import {VInput, VKey, VValue} from "../style/view_config_styles";
import {getParamFromQueryString, setParamToQueryString,} from "src/utils/url_utils";
import {PopUp} from "../style/common_styles";
import {Domains} from "../configPanel/config_constants";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {filterList, sortList} from "src/utils/list_utils";
import {listDataStudios} from "src/control/actions/notebooks/data_studio_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

enum SortBy {
    runwayName = "runwayName",
    createdBy = "createdBy",
    creationTime = "creationTime",
    lastUpdatedBy = "lastUpdatedBy",
    lastUpdatedTime = "lastUpdatedTime",
}

/**
 * renders the view into a list runways table
 * @returns JSX
 */
export default function ListRunways() {

    let _isMounted = useRef(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const params: any = useParams();

    //selectors
    let userName = useSelector(usernameSelector);
    let fetchedNamespace = useSelector(namespaceSelector);
    let isSpinnerLoading = useSelector(isRunwaySpinnerLoadingSelector);
    let runwayListData = useSelector(runwayListSelector);

    //state hooks
    const [sortBy, setSortBy] = useState(SortBy.runwayName);
    const [sortOrder, setSortOrder] = useState(Ordering.Ascending);
    const [enablePopUpVisible, setEnablePopUpVisible] = useState(false);
    const [mcmOrSev2, setMcmOrSev2] = useState("");
    const [selectedRunway, setSelectedRunway] = useState<any>({
        status: RunwayStatus.DISABLED,
        runwayId: "",
        domain: "",
    });
    const [domain, setDomain]: any = useState(
        getParamFromQueryString(location.search, "domain")
    );
    const domains = [
        Stages.GAMMA.toUpperCase(),
        Stages.PROD.toUpperCase(),
    ];
    const [isMCMOrSev2LinkValid, setIsMCMOrSev2LinkValid] = useState(false);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    //useEffect hooks
    useEffect(() => {
        dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
        dispatch(listV2Runways(params.namespaceId));
    }, [userName]);

    useEffect(() => {
        dispatch(listDataStudios(fetchedNamespace.namespaceData.accountTypeMap.experimentation));
    }, [fetchedNamespace]);
    /**
     * checks if domain is prod or not
     * @param domain
     * @returns boolean
     */
    const isProdDomain = (domain: string) => {
        return Domains.PROD === domain.toUpperCase();
    };

    /**
     * sets the property on which sorting needs to happen
     * @param sortByProperty
     */
    const handleOnSortClick = (sortByProperty: SortBy) => {
        if (sortBy == sortByProperty) {
            setSortOrder(toggledSortOrdering(sortOrder));
        } else {
            setSortBy(sortByProperty);
            setSortOrder(Ordering.Ascending);
        }
    };

    /**
     * returns the sort icon based upon sorting order
     * @param property
     * @returns JSX
     */
    const getSortIcon = (property: SortBy) => {
        if (sortBy == property) {
            return sortOrder == Ordering.Ascending ? (
                <div
                    className={"float-right"}
                    onClick={() => handleOnSortClick(property)}
                >
                    <SortAscending/>
                </div>
            ) : (
                <div
                    className={"float-right"}
                    onClick={() => handleOnSortClick(property)}
                >
                    <SortDescending/>
                </div>
            );
        } else {
            return (
                <div
                    className={"float-right"}
                    onClick={() => handleOnSortClick(property)}
                >
                    <SortAscending style={colorGrey}/>
                </div>
            );
        }
    };

    /**
     * redirects to view page
     * @param runwayId
     */
    const handleRedirectToView = (runwayId: string) => {
        history.push(window.location.pathname + `/${runwayId}` + `/view`);
    };

    /**
     * validates pre-requisistes for update
     * @param mcmOrSev2
     * @param domain
     * @returns boolean
     */
    const validateUpdatePrerequisites = (mcmOrSev2: string, domain: string) => {
        return isProdDomain(domain) ? isValidMcmOrSev2Link(mcmOrSev2) : true;
    };

    /**
     * renders view for mcm area
     * @returns JSX
     */
    const getMCMOrSev2Row = () => {
        return (
            <TableRow>
                <VKey>
                    MCM/Sev2 Link *
                    <RunwayStatusIndicator
                        variant={isMCMOrSev2LinkValid ? "success" : "error"}
                        label={""}
                    />
                </VKey>
                <VValue>
                    <VInput
                        required
                        value={mcmOrSev2}
                        placeholder={"MCM/Sev2 for Updating"}
                        onInput={(e: any) => {
                            setMcmOrSev2(e.target.value);
                            setIsMCMOrSev2LinkValid(
                                validateUpdatePrerequisites(
                                    e.target.value,
                                    selectedRunway.domain
                                )
                            );
                        }}
                    />
                </VValue>
            </TableRow>
        );
    };

    const getRunwayStatusComponent = (runway: any) => {
        switch (runway.runwayStatus.toUpperCase()) {
            case RunwayStatus.CREATE_FAILED: {
                return <RunwayStatusIndicator variant="alert" label="Create Failed"/>;
            }
            case RunwayStatus.UPDATE_FAILED: {
                return <RunwayStatusIndicator variant="alert" label="Update Failed"/>;
            }
            case RunwayStatus.CREATING: {
                return <RunwayStatusIndicator variant="loading" label="Creating"/>;
            }
            case RunwayStatus.UPDATING: {
                return <RunwayStatusIndicator variant="loading" label="Updating"/>;
            }
            default: {
                return (
                    <div
                        className={"row"}
                        data-tip="Enable/disable runway"
                        onClick={() => {
                            setEnablePopUpVisible(true);
                            setSelectedRunway(runway);
                        }}
                    >
                        {runway.runwayStatus.toUpperCase() === RunwayStatus.ENABLED ? (
                            <div>
                                <div className={"custom-toggle"}>
                                    <RunwayStatusIndicator variant="success" label="Enabled"/>
                                    &nbsp;&nbsp;
                                    <ToggleOn/>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className={"custom-toggle"}>
                                    <RunwayStatusIndicator variant="error" label="Disabled"/>
                                    &nbsp;&nbsp;
                                    <ToggleOff/>
                                </div>
                            </div>
                        )}
                    </div>
                );
            }
        }
    };

    const V1RunwaysViewLogsMetrics = (stage: string, runwayId: string) => {
        const accountId: string =
            fetchedNamespace.namespaceData.accountTypeMap[stage];
        const dashboardName: string = `expresso-${runwayId}-modelTraining`;
        const dashboardUrl: string = constructCloudWatchDashboardLink(
            accountId,
            dashboardName
        );
        window.open(dashboardUrl, "_blank")?.focus();
    };

    const constructCloudWatchDashboardLink = (
        accountId: string,
        dashboardName: string
    ) => {
        return `https://cw-dashboards.aka.amazon.com/cloudwatch/dashboardInternal?accountId=${accountId}#dashboards:name=${dashboardName}`;
    };

    const getCloneButtonComponent = (runwayRelease: any, runwayId: string) => {
        return isEmpty(runwayRelease) ? (
            <KatButton
                label="Clone"
                size="small"
                variant="primary"
                onClick={() => {
                    history.push(
                        window.location.pathname + `/create?runwayId=${runwayId}`
                    );
                }}
            />
        ) : (
            <KatPopover
                katAriaBehavior="tooltip"
                position="right"
                triggerType="hover"
            >
                <KatButton
                    label="Clone"
                    size="small"
                    variant="primary"
                    disabled={true}
                    slot="trigger"
                ></KatButton>
                Coming Soon!
            </KatPopover>
        );
    };

    /**
     * renders list of runways in a table rows based upon stage tab selected
     * @param filterRunways
     * @returns runwayList<JSX>
     */
    const fillRows = (filterRunways: any[]) => {
        let runwayList = sortList(filterRunways, sortBy);
        runwayList =
            sortOrder == Ordering.Ascending ? runwayList : runwayList.reverse();
        return runwayList.map((runway: any, key: number) => {
            return (
                <TableRowWithShadow key={key}>
                    <KatTableCell onClick={() => handleRedirectToView(runway.runwayId)}>
                        <div>{runway.runwayName}</div>
                    </KatTableCell>
                    <KatTableCell onClick={() => handleRedirectToView(runway.runwayId)}>
                        <div>{runway.createdBy}</div>
                    </KatTableCell>
                    <KatTableCell onClick={() => handleRedirectToView(runway.runwayId)}>
                        <div>{runway.creationTime && formatDate(runway.creationTime)}</div>
                    </KatTableCell>
                    <KatTableCell>
                        <ReactTooltip place="bottom" type="dark"/>
                        <TableCellItem>
                            <TableCellItem>
                                <div
                                    data-tip="Edit Runway Details"
                                    onClick={(event: any) => {
                                        history.push(
                                            window.location.pathname +
                                            `/${runway.runwayId}/${runway.latestVersion}/update`
                                        );
                                    }}
                                >
                                    <EditIconTable
                                        style={{
                                            margin: "10px",
                                            width: "20px",
                                            color: "#002e36",
                                        }}
                                    />
                                </div>
                                <div
                                    data-tip="View Runway Details"
                                    onClick={(event: any) => {
                                        handleRedirectToView(runway.runwayId);
                                    }}
                                >
                                    <ViewIconTable
                                        style={{
                                            margin: "10px",
                                            width: "20px",
                                            color: "#002e36",
                                        }}
                                    />
                                </div>
                                <div style={{margin: "10px"}}>
                                    <KatButton
                                        label="View Executions"
                                        size="small"
                                        variant="primary"
                                        onClick={(e: any) =>
                                            history.push(
                                                window.location.pathname +
                                                `/${runway.runwayId}/executions`
                                            )
                                        }
                                    />
                                </div>
                                <div style={{margin: "10px"}}>
                                    <KatButton
                                        label="View Logs/Metrics"
                                        size="small"
                                        variant="primary"
                                        onClick={() => {
                                            !isEmpty(runway.runwayRelease)
                                                ? window.open(
                                                    getViewLogsMetricsUrl(
                                                        fetchedNamespace.namespaceData.accountTypeMap[
                                                            runway.domain.toLowerCase()
                                                            ],
                                                        runway.runwayId
                                                    )
                                                )
                                                : V1RunwaysViewLogsMetrics(
                                                    runway.domain.toLowerCase(),
                                                    runway.runwayId
                                                );
                                        }}
                                    />
                                </div>
                                <div style={{margin: "10px"}}>
                                    {getCloneButtonComponent(
                                        runway.runwayRelease,
                                        runway.runwayId
                                    )}
                                </div>
                            </TableCellItem>
                        </TableCellItem>
                    </KatTableCell>
                    <KatTableCell>{getRunwayStatusComponent(runway)}</KatTableCell>
                </TableRowWithShadow>
            );
        });
    };

    /**
     * sets domain and query param
     * @param domain
     */
    const handleSelectDomain = (domain: string) => {
        setParamToQueryString("domain", domain);
        setDomain(domain);
    };

    /**
     * renders data into table headers
     * @returns JSX
     */
    const display = () => {
        return (
            <Tabs selected={domain}>
                {domains.map((domain: string, index: number) => {
                    const filterRunways = filterList(runwayListData, "domain", domain);
                    return (
                        <>
                            <Tab
                                key={index}
                                tabId={domain}
                                label={domain}
                                onClick={() => handleSelectDomain(domain)}
                            >
                                <KatTable>
                                    <>
                                        <TableHead>
                                            <TableHeadRow>
                                                <KatTableCell>
                                                    <TableCellItem>
                                                        Name&nbsp;&nbsp;&nbsp;
                                                        {getSortIcon(SortBy.runwayName)}
                                                    </TableCellItem>
                                                </KatTableCell>
                                                <KatTableCell>
                                                    <TableCellItem>
                                                        Created By&nbsp;&nbsp;&nbsp;
                                                        {getSortIcon(SortBy.createdBy)}
                                                    </TableCellItem>
                                                </KatTableCell>
                                                <KatTableCell>
                                                    <TableCellItem>
                                                        Created At&nbsp;&nbsp;&nbsp;
                                                        {getSortIcon(SortBy.creationTime)}
                                                    </TableCellItem>
                                                </KatTableCell>
                                                <KatTableCell>
                                                    <div>Actions</div>
                                                </KatTableCell>
                                                <KatTableCell>Status</KatTableCell>
                                            </TableHeadRow>
                                        </TableHead>
                                        <KatTableBody>{fillRows(filterRunways)}</KatTableBody>
                                    </>
                                </KatTable>
                            </Tab>
                        </>
                    );
                })}
            </Tabs>
        );
    };

    const enableDisableRunway = async (status: string) => {
        if (isProdDomain(selectedRunway.domain) && !isMCMOrSev2LinkValid) {
            dispatch(
                showSnackBar("Please provide a valid MCM/Sev2 link to continue")
            );
        } else {
            await dispatch(
                updateV2RunwayStatus(selectedRunway.runwayId, userName, status)
            );
        }
        setEnablePopUpVisible(false);
        setMcmOrSev2("");
    };

    /**
     * renders view for enabling/disabling runway status
     * @returns JSX
     */
    const getInfrastructureInfoPopUp = () => {
        const buttonLabel: string =
            selectedRunway.runwayStatus == RunwayStatus.DISABLED
                ? "Enable Runway"
                : "Disable Runway";
        const runwayStatus: string = switchRunwayStatus(
            selectedRunway.runwayStatus
        );

        return (
            <>
                <PopUp
                    visible={enablePopUpVisible}
                    onOpen={() => {
                        setEnablePopUpVisible(true);
                    }}
                    onClose={() => {
                        setEnablePopUpVisible(false);
                    }}
                >
                    <span slot={"title"}>{buttonLabel}</span>
                    <p>Are you sure you want to change runway status to {runwayStatus}</p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton
                            label={buttonLabel}
                            size="base"
                            variant="primary"
                            onClick={(event: any) => {
                                enableDisableRunway(runwayStatus);
                            }}
                        />
                    </div>
                    {isProdDomain(selectedRunway.domain) && getMCMOrSev2Row()}
                </PopUp>
            </>
        );
    };

    if (isEmpty(fetchedNamespace.namespaceData.namespace) || isSpinnerLoading) {
        return <GreenSpinner variant={"default"} size={"large"}/>;
    }

    return (
        <>
            {getInfrastructureInfoPopUp()}
            {
                !isEmpty(fetchedNamespace.namespaceData.namespace) ?
                    <>
                        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}></BreadCrumb>
                        <KatCard>
                            {
                                <div>
                                    <CreateButton
                                        variant="primary"
                                        label="Create New"
                                        onClick={() => {
                                            history.push(window.location.pathname + "/create");
                                        }}
                                    />
                                </div>
                            }
                            <div style={{width: "100%"}}>
                                <div>
                                    <TableHeader>
                                        Runways
                                    </TableHeader>
                                    <br/>
                                    {display()}
                                </div>
                            </div>
                        </KatCard>
                    </> : <KatSpinner/>}
        </>
    );
}
