export enum ValidationStatus {
    VALID = "VALID",
    INVALID = "INVALID",
    IN_PROGRESS = "IN_PROGRESS",
    INTERNAL_FAILURE = "INTERNAL_FAILURE",
    STARTING = "STARTING"
}

export enum ValidatorType {
    SYNTACTIC = "SYNTACTIC",
    SEMANTIC = "SEMANTIC"
}