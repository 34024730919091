import React, {useEffect, useRef, useState} from "react";
import {useLocation, useParams, useRouteMatch} from "react-router-dom";
import {
    BreadCrumbBar,
    BreadCrumbGroup,
    BreadCrumbItemPadding,
    BreadCrumbItemStyle
} from "src/view/style/bread_crumb_styles";
import {GreenSpinner} from "src/view/style/header_styles";
import {FLOWS, getFlowFromURL, removeTrailingSlashes} from "src/utils/url_utils";
import {
    CodeRepositoriesCrumbs,
    ConfigPanelCrumbs,
    DatasetCrumbs,
    FeatureGenerationCrumbs,
    JobTemplateCrumbs,
    ModelTrainingCrumbs,
    NotebooksAdminCrumbs,
    NotebooksCrumb,
    RunwaysCrumbs
} from "./breadcrumb_items";
import {isEmpty, setIfMounted} from "src/utils/common_utils";

export interface Crumb {
    crumbLabel: string,
    crumbPath: string
}

export interface BreadCrumbProps {
    namespaceName?: string,
    runwayName?: string,
    jobName?: string,
    dataStudioOnboardingName?: string,
    dataStudioManagedEndpointName?: string
}

export default function BreadCrumb(props: BreadCrumbProps) {
    let currentPath = removeTrailingSlashes(useLocation().pathname);
    let matchedParams = useParams();
    let route = useRouteMatch();
    const [crumbs, setCrumbs] = useState<Crumb[]>([]);

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, [])

    useEffect(() => {
        let flow = getFlowFromURL(currentPath);
        switch (flow) {
            case FLOWS.CONFIG_PANEL:
                let configPanelCrumbs = ConfigPanelCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(configPanelCrumbs) && setIfMounted(_isMounted.current, setCrumbs, configPanelCrumbs);
                return;
            case FLOWS.FEATURE_GENERATION:
                let featureGenerationCrumbs = FeatureGenerationCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(featureGenerationCrumbs) && setIfMounted(_isMounted.current, setCrumbs, featureGenerationCrumbs);
                return;
            case FLOWS.MODEL_TRAINING:
                let modelTrainingCrumbs = ModelTrainingCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(modelTrainingCrumbs) && setIfMounted(_isMounted.current, setCrumbs, modelTrainingCrumbs);
                return;
            case FLOWS.JOB_TEMPLATE:
                let jobTemplateCrumbs = JobTemplateCrumbs(matchedParams, route.path) as Crumb[];
                !isEmpty(jobTemplateCrumbs) && setIfMounted(_isMounted.current, setCrumbs, jobTemplateCrumbs);
                return;
            case FLOWS.NOTEBOOKS:
                let notebooksCrumbs = NotebooksCrumb(props, matchedParams, route.path) as Crumb[];
                !isEmpty(notebooksCrumbs) && setIfMounted(_isMounted.current, setCrumbs, notebooksCrumbs);
                return;
            case FLOWS.RUNWAYS:
                let runwaysCrumbs = RunwaysCrumbs(props, matchedParams, route.path) as Crumb[];
                !isEmpty(runwaysCrumbs) && setIfMounted(_isMounted.current, setCrumbs, runwaysCrumbs);
                return;
            case FLOWS.DATASETS:
                let datasetCrumbs = DatasetCrumbs(props, route.path) as Crumb[];
                !isEmpty(datasetCrumbs) && setIfMounted(_isMounted.current, setCrumbs, datasetCrumbs);
                return;
            case FLOWS.CODE_REPOSITORIES:
                let codeRepoCrumbs = CodeRepositoriesCrumbs(props, route.path) as Crumb[];
                !isEmpty(codeRepoCrumbs) && setIfMounted(_isMounted.current, setCrumbs, codeRepoCrumbs);
                return;
            case FLOWS.NOTEBOOKS_ADMIN:
                let notebooksAdminCrumb = NotebooksAdminCrumbs(props, matchedParams,route.path) as Crumb[];
                !isEmpty(notebooksAdminCrumb) && setIfMounted(_isMounted.current, setCrumbs, notebooksAdminCrumb);
                return;
            default:
                setCrumbs([])
        }
    }, [currentPath, matchedParams])

    if (crumbs && crumbs.length <= 1) {
        return null;
    }

    const showCrumbs = () => {
        return (
            <BreadCrumbGroup>
                <BreadCrumbItemPadding/>
                {
                    crumbs.map((crumb: Crumb, key: number) =>
                        <kat-breadcrumb-item
                            label={crumb.crumbLabel}
                            href={crumb.crumbPath}
                            key={key}
                            style={BreadCrumbItemStyle}
                        />)
                }
            </BreadCrumbGroup>
        )
    }

    return (
        <BreadCrumbBar>
            {(crumbs && crumbs.length > 0) ?
                showCrumbs() :
                <GreenSpinner variant={"default"} size={"small"}/>
            }
        </BreadCrumbBar>
    )


}