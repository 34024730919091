export const getPrettyJsonString = (jsonString: string) => {
    try {
        if (!jsonString)
            return null;
        let json = JSON.parse(jsonString);
        return JSON.stringify(json, null, 2);
    } catch (error) {
        alert("Could not parse JSON" + error);
        return null;
    }
}

export const readJSON = (fileObject: any) => {
    return JSON.parse(JSON.stringify(fileObject)).default;
}

export const constructMap = (jsonArrayObject: any, key: string) => {
    try {
        let jsonMap = new Map();
        jsonArrayObject.forEach((object: any) => {
            jsonMap.set(object[key], object);
        });
        return jsonMap;
    } catch (error) {
        return null;
    }

}

export const isValidJSON = (jsonString: string) => {
    try {
        JSON.parse(jsonString)
        return true;
    } catch (error) {
        return false;
    }
}

// This converts values in a valid json to strings i.e json with depth=1 and values being strings.
export const convertToJsonWithStrings = (jsonString: string) => {
    const withStrings = JSON.parse(jsonString, (key, value) => (
        key && value && typeof value !== "string" ? JSON.stringify(value)  : value)
    );
    return withStrings;
}

export const isJSONFile = (fileName: string) => {
    return fileName.endsWith(".json");
}