import { Failure, Request, Success } from "../../../utils/action_utils";
import { APPROVE_REVIEW_SUCCESS, CREATE_CONFIG_REVIEW_FAILURE, CREATE_CONFIG_REVIEW_SUCCESS, DISCARD_REVIEW_SUCCESS, ERROR_WHILE_APPROVING_REVIEW, ERROR_WHILE_CREATING_CONFIG_REVIEW, ERROR_WHILE_DISCARDING_REVIEW, ERROR_WHILE_FETCHING_REVIEW_DETAILS, ERROR_WHILE_MERGING_REVIEW, ERROR_WHILE_PUBLISHING_REVIEW, ERROR_WHILE_REVOKING_REVIEW, ERROR_WHILE_SAVING_REVIEW, MERGE_REVIEW_SUCCESS, PUBLISH_REVIEW_SUCCESS, REVIEW_ID_NOT_FOUND, REVIEW_DATA_FETCH_FAILURE, REVIEW_DATA_FETCH_SUCCESS, REVIEW_FETCH_START, REVOKE_APPROVAL_SUCCESS, SAVE_REVIEW_SUCCESS } from "../../../control/actions/action_types";

import { ApproverInfo, CommentInfo, ReviewType, DescriptionInfo, RequestStatus } from "../../../model/review/review_types";
import { apiGetPromise, apiPostPromise, apiPutPromise } from "src/utils/api_handler";
import { getCreateConfigReviewEndpoint, getReviewTargetEndpoint } from "src/control/targets";
import { getOverridesMap } from "src/utils/config_panel_action_utils";
import { isEmpty } from "src/utils/common_utils";
import { showSnackBar } from "../commons/snack_bar_actions";


export const createConfigReview = (
    configId: string,
    owner: string,
    changeContent: string,
    config_name: any,
    domain: any,
    realm: any,
    applicationRealm: any,
    service_name: any,
    version: any,
    url: string
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            const allOverrides: any = getOverridesMap();
            const filteredOverrides = allOverrides.filter((override: any) => {
                return (
                    override.serviceName == service_name
                    && override.domain == domain
                    && override.realm == realm
                )
            })
            let overrides = ""

            if (!isEmpty(filteredOverrides) && filteredOverrides.length == 1) {
                overrides = filteredOverrides[0].overrides
            }
            if (domain.toLowerCase() == "onepod")
                domain = "Prod"
            else if (domain.toLowerCase() == "prod replay")
                domain = "Alpha"
            domain = domain.toLowerCase()
            let sourceInfo = JSON.stringify({
                config_name: config_name,
                domain: domain,
                realm: realm,
                applicationRealm: applicationRealm,
                configId: configId,
                service_name: service_name,
                version: version,
                overrides: overrides,
                url: url
            })
            let reviewType = ReviewType.CONFIG_REVIEW
            let response: any = await apiPostPromise(getCreateConfigReviewEndpoint(), {
                changeContent,
                sourceInfo,
                reviewType,
                owner
            });
            if (!isEmpty(response) && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase() && !isEmpty(response.data) && response.data.reviewId) {
                dispatch(Success(CREATE_CONFIG_REVIEW_SUCCESS, {}))
                resolve(response.data.reviewId)
                return;
            }
            else {
                const error = ERROR_WHILE_CREATING_CONFIG_REVIEW;
                dispatch(Failure(CREATE_CONFIG_REVIEW_FAILURE, error));
                reject(error)
            }
        }
        catch (err) {
            const error = ERROR_WHILE_CREATING_CONFIG_REVIEW;
            dispatch(Failure(CREATE_CONFIG_REVIEW_FAILURE, error));
            dispatch(showSnackBar("Some error occurred while generating review. Please try again later"))
            reject(err)
        }
    });
}

export const getReview = (
    reviewId: string
) => async (dispatch: any) => {
    dispatch(Request(REVIEW_FETCH_START));
    return new Promise(async (resolve, reject) => {
        try {
            let response: any = await apiGetPromise(getReviewTargetEndpoint(reviewId), {});
            if (response && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(REVIEW_DATA_FETCH_SUCCESS, response))
                resolve();
                return;
            }
            else {
                const error = REVIEW_ID_NOT_FOUND
                dispatch(Failure(REVIEW_DATA_FETCH_FAILURE, error))
                reject(error)
            }
        }
        catch (err) {
            const error = ERROR_WHILE_FETCHING_REVIEW_DETAILS;
            dispatch(Failure(REVIEW_DATA_FETCH_FAILURE, error));
            reject(err);
        }
    });
}

export const publishReview = (
    reviewId: string,
    requestedBy: string,
    approverInfo: ApproverInfo[],
    commentInfo: CommentInfo[],
    descriptionInfo: DescriptionInfo
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let operationType = "PUBLISH"
            let updateReviewPayload = {
                approverInfo,
                commentInfo,
                descriptionInfo
            }
            let response: any = await apiPutPromise(getReviewTargetEndpoint(reviewId), {
                operationType,
                requestedBy,
                updateReviewPayload
            })
            if (response && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(PUBLISH_REVIEW_SUCCESS, updateReviewPayload))
                dispatch(showSnackBar("The review has been published!"))
                resolve();
                return;
            }
            else {
                const error = ERROR_WHILE_PUBLISHING_REVIEW
                dispatch(showSnackBar("Some error occured while publishing. Please try again later"))
                reject(error)
            }
        }
        catch (err) {
            dispatch(showSnackBar("Some error occured while publishing. Please try again later"))
            reject(err);
        }
    })
}

export const saveReview = (
    reviewId: string,
    requestedBy: string,
    approverInfo: ApproverInfo[],
    commentInfo: CommentInfo[]
) => async (dispatch: any): Promise<any> => {
    return new Promise(async (resolve, reject) => {
        try {
            let operationType = "SAVE"
            let updateReviewPayload = {
                approverInfo,
                commentInfo
            }
            let response: any = await apiPutPromise(getReviewTargetEndpoint(reviewId), {
                operationType,
                requestedBy,
                updateReviewPayload
            })
            if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(SAVE_REVIEW_SUCCESS, {
                    approverInfo: approverInfo,
                    commentInfo: commentInfo
                }))
                dispatch(showSnackBar("Changes have been saved!"));
                resolve()
                return;
            }
            else {
                if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.FAILURE.toLowerCase()) {
                    const error = ERROR_WHILE_SAVING_REVIEW
                    dispatch(showSnackBar(response.message))
                    reject(error)
                } else {
                    const error = ERROR_WHILE_SAVING_REVIEW
                    dispatch(showSnackBar("Some error occured while saving the changes. Please try again later"))
                    reject(error)
                }
            }
        }
        catch (err) {
            dispatch(showSnackBar("Some error occured while saving the changes. Please try again later"))
            reject(err);
        }
    })
}

export const mergeReview = (
    reviewId: string,
    requestedBy: string,
    MCMorSev2Link: string = ""
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let stringComment = JSON.stringify({
                comment: reviewId,
                mcmOrSev2: MCMorSev2Link,
                updatedBy: requestedBy
            })
            let response: any = await apiPostPromise(getReviewTargetEndpoint(reviewId), {
                comment: stringComment,
                requestedBy
            })
            if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(MERGE_REVIEW_SUCCESS, {}))
                dispatch(showSnackBar("Merge operation is successful"))
                resolve();
                return;
            }
            else {
                if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.FAILURE.toLowerCase()) {
                    const error = ERROR_WHILE_MERGING_REVIEW
                    dispatch(showSnackBar(response.message))
                    reject(error)
                }
                else {
                    const error = ERROR_WHILE_MERGING_REVIEW
                    dispatch(showSnackBar("Merge operation failed. Please try after some time"))
                    reject(error)
                }
            }
        }
        catch (err) {
            dispatch(showSnackBar("Merge operation failed. Please try after some time"))
            reject(err);
        }
    })
}

export const discardReview = (
    reviewId: string,
    requestedBy: string
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let operationType = "DISCARD"
            let response: any = await apiPutPromise(getReviewTargetEndpoint(reviewId), { operationType,requestedBy })
            if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(DISCARD_REVIEW_SUCCESS, {}))
                dispatch(showSnackBar("The review has been cancelled!"))
                resolve();
                return;
            }
            else {
                if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.FAILURE.toLowerCase()) {
                    const error = ERROR_WHILE_DISCARDING_REVIEW
                    dispatch(showSnackBar(response.message))
                    reject(error)
                } else {
                    const error = ERROR_WHILE_DISCARDING_REVIEW
                    dispatch(showSnackBar("Some error occured while discarding. Please try again later"))
                    reject(error)
                }
            }
        }
        catch (err) {
            dispatch(showSnackBar("Some error occured while discarding. Please try again later"))
            reject(err);
        }
    })
}


export const approveReview = (
    reviewId: string,
    requestedBy: string
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let operationType = 'APPROVE'
            let response: any = await apiPutPromise(getReviewTargetEndpoint(reviewId), {
                operationType,
                requestedBy
            })
            if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(APPROVE_REVIEW_SUCCESS, requestedBy))
                dispatch(showSnackBar("The review has been approved!"));
                resolve();
                return;
            }
            else {
                if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.FAILURE.toLowerCase()) {
                    const error = ERROR_WHILE_APPROVING_REVIEW
                    dispatch(showSnackBar(response.message))
                    reject(error)
                } else {
                    const error = ERROR_WHILE_APPROVING_REVIEW
                    dispatch(showSnackBar("Some error occured while approving. Please try again later"))
                    reject(error)
                }
            }
        }
        catch (err) {
            dispatch(showSnackBar("Some error occured while approving. Please try again later"))
            reject(err);
        }
    })
}

export const revokeApproval = (
    reviewId: string,
    requestedBy: string
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let operationType = 'REVOKE'
            let response: any = await apiPutPromise(getReviewTargetEndpoint(reviewId), {
                operationType,
                requestedBy
            })
            if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.SUCCESS.toLowerCase()) {
                dispatch(Success(REVOKE_APPROVAL_SUCCESS, requestedBy))
                dispatch(showSnackBar("The review has been revoked!"))
                resolve()
                return;
            }
            else {
                if (response && response.requestStatus && response.requestStatus.toLowerCase() === RequestStatus.FAILURE.toLowerCase()) {
                    const error = ERROR_WHILE_REVOKING_REVIEW
                    dispatch(showSnackBar(response.message))
                    reject(error)
                } else {
                    const error = ERROR_WHILE_REVOKING_REVIEW
                    dispatch(showSnackBar("Some error occured while revoking. Please try again later"))
                    reject(error)
                }
            }
        }
        catch (err) {
            dispatch(showSnackBar("Some error occured while revoking. Please try again later"))
            reject(err);
        }
    })
}
