export interface Configuration {
    "configId": string,
    "teamId": string ,
    "namespaceId": string,
    "serviceName": string,
    "realm": string,
    "domain": string,
    "name": string,
    "description": string,
    "version"?: string,
    "jsonConfig"?: string
}

//Service supports loading maximum of 20 versions
export const MAX_CONFIG_VERSION_BUFFER_SIZE = 20

//postStatus
export enum PostStatus {
    NONE = "NONE",
    ERROR = "ERROR",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS"
}

export enum Domains {
    PROD_REPLAY = "PROD REPLAY", //Alpha is PROD_REPLAY
    BETA = "BETA",
    GAMMA = "GAMMA",
    ONEPOD = "ONEPOD",
    PROD = "PROD"
}

export const UPDATE_CONFIG_VALIDATOR_SUCCESS_MESSAGE = "Successfully updated config validator"
export const UPDATE_CONFIG_VALIDATOR_FAILURE_MESSAGE = "Config validator update failed"
