import {
    KatBadge,
    KatButton,
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow
} from "@amzn/katal-react";
import {useParams} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import React, {useEffect, useRef, useState} from "react";
import {ErrorContainer, ErrorHeading} from "src/view/style/modelTraining/errors"
import {useDispatch, useSelector} from "react-redux";
import {filterList} from "src/utils/list_utils";
import {AddonIcon, InfoIcon, LaunchIcon, ViewIconTable} from "src/view/style/icons";
import {FilterInput, PaddedSpan, TableHeader} from "src/view/style/table_styles";
import {getDataStudioOnboarding, listDataStudioOnboardings} from "src/control/actions/notebooks/data_studio_actions";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {getParamFromQueryString, isAdminWindow, setParamToQueryString} from "src/utils/url_utils";
import {
    DataStudioOnboarding,
    DataStudioOnboardingStatus,
    DataStudioResourceMap
} from "src/model/notebooks/data_studio_models";
import {useHistory} from "react-router";
import {ClickablePaddedSpan, PopUp} from "src/view/style/common_styles";
import {
    dataStudioOnboardingSelector,
    dataStudioOnboardingsListSelector
} from "src/control/selectors/notebooks/data_studio_selectors";
import {Status} from "src/constant";
import {fetchTeam} from "src/control/actions/commons/team_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {selectedTeamSelector} from "src/control/selectors/commons/team_selectors";
import {DATA_STUDIO_ONBOARDING_SIM} from "src/external_links";


export default function ListDataStudioOnBoardings() {
    const dispatch = useDispatch();
    const params: any = useParams();
    const history = useHistory();
    const isAdminView = isAdminWindow();

    const [isInfoPopUpVisible, setIsInfoPopUpVisible] = useState(false);
    const [popUpOnboardingId, setPopUpOnboardingId] = useState("");
    const [dataStudioOnboardingPopUpVisibility, setDataStudioOnboardingPopUpVisibility] = useState(false);
    const [filter, setFilter] = useState("");

    let popDataStudioOnboarding = useSelector(dataStudioOnboardingSelector);
    let fetchedNamespace = useSelector(namespaceSelector);
    let dataStudioOnboardings = useSelector(dataStudioOnboardingsListSelector);
    let selectedTeam = useSelector(selectedTeamSelector);
    let filterFromURL = getParamFromQueryString(history.location.search, "filter");

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
        dispatch(fetchTeam(params.teamId, null, null));
        dispatch(listDataStudioOnboardings());
        !isAdminView && dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        popUpOnboardingId && dispatch(getDataStudioOnboarding(popUpOnboardingId))
    }, [popUpOnboardingId]);

    useEffect(() => {
        if (!isEmpty(filterFromURL)) {
            setIfMounted(_isMounted.current, setFilter, filterFromURL)
        } else {
            setIfMounted(_isMounted.current, setFilter, "")
        }
    }, [filterFromURL])

    const handleFilterChange = (filterString: any) => {
        if (isEmpty(filterString)) {
            setParamToQueryString("filter", null);
            return;
        }
        setParamToQueryString("filter", filterString);
    }

    const getDataStudioStatusBadgeType = (dataStudioStatus: DataStudioOnboardingStatus) => {
        switch (dataStudioStatus) {
            case DataStudioOnboardingStatus.ACTIVE :
                return "success";
            default:
                return "tag";
        }
    }

    const handleDataStudioLaunch = (dataStudioResources: DataStudioResourceMap) => {
        window.open(dataStudioResources.studioUrl, "_blank")
    }

    const handleRedirection = (path: string) => {
        history.push(`${window.location.pathname}/${path}`)
    }

    const getResourceMapRows = () => {
        return (
            <KatTable>
                <KatTableHead>
                    <KatTableRow>
                        <KatTableCell>Key</KatTableCell>
                        <KatTableCell>Value</KatTableCell>
                    </KatTableRow>
                </KatTableHead>
                <KatTableBody>
                    {
                        Object.keys(popDataStudioOnboarding.value.resources).map((key) => {
                            return (<KatTableRow key={key}>
                                <KatTableCell>{key}</KatTableCell>
                                <KatTableCell>{popDataStudioOnboarding.value.resources[key]}</KatTableCell>
                            </KatTableRow>)
                        })
                    }
                </KatTableBody>
            </KatTable>
        );
    }

    const getResourceMapInfoPopUp = () => {
        return (
            <PopUp
                visible={isInfoPopUpVisible}
                onOpen={() => {
                    setIfMounted(_isMounted.current, setIsInfoPopUpVisible, true);
                }}
                onClose={() => {
                    setIfMounted(_isMounted.current, setIsInfoPopUpVisible, false);
                }}
            >
                <span slot={"title"}>DataStudio Resources</span>
                {
                    popDataStudioOnboarding.status == Status.LOADING ? <KatSpinner/> :
                        popDataStudioOnboarding.status == Status.ERROR ?
                            <ErrorContainer><ErrorHeading>{popDataStudioOnboarding.error}</ErrorHeading></ErrorContainer> : getResourceMapRows()
                }
                <div slot="footer" className="kat-group-horizontal">
                    <KatButton
                        label="Cancel"
                        size="base"
                        variant="primary"
                        onClick={() => {
                            setIfMounted(_isMounted.current, setIsInfoPopUpVisible, false);
                        }}
                    />
                </div>
            </PopUp>
        )
    }

    const getRow = (dataStudioOnboarding: DataStudioOnboarding) => {
        return (
            <KatTableRow key={dataStudioOnboarding.onboardingId}>
                <KatTableCell>{dataStudioOnboarding.name}</KatTableCell>
                <KatTableCell>{dataStudioOnboarding.accountId}</KatTableCell>
                {isAdminView && <KatTableCell>{dataStudioOnboarding.teamIdentifier}</KatTableCell>}
                <KatTableCell>{dataStudioOnboarding.region}</KatTableCell>
                <KatTableCell>{dataStudioOnboarding.createdBy}</KatTableCell>
                <KatTableCell>{dataStudioOnboarding.createdTimestamp}</KatTableCell>
                <KatTableCell><KatBadge label={dataStudioOnboarding.status}
                                        type={getDataStudioStatusBadgeType(dataStudioOnboarding.status)}
                /></KatTableCell>
                <KatTableCell>
                    <ReactTooltip place="bottom" type="dark"/>
                    <ClickablePaddedSpan data-tip="View Onboarding">
                        <ViewIconTable style={{
                            margin: "10px",
                            width: "20px",
                            color: "#002e36",
                        }} onClick={() => {
                            history.push(`${window.location.pathname}/${dataStudioOnboarding.onboardingId}/view`)
                        }}/>
                    </ClickablePaddedSpan>
                    <ClickablePaddedSpan data-tip="Launch DataStudio">
                        <LaunchIcon style={{
                            margin: "10px",
                            width: "20px",
                            color: "#002e36"
                        }} onClick={() => {
                            handleDataStudioLaunch(dataStudioOnboarding.resources)
                        }}/>
                    </ClickablePaddedSpan>
                    <ClickablePaddedSpan data-tip="Show Addons">
                        <AddonIcon style={{
                            margin: "10px",
                            width: "20px",
                            color: "#002e36"
                        }} onClick={
                            () => handleRedirection(`${dataStudioOnboarding.onboardingId}/addons`)
                        }/>
                    </ClickablePaddedSpan>
                    <ClickablePaddedSpan data-tip="View Resources">
                        <InfoIcon style={{
                            margin: "10px",
                            width: "20px",
                            color: "#002e36"
                        }} onClick={
                            () => {
                                setIfMounted(_isMounted.current, setPopUpOnboardingId, dataStudioOnboarding.onboardingId)
                                setIfMounted(_isMounted.current, setIsInfoPopUpVisible, true)
                            }
                        }/>
                    </ClickablePaddedSpan>
                </KatTableCell>
            </KatTableRow>
        )
    }

    const getOnboardingList = () => {
        let displayList = dataStudioOnboardings.value;
        if (!isAdminView) {
            displayList = filterList(displayList, "accountId", fetchedNamespace.namespaceData.accountTypeMap.experimentation);
            displayList = filterList(displayList, "teamIdentifier", selectedTeam.teamName);
            isEmpty(displayList) && setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, true)
        } else {
            let searchFilters = filter.split(/\s+/i)
            searchFilters
                .filter(value => !isEmpty(value))
                .forEach((value) => {
                    displayList = displayList.filter((onboarding: DataStudioOnboarding) => {
                        let searchValues =
                            `${onboarding.accountId} ${onboarding.teamIdentifier}  ${onboarding.name} ${onboarding.region} ${onboarding.status}`.toUpperCase()
                        return searchValues.includes(value.toUpperCase())
                    })
                })
        }
        return displayList.sort((onboarding1: DataStudioOnboarding, onboarding2: DataStudioOnboarding) => {
            return (onboarding1.teamIdentifier > onboarding2.teamIdentifier ? 1 :
                (onboarding1.teamIdentifier < onboarding2.teamIdentifier ? -1 :
                    (onboarding1.createdTimestamp > onboarding2.createdTimestamp ? 1 :
                        (onboarding1.createdTimestamp < onboarding2.createdTimestamp ? -1 : 0))));
        });
    }

    const getRows = () => {
        return (<>
            {getOnboardingList().map(getRow)}
        </>)
    }

    const getOnboardings = () => {
        return (
            <>{
                        dataStudioOnboardings.value.length == 0 ?
                            <ErrorContainer>
                                <ErrorHeading>No Results Found</ErrorHeading>
                            </ErrorContainer>
                            :
                            <KatTable>
                                <KatTableHead>
                                    <KatTableRow>
                                        <KatTableCell>Name</KatTableCell>
                                        <KatTableCell>Account Id</KatTableCell>
                                        {isAdminView && <KatTableCell>Team Identifier</KatTableCell>}
                                        <KatTableCell>Region</KatTableCell>
                                        <KatTableCell>Created By</KatTableCell>
                                        <KatTableCell>Creation Date</KatTableCell>
                                        <KatTableCell>Status</KatTableCell>
                                        <KatTableCell>Actions</KatTableCell>
                                    </KatTableRow>
                                </KatTableHead>
                                <KatTableBody>
                                    {getRows()}
                                </KatTableBody>
                            </KatTable>
            }</>
        )
    }

    const getDataStudioOnboardingPopUp = () => {
        return (
            <>
                <PopUp
                    visible={dataStudioOnboardingPopUpVisibility}
                    onOpen={() => {
                        setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, true);
                    }}
                    onClose={() => {
                        setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, false);
                        history.push(window.location.pathname.replace("/dataStudio/onboardings", ""))
                    }}
                >
                    <span slot={"title"}>Do you want to onboard to Data Studio?</span>
                    <p>Account linked to this namespace is not onboarded to Data Studio. If you want to get started with Data
                        Studio, click on "Create SIM" below.</p>
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton
                            label="Cancel"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                setIfMounted(_isMounted.current, setDataStudioOnboardingPopUpVisibility, false);
                                history.push(window.location.pathname.replace("/dataStudio/onboardings", ""))
                            }}
                        />
                        <KatButton
                            label="Create SIM"
                            size="base"
                            variant="primary"
                            onClick={() => {
                                window.open(DATA_STUDIO_ONBOARDING_SIM, "_blank")
                            }}
                        />
                    </div>
                </PopUp>
            </>
        );
    }

    return (
        <>
            {getDataStudioOnboardingPopUp()}
            {getResourceMapInfoPopUp()}
            {
                (!isAdminView && isEmpty(fetchedNamespace.namespaceData.namespace)) || dataStudioOnboardings.status === Status.LOADING ?
                    <KatSpinner/> :
                    dataStudioOnboardings.status === Status.ERROR ? <ErrorContainer>
                            <ErrorHeading>{dataStudioOnboardings.error}</ErrorHeading>
                        </ErrorContainer> :
                        <>
                            <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}/>
                            <TableHeader>
                                DataStudio Onboardings
                            </TableHeader>
                            {
                                isAdminView &&
                                <PaddedSpan>
                                    <FilterInput
                                        placeholder={"Search here to filter by name, accountId, region, team or status..."}
                                        value={filter}
                                        onKeyup={(e: any) => {
                                            handleFilterChange(e.target.value)
                                        }}
                                    />

                                </PaddedSpan>
                            }
                            {getOnboardings()}
                        </>
            }
        </>
    )

}
