import styled from "styled-components";
import { KatButton, KatModal, KatPopover, KatStatusindicator } from "@amzn/katal-react";

export const CreateRunwayButton = styled(KatButton)``;
export const UpdateRunwayButton = styled(KatButton)``;
export const UpdateButton = styled(UpdateRunwayButton)`
  float: right;
`;
export const CloneRunwayButton = styled(KatButton)``;
export const CloneButton = styled(CloneRunwayButton)`
  margin-right: 10px;
`;
export const ClonePopover = styled(KatPopover)`
  float: right;
  margin-right: 10px;
`;
export const CreateButton = styled(UpdateButton)``;
export const CreateJobTemplateButton = styled(KatButton)``;

export const JobPopUp = styled(KatModal)``;

export const ViewJobExecutionsButton = styled(KatButton)`
  float: right;
`;
export const ViewJobExecutions = styled(KatButton)`
  margin: 10px;
`;
export const ViewLogsMetrics = styled(KatButton)`
  margin: 10px;
`;
export const InputTag = styled.input`
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  margin-right: 20px;
  padding: 6px;
`;
export const AddTagButton = styled(KatButton)`
  float: right;
  width: 100px;
  --background: #002e36;
  --hover-ring-color: #002e36;
  --background-hover: #002e36;
  --font-size: 0.875rem;
  color: white;
  border-radius: 4px;
`;
export const TagBox = styled.div`
  display: inline-block;
  margin-top: 1%;
  box-shadow: rgb(0 28 36 / 50%) 0px 1px 4px 0px;
  margin-right: 10px;
  padding: 5px;
`;
export const OperationIconBox = styled.div`
  height: 15px;
  margin: 3px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
export const RunwayStatusIndicator = styled(KatStatusindicator)`
  --label-color: #000;
`