import { Failure, Success } from "../../../utils/action_utils";
import {
  apiGetPromise,
  apiPostPromise,
  apiPutPromise,
} from "../../../utils/api_handler";
import {
  getListV2RunwaysEndpoint,
  getUpdateV2RunwayEndpoint,
  getViewV2RunwayEndpoint,
  getCreateV2RunwayEndpoint,
  getUpdateV2RunwayStatusEndpoint,
  getListJobsInstancesEndpointV2,
} from "../../../control/targets";
import {
  CREATE_V2_RUNWAY_FAILURE,
  CREATE_V2_RUNWAY_SUCCESS,
  ERROR_CREATING_V2_RUNWAY,
  ERROR_FETCHING_V2_RUNWAY,
  ERROR_LISTING_V2_RUNWAYS,
  ERROR_LOADING_V2_RUNWAYS_EXECUTIONS,
  ERROR_UPDATING_V2_RUNWAY,
  ERROR_UPDATING_V2_RUNWAY_STATUS,
  FETCH_RUNWAY_SUCCESS,
  FETCH_V2_RUNWAY_FAILURE,
  FETCH_V2_RUNWAY_SUCCESS,
  LIST_V2_RUNWAYS_FAILURE,
  LIST_V2_RUNWAYS_SUCCESS,
  LOAD_V2_RUNWAYS_EXECUTION_METRICS_SUCCESS,
  LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE,
  LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS,
  START_LOAD_V2_RUNWAYS_EXECUTION_METRICS,
  START_LOAD_V2_RUNWAYS_EXECUTIONS,
  START_V2_RUNWAY_LOADER,
  UPDATE_V2_RUNWAY_FAILURE,
  UPDATE_V2_RUNWAY_STATUS_FAILURE,
  UPDATE_V2_RUNWAY_STATUS_SUCCESS,
  UPDATE_V2_RUNWAY_SUCCESS,
  FETCH_RUNWAY_LATEST_VERSION_DATA_SUCCESS,
} from "../../../control/actions/action_types";
import { RequestStatus } from "../../../utils/model_utils";
import { isEmpty } from "../../../utils/common_utils";
import { showSnackBar } from "../commons/snack_bar_actions";
import { Job } from "src/model/runways/job_model";

export const createV2Runway =
  (
    runwayName: string,
    namespaceId: string,
    requestedBy: string,
    domain: string,
    runwayDAG: Job[],
    runwayId: string | null
  ) =>
  async (dispatch: any) => {
    dispatch({ type: START_V2_RUNWAY_LOADER });
    return new Promise(async (resolve, reject) => {
      try {
        const error = ERROR_CREATING_V2_RUNWAY;
        let newRunwayDag = runwayDAG.map((dagElement: any) => {
          const element = {...dagElement}
          delete element.prevLiveVersion
          return element;
        });
        let createRunwayPayload: any = {
          runwayName: runwayName,
          namespaceId: namespaceId,
          requestedBy: requestedBy,
          domain: domain,
          runwayDAG: newRunwayDag,
          runwayId: runwayId,
        };
        let response: any = await apiPostPromise(
          getCreateV2RunwayEndpoint(),
          {
            ...createRunwayPayload,
            runwayRelease: 2,
          },
          2
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase() &&
          !isEmpty(response.runwayId)
        ) {
          createRunwayPayload.runwayId = response.runwayId;
          dispatch(Success(CREATE_V2_RUNWAY_SUCCESS, createRunwayPayload));
          resolve(response.runwayId);
        } else {
          dispatch(Failure(CREATE_V2_RUNWAY_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(CREATE_V2_RUNWAY_FAILURE, err));
        reject(err);
      }
    });
  };

export const getV2Runway =
  (runwayId: string, version?: number | null, latestVersions?: number | null) =>
  async (dispatch: any) => {
    dispatch({ type: START_V2_RUNWAY_LOADER });
    return new Promise<any>(async (resolve, reject) => {
      try {
        const error = ERROR_FETCHING_V2_RUNWAY;
        let response: any = await apiGetPromise(
          getViewV2RunwayEndpoint(runwayId),
          {
            version: isEmpty(version) ? null : version,
            latestVersions: isEmpty(latestVersions) ? null : latestVersions,
          }
        );

        if (!isEmpty(response) && !isEmpty(response.runway)) {
          if (
            !isEmpty(response.runway.runwayRelease) &&
            response.runway.runwayRelease === 2
          ) {
            dispatch(Success(FETCH_V2_RUNWAY_SUCCESS, response));
            resolve(response.runway);
          } else {
            // TODO: Remove once old experience is deprecated
            dispatch(Success(FETCH_RUNWAY_SUCCESS, response.runway));
            resolve(response.runway);
          }
        } else {
          dispatch(Failure(FETCH_V2_RUNWAY_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(FETCH_V2_RUNWAY_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const updateV2Runway =
  (
    runwayId: string,
    namespaceId: string,
    requestedBy: string,
    runwayDAG: Job[]
  ) =>
  async (dispatch: any) => {
    dispatch({ type: START_V2_RUNWAY_LOADER });
    return new Promise<void>(async (resolve, reject) => {
      try {
        const error = ERROR_UPDATING_V2_RUNWAY;
        let newRunwayDag = runwayDAG.map((dagElement: any) => {
          const element = {...dagElement}
          delete element.prevLiveVersion
          return element;
        });
        let updateRunwayPayload = {
          namespaceId: namespaceId,
          requestedBy: requestedBy,
          runwayDAG: newRunwayDag,
        };
        let response: any = await apiPutPromise(
          getUpdateV2RunwayEndpoint(runwayId),
          updateRunwayPayload,
          2
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase()
        ) {
          dispatch(Success(UPDATE_V2_RUNWAY_SUCCESS, updateRunwayPayload));
          resolve();
        } else {
          dispatch(Failure(UPDATE_V2_RUNWAY_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(UPDATE_V2_RUNWAY_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const listV2Runways = (namespaceId: string) => async (dispatch: any) => {
  dispatch({ type: START_V2_RUNWAY_LOADER });
  return new Promise<void>(async (resolve, reject) => {
    try {
      const error = ERROR_LISTING_V2_RUNWAYS;
      let response: any = await apiGetPromise(getListV2RunwaysEndpoint(), {
        namespaceId: namespaceId,
      });
      if (!isEmpty(response)) {
        dispatch(Success(LIST_V2_RUNWAYS_SUCCESS, response.runways));
        resolve();
      } else {
        dispatch(Failure(LIST_V2_RUNWAYS_FAILURE, error));
        dispatch(showSnackBar("Some error occurred! Please try again later"));
        reject(error);
      }
    } catch (err: any) {
      dispatch(Failure(LIST_V2_RUNWAYS_FAILURE, err));
      dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
      reject(err);
    }
  });
};

export const updateV2RunwayStatus =
  (runwayId: any, requestedBy: string, status: string) =>
  async (dispatch: any) => {
    dispatch({ type: START_V2_RUNWAY_LOADER });
    return new Promise<void>(async (resolve, reject) => {
      try {
        const error = ERROR_UPDATING_V2_RUNWAY_STATUS;
        let response: any = await apiPutPromise(
          getUpdateV2RunwayStatusEndpoint(runwayId, status),
          { requestedBy }
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase()
        ) {
          dispatch(
            Success(UPDATE_V2_RUNWAY_STATUS_SUCCESS, {
              runwayId: runwayId,
              requestedBy: requestedBy,
            })
          );
          dispatch(showSnackBar("The runway status has been updated!"));
          resolve();
        } else {
          dispatch(Failure(UPDATE_V2_RUNWAY_STATUS_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(UPDATE_V2_RUNWAY_STATUS_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const loadV2RunwayExecutions =
  (
    runwayId: string,
    refreshExecutions: boolean,
    timeWindowStart?: string,
    timeWindowEnd?: string
  ) =>
  async (dispatch: any) => {
    if (refreshExecutions) {
      dispatch({ type: START_LOAD_V2_RUNWAYS_EXECUTIONS });
    }
    return new Promise<void>(async (resolve, reject) => {
      const error = ERROR_LOADING_V2_RUNWAYS_EXECUTIONS;
      try {
        let data: any = await apiGetPromise(
          getListJobsInstancesEndpointV2(runwayId),
          {
            timeWindowStart: timeWindowStart,
            timeWindowEnd: timeWindowEnd,
          }
        );
        if (!isEmpty(data)) {
          dispatch(
            Success(
              LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS,
              data.jobInstanceList
            )
          );
          resolve();
          return;
        }
        dispatch(Failure(LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE, error));
        reject(error);
      } catch (err) {
        dispatch(Failure(LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_FAILURE, error));
        reject(error);
      }
    });
  };

// TODO: Replace data with real API call and add try catch after adding the real API call.
export const loadRunwayInstanceExecutionMetrics =
  (runwayId: string, executionId: string) => async (dispatch: any) => {
    dispatch({ type: START_LOAD_V2_RUNWAYS_EXECUTION_METRICS });
    return new Promise<void>(async (resolve, reject) => {
      const error = ERROR_LOADING_V2_RUNWAYS_EXECUTIONS;
      let data: any = {
        auc: "0.2",
      };
      if (!isEmpty(data)) {
        dispatch(Success(LOAD_V2_RUNWAYS_EXECUTION_METRICS_SUCCESS, data));
        resolve();
        return;
      }
    });
  };

export const getRunwayLatestVersionData =
  (runwayId: string) => async (dispatch: any) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const error = ERROR_FETCHING_V2_RUNWAY;
        let response: any = await apiGetPromise(
          getViewV2RunwayEndpoint(runwayId),
          {}
        );
        if (!isEmpty(response) && !isEmpty(response.runway)) {
          dispatch(Success(FETCH_RUNWAY_LATEST_VERSION_DATA_SUCCESS, response));
          resolve(response.runway);
        } else {
          dispatch(Failure(FETCH_V2_RUNWAY_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(FETCH_V2_RUNWAY_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };
