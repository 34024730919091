import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { showSnackBar } from "src/control/actions/commons/snack_bar_actions";
import { getReview, mergeReview } from "src/control/actions/review/review_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { APIRequestStatusSelector, requestMessageSelector, reviewDataSelector } from "src/control/selectors/review/review_selectors";
import { APIRequestStatus, ApproverInfo, ReviewStatus, ReviewType } from "src/model/review/review_types";
import { isEmpty, isValidMcmOrSev2Link } from "src/utils/common_utils";
import { GreenSpinner } from "../style/header_styles";
import { BoundaryBox, MCMorSEV2Area, MergeButton, MergeRequirementsBox, MergeSummaryBox, ReviewDetailsBox, SourceDetailsBox } from "../style/review_styles";
import { GenericAlert, PopUp } from "../style/view_config_styles";

export default function Merge() {
    const history = useHistory();
    const dispatch = useDispatch();
    let APIStatus = useSelector(APIRequestStatusSelector);
    let requestMessage = useSelector(requestMessageSelector)
    let reviewData = useSelector(reviewDataSelector);
    let userName = useSelector(usernameSelector);
    const [mcmOrSev2, setMcmOrSev2] = useState("");
    const [isMCMOrSev2LinkValid, setIsMCMOrSev2LinkValid] = useState(false);
    const [popUpVisible, setPopUpVisible] = useState(false);
    const [showPopUpSpinner, setShowPopUpSpinner] = useState(false)
    const [basicDescriptionJSON, setBasicDescriptionJSON] = useState<any>({})
    let url: any = window.location.href;
    let temp = url.split('/')
    url = new URL(url);
    let reviewId = url.searchParams.get("reviewId")
    let reviewURL = temp[0] + '//' + temp[2] + '/review/' + reviewId
    if (isEmpty(reviewId)) {
        history.push("/notFound")
    }
    useEffect(() => {
        if (userName) {
            dispatch(getReview(reviewId))
        }
    }, [userName])
    useEffect(() => {
        if (APIStatus == APIRequestStatus.ERROR) {
            history.push("/notFound")
        }
        if(!isEmpty(reviewData.descriptionInfo.basicDescription)){
            setBasicDescriptionJSON(JSON.parse(reviewData.descriptionInfo.basicDescription))
        }
    }, [reviewData, APIStatus])

    if (APIStatus === APIRequestStatus.LOADING || !userName) {
        return (
            <GreenSpinner variant={"default"} size={"large"} />
        );
    }
    const ApprovedUserList = () => {
        let approversNames: string[] = [];
        let reviewerList: ApproverInfo[] = reviewData.approverInfo;
        reviewerList.forEach(approver => {
            if (approver.isApproved) {
                approversNames.push(approver.alias);
            }
        })
        if (approversNames.length) {
            return (
                <span style={{ display: "inline-block" }}>Approved by: {approversNames.join(", ")}</span>
            )
        }
        else return (
            <span style={{ display: "inline-block" }}>Approved by: None</span>
        )
    }
    const handleMergeStatus = () => {
        if (reviewData.status === ReviewStatus.MERGED) {
            return (
                <span style={{ color: "green", float: "right" }}>MERGED</span>
            )
        }
        else {
            if (reviewData.status === ReviewStatus.CANCELLED) {
                return (
                    <span style={{ color: "blue", float: "right" }}>CANCELLED</span>
                )
            } else {
                return (
                    <span style={{ color: "red", float: "right" }}>PENDING</span>
                )
            }
        }
    }

    const showPopUp = () => {
        setPopUpVisible(true);
    }

    const closePopUp = () => {
        setPopUpVisible(false);
    }

    const handleContinue = async () => {
        setShowPopUpSpinner(true)
        try {
            if (reviewData.type === ReviewType.CONFIG_REVIEW) {
                await dispatch(mergeReview(reviewId, userName, mcmOrSev2))
                const url = window.location.href.replace(/\/merge(.*)/g, "/view")
                window.location.href = url
            }
            else if (reviewData.type === ReviewType.CODE_REVIEW) {
                await dispatch(mergeReview(reviewId, userName))
            }

        } catch (err) {
            console.log(err)
        }
        setShowPopUpSpinner(false)
        closePopUp();
    }

    if (popUpVisible) {
        return (
            <PopUp visible={popUpVisible} onOpen={showPopUp} onClose={closePopUp}>
                {showPopUpSpinner ? <span slot={"title"} >Processing</span> : <span slot={"title"} >Do you wish to continue?</span>}
                {showPopUpSpinner && <GreenSpinner variant={"default"} size={"large"} />}
                {!showPopUpSpinner && <Button style={{ float: "right", background: "#002e36", color: "white", marginTop: "10px" }} onClick={closePopUp}>CANCEL</Button>}
                {!showPopUpSpinner && <Button style={{ float: "right", marginRight: "10px", background: "#002e36", color: "white", marginTop: "10px" }} onClick={() => {
                    handleContinue();
                }}> Continue </Button>}
            </PopUp>
        )
    }


    const handleMerge = () => {
        if (reviewData.type === ReviewType.CONFIG_REVIEW) {
            if (isMCMOrSev2LinkValid) showPopUp()
            else dispatch(showSnackBar("Provided MCM/Sev2 link invalid, please provide the right link"))
        }
        else if (reviewData.type === ReviewType.CODE_REVIEW) {
            showPopUp()
        }


    }
    return (
        <>
            {reviewData.status === ReviewStatus.PUBLISHED && !reviewData.canMerge && <GenericAlert
                variant={"danger"}
                header={"NOTE"}
                persistent
            >
                {requestMessage}
            </GenericAlert>}
            {reviewData.status === ReviewStatus.PUBLISHED && reviewData.canMerge && <GenericAlert
                header={"NOTE"}
                persistent
            >
                {requestMessage}
            </GenericAlert>}
            <BoundaryBox>
                <MergeSummaryBox>
                    <strong style={{ fontSize: "150%" }}> MERGE SUMMARY</strong>
                    {handleMergeStatus()}<span style={{ float: "right" }}> Merge Status: &nbsp;&nbsp;</span>
                </MergeSummaryBox>
                <hr />
                {reviewData.type === ReviewType.CONFIG_REVIEW && <SourceDetailsBox>
                    <strong style={{ fontSize: "130%" }}> CONFIGURATION DETAILS</strong>
                    <div style={{ marginTop: "2%" }}>
                        <span> Name: {basicDescriptionJSON.config_name}</span>
                        <span style={{ float: "right" }}> Domain: {basicDescriptionJSON.domain}</span>
                    </div>
                    <div style={{ marginTop: "1%" }}>
                        <span> Service Name: {basicDescriptionJSON.service_name}</span>
                        <span style={{ float: "right" }}> Realm: {!isEmpty(basicDescriptionJSON.applicationRealm) ? basicDescriptionJSON.applicationRealm : basicDescriptionJSON.realm}</span>
                    </div>
                </SourceDetailsBox>}
                {reviewData.type === ReviewType.CODE_REVIEW && <SourceDetailsBox>
                    <strong style={{ fontSize: "130%" }}> REPOSITORY DETAILS</strong>
                    <div style={{ marginTop: "2%" }}>
                        <span> Name: {basicDescriptionJSON.expresso_repo_name}</span>
                        <span style={{ float: "right" }}> Type: {basicDescriptionJSON.repo_type}</span>
                    </div>
                    <div style={{ marginTop: "1%" }}>
                        <span> File: {basicDescriptionJSON.file}</span>
                    </div>
                </SourceDetailsBox>}
                <hr />
                <ReviewDetailsBox>
                    <strong style={{ fontSize: "130%" }}> REVIEW DETAILS</strong>
                    <div style={{ marginTop: "2%" }}>
                        <span> Review URL: <a href={reviewURL} target="_isblank">{reviewURL}</a></span>
                        <span style={{ float: "right" }}> Owner: {reviewData.owner}</span>
                    </div>
                    <div style={{ marginTop: "1%" }}>
                        {ApprovedUserList()}
                    </div>
                </ReviewDetailsBox>
                <hr />
                {reviewData.type === ReviewType.CONFIG_REVIEW && <MergeRequirementsBox>
                    <strong style={{ fontSize: "130%" }}> MERGE REQUIREMENTS</strong>
                    <div>
                        <span style={{ marginTop: "2%" }}> MCM/SEV2:</span>&nbsp;&nbsp;
                        <MCMorSEV2Area disabled={reviewData.status === 'MERGED' || !reviewData.canMerge} value={reviewData.status === 'MERGED' ? JSON.parse(reviewData.comments).mcmOrSev2 : mcmOrSev2} onInput={(e: any) => {
                            setMcmOrSev2(e.target.value);
                            reviewData.type === ReviewType.CONFIG_REVIEW && setIsMCMOrSev2LinkValid(isValidMcmOrSev2Link(e.target.value))
                        }} placeholder={"no valid MCM/SEV2 link provided yet"}
                            style={{ fontSize: "15px" }} readOnly={reviewData.status === 'MERGED' || userName !== reviewData.owner} ></MCMorSEV2Area>
                    </div>
                </MergeRequirementsBox>}
                <br />
            </BoundaryBox>
            <div style={{ marginRight: "5%", marginBottom: "10%" }}>
                {reviewData.status !== ReviewStatus.MERGED && userName === reviewData.owner && reviewData.canMerge &&
                    <MergeButton disabled={reviewData.type === ReviewType.CONFIG_REVIEW ? isEmpty(mcmOrSev2) : false}
                        onClick={handleMerge}>MERGE
                    </MergeButton>}
            </div>
        </>
    )
}
