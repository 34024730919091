import React from "react";
import { isEmpty } from "src/utils/common_utils";
import {OperationType} from "src/constant";
export function DisplayError(props: {
  message: string;
  marginTop?: number;
  operationType?: OperationType;
}) {
  return (
    <>
      {props.operationType !== OperationType.VIEW && (
        <div
          style={{
            color: "red",
            fontSize: "10px",
            marginTop: `${!isEmpty(props.marginTop) ? props.marginTop : -8}px`,
          }}
        >
          {props.message}
        </div>
      )}
    </>
  );
}
