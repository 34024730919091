export interface ListCodeRepositoriesRequest {
    repositoryType: string,
    nextToken?: string,
    pageSize?: string
}

export interface ListCodeVersionsRequest {
    repositoryType: string,
    scriptPath: string,
    branch?: string
}

export enum RepositoryType {
    "S3" = "S3",
    "GIT" = "GIT",
}

export interface GitRepository {
    packageName: string,
    branchList?: string[],
    gitRepoURL? : string,
    syncEnabled? : boolean
}

export interface S3Repository {
    bucketName: string,
    isAccessible?: boolean,
    isVersionEnabled?: boolean
}

export interface CodeRepository {
    repositoryType: RepositoryType,
    gitRepository?: GitRepository,
    s3Repository?: S3Repository
}

export interface CreateCodeRepositoryRequest {
    requestedBy: string,
    codeRepository: CodeRepository
}

export interface GetCodeRepositoryRequest {
    repositoryType: RepositoryType
}

export const codeRepositoryInitialState = {
    fetchedCodeRepositories: {
        codeRepositories: [],
        startCodeRepositoriesLoad: false,
        codeRepositoriesFetched: false,
        fetchAllCodeRepositoriesSuccess: false,
    },
    registerCodeRepository: {
        requestStatus: "",
        codeRepository: {},
        startRegisterCodeRepository: false,
        registerRepositorySuccess: false,
        registerRepositoryResponseFetched: false,
        errorMessage: ""
    },
    getCodeRepository: {
        repository: {},
        startCodeRepositoryFetch: false,
        getCodeRepositorySuccess: false,
        codeRepositoryFetched: false
    },
    codeVersions: {
        preExecution: {
            startLoader: false,
            codeVersionsList: [],
        },
        execution: {
            startLoader: false,
            codeVersionsList: [],
        },
        postExecution: {
            startLoader: false,
            codeVersionsList: [],
        }
    }
}