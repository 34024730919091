import {getDatasetFetchAllTarget} from "src/control/targets";

export interface DatasetInfo {
    datasetInfoName: string,
    s3PathTemplate: string,
    accountId: string,
    createdBy: string,
    creationTime?: string,
    encryptionKMSArn?: string
}

export interface DatasetInfosListRequestParams {
    nextToken: string | null,
    pageSize: number | null
}

//TODO: Change PAGE_SIZE to 20/10 when the pagination logic is added
export enum DatasetDefaultConstants {
    PAGE_SIZE = 100
}

export type SetFunction<T> = (value: T) => void;

export enum BannerTypes {
    SUCCESS = "success",
    DANGER = "danger",
    WARNING = "warning",
    INFO = "info"

}

export enum RequestStatus {
    Success = "SUCCESS",
    AlreadyExists = "ALREADY_EXISTS",
    Failure = "FAILURE"
}

export interface DatasetInfoCreateRequestBody {
    datasetInfoName: string,
    s3PathTemplate: string,
    accountId: string,
    requestedBy: string,
    encryptionKMSArn:string | null
}

export interface CreateDatasetInfoResponse {
    datasetInfoName: string,
    requestStatus: RequestStatus,
    message: string
}

export enum Ordering {
    Ascending = "ASCENDING",
    Descending = "DESCENDING"
}

export class Dataset {
    datasetName: string | undefined
    s3Path: string | undefined
    accountId: string | undefined
    createdBy: string | undefined
    creationTime: string | undefined
    encryptionKMSArn: string | undefined


    constructor (datasetName:string, s3Path: string, accountId: string, createdBy: string, creationTime: string | undefined, encryptionKmsArn: string | undefined) {
        this.datasetName = datasetName;
        this.s3Path = s3Path;
        this.accountId = accountId;
        this.createdBy = createdBy;
        this.creationTime = creationTime;
        this.encryptionKMSArn = encryptionKmsArn;
    }

    public static fromDatasetInfo(datasetInfoObj: DatasetInfo) {
        return new Dataset(
            datasetInfoObj.datasetInfoName,
            datasetInfoObj.s3PathTemplate,
            datasetInfoObj.accountId,
            datasetInfoObj.createdBy,
            datasetInfoObj.creationTime,
            datasetInfoObj.encryptionKMSArn
        )
    }

    toJsonObject () {
        return JSON.stringify(this);
    }
}