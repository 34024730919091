import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CreateNewButton,
    PaddedSpan,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableHeadRow,
    TableRowWithShadow
} from "src/view/style/table_styles";
import {fetchAllDatasets} from "src/control/actions/dataset/dataset_actions";
import {allDatasetsSelector} from "src/control/selectors/dataset/dataset_selectors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {formatDate, getFromLocalStorageAndSetTeam, setIfMounted} from "src/utils/common_utils";
import {CouldNotLoadAlert} from "src/view/style/common_styles";
import {GreenSpinner} from "src/view/style/header_styles";
import {Dataset, DatasetDefaultConstants, Ordering, SetFunction} from "src/view/dataset/dataset_constants";
import {colorGrey, SortAscending, SortDescending} from "src/view/style/icons";


export interface ListDatasetProps {
    setVisibilityForViewDataset: SetFunction<boolean>,
    setVisiblityForCreateDataset: SetFunction<boolean>,
    setViewingDataset: SetFunction<Dataset>
}

enum SortBy {
    datasetName= "datasetName",
    s3Path = "s3Path",
    accountId = "accountId",
    createdBy = "createdBy",
    creationTime = "creationTime"
}

//TODO: Add Pagination Logic
//TODO: Add Filter
export default function ListDatasets(props: ListDatasetProps) {
    let _isMounted = useRef(true);
    const dispatch = useDispatch();
    let datasets: Dataset[] = useSelector(allDatasetsSelector);
    const [isLoaded, setIsLoaded] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [sortBy, setSortBy] = useState(SortBy.datasetName);
    const [sortOrder, setSortOrder] = useState(Ordering.Ascending)

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    });

    useEffect(() => {
        dispatch(fetchAllDatasets(
            () => {
                setIfMounted(_isMounted.current, setIsLoaded, true);
            },
            (error: any) => {
                setIfMounted(_isMounted.current, setIsLoaded, true);
                setIfMounted(_isMounted.current, setErrorOccurred, true);
                console.log(error);
                dispatch(showSnackBar(error.message));
            },
            {
                pageSize: DatasetDefaultConstants.PAGE_SIZE,
                nextToken: null
            }
        ))
        getFromLocalStorageAndSetTeam(dispatch)
    }, [dispatch]);

    const getSortedList = (datasets: Dataset[]) => {
        return datasets.sort((first: Dataset, second:Dataset) : number => {
            switch (sortBy) {
                case SortBy.datasetName: return (first.datasetName as string).localeCompare(second.datasetName as string)
                case SortBy.s3Path: return (first.s3Path as string).localeCompare(second.s3Path as string)
                case SortBy.accountId: return (first.accountId as string).localeCompare(second.accountId as string)
                case SortBy.createdBy: return (first.createdBy as string).localeCompare(second.createdBy as string)
                case SortBy.creationTime: return (first.creationTime as string).localeCompare(second.creationTime as string)
            }
            return 0;
        })
    }

    const showDatasetView = (dataset: Dataset) => {
        props.setVisibilityForViewDataset(true);
        props.setViewingDataset(dataset);
    }

    const showDatasetCreate = () => {
        props.setVisiblityForCreateDataset(true);
    }

    const toggledSortOrdering = (currentSortOrdering: Ordering) => {
        return (currentSortOrdering == Ordering.Ascending)  ? Ordering.Descending : Ordering.Ascending;
    }

    const handleOnSortClick = (sortByProperty: SortBy) => {
        if (sortBy == sortByProperty) {
            setIfMounted(_isMounted.current, setSortOrder, toggledSortOrdering(sortOrder))
        } else {
            setIfMounted(_isMounted.current, setSortBy, sortByProperty);
            setIfMounted(_isMounted.current, setSortOrder, Ordering.Ascending);
        }
    }

    const getSortIcon = (property: SortBy) => {
        if (sortBy == property) {
            return sortOrder == Ordering.Ascending ?
                <div className={"float-right"} onClick={() => handleOnSortClick(property)}>
                    <SortAscending/>
                </div>
                :
                <div className={"float-right"} onClick={() => handleOnSortClick(property)}>
                    <SortDescending/>
                </div>
        } else {
            return (
                <div className={"float-right"} onClick={() => handleOnSortClick(property)}>
                    <SortAscending style={colorGrey}/>
                </div>
            )
        }
    }

    const fillRows = () => {
        if (errorOccurred) {
            return (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    Could Not Load Datasets
                </CouldNotLoadAlert>
            );
        }

        if (!isLoaded) {
            return (
                <GreenSpinner variant={"default"} size={"large"} />
            );
        }

        let datasetList = getSortedList(datasets);
        datasetList = sortOrder == Ordering.Ascending ? datasetList : datasetList.reverse();

        return datasetList.map((dataset:Dataset, key: number) => {
            return (
                <TableRowWithShadow key={key}>
                    <TableCell onClick={() => {showDatasetView(dataset)}}>
                        <PaddedSpan>{dataset.datasetName}</PaddedSpan>
                    </TableCell>
                    <TableCell onClick={() => {showDatasetView(dataset)}}>
                        <PaddedSpan>{dataset.s3Path}</PaddedSpan>
                    </TableCell>
                    <TableCell onClick={() => {showDatasetView(dataset)}}>
                        <PaddedSpan>{dataset.accountId}</PaddedSpan>
                    </TableCell>
                    <TableCell onClick={() => {showDatasetView(dataset)}}>
                        <PaddedSpan>{dataset.createdBy}</PaddedSpan>
                    </TableCell>
                    <TableCell onClick={() => {showDatasetView(dataset)}}>
                        <PaddedSpan>{dataset.creationTime && formatDate(dataset.creationTime)}</PaddedSpan>
                    </TableCell>
                </TableRowWithShadow>
            )
        })

    }

    const display = () => {

        return (
            <>
                <Table>
                    <TableHead>
                        <TableHeadRow>
                            <TableCell>
                                <PaddedSpan>Dataset Name</PaddedSpan>
                                {getSortIcon(SortBy.datasetName)}
                            </TableCell>
                            <TableCell>
                                <PaddedSpan>S3 Path</PaddedSpan>
                                {getSortIcon(SortBy.s3Path)}
                            </TableCell>
                            <TableCell>
                                <PaddedSpan>Account Id</PaddedSpan>
                                {getSortIcon(SortBy.accountId)}
                            </TableCell>
                            <TableCell>
                                <PaddedSpan>Created By</PaddedSpan>
                                {getSortIcon(SortBy.createdBy)}
                            </TableCell>
                            <TableCell>
                                <PaddedSpan>Created At</PaddedSpan>
                                {getSortIcon(SortBy.creationTime)}
                            </TableCell>
                        </TableHeadRow>
                    </TableHead>
                    <TableBody>
                        {fillRows()}
                    </TableBody>
                </Table>
            </>
        )
    }

    return (
        <>
            <TableHeader>
                Datasets
                <CreateNewButton
                    variant={"primary"}
                    disabled={false}
                    onClick={showDatasetCreate}
                >
                    Create New!
                </CreateNewButton>
            </TableHeader>
            <br/>
            {display()}
        </>
    );
}