import styled from "styled-components";
import {KatBreadcrumb, KatBreadcrumbItem} from "@amzn/katal-react";

export const BreadCrumbBar = styled.div`
    top: 90px;
    position: fixed;
    height: 40px;
    margin: 0 auto !important;
    left: 0;
    right: 0;
    max-width: 98vw;
    z-index: 10;
    background: #e2e2e2;
`;

export const BreadCrumbGroup = styled(KatBreadcrumb)`
`

export const BreadCrumbItem = styled(KatBreadcrumbItem)`
`

export const BreadCrumbItemStyle = {
    padding: "10px",
    fontSize: "0.8vw"
}

export const BreadCrumbItemPadding = styled.div`
    padding: 10px;
`;