import React, {useEffect, useRef, useState} from "react";
import {KatDivider, KatFlashbar, KatLabel, KatRadiobuttonGroup, KatSpinner,} from "@amzn/katal-react";
import {
  ContainerType,
  EmrClusterType,
  RepositoryType,
  SagemakerFeature,
  ServiceType,
} from "../../model/runways/job_model";
import Collapsible from "../commons/collapsible";
import {
  ADHOC_EMR_CLUSTER_NAME,
  CUSTOM_TRAINING_IMAGE,
  DEFAULT_EMR_CLUSTER_NAME,
  getContainerType,
  getEcsImageOptions,
  getEmrClusterType,
  getInitialEmrJobFlowStep,
  getModelTrainingImageOptions,
  getModelTrainingInstanceTypes,
  getRepositoryTypeOptions,
  getSagemakerFeatureType,
  getServiceType,
  initialEmrComputeConfig,
  isNonProdHostName,
  NO_ONBOARDED_GIT_REPOSITORIES_MESSAGE,
  NO_ONBOARDED_S3_REPOSITORIES_MESSAGE
} from "./constants";
import {FormDropDown, FormInput} from "../style/runways/form_input_styles";
import {useDispatch, useSelector} from "react-redux";
import {trainingImagesSelector,} from "../../control/selectors/modelTraining/model_training_selectors";
import {useParams} from "react-router";
import {
  extractVersionIdFromSignedUrl,
  getEmptyStringValue,
  getValueOrDefaultJsonString,
  getValueOrEmptyPlaceholder,
  isEmpty,
  setIfMounted,
} from "../../utils/common_utils";
import {getPrettyJsonString, isValidJSON} from "../../utils/json_utils";
import JSONEditor from "../commons/json_editor";
import {VStatusIndicator} from "../style/view_config_styles";
import {loadTrainingImages} from "src/control/actions/modelTraining/runway_actions";
import {DisplayError} from "../commons/display_error";
import {
  isValidCodeRepositoryType,
  isValidContainerArn,
  isValidContainerName,
  isValidEmrClusterName,
  isValidEmrJobFlowStep,
  isValidExecutionCode,
  isValidSagemakerInstanceCount,
  isValidSagemakerInstanceType,
  isValidSagemakerVolumeSize,
  isValidServiceType,
} from "../validation/runways/job_components_validations";
import {
  allCodeRepositoriesSelector,
  executionCodeVersionsSelector,
  getCodeRepositorySelector,
  postExecutionCodeVersionsSelector,
  preExecutionCodeVersionsSelector
} from "src/control/selectors/codeRepository/code_repo_selectors";
import {
  getCodeRepository,
  getCodeVersions,
  loadCodeRepositories
} from "src/control/actions/codeRepository/code_repository_actions";
import {DEFAULT_BRANCH, JobStep, LATEST_CODE_VERSION, OperationType} from "src/constant";
import {START_FETCH_ALL_CODE_REPOSITORIES_LOADER} from "src/control/actions/action_types";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

const moment = require("moment")

/**
 * reusable component to render service and artifact info
 * @param props
 * @returns JSX
 */
export default function JobComponent(props: {
  operationType: OperationType;
  serviceType?: any;
  setServiceType?: any;
  service: any;
  setService?: any;
  artifactInfo: any;
  setArtifactInfo?: any;
  isValidationSuccess?: boolean;
  jobName?: string;
}) {
  const params: any = useParams();
  const dispatch = useDispatch();

  let _isMounted = useRef(true);

  // Selectors
  let containerArnInfo = useSelector(trainingImagesSelector);
  let codeRepositoriesSelector = useSelector(allCodeRepositoriesSelector);

  const getCodeRepo = useSelector(getCodeRepositorySelector)

  let executionCodeVersions = useSelector(executionCodeVersionsSelector);
  let preExecutionCodeVersions = useSelector(preExecutionCodeVersionsSelector);
  let postExecutionCodeVersions = useSelector(postExecutionCodeVersionsSelector);

  // State hooks
  const [serviceType, setServiceType] = useState("");
  const [containerType, setContainerType] = useState("");
  const [customCodeRepository, setCustomCodeRepository] = useState("");
  const [preExecutionCode, setPreExecutionCode] = useState({
    codeUri: "",
    codeVersion: "",
  });
  const [executionCode, setExecutionCode] = useState({
    codeUri: "",
    codeVersion: "",
  });
  const [postExecutionCode, setPostExecutionCode] = useState({
    codeUri: "",
    codeVersion: "",
  });
  const [sageMakerFeatureType, setSageMakerFeatureType] = useState(
    SagemakerFeature.TRAINING
  );
  const [emrClusterType, setEmrClusterType] = useState("");
  const [emrClusterName, setEmrClusterName] = useState("");
  const [emrClusterConfig, setEmrClusterConfig] = useState(
    getPrettyJsonString(JSON.stringify(initialEmrComputeConfig)) as string
  );
  const [emrJobFlowStep, setEmrJobFlowStep] = useState(
    getPrettyJsonString(
      JSON.stringify(getInitialEmrJobFlowStep(props.jobName as string))
    ) as string
  );
  const [sagemakerInstanceType, setSagemakerInstanceType] =
    useState("ml.t2.medium");
  const [sagemakerInstanceCount, setSagemakerInstanceCount] = useState("1");
  const [sagemakerVolumeSize, setSagemakerVolumeSize] = useState("50");
  const [jobMetadata, setJobMetadata] = useState("{}");
  const [artifactArguments, setArtifactArguments] = useState("");
  const [containerName, setContainerName] = useState("");
  const [containerArn, setContainerArn] = useState("");
  const [repositoryType, setRepositoryType] = useState("");
  const [gitBranchName, setGitBranchName] = useState("");
  const [codeRepoOptions, setCodeRepoOptions] = useState<any[]>([]);
  const [branchOptions, setBranchOptions] = useState([])

  const DISPLAY_TIMESTAMP_FORMAT = "YYYY-MM-DD HH:mm:ss"

  useEffect(() => {

    setEmrJobFlowStep(
      getPrettyJsonString(
        JSON.stringify(getInitialEmrJobFlowStep(props.jobName as string))
      ) as string
    );
  }, [props.jobName]);

  useEffect(() => {
    if (
      !isEmpty(props.service) &&
      !isEmpty(props.artifactInfo) &&
      Object.keys(props.service).length !== 0 &&
      Object.keys(props.artifactInfo).length !== 0
    ) {
      initializeJobComponents();
    }
  }, [props.service, props.artifactInfo]);

  useEffect(() => {
    if (!isEmpty(containerName) && containerName !== CUSTOM_TRAINING_IMAGE) {
      dispatch(loadTrainingImages(containerName));
    }
  }, [containerName]);

  const getRepositoryNameFromArtifactInfo = () => {
    if (isEmpty(props.artifactInfo) ||
        isEmpty(props.artifactInfo.codeInfo) ||
        isEmpty(props.artifactInfo.codeInfo.codeRepository)
    ) {
      return ""
    }
    switch (props.artifactInfo.codeInfo.codeRepository.repositoryType) {
      case RepositoryType.GIT:
        return props.artifactInfo.codeInfo.codeRepository.gitRepository?.packageName
      case RepositoryType.S3:
        return props.artifactInfo.codeInfo.codeRepository.s3Repository?.bucketName
    }
  }

  useEffect(() => {
    const repositoryName= getRepositoryNameFromArtifactInfo()
    if ((props.operationType == OperationType.VIEW || props.operationType == OperationType.UPDATE)
        && !isEmpty(props.artifactInfo.codeInfo) && !isEmpty(repositoryName)) {
      !isEmpty(props.artifactInfo.codeInfo.preExecutionInfo) &&
      !isEmpty(props.artifactInfo.codeInfo.preExecutionInfo.codeUri) &&
      dispatch(getCodeVersions({
            repositoryType: props.artifactInfo.codeInfo.codeRepository.repositoryType,
            scriptPath: props.artifactInfo.codeInfo.preExecutionInfo.codeUri,
            branch: (props.artifactInfo.codeInfo.codeRepository.repositoryType == RepositoryType.GIT) ?
                (!isEmpty(props.artifactInfo.codeInfo.codeRepository.gitRepository.branchList[0]) ?
                    props.artifactInfo.codeInfo.codeRepository.gitRepository.branchList[0] :
                    DEFAULT_BRANCH) : undefined
          }, repositoryName, JobStep.PRE_EXECUTION
      ));

      !isEmpty(props.artifactInfo.codeInfo.executionInfo) &&
      !isEmpty(props.artifactInfo.codeInfo.executionInfo.codeUri) &&
      dispatch(getCodeVersions({
            repositoryType: props.artifactInfo.codeInfo.codeRepository.repositoryType,
            scriptPath: props.artifactInfo.codeInfo.executionInfo.codeUri,
            branch: (props.artifactInfo.codeInfo.codeRepository.repositoryType == RepositoryType.GIT) ?
                (!isEmpty(props.artifactInfo.codeInfo.codeRepository.gitRepository.branchList[0]) ?
                    props.artifactInfo.codeInfo.codeRepository.gitRepository.branchList[0] :
                    DEFAULT_BRANCH) : undefined
          }, repositoryName, JobStep.EXECUTION
      ));

      !isEmpty(props.artifactInfo.codeInfo.postExecutionInfo) &&
      !isEmpty(props.artifactInfo.codeInfo.postExecutionInfo.codeUri) &&
      dispatch(getCodeVersions({
            repositoryType: props.artifactInfo.codeInfo.codeRepository.repositoryType,
            scriptPath: props.artifactInfo.codeInfo.postExecutionInfo.codeUri,
            branch: (props.artifactInfo.codeInfo.codeRepository.repositoryType == RepositoryType.GIT) ?
                (!isEmpty(props.artifactInfo.codeInfo.codeRepository.gitRepository.branchList[0]) ?
                    props.artifactInfo.codeInfo.codeRepository.gitRepository.branchList[0] :
                    DEFAULT_BRANCH) : undefined
          }, repositoryName, JobStep.POST_EXECUTION
      ));
    }
  }, [props.artifactInfo]);

  const setCodeRepositories = () => {
    const codeRepositories: any[] = []
    if (codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess) {
      codeRepositoriesSelector.codeRepositories.forEach((codeRepository: any) => {
            let repositoryName = ""
            switch (codeRepository.codeRepository.repositoryType) {
              case RepositoryType.GIT:
                if (codeRepository.codeRepository.gitRepository.syncEnabled) {
                  repositoryName = codeRepository.codeRepository.gitRepository.packageName
                }
                break;
              case RepositoryType.S3:
                repositoryName = codeRepository.codeRepository.s3Repository.bucketName
                break;
            }
            if (!isEmpty(repositoryName)) {
              codeRepositories.push({
                name: repositoryName,
                value: repositoryName
              })
            }
          }
      )
      setCodeRepoOptions(codeRepositories)
    }
  }

  useEffect(() => {
    setCodeRepositories()
  }, [codeRepositoriesSelector]);

  useEffect(() => {
    if(!isEmpty(props.artifactInfo.codeInfo.codeRepository.repositoryType)) {
      dispatch({type: START_FETCH_ALL_CODE_REPOSITORIES_LOADER})
      dispatch(loadCodeRepositories({
        repositoryType: props.artifactInfo.codeInfo.codeRepository.repositoryType
      }))
    }
  }, [props.artifactInfo.codeInfo.codeRepository.repositoryType]);

  useEffect(() => {
      (props.artifactInfo.codeInfo.codeRepository.repositoryType == RepositoryType.S3 &&
          !isEmpty(customCodeRepository))
      && dispatch(getCodeRepository({
            repositoryType: RepositoryType.S3
          }, customCodeRepository)
      )
  }, [customCodeRepository]);


  useEffect(() => {
    if(repositoryType == RepositoryType.GIT) {
      setGitBranchOptions()
    }
  }, [customCodeRepository, codeRepositoriesSelector.codeRepositories]);


  const setGitBranchOptions = () => {
    const gitBranchOptions: any[] = []
    const filteredCodeRepositories = codeRepositoriesSelector.codeRepositories.filter((codeRepository: any) => {
      return (codeRepository.codeRepository.repositoryType == repositoryType &&
          codeRepository.codeRepository.gitRepository.packageName == customCodeRepository
      )
    })
    if(!isEmpty(filteredCodeRepositories)) {
      filteredCodeRepositories[0].codeRepository.gitRepository.branchList.forEach((branch: any) => {
            gitBranchOptions.push({
              name: branch,
              value: branch
            })
          }
      )
      setIfMounted(_isMounted.current, setBranchOptions, gitBranchOptions)
    }
  }

  const getCodeVersionList = (step : string) => {
    switch (step) {
      case JobStep.PRE_EXECUTION : return preExecutionCodeVersions;
      case JobStep.POST_EXECUTION : return postExecutionCodeVersions;
      case JobStep.EXECUTION : return executionCodeVersions;
      default : return []
    }
  }

  const getCodeVersionsListDropDown = (initialValue: string, step: string, handleCodeVersionChange: (event: any) => void) => {
    const options: any[] = []
    let codeVersions = getCodeVersionList(step)
    let codeVersionsList = codeVersions.codeVersionsList

    options.push({
      "name": LATEST_CODE_VERSION,
      "value": LATEST_CODE_VERSION
    })
    let index = 1;
    let currentVal = "";
    if (initialValue == LATEST_CODE_VERSION) {
      currentVal = LATEST_CODE_VERSION
    }
    !isEmpty(codeVersionsList) && codeVersionsList.forEach((codeVersion: any) => {
      const timestamp = moment.utc(new Date(codeVersion.timestamp * 1000)).format(DISPLAY_TIMESTAMP_FORMAT)
      options.push({
        "name": `[` + timestamp.toString() + `UTC] V${index++}`,
        "value": codeVersion.signedS3Url
      })
      if (extractVersionIdFromSignedUrl(codeVersion.signedS3Url) == initialValue) {
        currentVal = codeVersion.signedS3Url
      }
    });
    return (
        <>
          {
            codeVersions.startLoader ?
                <KatSpinner/> :
                !isEmpty(codeVersionsList) ?
                    <FormDropDown
                        searchable={true}
                        max-height="200px"
                        options={options}
                        value={currentVal}
                        label="Select Code Version"
                        placeholder='Select Code Version'
                        tooltipText='Select Code Version'
                        onChange={handleCodeVersionChange}
                        disabled={props.operationType === OperationType.VIEW}
                    >
                    </FormDropDown> :
                    <KatFlashbar header="Note" description="No Code Versions found"/>

          }
        </>
    )
  }

  /**
   * Initializes component states from prop
   */
  const initializeJobComponents = () => {
    if (props.serviceType) {
      setServiceType(props.serviceType);
      if (props.setServiceType) props.setServiceType(props.serviceType);
    }
    if (props.serviceType === ServiceType.EMR) {
      setEmrClusterType(props.service.emrService?.emrClusterType);
      setEmrClusterName(
        getEmptyStringValue(props.service.emrService?.computeInfo.clusterName)
      );
      setEmrClusterConfig(
        getValueOrDefaultJsonString(
          props.service.emrService?.computeInfo.clusterConfig
        )
      );
      setEmrJobFlowStep(
        getValueOrDefaultJsonString(
          props.service.emrService?.serviceInfo.jobFlowStep
        )
      );
    } else if (props.serviceType === ServiceType.SAGEMAKER) {
      setSageMakerFeatureType(props.service.sagemakerService?.sagemakerFeature);
      setSagemakerVolumeSize(
        props.service.sagemakerService?.computeInfo.volumeSizeInGB
      );
      setSagemakerInstanceType(
        props.service.sagemakerService?.computeInfo.instanceType
      );
      setSagemakerInstanceCount(
        props.service.sagemakerService?.computeInfo.instanceCount
      );
    }
    if (props.artifactInfo) {
      setContainerType(props.artifactInfo.containerType);
      if (props.artifactInfo.containerType === ContainerType.IMAGE) {
        setContainerName(
          props.artifactInfo.containerInfo?.imageContainer?.containerName
        );
        setContainerArn(
          props.artifactInfo.containerInfo?.imageContainer?.containerArn
        );
      }
      setRepositoryType(
        props.artifactInfo.codeInfo?.codeRepository?.repositoryType
      );
      setCustomCodeRepository(
        getEmptyStringValue(
          props.artifactInfo.codeInfo?.codeRepository?.repositoryType ===
            RepositoryType.GIT
            ? props.artifactInfo.codeInfo?.codeRepository?.gitRepository
                ?.packageName
            : props.artifactInfo.codeInfo?.codeRepository?.s3Repository
                ?.bucketName
        )
      );
      if(!isEmpty(props.artifactInfo.codeInfo?.codeRepository.gitRepository
          ?.branchList) && props.artifactInfo.codeInfo?.codeRepository.gitRepository
          ?.branchList.length >0
      ) {
        setGitBranchName(
            getEmptyStringValue(
                props.artifactInfo.codeInfo?.codeRepository?.repositoryType ===
                RepositoryType.GIT
                    ? props.artifactInfo.codeInfo?.codeRepository.gitRepository
                        ?.branchList[0]
                    : ""
            )
        );
      }
      setPreExecutionCode({
        codeUri: getEmptyStringValue(props.artifactInfo.codeInfo?.preExecutionInfo?.codeUri),
            codeVersion: (props.artifactInfo.codeInfo?.preExecutionInfo?.codeVersion == null ? LATEST_CODE_VERSION:
                props.artifactInfo.codeInfo?.preExecutionInfo?.codeVersion!)
          }
      );
      setExecutionCode(
          {
            codeUri: getEmptyStringValue(props.artifactInfo.codeInfo?.executionInfo?.codeUri),
            codeVersion: (props.artifactInfo.codeInfo?.executionInfo?.codeVersion == null ? LATEST_CODE_VERSION:
                props.artifactInfo.codeInfo?.executionInfo?.codeVersion)
          }
      );
      setPostExecutionCode(
          {
            codeUri: getEmptyStringValue(props.artifactInfo.codeInfo?.postExecutionInfo?.codeUri),
            codeVersion: (props.artifactInfo.codeInfo?.postExecutionInfo?.codeVersion == null ? LATEST_CODE_VERSION:
                props.artifactInfo.codeInfo?.postExecutionInfo?.codeVersion)
          }
      );
      setJobMetadata(
        getValueOrDefaultJsonString(props.artifactInfo.jobMetadata)
      );
      setArtifactArguments(getEmptyStringValue(props.artifactInfo.arguments));
    }
  };

  /**
   * Get JSX based on service type
   * @returns JSX
   */
  const getServiceComponent = () => {
    switch (serviceType) {
      case ServiceType.EMR:
        return getEmrServiceComponent();
      case ServiceType.SAGEMAKER:
        return getSagemakerServiceComponent();
      case ServiceType.ECS:
        return getEcsServiceComponent();
    }
  };

  /**
   * renders the view for Emr Service
   * @returns JSX
   */
  const getEmrServiceComponent = () => {
    return (
      <div>
        <KatLabel tooltipText="Cluster Type">Cluster Type :&nbsp;</KatLabel>
        <KatRadiobuttonGroup
          name={"Cluster Type"}
          options={getEmrClusterType()}
          value={emrClusterType}
          onChange={(event: any) => handleClusterTypeChange(event.target.value)}
          disabled={props.operationType === OperationType.VIEW}
        />
        <br />

        <Collapsible label="Compute Info">
          <FormInput
            label="Cluster Name"
            type="text"
            placeholder="Provide Cluster Name"
            value={emrClusterName}
            tooltipText="This is the name of the cluster"
            onChange={(event: any) => {
              setEmrClusterName(event.target.value);
              props.setService({
                ...props.service,
                emrService: {
                  ...props.service.emrService,
                  computeInfo: {
                    ...props.service.emrService?.computeInfo,
                    clusterName: event.target.value,
                  },
                },
              });
            }}
            disabled={props.operationType === OperationType.VIEW || EmrClusterType.DEFAULT === emrClusterType}
          />
          {!props.isValidationSuccess &&
            !isValidEmrClusterName(emrClusterName) && (
              <DisplayError
                operationType={props.operationType}
                message="Field can't be empty"
                marginTop={1}
              />
            )}
          <br />

          <KatLabel tooltipText="Cluster configuration">
            Cluster configuration :&nbsp; &nbsp; &nbsp;
            <VStatusIndicator
              variant={isValidJSON(emrClusterConfig) ? "success" : "error"}
              label={"Cluster configuration"}
            />
          </KatLabel>
          <JSONEditor
            fieldName={"Cluster configuration"}
            width="80%"
            value={
              props.operationType === OperationType.VIEW
                ? getValueOrEmptyPlaceholder(emrClusterConfig)
                : emrClusterConfig
            }
            onChange={(name: any, value: any) => {
              setEmrClusterConfig(value);
              props.setService({
                ...props.service,
                emrService: {
                  ...props.service.emrService,
                  computeInfo: {
                    ...props.service.emrService?.computeInfo,
                    clusterConfig: value,
                  },
                },
              });
            }}
            readOnly={props.operationType === OperationType.VIEW || EmrClusterType.DEFAULT === emrClusterType}
          />
        </Collapsible>
        <br />

        <Collapsible label="Service Info">
          <KatLabel tooltipText="Job Flow Step">
            Job Flow Step :&nbsp; &nbsp; &nbsp;
            <VStatusIndicator
              variant={isValidJSON(emrJobFlowStep) ? "success" : "error"}
              label={"Job Flow Step"}
            />
          </KatLabel>
          <JSONEditor
            fieldName={"Job Flow Step"}
            width="80%"
            value={
              props.operationType === OperationType.VIEW
                ? getValueOrEmptyPlaceholder(emrJobFlowStep)
                : emrJobFlowStep
            }
            onChange={(name: any, value: any) => {
              setEmrJobFlowStep(value);
              props.setService({
                ...props.service,
                emrService: {
                  ...props.service.emrService,
                  serviceInfo: {
                    ...props.service.emrService?.serviceInfo,
                    jobFlowStep: value,
                  },
                },
              });
            }}
            readOnly={props.operationType === OperationType.VIEW}
          />
          {!props.isValidationSuccess &&
            !isValidEmrJobFlowStep(emrJobFlowStep) && (
              <DisplayError
                operationType={props.operationType}
                message="Invalid JSON"
                marginTop={1}
              />
            )}
        </Collapsible>
      </div>
    );
  };

  /**
   * renders the view for Sagemaker Service
   * @returns JSX
   */
  const getSagemakerServiceComponent = () => {
    return (
      <>
        <div>
          <KatLabel tooltipText="Feature Type">
            SM Feature Type :&nbsp;
          </KatLabel>
          <KatRadiobuttonGroup
            name={"FeatureType"}
            options={getSagemakerFeatureType()}
            value={sageMakerFeatureType}
            disabled={true}
          ></KatRadiobuttonGroup>
          <br />
        </div>
        <Collapsible label="Compute Info">
          <KatLabel tooltipText="Instance type of the training instance created.">
            Training instance Type :&nbsp;
          </KatLabel>
          <FormDropDown
            searchable={true}
            value={sagemakerInstanceType}
            maxHeight="100px"
            options={getModelTrainingInstanceTypes()}
            onChange={(event: any) => {
              setSagemakerInstanceType(event.target.value);
              props.setService({
                ...props.service,
                sagemakerService: {
                  ...props.service.sagemakerService,
                  computeInfo: {
                    ...props.service.sagemakerService?.computeInfo,
                    instanceType: event.target.value,
                  },
                },
              });
            }}
            disabled={props.operationType === OperationType.VIEW}
          ></FormDropDown>
          {!props.isValidationSuccess &&
            !isValidSagemakerInstanceType(sagemakerInstanceType) && (
              <DisplayError
                operationType={props.operationType}
                message="field can't be empty"
              />
            )}

          <FormInput
            label="Instance Count"
            type="number"
            placeholder="Instance Count"
            value={sagemakerInstanceCount?.toString()}
            tooltipText="This is the instance count of the instance type specified while training."
            onChange={(event: any) => {
              setSagemakerInstanceCount(event.target.value);
              props.setService({
                ...props.service,
                sagemakerService: {
                  ...props.service.sagemakerService,
                  computeInfo: {
                    ...props.service.sagemakerService?.computeInfo,
                    instanceCount: event.target.value,
                  },
                },
              });
            }}
            disabled={props.operationType === OperationType.VIEW}
            required
          />
          {!props.isValidationSuccess &&
            !isValidSagemakerInstanceCount(sagemakerInstanceCount) && (
              <DisplayError
                operationType={props.operationType}
                message="field can't be empty or negative"
              />
            )}

          <FormInput
            label="Volume Size(in Gb)"
            type="number"
            placeholder="Volume Size"
            value={sagemakerVolumeSize?.toString()}
            tooltipText="Volume size"
            onChange={(event: any) => {
              setSagemakerVolumeSize(event.target.value);
              props.setService({
                ...props.service,
                sagemakerService: {
                  ...props.service.sagemakerService,
                  computeInfo: {
                    ...props.service.sagemakerService?.computeInfo,
                    volumeSizeInGB: event.target.value,
                  },
                },
              });
            }}
            disabled={props.operationType === OperationType.VIEW}
            required
          />
          {!props.isValidationSuccess &&
            !isValidSagemakerVolumeSize(sagemakerVolumeSize) && (
              <DisplayError
                operationType={props.operationType}
                message="field can't be empty or negative"
              />
            )}
        </Collapsible>
      </>
    );
  };

  /**
   * renders the view for Ecs Service
   * @returns JSX
   */
  const getEcsServiceComponent = () => {
    return <></>;
  };

  /**
   * get images based upon service type
   * @returns string[]
   */
  const getContainerNameOptions = () => {
    switch (serviceType) {
      case ServiceType.EMR:
        return [];
      case ServiceType.SAGEMAKER:
        return getModelTrainingImageOptions();
      case ServiceType.ECS:
        return getEcsImageOptions();
    }
  };

  /**
   * renders the container names into a drop down
   * @returns JSX
   */
  const getContainerName = () => {
    return (
      <>
        <FormDropDown
          label="Container Name"
          searchable={true}
          placeholder="Select image"
          name={"Container Name"}
          value={
            props.operationType === OperationType.VIEW
              ? getValueOrEmptyPlaceholder(containerName)
              : containerName
          }
          options={getContainerNameOptions()}
          onChange={handleContainerNameChange}
          disabled={props.operationType === OperationType.VIEW}
        ></FormDropDown>
        {!props.isValidationSuccess &&
          !isValidContainerName(containerName, serviceType as ServiceType) && (
            <DisplayError
              operationType={props.operationType}
              message="Please Select this field"
            />
          )}
      </>
    );
  };

  /**
   * Changes the container name & fetches corresponding images
   * @param event: input change event
   */
  const handleContainerNameChange = (event: any) => {
    setContainerArn("");
    setContainerName(event.target.value);
    props.setArtifactInfo({
      ...props.artifactInfo,
      containerInfo: {
        ...props.artifactInfo.containerInfo,
        imageContainer: {
          ...props.artifactInfo.containerInfo.imageContainer,
          containerName: event.target.value,
        },
      },
    });
  };

  const handleClusterTypeChange = (clusterType: any) => {
    let placeholderClusterName = ""
    let placeholderClusterConfig = "{}"
    if (EmrClusterType.DEFAULT === clusterType) {
      placeholderClusterName = DEFAULT_EMR_CLUSTER_NAME
      placeholderClusterConfig = "{}"
    } else if (EmrClusterType.ADHOC === clusterType) {
      placeholderClusterName = ADHOC_EMR_CLUSTER_NAME
      placeholderClusterConfig = getPrettyJsonString(JSON.stringify(initialEmrComputeConfig)) as string
    }
    setEmrClusterType(clusterType)
    setEmrClusterName(placeholderClusterName)
    setEmrClusterConfig(placeholderClusterConfig)
    props.setService({
      ...props.service,
      emrService: {
        ...props.service.emrService,
        emrClusterType: clusterType,
        computeInfo: {
          ...props.service.emrService?.computeInfo,
          clusterName: placeholderClusterName,
          clusterConfig: placeholderClusterConfig,
        },
      },
    });
  }

  /**
   * renders the available Arns in a drop down/input field
   * @returns JSX
   */
  const getContainerArn = () => {
    const containerArnOptions: any[] = [];
    containerArnInfo.trainingImages.imageList.forEach((arn: any) =>
      containerArnOptions.push({
        name: arn.imageTag,
        value: arn.imageTag,
      })
    );
    if (serviceType === ServiceType.ECS) {
      return (
        <>
          <KatLabel>Container ARN: </KatLabel>
          <FormInput value={containerArn} disabled={true}></FormInput>
        </>
      );
    } else {
      return (
        <>
          <KatLabel>Container ARN: </KatLabel>
          {containerName === CUSTOM_TRAINING_IMAGE ? (
            <FormInput
              value={containerArn}
              placeholder="Provide arn"
              disabled={props.operationType === OperationType.VIEW}
              onChange={(event: any) => {
                setContainerArn(event.target.value);
                props.setArtifactInfo({
                  ...props.artifactInfo,
                  containerInfo: {
                    ...props.artifactInfo.containerInfo,
                    imageContainer: {
                      ...props.artifactInfo.containerInfo.imageContainer,
                      containerArn: event.target.value,
                    },
                  },
                });
              }}
            ></FormInput>
          ) : containerArnOptions.length > 0 ? (
            <>
              <FormDropDown
                value={containerArn}
                options={containerArnOptions}
                disabled={props.operationType === OperationType.VIEW}
                placeholder="Select arn: "
                onChange={(event: any) => {
                  setContainerArn(event.target.value);
                  props.setArtifactInfo({
                    ...props.artifactInfo,
                    containerInfo: {
                      ...props.artifactInfo.containerInfo,
                      imageContainer: {
                        ...props.artifactInfo.containerInfo.imageContainer,
                        containerArn: event.target.value,
                      },
                    },
                  });
                }}
              />
              {!props.isValidationSuccess &&
                !isValidContainerArn(
                  containerArn,
                  serviceType as ServiceType
                ) && (
                  <DisplayError
                    operationType={props.operationType}
                    message="Please select this field"
                  />
                )}
            </>
          ) : containerArnInfo.startLoader ? (
            <KatSpinner />
          ) : (
            <KatFlashbar header="Note" description="No Images found" />
          )}
        </>
      );
    }
  };

  const isS3RepositoryFetchSuccess = () => {
    return getCodeRepo.codeRepositoryFetched && getCodeRepo.getCodeRepositorySuccess
  }

  const isS3RepositoryVersionEnabled = () => {
    return isS3RepositoryFetchSuccess() && getCodeRepo.repository.codeRepository.s3Repository.isAccessible &&
        getCodeRepo.repository.codeRepository.s3Repository.isVersionEnabled
  }

  const isS3RepositoryLoadFailure = () => {
    return getCodeRepo.codeRepositoryFetched && !getCodeRepo.getCodeRepositorySuccess
  }

  const handleRepositoryChange = (value: any) => {
    setCustomCodeRepository("")
    setExecutionCode({
      ...executionCode,
      codeUri: "",
      codeVersion: ""
    });
    setPreExecutionCode({
      ...preExecutionCode,
      codeUri: "",
      codeVersion: ""
    });
    setPostExecutionCode({
      ...postExecutionCode,
      codeUri: "",
      codeVersion: ""
    });
    props.setArtifactInfo({
      ...props.artifactInfo,
      codeInfo: {
        ...props.artifactInfo.codeInfo,
        codeRepository: {
          ...props.artifactInfo.codeInfo?.codeRepository,
          repositoryType: value,
          gitRepository:
              value === RepositoryType.GIT
                  ? {
                    packageName: "",
                    branchList: [""],
                  }
                  : null,
          s3Repository:
              value === RepositoryType.S3
                  ? {
                    bucketName: "",
                  }
                  : null,
        },
        executionInfo: {
          ...props.artifactInfo.codeInfo?.executionInfo,
          codeUri: "",
          codeVersion: ""
        },
        preExecutionInfo: {
          ...props.artifactInfo.codeInfo?.preExecutionInfo,
          codeUri: "",
          codeVersion: ""
        },
        postExecutionInfo: {
          ...props.artifactInfo.codeInfo?.postExecutionInfo,
          codeUri: "",
          codeVersion: ""
        },
      }
    });
  }

  const isDisabledCodeRepoUris = () => {
      switch (repositoryType) {
        case RepositoryType.GIT:
          return isEmpty(customCodeRepository) || isEmpty(gitBranchName)
        case RepositoryType.S3:
          return isEmpty(customCodeRepository)
        default:
          return true
      }
  }

  /**
   * Renders the available code repositories in a radio button group field, from which the custom code repository
   * can be chosen.
   */
  const getCodeRepositories = () => {
    if (props.operationType === OperationType.VIEW) {
      return (
        <>
          <FormInput
            type="text"
            value={getValueOrEmptyPlaceholder(customCodeRepository)}
            disabled={true}
          />
          {repositoryType === RepositoryType.GIT && (
            <FormInput
              label="Branch Name: "
              tooltipText="Branch for your git package"
              type="text"
              value={getValueOrEmptyPlaceholder(gitBranchName)}
              disabled={true}
            />
          )}
        </>
      );
    } else {
      return (
          <>
            <>
              {
                <div style={{maxWidth: "50%"}}>
                  {
                    (codeRepositoriesSelector.codeRepositoriesFetched && codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess) ?
                        (
                            codeRepoOptions.length > 0 ?
                                <>
                                  <FormDropDown
                                      searchable={true}
                                      placeholder="Select Repository"
                                      name={"customCodeRepository"}
                                      value={customCodeRepository}
                                      options={
                                        codeRepoOptions
                                      }
                                      onChange={(event: any) => {
                                        setCustomCodeRepository(event.target.value);
                                        props.setArtifactInfo({
                                          ...props.artifactInfo,
                                          codeInfo: {
                                            ...props.artifactInfo.codeInfo,
                                            codeRepository: {
                                              ...props.artifactInfo.codeInfo?.codeRepository,
                                              gitRepository:
                                                  repositoryType === RepositoryType.GIT
                                                      ? {
                                                        ...props.artifactInfo.codeInfo
                                                            .codeRepository.gitRepository,
                                                        packageName: event.target.value,
                                                      }
                                                      : null,
                                              s3Repository:
                                                  repositoryType === RepositoryType.S3
                                                      ? {
                                                        ...props.artifactInfo.codeInfo
                                                            .codeRepository.s3Repository,
                                                        bucketName: event.target.value,
                                                      }
                                                      : null,
                                            },
                                          },
                                        });
                                      }}
                                  />
                                  {!isEmpty(customCodeRepository) && repositoryType === RepositoryType.GIT && (
                                      <>
                                        <FormDropDown
                                            searchable={true}
                                            label="Branch: "
                                            placeholder="Select Branch"
                                            value={gitBranchName}
                                            options={branchOptions}
                                            onChange={(event: any) => {
                                              setGitBranchName(event.target.value);
                                              props.setArtifactInfo({
                                                ...props.artifactInfo,
                                                codeInfo: {
                                                  ...props.artifactInfo.codeInfo,
                                                  codeRepository: {
                                                    ...props.artifactInfo.codeInfo
                                                        ?.codeRepository,
                                                    gitRepository: {
                                                      ...props.artifactInfo.codeInfo
                                                          ?.codeRepository.gitRepository,
                                                      branchList: [event.target.value],
                                                    },
                                                  },
                                                },
                                              });
                                            }}
                                        ></FormDropDown>
                                      </>
                                  )}
                                </>
                                : (
                                    <KatFlashbar
                                        header="Note"
                                        description={
                                          repositoryType === RepositoryType.GIT
                                              ? NO_ONBOARDED_GIT_REPOSITORIES_MESSAGE
                                              : NO_ONBOARDED_S3_REPOSITORIES_MESSAGE
                                        }
                                    />
                                )
                        ) :
                        ((codeRepositoriesSelector.codeRepositoriesFetched && !codeRepositoriesSelector.fetchAllCodeRepositoriesSuccess) ?
                                <>
                                  {
                                    dispatch(showSnackBar("Error loading Code Repositories"))
                                  }
                                </> :
                                (codeRepositoriesSelector.startCodeRepositoriesLoad ?
                                        <KatSpinner/> : <></>
                                )
                        )
                  }
                </div>
              }
            </>
          </>
      );
    }
  };

  return (
    <>
        <Collapsible label="Service">
            <KatLabel tooltipText="Service Type">Service Type :&nbsp;</KatLabel>
            <KatRadiobuttonGroup
                name={"serviceType"}
                options={getServiceType()}
                value={serviceType}
                onChange={(event: any) => {
                    setServiceType(event.target.value);
                    props.setServiceType(event.target.value);
                    if (event.target.value === ServiceType.EMR) {
                      props.setService({
                        ...props.service,
                        emrService: {
                          ...props.service.emrService,
                          emrClusterType: emrClusterType,
                          computeInfo: {
                            clusterName: emrClusterName,
                            clusterConfig: emrClusterConfig,
                          },
                          serviceInfo: {
                            jobFlowStep: emrJobFlowStep,
                          },
                        },
                        sagemakerService: null,
                        ecsService: null,
                      });
                      props.setArtifactInfo({
                        ...props.artifactInfo,
                        containerInfo: null
                      });
                        setContainerType("");
                    } else {
                        if (event.target.value === ServiceType.SAGEMAKER) {
                            props.setService({
                                ...props.service,
                                sagemakerService: {
                                    ...props.service.sagemakerService,
                                    computeInfo: {
                                        volumeSizeInGB: sagemakerVolumeSize,
                                        instanceType: sagemakerInstanceType,
                                        instanceCount: sagemakerInstanceCount,
                                    },
                                    sagemakerFeature: "TRAINING",
                                },
                                emrService: null,
                                ecsService: null,
                            });
                            props.setArtifactInfo({
                                ...props.artifactInfo,
                                containerType: ContainerType.IMAGE,
                                containerInfo: {
                                    imageContainer: {},
                                },
                            });
                        } else if (event.target.value === ServiceType.ECS) {
                            props.setService({
                                ...props.service,
                                ecsService: {
                                    ...props.service.ecsService,
                                    computeInfo: {
                                        launchType: "FARGATE",
                                        instanceCount: "1",
                                    },
                                },
                                emrService: null,
                                sagemakerService: null,
                            });
                            setContainerName("PYTHON_DEFAULT");
                            let arn = isNonProdHostName(window.location.hostname)
                                ? "885253899147.dkr.ecr.us-east-1.amazonaws.com/expresso-python-default"
                                : "914822796687.dkr.ecr.us-east-1.amazonaws.com/expresso-python-default";
                            setContainerArn(arn);
                            props.setArtifactInfo({
                                ...props.artifactInfo,
                                containerType: ContainerType.IMAGE,
                                containerInfo: {
                                    imageContainer: {
                                        containerName: "PYTHON_DEFAULT",
                                        containerArn: arn,
                                    },
                                },
                            });
                        }
                        setContainerType(ContainerType.IMAGE);
                    }
                }}
                disabled={
                    props.serviceType
                        ? true
                        : props.operationType !== OperationType.CREATE
                }
            ></KatRadiobuttonGroup>
            {!props.isValidationSuccess && !isValidServiceType(serviceType) && (
                <DisplayError
                    operationType={props.operationType}
                    message="Please select this field"
                    marginTop={1}
                />
            )}
            <br/>
            {getServiceComponent()}
        </Collapsible>

        <Collapsible label="Artifact">
          <Collapsible label="Container Info">
            <KatLabel tooltipText="Container Type">
              Container Type :&nbsp;
            </KatLabel>

          <KatRadiobuttonGroup
            name={"containerType"}
            options={getContainerType()}
            value={containerType}
            onChange={(event: any) => {
              setContainerType(event.target.value);
              props.setArtifactInfo({
                ...props.artifactInfo,
                containerType: event.target.value,
              });
            }}
            // handle disable once VersionSet is supported
            disabled={true}
          ></KatRadiobuttonGroup>

            {!isEmpty(serviceType) &&
            serviceType !== ServiceType.EMR &&
            getContainerName()}

          {!isEmpty(serviceType) &&
            serviceType !== ServiceType.EMR &&
            getContainerArn()}
        </Collapsible>

          <br />

        <Collapsible label="Code Info">
          <KatLabel>Select code repo type: </KatLabel>

            <br />

            <KatRadiobuttonGroup
                name={"Repository Type"}
                options={getRepositoryTypeOptions()}
                value={repositoryType}
                onChange={(event: any) => {
                  handleRepositoryChange(event.target.value);
                  setRepositoryType(event.target.value);
                }}
                disabled={props.operationType === OperationType.VIEW}
            ></KatRadiobuttonGroup>
            {!props.isValidationSuccess &&
            !isValidCodeRepositoryType(repositoryType) && (
                <DisplayError
                    operationType={props.operationType}
                    message="Please fill this field"
                    marginTop={1}
                />
            )}

            <br />

            {!isEmpty(repositoryType) && (
                <>
                  <KatLabel
                      tooltipText={`Select repository whose code you want to be made available.`}
                  >
                    Repository :&nbsp;
                  </KatLabel>
                  {getCodeRepositories()}
                </>
            )}
          <KatDivider variant={"eastern"}/>
          {

            (repositoryType != RepositoryType.S3 || (isS3RepositoryVersionEnabled())) ?
                <>
                  <FormInput
                      label="Pre Execution Code Script Path"
                      type="text"
                      value={
                        props.operationType === OperationType.VIEW
                            ? getValueOrEmptyPlaceholder(preExecutionCode.codeUri)
                            : preExecutionCode.codeUri
                      }
                      tooltipText="Pre Execution Code Script Path"
                      onBlur={(event: any) => {
                        dispatch(getCodeVersions({
                              repositoryType: repositoryType,
                              scriptPath: event.target.value,
                              branch: (repositoryType == RepositoryType.GIT) ?
                                  (!isEmpty(gitBranchName) ? gitBranchName : DEFAULT_BRANCH) : undefined
                            }, customCodeRepository, JobStep.PRE_EXECUTION
                        ));
                      }}
                      onChange={(event: any) => {
                        setPreExecutionCode({
                          ...preExecutionCode,
                          codeUri: event.target.value,
                        });
                        props.setArtifactInfo({
                          ...props.artifactInfo,
                          codeInfo: {
                            ...props.artifactInfo.codeInfo,
                            preExecutionInfo: {
                              ...props.artifactInfo.codeInfo.preExecutionInfo,
                              codeUri: event.target.value
                            },
                          },
                        });
                      }}
                      disabled={props.operationType === OperationType.VIEW || isDisabledCodeRepoUris()}
                  />
                  {
                    !isEmpty(preExecutionCode.codeUri) ?
                        getCodeVersionsListDropDown(preExecutionCode.codeVersion, JobStep.PRE_EXECUTION, (event: any) => {
                          setIfMounted(
                              _isMounted.current, setPreExecutionCode, {
                                ...preExecutionCode,
                                codeVersion: extractVersionIdFromSignedUrl(event.target.value),
                              })
                          props.setArtifactInfo({
                            ...props.artifactInfo,
                            codeInfo: {
                              ...props.artifactInfo.codeInfo,
                              preExecutionInfo: {
                                ...props.artifactInfo.codeInfo.preExecutionInfo,
                                codeVersion: extractVersionIdFromSignedUrl(event.target.value)
                              },
                            },
                          });
                          if (event.target.value != LATEST_CODE_VERSION) {
                            window.open(event.target.value, '_blank')?.focus();
                          }
                        }) : null
                  }
                  <KatDivider variant={"eastern"}/>
                  <FormInput
                      label="Execution Code Script Path *"
                      type="text"
                      value={
                        props.operationType === OperationType.VIEW
                          ? getValueOrEmptyPlaceholder(executionCode.codeUri)
                          : executionCode.codeUri
                      }
                      tooltipText="Execution Code Script Path"
                      onBlur={(event: any) => {
                        dispatch(getCodeVersions({
                              repositoryType: repositoryType,
                              scriptPath: event.target.value,
                              branch: (repositoryType == RepositoryType.GIT) ?
                                  (!isEmpty(gitBranchName) ? gitBranchName : DEFAULT_BRANCH) : undefined
                            }, customCodeRepository, JobStep.EXECUTION
                        ));
                      }}
                      onChange={(event: any) => {
                        setExecutionCode({
                          ...executionCode,
                          codeUri: event.target.value,
                        });
                        props.setArtifactInfo({
                          ...props.artifactInfo,
                          codeInfo: {
                            ...props.artifactInfo.codeInfo,
                            executionInfo: {
                              ...props.artifactInfo.codeInfo.executionInfo,
                              codeUri: event.target.value
                            },
                          },
                        });
                      }}
                      disabled={props.operationType === OperationType.VIEW || isDisabledCodeRepoUris()}
                      required
                  />
                  {!props.isValidationSuccess &&
                  !isValidExecutionCode(executionCode.codeUri) && (
                      <DisplayError
                          operationType={props.operationType}
                          message="field can't be empty"
                      />
                  )}

                  {
                    !isEmpty(executionCode.codeUri) ?
                        getCodeVersionsListDropDown(
                            executionCode.codeVersion,
                            JobStep.EXECUTION, (event: any) => {
                          setIfMounted(
                              _isMounted.current, setExecutionCode, {
                                ...executionCode,
                                codeVersion: extractVersionIdFromSignedUrl(event.target.value),
                              })
                          props.setArtifactInfo({
                            ...props.artifactInfo,
                            codeInfo: {
                              ...props.artifactInfo.codeInfo,
                              executionInfo: {
                                ...props.artifactInfo.codeInfo.executionInfo,
                                codeVersion: extractVersionIdFromSignedUrl(event.target.value)
                              },
                            },
                          });
                          if (event.target.value != LATEST_CODE_VERSION) {
                            window.open(event.target.value, '_blank')?.focus();
                          }
                        }) : null
                  }
                  <KatDivider variant={"eastern"}/>
                  <FormInput
                      label="Post Execution Code Script Path"
                      type="text"
                      value={
                        props.operationType === OperationType.VIEW
                            ? getValueOrEmptyPlaceholder(postExecutionCode.codeUri)
                            : postExecutionCode.codeUri
                      }
                      tooltipText="Post Execution Code Script Path"
                      onBlur={(event: any) => {
                        dispatch(getCodeVersions({
                              repositoryType: repositoryType,
                              scriptPath: event.target.value,
                              branch: (repositoryType == RepositoryType.GIT) ?
                                  (!isEmpty(gitBranchName) ? gitBranchName : DEFAULT_BRANCH) : undefined
                            }, customCodeRepository, JobStep.POST_EXECUTION
                        ));
                      }}
                      onChange={(event: any) => {
                        setPostExecutionCode({
                          ...postExecutionCode,
                          codeUri: event.target.value,
                        });
                        props.setArtifactInfo({
                          ...props.artifactInfo,
                          codeInfo: {
                            ...props.artifactInfo.codeInfo,
                            postExecutionInfo: {
                              ...props.artifactInfo.codeInfo.postExecutionInfo,
                              codeUri: event.target.value
                            },
                          },
                        });
                      }}
                      disabled={props.operationType === OperationType.VIEW || isDisabledCodeRepoUris()}
                  />
                  {
                    !isEmpty(postExecutionCode.codeUri) ?
                        getCodeVersionsListDropDown(postExecutionCode.codeVersion, JobStep.POST_EXECUTION, (event: any) => {
                          setIfMounted(
                              _isMounted.current, setPostExecutionCode, {
                                ...postExecutionCode,
                                codeVersion: extractVersionIdFromSignedUrl(event.target.value),
                              })
                          props.setArtifactInfo({
                            ...props.artifactInfo,
                            codeInfo: {
                              ...props.artifactInfo.codeInfo,
                              postExecutionInfo: {
                                ...props.artifactInfo.codeInfo.postExecutionInfo,
                                codeVersion: extractVersionIdFromSignedUrl(event.target.value)
                              },
                            },
                          });
                          if (event.target.value != LATEST_CODE_VERSION) {
                            window.open(event.target.value, '_blank')?.focus();
                          }
                        }) : null
                  }
                  <KatDivider variant={"eastern"}/>
                </> :
                (
                    (repositoryType == RepositoryType.S3 && isS3RepositoryFetchSuccess()) ?
                        <>
                          <KatFlashbar header="Note" description="Repository doesnt have version enabled"/>
                        </> :
                        (
                            (repositoryType == RepositoryType.S3 && isS3RepositoryLoadFailure()) ?
                                <>
                                  {
                                    dispatch(showSnackBar("Error loading S3 Repository"))
                                  }
                                </> :
                                (
                                    !isEmpty(customCodeRepository) && <KatSpinner/>
                                )
                        )

                )
          }
        </Collapsible>
          <br/>

          <KatLabel tooltipText="Metadata">
            Metadata :&nbsp; &nbsp; &nbsp;
            <VStatusIndicator
                variant={isValidJSON(jobMetadata) ? "success" : "error"}
                label={"Metadata"}
            />
          </KatLabel>

          <JSONEditor
              fieldName={"Metadata"}
              width="400px"
              value={getValueOrDefaultJsonString(jobMetadata)}
              onChange={(name: any, value: any) => {
                setJobMetadata(getValueOrDefaultJsonString(value));
                props.setArtifactInfo({
                  ...props.artifactInfo,
                  jobMetadata: value,
                });
              }}
              readOnly={props.operationType === OperationType.VIEW}
          />
          <br/>

          <KatLabel tooltipText="Arguments">Arguments :</KatLabel>

          <JSONEditor
              fieldName={"Arguments"}
              width="400px"
              value={
                props.operationType === OperationType.VIEW
                    ? getValueOrEmptyPlaceholder(artifactArguments)
                    : artifactArguments
              }
              onChange={(name: any, value: any) => {
                setArtifactArguments(getEmptyStringValue(value));
                props.setArtifactInfo({
                  ...props.artifactInfo,
                  arguments: value,
                });
              }}
              readOnly={props.operationType === OperationType.VIEW}
          />
        </Collapsible>
      </>
  );
}