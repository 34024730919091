import { Failure, Success } from "../../../utils/action_utils";
import {
  apiGetPromise,
  apiPostPromise,
  apiPutPromise,
} from "../../../utils/api_handler";
import {
  getCreateJobTemplateEndpoint,
  getListJobTemplatesEndpoint,
  getUpdateJobTemplateEndpoint,
  getViewJobTemplateEndpoint,
} from "../../targets";
import { RequestStatus } from "../../../utils/model_utils";
import { isEmpty } from "../../../utils/common_utils";
import {
  ServiceType,
  Service,
  ArtifactInfo,
  ContainerType,
} from "../../../model/runways/job_model";
import {
  CREATE_JOB_TEMPLATE_FAILURE,
  CREATE_JOB_TEMPLATE_SUCCESS,
  ERROR_CREATING_JOB_TEMPLATE,
  ERROR_FETCHING_JOB_TEMPLATE,
  ERROR_LISTING_JOB_TEMPLATES,
  ERROR_UPDATING_JOB_TEMPLATE,
  FETCH_JOB_TEMPLATE_FAILURE,
  START_JOB_TEMPLATE_LOADER,
  FETCH_JOB_TEMPLATE_SUCCESS,
  LIST_JOB_TEMPLATES_FAILURE,
  LIST_JOB_TEMPLATES_SUCCESS,
  UPDATE_JOB_TEMPLATE_FAILURE,
  UPDATE_JOB_TEMPLATE_SUCCESS,
  FETCH_JOB_TEMPLATE_LATEST_VERSION_DATA_SUCCESS,
} from "../action_types";
import { showSnackBar } from "../commons/snack_bar_actions";

export const createJobTemplate =
  (
    templateName: string,
    requestedBy: string,
    serviceType: ServiceType,
    service: Service,
    artifactInfo: ArtifactInfo
  ) =>
  async (dispatch: any) => {
    dispatch({ type: START_JOB_TEMPLATE_LOADER });
    return new Promise(async (resolve, reject) => {
      try {
        const error = ERROR_CREATING_JOB_TEMPLATE;
        let createJobTemplatePayload: any = {
          templateName: templateName,
          requestedBy: requestedBy,
          serviceType: serviceType,
          service: service,
          artifactInfo: artifactInfo,
        };
        let response: any = await apiPostPromise(
          getCreateJobTemplateEndpoint(),
          createJobTemplatePayload
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase() &&
          !isEmpty(response.templateId)
        ) {
          createJobTemplatePayload.jobTemplateId = response.templateId;
          dispatch(
            Success(CREATE_JOB_TEMPLATE_SUCCESS, createJobTemplatePayload)
          );
          resolve(response.templateId);
        } else {
          dispatch(Failure(CREATE_JOB_TEMPLATE_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(CREATE_JOB_TEMPLATE_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const getJobTemplate =
  (jobTemplateId: string, version?: number | null, latestVersions?: number) =>
  async (dispatch: any) => {
    dispatch({ type: START_JOB_TEMPLATE_LOADER });
    return new Promise<void>(async (resolve, reject) => {
      try {
        const error = ERROR_FETCHING_JOB_TEMPLATE;
        let response: any = await apiGetPromise(
          getViewJobTemplateEndpoint(jobTemplateId),
          {
            version: isEmpty(version) ? null : version,
            latestVersions: isEmpty(latestVersions) ? null : latestVersions,
          }
        );
        if (!isEmpty(response) && !isEmpty(response.jobTemplate)) {
          dispatch(Success(FETCH_JOB_TEMPLATE_SUCCESS, response));
          resolve();
        } else {
          dispatch(Failure(FETCH_JOB_TEMPLATE_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(FETCH_JOB_TEMPLATE_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const updateJobTemplate =
  (
    jobTemplateId: string,
    requestedBy: string,
    service: Service,
    artifactInfo: ArtifactInfo
  ) =>
  async (dispatch: any) => {
    dispatch({ type: START_JOB_TEMPLATE_LOADER });
    return new Promise<void>(async (resolve, reject) => {
      try {
        const error = ERROR_UPDATING_JOB_TEMPLATE;
        let updateJobTemplatePayload = {
          requestedBy: requestedBy,
          service: service,
          artifactInfo: artifactInfo,
        };
        let response: any = await apiPutPromise(
          getUpdateJobTemplateEndpoint(jobTemplateId),
          updateJobTemplatePayload
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase()
        ) {
          dispatch(
            Success(UPDATE_JOB_TEMPLATE_SUCCESS, updateJobTemplatePayload)
          );
          resolve();
        } else {
          dispatch(Failure(UPDATE_JOB_TEMPLATE_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(UPDATE_JOB_TEMPLATE_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const listJobTemplates = () => async (dispatch: any) => {
  dispatch({ type: START_JOB_TEMPLATE_LOADER });
  return new Promise<void>(async (resolve, reject) => {
    try {
      const error = ERROR_LISTING_JOB_TEMPLATES;
      let response: any = await apiGetPromise(
        getListJobTemplatesEndpoint(),
        {}
      );
      if (!isEmpty(response)) {
        dispatch(Success(LIST_JOB_TEMPLATES_SUCCESS, response.jobTemplateList));
        resolve();
      } else {
        dispatch(Failure(LIST_JOB_TEMPLATES_FAILURE, error));
        dispatch(showSnackBar("Some error occurred! Please try again later"));
        reject(error);
      }
    } catch (err: any) {
      dispatch(Failure(LIST_JOB_TEMPLATES_FAILURE, err));
      dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
      reject(err);
    }
  });
};

export const getJobTemplateLatestVersionData =
  (jobTemplateId: string) => async (dispatch: any) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const error = ERROR_FETCHING_JOB_TEMPLATE;
        let response: any = await apiGetPromise(
          getViewJobTemplateEndpoint(jobTemplateId),
          {}
        );
        if (!isEmpty(response) && !isEmpty(response.jobTemplate)) {
          dispatch(
            Success(FETCH_JOB_TEMPLATE_LATEST_VERSION_DATA_SUCCESS, response)
          );
          resolve(response.jobTemplate);
        } else {
          dispatch(Failure(FETCH_JOB_TEMPLATE_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(FETCH_JOB_TEMPLATE_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };
