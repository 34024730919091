import React, {useRef, useState} from "react";
import {VCancelPopUpButton, VInput, VKey, VSubmitPopUpButton, VTable, VValue} from "src/view/style/view_config_styles";
import {TableBody, TableRow} from "src/view/style/table_styles";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {CancelIcon, colorWhite, UploadIcon} from "src/view/style/icons";
import {SetFunction} from "src/view/dataset/dataset_constants";
import {useDispatch, useSelector} from "react-redux";
import {registerCodeRepository} from "src/control/actions/codeRepository/code_repository_actions";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {RepositoryType} from "src/model/runways/job_model";

export interface GitRepoFormProps {
    isAlreadyExistingGitRepo: boolean,
    existingPackageName: string,
    setVisibility: SetFunction<boolean>,
    setRepoNameWrapper: SetFunction<string>,
    setBranchWrapper: SetFunction<string>
}

const GitRepoRegisterForm = (props: GitRepoFormProps) => {
    const dispatch = useDispatch();
    const userName = useSelector(usernameSelector);
    const [packageName, setPackageName] = useState(props.existingPackageName);
    const [branchName, setBranchName] = useState("mainline");
    const _isMounted = useRef(true);

    const isParamsValid = () => {
        return !isEmpty(packageName)
    }

    const handleCreate = () => {
        props.setRepoNameWrapper(packageName)
        props.setBranchWrapper(branchName)
        dispatch(registerCodeRepository({
            requestedBy: userName,
            codeRepository: {
                repositoryType: RepositoryType.GIT,
                gitRepository: {
                    packageName: packageName,
                    branchList: isEmpty(branchName) ? [] : [branchName]
                }
            }
        }))
    }

    return (
        <>
            <div className={"container"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <VTable>
                            <TableBody>
                                <TableRow>
                                    <VKey>Git Package Name *</VKey>
                                    <VValue>
                                        <VInput
                                            readonly={props.isAlreadyExistingGitRepo}
                                            required
                                            value={packageName}
                                            placeholder={"Package Name"}
                                            onChange={(event: any) => setIfMounted(
                                                _isMounted.current,
                                                setPackageName,
                                                event.target.value)
                                            }
                                        />
                                    </VValue>
                                </TableRow>
                                <TableRow>
                                    <VKey>New Branch Name(default: mainline) *</VKey>
                                    <VValue>
                                        <VInput
                                            required
                                            value={branchName}
                                            placeholder={"Branch Name"}
                                            onChange={(event: any) => setIfMounted(
                                                _isMounted.current,
                                                setBranchName,
                                                event.target.value)
                                            }
                                        />
                                    </VValue>
                                </TableRow>
                            </TableBody>
                        </VTable>
                    </div>
                </div>
                <br/>
            </div>
            <div slot="footer" className="kat-group-horizontal">
                <VCancelPopUpButton
                    onClick={() => props.setVisibility(false)}
                    variant={"danger"}
                >
                    <CancelIcon style={colorWhite} size={null}/>
                    &nbsp; &nbsp; Cancel
                </VCancelPopUpButton>
                <VSubmitPopUpButton onClick={handleCreate} disabled={!isParamsValid()}>
                    <UploadIcon style={colorWhite} size={null}/>
                    &nbsp;&nbsp; Create
                </VSubmitPopUpButton>
            </div>
        </>
    )
}

export default GitRepoRegisterForm