import {
    PopUp,
    VInput,
    VKey,
    VStatusIndicator,
    VTable,
    VValue
} from "src/view/style/view_config_styles";
import {TableBody, TableRow} from "src/view/style/table_styles";
import React from "react";
import {RepositoryType} from "src/model/runways/job_model";
import {SetFunction} from "src/view/dataset/dataset_constants";
import {useDispatch, useSelector} from "react-redux";
import {
    getCodeRepositorySelector
} from "src/control/selectors/codeRepository/code_repo_selectors";
import {KatBadge, KatSpinner} from "@amzn/katal-react";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

export interface ViewCodeRepoProps {
    visibility: boolean,
    setVisibilityForViewCodeRepo: SetFunction<boolean>
    repositoryData: any | {}
}

export default function ViewCodeRepo(props: ViewCodeRepoProps) {
    const ACCESSIBLE = "ACCESSIBLE"
    const INACCESSIBLE = "INACCESSIBLE"

    const ENABLED = "ENABLED"
    const DISABLED = "DISABLED"

    const dispatch = useDispatch();
    const viewCodeRepo = useSelector(getCodeRepositorySelector)

    const getGitRepoView = () => {
        return (
            <>
                <TableBody>
                    <TableRow>
                        <VKey>Git Package Name</VKey>
                        <VValue>
                            <VInput
                                readonly
                                value={props.repositoryData.codeRepository.gitRepository.packageName}
                                placeholder={"Package Name"}
                            />
                        </VValue>
                    </TableRow>
                    <TableRow>
                        <VKey>Branches</VKey>
                        <VValue>
                            <VInput
                                readonly
                                value={props.repositoryData.codeRepository.gitRepository.branchList.join(",")}
                                placeholder={"Branches"}
                            />
                        </VValue>
                    </TableRow>
                    <TableRow>
                        <VKey>Sync enabled</VKey>
                        <VValue>
                            <KatBadge
                                label={props.repositoryData.codeRepository.gitRepository.syncEnabled ? ENABLED : DISABLED}
                                type={props.repositoryData.codeRepository.gitRepository.syncEnabled ? "success" : "warning"}
                            />
                        </VValue>
                    </TableRow>
                </TableBody>

            </>
        )
    }

    const getS3RepoView = () => {
        return (
            <>
                {
                    (viewCodeRepo.codeRepositoryFetched && viewCodeRepo.getCodeRepositorySuccess) ?
                        <>
                            <TableBody>
                                <TableRow>
                                    <VKey>Bucket Name</VKey>
                                    <VValue>
                                        <VInput
                                            readonly
                                            value={viewCodeRepo.repository.codeRepository.s3Repository.bucketName}
                                            placeholder={"Package Name"}
                                        />
                                    </VValue>
                                </TableRow>
                                <TableRow>
                                    <VKey>Is Accessible</VKey>
                                    <VValue>
                                        <VInput
                                            readonly
                                            value={viewCodeRepo.repository.codeRepository.s3Repository.isAccessible ? ACCESSIBLE : INACCESSIBLE}
                                            placeholder={"Is Accessible"}
                                        />
                                    </VValue>
                                </TableRow>
                                <TableRow>
                                    <VKey>Is Version enabled</VKey>
                                    <VValue>
                                        <KatBadge
                                            label={viewCodeRepo.repository.codeRepository.s3Repository.isVersionEnabled ? ENABLED : DISABLED}
                                            type={viewCodeRepo.repository.codeRepository.s3Repository.isVersionEnabled ? "success" : "warning"}
                                        />
                                    </VValue>
                                </TableRow>
                            </TableBody>
                        </>
                        :

                        <>
                            {
                                (viewCodeRepo.codeRepositoryFetched && !viewCodeRepo.getCodeRepositorySuccess) ?
                                    <>
                                        {dispatch(showSnackBar("Error loading code repository"))}
                                    </>
                                    :
                                    <>
                                        {
                                            (viewCodeRepo.startCodeRepositoryFetch) &&
                                                <KatSpinner/>
                                        }
                                    </>

                            }
                        </>
                }
            </>
        )
    }

    const getRepoView = () => {
        switch (props.repositoryData.codeRepository.repositoryType) {
            case RepositoryType.GIT:
                return getGitRepoView()
            case RepositoryType.S3:
                return getS3RepoView()
        }
    }

    return (
        <>
            <PopUp visible={props.visibility}
                   onOpen={() => props.setVisibilityForViewCodeRepo(true)}
                   onClose={() => props.setVisibilityForViewCodeRepo(false)}
            >
                <span slot={"title"}>Code Repository</span>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <VTable>
                                {
                                    getRepoView()
                                }
                            </VTable>
                        </div>
                    </div>
                    <br/>
                </div>
            </PopUp>

        </>
    )
}