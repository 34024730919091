import {filterList} from "src/utils/list_utils";
import {readJSON} from "src/utils/json_utils";
import * as dataStudioMetadata from "src/view/notebooks/data/data_studio_metadata.json";
import {Failure, Request, Success} from "src/utils/action_utils";
import {
    CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE,
    CREATE_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS,
    DELETE_DATA_STUDIO_ADDON_FAILURE,
    DELETE_DATA_STUDIO_ADDON_SUCCESS,
    ERROR_CREATING_DATA_STUDIO_MANAGED_ENDPOINT,
    ERROR_DELETING_DATA_STUDIO_ADDON,
    ERROR_GETTING_DATA_STUDIO_MANAGED_ENDPOINT,
    ERROR_GETTING_DATA_STUDIO_ONBOARDING,
    ERROR_LISTING_DATA_STUDIO_ADDONS,
    ERROR_LISTING_DATA_STUDIO_ONBOARDING,
    FETCH_DATA_STUDIO_INFRA_FAILURE,
    FETCH_DATA_STUDIO_INFRA_SUCCESS,
    GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE,
    GET_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS,
    GET_DATA_STUDIO_ONBOARDING_FAILURE,
    GET_DATA_STUDIO_ONBOARDING_SUCCESS,
    LIST_DATA_STUDIO_ADDONS_FAILURE,
    LIST_DATA_STUDIO_ADDONS_SUCCESS,
    LIST_DATA_STUDIO_FAILURE,
    LIST_DATA_STUDIO_ONBOARDING_FAILURE,
    LIST_DATA_STUDIO_ONBOARDING_SUCCESS,
    LIST_DATA_STUDIO_REQUEST,
    LIST_DATA_STUDIO_SUCCESS
} from "src/control/actions/action_types";
import {apiDeletePromise, apiGetPromise, apiPostPromise} from "src/utils/api_handler";
import {
    getDataStudioAddonsTarget,
    getDataStudioAddonTargetWithAddonId,
    getDataStudioManagedEndpointTarget,
    getDataStudioOnboardingsTarget,
    getDataStudioOnboardingTarget
} from "src/control/targets";
import {
    DataStudioAddonType,
    DataStudioManagedEndpoint,
    DataStudioRegion,
    DataStudioTeamType
} from "src/model/notebooks/data_studio_models";
import {isEmpty} from "src/utils/common_utils";

//TODO : Remove static fetch methods once migration to api is complete

export const getDataStudioMetadata = (accountId: string) => {
    return filterList(readJSON(dataStudioMetadata), "accountId", accountId);
}

export const getDataStudioInfra = (accountId: string) => {
    let infraList: any[] = [];
    const filteredList = getDataStudioMetadata(accountId);
    filteredList.forEach((dataStudio: any) => {
        infraList.push(
            {
                studioName: dataStudio.studioName,
                infra: dataStudio.infra
            }
        )
    });
    return infraList;
}

export const listDataStudios = (accountId: string) => async (dispatch: any) => {
    dispatch(Request(LIST_DATA_STUDIO_REQUEST))
    return new Promise<void>(async (resolve, reject) => {
        try {
            const dataStudioDetails = getDataStudioMetadata(accountId)
            dispatch(Success(LIST_DATA_STUDIO_SUCCESS, dataStudioDetails))
            resolve();
            return;
        } catch (err: any) {
            dispatch(Failure(LIST_DATA_STUDIO_FAILURE, err));
            reject(err)
        }
    });
}

export const loadDataStudioInfra = (accountId: string) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            const dataStudioInfra = getDataStudioInfra(accountId)
            dispatch(Success(FETCH_DATA_STUDIO_INFRA_SUCCESS, dataStudioInfra))
            resolve();
            return;
        } catch (err: any) {
            dispatch(Failure(FETCH_DATA_STUDIO_INFRA_FAILURE, err));
            reject(err)
        }
    });
}

export const listDataStudioOnboardings = (accountId?: string, teamName?: string, teamType?: DataStudioTeamType, region?: DataStudioRegion) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getDataStudioOnboardingsTarget(), {
                accountId: accountId,
                teamIdentifier: teamName,
                region: region,
                teamType: teamType
            })
            if (!isEmpty(data) && !isEmpty(data["onboardings"])) {
                dispatch(Success(LIST_DATA_STUDIO_ONBOARDING_SUCCESS, data["onboardings"]))
                resolve();
            } else {
                dispatch(Failure(LIST_DATA_STUDIO_ONBOARDING_FAILURE, ERROR_LISTING_DATA_STUDIO_ONBOARDING))
                reject(ERROR_LISTING_DATA_STUDIO_ONBOARDING)
            }
            return;
        } catch (err: any) {
            dispatch(Failure(LIST_DATA_STUDIO_ONBOARDING_FAILURE, JSON.stringify(err.response.data.message)));
            reject(err)
        }
    });
}

export const listDataStudioAddons = (onboardingId: string) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getDataStudioAddonsTarget(), {
                onboardingId: onboardingId
            })
            if (!isEmpty(data) && !isEmpty(data["addons"])) {
                dispatch(Success(LIST_DATA_STUDIO_ADDONS_SUCCESS, data["addons"]))
                resolve();
            } else {
                dispatch(Failure(LIST_DATA_STUDIO_ADDONS_FAILURE, ERROR_LISTING_DATA_STUDIO_ADDONS))
                reject(ERROR_LISTING_DATA_STUDIO_ADDONS)
            }
        } catch (err: any) {
            dispatch(Failure(LIST_DATA_STUDIO_ADDONS_FAILURE, JSON.stringify(err.response.data.message)));
            reject(err)
        }
    });
}

export const deleteDataStudioAddon = (addonId: string, addonType: DataStudioAddonType) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let data = await apiDeletePromise(getDataStudioAddonTargetWithAddonId(addonId, addonType))
            if (!isEmpty(data)) {
                dispatch(Success(DELETE_DATA_STUDIO_ADDON_SUCCESS, data))
                resolve();
            } else {
                dispatch(Failure(DELETE_DATA_STUDIO_ADDON_FAILURE, ERROR_DELETING_DATA_STUDIO_ADDON))
                reject(ERROR_DELETING_DATA_STUDIO_ADDON)
            }
            return;
        } catch (err: any) {
            dispatch(Failure(DELETE_DATA_STUDIO_ADDON_FAILURE, JSON.stringify(err.response.data.message)));
            reject(err)
        }
    });
}

export const getDataStudioOnboarding = (onboardingId: string) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getDataStudioOnboardingTarget(), {
                onboardingId: onboardingId
            })
            if (!isEmpty(data) && !isEmpty(data["onboarding"])) {
                dispatch(Success(GET_DATA_STUDIO_ONBOARDING_SUCCESS, data["onboarding"]))
                resolve();
            } else {
                dispatch(Failure(GET_DATA_STUDIO_ONBOARDING_FAILURE, ERROR_GETTING_DATA_STUDIO_ONBOARDING))
                reject(ERROR_GETTING_DATA_STUDIO_ONBOARDING)
            }
            return;
        } catch (err: any) {
            dispatch(Failure(GET_DATA_STUDIO_ONBOARDING_FAILURE, JSON.stringify(err.response.data.message)));
            reject(err)
        }
    });
}

export const getDataStudioManagedEndpoint = (addonId: string) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getDataStudioManagedEndpointTarget(addonId))
            if (!isEmpty(data)) {
                dispatch(Success(GET_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS, data))
                resolve();
            } else {
                dispatch(Failure(GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE, ERROR_GETTING_DATA_STUDIO_MANAGED_ENDPOINT))
                reject(ERROR_GETTING_DATA_STUDIO_MANAGED_ENDPOINT)
            }
            return;
        } catch (err: any) {
            dispatch(Failure(GET_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE, JSON.stringify(err.response.data.message)));
            reject(err)
        }
    });
}

export const createDataStudioManagedEndpoint = (managedEndpoint: DataStudioManagedEndpoint) => async (dispatch: any) => {
    return new Promise<void>(async (resolve, reject) => {
        try {
            let data = await apiPostPromise(getDataStudioManagedEndpointTarget(), managedEndpoint)
            if (!isEmpty(data)) {
                dispatch(Success(CREATE_DATA_STUDIO_MANAGED_ENDPOINT_SUCCESS, data))
                resolve();
            } else {
                dispatch(Failure(CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE, ERROR_CREATING_DATA_STUDIO_MANAGED_ENDPOINT))
                reject(ERROR_CREATING_DATA_STUDIO_MANAGED_ENDPOINT)
            }
            return;
        } catch (err: any) {
            dispatch(Failure(CREATE_DATA_STUDIO_MANAGED_ENDPOINT_FAILURE, JSON.stringify(err.response.data.message)));
            reject(err)
        }
    });
}