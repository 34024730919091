import styled from "styled-components";

export const ErrorContainer = styled.div`
    margin-top: 25%;
    text-align: center;
    `

export const ErrorHeading = styled.h1`
    font-weight: 1000 !important;
    font-size: 3em !important;
    `