import React, { useEffect, useRef } from "react";
import { KatLabel, KatRadiobutton } from "@amzn/katal-react";
import {
  isValidLambdaArn,
  isValidSemanticValidatorLambdaName,
  setIfMounted,
} from "src/utils/common_utils";
import { VInput, VKey } from "../style/view_config_styles";
import {
  INVALID_LAMBDA_ARN_MESSAGE,
  OperationType,
  SetFunction,
} from "src/constant";
import { DisplayError } from "../commons/display_error";

export enum LambdaValidatorType {
  ONBOARDED = "ExpressoOnboarded", // User managed lambda validator onboarded to Expresso
  CUSTOM = "Custom", // User created, managed and owned lambda validator
  NONE = "None",
}

type CustomLambdaConfigValidatorProps = {
  operationType: OperationType;
  lambdaValidatorType: LambdaValidatorType;
  setLambdaValidatorType?: SetFunction<LambdaValidatorType>;
  customValidatorLambdaArn: string;
  setCustomValidatorLambdaArn?: SetFunction<string>;
  customValidatorLambdaName: string;
  setCustomValidatorLambdaName?: SetFunction<string>;
};

/**
 *
 * @param props CustomLambdaConfigValidatorProps
 * @returns JSX (Renders Validator View)
 */
export default function CustomLambdaConfigValidator(
  props: CustomLambdaConfigValidatorProps
): JSX.Element {
  const _isMounted = useRef(true);
  const INVALID_LAMBDA_NAME_MESSAGE = "Please provide Custom validator name in alphabets and numbers only, no special characters allowed in name!"
  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <VKey>
        <KatLabel>
          <b> Custom Lambda Validator: </b>
        </KatLabel>
      </VKey>
      <div
        style={{
          marginLeft: "3%",
          marginRight: "3%",
          marginBottom: "1%",
        }}
      >
        <KatRadiobutton
          className={"marginBox"}
          disabled={props.operationType === OperationType.VIEW}
          checked={props.lambdaValidatorType === LambdaValidatorType.NONE}
          value="None"
          onClick={() =>
            setIfMounted(
              _isMounted.current,
              props.setLambdaValidatorType,
              LambdaValidatorType.NONE
            )
          }
        >
          None
        </KatRadiobutton>
        <KatRadiobutton
          className={"marginBox"}
          disabled={props.operationType === OperationType.VIEW}
          checked={props.lambdaValidatorType === LambdaValidatorType.CUSTOM}
          value=""
          onClick={() =>
            setIfMounted(
              _isMounted.current,
              props.setLambdaValidatorType,
              LambdaValidatorType.CUSTOM
            )
          }
        >
          <KatLabel
            tooltipText="This is the lambda(Customer owned) ARN which will be invoked with the S3 location of the configuration body
                          to be validated, as a parameter. The parameter name will be - configBodyURI"
          >
            Bring your own Lambda Validator
          </KatLabel>
        </KatRadiobutton>
        {props.lambdaValidatorType === LambdaValidatorType.CUSTOM && (
          <>
            <VInput
              disabled={props.operationType === OperationType.VIEW}
              value={props.customValidatorLambdaArn}
              placeholder={INVALID_LAMBDA_ARN_MESSAGE}
              onChange={(event: any) =>
                setIfMounted(
                  _isMounted.current,
                  props.setCustomValidatorLambdaArn,
                  event.target.value
                )
              }
            />
            {!isValidLambdaArn(props.customValidatorLambdaArn) && (
              <DisplayError
                message={INVALID_LAMBDA_ARN_MESSAGE}
                marginTop={5}
                operationType={props.operationType}
              />
            )}
          </>
        )}
        <KatRadiobutton
          className={"marginBox"}
          disabled={props.operationType === OperationType.VIEW}
          checked={props.lambdaValidatorType === LambdaValidatorType.ONBOARDED}
          value=""
          onClick={() =>
            setIfMounted(
              _isMounted.current,
              props.setLambdaValidatorType,
              LambdaValidatorType.ONBOARDED
            )
          }
        >
          <KatLabel
            tooltipText="This is the Custom Validator Name which will be invoked with the S3 location of the configuration body
                        to be validated, as a parameter. The parameter name will be - configBodyURI and Validator Name"
          >
            User managed-Lambda validator onboarded to Expresso package
          </KatLabel>
        </KatRadiobutton>
        {props.lambdaValidatorType === LambdaValidatorType.ONBOARDED && (
          <>
            <VInput
              value={props.customValidatorLambdaName}
              disabled={props.operationType === OperationType.VIEW}
              placeholder={INVALID_LAMBDA_NAME_MESSAGE}
              onChange={(event: any) =>
                setIfMounted(
                  _isMounted.current,
                  props.setCustomValidatorLambdaName,
                  event.target.value
                )
              }
            />
            {!isValidSemanticValidatorLambdaName(
              props.customValidatorLambdaName
            ) && (
              <DisplayError
                message={INVALID_LAMBDA_NAME_MESSAGE}
                marginTop={5}
                operationType={props.operationType}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
