export const AUTHENTICATION_ERROR = new Error("Authentication Error, Ensure connection to VPN, and proceed to Midway");

export const ERROR_WHILE_FETCHING_ALL_TEAMS_INFO = new Error("Error while fetching team information (all)");
export const ERROR_TEAM_NOT_FOUND = new Error("Team not found");

export const ERROR_WHILE_FETCHING_USER_INFO = new Error("Error while fetching user information");

export const ERROR_WHILE_FETCHING_NAMESPACE_INFO = new Error("Error while fetching namespace info");
export const ERROR_NAMESPACES_NOT_FOUND = new Error("No namespaces found");

export const ERROR_EMPTY_TEAM_OR_NAMESPACE = new Error("Empty team or namespace information");

export const ERROR_WHILE_FETCHING_CONFIG_INFO = new Error("Error while fetching config information (all)");
export const ERROR_WHILE_FETCHING_DOMAIN_INFO = new Error("Error while fetching domain information (all)");

export const ERROR_OPERATION_NOT_SUPPORTED = new Error("Operation not supported")

export const ERROR_CONFIG_NOT_FOUND = new Error("Configuration not found");
export const ERROR_WHILE_FETCHING_VERSION_INFO = new Error("Error while fetching version information");
export const ERROR_UNABLE_TO_FETCH_CONFIG = new Error("Unable to fetch configuration information");
export const ERROR_CONFIG_VERSION_DOES_NOT_EXIST = new Error("Configuration version does not exist");
export const ERROR_CONFIG_BUFFER_SIZE_EXCEEDED = new Error("Configurations could not be loaded due to buffer size limit")

export const ERROR_WHILE_FETCHING_ALL_DATASETS_INFO = new Error("Error while fetching datasets (all)");