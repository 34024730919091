import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  KatCard,
  KatSpinner,
  KatIcon,
  KatTable,
  KatTableBody,
  KatTableCell,
  KatTableHead,
  KatTableRow,
  KatButton,
  KatPagination,
} from "@amzn/katal-react";
import React, { useEffect, useRef, useState } from "react";
import {
  getV2Runway,
  loadV2RunwayExecutions,
  loadRunwayInstanceExecutionMetrics,
} from "src/control/actions/runways/runway_actions";
import {
  listRunwayExecutionsSelector,
  listRunwayMetricsSelector,
  runwayDataSelector,
} from "src/control/selectors/runways/runway_selectors";
import { PaddedSpan, TableRowWithShadow } from "src/view/style/table_styles";
import { JobStatus } from "src/view/modelTraining/constants";
import { Regions } from "src/constant";
import { isEmpty, setIfMounted } from "src/utils/common_utils";
import { sortList } from "src/utils/list_utils";
import { FormInput } from "src/view/style/modelTraining/form_input_styles";
import styled from "styled-components";
import { PopUp } from "src/view/style/common_styles";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { convertToUTC, getDateTimeStringFromTimestamp, getLocalDateTimeStringFromTimestamp } from "src/utils/date_utils";
import {
  ErrorContainer,
  ErrorHeading,
} from "src/view/style/modelTraining/errors";
import { getParamFromQueryString } from "src/utils/url_utils";
import { getAWSRegion } from "src/utils/region_utils";
import { currentRegionSelector } from "src/control/selectors/commons/regions_selectors";
import { getBeatsDomain } from "src/utils/model_training_utils";
import { namespaceSelector } from "src/control/selectors/modelTraining/model_training_selectors";
import { Pipeline } from "./pipelines";
import { isNonProdHostName } from "./constants";
import { ServiceType } from "src/model/runways/job_model";
import { getStatusBadge } from "./utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {useHistory} from "react-router";

const moment = require("moment");

interface RunwayExecutions {
  executionTime: Date;
  executionStatus: JobStatus;
  stringTimestamp: string;
}

const ViewV2RunwayExecutions = () => {
  const params: any = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const TIMESTAMP_FORMAT = "YYYYMMDDhhmmss";
  const runwayData = useSelector(runwayDataSelector);
  const region = useSelector(currentRegionSelector);
  const fetchedNamespace = useSelector(namespaceSelector);

  const getStartDate = () => {
    const timeWindowStart = getParamFromQueryString(
      location.search,
      "timeWindowStart"
    );
    if (isEmpty(timeWindowStart)) {
      const startDate = convertToUTC(new Date());
      startDate.setDate(startDate.getDate() - 1);
      return startDate;
    } else {
      return moment(timeWindowStart, TIMESTAMP_FORMAT).toDate();
    }
  };

  const getEndDate = () => {
    const timeWindowEnd = getParamFromQueryString(
      location.search,
      "timeWindowEnd"
    );
    if (isEmpty(timeWindowEnd)) {
      return convertToUTC(new Date());
    } else {
      return moment(timeWindowEnd, TIMESTAMP_FORMAT).toDate();
    }
  };

  let listRunwaysExecution = useSelector(listRunwayExecutionsSelector);
  let listRunwaysExecutionMetrics = useSelector(listRunwayMetricsSelector);
  let _isMounted = useRef(true);

  useEffect(() => {
    _isMounted.current = true;
    return () => {
      _isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if(_isMounted.current) {
      history.replace({
        search: "",
      });
      dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
      dispatch(getV2Runway(params.runwayId));
    }
  }, [params.teamId, params.namespaceId, params.runwayId]);

  useEffect(() => {
    if (runwayData.runwayDAG.length > 0) {
      dispatch(loadV2RunwayExecutions(
        runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId,
        true
      ));
    }
  }, [runwayData]);

  const getCloudWatchLogs = (awsRegion: string, accountId: string) => {
    switch (runwayData.runwayDAG[runwayData.runwayDAG.length - 1].serviceType) {
      case ServiceType.EMR:
        return `https://s3.console.aws.amazon.com/s3/buckets/expresso-artifacts-${accountId}?region=${awsRegion}&prefix=jobs/${
          runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId
        }/executions/${logsRunway.stringTimestamp}/logs/`;
      case ServiceType.ECS:
        return `https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#logsV2:log-groups/log-group/ExpressoRunways$252Fimages$252FExpressoPythonDefaultImage$252Fjobs$252F${
          runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId
        }$252F${logsRunway.stringTimestamp}`;
      default:
        return `https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#logsV2:log-groups/log-group/ExpressoRunways$252Fjobs$252F${
          runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId
        }/log-events/${logsRunway.stringTimestamp}`;
    }
  };

  // Component States
  const [startTime, setStartTime] = useState(getStartDate());
  const [endTime, setEndTime] = useState(getEndDate());
  const [selectedPage, setSelectedPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [metricsPopUpVisible, setMetricsPopUpVisible] = useState(false);
  const [executionDetailsPopUpVisible, setExecutionDetailsPopUpVisible] =
    useState(false);
  const [logsRunway, setLogsRunway] = useState({
    executionId: "",
    executionTime: new Date(),
    executionStatus: "",
    stringTimestamp: "",
  });

  const runwayExecutions: RunwayExecutions[] = sortList(
    listRunwaysExecution.executionsList.map((execution: any) => {
      return {
        executionTime: execution.timestamp,
        executionStatus: execution.status,
        stringTimestamp: execution.timestamp,
      };
    }),
    "executionTime"
  ).reverse();

  // Component Specific Styles
  const InlineContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  `;
  const InlineItem = styled.div`
    padding: 15px;
  `;

  const ItemsPerPage = styled(FormInput)`
    width: 100px;
  `;

  const getRegion = () => {
    switch (region) {
      case "IAD":
        return "US";
      case "DUB":
        return "EU";
      case "SIN":
        return "SIN";
      case "PDX":
        return "FE";
      default:
        return "";
    }
  };

  const onPageChanged = (event: any) => {
    if (!event.detail.page) {
      return;
    }
    setIfMounted(_isMounted.current, setSelectedPage, event.detail.page);
  };

  const getNumberOfPages = (): number => {
    return (
      Math.floor(runwayExecutions.length / itemsPerPage) +
      (runwayExecutions.length % itemsPerPage > 0 ? 1 : 0)
    );
  };

  const fetchExecutionMetrics = (executionId: string) => {
    dispatch(loadRunwayInstanceExecutionMetrics(params.runwayId, executionId));
  };

  const constructBeatsUrl = () => {
    //TODO: to be replaced by url that supports schedulerId
    return `https://${getBeatsDomain(region)}/job/Expresso${
      isNonProdHostName(window.location.hostname) ? "Gamma" : ""
    }_${runwayData.domain === "PROD" ? "Prod" : "Gamma"}_${getRegion()}_${
      fetchedNamespace.namespaceData.namespace
    }/${runwayData.runwayName}_${
      runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobName
    }/parentJobList`;
  };

  const getExecutionDetailsInfoPopUp = () => {
    const accountId: string =
      fetchedNamespace.namespaceData.accountTypeMap[
        runwayData.domain.toLowerCase()
      ];
    const awsRegion: string = getAWSRegion(
      Regions[region as keyof typeof Regions]
    );

    return (
      <>
        <PopUp
          visible={executionDetailsPopUpVisible}
          onOpen={(event: any) => {
            setExecutionDetailsPopUpVisible(true);
          }}
          onClose={(event: any) => {
            setExecutionDetailsPopUpVisible(false);
          }}
        >
          <span slot={"title"}>View Details</span>
          <KatCard>
            <span slot="subtitle">Step 1. Sign in to the AWS account</span>
            <KatButton
              label={`Sign in to AWS account - ${accountId}`}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                window
                  .open(
                    `https://conduit.security.a2z.com/accounts/aws/${accountId}`,
                    "_blank"
                  )
                  ?.focus();
              }}
            />
          </KatCard>
          <div />
          <br />
          <KatCard>
            <span slot="subtitle">Step 2. View Execution Details</span>
            <KatButton
              label={`CloudWatch Logs`}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                window.open(getCloudWatchLogs(awsRegion, accountId))?.focus();
              }}
            />
            <div />
            <br />
            <KatButton
              label={`CloudWatch Metrics`}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                window
                  .open(
                    `https://console.aws.amazon.com/cloudwatch/home?region=${awsRegion}#dashboards:name=expresso-runway-${
                      params.runwayId
                    };start=${logsRunway.executionTime.toISOString()};end=${logsRunway.executionTime.toISOString()}`,
                    "_blank"
                  )
                  ?.focus();
              }}
            />
            <div />
            <br />
          </KatCard>
        </PopUp>
      </>
    );
  };

  /**
   * Pop-up view for metrics.
   */
  const getMetricsPopUp = () => {
    return (
      <>
        <PopUp
          visible={metricsPopUpVisible}
          onOpen={(event: any) => {
            setIfMounted(_isMounted.current, setMetricsPopUpVisible, true);
          }}
          onClose={(event: any) => {
            setIfMounted(_isMounted.current, setMetricsPopUpVisible, false);
          }}
        >
          <span slot={"title"}>Metrics</span>
          <p>
            {listRunwaysExecutionMetrics.runwaysExecutionMetricsFetched &&
            listRunwaysExecutionMetrics.fetchRunwaysExecutionMetricsSuccess ? (
              <KatTable>
                <KatTableHead>
                  <KatTableRow>
                    <KatTableCell>Metric Key</KatTableCell>
                    <KatTableCell>Metric Value</KatTableCell>
                  </KatTableRow>
                </KatTableHead>
                <KatTableBody>
                  {!isEmpty(listRunwaysExecutionMetrics.metricData)
                    ? Object.keys(listRunwaysExecutionMetrics.metricData).map(
                        (key) => {
                          return (
                            <>
                              <TableRowWithShadow key={key}>
                                <KatTableCell>{key}</KatTableCell>
                                <KatTableCell>
                                  {listRunwaysExecutionMetrics.metricData[key]}
                                </KatTableCell>
                              </TableRowWithShadow>
                            </>
                          );
                        }
                      )
                    : null}
                </KatTableBody>
              </KatTable>
            ) : listRunwaysExecutionMetrics.runwaysExecutionMetricsFetched &&
              !listRunwaysExecutionMetrics.fetchRunwaysExecutionMetricsSuccess ? (
              <>
                <ErrorContainer>
                  <ErrorHeading>
                    Error loading runway executions Metrics
                  </ErrorHeading>
                </ErrorContainer>
              </>
            ) : (
              <KatSpinner />
            )}
          </p>
        </PopUp>
      </>
    );
  };

  /**
   * Executions table row.
   * @param runwayExecution
   */
  const getRow = (runwayExecution: RunwayExecutions) => {
    return (
      <TableRowWithShadow>
        <KatTableCell>
          <div style={{ display: "flex" }}>
            <div>
              GMT:&nbsp;
              {getDateTimeStringFromTimestamp(String(runwayExecution.executionTime))}
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
            <div>
              Local:&nbsp;
              {getLocalDateTimeStringFromTimestamp(String(runwayExecution.executionTime))}
            </div>
          </div>
        </KatTableCell>
        <KatTableCell>
          {getStatusBadge(runwayExecution.executionStatus)}
        </KatTableCell>
        <KatTableCell>
          <PaddedSpan>
            <KatButton
              label="View Details"
              size="small"
              variant="primary"
              onClick={() => {
                setIfMounted(
                  _isMounted.current,
                  setLogsRunway,
                  runwayExecution
                );
                setIfMounted(
                  _isMounted.current,
                  setExecutionDetailsPopUpVisible,
                  true
                );
              }}
            >
              <KatIcon size="small" slot="icon" name="info-circle" />
            </KatButton>
          </PaddedSpan>
          {/*Opening link in new page as this is a beats link*/}
          <PaddedSpan>
            <a
              href={`${constructBeatsUrl()}?start=${
                runwayExecution.stringTimestamp
              }&end=${runwayExecution.stringTimestamp}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <KatButton
                label="View Instance Executions"
                size="small"
                variant="primary"
                disabled={
                  !runwayData ||
                  isEmpty(
                    runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId
                  )
                }
              >
                <KatIcon size="small" slot="icon" name="info-circle" />
              </KatButton>
            </a>
          </PaddedSpan>
        </KatTableCell>
      </TableRowWithShadow>
    );
  };

  /**
   * Runway executions table head
   */
  const getExecutionsTableHead = () => {
    return (
      <>
        <InlineContainer>
          <InlineItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="Start Timestamp(UTC)"
                value={startTime}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) setStartTime(date);
                }}
                format="yyyy/MM/dd HH:mm"
              />
            </MuiPickersUtilsProvider>
          </InlineItem>
          <InlineItem>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="End Timestamp(UTC)"
                value={endTime}
                onChange={(date: MaterialUiPickersDate) => {
                  if (date) setEndTime(date);
                }}
                format="yyyy/MM/dd HH:mm"
              />
            </MuiPickersUtilsProvider>
          </InlineItem>
          <InlineItem>
            <KatButton
              label={"Show"}
              size="base"
              variant="primary"
              onClick={(event: any) => {
                setIfMounted(_isMounted.current, setSelectedPage, 1);
                dispatch(
                  loadV2RunwayExecutions(
                    runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId,
                    true,
                    moment(startTime).format(TIMESTAMP_FORMAT),
                    moment(endTime).format(TIMESTAMP_FORMAT)
                  )
                );
              }}
            />
          </InlineItem>
        </InlineContainer>
      </>
    );
  };

  /**
   * Runway executions table
   */
  const getExecutionTable = () => {
    return (
      <>
        <KatTable>
          <KatTableHead>
            <KatTableRow>
              <KatTableCell>Schedule Time</KatTableCell>
              <KatTableCell>Execution Status</KatTableCell>
              <KatTableCell>Actions</KatTableCell>
            </KatTableRow>
          </KatTableHead>
          <KatTableBody>
            {listRunwaysExecution.runwaysExecutionsFetched &&
            listRunwaysExecution.fetchAllExecutionsSuccess ? (
              <>
                {runwayExecutions.length > 0 ? (
                  runwayExecutions
                    .slice(
                      itemsPerPage * (selectedPage - 1),
                      itemsPerPage * selectedPage
                    )
                    .map(getRow)
                ) : (
                  <ErrorContainer>
                    <ErrorHeading>
                      No Executions in given time range
                    </ErrorHeading>
                  </ErrorContainer>
                )}
              </>
            ) : listRunwaysExecution.runwaysExecutionsFetched &&
              !listRunwaysExecution.fetchAllExecutionsSuccess ? (
              <>
                <ErrorContainer>
                  <ErrorHeading>Error loading runway Executions</ErrorHeading>
                </ErrorContainer>
              </>
            ) : (
              <KatSpinner />
            )}
          </KatTableBody>
        </KatTable>
      </>
    );
  };

  const getValueOrEmptyPlaceholder = (value: any) => {
    return !isEmpty(value) ? value : "-";
  };

  /**
   * Runway executions table footer.
   */
  const getExecutionsTableFooter = () => {
    return (
      <>
        <InlineContainer>
          <KatPagination
            onChange={onPageChanged}
            totalItems={runwayExecutions.length}
            itemsPerPage={itemsPerPage}
            page={selectedPage}
          />
          {selectedPage == getNumberOfPages() &&
          listRunwaysExecution.loadMore ? (
            <KatButton
              label={"Load More"}
              size="small"
              variant="primary"
              onClick={(event: any) => {
                dispatch(
                  loadV2RunwayExecutions(
                    runwayData.runwayDAG[runwayData.runwayDAG.length - 1].jobId,
                    false,
                    moment(
                      runwayExecutions[runwayExecutions.length - 1]
                        .executionTime
                    ).format(TIMESTAMP_FORMAT),
                    moment(endTime).format(TIMESTAMP_FORMAT)
                  )
                );
              }}
            />
          ) : null}
          <ItemsPerPage
            value={itemsPerPage.toString()}
            type="number"
            label="Items per page"
            name="itemsPerPage"
            onChange={(event: any) => {
              setIfMounted(
                _isMounted.current,
                setItemsPerPage,
                parseInt(event.target.value)
              );
            }}
          />
        </InlineContainer>
      </>
    );
  };

  return (
    <>
      {getMetricsPopUp()}
      {getExecutionDetailsInfoPopUp()}
      {
        fetchedNamespace.namespaceData.namespace && runwayData.runwayName ?
        <>
            <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace} runwayName={runwayData.runwayName}></BreadCrumb>
            <KatCard>
              <KatCard>
                <span slot="title"> Runway </span>
                <FormInput
                  label="Runway Name"
                  type="text"
                  placeholder="Runway name"
                  value={runwayData.runwayName}
                  tooltipText="Runway name"
                  disabled={true}
                />
                <FormInput
                  label="Created By"
                  type="text"
                  placeholder="Created By"
                  value={runwayData.createdBy}
                  tooltipText="Creator of the runway"
                  disabled
                />
                <FormInput
                  label="Creation time"
                  type="text"
                  placeholder="Creation time"
                  value={runwayData.creationTime}
                  tooltipText="Time of creation of the runway"
                  disabled
                />
                <FormInput
                  label="Domain"
                  type="text"
                  placeholder="Domain"
                  value={getValueOrEmptyPlaceholder(runwayData.domain)}
                  tooltipText="Domain of the runway."
                  disabled
                />
              </KatCard>
              <KatCard>
                Jobs&nbsp;:
                <Pipeline
                  jobData={runwayData.runwayDAG}
                  isViewExecutions={true}
                ></Pipeline>
              </KatCard>
              <KatCard>
                <span slot="title">Runway Instances</span>
                {getExecutionsTableHead()}
                {getExecutionTable()}
                {getExecutionsTableFooter()}
              </KatCard>
            </KatCard>
        </> : <KatSpinner/>
      }
    </>
  );
};

export default ViewV2RunwayExecutions;
