import styled from "styled-components";
import {KatDropdown, KatInput} from "@amzn/katal-react";

export const FormInput = styled(KatInput)`
        width: 400px;
        margin: 8px 0;
        box-sizing: border-box;
    `
export const FormDropDown = styled(KatDropdown)`
        width: 400px;
        margin: 8px 0;
        box-sizing: border-box;
    `

export const PipelineFormInput = styled(KatInput)`
    width: 20px;
    margin: 8px 0;
    box-sizing: border-box;
`