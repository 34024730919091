export const Request = (type: any) => {
    return {
        type: type
    }
}

export const Success = (type: any, payload: any) => {
    return {
        type: type,
        payload: payload
    }
}

export const Failure = (type: any, error: any) => {
    return {
        type: type,
        error: error
    }
}

export const Event = (type: any) => {
    return {
        type: type
    }
}

export const sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}