import React from 'react'
import {KatRadiobuttonGroup, KatButton, KatCheckbox, KatInput, KatIcon, KatSpinner, KatLabel} from "@amzn/katal-react";
import {apiPostCallback} from 'src/utils/api_handler'
import { lookupKeysList, RealTimeService } from '../constants';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCreateFeatureGenerationJobTarget } from 'src/control/targets';

export class FeatureGenerationJobForm extends React.Component<any, any> {
    _mounted: boolean = false
    
    constructor(props: any) {
        super(props);
        this.state = {
            jobName: 'my_new_feature',
            loadInServiceFleet: false,
            realTimeService: '',
            lookupKey: 'ip',
            namespaceId: this.props.match.params.namespaceId,
            teamId: this.props.match.params.teamId,
            servicesList: [
                {
                    'value': RealTimeService.Bromine.toString(),
                    'label': RealTimeService.Bromine.toString(),
                },
                {
                    'value': RealTimeService.Chlorine.toString(),
                    'label': RealTimeService.Chlorine.toString(),
                },
                {
                    'value': RealTimeService.Fluorine.toString(),
                    'label': RealTimeService.Fluorine.toString(),
                    'disabled': true,
                },
            ],
            lookupKeysList: lookupKeysList,
            displayApiLoading: false,
            displaySuccessMessage: false,
            displayErrorMessage: false,
            requestedBy: this.props.username,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }
    componentDidMount() {
        this._mounted = true
    }
    componentWillUnmount() {
        this._mounted = false
    }
    private handleChange = (name: string, value: any) => {
        this.setState({ [name]: value })
    }
    private handleSubmit = (event: any) => {
        event.preventDefault()
        this._mounted && this.setState({'displayApiLoading': true})
        apiPostCallback(getCreateFeatureGenerationJobTarget(this.state.namespaceId), {
            ...this.state,
        }, (response: any) => {
            this._mounted && this.setState({'displaySuccessMessage': true, 'displayApiLoading': false})
        }, (error: any) => {
            this._mounted && this.setState({'displayErrorMessage': true, 'displayApiLoading': false})
        })
    }

    render() {
        return (
            <>
                <h1>{`Create Job`}</h1>
                <form onSubmit={(event) => this.handleSubmit(event)}>
                    <KatInput
                    value={this.state.jobName}
                    type="text"
                    label="Name"
                    name="jobName"
                    onInput={(event: any) => this.handleChange(event.currentTarget.name,event.currentTarget.value)}
                    />
                    &nbsp;
                    <KatRadiobuttonGroup
                    name='lookupKey'
                    value={this.state.lookupKey}
                    label='Select the lookup key for the feature generation job:'
                    options={this.state.lookupKeysList}
                    onChange={(event: any) => this.handleChange(event.currentTarget.name,event.currentTarget.value)}
                    />
                    &nbsp;
                    <br/>
                    <KatLabel
                    text="Need a different lookup key?"
                    tooltipText="Onboarding new lookup keys is a manual process. Please reach out to SQ Adrunway Experimentation team for more information."
                    />
                    &nbsp;
                    <br/>
                    &nbsp;
                    <KatCheckbox
                        label='Load in real time service?'
                        name='loadInServiceFleet'
                        onChange={(event: any) => this.handleChange(event.currentTarget.name,event.currentTarget.checked)}
                    />
                    &nbsp;
                    {this.state.loadInServiceFleet &&
                    <KatRadiobuttonGroup
                    name='realTimeService'
                    value={this.state.realTimeService}
                    label='Select a service to load the feature into:'
                    options={this.state.servicesList}
                    onChange={(event: any) => this.handleChange(event.currentTarget.name,event.currentTarget.value)}
                    />}
                    &nbsp;
                    {this.state.displaySuccessMessage &&
                    <kat-alert header="Success" description={`Created job ${this.state.jobName}`} variant="success"/>}
                    {this.state.displayErrorMessage &&
                    <kat-alert header="Server Error" description="Could not create job." variant="danger"/>}
                    &nbsp;
                    {this.state.displayApiLoading && <KatSpinner/>}
                    <br/>
                    &nbsp;
                    <KatButton type='submit' label="Create Job"/>
                    &nbsp;
                </form>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    return {
      username: state.user.username
    }
}

export default withRouter(connect(mapStateToProps)(FeatureGenerationJobForm))