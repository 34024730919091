export const configsInitialState = {
    configList: [],
    domainList: [],
    selectedConfig: null,
    fetchedConfig: {
        configBody: null,
        configMetadata: {
            configId: "",
            teamId: "" ,
            namespaceId: "",
            serviceName: "",
            realm: "",
            applicationRealm: "",
            domain: "",
            name: "",
            description: "",
            version: "",
            jsonConfig: ""
        }
    },
    fetchConfigValidation: {
        validationData: {
            validationId: "",
            configBodyURI: "",
            configId: "",
            requestedBy: "",
            creationTime: "",
            validationResults: []
        },
        startFetchValidationInfoLoader: false,
        validationInfoFetched: false,
        validationInfoFetchSuccess: false
    },
    startConfigValidation: {
        validationId: "",
        startConfigValidationLoader: false,
        validationTriggered: false,
        validationStartSuccess: false
    },
    versions: [],
    updateConfigMetadata: ""
}

export const namespaceInitialState = {
    namespaceList: [],
}
