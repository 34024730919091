import {REFRESH_STATES, START_FETCH_ALL_CODE_REPOSITORIES_LOADER} from "src/control/actions/action_types";
import {loadCodeRepositories} from "src/control/actions/codeRepository/code_repository_actions";

const loadCodeRepositoriesFlow = ({dispatch}: any) => (next: any) => async (action: any) => {
    const nextAction = next(action)
    if (action.type === REFRESH_STATES) {
        if (window.location.pathname.endsWith("/codeRepositories")) {
            dispatch({type: START_FETCH_ALL_CODE_REPOSITORIES_LOADER})
            dispatch(loadCodeRepositories({
                // Default - GIT
                    repositoryType: "GIT"
                }
            ))
        }

    }
    return nextAction
}

export default [
    loadCodeRepositoriesFlow
]