import { ServiceType } from "src/model/runways/job_model";
import { isEmpty } from "../../../utils/common_utils";
import {
  isValidPreRequisite,
  isValidServiceType,
} from "./job_components_validations";

export const isValidJobTemplateName = (templateName: string) => {
  return !isEmpty(templateName);
};

export const validatePrerequisites = (payload: any) => {
  return (
    isValidJobTemplateName(payload.templateName) &&
    isValidServiceType(payload.serviceType as ServiceType) &&
    isValidPreRequisite(payload)
  );
};
