import {
    DELETE_NOTEBOOK_FAILURE,
    DELETE_NOTEBOOK_SUCCESS,
    FETCH_ALL_EXPERIMENTS_FAILURE,
    FETCH_ALL_EXPERIMENTS_SUCCESS,
    FETCH_CLUSTER_STATUS_INIT,
    FETCH_CLUSTER_STATUS_FAILURE,
    FETCH_CLUSTER_STATUS_SUCCESS,
    FETCH_EXPERIMENT_FAILURE,
    FETCH_EXPERIMENT_SUCCESS,
    LIST_EXPERIMENTS_LOADER,
    RESTART_NOTEBOOK_FAILURE,
    RESTART_NOTEBOOK_SUCCESS,
    SET_EXPERIMENT_OPERATION_STARTED,
    START_CREATE_EXPERIMENT_LOADER,
    START_UPDATE_EXPERIMENT_LOADER,
    UPDATE_CREATE_EXPERIMENT_ON_FAILURE,
    UPDATE_CREATE_EXPERIMENT_ON_SUCCESS,
    UPDATE_EXPERIMENT_FAILURE,
    UPDATE_EXPERIMENT_FLOW_STARTED
} from "src/control/actions/action_types";
import {experimentInitialState} from "src/model/modelTraining/model_training_models"
import {Status} from "src/view/modelTraining/constants";

export default function (state = experimentInitialState, action: any) {
    switch (action.type) {
        case START_CREATE_EXPERIMENT_LOADER:
            return {
                ...state,
                createExperiment: {
                    ...state.createExperiment,
                    showCreateExperimentLoading: true,
                    showCreateExperimentSuccess: false,
                    showCreateExperimentDisplayMessage: false,
                }
            }
        case UPDATE_CREATE_EXPERIMENT_ON_SUCCESS:
            return {
                ...state,
                createExperiment: {
                    ...state.createExperiment,
                    showCreateExperimentLoading: false,
                    showCreateExperimentSuccess: true,
                    showCreateExperimentDisplayMessage: true,
                    createExperimentMessage: action.payload.createExperimentMessage
                }
            }
        case UPDATE_CREATE_EXPERIMENT_ON_FAILURE:
            return {
                ...state,
                createExperiment: {
                    ...state.createExperiment,
                    showCreateExperimentLoading: false,
                    showCreateExperimentSuccess: false,
                    showCreateExperimentDisplayMessage: true,
                    createExperimentMessage: action.payload.createExperimentMessage
                }
            }
        case START_UPDATE_EXPERIMENT_LOADER:
            return {
                ...state,
                editExperiment: {
                    ...state.editExperiment,
                    showUpdateExperimentLoading: true,
                    showUpdateExperimentDisplayMessage: false,
                    showUpdateExperimentSuccess: false
                }
            }
        case FETCH_EXPERIMENT_SUCCESS:
            return {
                ...state,
                fetchedExperiment: {
                    ...experimentInitialState.fetchedExperiment,
                    experimentData: {
                        ...experimentInitialState.fetchedExperiment.experimentData,
                        ...action.payload
                    },
                    experimentFetched: true,
                    experimentFetchSuccess: true
                }
            }
        case FETCH_EXPERIMENT_FAILURE:
            return {
                ...state,
                fetchedExperiment: {
                    ...experimentInitialState.fetchedExperiment,
                    experimentFetched: true,
                    experimentFetchSuccess: false
                }
            }
        case UPDATE_EXPERIMENT_FLOW_STARTED:
            return {
                ...state,
                editExperiment: {
                    ...state.editExperiment,
                    showUpdateExperimentLoading: false,
                    showUpdateExperimentSuccess: true,
                    showUpdateExperimentDisplayMessage: true,
                    updateExperimentMessage: action.payload.updateExperimentMessage
                }
            }
        case UPDATE_EXPERIMENT_FAILURE:
            return {
                ...state,
                editExperiment: {
                    ...state.editExperiment,
                    showUpdateExperimentLoading: false,
                    showUpdateExperimentSuccess: false,
                    showUpdateExperimentDisplayMessage: true,
                    updateExperimentMessage: action.error
                }
            }
        // Action to Mark operation is started and to avoid double triggering of actions in list experiments page
        case SET_EXPERIMENT_OPERATION_STARTED:
            const newExperimentList: any[] = []
            state.listExperiments.experiments.map((experiment: any) => {
                let newExperiment = experiment
                if (experiment.experimentName == action.payload.experimentName) {
                    newExperiment = {
                        ...experiment,
                        operationStarted: true
                    }
                }
                newExperimentList.push(newExperiment)
            })
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    experiments: newExperimentList
                }
            }
        case LIST_EXPERIMENTS_LOADER:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    showLoader: true,
                    showMessage: false,
                    isSuccess: false,
                    message: ""
                }
            }
        case FETCH_ALL_EXPERIMENTS_SUCCESS:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    experiments: action.payload,
                    tableStatus: Status.InService
                }
            }
        case FETCH_ALL_EXPERIMENTS_FAILURE:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    tableStatus: Status.ERROR
                }
            }
        case FETCH_CLUSTER_STATUS_INIT:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    clusterFetchError: false,
                    clusterStatusForExperiment: ""
                }
            }

        case FETCH_CLUSTER_STATUS_SUCCESS:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    clusterStatusForExperiment: action.payload.clusterStatus,
                    clusterFetchError: false,
                }
            }
        case FETCH_CLUSTER_STATUS_FAILURE:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    clusterStatusForExperiment: "",
                    clusterFetchError: true,
                }
            }
        case RESTART_NOTEBOOK_SUCCESS:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    showLoader: false,
                    showMessage: true,
                    isSuccess: true,
                    message: action.payload.message
                }
            }
        case RESTART_NOTEBOOK_FAILURE:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    showLoader: false,
                    showMessage: true,
                    isSuccess: false,
                    message: action.error
                }
            }
        case DELETE_NOTEBOOK_SUCCESS:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    showLoader: false,
                    showMessage: true,
                    isSuccess: true,
                    message: action.payload.message
                }
            }
        case DELETE_NOTEBOOK_FAILURE:
            return {
                ...state,
                listExperiments: {
                    ...state.listExperiments,
                    showLoader: false,
                    showMessage: true,
                    isSuccess: false,
                    message: action.error
                }
            }
        default:
            return state;
    }
}
