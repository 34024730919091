import {
    FETCH_ALL_CONFIG_NAMESPACES_FAILURE,
    FETCH_ALL_CONFIG_NAMESPACES_SUCCESS,
} from "../../actions/action_types";
import {namespaceInitialState} from "src/model/configPanel/config_panel_models"


export default function (state = namespaceInitialState, action: any) {
    switch (action.type) {
        case FETCH_ALL_CONFIG_NAMESPACES_SUCCESS:
            return {
                ...state,
                namespaceList: action.payload
            }

        case FETCH_ALL_CONFIG_NAMESPACES_FAILURE:
            return {
                ...state,
                namespaceList: []
            }
        default:
            return state;
    }
}
