import {useParams} from "react-router-dom";
import history from "src/history";
import {parse} from "query-string";

export const getParamFromURL = (param: string) => {
    try {
        const params: any = useParams();
        return params ? params[param] : null;
    } catch (err) {
        return null;
    }
}

export const redirectToNotFound = () => {
    history.push("/notFound");
}

export const redirectToHomePage = (isAdminView?: boolean) => {
    isAdminView === true ? history.push("/admin") : history.push("/");
}


const flows_regexp: any = {
    configPanel: /\/configPanel\/.*/,
    modelTraining: /\/modelTraining\/.*/,
    featureGeneration: /\/featureGeneration\/.*/,
    jobTemplate: /\/jobTemplate.*/,
    notebooks: /\/notebooks\/.*/,
    runways: /\/runways\/.*/,
    codeRepositories: /\/codeRepositories.*/,
    datasets: /\/datasets.*/,
    notebooksAdmin: /\/admin\/.*\/notebooks.*/,
}

export const FLOWS = {
    CONFIG_PANEL: "configPanel",
    MODEL_TRAINING: "modelTraining",
    FEATURE_GENERATION: "featureGeneration",
    JOB_TEMPLATE: "jobTemplate",
    NOTEBOOKS: "notebooks",
    RUNWAYS: "runways",
    CODE_REPOSITORIES: "codeRepositories",
    DATASETS: "datasets",
    FLASH: "flash",
    NOTEBOOKS_ADMIN: "notebooksAdmin"
}

export const getFlowFromURL = (pathname: string) => {
    for (const flow in flows_regexp) {
        let match = pathname.match(flows_regexp[flow]);
        if (match && match[0] === pathname) {
            //TODO : This should ideally return FLOWS
            // 1. FLOWS is used at multiple places make it enum
            // 2. convert this string to enum value and use
            return flow;
        }
    }
    return null;
}

export const matchURLRegex = (regex: string) => {
    const uri = window.location.pathname;
    return uri.match(regex)
}

export const removeTrailingSlashes = (path: string) => {
    return path.replace(/\/$/, "");
}

export const isAnchor = (event: any) => {
    try {
        if (event.target.href) {
            return true;
        }
        return false;
    } catch (error) {
        return false;
    }
}

export const getParamFromQueryString = (search: any, param: string) => {
    try {
        const params = parse(search);
        if (params && params[param]) {
            return params[param];
        }
        return null;
    } catch (error) {
        return null;
    }
}

export const setParamToQueryString = (paramName: string, paramValue: string | null) => {
    const params = new URLSearchParams(history.location.search);
    if (paramValue && params.has(paramName))
        params.set(paramName, paramValue);
    else if (paramValue)
        params.append(paramName, paramValue);
    else
        params.delete(paramName);
    let newLocation = {...history.location, search: params.toString()};
    history.replace(newLocation);
}

export const resetParamsQueryString = () => {
    const params = new URLSearchParams();
    let newLocation = {...history.location, search: params.toString()}
    history.replace(newLocation);
}

export const isAdminWindow = () => {
    return window.location.pathname.includes('admin')
}