import commonMiddlewares from "src/control/middlewares/commons";
import modelTrainingMiddlewares from "src/control/middlewares/modelTraining/model_training_middlewares";
import configPanelMiddlewares from "src/control/middlewares/configPanel/config_panel_middlewares";
import jobMiddlewares from "src/control/middlewares/runways/job_middlewares"
import runwayMiddlewares from "src/control/middlewares/runways/runway_middlewares"
import codeRepoMiddlewares from "src/control/middlewares/codeRepository/code_repository_middlewares";

export default [
    ...commonMiddlewares,
    ...modelTrainingMiddlewares,
    ...configPanelMiddlewares,
    ...jobMiddlewares,
    ...runwayMiddlewares,
    ...codeRepoMiddlewares
]