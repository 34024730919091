import styled from "styled-components";


export const NotFoundContainer = styled.div`
    margin-top: 25%;
    text-align: center;
`

export const NotFoundHeading = styled.h1`
    color: #002e36;
    font-weight: 1000 !important;
    font-size: 3em !important;
`