import {
    REVIEW_DATA_FETCH_FAILURE,
    REVIEW_DATA_FETCH_SUCCESS,
    REVIEW_FETCH_START,
    PUBLISH_REVIEW_SUCCESS,
    APPROVE_REVIEW_SUCCESS,
    DISCARD_REVIEW_SUCCESS,
    MERGE_REVIEW_SUCCESS,
    REVOKE_APPROVAL_SUCCESS,
    SAVE_REVIEW_SUCCESS,
    CREATE_CONFIG_REVIEW_FAILURE,
    CREATE_CONFIG_REVIEW_SUCCESS
} from "../../actions/action_types";
import { initialReviewState, APIRequestStatus, ReviewStatus } from "../../../model/review/review_types"



export default function (state = initialReviewState, action: any) {
    const approverInfo = JSON.parse(JSON.stringify(state.data.approverInfo));    
    switch (action.type) {

        case CREATE_CONFIG_REVIEW_SUCCESS:
            return {
                ...state
            }
            
        case CREATE_CONFIG_REVIEW_FAILURE:
            return {
                ...state
            }
            
        case REVIEW_DATA_FETCH_SUCCESS:
            return {
                ...state,
                requestStatus: APIRequestStatus.SUCCESS,
                data: action.payload.data,
                message: action.payload.message
            }
        case REVIEW_DATA_FETCH_FAILURE:
            return {
                ...state,
                requestStatus: APIRequestStatus.ERROR
            }
        case REVIEW_FETCH_START:
            return {
                ...state,
                requestStatus: APIRequestStatus.LOADING
            }
        case PUBLISH_REVIEW_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    approverInfo: action.payload.approverInfo,
                    commentInfo: action.payload.commentInfo,
                    descriptionInfo: action.payload.descriptionInfo,
                    status: ReviewStatus.PUBLISHED
                }
            }
        case SAVE_REVIEW_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    approverInfo: action.payload.approverInfo,
                    commentInfo: action.payload.commentInfo
                }
            }
        case MERGE_REVIEW_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    status: ReviewStatus.MERGED
                }
            }
        case DISCARD_REVIEW_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    status: ReviewStatus.CANCELLED
                }
            }
        case APPROVE_REVIEW_SUCCESS:
            approverInfo.forEach((element:any) => {
                if (element.alias === action.payload) {
                    element.isApproved = true
                    return
                }
            })
            return {
                ...state,
                data: {
                    ...state.data,
                    approverInfo: [...approverInfo]
                }
            }
        case REVOKE_APPROVAL_SUCCESS:
            approverInfo.forEach((element:any) => {
                if (element.alias === action.payload) {
                    element.isApproved = false
                    return
                }
            })            
            return {
                ...state,
                data: {
                    ...state.data,
                    approverInfo: [...approverInfo]
                }
            }
        default:
            return state;
    }
}