import {Failure, Request, Success} from "src/utils/action_utils";
import {
    FETCH_ALL_CONFIG_NAMESPACES_FAILURE,
    FETCH_ALL_CONFIG_NAMESPACES_REQUEST,
    FETCH_ALL_CONFIG_NAMESPACES_SUCCESS,
    CONFIG_FETCH_ALL_FAILURE,
    CONFIG_FETCH_ALL_SUCCESS,
    CONFIG_SET_DOMAINS,
    CONFIG_FETCH_FAILURE,
    CONFIG_SET_VERSIONS,
    ERROR_UPDATING_CONFIG_METADATA,
    UPDATE_CONFIG_METADATA_SUCCESS,
    UPDATE_CONFIG_METADATA_FAILURE,
    ERROR_FETCHING_CONFIG_METADATA,
    FETCH_CONFIG_METADATA_SUCCESS,
    FETCH_CONFIG_METADATA_FAILURE,
    ERROR_FETCHING_CONFIG_BODY,
    CONFIG_FETCH_SUCCESS,
    START_UPDATE_CONFIG_METADATA,
    FETCH_CONFIG_VALIDATION_INFO_SUCCESS,
    FETCH_CONFIG_VALIDATION_INFO_FAILURE,
    ERROR_FETCHING_CONFIG_VALIDATION_INFO,
    ERROR_STARTING_CONFIG_VALIDATION_FLOW,
    START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS,
    START_CONFIG_VALIDATION_FLOW_INFO_FAILURE,
    START_CONFIG_VALIDATION_FLOW_LOADER,
    FETCH_CONFIG_VALIDATION_START_LOADER,
} from "src/control/actions/action_types";
import {apiGetPromise, apiPostPromise, apiPutCallback, apiPutPromise} from "src/utils/api_handler";
import {
    ERROR_NAMESPACES_NOT_FOUND,
    ERROR_WHILE_FETCHING_NAMESPACE_INFO,
    ERROR_EMPTY_TEAM_OR_NAMESPACE,
    ERROR_WHILE_FETCHING_CONFIG_INFO,
    ERROR_WHILE_FETCHING_DOMAIN_INFO,
    ERROR_CONFIG_BUFFER_SIZE_EXCEEDED,
    ERROR_CONFIG_VERSION_DOES_NOT_EXIST,
    ERROR_WHILE_FETCHING_VERSION_INFO,
} from "src/control/errors";
import {
    getConfigMetadataEndpoint,
    getConfigBodyEndpoint, getListConfigsEndpoint, getFetchConfigValidationEndpoint, getTriggerConfigValidationEndpoint
} from "src/control/targets";
import {isEmpty} from "src/utils/common_utils";
import {filterList, getUniqueItemsFromObjectList} from "../../../utils/list_utils";
import {MAX_CONFIG_VERSION_BUFFER_SIZE} from "src/view/configPanel/config_constants";
import {
    versionsSelector
} from "src/control/selectors/configPanel/config_panel_selectors";
import {getNamespaces} from "src/utils/config_panel_action_utils";

export const fetchAllNamespaces = (teamId: string) => async (dispatch: any) => {
    dispatch(Request(FETCH_ALL_CONFIG_NAMESPACES_REQUEST));
    return new Promise(async (resolve, reject) => {
        try {
            let namespaceList = getNamespaces()
            namespaceList = filterList(namespaceList, "teamId", teamId);

            let data = {
                namespaceList: namespaceList
            }

            if(!isEmpty(data) && !isEmpty(data.namespaceList)){
                dispatch(Success(FETCH_ALL_CONFIG_NAMESPACES_SUCCESS, data.namespaceList));
                resolve();
                return;
            }
            let error = ERROR_NAMESPACES_NOT_FOUND;
            dispatch(Failure(FETCH_ALL_CONFIG_NAMESPACES_FAILURE, error));
            reject(error);
        } catch (err) {
            let error = ERROR_WHILE_FETCHING_NAMESPACE_INFO;
            dispatch(Failure(FETCH_ALL_CONFIG_NAMESPACES_FAILURE, err));
            reject(error);
        }
    });
}

export const setDomains = (configs: []) => (dispatch: any) => {
    return new Promise((resolve, reject) => {

        if (!isEmpty(configs)) {
            let domains = getUniqueItemsFromObjectList(configs, "domain");
            domains.sort();
            dispatch(Success(CONFIG_SET_DOMAINS, domains))
            resolve();
            return;
        }
        reject(ERROR_WHILE_FETCHING_DOMAIN_INFO);
    });

}

export const fetchAllConfigs = (
    namespaceId: string, domain?: string
) => async (dispatch: any) => {

    return new Promise(async (resolve, reject) => {
        try {
            if (isEmpty(namespaceId)) {
                const error = ERROR_EMPTY_TEAM_OR_NAMESPACE;
                dispatch(Failure(CONFIG_FETCH_ALL_FAILURE, error));
                reject(error);
                return;
            }

            let allConfigs: any = await apiGetPromise(getListConfigsEndpoint(), {
                domain: domain,
                namespaceId: namespaceId,
            })

            if (isEmpty(allConfigs)) {
                dispatch(Failure(CONFIG_FETCH_ALL_FAILURE, ERROR_WHILE_FETCHING_CONFIG_INFO));
                reject(ERROR_WHILE_FETCHING_CONFIG_INFO);
                return;
            }
            dispatch(Success(CONFIG_FETCH_ALL_SUCCESS, allConfigs.configMetadatas));
            await dispatch(setDomains(allConfigs.configMetadatas));
            resolve(allConfigs);
        } catch (err) {
            dispatch(Failure(CONFIG_FETCH_ALL_FAILURE, err));
            let error = ERROR_WHILE_FETCHING_CONFIG_INFO;
            reject(error);
        }
    });
}

export const setVersions = (configs: any) => (dispatch: any) => {
    return new Promise((resolve, reject) => {
        if (!isEmpty(configs)) {
            let versions = getUniqueItemsFromObjectList(configs, "version");
            let integerVersions = versions.map((version: string) => (parseInt(version)));
            integerVersions.sort(function(a, b){return a - b});
            integerVersions.reverse();
            let stringVersions = integerVersions.map((v: number) => v.toString())
            dispatch(Success(CONFIG_SET_VERSIONS, stringVersions));
            resolve();
            return;
        }
        let error = ERROR_WHILE_FETCHING_VERSION_INFO;
        reject(error);
    });

}

export const fetchConfig = (
    configId: string,
    versions: number = 5
) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            if (versions > MAX_CONFIG_VERSION_BUFFER_SIZE) {
                return;
            }

            const error = ERROR_FETCHING_CONFIG_BODY

            let data: any = await apiGetPromise(getConfigBodyEndpoint(configId),{
                versions: versions
            });
            if (!isEmpty(data)) {
                let versionAndJsons = data.data;

                let configs = versionAndJsons.map((version: any) => {
                    return {
                        version: version.version,
                        jsonConfig: version.body
                    }
                })
                dispatch(Success(CONFIG_FETCH_SUCCESS, configs));
                dispatch(setVersions(configs));
                resolve();
                return;
            }
            dispatch(Failure(ERROR_FETCHING_CONFIG_BODY, error));
            reject(error);
        } catch (error) {
            dispatch(Failure(ERROR_FETCHING_CONFIG_BODY, error));
            reject(error);
        }
    })
}

export const fetchMoreConfig = (
    configId: string,
    versionNeeded: string = "",
    additionalVersions: number = 5) => (dispatch: any, getState: any) => {
    return new Promise(async (resolve, reject) => {
        try{
            let currentVersions = versionsSelector(getState())
            if (isEmpty(currentVersions)) {
                reject(ERROR_WHILE_FETCHING_VERSION_INFO);
                return;
            }

            if (isEmpty(versionNeeded)) {
                let currentLength = currentVersions.length;
                if (currentLength == MAX_CONFIG_VERSION_BUFFER_SIZE) {
                    reject(ERROR_CONFIG_BUFFER_SIZE_EXCEEDED);
                    return;
                }
                if (currentLength + additionalVersions > MAX_CONFIG_VERSION_BUFFER_SIZE) {
                    additionalVersions = MAX_CONFIG_VERSION_BUFFER_SIZE - currentLength;
                }
                await dispatch(fetchConfig(configId, currentLength + additionalVersions));
                resolve();
                return;
            }

            let vNeededInt = parseInt(versionNeeded);
            if (isNaN(vNeededInt)){
                reject(ERROR_CONFIG_VERSION_DOES_NOT_EXIST);
                return;
            }

            let vCurrentHighest = parseInt(currentVersions[0])
            let vCurrentLowest = parseInt(currentVersions[currentVersions.length - 1])

            if (vNeededInt > vCurrentHighest) {
                reject(ERROR_CONFIG_VERSION_DOES_NOT_EXIST);
                return;
            }

            if(vNeededInt < vCurrentLowest) {
                let difference = vCurrentLowest - vNeededInt;
                let currentLength = currentVersions.length;
                if (currentLength + difference > MAX_CONFIG_VERSION_BUFFER_SIZE) {
                    dispatch(Failure(CONFIG_FETCH_FAILURE, ERROR_CONFIG_BUFFER_SIZE_EXCEEDED));
                    reject(ERROR_CONFIG_BUFFER_SIZE_EXCEEDED);
                    return;
                }
                await dispatch(fetchConfig(configId, currentLength + difference));
                resolve();
                return;
            }
            //do nothing
            resolve()
            return;
        } catch (error) {
            reject(error);
        }

    })
}

export const uploadConfig = (
    configId: string,
    putData: any,
    callbackSuccess: any,
    callbackFailure: any) => async () => {
    apiPutCallback(getConfigBodyEndpoint(configId), putData, callbackSuccess, callbackFailure)
}

export const updateConfigValidators = (configId: string, jsonSchemaBody: string, customValidatorLambdaARN: string,customValidatorLambdaName: string) => async (dispatch: any) => {
    dispatch({type: START_UPDATE_CONFIG_METADATA})
    return new Promise(async (resolve, reject) => {
        const error = ERROR_UPDATING_CONFIG_METADATA
        try {
            let data: any = await apiPutPromise(getConfigMetadataEndpoint(configId), {
                syntaxValidatorBody: jsonSchemaBody,
                semanticValidationLambdaArn: customValidatorLambdaARN,
                semanticValidationLambdaName: customValidatorLambdaName,
            });
            if (!isEmpty(data)) {
                dispatch(Success(UPDATE_CONFIG_METADATA_SUCCESS, {
                    ...data
                }));
                resolve();
                return;
            }
            dispatch(Failure(UPDATE_CONFIG_METADATA_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(UPDATE_CONFIG_METADATA_FAILURE, error));
            reject(err);
        }
    });
}

export const fetchConfigMetadata = (configId: string, params?: any) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_CONFIG_METADATA
        try {
            let data: any = await apiGetPromise(getConfigMetadataEndpoint(configId), params);
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_CONFIG_METADATA_SUCCESS, {
                    ...data
                }));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_CONFIG_METADATA_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_CONFIG_METADATA_FAILURE, error));
            reject(err);
        }
    });
}

export const fetchConfigValidationInfo = (validationId: string) => async (dispatch: any) => {
    dispatch(Success(FETCH_CONFIG_VALIDATION_START_LOADER, {}))
    return new Promise(async (resolve, reject) => {
        const error = ERROR_FETCHING_CONFIG_VALIDATION_INFO
        try {
            let data: any = await apiGetPromise(getFetchConfigValidationEndpoint(validationId));
            if (!isEmpty(data)) {
                dispatch(Success(FETCH_CONFIG_VALIDATION_INFO_SUCCESS, {
                    ...data
                }));
                resolve();
                return;
            }
            dispatch(Failure(FETCH_CONFIG_VALIDATION_INFO_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(FETCH_CONFIG_VALIDATION_INFO_FAILURE, error));
            reject(err);
        }
    });
}


export const startConfigValidation = (startConfigValidationData: any) => async (dispatch: any) => {
    dispatch(Success(START_CONFIG_VALIDATION_FLOW_LOADER, {}));
    return new Promise(async (resolve, reject) => {
        const error = ERROR_STARTING_CONFIG_VALIDATION_FLOW
        try {
            let data: any = await apiPostPromise(getTriggerConfigValidationEndpoint(), startConfigValidationData);
            if (!isEmpty(data)) {
                dispatch(Success(START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS, {
                    ...data
                }));
                resolve();
                return;
            }
            dispatch(Failure(START_CONFIG_VALIDATION_FLOW_INFO_FAILURE, error));
            reject(error);
        } catch (err) {
            dispatch(Failure(START_CONFIG_VALIDATION_FLOW_INFO_FAILURE, error));
            reject(err);
        }
    });
}