import React, {useState} from "react";
import {NavHeaderBar, NavIconWrapper, NavPadding, NavSideBar, NavSideBarWrapper} from "../../style/navigation_styles"
import {colorWhite, NavBarsIcon, NavCloseIcon} from "../../style/icons";
import NavigationItem from "src/view/commons/navigationPanel/navigation_item";
import HomeNavItem from "./home_nav_item";
import ConfigPanelNavItem from "./config_panel_nav_item";
import {NavItem} from "src/view/commons/navigationPanel/nav_item_commons";
import DatasetNavItem from "./dataset_nav_item";
import JobTemplatesNavItem from "./job_templates_nav_item";
import CodeRepositoriesNavItem from "src/view/commons/navigationPanel/code_repository_nav_item";
import NotebooksNavItem from "src/view/commons/navigationPanel/notebooks_nav_item";
import RunwaysNavItem from "src/view/commons/navigationPanel/runways_nav_item";
import {isAdminWindow} from "src/utils/url_utils";

export const getNavItems = () => {
    return [
        HomeNavItem,
        NotebooksNavItem,
        RunwaysNavItem,
        ConfigPanelNavItem,
        DatasetNavItem,
        JobTemplatesNavItem,
        CodeRepositoriesNavItem
    ]
}

export default function NavigationBar() {
    const navigationItems = getNavItems();
    const [sideBar, setSideBar] = useState(false);

    const showSideBar = () => setSideBar(!sideBar);
    const isAdminView = isAdminWindow()

    const showNavItems = () => {
        if (navigationItems.length > 0) {
            return (
             <>
                 {
                     navigationItems.map((navItem: NavItem, key: number) => {
                         return <NavigationItem key={key} navItem={navItem}  closeSideNav={showSideBar} paddingLevel={0}/>
                     })
                 }
             </>
            )
        }
    }

    return (
        <>
            <NavHeaderBar>
                {!isAdminView && <NavIconWrapper onClick={showSideBar}>
                    <NavBarsIcon style={colorWhite}/>
                </NavIconWrapper>}
            </NavHeaderBar>
            {
                !isAdminView && <NavSideBar sidebar={sideBar}>
                    <NavSideBarWrapper>
                        <NavIconWrapper onClick={showSideBar}>
                            <NavCloseIcon style={{...colorWhite, float: "right"}}/>
                        </NavIconWrapper>
                        <NavPadding/>
                        {showNavItems()}
                    </NavSideBarWrapper>
                </NavSideBar>
            }

        </>
    )


}