import {
    FETCH_ALL_TEAMS_FAILURE,
    FETCH_ALL_TEAMS_SUCCESS, FETCH_TEAM_FAILURE,
    FETCH_TEAM_SUCCESS,
} from "../../actions/action_types";
import {teamInitialState} from "src/model/commons/commons_models"



export default function (state = teamInitialState, action: any) {
    switch (action.type) {
        case FETCH_ALL_TEAMS_SUCCESS:
            return {
                ...state,
                allTeams: action.payload
            }

        case FETCH_ALL_TEAMS_FAILURE:
            return {
                ...state,
                allTeams: []
            }

        case FETCH_TEAM_SUCCESS:
            return {
                ...state,
                team: action.payload
            }

        case FETCH_TEAM_FAILURE:
            return {
                ...state,
                team: null
            }

        default:
            return state;
    }
}
