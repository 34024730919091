import {
    FETCH_ALL_TEAMS_FAILURE,
    FETCH_ALL_TEAMS_REQUEST,
    FETCH_ALL_TEAMS_SUCCESS, FETCH_TEAM_FAILURE,
    FETCH_TEAM_REQUEST, FETCH_TEAM_SUCCESS
} from "../action_types";
import {apiGetCallback} from "../../../utils/api_handler";
import {Request, Success, Failure} from "../../../utils/action_utils";
import {ERROR_TEAM_NOT_FOUND, ERROR_WHILE_FETCHING_ALL_TEAMS_INFO} from "src/control/errors";
import {getFetchAllTeamsTarget, getFetchTeamTarget} from "src/control/targets";
import {isEmpty} from "src/utils/common_utils";


export const fetchAllTeams = (callbackOnSuccess: any, callbackOnFailure: any) => (dispatch: any) => {
    dispatch(Request(FETCH_ALL_TEAMS_REQUEST));
    let error = ERROR_WHILE_FETCHING_ALL_TEAMS_INFO;
    apiGetCallback(
        getFetchAllTeamsTarget(),
        (data: any) => {
            if(isEmpty(data) || isEmpty(data.teamsList)) {
                dispatch(Failure(FETCH_ALL_TEAMS_FAILURE, error));
                callbackOnFailure && callbackOnFailure(error);
                return;
            }
            dispatch(Success(FETCH_ALL_TEAMS_SUCCESS, data.teamsList));
            callbackOnSuccess && callbackOnSuccess(data);
        },
        (error: any) => {
            dispatch(Failure(FETCH_ALL_TEAMS_FAILURE, error));
            callbackOnFailure && callbackOnFailure(error);
        }
    );
}


export const fetchTeam = (teamId: string, callbackOnSuccess: any, callbackOnFailure: any) => (dispatch: any) => {
    dispatch(Request(FETCH_TEAM_REQUEST));
    apiGetCallback(
        getFetchTeamTarget(teamId),
        (data: any) => {
            if (isEmpty(data)) {
                let error = ERROR_TEAM_NOT_FOUND;
                dispatch(Failure(FETCH_TEAM_FAILURE, error));
                callbackOnFailure && callbackOnFailure(error);
                return;
            }
            dispatch(Success(FETCH_TEAM_SUCCESS, data));
            callbackOnSuccess && callbackOnSuccess(data);
        },
        (error: any) => {
            dispatch(Failure(FETCH_TEAM_FAILURE, error));
            callbackOnFailure && callbackOnFailure(error);
        }
    );
}
