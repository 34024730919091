import {KatButton, KatDivider, KatFlashbar, KatSpinner, KatTab, KatTabs} from "@amzn/katal-react";
import React, {useEffect, useRef, useState} from "react";
import {FormInput} from "src/view/style/modelTraining/form_input_styles";
import {
    FIELDS_REQUIRED_MESSAGE,
    NAMESPACE_CREATION_FAILED,
    NAMESPACE_CREATION_SUCCESS,
    RUNWAY_ACCOUNT_REQUIRED_MESSAGE,
} from "src/view/modelTraining/constants";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {PopUp} from "src/view/style/common_styles";
import {START_CREATE_NAMESPACE_LOADER} from "src/control/actions/action_types";
import {createModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import {
    createModelTrainingNamespaceSelector,
    createRunwaySelector
} from "src/control/selectors/modelTraining/model_training_selectors";
import {FLOWS, getFlowFromURL} from "src/utils/url_utils";
import {TableHeader} from "src/view/style/modelTraining/table_styles";

const CreateNamespace = () => {
    const params: any = useParams()

    const dispatch = useDispatch();
    let _isMounted = useRef(true);
    const [namespaceName, setNamespaceName] = useState('')
    const [namespaceDescription, setNamespaceDescription] = useState('')
    const [prodAccountId, setProdAccountId] = useState('')
    const [gammaAccountId, setGammaAccountId] = useState('')
    const [expAccountId, seExpAccountId] = useState('')


    const [showSuccessDialog, setShowSuccessDialog] = useState(false)

    const flow = getFlowFromURL(window.location.pathname)

    let userId = useSelector(usernameSelector);
    let createNamespaceState = useSelector(createModelTrainingNamespaceSelector);
    let createRunwayState = useSelector(createRunwaySelector);

    let displayMessage = (createNamespaceState.isError || createRunwayState.isError)
        || (createNamespaceState.isSuccess && createRunwayState.isSuccess)

    let isLoading = createNamespaceState.startLoader || createRunwayState.startLoader

    const getMessage = () => {
        if (createNamespaceState.isError || createRunwayState.isError) {
            return NAMESPACE_CREATION_FAILED
        } else if (createNamespaceState.isSuccess && createRunwayState.isSuccess) {
            return NAMESPACE_CREATION_SUCCESS
        }
        return ""
    }

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });

    const getCreateNamespaceSuccessPopUp = () => {
        return (
            <>
                <PopUp visible={showSuccessDialog}
                       onOpen={(event: any) => {
                           setIfMounted(_isMounted.current, setShowSuccessDialog, true)
                       }}
                       onClose={(event: any) => {
                           setIfMounted(_isMounted.current, setShowSuccessDialog, false)
                       }}
                >
                    <span slot={"title"}>Success</span>
                    Successfully created namespace.
                    Please follow this <a href="https://quip-amazon.com/ksGfAUH3DafQ/ExpressoRuntimeOctaneTemplates"
                                          target="_blank">quip</a> for creating aegis pipeline.
                    <div slot="footer" className="kat-group-horizontal">
                        <KatButton label="Done" size="base" variant="primary" onClick={(event: any) => {
                            setIfMounted(_isMounted.current, setShowSuccessDialog, false)
                        }}/>
                    </div>
                </PopUp>
            </>
        );
    }

    const onSubmit = (event: any) => {
        event.preventDefault()
        if (flow == FLOWS.NOTEBOOKS && (isEmpty(namespaceName) || isEmpty(expAccountId))) {
            alert(FIELDS_REQUIRED_MESSAGE)
            return
        }
        if (flow == FLOWS.RUNWAYS && (isEmpty(namespaceName) || (isEmpty(prodAccountId) && isEmpty(gammaAccountId)))) {
            alert(RUNWAY_ACCOUNT_REQUIRED_MESSAGE)
            return
        }
        dispatch({type: START_CREATE_NAMESPACE_LOADER})
        dispatch(createModelTrainingNamespace({
            teamId: params.teamId,
            namespace: namespaceName,
            description: namespaceDescription,
            requestedBy: userId,
            accountTypeMap: {
                experimentation: expAccountId,
                gamma: gammaAccountId,
                prod: prodAccountId
            }
        }))
    }

    return <>
        <div>
            <TableHeader>
                Create Namespace
            </TableHeader>
        </div>
        <div className="CreateNamespace">
            {getCreateNamespaceSuccessPopUp()}
            {displayMessage ?
                <KatFlashbar
                    variant={createNamespaceState.isSuccess && createRunwayState.isSuccess ? "success" : "danger"}
                    header={getMessage()}
                >
                </KatFlashbar>
                : isLoading ? <KatSpinner/> : null
            }
            <form className='CreateNamespace-form' onSubmit={onSubmit}>
                <FormInput
                    label="Namespace name *"
                    type='text'
                    value={namespaceName}
                    onChange={(event: any) => setNamespaceName(event.target.value)}
                    required
                />
                <br/>
                <FormInput
                    label="Description"
                    type='text'
                    value={namespaceDescription}
                    onChange={(event: any) => setNamespaceDescription(event.target.value)}
                />
                <br/>
                <KatDivider variant="eastern"/>
                {
                    <KatTabs selected={flow == FLOWS.NOTEBOOKS ? "Experimentation" : "Gamma"}>
                        {
                            flow == FLOWS.NOTEBOOKS && <KatTab tabId="Experimentation" label="Experimentation">
                                <FormInput
                                    label="Account Id *"
                                    placeholder='Experimentation Account Id'
                                    type='text'
                                    value={expAccountId}
                                    onChange={(event: any) => seExpAccountId(event.target.value)}
                                    required
                                />
                                <br/>
                            </KatTab>
                        }
                        {
                            flow == FLOWS.RUNWAYS && <KatTab tabId="Gamma" label="Gamma">
                                <FormInput
                                    label="Account Id"
                                    type='text'
                                    placeholder='Gamma Account Id'
                                    value={gammaAccountId}
                                    onChange={(event: any) => setGammaAccountId(event.target.value)}
                                />
                                <br/>
                            </KatTab>
                        }
                        {
                            flow == FLOWS.RUNWAYS && <KatTab tabId="Prod" label="Prod">
                                <FormInput
                                    label="Account Id"
                                    type='text'
                                    placeholder='Prod Account Id'
                                    value={prodAccountId}
                                    onChange={(event: any) => setProdAccountId(event.target.value)}
                                />
                                <br/>
                            </KatTab>
                        }
                    </KatTabs>
                }
                <KatFlashbar header="Note"
                             description="Please ensure the account Id entered here has been onboarded onto Expresso. Currently we do not support 1-click onboarding via Expresso Console."/>
                <br/>
                <KatButton type="submit" label="Create" variant="primary" size="base"/>
            </form>
        </div>
    </>;
}

export default CreateNamespace;