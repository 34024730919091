import { Stages } from "src/view/runways/constants";
import { isEmpty } from "../../../utils/common_utils";
export const isValidRunwayName = (runwayName: string) => {
  return !isEmpty(runwayName);
};

export const isValidDomainSelection = (stage: string) => {
  return (
    stage.toUpperCase() === Stages.GAMMA.toUpperCase() ||
    stage.toUpperCase() === Stages.PROD.toUpperCase()
  );
};

export const isValidRunwayDAG = (runwayDAG: []) => {
  return !isEmpty(runwayDAG) && runwayDAG.length > 0
}