import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useLocation} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import {
    Tabs,
    Tab,
    GreenSpinner
} from "../style/header_styles";
import {
    Table,
    TableCell,
    TableRowWithShadow,
    TableBody,
    TableHead,
    TableHeadRow,
    IconColumn,
    PaddedSpan, FilterInput, TableHeader,
    CreateNewButton
} from "../style/table_styles";
import {CouldNotLoadAlert} from "../style/common_styles";
import {
    getParamFromURL,
    getParamFromQueryString,
    setParamToQueryString,
    resetParamsQueryString, removeTrailingSlashes
} from "src/utils/url_utils";
import {DeleteIconTable, EditIconTable} from "src/view/style/icons";
import {fetchAllConfigs} from "../../control/actions/configPanel/config_panel_actions";
import {filterList, getUniqueItemsFromObjectList} from "../../utils/list_utils";
import history from "src/history";
import {allConfigsSelector, allDomainsSelector} from "src/control/selectors/configPanel/config_panel_selectors";
import {ERROR_NAMESPACES_NOT_FOUND, ERROR_OPERATION_NOT_SUPPORTED, ERROR_TEAM_NOT_FOUND} from "src/control/errors";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import ReactTooltip from "react-tooltip";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";

export interface Configuration {
    "configId": string,
    "namespaceId": string,
    "serviceName": string,
    "realm": string,
    "applicationRealm": string,
    "domain": string,
    "configName": string,
    "description": string
}

function fillConfigurationTableBody(configs: Configuration[], resetQueryParams: any) {
    const history = useHistory();
    const location = useLocation();
    const path = removeTrailingSlashes(location.pathname);
    const dispatch = useDispatch();

    const handleRedirectToView = (config: Configuration) => {
        resetQueryParams.current = true;
        history.push(
            `${path}/${config.configId}/view`
        );
    }

    const handleRedirectToEdit = (config: Configuration) => {
        resetQueryParams.current = true;
        history.push(
            `${path}/${config.configId}/edit`
        );
    }

    const tableRows = configs.map((config, key) => {
        return (
            <TableRowWithShadow key={key}>
                <TableCell onClick={(event: any) => {handleRedirectToView(config)}}>
                    <PaddedSpan>{config.configName}</PaddedSpan>
                </TableCell>
                <TableCell onClick={(event: any) => {handleRedirectToView(config)}}>
                    <PaddedSpan>{config.description}</PaddedSpan>
                </TableCell>
                <TableCell onClick={(event: any) => {handleRedirectToView(config)}}>
                    <PaddedSpan>{config.serviceName}</PaddedSpan>
                </TableCell>
                <TableCell onClick={(event: any) => {handleRedirectToView(config)}}>
                    <PaddedSpan>{!isEmpty(config.applicationRealm)? config.applicationRealm: config.realm}</PaddedSpan>
                </TableCell>
                <TableCell>
                    <ReactTooltip place="bottom" type="dark"/>
                    <PaddedSpan data-tip="Edit" onClick={(event: any) => {handleRedirectToEdit(config)}}>
                        <EditIconTable/>
                    </PaddedSpan>
                </TableCell>
                <TableCell>
                    <PaddedSpan data-tip="Delete" onClick={(event: any) => {dispatch(showSnackBar(ERROR_OPERATION_NOT_SUPPORTED.message))}}>
                        <DeleteIconTable style={{color: "grey"}}/>
                    </PaddedSpan>
                </TableCell>
            </TableRowWithShadow>
        )
    });

    return tableRows
}


function ConfigurationTable( props: { configs: Configuration[], resetQueryParams: any }) {

    let configs = props.configs
    let filterFromURL = getParamFromQueryString(history.location.search, "filter");

    const [filter, setFilter] = useState("");
    const [rows, setRows] = useState(configs);

    let _isMounted = useRef(true);

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    })

    const getFilteredRows = (filterString: string) => {
        const values: string[] = filterString.split(/\s+/i);

        let filteredRows: Configuration[] = configs;

        values
            .filter(value => !isEmpty(value))
            .forEach((value, index) => {
                filteredRows = filteredRows.filter((config: any) => {
                    let filterColumns = (
                        `${config.configName} ${config.realm} ${config.description}`
                    ).toUpperCase();

                    let filterValue = value.toUpperCase();
                    return filterColumns.includes(filterValue);
                })
            })

        return filteredRows;

    }

    const handleFilterChange = (filterString: any) => {
        if(isEmpty(filterString)) {
            setParamToQueryString("filter", null);
            return;
        }
        setParamToQueryString("filter", filterString);
    }

    useEffect(() => {
        if (!isEmpty(filterFromURL)) {
            setIfMounted(_isMounted.current, setFilter, filterFromURL as string);
            let filteredRows = getFilteredRows(filterFromURL as string);
            setIfMounted(_isMounted.current, setRows, filteredRows);
        }
        else {
            setIfMounted(_isMounted.current, setFilter, "");
            let filteredRows = getFilteredRows("");
            setIfMounted(_isMounted.current, setRows, filteredRows);
        }
    }, [filterFromURL])

    return (
        <>
            <PaddedSpan>
                <FilterInput
                    placeholder={"Filter by name description realm ..."}
                    value={filter}
                    label={"Search"}
                    onKeyup={(e: any) => {handleFilterChange(e.target.value)}}
                />

            </PaddedSpan>
            <Table>
                <TableHead>
                    <TableHeadRow>
                        <TableCell>
                            <PaddedSpan>Configuration Name</PaddedSpan>
                        </TableCell>
                        <TableCell>
                            <PaddedSpan>Description</PaddedSpan>
                        </TableCell>
                        <TableCell >
                            <PaddedSpan>Service Name</PaddedSpan>
                        </TableCell>
                        <TableCell >
                            <PaddedSpan>Realm</PaddedSpan>
                        </TableCell>
                        <IconColumn>
                            <PaddedSpan>Edit</PaddedSpan>
                        </IconColumn>
                        <IconColumn>
                            <PaddedSpan>Delete</PaddedSpan>
                        </IconColumn>
                    </TableHeadRow>
                </TableHead>
                <TableBody>
                    {fillConfigurationTableBody(rows, props.resetQueryParams)}
                </TableBody>
            </Table>
        </>
    );
}

export default function ListConfigurations () {
    const dispatch = useDispatch();
    const location = useLocation();
    const teamId = getParamFromURL("teamId");
    const namespaceId = getParamFromURL("namespaceId");
    const domainFromQueryString = getParamFromQueryString(location.search, "domain");

    const [fetchSuccess, setFetchSuccess] = useState(false);
    const [errorOccurred, setErrorOccurred] = useState(false);
    const [configsLoaded, setConfigsLoaded] = useState(false);

    const [domain, setDomain]: any = useState(domainFromQueryString);
    let configs: any = useSelector(allConfigsSelector);
    let domains: any = useSelector(allDomainsSelector);
    let _isMounted = useRef(true);
    let resetQueryParams = useRef(false);

    useEffect(() => {
        _isMounted.current = true;
    });

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    });


    const setDomainAndQueryParam = (domain: string) => {
        if (resetQueryParams.current) {
            return;
        }
        setParamToQueryString("domain", domain);
        setIfMounted(_isMounted.current, setDomain, domain);
    }

    const handleSelectDomain = (domain: string) => {
        setDomainAndQueryParam(domain);
    }

    const initialize = async () => {
        try {
            setIfMounted(_isMounted.current, setConfigsLoaded, false);
            if (isEmpty(teamId)) {
                throw ERROR_TEAM_NOT_FOUND;
            }
            if (isEmpty(namespaceId)) {
                throw ERROR_NAMESPACES_NOT_FOUND;
            }
            await dispatch(fetchAllConfigs(namespaceId));
            setIfMounted(_isMounted.current, setFetchSuccess, true);
            setIfMounted(_isMounted.current, setErrorOccurred, false);
        } catch (error) {
            setIfMounted(_isMounted.current, setErrorOccurred, true);
            setIfMounted(_isMounted.current, setFetchSuccess, false);
            dispatch(showSnackBar(error.message));
        }

    }

    const initializeDomain = () => {
        if (domainFromQueryString) {
            setDomainAndQueryParam(domainFromQueryString as string);
        } else {
            if (!isEmpty(domains) && fetchSuccess) {
                setDomainAndQueryParam(domains[0]);
            }
        }
    }

    useEffect(() => {
        if (_isMounted.current)
            initialize();
    }, [dispatch, teamId, namespaceId]);

    useEffect(() => {
        if(_isMounted.current)
            initializeDomain();
    }, [domainFromQueryString, domains, fetchSuccess])

    useEffect(() => {
        if (resetQueryParams.current){
            resetParamsQueryString();
        }
    }, [resetQueryParams])

    useEffect(() => {
        if (!isEmpty(domains) && !isEmpty(domain) && !domains.includes(domain)){
            dispatch(showSnackBar("Domain not found"));
            setDomainAndQueryParam(domains[0]);
            return;
        }

        if( !isEmpty(configs) && !isEmpty(domains) && !isEmpty(domain)) {
            let loadedNamespace = getUniqueItemsFromObjectList(configs, "namespaceId")
            loadedNamespace.length == 1
            && setIfMounted(_isMounted.current, setConfigsLoaded, loadedNamespace[0]==namespaceId)
        }
    }, [configs, domains, domain])

    const display = () => {

        if (errorOccurred) {
            return (
                <CouldNotLoadAlert variant={"danger"} header={"Error"}>
                    Could Not Find Configurations
                </CouldNotLoadAlert>
            );
        }

        if (!configsLoaded) {
            return (
                <GreenSpinner variant={"default"} size={"large"} />
            );
        }

        return (
            <Tabs selected={domain}>
                {
                    domains.map((d: string, index: number) => {
                        return (
                            <Tab key={index} tabId={d} label={d} onClick={(e)=>handleSelectDomain(d)}>
                                <ConfigurationTable configs={filterList(configs, "domain", d)}
                                    resetQueryParams={resetQueryParams}
                                />
                            </Tab>
                        )
                    })
                }
            </Tabs>
        );
    }

    return (
        <>
            <TableHeader> Configurations
                <CreateNewButton
                    variant={"primary"}
                    className={"disabled-link"}
                    disabled={true}
                >
                    Create New!
                </CreateNewButton>
            </TableHeader>
            <br/>
            {display()}
        </>
    )
}
