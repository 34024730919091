import {colorWhite, NavErrorIcon, NavNamespaceCreateIcon, NavNamespaceIcon} from "src/view/style/icons";
import React from "react";

export interface NavItem {
    //Name to display
    displayName: string;

    //Icon to display
    icon?: any;

    //indicates if subNavItems should be loaded dynamically
    hasSubNav?: boolean;

    //labelOnClick (hasSubNav -> false) handles redirect on-click of nav-list-item
    labelOnClick?: any;

    //onClick (hasSubNav -> true) loads subNavItems dynamically
    onClick?: any;

    //sub-nav-list-items (loaded dynamically or present static)
    subNavItems?: NavItem[];

    //selector to be used for populating subNavItems (case: dynamic loading)
    subNavSelector?: any;

    //constructor that outputs NavItem given any object returned from subNavSelector
    navItemConstructor?: any;

    //True if could not load dynamic subnav items
    couldNotLoadSubNav?: boolean;
}

export interface onClickParams {
    history?: any;
    dispatch?: any;
    isMounted?: any;
    setLoading?: any;
    otherParams?: any;
}

export const NamespaceItem: NavItem = {
    displayName: "Namespaces",
    icon: <NavNamespaceIcon style={colorWhite}/>

};
export const CreateNamespaceItem: NavItem = {
    displayName: "Create Namespace",
    icon: <NavNamespaceCreateIcon style={colorWhite}/>
};

export const CouldNotLoadItem: NavItem = {
    displayName: "Could Not Load",
    icon: <NavErrorIcon style={colorWhite}/>
}
