import React, { useState } from "react";
import { PopUp } from "./style/view_config_styles";
import { KatButton, KatLabel, KatRadiobutton } from "@amzn/katal-react";
import CreateV2Runway from "./runways/create_runway";
import CreateV1Runway from "./modelTraining/runways/create_runway";
import {getParamFromQueryString} from "src/utils/url_utils";
import {isEmpty} from "src/utils/common_utils";
/**
 * responsible for calling the corresponding v1/v2 create component
 * @returns JSX
 */
export default function CreateRunway() {
  // runwayId for the cloning runway
  const baseRunwayId = getParamFromQueryString(location.search, "runwayId") as string;
  const [isPopUpVisible, setIsPopUpVisible] = useState<boolean>(isEmpty(baseRunwayId));
  const [isV2CreateRunway, setIsV2CreateRunway] = useState<boolean>(isEmpty(baseRunwayId));

  const openPopUp = () => {
    setIsPopUpVisible(true);
  };

  const closePopUp = () => {
    setIsPopUpVisible(false);
  };

  return (
    <>
      {!isPopUpVisible && isV2CreateRunway === true && <CreateV2Runway />}
      {(!isEmpty(baseRunwayId) || (!isPopUpVisible && isV2CreateRunway === false))  && <CreateV1Runway />}
      {isPopUpVisible && (
        <PopUp visible={isPopUpVisible} onOpen={openPopUp} onClose={closePopUp}>
          <KatLabel>Select Type :</KatLabel>
          <br />
          <br />
          <KatRadiobutton value="" onClick={() => setIsV2CreateRunway(false)}>
            <div>
              Single Job
              <span style={{ backgroundColor: "#c7c807", color: "#ffffffeb" }}>
                &nbsp;&nbsp;To be deprecated&nbsp;&nbsp;
              </span>
              <div style={{ opacity: "0.6", fontStyle: "italic" }}>
                Sagemaker model training supported
              </div>
            </div>
          </KatRadiobutton>
          <br />
          <KatRadiobutton
            value=""
            label=" "
            onClick={() => setIsV2CreateRunway(true)}
            checked={true}
          >
            <div>
              Workflow with multiple jobs
              <span style={{ backgroundColor: "#009800", color: "white" }}>
                &nbsp;&nbsp;Recommended&nbsp;&nbsp;
              </span>
              <div style={{ opacity: "0.6", fontStyle: "italic" }}>
                Sagemaker, ECS and EMR jobs supported.
              </div>
            </div>
          </KatRadiobutton>
          <br />
          <br />
          <KatButton
            onClick={() => {
              setIsPopUpVisible(false);
            }}
          >
            Create Now
          </KatButton>
        </PopUp>
      )}
    </>
  );
}
