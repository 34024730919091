export enum APIRequestStatus {
    NONE = "NONE",
    ERROR = "ERROR",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS"
}

export enum RequestStatus {
    SUCCESS = "Success",
    FAILURE = "Failure",
    LOADING = "Loading",
    ALREADY_EXISTs = "AlreadyExists"
}

export interface DescriptionInfo {
    title: string,
    basicDescription: string,
    additionalDescription: string
}

export interface SourceInfo {
    url: string,
    config_name?: string,
    domain?: string,
    realm?: string,
    applicationRealm?: string,
    service_name?: string,
    version?: string
}

export interface ChangeInfo {
    staging_bucket: string,
    content_s3_key: string,
    version: string,
}

export interface ReviewInfo {
    sourceInfo: SourceInfo,
    changeInfo: ChangeInfo,
    lastUpdationTime: Date
}

export interface ApproverInfo {
    approverId: string,
    alias: string,
    isApproved: boolean,
    approvalTime: Date | null
}

export interface CommentInfo {
    commentId: string,
    commentBy: string,
    comment: string,
    commentTime: Date,
    is_editable?: boolean
}

export enum ReviewStatus {
    CREATED = "CREATED",
    PUBLISHED = "PUBLISHED",
    CANCELLED = "CANCELLED",
    MERGED = "MERGED",
}

export enum ReviewType {
    CONFIG_REVIEW = "CONFIG_REVIEW",
    CODE_REVIEW = "CODE_REVIEW"
}

export interface ReviewDetails {
    requestStatus: APIRequestStatus,
    message?: string,
    data: ReviewDetailsData
}

export interface ReviewDetailsData {
    reviewId: string,
    revision_number: number,
    status: ReviewStatus | null,
    owner: string,
    type: ReviewType | null,
    descriptionInfo: DescriptionInfo | null,
    commentInfo: CommentInfo[],
    approverInfo: ApproverInfo[],
    creation_time: Date | null,
    last_updation_time: Date | null,
    reviewInfo: ReviewInfo | null,
    canMerge: boolean
}

export interface ApiResponse {
    data?: any,
    requestStatus: RequestStatus,
    message: string
}

export const initialReviewState: ReviewDetails = {
    requestStatus: APIRequestStatus.NONE,
    message: "",
    data: {
        reviewId: "",
        revision_number: 0,
        status: null,
        owner: "",
        type: null,
        descriptionInfo: {
            title: "",
            basicDescription: "",
            additionalDescription: ""
        },
        commentInfo: [],
        approverInfo: [],
        creation_time: null,
        last_updation_time: null,
        reviewInfo: {
            sourceInfo: {
                url: "",
                config_name: "",
                domain: "",
                realm: "",
                service_name: "",
                version: ""
            },
            changeInfo: {
                staging_bucket: "",
                content_s3_key: "",
                version: "",
            },
            lastUpdationTime: new Date()
        },
        canMerge: false
    }
}