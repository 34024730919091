import { Failure, Success } from "../../../utils/action_utils";
import {
  apiGetPromise,
  apiPostPromise,
  apiPutPromise,
} from "../../../utils/api_handler";
import {
  getCreateJobEndpoint,
  getListJobsEndpoint,
  getListJobsInstancesEndpointV2,
  getUpdateJobEndpoint,
  getViewJobEndpoint,
} from "../../../control/targets";
import {
  CREATE_JOB_FAILURE,
  CREATE_JOB_SUCCESS,
  ERROR_CREATING_JOB,
  ERROR_FETCHING_JOB,
  ERROR_LISTING_JOBS,
  ERROR_LOADING_JOB_EXECUTIONS,
  ERROR_UPDATING_JOB,
  FETCH_JOB_FAILURE,
  FETCH_JOB_SUCCESS,
  FETCH_JOB_LATEST_VERSION_DATA_SUCCESS,
  LIST_JOBS_FAILURE,
  LIST_JOBS_SUCCESS,
  LOAD_JOB_EXECUTIONS_STATUS_FAILURE,
  LOAD_JOB_EXECUTIONS_STATUS_SUCCESS,
  START_JOB_LOADER,
  START_LOAD_JOB_EXECUTIONS,
  UPDATE_JOB_FAILURE,
  UPDATE_JOB_SUCCESS,
} from "../../../control/actions/action_types";
import { RequestStatus } from "../../../utils/model_utils";
import { isEmpty } from "../../../utils/common_utils";
import { showSnackBar } from "../commons/snack_bar_actions";
import {
  ArtifactInfo,
  JobDependencyInfo,
  JobScheduleInfo,
  Service,
  ServiceType,
} from "../../../model/runways/job_model";

export const createJob =
  (
    jobName: string,
    namespaceId: string,
    requestedBy: string,
    domain: string,
    serviceType: ServiceType,
    service: Service,
    artifactInfo: ArtifactInfo,
    jobScheduleInfo: JobScheduleInfo,
    jobDependencies: JobDependencyInfo[],
    customTags: string[]
  ) =>
  async (dispatch: any) => {
    dispatch({ type: START_JOB_LOADER });
    return new Promise(async (resolve, reject) => {
      try {
        const error = ERROR_CREATING_JOB;
        let createJobPayload: any = {
          jobName: jobName,
          namespaceId: namespaceId,
          requestedBy: requestedBy,
          domain: domain,
          serviceType: serviceType,
          service: service,
          artifactInfo: artifactInfo,
          jobScheduleInfo: jobScheduleInfo,
          jobDependencies: jobDependencies,
          customTags: customTags,
        };
        let response: any = await apiPostPromise(
          getCreateJobEndpoint(),
          createJobPayload
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase() &&
          !isEmpty(response.jobId)
        ) {
          createJobPayload.jobId = response.jobId;
          dispatch(Success(CREATE_JOB_SUCCESS, createJobPayload));
          dispatch(showSnackBar("The job has been created!"));
          resolve(response.jobId);
        } else {
          dispatch(Failure(CREATE_JOB_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(CREATE_JOB_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const getJob =
  (jobId: string, version?: number | null, latestVersions?: number | null) =>
  async (dispatch: any) => {
    dispatch({ type: START_JOB_LOADER });
    return new Promise<void>(async (resolve, reject) => {
      try {
        const error = ERROR_FETCHING_JOB;
        let response: any = await apiGetPromise(getViewJobEndpoint(jobId), {
          version: isEmpty(version) ? null : version,
          latestVersions: isEmpty(latestVersions) ? null : latestVersions,
        });

        if (!isEmpty(response) && !isEmpty(response.job)) {
          dispatch(Success(FETCH_JOB_SUCCESS, response));
          resolve();
        } else {
          dispatch(Failure(FETCH_JOB_FAILURE, error));

          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(FETCH_JOB_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const updateJob =
  (
    jobId: string,
    requestedBy: string,
    service: Service,
    artifactInfo: ArtifactInfo,
    jobScheduleInfo: JobScheduleInfo,
    jobDependencies: JobDependencyInfo[],
    customTags: string[]
  ) =>
  async (dispatch: any) => {
    dispatch({ type: START_JOB_LOADER });
    return new Promise<void>(async (resolve, reject) => {
      try {
        const error = ERROR_UPDATING_JOB;
        let updateJobPayload = {
          requestedBy: requestedBy,
          service: service,
          artifactInfo: artifactInfo,
          jobScheduleInfo: jobScheduleInfo,
          jobDependencies: jobDependencies,
          customTags: customTags,
        };
        let response: any = await apiPutPromise(
          getUpdateJobEndpoint(jobId),
          updateJobPayload
        );
        if (
          !isEmpty(response) &&
          response.requestStatus.toLowerCase() ===
            RequestStatus.SUCCESS.toLowerCase()
        ) {
          dispatch(Success(UPDATE_JOB_SUCCESS, updateJobPayload));
          dispatch(
            showSnackBar(
              "Job updated successfully and a new version is available!"
            )
          );
          resolve();
        } else {
          dispatch(Failure(UPDATE_JOB_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(UPDATE_JOB_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };

export const listJobs = (namespaceId: string) => async (dispatch: any) => {
  dispatch({ type: START_JOB_LOADER });
  return new Promise<void>(async (resolve, reject) => {
    try {
      const error = ERROR_LISTING_JOBS;
      let response: any = await apiGetPromise(getListJobsEndpoint(), {
        namespaceId,
      });
      if (!isEmpty(response)) {
        dispatch(Success(LIST_JOBS_SUCCESS, response.jobs));
        resolve();
      } else {
        dispatch(Failure(LIST_JOBS_FAILURE, error));
        dispatch(showSnackBar("Some error occurred! Please try again later"));
        reject(error);
      }
    } catch (err: any) {
      dispatch(Failure(LIST_JOBS_FAILURE, err));
      dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
      reject(err);
    }
  });
};

export const loadJobExecutions =
  (
    jobId: string,
    refreshExecutions: boolean,
    timeWindowStart?: string,
    timeWindowEnd?: string
  ) =>
  async (dispatch: any) => {
    if (refreshExecutions) {
      dispatch({ type: START_LOAD_JOB_EXECUTIONS });
    }
    return new Promise<void>(async (resolve, reject) => {
      const error = ERROR_LOADING_JOB_EXECUTIONS;
      try {
        let data: any = await apiGetPromise(
          getListJobsInstancesEndpointV2(jobId),
          {
            timeWindowStart: timeWindowStart,
            timeWindowEnd: timeWindowEnd,
          }
        );
        if (!isEmpty(data)) {
          dispatch(
            Success(LOAD_JOB_EXECUTIONS_STATUS_SUCCESS, data.jobInstanceList)
          );
          resolve();
          return;
        }
        dispatch(Failure(LOAD_JOB_EXECUTIONS_STATUS_FAILURE, error));
        reject(error);
      } catch (err) {
        dispatch(Failure(LOAD_JOB_EXECUTIONS_STATUS_FAILURE, error));
        reject(error);
      }
    });
  };

export const getJobLatestVersionData =
  (jobId: string) => async (dispatch: any) => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const error = ERROR_FETCHING_JOB;
        let response: any = await apiGetPromise(getViewJobEndpoint(jobId), {});
        if (!isEmpty(response) && !isEmpty(response.job)) {
          dispatch(Success(FETCH_JOB_LATEST_VERSION_DATA_SUCCESS, response));
          resolve(response.job);
        } else {
          dispatch(Failure(FETCH_JOB_FAILURE, error));
          dispatch(showSnackBar("Some error occurred! Please try again later"));
          reject(error);
        }
      } catch (err: any) {
        dispatch(Failure(FETCH_JOB_FAILURE, err));
        dispatch(showSnackBar(JSON.stringify(err.response.data.message)));
        reject(err);
      }
    });
  };
