import {apiGetPromise} from "src/utils/api_handler";
import {isEmpty} from "src/utils/common_utils";
import {Failure, Success} from "src/utils/action_utils";
import {getAccountInfoEndpoint} from "src/control/targets";
import {
    ERROR_FETCHING_EXP_ACCOUNT_INFRA,
    ERROR_FETCHING_GAMMA_ACCOUNT_INFRA,
    ERROR_FETCHING_PROD_ACCOUNT_INFRA,
    EXP_ACCOUNT_INFRA_NOT_FOUND,
    FETCH_EXP_ACCOUNT_INFRA_FAILURE,
    FETCH_EXP_ACCOUNT_INFRA_SUCCESS,
    FETCH_GAMMA_ACCOUNT_INFRA_FAILURE,
    FETCH_GAMMA_ACCOUNT_INFRA_SUCCESS,
    FETCH_PROD_ACCOUNT_INFRA_FAILURE,
    FETCH_PROD_ACCOUNT_INFRA_SUCCESS,
    GAMMA_ACCOUNT_INFRA_NOT_FOUND,
    PROD_ACCOUNT_INFRA_NOT_FOUND
} from "src/control/actions/action_types";
import {Stages} from "src/view/modelTraining/constants";

const getSuccessAction = (stage: Stages) => {
    switch (stage) {
        case Stages.EXPERIMENTATION:
            return FETCH_EXP_ACCOUNT_INFRA_SUCCESS
        case Stages.GAMMA:
            return FETCH_GAMMA_ACCOUNT_INFRA_SUCCESS
        case Stages.PROD:
            return FETCH_PROD_ACCOUNT_INFRA_SUCCESS
    }
}

const getFailureAction = (stage: Stages) => {
    switch (stage) {
        case Stages.EXPERIMENTATION:
            return FETCH_EXP_ACCOUNT_INFRA_FAILURE
        case Stages.GAMMA:
            return FETCH_GAMMA_ACCOUNT_INFRA_FAILURE
        case Stages.PROD:
            return FETCH_PROD_ACCOUNT_INFRA_FAILURE
    }
}

const getNotFoundError = (stage: Stages) => {
    switch (stage) {
        case Stages.EXPERIMENTATION:
            return EXP_ACCOUNT_INFRA_NOT_FOUND
        case Stages.GAMMA:
            return GAMMA_ACCOUNT_INFRA_NOT_FOUND
        case Stages.PROD:
            return PROD_ACCOUNT_INFRA_NOT_FOUND
    }
}

const getFailureError = (stage: Stages) => {
    switch (stage) {
        case Stages.EXPERIMENTATION:
            return ERROR_FETCHING_EXP_ACCOUNT_INFRA
        case Stages.GAMMA:
            return ERROR_FETCHING_GAMMA_ACCOUNT_INFRA
        case Stages.PROD:
            return ERROR_FETCHING_PROD_ACCOUNT_INFRA
    }
}

export const loadAccountInfra = (accountId: string, stage: Stages) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            let data: any = await apiGetPromise(getAccountInfoEndpoint(accountId));
            if(!isEmpty(data)){
                dispatch(Success(getSuccessAction(stage), data));
                resolve();
                return;
            }
            const error = getNotFoundError(stage);
            dispatch(Failure(getFailureAction(stage), error));
            reject(error);
        } catch (err) {
            const error = getFailureError(stage);
            dispatch(Failure(getFailureAction(stage), error));
            reject(err);
        }
    });
}