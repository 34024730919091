import {
    CONFIG_FETCH_ALL_FAILURE,
    CONFIG_FETCH_ALL_SUCCESS,
    CONFIG_SET_DOMAINS,
    CONFIG_FETCH_SUCCESS,
    CONFIG_FETCH_FAILURE,
    CONFIG_SET_VERSIONS,
    FETCH_CONFIG_METADATA_SUCCESS,
    FETCH_CONFIG_METADATA_FAILURE,
    UPDATE_CONFIG_METADATA_SUCCESS,
    START_UPDATE_CONFIG_METADATA,
    UPDATE_CONFIG_METADATA_FAILURE,
    FETCH_CONFIG_VALIDATION_INFO_SUCCESS,
    FETCH_CONFIG_VALIDATION_INFO_FAILURE,
    START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS,
    FETCH_CONFIG_VALIDATION_START_LOADER,
    START_CONFIG_VALIDATION_FLOW_LOADER, START_CONFIG_VALIDATION_FLOW_INFO_FAILURE,
    DISABLE_CONFIG_VALIDATION
} from "../../actions/action_types";
import {configsInitialState} from "src/model/configPanel/config_panel_models"
import {RequestStatus} from "src/model/review/review_types";


export default function (state = configsInitialState, action: any) {
    switch (action.type) {
        case CONFIG_FETCH_ALL_SUCCESS:
            return {
                ...state,
                configList: action.payload
            }

        case CONFIG_FETCH_ALL_FAILURE:
            return {
                ...state,
                configList: []
            }

        case CONFIG_SET_DOMAINS:
            return {
                ...state,
                domainList: action.payload
            }
        case CONFIG_FETCH_SUCCESS:
            return {
                ...state,
                fetchedConfig: {
                    ...state.fetchedConfig,
                    configBody: action.payload
                }
            }

        case CONFIG_FETCH_FAILURE:
            return {
                ...state,
                fetchedConfig: null
            }

        case CONFIG_SET_VERSIONS:
            return {
                ...state,
                versions: action.payload
            }

        case FETCH_CONFIG_METADATA_SUCCESS:
            return {
                ...state,
                fetchedConfig: {
                    ...state.fetchedConfig,
                    configMetadata: action.payload
                }
            }

        case FETCH_CONFIG_METADATA_FAILURE:
            return {
                ...state,
                fetchedConfig: {
                    ...state.fetchedConfig,
                    configMetadata: null
                }
            }

        case UPDATE_CONFIG_METADATA_SUCCESS:
            return {
                ...state,
                updateConfigMetadata: RequestStatus.SUCCESS
            }

        case UPDATE_CONFIG_METADATA_FAILURE:
            return {
                ...state,
                updateConfigMetadata: RequestStatus.FAILURE
            }

        case START_UPDATE_CONFIG_METADATA:
            return {
                ...state,
                updateConfigMetadata: RequestStatus.LOADING
            }
        case FETCH_CONFIG_VALIDATION_START_LOADER:
            return {
                ...state,
                fetchConfigValidation: {
                    validationData: {},
                    startFetchValidationInfoLoader: true,
                    validationInfoFetchSuccess: false,
                    validationInfoFetched: false
                }
            }
        case FETCH_CONFIG_VALIDATION_INFO_SUCCESS:
            return {
                ...state,
                fetchConfigValidation: {
                    validationData: action.payload.configValidationInfo,
                    validationInfoFetched: true,
                    validationInfoFetchSuccess: true,
                    startFetchValidationInfoLoader: false
                }
            }
        case FETCH_CONFIG_VALIDATION_INFO_FAILURE:
            return {
                ...state,
                fetchConfigValidation: {
                    validationInfoFetched: true,
                    validationInfoFetchSuccess: false,
                    startFetchValidationInfoLoader: false
                }
            }

        case START_CONFIG_VALIDATION_FLOW_LOADER:
            return {
                ...state,
                startConfigValidation: {
                    startConfigValidationLoader: true,
                    validationTriggered: false,
                    validationStartSuccess: false,
                }
            }
        case START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS:
            return {
                ...state,
                startConfigValidation: {
                    validationId: action.payload.validationId,
                    validationTriggered: true,
                    validationStartSuccess: true,
                    startConfigValidationLoader: false
                }
            }
        case START_CONFIG_VALIDATION_FLOW_INFO_FAILURE:
            return {
                ...state,
                startConfigValidation: {
                    validationTriggered: true,
                    validationStartSuccess: false,
                    startConfigValidationLoader: false
                }
            }
        
        case DISABLE_CONFIG_VALIDATION:
            return{
                ...state,
                fetchConfigValidation: {
                    ...state.fetchConfigValidation,
                    validationInfoFetched: false,
                    validationInfoFetchSuccess: false,
                    startFetchValidationInfoLoader: false,
                    validationData: {
                        ...state.fetchConfigValidation.validationData,
                        validationResults: []
                    }
                }
            }

        default:
            return state;
    }
}
