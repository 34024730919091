import {useDispatch, useSelector} from "react-redux";
import {dataStudioOnboardingSelector} from "src/control/selectors/notebooks/data_studio_selectors";
import React, {useEffect, useRef, useState} from "react";
import {dataStudioInitialState} from "src/model/notebooks/data_studio_models";
import {useParams} from "react-router-dom";
import {getDataStudioOnboarding} from "src/control/actions/notebooks/data_studio_actions";
import {OperationType, Status} from "src/constant";
import {KatBadge, KatCard, KatFlashbar, KatSpinner} from "@amzn/katal-react";
import {isEmpty, setIfMounted} from "src/utils/common_utils";
import {getAddonStatusBadgeType} from "src/view/notebooks/datastudio_utils";
import {FormInput} from "src/view/style/modelTraining/form_input_styles";
import JSONEditor from "src/view/commons/json_editor";
import {PaddedSpan} from "src/view/style/table_styles";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {isAdminWindow} from "src/utils/url_utils";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";

interface DataStudioOnboardingProps {
    operationType: OperationType
}

export default function DataStudioOnboarding(props: DataStudioOnboardingProps) {
    const dispatch = useDispatch();
    const params: any = useParams();
    const isAdminView = isAdminWindow();

    const [onboarding, setOnboarding] = useState(dataStudioInitialState.onboarding.value)

    const _isMounted = useRef(true);

    let onboardingState = useSelector(dataStudioOnboardingSelector);
    let fetchedNamespace = useSelector(namespaceSelector)

    useEffect(() => {
        _isMounted.current = true;
        props.operationType !== OperationType.CREATE && dispatch(getDataStudioOnboarding(params.onboardingId))
        !isAdminView && dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        props.operationType !== OperationType.CREATE && setOnboarding(onboardingState.value)
    }, [onboardingState]);

    const getCardTitle = () => {
        return props.operationType === OperationType.CREATE ? <>Data Studio Onboarding</> : <>
            Data Studio Onboarding : <KatBadge label={onboardingState.value.status}
                                               type={getAddonStatusBadgeType(onboardingState.value.status)}/>
        </>
    }

    /**
     TODO : Add onChange and Display error component when create will be implemented : Spektr Milestone 3
     */
    return (
        onboardingState.status === Status.ERROR ? <KatFlashbar
                variant="danger"
                header={"Error"}
                description={`Failed to load onboarding for onboardingId : ${params.onboardingId}`}
            /> :
            (
                (!isAdminView && isEmpty(fetchedNamespace.namespaceData.namespace)) ||
                (props.operationType !== OperationType.CREATE && onboardingState.status === Status.LOADING) ?
                    <KatSpinner/> :
                    <>
                        <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}
                                    dataStudioOnboardingName={onboardingState.value.name}/>
                        <KatCard>
                            <span slot={"subtitle"}>{getCardTitle()}</span>
                            {props.operationType !== OperationType.CREATE &&
                                <PaddedSpan>onboarding Id : {onboarding.onboardingId}</PaddedSpan>}
                            <FormInput
                                label="Name*"
                                type="text"
                                value={onboarding.name}
                                placeholder="Name"
                                readonly={props.operationType === OperationType.VIEW}
                            />
                            <FormInput
                                label="Team*"
                                type="text"
                                value={onboarding.teamIdentifier}
                                placeholder="Team"
                                readonly={props.operationType === OperationType.VIEW}
                            />
                            <FormInput
                                label="Account Id*"
                                type="text"
                                value={onboarding.accountId}
                                placeholder="Account Id"
                                readonly={props.operationType === OperationType.VIEW}
                            />
                            <div style={{height: "450px", width: "800px"}}>
                                <span>Resources</span>
                                <JSONEditor
                                    fieldName={"Cluster Configurations"}
                                    value={JSON.stringify(onboarding.resources, null, "\t")}
                                    readOnly={props.operationType === OperationType.VIEW}
                                />
                            </div>
                        </KatCard>
                    </>
            )
    )

}