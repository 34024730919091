export const FIELDS_REQUIRED_MESSAGE: string = "Fields marked with * are required"
export const RUNWAY_ACCOUNT_REQUIRED_MESSAGE: string = "One of the gamma and prod account Ids are required"
export const PACKAGE_LIMIT_MESSAGE = "Cannot have more than 6 packages with an experiment."
export const NAMESPACE_ERROR_MESSAGE = "Error fetching namespace details"
export const FETCH_RUNWAY_ERROR_MESSAGE = "Error fetching runway details"
export const UPDATE_RUNWAY_ERROR_MESSAGE = "Error updating runway details"
export const EXPERIMENT_CREATION_FAILED_MESSAGE = "Experiment Creation Failed"
export const EXPERIMENT_UPDATE_FAILED_MESSAGE = "Experiment Update Failed"
export const EXPERIMENT_FETCH_FAILED_MESSAGE = "Error fetching experiment details"
export const NAMESPACE_CREATION_SUCCESS = "Successfully created namespace"
export const NAMESPACE_CREATION_FAILED = "Namespace Creation Failed!"
export const NAMESPACE_UPDATE_SUCCESS = "Successfully updated namespace"
export const NAMESPACE_UPDATE_FAILED = "Namespace update Failed!"
export const REGISTER_PACKAGE_SUCCESS = "Package registeration suceeded !"
export const REGISTER_PACKAGE_FAILED = "Package registeration failed !"
export const EXP_ACCOUNT_INFRA_FAILURE = "Error fetching experimentation account details"
export const UPDATING_EXPERIMENT_MESSAGE = "Updating Experiment..."
export const DEFAULT_NAMESPACE_NAME = "Default"

export const CUSTOM_TRAINING_IMAGE = "Custom Training Image"
export const NO_DATASETS_FOUND_MESSAGE = "No datasets found"
export const NO_ONBOARDED_PACKAGES_MESSAGE = "No on-boarded packages found"
export const UPDATE_RUNWAY_SUCCESS_MESSAGE = "Successfully updated Runway"

export const UPDATE_RUNWAY_STATUS_SUCCESS_MESSAGE = "Successfully updated runway status"
export const UPDATE_RUNWAY_STATUS_FAILURE_MESSAGE = "Update runway status failure"

export const CREATE_RUNWAY_SUCCESS_MESSAGE = "Successfully created runway"
export const CREATE_RUNWAY_FAILURE_MESSAGE = "Runway creation failed"
export const RUNWAY_EXECUTIONS_DEFAULT_PAGE_SIZE = 250

export const getCreateExperimentSuccessMessage = (notebookInstanceName: string, userId: string) => {
    return `Creating notebook with name ${notebookInstanceName}-${userId}`
}

export const getRestartExperimentSuccessMessage = (notebookInstanceName: string) => {
    return `Restarting experiment with name ${notebookInstanceName}`
}

export const getRestartExperimentFailureMessage = (notebookInstanceName: string) => {
    return `Failed to restart experiment ${notebookInstanceName}`
}

export const getDeleteExperimentSuccessMessage = (notebookInstanceName: string) => {
    return `Deleting experiment with name ${notebookInstanceName}`
}

export const getDeleteExperimentFailureMessage = (notebookInstanceName: string) => {
    return `Failed to delete experiment ${notebookInstanceName}`
}

export const switchRunwayStatus = (status: string) => {
    return (status == RunwayStatus.DISABLED ? RunwayStatus.ENABLED : RunwayStatus.DISABLED)
}


export enum RunwayStatus {
    ENABLED = "ENABLED",
    DISABLED = "DISABLED"
}

export enum EcrRepositoryType {
    DNN = "DNN",
    XGB = "XGB",
    GBDT = "GBDT",
    CATBOOST = "CATBOOST",
    PYTORCH = "PYTORCH"
}

export enum Status {
    InService = "InService",
    ERROR = "ERROR",
    LOADING = "LOADING",
    SUCCESS = "SUCCESS"
}

export enum NotebookStatus {
    InService = "InService",
    FAILED = "FAILED",
    UPDATE_FAILED = "UPDATE_FAILED",
    DELETION_FAILED = "DELETION_FAILED"
}

export enum ClusterStatus {
    WAITING = "WAITING",
    RUNNING = "RUNNING",
    TERMINATED = "TERMINATED"
}

export enum KernelType {
    EMR_KERNEL = "EMR",
    TRAINING_EMR_KERNEL = "EMR+Training",
    TRAINING = "Training"
}

export enum Stages {
    EXPERIMENTATION = "Experimentation",
    GAMMA = "Gamma",
    PROD = "Prod"
}

export enum JobStatus {
    SUCCEEDED = "SUCCEEDED",
    FAILED = "FAILED",
    INPROGRESS = "INPROGRESS",
    ABORTED = "ABORTED"
}

export const BEATS_IAD_ENDPOINT = "iad.beats.adrunway.amazon.dev"
export const BEATS_DUB_ENDPOINT = "dub.beats.adrunway.amazon.dev"
export const BEATS_PDX_ENDPOINT = "pdx.beats.adrunway.amazon.dev"

export const getEcrReposOptions = () => {
    const ecrReposOptions: any[] = []
    ecrReposOptions.push({
        "label": EcrRepositoryType.DNN, "value": EcrRepositoryType.DNN
    })
    ecrReposOptions.push({
        "label": EcrRepositoryType.XGB, "value": EcrRepositoryType.XGB
    })
    ecrReposOptions.push({
        "label": EcrRepositoryType.PYTORCH, "value": EcrRepositoryType.PYTORCH
    })
    ecrReposOptions.push({
        "label": EcrRepositoryType.CATBOOST, "value": EcrRepositoryType.CATBOOST
    })
    ecrReposOptions.push({
        "label": EcrRepositoryType.GBDT, "value": EcrRepositoryType.GBDT
    })
    ecrReposOptions.push({
        "label": CUSTOM_TRAINING_IMAGE, "value": CUSTOM_TRAINING_IMAGE
    })
    return ecrReposOptions;
}


export const getKernelOptions = () => {
    const kernelOptions: any[] = [];
    kernelOptions.push({
        'label': 'Spark',
        'value': KernelType.EMR_KERNEL
    })
    kernelOptions.push({
        'label': 'Spark + Training',
        'value': KernelType.TRAINING_EMR_KERNEL
    })
    kernelOptions.push({
        'label': 'Training',
        'value': KernelType.TRAINING
    })
    return kernelOptions;
}