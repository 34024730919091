import { KatCard } from "@amzn/katal-react";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import {
  FormDropDown,
  FormInput,
} from "src/view/style/modelTraining/form_input_styles";
import { setVersions } from "../constants";
import JobComponent from "../job_components";
import {
  isJobTemplateSpinnerLoadingSelector,
  jobTemplateDataSelector,
  jobTemplateLatestVersionDataSelector,
} from "src/control/selectors/runways/job_template_selectors";
import { getJobTemplate, getJobTemplateLatestVersionData } from "src/control/actions/runways/job_template_actions";
import { GreenSpinner } from "src/view/style/header_styles";
import { UpdateButton } from "src/view/style/runway_styles";
import {OperationType} from "src/constant";

/**
 * renders the view for update job template form
 * @returns JSX
 */
export default function UpdateJobTemplate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  //selectors
  let userName = useSelector(usernameSelector);
  let jobTemplateData = useSelector(jobTemplateDataSelector);
  let latestVersionJobTemplateData: any = useSelector(
    jobTemplateLatestVersionDataSelector
  );
  let isSpinnerLoading = useSelector(isJobTemplateSpinnerLoadingSelector);

  let latestVersion = latestVersionJobTemplateData.latestVersion;

  // useEffect hooks
  useEffect(() => {
    dispatch(getJobTemplateLatestVersionData(params.jobTemplateId));
    dispatch(getJobTemplate(params.jobTemplateId));
  }, []);

  if (isSpinnerLoading) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }
  return (
    <>
      <KatCard>
        <div>
          <span slot="title">TemplateId: {jobTemplateData.jobTemplateId} </span>
          <UpdateButton
            label="Update"
            onClick={() => {
              let redirecedPath = window.location.pathname.replace(
                "view",
                "update"
              );
              history.push(redirecedPath);
            }}
          ></UpdateButton>
        </div>
        <br />

        <FormInput
          label="Job Template Name"
          type="text"
          placeholder="Job Template name"
          value={jobTemplateData.templateName}
          disabled={true}
        />

        <FormDropDown
          label="Version"
          searchable={true}
          value={jobTemplateData.version}
          maxHeight="100px"
          options={setVersions(latestVersion)}
          placeholder={
            latestVersion === -1
              ? `${jobTemplateData.version} (Could not load other versions)`
              : "Select versions"
          }
          disabled={latestVersion === -1}
          onChange={(event: any) => {
            dispatch(
              getJobTemplate(jobTemplateData.jobTemplateId, event.target.value)
            );
          }}
        ></FormDropDown>
        {latestVersion === -1 && (
          <div>NOTE: Please refresh to load other versions</div>
        )}
      </KatCard>

      <JobComponent
        operationType={OperationType.VIEW}
        serviceType={jobTemplateData.serviceType}
        service={jobTemplateData.service}
        artifactInfo={jobTemplateData.artifactInfo}
      ></JobComponent>
      <br />
      <br />
    </>
  );
}
