export const jobDataSelector = (state: any) => state.job.fetchedJob;
export const jobListSelector = (state: any) => state.job.fetchedJobList;
export const isJobSpinnerLoadingSelector = (state: any) =>
  state.job.isSpinnerLoading;
export const jobVersionListSelector = (state: any) =>
  state.job.fetchedJobVersionsList;
export const jobLatestVersionDataSelector = (state: any) =>
  state.job.fetchedLatestVersionJob;
export const listJobsExecutionsSelector = (state: any) =>
  state.job.fetchedJobExecutionsList;
export const listJobsMetricsSelector = (state: any) =>
  state.job.executionMetrics;
