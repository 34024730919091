export const runwayDataSelector = (state: any) => state.runway.fetchedRunway;
export const runwayDAGSelector = (state: any) =>
  state.runway.fetchedRunway.runwayDAG;
export const runwayListSelector = (state: any) =>
  state.runway.fetchedRunwayList;
export const isRunwaySpinnerLoadingSelector = (state: any) =>
  state.runway.isSpinnerLoading;
export const runwayVersionsListSelector = (state: any) =>
  state.runway.fetchedRunwayVersionsList;
export const runwayLatestVersionDataSelector = (state: any) =>
  state.runway.fetchedLatestVersionRunway;
export const runwayReleaseSelector = (state: any) =>
  state.runway.fetchedLatestVersionRunway.runwayRelease;
export const listRunwayExecutionsSelector = (state: any) =>
  state.runway.fetchRunwayExecutions;
export const listRunwayMetricsSelector = (state: any) =>
  state.runway.executionMetrics;
