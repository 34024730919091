import React from "react";
import {useEffect, useRef, useState} from "react";
import ListDatasets from "src/view/dataset/list_datasets";
import {BannerTypes, Dataset} from "src/view/dataset/dataset_constants";
import {setIfMounted} from "src/utils/common_utils";
import ViewDataset from "src/view/dataset/view_dataset";
import CreateDataset from "src/view/dataset/create_dataset";
import { GenericAlert } from "src/view/style/view_config_styles";

export default function DatasetComponent () {
    let _isMounted = useRef(true);
    const [visibilityViewDataset, setVisibilityViewDataset] = useState(false);
    const [visibilityCreateDataset, setVisibilityCreateDataset] = useState(false);
    const [viewingDataset, setViewingDataset] = useState<Dataset| null>(null);
    const [bannerMessage, setBannerMessage] = useState("");
    const [bannerHeading, setBannerHeading] = useState("");
    const [bannerType, setBannerType] = useState<BannerTypes | undefined>(undefined);
    const [showBanner, setShowBanner] = useState(false);


    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    });

    const setVisibilityViewDatasetWrapper = (value: boolean) => setIfMounted(_isMounted.current, setVisibilityViewDataset, value);
    const setViewingDatasetWrapper = (value: Dataset) => setIfMounted(_isMounted.current, setViewingDataset, value);
    const setVisibilityCreateDatasetWrapper = (value: boolean) => setIfMounted(_isMounted.current, setVisibilityCreateDataset, value);
    const setBannerMessageWrapper = (value: string) => setIfMounted(_isMounted.current, setBannerMessage, value);
    const setBannerHeadingWrapper = (value: string) => setIfMounted(_isMounted.current, setBannerHeading, value);
    const setBannerTypeWrapper = (value: BannerTypes | undefined) => setIfMounted(_isMounted.current, setBannerType, value);
    const setShowBannerWrapper = (value: boolean) => setIfMounted(_isMounted.current, setShowBanner, value);

    return (
        <>
            {showBanner && (
                <GenericAlert
                    header={bannerHeading}
                    description={bannerMessage}
                    variant={bannerType}
                    onDismiss={() => setShowBannerWrapper(false)}
                />
            )}
            {visibilityCreateDataset && (
                <CreateDataset
                    visibility={visibilityCreateDataset}
                    setVisiblity={setVisibilityCreateDatasetWrapper}
                    setBannerHeading={setBannerHeadingWrapper}
                    setBannerMessage={setBannerMessageWrapper}
                    setBannerType={setBannerTypeWrapper}
                    setBannerVisibility={setShowBannerWrapper}
                />
            )}
            {visibilityViewDataset && (
                <ViewDataset
                    setVisiblity={setVisibilityViewDatasetWrapper}
                    dataset={viewingDataset}
                    visibility={visibilityViewDataset}
                />
            )}
            <ListDatasets
                setVisibilityForViewDataset={setVisibilityViewDatasetWrapper}
                setVisiblityForCreateDataset={setVisibilityCreateDatasetWrapper}
                setViewingDataset={setViewingDatasetWrapper}/>
        </>
    )

}