import React from 'react'
import {Link} from 'react-router-dom'
import FeatureGenerationJobsTable from './feature_generation_jobs_table'
import {KatButton, KatCard, KatIcon, KatTab, KatTabs, KatSpinner} from "@amzn/katal-react"
import {apiGetCallback} from 'src/utils/api_handler'
import {JobDomain} from '../constants'
import { withRouter } from 'react-router'
import { getListFeatureGenerationJobTarget } from 'src/control/targets'

export class FeatureGenerationJobsView extends React.Component<any, any> {
    _mounted: boolean = true

    constructor(props: any) {
      super(props);
      this.state = {
        namespaceId: this.props.match.params.namespaceId,
        teamId: this.props.match.params.teamId,
        jobLists: {
          experimentation: [],
          gamma: [],
          prod: [],
        },
        apiLoading: true,
        apiErrorFlag: false,
      }
      this.populateJobLists = this.populateJobLists.bind(this)
      this.loadJob = this.loadJob.bind(this)
    }

    componentDidMount() {
      this.loadJob()
      this._mounted = true
    }
    componentWillUnmount() {
      this._mounted = false
    }
    componentDidUpdate() {
      if(this.props.username != this.state.requestedBy) {
          this._mounted && this.setState({'requestedBy': this.props.username})
      }
      if(this.props.match.params.namespaceId != this.state.namespaceId) {
        this._mounted && this.setState({
          'namespaceId': this.props.match.params.namespaceId,
          'apiLoading': true,
        })
        this.loadJob()
      }
  }

    populateJobLists(apiResponseData: any) {
      const experimentationList: any = []
      const gammaList: any = []
      const prodList: any = []

      apiResponseData.JobsList.map((job: any) => {
        switch(job.domain) {
          case JobDomain.EXPERIMENTATION: {
            experimentationList.push(job)
            break
          }
          case JobDomain.GAMMA: {
            gammaList.push(job)
            break
          }
          case JobDomain.PROD: {
            prodList.push(job)
            break
          }
        }
      })
      this._mounted &&
      this.setState({jobLists: {
        experimentation: experimentationList,
        gamma: gammaList,
        prod: prodList,
      }})
    }

    loadJob() {
      apiGetCallback(getListFeatureGenerationJobTarget(this.props.match.params.namespaceId), (responseData: any) => {
          this.populateJobLists(responseData)
          this._mounted && this.setState({apiLoading: false})
      }, (error: any) => {
          this._mounted && this.setState({apiErrorFlag: true, apiLoading: false})
      });
    }

    render() {
        return (
            <>
                &nbsp;
                <KatCard>
                <span slot='title'> Jobs </span>
                <span slot='action'>
                  <Link to={`/featureGeneration/${this.state.teamId}/namespaces/${this.state.namespaceId}/jobs/new`}>
                      <KatButton variant="primary">
                          <KatIcon size="small" slot="icon" name="add"/>
                          Add Job
                      </KatButton>
                  </Link>
                </span>
                &nbsp;
                {this.state.apiLoading && <KatSpinner/>}
                {this.state.apiErrorFlag &&
                <kat-alert
                header="ERROR"
                description={`Error while fetching job data.`}
                variant="danger"
                />}
                {this.state.apiLoading == false && this.state.apiErrorFlag == false &&
                <KatTabs selected='experimentation'>
                    {Object.keys(this.state.jobLists).map((jobDomain: any) => {
                      return (
                            <KatTab key={jobDomain} tabId={jobDomain} label={jobDomain.charAt(0).toUpperCase() + jobDomain.slice(1)}>
                              {this.state.jobLists[jobDomain].length == 0 &&
                              <kat-alert
                              header="No view.configPanel.data"
                              description={`No job details registered in this namespace for ${jobDomain}.`}
                              variant="info"
                              persistent
                              />}
                              {this.state.jobLists[jobDomain].length != 0 &&
                              <FeatureGenerationJobsTable
                              domain={jobDomain}
                              jobList={this.state.jobLists[jobDomain]}
                              namespaceId={this.state.namespaceId}
                              teamId={this.state.teamId}
                              requestedBy={this.state.requestedBy}
                              />}
                          </KatTab>
                        )
                    })}
                  </KatTabs>}
                </KatCard>
                &nbsp;
            </>
        )
    }
}

export default withRouter(FeatureGenerationJobsView);
