import { ArtifactInfo, Service, ServiceType } from "./job_model";

export interface JobTemplate {
  templateName: string;
  jobTemplateId: string;
  createdBy: string;
  version: number;
  latestVersion: number;
  artifactInfo: ArtifactInfo;
  serviceType: ServiceType | string;
  service: Service | null;
  creationTime: string;
  lastUpdatedBy: string;
  lastUpdatedTime: string;
}

export interface InitialJobTemplateState {
  fetchedLatestVersionJobTemplate: JobTemplate;
  fetchedJobTemplate: JobTemplate;
  fetchedJobTemplateVersionsList: JobTemplate[];
  isSpinnerLoading: boolean;
  fetchedJobTemplateList: JobTemplate[];
}

export const initialJobTemplateState: InitialJobTemplateState = {
  fetchedLatestVersionJobTemplate: {
    templateName: "",
    jobTemplateId: "",
    createdBy: "",
    version: 1,
    latestVersion: -1,
    artifactInfo: {
      containerType: "",
      containerInfo: {},
      jobMetadata: "",
      arguments: "",
      codeInfo: {
        codeRepository: {
          repositoryType: "",
          gitRepository: {},
          s3Repository: {},
        },
        preExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        executionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        postExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        }
      },
    },
    serviceType: "",
    service: null,
    creationTime: "",
    lastUpdatedBy: "",
    lastUpdatedTime: "",
  },
  fetchedJobTemplate: {
    templateName: "",
    jobTemplateId: "",
    createdBy: "",
    version: 1,
    latestVersion: 1,
    artifactInfo: {
      containerType: "",
      containerInfo: {},
      jobMetadata: "",
      arguments: "",
      codeInfo: {
        codeRepository: {
          repositoryType: "",
          gitRepository: {},
          s3Repository: {},
        },
        preExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        executionInfo: {
          codeUri:"",
          codeVersion: ""
        },
        postExecutionInfo: {
          codeUri:"",
          codeVersion: ""
        }
      },
    },
    serviceType: "",
    service: null,
    creationTime: "",
    lastUpdatedBy: "",
    lastUpdatedTime: "",
  },
  fetchedJobTemplateVersionsList: [],
  isSpinnerLoading: false,
  fetchedJobTemplateList: [],
};
