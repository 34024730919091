import React, {useEffect, useRef, useState} from "react";
import {WhiteSpinner} from "src/view/style/header_styles";
import {NavItemLabel, NavItemPadding, NavListItem} from "src/view/style/navigation_styles";
import {colorWhite, NavExpandedIcon, NavExpandIcon,} from "src/view/style/icons";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {CouldNotLoadItem, NavItem, onClickParams} from "./nav_item_commons";
import {selectedTeamSelector} from "src/control/selectors/commons/team_selectors";
import {isEmpty, isEmptyObject, setIfMounted} from "src/utils/common_utils";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";


interface NavigationItemProps {
    navItem: NavItem,
    closeSideNav: any,
    paddingLevel: number
}

export default function NavigationItem(props: NavigationItemProps) {
    let navItem = props.navItem;
    let closeSideNav = props.closeSideNav;
    let currentPadding = props.paddingLevel;

    const dispatch = useDispatch();
    const history = useHistory();
    const team = useSelector(selectedTeamSelector);

    const [subnavShowing, setSubnavShowing] = useState(false);
    const [subNav, setSubNav] = useState(!isEmpty(navItem.subNavItems) ? navItem.subNavItems: []);
    const [subNavLoading, setSubNavLoading] = useState(false);
    const subNavSelector: any = navItem.subNavSelector? useSelector(navItem.subNavSelector): null;
    const couldNotLoadSubNav = navItem.couldNotLoadSubNav;

    let _isMounted = useRef(true);

    const showSubNav = () => setIfMounted(_isMounted.current, setSubnavShowing, !subnavShowing);

    useEffect(() => {
        _isMounted.current = true;
    })

    useEffect(() => {
        return () => {
            _isMounted.current = false;
        }
    })

    useEffect(() => {
        if (isEmpty(navItem.subNavItems)) {
            loadSubNavDynamically()
        }
    }, [subNavSelector, subNavLoading]);

    const loadSubNavDynamically = () => {
        if (!isEmpty(subNavSelector)) {
            let subNavItems: NavItem[] = subNavSelector.map((item: any) => {
                return navItem.navItemConstructor(item)
            });
            setIfMounted(_isMounted.current, setSubNav, subNavItems);
            return;
        }

        if (isEmpty(subNavSelector) && !subNavLoading) {
            setIfMounted(_isMounted.current, setSubNav, [CouldNotLoadItem])
        }
    }

    const addPadding = () => {
        let paddings = []
        for (let i=0; i<props.paddingLevel; i++) {
            paddings.push(<NavItemPadding key={i}/>)
        }
        return paddings;
    }

    const shouldDisableTeams = (displayItem: string) => {
        const nonTeamsDisplayItems = ["Job Template", "Code Repositories"]
        return nonTeamsDisplayItems.includes(displayItem)
    }

    return !isEmptyObject(navItem) ? (
        <>
            <NavListItem onClick={(event: any) => {
                if (isEmpty(team) && !shouldDisableTeams(navItem.displayName)){
                    dispatch(showSnackBar("Please select team to continue"))
                    return;
                }

                if (navItem.hasSubNav && navItem.onClick && !subnavShowing) {
                    let onClickProps: onClickParams = {
                        history: history,
                        dispatch: dispatch,
                        isMounted: _isMounted,
                        setLoading: setSubNavLoading,
                        otherParams: {
                            teamId: team.teamId
                        }

                    }
                    navItem.onClick(onClickProps);
                    showSubNav();
                    return;
                }

                if (navItem.hasSubNav && !isEmpty(subNav)) {
                    showSubNav();
                    return;
                }

                if (!navItem.hasSubNav && navItem.labelOnClick) {
                    let onClickProps: onClickParams = {
                        history: history,
                        dispatch: dispatch,
                        isMounted: _isMounted,
                        setLoading: setSubNavLoading,
                        otherParams: {
                            teamId: shouldDisableTeams(navItem.displayName) ? null : team.teamId
                        }
                    }
                    navItem.labelOnClick(onClickProps);
                }
                closeSideNav();
            }}>
                {addPadding()}
                <div>
                {
                    navItem.hasSubNav && subnavShowing ?
                        <NavExpandedIcon style={colorWhite}/> :
                        navItem.hasSubNav ?
                            <NavExpandIcon style={colorWhite}/>:
                            <NavItemPadding/>
                }
                </div>
                <NavItemLabel>
                    {navItem.icon ? navItem.icon: ""}
                </NavItemLabel>
                <NavItemLabel
                    onClick={(e: any) => {
                        if (navItem.hasSubNav) {
                            if (isEmpty(team)) {
                                dispatch(showSnackBar("Please select a team to continue"));
                                return;
                            }

                            if (navItem.labelOnClick) {
                                let onClickProps: onClickParams = {
                                    history: history,
                                    dispatch: dispatch,
                                    isMounted: _isMounted,
                                    setLoading: setSubNavLoading,
                                    otherParams: {
                                        teamId: team.teamId
                                    }
                                }
                                navItem.labelOnClick(onClickProps);
                            }
                            closeSideNav();
                        }
                    }}
                >
                    {navItem.displayName}
                </NavItemLabel>
                &nbsp;&nbsp;&nbsp;
                {subNavLoading?
                    <WhiteSpinner className={"float-right"} variant={"default"} size={"small"}/>:
                    ""
                }
            </NavListItem>
            {
                subnavShowing &&
                    navItem.hasSubNav &&
                    !subNavLoading &&
                    subNav &&
                    subNav.map((nItem:NavItem, key:number) => {
                        return (
                            <NavigationItem
                                key={key}
                                navItem={nItem}
                                closeSideNav={closeSideNav}
                                paddingLevel={currentPadding + 1}
                            />
                        )
                    })

            }

        </>
    ) : <></>;
}