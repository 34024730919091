import { KatBadge, KatOption } from "@amzn/katal-react";
import { Button } from "@material-ui/core";
import React from "react";
import { RunwayStatus } from "../../../src/model/runways/runway_model";
import { JobStatus } from "../modelTraining/constants";
import { GenericAlert, PopUp, VStatusIndicator } from "../style/view_config_styles";

export const getRunwayStatusComponent = (runwayStatus: any) => {
  switch (runwayStatus.toUpperCase()) {
    case RunwayStatus.CREATE_FAILED: {
      return <VStatusIndicator variant="error" label="Create Failed" />;
    }
    case RunwayStatus.UPDATE_FAILED: {
      return <VStatusIndicator variant="error" label="Update Failed" />;
    }
    case RunwayStatus.CREATING: {
      return <VStatusIndicator variant="loading" label="Creating" />;
    }
    case RunwayStatus.UPDATING: {
      return <VStatusIndicator variant="loading" label="Updating" />;
    }
    case RunwayStatus.ENABLED: {
      return <VStatusIndicator variant="success" label="Enabled" />;
    }
    case RunwayStatus.DISABLED: {
      return <VStatusIndicator variant="alert" label="Disabled" />;
    }
  }
};

export const getStatusBadge = (status: string) => {
  let type: any = "";
  switch (status) {
    case JobStatus.SUCCEEDED:
      type = "success";
      break;
    case JobStatus.FAILED:
      type = "warning";
      break;
    case JobStatus.ABORTED:
      break;
    default:
      status = JobStatus.INPROGRESS;
      type = "info";
      break;
  }
  return (
    <>
      <KatBadge label={status} type={type} />
    </>
  );
};

export const getJobVersionDropdownOptions = (
  latestVersion: number,
  liveVersion: number
) => {
  let options: any[] = [];
  for (let currIndex = latestVersion; currIndex > 0; currIndex--) {
    options.push(
      currIndex === liveVersion ? (
        <KatOption value={`${currIndex}`}>
          {currIndex}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ backgroundColor: "#009800", color: "white" }}>
            &nbsp;&nbsp;Live&nbsp;&nbsp;
          </span>
        </KatOption>
      ) : (
        <KatOption value={`${currIndex}`}>{currIndex}</KatOption>
      )
    );
  }
  return options;
};

export const getSaveMessageComponent = (runwayDAG: any[]) => {
  let showSaveMessage = false;
  runwayDAG.every((dagElement: any) => {
    if (dagElement.prevLiveVersion !== dagElement.version) {
      showSaveMessage = true;
      return false;
    }
    return true;
  });

  if (showSaveMessage) {
    return (
      <GenericAlert
        variant="warning"
        header={"[NOTE] Unsubmitted changes"}
        description={
          "Please click on Submit button below to save your changes. Else changes will be lost."
        }
        persistent
      ></GenericAlert>
    );
  }
};

export function VersionCheckPopUp(props: {
  popUpVisible: boolean,
  setPopUpVisible: any,
  showPopUp: any,
  closePopUp: any,
  continueActionMethod: any,
  cancelActionMethod: any,
}){
  return(
    <PopUp visible={true} onOpen={props.showPopUp} onClose={props.closePopUp}>
          <span slot={"title"}>Warning</span>
          <div>You are not making changes in the latest version. You can still
          proceed or refresh to find all the latest versions. However note that
          all the changes will be lost on refresh</div>
          <Button
            style={{
              float: "right",
              background: "#002e36",
              color: "white",
              marginTop: "10px",
            }}
            onClick={props.cancelActionMethod}
          >
            CANCEL
          </Button>
          <Button
            style={{
              float: "right",
              marginRight: "10px",
              background: "#002e36",
              color: "white",
              marginTop: "10px",
            }}
            onClick={() => {
              props.setPopUpVisible(false);
              props.continueActionMethod()
            }}
          >
            Continue
          </Button>
        </PopUp>
  )
}