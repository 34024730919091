import { ServiceType } from "src/model/runways/job_model";
import { isEmpty } from "../../../utils/common_utils";
import {
  isValidPreRequisite,
  isValidServiceType,
} from "./job_components_validations";

export const isValidJobName = (jobName: string) => {
  return !isEmpty(jobName);
};

export const isValidScheduleFrequency = (scheduleFrequencyInMins: number) => {
  return !isEmpty(scheduleFrequencyInMins) && scheduleFrequencyInMins > 0;
};

export const isValidOffsetMinutes = (minutes: string) => {
  return !isEmpty(minutes);
};

export const isValidDependentOffsetMinutes = (
  dependencyOffsetInMins: string
) => {
  return (
    !isEmpty(dependencyOffsetInMins) && Number(dependencyOffsetInMins) >= 0
  );
};

export const isValidDependentInstanceCount = (
  dependentInstancesCount: string
) => {
  return (
    !isEmpty(dependentInstancesCount) && Number(dependentInstancesCount) > 0
  );
};

export const isValidDependentInstanceFrequency = (
  dependencyInstanceFrequencyInMins: string
) => {
  return isEmpty(dependencyInstanceFrequencyInMins)
    ? true
    : Number(dependencyInstanceFrequencyInMins) > 0;
};

/**
 * checks if all fields have valid values or not
 * @returns boolean
 */
export const validatePrerequisites = (payload: any, isFirstJob?: boolean) => {
  return (
    isValidJobName(payload.jobName) &&
    isValidScheduleFrequency(payload.scheduleFrequencyInMins) &&
    isValidOffsetMinutes(payload.offsetInfo.minutes.toString()) &&
    (isFirstJob ||
      (isValidDependentOffsetMinutes(
        payload.dependencyOffsetInMins.toString()
      ) &&
        isValidDependentInstanceCount(
          payload.dependentInstancesCount.toString()
        ) &&
        isValidDependentInstanceFrequency(
          payload.dependencyInstanceFrequencyInMins
        ))) &&
    isValidServiceType(payload.serviceType as ServiceType) &&
    isValidPreRequisite(payload)
  );
};
