import {
    START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS
} from "src/control/actions/action_types";
import {fetchConfigValidationInfo} from "src/control/actions/configPanel/config_panel_actions";
import {isEmpty} from "src/utils/common_utils";

const configValidationFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const nextAction = next(action)
    if(action.type === START_CONFIG_VALIDATION_FLOW_INFO_SUCCESS) {
        if(!isEmpty(action.payload)) {
            dispatch(fetchConfigValidationInfo(action.payload.validationId))
        }
    }
    return nextAction
}

export default [
    configValidationFlow
]