import {LOCATION_CHANGE} from "connected-react-router";
import {REFRESH_STATES} from "src/control/actions/action_types";


const refreshStateFlow = ({dispatch}: any) => (next: any) => (action: any) => {
    const REPLACE_ACTION_TYPE = "REPLACE"
    const nextAction = next(action)
    if (action.type === LOCATION_CHANGE) {
        // We dont want to dispatch refresh action on query parameters change
        if(action.payload.action != REPLACE_ACTION_TYPE) {
            dispatch({
                type: REFRESH_STATES
            })
        }
    }
    return nextAction
};

export default [
    refreshStateFlow
]
