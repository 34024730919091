import {
    KatSpinner,
    KatTable,
    KatTableBody,
    KatTableCell,
    KatTableHead,
    KatTableRow,
    KatBadge
} from "@amzn/katal-react";
import {useParams} from "react-router-dom";
import ReactTooltip from 'react-tooltip';
import React, {useRef} from "react";
import {useEffect} from 'react';
import {ErrorHeading, ErrorContainer} from "src/view/style/modelTraining/errors"
import {useDispatch, useSelector} from "react-redux";
import {usernameSelector} from "src/control/selectors/commons/user_selectors";
import {showSnackBar} from "src/control/actions/commons/snack_bar_actions";
import {TableHeader} from "src/view/style/modelTraining/table_styles";
import {sortList} from "src/utils/list_utils";
import {LaunchIcon} from "src/view/style/icons";
import {Status} from "src/constant";
import {PaddedSpan, TableRowWithShadow} from "src/view/style/table_styles";
import {namespaceSelector} from "src/control/selectors/modelTraining/model_training_selectors";
import {listDataStudioSelector} from "src/control/selectors/notebooks/data_studio_selectors";
import {listDataStudios} from "src/control/actions/notebooks/data_studio_actions";
import {loadModelTrainingNamespace} from "src/control/actions/modelTraining/namespace_actions";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";
import {isEmpty} from "src/utils/common_utils";


export default function ListDataStudio() {
    const dispatch = useDispatch();
    let userName = useSelector(usernameSelector);
    let listDataStudioState = useSelector(listDataStudioSelector);
    let fetchedNamespace = useSelector(namespaceSelector)

    let _isMounted = useRef(true);
    const params: any = useParams()

    useEffect(() => {
        _isMounted.current = true;
        return () => {
            _isMounted.current = false;
        }
    }, []);

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []))
        }
    }, [params.teamId, params.namespaceId, dispatch]);

    useEffect(() => {
        if (_isMounted.current) {
            dispatch(listDataStudios(fetchedNamespace.namespaceData.accountTypeMap.experimentation))
        }
    }, [fetchedNamespace]);

    const getDataStudioStatusBadgeType = (dataStudioStatus: string) => {
        switch (dataStudioStatus) {
            case "Active" :
                return "success";
            default:
                return "tag";
        }
    }

    const handleDataStudioLaunch = (dataStudio: any) => {
        if (dataStudio.domainUrl)
            window.open(dataStudio.domainUrl, "_blank")
        else
            dispatch(showSnackBar("Error Launching Data Studio"))
    }

    const getRow = (dataStudio: any) => {
        return (
            <TableRowWithShadow
                key={dataStudio.studioName}
                onClick={
                () => handleDataStudioLaunch(dataStudio)
            }>
                <KatTableCell>{dataStudio.studioName}</KatTableCell>
                <KatTableCell>{dataStudio.createdBy}</KatTableCell>
                <KatTableCell>{dataStudio.creationTime}</KatTableCell>
                <KatTableCell>
                    <KatBadge label={dataStudio.status}
                              type={getDataStudioStatusBadgeType(dataStudio.status)}
                    />
                </KatTableCell>
                <KatTableCell>
                    <ReactTooltip place="bottom" type="dark"/>
                    <PaddedSpan data-tip="Launch DataStudio">
                        <LaunchIcon style={{
                            margin: "10px",
                            width: "20px",
                            color: "#002e36"
                        }} onClick={
                            () => handleDataStudioLaunch(dataStudio)
                        }/>
                    </PaddedSpan>
                </KatTableCell>
            </TableRowWithShadow>
        )
    }
    return !isEmpty(fetchedNamespace.namespaceData.namespace) ? (
        <>
            <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}/>
            {
                listDataStudioState.status == Status.ERROR ?
                    <ErrorContainer>
                        <ErrorHeading>Error loading DataStudios</ErrorHeading>
                    </ErrorContainer>
                    :
                    <>
                        <div>
                            <TableHeader>
                                Your Data Studio
                            </TableHeader>
                        </div>
                        <KatTable>
                            {
                                listDataStudioState.status === Status.LOADING ? <KatSpinner/> :
                                    <>
                                        {
                                            listDataStudioState.dataStudios.length == 0 ?
                                                <ErrorContainer>
                                                    <ErrorHeading>No Results Found</ErrorHeading>
                                                </ErrorContainer>
                                                :
                                                <>
                                                    <KatTableHead>
                                                        <KatTableRow>
                                                            <KatTableCell>Name</KatTableCell>
                                                            <KatTableCell>Created By</KatTableCell>
                                                            <KatTableCell>Creation Date</KatTableCell>
                                                            <KatTableCell>Status</KatTableCell>
                                                            <KatTableCell>Actions</KatTableCell>
                                                        </KatTableRow>
                                                    </KatTableHead>
                                                    <KatTableBody>
                                                        {sortList(listDataStudioState.dataStudios, "createTime").map(getRow)}
                                                    </KatTableBody>
                                                </>
                                        }
                                    </>
                            }
                        </KatTable>
                    </>
            }
        </>
    ) : <KatSpinner/>;

}