import {
    DISABLE_V2_RUNWAYS_EXECUTIONS_LOAD_MORE,
    LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS,
  } from "../../../control/actions/action_types";
  import { V2_RUNWAYS_EXECUTIONS_DEFAULT_PAGE_SIZE } from "../../../view/runways/constants";
  
  const disableLoadMoreForRunwaysInstances =
    ({ dispatch }: any) =>
    (next: any) =>
    (action: any) => {
      const nextAction = next(action);
      if (action.type === LOAD_V2_RUNWAYS_EXECUTIONS_STATUS_SUCCESS) {
        if (action.payload.length < V2_RUNWAYS_EXECUTIONS_DEFAULT_PAGE_SIZE) {
          // To disable load more button in case there are no more pages to be fetched.
          dispatch({ type: DISABLE_V2_RUNWAYS_EXECUTIONS_LOAD_MORE });
        }
      }
      return nextAction;
    };
  
  export default [disableLoadMoreForRunwaysInstances];
  