import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getRunwayLatestVersionData } from "src/control/actions/runways/runway_actions";
import { isEmpty } from "src/utils/common_utils";
import ViewV1RunwayExecutions from "./modelTraining/runways/runway_executions";
import ViewV2RunwayExecutions from "./runways/runway_executions";
import { GreenSpinner } from "./style/header_styles";
import { runwayReleaseSelector } from "src/control/selectors/runways/runway_selectors";

/**
 * responsible for calling the corresponding v1/v2 executions component
 * @returns JSX
 */
export default function ViewRunwayExecutions() {
  const dispatch = useDispatch();
  const params: any = useParams();

  //selectors
  let runwayRelease = useSelector(runwayReleaseSelector);

  //useEffect hooks
  useEffect(() => {
    dispatch(getRunwayLatestVersionData(params.runwayId));
  }, [params.runwayId]);

  if (runwayRelease === -1) {
    return <GreenSpinner />;
  }

  return (
    <>
      {isEmpty(runwayRelease) && <ViewV1RunwayExecutions />}
      {!isEmpty(runwayRelease) && runwayRelease.toString() === "2" && (
        <ViewV2RunwayExecutions />
      )}
    </>
  );
}
