import { RepositoryType } from "src/model/codeRepository/code_repository_models";
import {BEATS_DUB_ENDPOINT, BEATS_IAD_ENDPOINT, BEATS_PDX_ENDPOINT} from "src/view/modelTraining/constants";
import { isEmpty } from "./common_utils";

export function isExperimentNameValid (experimentName: string, userId: string) {
    // Experiment name starting with alphabet or number and having only - in special characters
    const regexExp : RegExp= new RegExp("^[a-zA-Z0-9](-*[a-zA-Z0-9])*")
    const regexMatchArray = experimentName.match(regexExp)
    return regexMatchArray != null &&
        regexMatchArray.indexOf(experimentName) > -1 &&
        (experimentName.length + userId.length + 1) <= 63;
}

export const getBeatsDomain = (region: string) => {
    switch (region) {
        case "IAD":
            return BEATS_IAD_ENDPOINT
        case "DUB":
            return BEATS_DUB_ENDPOINT
        case "PDX":
            return BEATS_PDX_ENDPOINT
        case "SIN":
            return BEATS_PDX_ENDPOINT
    }
}

export const isValidCustomScriptPath = (path: string) => {
    const scriptPathRegex = /^[a-zA-Z][^.]*\.sh$/;
    return scriptPathRegex.test(path);
}