import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io5";
import * as VscIcons from "react-icons/vsc";
import * as CgIcons from "react-icons/cg";
import * as RiIcons from "react-icons/ri";
import * as GiIcons from "react-icons/gi";
import * as TiIcons from "react-icons/ti";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";
import { MdContentCopy, MdDeleteForever } from "react-icons/md";
import { ImCross, ImCogs } from "react-icons/im";
import { BsFillInfoCircleFill, BsToggleOn, BsToggleOff, BsArrowRight } from "react-icons/bs";

export const colorWhite = {
    "color": "#fff",
}

export const colorGreen = {
    color: "#002e36",
}

export const aquaGreen = {
    color: "#00a4b4",
}

export const colorGrey = {
    color: "grey",
}

export const defaultIconSize = "25px";
export const defaultCursorType = "pointer";
export const smallIconSize = "20px";


export const ViewIconTable =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<AiIcons.AiFillEye style={style} size={size} {...props} />);

export const NewBranchIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<AiIcons.AiOutlineBranches style={style} size={size} {...props} />);

export const EditIconTable =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<FaIcons.FaEdit style={style} size={size} {...props} />);

export const DeleteIconTable =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<AiIcons.AiFillDelete style={style} size={size} {...props} />);

export const RestartIconTable =
    ({ style = colorGreen, cursor = defaultCursorType, size = defaultIconSize, ...props }: any) => (<VscIcons.VscDebugRestart style={style} size={size} cursor={cursor} {...props} />);

export const NavBarsIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<FaIcons.FaBars style={style} size={size} {...props} />);

export const NavCloseIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<AiIcons.AiOutlineClose style={style} size={size} {...props} />);

export const NavExpandIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<RiIcons.RiArrowRightSFill style={style} size={size} {...props} />);

export const NavExpandedIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<RiIcons.RiArrowDownSFill style={style} size={size} {...props} />);

export const NavDashIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<VscIcons.VscDash style={style} size={size} {...props} />);

export const NavErrorIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<RiIcons.RiErrorWarningLine style={style} size={size} {...props} />);

export const NavHomeIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<AiIcons.AiFillHome style={style} size={size} {...props} />);

export const NavConfigPanelIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<IoIcons.IoNewspaperSharp style={style} size={size} {...props} />);

export const NavDatasetIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<FaIcons.FaDatabase style={style} size={size} {...props} />);

export const NavNotebooksIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<GiIcons.GiArtificialHive style={style} size={size} {...props} />);

export const NavRunwaysIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<IoIcons.IoHardwareChipOutline style={style} size={size} {...props} />);

export const NavNamespaceIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<VscIcons.VscSymbolNamespace style={style} size={size} {...props} />);

export const NavNamespaceCreateIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<CgIcons.CgAddR style={style} size={size} {...props} />);

export const ReLoadIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<IoIcons.IoReloadCircle style={style} size={size} {...props} />);

export const RefreshIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<IoIcons.IoRefreshCircle style={style} size={size} {...props} />);

export const CancelIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<TiIcons.TiCancel style={style} size={size} {...props} />);

export const UploadIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<FaIcons.FaUpload style={style} size={size} {...props} />);

export const CopyIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<MdContentCopy style={style} size={size} {...props} />);

export const InfoIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<BsFillInfoCircleFill style={style} size={size} {...props} />);

export const ToggleOn =
    ({ style = aquaGreen, size = defaultIconSize, ...props }: any) => (<BsToggleOn style={style} size={size} {...props} />);

export const ToggleOff =
    ({ style = aquaGreen, size = defaultIconSize, ...props }: any) => (<BsToggleOff style={style} size={size} {...props} />);

export const ExternalLink =
    ({ style = aquaGreen, size = defaultIconSize, ...props }: any) => (<CgIcons.CgExternal style={style} size={size} {...props} />);

export const SortAscending =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<BiIcons.BiSortUp style={style} size={size} {...props} />);

export const SortDescending =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<BiIcons.BiSortDown style={style} size={size} {...props} />);

export const ContinueIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<BsArrowRight style={style} size={size} {...props} />);

export const CrossIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<ImCross style={style} size={size} {...props} />);

export const DeleteIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<MdDeleteForever style={style} size={size} {...props} />);
export const DownloadIcon =
    ({ style = colorGreen, size = defaultIconSize, ...props }: any) => (<FaIcons.FaDownload style={style} size={size} {...props} />);

export const RightArrowIcon = 
    ({ style = colorWhite, size = defaultIconSize, ...props }: any) => (<BiIcons.BiRightArrow style={style} size={size} {...props} />);

export const NextPointerIcon = 
({ style = colorGrey, size = "50px", ...props }: any) => (<BsArrowRight style={style} size={size} {...props} />);

export const ExecutionsIcon =
    ({ style = colorGrey, size = defaultIconSize, ...props }: any) => (<ImCogs style={style} size={size} {...props} />);

export const JobTemplateIcon = 
    ({ style = colorGrey, size = defaultIconSize, ...props }: any) => (<CgIcons.CgNotes style={style} size={size} {...props} />);

export const CodeRepositoryIcon =
    ({ style = colorGrey, size = defaultIconSize, ...props }: any) => (<AiIcons.AiFillCodepenCircle style={style} size={size} {...props} />);

export const LaunchIcon =
    ({ style = colorGrey, size = defaultIconSize, ...props }: any) => (<MdIcons.MdLaunch style={style} size={size} {...props} />);

export const AddonIcon =
    ({ style = colorGrey, size = defaultIconSize, ...props }: any) => (<IoIcons.IoExtensionPuzzleSharp style={style} size={size} {...props} />);