import { KatButton, KatCard } from "@amzn/katal-react";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { FormInput } from "src/view/style/modelTraining/form_input_styles";
import { isJobTemplateSpinnerLoadingSelector } from "src/control/selectors/runways/job_template_selectors";
import JobComponent from "../job_components";
import { createJobTemplate } from "src/control/actions/runways/job_template_actions";
import {ArtifactInfo, ServiceType} from "src/model/runways/job_model";
import { DisplayError } from "src/view/commons/display_error";
import {
  isValidJobTemplateName,
  validatePrerequisites,
} from "../../validation/runways/job_template_validations";
import {initialJobTemplateState} from "src/model/runways/job_template_model";
import {OperationType} from "src/constant";

/**
 * renders the view for create job template form
 * @returns JSX
 */
export default function CreateJobTemplate() {
  const dispatch = useDispatch();
  const history = useHistory();
  const params: any = useParams();

  //selectors
  let userName = useSelector(usernameSelector);
  let isSpinnerLoading = useSelector(isJobTemplateSpinnerLoadingSelector);

  //state hooks
  const [templateName, setJobTemplateName] = useState("");
  const [service, setService] = useState<any>({});
  const [artifactInfo, setArtifactInfo] = useState<any>(initialJobTemplateState.fetchedJobTemplate.artifactInfo);
  const [serviceType, setServiceType] = useState("");
  const [isValidationSuccess, setIsValidationSuccess] = useState(false);

  //useEffect hooks
  useEffect(() => {
    //to remove unused query params from url
    history.replace({
      search: "",
    });
  }, [userName]);

  /**
   * responsible for calling relevant action on submit click
   * redirects to view job template page
   */
  const submitCreateJobTemplateForm = async () => {
    if (
      !validatePrerequisites({
        templateName,
        serviceType,
        service,
        artifactInfo,
      })
    ) {
      setIsValidationSuccess(false);
    } else {
      setIsValidationSuccess(true);
      try {
        let jobTemplateId = await dispatch(
          createJobTemplate(
            templateName,
            userName,
            serviceType as ServiceType,
            service,
              {
                  ...artifactInfo,
                  containerType: serviceType == ServiceType.EMR ? null :
                      artifactInfo.containerType
              } as ArtifactInfo,
          )
        );
        let currentPath = window.location.pathname.replace(
          "create",
          `${jobTemplateId}`
        );
        history.push(currentPath + "/view");
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <>
      <KatCard>
        <span slot="title"> Create Job Template</span>
        <FormInput
          label="Job Template Name"
          type="text"
          placeholder="Job Template Name"
          value={templateName}
          tooltipText="Job Template Name"
          onChange={(event: any) => setJobTemplateName(event.target.value)}
          required
        />
        {!isValidationSuccess && !isValidJobTemplateName(templateName) && (
          <DisplayError message="field can't be empty" />
        )}
      </KatCard>
      <JobComponent
        operationType={OperationType.CREATE}
        setServiceType={setServiceType}
        service={service}
        setService={setService}
        artifactInfo={artifactInfo}
        setArtifactInfo={setArtifactInfo}
      ></JobComponent>

      <KatButton
        type="submit"
        label="Submit"
        loading={isSpinnerLoading}
        onClick={submitCreateJobTemplateForm}
      ></KatButton>
      <br />
      <br />
    </>
  );
}
