import {KatCard, KatFlashbar, KatRadiobuttonGroup, KatSpinner} from "@amzn/katal-react";
import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { loadModelTrainingNamespace } from "src/control/actions/modelTraining/namespace_actions";
import { usernameSelector } from "src/control/selectors/commons/user_selectors";
import { namespaceSelector } from "../../control/selectors/modelTraining/model_training_selectors";
import {
  runwayDAGSelector,
  isRunwaySpinnerLoadingSelector,
} from "../../control/selectors/runways/runway_selectors";
import Collapsible from "../commons/collapsible";
import { FormInput } from "../style/modelTraining/form_input_styles";
import { CreateRunwayButton } from "../style/runway_styles";
import { isEmpty } from "src/utils/common_utils";
import { GreenSpinner } from "../style/header_styles";
import { Pipeline } from "./pipelines";
import { createV2Runway } from "../../control/actions/runways/runway_actions";
import { getStageOptions } from "./constants";
import {
  isValidDomainSelection,
  isValidRunwayDAG,
  isValidRunwayName,
} from "../validation/runways/runways_validation";
import { DisplayError } from "../commons/display_error";
import { getSaveMessageComponent } from "./utils";
import BreadCrumb from "src/view/commons/breadCrumb/breadcrumb_bar";

/**
 * renders the view into a create runway form
 * @returns JSX
 */
export default function CreateV2Runway() {
  const dispatch = useDispatch();
  const params: any = useParams();
  const history = useHistory();

  //selectors
  let fetchedNamespace = useSelector(namespaceSelector);
  let userName = useSelector(usernameSelector);
  let runwayDAG = useSelector(runwayDAGSelector);
  let isSpinnerLoading = useSelector(isRunwaySpinnerLoadingSelector);

  //state hooks
  const [runwayName, setRunwayName] = useState("");
  const [stage, setStage] = useState("");
  const [isValidationSuccess, setIsValidationSuccess] = useState(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  //useEffect hooks
  useEffect(() => {
    //to remove unused query params from url
    history.replace({
      search: "",
    });
    dispatch(loadModelTrainingNamespace(params.teamId, params.namespaceId, []));
  }, [userName]);

  /**
   * checks if all fields have valid values or not
   * @returns boolean
   */
  const validatePrerequisites = () => {
    return (
      isValidRunwayName(runwayName) &&
      isValidDomainSelection(stage) &&
      isValidRunwayDAG(runwayDAG)
    );
  };

  /**
   * responsible for calling relevant action on submit click
   * redirects to view runway page
   */
  const submitCreateRunwayForm = async () => {
    if (!validatePrerequisites()) {
      setIsValidationSuccess(false);
    } else {
      try {
        setIsValidationSuccess(true);
        let runwayId = await dispatch(
          createV2Runway(
            runwayName,
            params.namespaceId,
            userName,
            stage,
            runwayDAG,
            null
          )
        );
        let currentPath = window.location.pathname.replace(
          "create",
          `${runwayId}`
        );
        history.push(currentPath + "/view");
      } catch (err : any) {
        setIsError(true)
        setErrorMessage(err.response.data.message)
        console.log("create runway operation failed with error: ",err.response.data.message);
      }
    }
  };

  if (isEmpty(fetchedNamespace.namespaceData.namespace)) {
    return <GreenSpinner variant={"default"} size={"large"} />;
  }

  return (
      <>
      { !isEmpty(fetchedNamespace.namespaceData.namespace) ?
        <>
          <BreadCrumb namespaceName={fetchedNamespace.namespaceData.namespace}></BreadCrumb>
          <KatCard>
            <span slot="title"> Create Runway </span>

            <FormInput
              label="Namespace Name"
              type="text"
              placeholder="Namespace name"
              value={fetchedNamespace.namespaceData.namespace}
              tooltipText="Namespace Name"
              disabled={true}
            />

            <FormInput
              label="Runway Name *"
              type="text"
              placeholder="Runway name"
              value={runwayName}
              tooltipText="Runway Name"
              onChange={(event: any) => setRunwayName(event.target.value)}
              required
            />
            {!isValidationSuccess && !isValidRunwayName(runwayName) && (
              <DisplayError message="field can't be empty" />
            )}
          </KatCard>

          <Collapsible label="Stage">
            <KatRadiobuttonGroup
              name={"Stage *"}
              value={stage}
              options={getStageOptions()}
              onChange={(event: any) => setStage(event.target.value)}
              disabled={runwayDAG.length > 0}
            ></KatRadiobuttonGroup>
            {!isValidationSuccess && !isValidDomainSelection(stage) && (
              <DisplayError message="Please select this field" marginTop={1} />
            )}
          </Collapsible>

          <Collapsible label="Jobs">
            <KatCard>
              <Pipeline jobData={runwayDAG} domain={stage}></Pipeline>
            </KatCard>
            {!isValidationSuccess && !isValidRunwayDAG(runwayDAG) && (
              <DisplayError message="Please add atleast one job" marginTop={1} />
            )}
            <br />
          </Collapsible>
          <br />

          {getSaveMessageComponent(runwayDAG)}

          <br/>{
          isError ? <KatFlashbar variant={"danger"} header={errorMessage}></KatFlashbar> : null
          }
          <br/>

          <CreateRunwayButton
            onClick={submitCreateRunwayForm}
            loading={isSpinnerLoading}
          >
            CREATE
          </CreateRunwayButton>
          <br />
          <br />
        </> : <KatSpinner/>
      }
      </>
  );
}
